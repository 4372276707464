import { useQuery, useQueryClient } from "@tanstack/react-query";
import { sendCall } from "../config/Utility";
import { useEffect } from "react";
import { minutes } from "src/app/utils/time";
import { useIsMobile } from "../hooks/useIsMobile";
import { useMyUser } from "../hooks/useMyUser";
import { upfetch } from "./upfetch";
import { Product, apiProductSchema } from "../models/Product";
import { SerialProduct } from "../models/SerialProduct";
import { QKEY } from "./QKEY";

const getCustomerRoles = {
  queryKey: () => [QKEY.CUSTOMER_ROLE],
  queryFn: (accessToken) => async () => {
    try {
      return await sendCall("users/GetAllCustomerRolesActive/", "GET", {
        accessToken,
      }).then((res) => res.result || []);
    } catch (e) {
      return [];
    }
  },
};

export const useCustomerRolesQuery = (options = {}) => {
  const { accessToken } = useMyUser();

  return useQuery({
    queryKey: getCustomerRoles.queryKey(),
    queryFn: getCustomerRoles.queryFn(accessToken),
    placeholderData: [],
    ...options,
  });
};

const getBottleTiers = {
  queryKey: () => [QKEY.BOTTLE_TIER],
  queryFn: (accessToken) => async () => {
    try {
      return await sendCall("barrels/GetAllActiveBottlesTiers/", "GET", {
        accessToken,
      }).then((res) => res.result);
    } catch (e) {
      return [];
    }
  },
};

export const useBottleTiersQuery = (options = {}) => {
  const { accessToken } = useMyUser();

  return useQuery({
    queryKey: getBottleTiers.queryKey(),
    queryFn: getBottleTiers.queryFn(accessToken),
    staleTime: minutes(1),
    placeholderData: [],
    ...options,
  });
};

export const usePrefetchBottleTiers = () => {
  const queryClient = useQueryClient();
  const accessToken = useMyUser({ maybeNull: true })?.accessToken;

  useEffect(() => {
    if (!accessToken) return;
    queryClient.prefetchQuery({
      queryKey: getBottleTiers.queryKey(),
      queryFn: getBottleTiers.queryFn(accessToken),
      cacheTime: Infinity,
    });
  }, [queryClient, accessToken]);
};

/**
 * @deprecated use src/app/api/queries_to_rename_later.ts
 */
const getWhatsNew = {
  queryKey: () => [QKEY.WHATS_NEW],
  queryFn: (accessToken) => async () => {
    try {
      return await sendCall("statistics/GetWhatsNew/", "GET", {
        accessToken,
      })
        .then((res) => res.result || [])
        .then((auctions) =>
          auctions.sort((a, b) => {
            const idA = a.id || a.auction.id;
            const idB = b.id || b.auction.id;
            return idA < idB ? 1 : -1;
          }),
        );
    } catch (e) {
      return [];
    }
  },
};

/**
 * 
 * @param {*} options 
 * @deprecated use src/app/api/queries_to_rename_later.ts
 * @returns 
 */
export const useGetWhatsNewQuery = (options = {}) => {
  const { accessToken } = useMyUser();

  return useQuery({
    queryKey: getWhatsNew.queryKey(),
    queryFn: getWhatsNew.queryFn(accessToken),
    ...options,
  });
};

const getBanners = {
  queryKey: (isMobile) => [QKEY.SPONSOR, isMobile],
  queryFn: (isMobile) => async () => {
    return await sendCall("v2/sponsor/banners/", "GET", {
      limit: 30,
      desktop: !isMobile,
    })
      .then((res) => res.result || [])
      .catch(() => []);
  },
};

export const useGetBannersQuery = (options = {}) => {
  const isMobile = useIsMobile();

  return useQuery({
    queryKey: getBanners.queryKey(isMobile),
    queryFn: getBanners.queryFn(isMobile),
    placeholderData: [],
    ...options,
  });
};

const getExperienceList = {
  queryKey: () => [QKEY.EXPERIENCES],
  queryFn: () => async () => {
    return await sendCall("v2/events/list/", "GET", {})
      .then((res) => res.result || [])
      .catch(() => []);
  },
};

export const useGetExperiencesListQuery = () => {
  return useQuery({
    queryKey: getExperienceList.queryKey(),
    queryFn: getExperienceList.queryFn(),
    placeholderData: [],
  });
};

const getProvenanceFromSku = {
  queryKey: (sku) => [QKEY.PROVENANCE, sku],
  queryFn: (sku) => async () => {
    return upfetch({
      url: `products/bottles/viewBySku/`,
      params: { sku },
      schema: apiProductSchema,
    }).then((comp) => new Product(comp));
  },
};

export const useProvenanceFromSkuQuery = (sku, options = {}) => {
  return useQuery({
    queryKey: getProvenanceFromSku.queryKey(sku),
    queryFn: getProvenanceFromSku.queryFn(sku),
    enabled: !!sku,
    ...options,
  });
};

const getProvenanceFromSerial = {
  queryKey: (serial) => [QKEY.PROVENANCE, serial],
  queryFn: (serial) => async () => {
    return upfetch({
      url: "products/serials/findOrFalse/",
      params: { serial_product: serial },
    }).then((res) => new SerialProduct(res));
  },
};

export const useProvenanceFromSerialQuery = (serial, options = {}) => {
  return useQuery({
    queryKey: getProvenanceFromSerial.queryKey(serial),
    queryFn: getProvenanceFromSerial.queryFn(serial),
    enabled: !!serial,
    ...options,
  });
};
