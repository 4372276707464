import { DesktopCellContentCurrency } from "./cell-content-currency";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { Tooltip } from "../../tooltip";
import { ReactNode, useId } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
type Props = {
  total?: number;
  details: Array<{ amount?: number; label?: ReactNode }>;
  paid?: boolean | number | string; // TODO: boolean only
};

export const DesktopCellContentTotalPrice = ({
  total,
  details,
  paid = true,
}: Props) => {
  const id = useId();
  const _paid = typeof paid === "string" ? paid !== "0" : !!paid;
  const _total =
    total ?? details.reduce((tot, { amount = 0 }) => tot + amount, 0);

  return (
    <DesktopCellContentCurrency
      amount={_total}
      rightElement={
        <Tooltip
          position="bottom"
          content={
            <div style={{ textAlign: "left" }}>
              {!_paid && (
                <div
                  style={{
                    color: "#e33f3f",
                    fontWeight: 600,
                    fontSize: "12px",
                    paddingBottom: "3px",
                  }}
                >
                  Not Paid
                </div>
              )}
              {details.map(({ amount, label }, i) => (
                <div key={`${id}-${i}`}>
                  {formatCurrency(amount)} {label}
                  {details[i + 1]?.amount !== undefined && " +"}
                </div>
              ))}
            </div>
          }
        >
          {/* The div is needed to display the tooltip because FaRegQuestionCircle does not forward ref */}
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaRegQuestionCircle
              style={{
                color: _paid ? "#b1b9bd" : "tomato",
                marginLeft: "10px",
                fontSize: "15px",
              }}
            />
          </div>
        </Tooltip>
      }
    />
  );
};
