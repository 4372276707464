import styles from "./error-message.module.css";
import {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useEffect,
} from "react";
import { useFieldCtx } from "./field";
import { useForm } from "./form";
import { useDomId } from "src/app/hooks/useDomId";
import { cn } from "src/app/utils/cn";
import { ImWarning } from "react-icons/im";

const defaultComponent = "div" as const;
const defaultRenderChildren = (error: string | undefined) => (
  <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
    <ImWarning style={{ transform: "translateY(-1px)" }} />
    {error}
  </div>
);

type Props<TComponent extends ElementType<any>> =
  ComponentPropsWithoutRef<TComponent> & {
    forceMount?: boolean;
    as?: TComponent;
    name?: never;
    id?: never;
    // can't name it "children" because it breaks type inference
    renderChildren?: (error: string | undefined) => ReactNode;
  };

export const ErrorMessage = <
  TComponent extends ElementType<any> = typeof defaultComponent,
>({
  forceMount = false,
  as,
  className,
  renderChildren = defaultRenderChildren,
  ...props
}: Props<TComponent>) => {
  const { name, setErrorMsgIds } = useFieldCtx();
  const ctx = useForm();
  const error = ctx.getFieldError(name)?.trim();
  const Comp = as || defaultComponent;
  const id = useDomId();
  const isEmpty = !error || !ctx.getFieldTouched(name);

  useEffect(() => {
    if (isEmpty) return;
    setErrorMsgIds((ids) => [...ids, id]);
    return () => setErrorMsgIds((ids) => ids.filter((_id) => _id !== id));
  }, [setErrorMsgIds, id, isEmpty]);

  if (!forceMount && isEmpty) return null;

  return (
    <Comp
      id={id}
      role="alert"
      className={cn(className, styles.root)}
      {...props}
    >
      {renderChildren(error)}
    </Comp>
  );
};
