import { ComponentProps, forwardRef } from "react";
import styles from "./index.module.css";
import { cn } from "src/app/utils/cn";

type Props = Omit<ComponentProps<"textarea">, "ref">;

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className = "", ...props }, ref) => {
    return (
      <textarea ref={ref} className={cn(styles.root, className)} {...props} />
    );
  },
);
