import { ShipmentV1 } from "src/app/models/ShipmentV1";
import styles from "./subrow.module.css";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";

export const ShipmentsTableSubrow = ({ row }: { row: ShipmentV1 }) => {
  const isMobile = useIsMobile();

  if (!row.lots?.length) return null;

  return (
    <tr className={cn(styles.tableWrapper, isMobile && styles.mobile)}>
      <td className={styles.tableContainer} colSpan={100}>
        <table className={styles.table}>
          <thead className={styles.tableHead}>
            <tr className={styles.tableRow}>
              <th className={`${styles.tableCell} ${styles.lotHeader}`}>Lot</th>
              <th className={`${styles.tableCell} ${styles.quantityHeader}`}>
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {row.lots.map((row, index) => (
              <tr key={index} className={styles.tableRow}>
                <td className={`${styles.tableCell} ${styles.lotData}`}>
                  {row.description}
                </td>
                <td className={`${styles.tableCell} ${styles.quantityData}`}>
                  {row.quantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};
