import styles from "./drawer-payment-request.module.css";
import { ButtonCopyToClipboard } from "../../../LayoutSide/components/common/controls/button-copy-to-clipboard";
import { ReactComponent as IconMail } from "src/media/svg/icons/Communication/Sending mail.svg";
import { ReactComponent as IconCheckSrc } from "src/media/svg/icons/Navigation/Check.svg";
import { OutstandingPaymentV1 } from "src/app/models/OutstandingPaymentV1";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import {
  useMarkOutstandingPaymentAsPaidMutation,
  useSendMailInfoMutation,
} from "src/app/api/mutations_new_to_rename";
import { Button } from "src/app/components/button";
import { Spacer } from "src/app/components/spacer";

type Props = {
  selectedData: OutstandingPaymentV1[];
  totalToPay: number;
  onClose: () => void;
};

export const DrawerPaymentRequest = ({
  selectedData,
  totalToPay,
  onClose,
}: Props) => {
  const config = useSystemConfig();
  const user = useMyUser();
  const alertPopup = useAlertPopup();

  const markPaymentAsPaid = useMarkOutstandingPaymentAsPaidMutation({
    onSuccess: () => {
      alertPopup.success({
        title: "Auctions mark as paid",
        onClose,
      });
    },
    onError: () => {
      alertPopup.error({
        title: "Error on mark paid Auction",
        onClose,
      });
    },
  });

  const sendMailInfo = useSendMailInfoMutation({
    onSuccess: () => {
      alertPopup.success({
        title: "Email sent",
        onClose,
      });
    },
    onError: () => {
      alertPopup.error({
        title: "Error on Send email",
        content: "Please try again",
        onClose,
      });
    },
  });

  const auctions_participant = selectedData.map((item) => {
    if (item.cellar_type === "lots") {
      return "lot-" + item.id_auction_participant;
    } else {
      return "barrel-" + item.id_barrel_auction_participant;
    }
  });

  const purpose = `Customer ${user.fullname} ID: ${user.id} - Payment for offers "${auctions_participant.toString()}"`;

  const onMarkAsPaid = () => {
    if (!selectedData.length) {
      alertPopup.error({
        title: "No row selected",
      });

      return;
    }

    alertPopup.prompt({
      title: "Are you sure?",
      content: "You won't be able to revert this!",
      actionButton: {
        label: "Yes, confirm it",
        onClick: () =>
          markPaymentAsPaid.mutate({
            auctions_participant: selectedData,
          }),
      },
      closeButton: { label: "No, Cancel" },
      onClose: () => {},
    });
  };

  const onSendMailInfo = () => {
    if (!selectedData.length) {
      alertPopup.error({
        title: "No row selected",
      });

      return;
    }

    return sendMailInfo.mutate({
      purpose: purpose,
      import: totalToPay,
      dataBankTransfer: config?.dataBankTransfer,
    });
  };

  return (
    <div>
      <Spacer size={24} />
      <div>
        <h4 className={styles.title}>Details</h4>
        <div className={styles.info}>
          <div className={styles.infoLabel}>Import:</div>
          <div className={styles.infoValue}>
            <span>{totalToPay}€</span>
            <ButtonCopyToClipboard text={totalToPay} />
          </div>
        </div>
      </div>
      <Spacer size={8} />
      <hr />
      <Spacer size={8} />

      <h4 className={styles.title}>Instructions Bank Transfer</h4>
      <div className={styles.infoWrap}>
        <div className={styles.info}>
          <div className={styles.infoLabel}>Beneficiary:</div>
          <div className={styles.infoValue}>
            <span>{config?.dataBankTransfer.Beneficiary}</span>
            <ButtonCopyToClipboard
              text={config?.dataBankTransfer.Beneficiary}
            />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.infoLabel}>IBAN:</div>
          <div className={styles.infoValue}>
            <span>{config?.dataBankTransfer.IBAN}</span>
            <ButtonCopyToClipboard text={config?.dataBankTransfer.IBAN} />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.infoLabel}>BIC:</div>
          <div className={styles.infoValue}>
            <span>{config?.dataBankTransfer.BIC}</span>
            <ButtonCopyToClipboard text={config?.dataBankTransfer.BIC} />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.infoLabel}>Beneficiary address:</div>
          <div className={styles.infoValue}>
            <span>{config?.dataBankTransfer.BeneficiaryAddress}</span>
            <ButtonCopyToClipboard
              text={config?.dataBankTransfer.BeneficiaryAddress}
            />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.infoLabel}>Bank / Payment institution:</div>
          <div className={styles.infoValue}>
            <span>{config?.dataBankTransfer.PaymentInstitution}</span>
            <ButtonCopyToClipboard
              text={config?.dataBankTransfer.PaymentInstitution}
            />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.infoLabel}>
            Bank / Payment institution address:
          </div>
          <div className={styles.infoValue}>
            <span>{config?.dataBankTransfer.PaymentInstitutionAddress}</span>
            <ButtonCopyToClipboard
              text={config?.dataBankTransfer.PaymentInstitutionAddress}
            />
          </div>
        </div>
      </div>

      <hr />
      <div className={styles.actionButtons}>
        <Button
          icon={<IconCheckSrc className={styles.buttonIcon} />}
          label={<span>Mark as paid</span>}
          disabled={sendMailInfo.isLoading}
          isLoading={markPaymentAsPaid.isLoading}
          onClick={() => onMarkAsPaid()}
        />
        <Button
          icon={<IconMail className={styles.buttonIcon} />}
          label={<span>Send Information Via Email</span>}
          disabled={markPaymentAsPaid.isLoading}
          isLoading={sendMailInfo.isLoading}
          onClick={() => onSendMailInfo()}
        />
      </div>
    </div>
  );
};
