import styles from "./styles.module.css";
import { useState } from "react";
import { RadioGroupAddress } from "src/app/components/shipment-form/radiogroup-address";
import { Drawer } from "src/app/components/drawer";
import { AddressForm } from "src/app/components/address-form/address-form";
import { ShipmentV1 } from "src/app/models/ShipmentV1";
import { AddressV1 } from "src/app/models/Address";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "src/app/components/button";
import { useCombineShipmentsMutation } from "src/app/api/shipments";
import { DisplaySelectedAddress } from "src/app/components/shipment-form/display-selected-address";
import { useMyUser } from "src/app/hooks/useMyUser";

export function CombineShipmentForm({
  shippingsSelected,
  closeDrawer,
}: {
  closeDrawer: () => void;
  shippingsSelected: ShipmentV1[];
}) {
  const user = useMyUser();
  const isOk =
    shippingsSelected.length > 1 &&
    shippingsSelected.every((el) => el.status_shipping < 2);

  const [addressToEdit, setAddressToEdit] = useState<AddressV1 | undefined>(
    undefined,
  );
  const [selectedAddress, setSelectedAddress] = useState<AddressV1 | undefined>(
    isOk && user.addressShipping.length > 0
      ? user.addressShipping.find((item) => item.selected) ||
          user.addressShipping[0]
      : shippingsSelected[0].shipping_address,
  );
  const [showAddressFormModal, setShowAddressFormModal] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);

  const queryClient = useQueryClient();

  const combineShipmentsMutations = useCombineShipmentsMutation();

  return (
    <>
      {!!showAddressFormModal && (
        <Drawer
          title={
            addressToEdit
              ? "Edit Address for shipping"
              : "Add Address for shipping"
          }
          position="right"
          width={570}
          onClosed={() => {
            setShowAddressFormModal(false);
            setAddressToEdit(undefined);
          }}
          render={({ close }) => (
            <AddressForm
              onSuccess={() => {
                close();
                setAddressToEdit(undefined);
              }}
              onCancel={() => {
                close();
                setAddressToEdit(undefined);
              }}
              addressType="shipping"
              initialAddress={addressToEdit}
            />
          )}
        />
      )}
      <div className={styles.root}>
        {isOk ? (
          <>
            <div className={styles.section}>
              <h3>Lots</h3>
              <div>
                <div className={styles.container}>
                  <div style={{ fontWeight: "600" }}>Name</div>
                  <div style={{ fontWeight: "600" }}>Quantity Available</div>
                </div>
                {shippingsSelected.map((shipping) =>
                  shipping.lots?.map((lot) => (
                    <div key={lot.id} className={styles.container}>
                      <div className={styles.description}>
                        {lot.description}
                      </div>
                      <input
                        disabled
                        className={styles.quantity}
                        value={lot.quantity}
                      />
                    </div>
                  )),
                )}
              </div>
            </div>

            {!!isEditingAddress && (
              <RadioGroupAddress
                onChange={(address) => {
                  setSelectedAddress(address);
                }}
                selectedAddress={selectedAddress}
                onCreate={() => setShowAddressFormModal(true)}
                onEdit={(address) => {
                  setAddressToEdit(address);
                  setShowAddressFormModal(true);
                }}
                onConfirm={() => setIsEditingAddress(false)}
              />
            )}

            {!!selectedAddress && !isEditingAddress && (
              <div className={styles.section}>
                <h3>Selected Address</h3>
                <DisplaySelectedAddress address={selectedAddress} />

                <div style={{ display: "flex", gap: "20px" }}>
                  <Button
                    isLoading={!!combineShipmentsMutations.isLoading}
                    label="Combine Shippings"
                    disabled={!!combineShipmentsMutations.isLoading}
                    onClick={() => {
                      combineShipmentsMutations
                        .mutateAsync({
                          selectedAddress: selectedAddress,
                          selectedLots: shippingsSelected.flatMap(
                            (shipping) => shipping.lots || [],
                          ),
                          selectedShippings: shippingsSelected,
                        })
                        .then(() => {
                          queryClient.invalidateQueries();
                          closeDrawer();
                        });
                    }}
                  />
                  <Button
                    label="Edit Address"
                    onClick={() => setIsEditingAddress(true)}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <div />
            <div>Internal Error</div>
          </div>
        )}
      </div>
    </>
  );
}
