import logo from "./assets/logo-crurated.svg";
import gerardBasset from "./assets/gerard-basset.svg";
import styles from "./Header.module.css";
import { ButtonFullScreen } from "./button-full-screen";
export default function Header({ totalRaised }) {
  return (
    <nav className={styles.containerHeader}>
      <div className={styles.containerHeaderElements}>
        <div className={styles.imageContainer}>
          <img src={logo} className={styles.logoCrurated} alt="LOGO" />
          <div className={styles.imageContainerDivider}></div>
          <img src={gerardBasset} className={styles.gerardBassets} alt="LOGO" />
        </div>
        <div className={styles.fullScreen}>
          <ButtonFullScreen />
        </div>
        <div>
          <p className={styles.totalRaised}>
            Total Raised:{" "}
            <span className={styles.totalPrice}>
              {totalRaised?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} €
            </span>
          </p>
        </div>
      </div>
    </nav>
  );
}
