import styles from "./index.module.css";
import { CSSProperties, useState } from "react";
import { addTimezoneOffset } from "src/app/utils/addTimezoneOffset";
import { cn } from "../../utils/cn";
import { useCountdown } from "../../hooks/useCountdown";

type TargetDate = Date | string | undefined | null;

type Clock = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type Props = {
  targetDate: TargetDate;
  className?: string;
  style?: CSSProperties;
};

export const Countdown = ({
  targetDate,
  className = "",
  style = {},
}: Props) => {
  const [clock, setClock] = useState<Clock>(getClock(targetDate, Date.now()));

  useCountdown({
    to: targetDate,
    onTick: () => {
      setClock(getClock(targetDate, Date.now()));
    },
  });

  if (!targetDate) return null;

  return (
    <div style={style} className={cn(styles.root, className)}>
      <div>
        <span className={styles.number}>{twoChars(clock.days)}</span>
        <span className={styles.unit}>D</span>
      </div>
      <div>
        <span className={styles.number}>{twoChars(clock.hours)}</span>
        <span className={styles.unit}>H</span>
      </div>
      <div>
        <span className={styles.number}>{twoChars(clock.minutes)}</span>
        <span className={styles.unit}>M</span>
      </div>
      <div>
        <span className={styles.number}>{twoChars(clock.seconds)}</span>
        <span className={styles.unit}>S</span>
      </div>
    </div>
  );
};

const msForOneSecond = 1000;
const msForOneMinute = msForOneSecond * 60;
const msForOneHour = msForOneMinute * 60;
const msForOneDay = msForOneHour * 24;

const emptyClock: Clock = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

function getClock(targetDate: TargetDate, now: number) {
  if (!targetDate) {
    return emptyClock;
  }
  const distanceMs = new Date(addTimezoneOffset(targetDate)).getTime() - now;

  if (distanceMs <= 0) {
    return emptyClock;
  }

  return {
    days: Math.max(0, Math.floor(distanceMs / msForOneDay)),
    hours: Math.max(0, Math.floor((distanceMs / msForOneHour) % 24)),
    minutes: Math.max(0, Math.floor((distanceMs / msForOneMinute) % 60)),
    seconds: Math.max(0, Math.floor((distanceMs / msForOneSecond) % 60)),
  };
}

function twoChars(num: number) {
  return num < 10 ? `0${num}` : `${num}`;
}
