import { Button } from "src/app/components/button";
import styles from "./index.module.css";
import { A } from "src/app/components/a";
import { ReactComponent as Logo } from "src/media/logos/logo.svg";
import { usePageTitleStore } from "src/app/hooks/usePageTitle";
import { MyEarnings } from "../my-earnings";
import { DevBadges } from "../dev-badges";
import { useLocation } from "react-router-dom";
import { path } from "src/app/routes/path";

export const TopbarMobile = () => {
  const pageTitle = usePageTitleStore((store) => store.pageTitle);
  const pathname = useLocation().pathname;

  return (
    <header className={styles.root}>
      <div className={styles.inner}>
        <Button
          as={A}
          to="/"
          className={styles.logo}
          tooltip="Go to Dashboard"
          icon={<Logo />}
          variant="ghost"
        />

        <h1
          className={styles.pageTitle}
          dangerouslySetInnerHTML={{ __html: pageTitle }}
        />

        <DevBadges />

        {pathname === path.dashboard() && (
          <MyEarnings style={{ paddingLeft: "4px" }} />
        )}
      </div>
    </header>
  );
};
