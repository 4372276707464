/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { CardProps } from ".";
import { useCardCtx } from "../../card-context";
import { Form } from "../../../auction-card/lg/atoms.form";
import AuctionDetails from "../auction-details";
import { Root } from "../atoms/root";

export const CardForSingleLot = ({
  className = "",
  cardId,
}: Omit<CardProps, "auction">) => {
  const cardCtx = useCardCtx();
  if (!cardCtx.auction) return null;

  return (
    <Root className={className} cardId={cardId}>
      <AuctionDetails />
      <Form.ForSingleLot />
    </Root>
  );
};
