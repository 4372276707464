/* eslint-disable @typescript-eslint/ban-types */
export type ValueOf<T> = T[keyof T];

export type NoInfer<T> = [T][T extends any ? 0 : never];

export function inferDataType<TData = unknown>(): TData {
  return null as any;
}

export type MaybePromise<T> = T | Promise<T>;

export type MaybeArray<T> = T | T[];

export type KeysOfUnion<ObjectType> = ObjectType extends unknown
  ? keyof ObjectType
  : never;
export type UnionToIntersection<T> = Prettify<
  (T extends any ? (x: T) => any : never) extends (x: infer R) => any
    ? R
    : never
>;

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};
