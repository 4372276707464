import { TYPE_PRODUCT } from "src/app/const";
import { ApiAuctionOfBarrel, AuctionOfBarrel } from "./AuctionOfBarrel";
import { ApiAuctionOfLot, AuctionOfLot } from "./AuctionOfLot";

export type Auction = AuctionOfLot | AuctionOfBarrel;
export type ApiAuction = ApiAuctionOfLot | ApiAuctionOfBarrel;

export function isAuctionOfLot(auction: Auction): auction is AuctionOfLot;
export function isAuctionOfLot(auction: ApiAuction): auction is ApiAuctionOfLot;
export function isAuctionOfLot(
  auction: Auction | ApiAuction,
): auction is AuctionOfLot | ApiAuctionOfLot {
  return auction.type_product === TYPE_PRODUCT.LOT;
}

export const isAuctionOfBarrel = (
  auction: Auction | ApiAuction,
): auction is AuctionOfBarrel | ApiAuctionOfBarrel => {
  return auction.type_product === TYPE_PRODUCT.BARREL;
};
