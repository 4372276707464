// type Props = {}
import styles from "./signup-stepper.module.css";
import { useHistory, useParams } from "react-router-dom";
import { TierName } from "src/app/const";
import { FaChevronLeft } from "react-icons/fa6";

export const SignupStepper = ({
  step,
  description,
}: {
  step: string | number;
  description: string;
}) => {
  const history = useHistory();
  const { tier } = useParams<{ tier: TierName }>();

  const currentStep = step;
  const maxSteps = tier === "explorer" ? 2 : 4;

  return (
    <div className={styles.root}>
      {+step > 1 && (
        <button
          id="signup-stepper"
          onClick={() =>
            currentStep === 2
              ? history.push({
                  pathname: "/signup",
                  search: history.location.search,
                })
              : history.goBack()
          }
          type="button"
        >
          <FaChevronLeft />
        </button>
      )}
      <label htmlFor="signup-stepper">
        Step 0{currentStep} / 0{maxSteps} - {description}
      </label>
    </div>
  );
};
