import { ComponentProps, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { Button } from "../button";
import { path } from "src/app/routes/path";
import { A } from "../a";

type Props = {
  children: ReactNode;
};

export const ErrorBoundary = ({ children }: Props) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>
  );
};

function Fallback({ resetError }: ComponentProps<Sentry.FallbackRender>) {
  return (
    <div>
      <div style={{ marginBottom: "1.5rem", fontSize: "1.5rem" }}>
        An unexpected error has occured
      </div>
      <div style={{ display: "inline-flex", flexWrap: "wrap", gap: "0.5rem" }}>
        <Button
          style={{ flexGrow: 1 }}
          label="Try Again"
          onClick={() => resetError()}
        />
        <Button
          as={A}
          style={{ flexGrow: 1 }}
          to={path.dashboard()}
          label="Go back to the homepage"
          variant="outlined"
        />
      </div>
    </div>
  );
}
