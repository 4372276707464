import styles from "./index.module.css";
import { MyEarnings } from "src/app/layout.private/my-earnings";
import { TierCardLink } from "./tier-card-link";
import { Title } from "./title";
import { DevBadges } from "../dev-badges";
import { MenuUser } from "./menu-user";
import { Wallet } from "./wallet";
import { FetchingIndicator } from "../fetching-indicator";

export function TopbarDesktop() {
  return (
    <nav className={styles.root}>
      <div className={styles.inner}>
        <TierCardLink />
        <Title />
        <DevBadges />

        <MyEarnings style={{ marginLeft: "auto", marginRight: "1rem" }} />
        <MenuUser />

        <Wallet />
      </div>

      <FetchingIndicator />
    </nav>
  );
}
