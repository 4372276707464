import styles from "./render-icon.module.css";
import { ReactNode } from "react";
import { SpinnerSm } from "../spinner-sm";
import { cn } from "src/app/utils/cn";

export function RenderIcon({
  icon,
  isLoading,
}: {
  icon?: ReactNode;
  isLoading?: boolean;
}) {
  if (!icon && !isLoading) {
    return null;
  }

  if (!icon && isLoading) {
    return <SpinnerSm />;
  }

  // prevents layout shift if the spinner has a different size than the icon
  return (
    <div className={cn(styles.root, isLoading && styles.loading)}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.spinner}>
        <SpinnerSm />
      </div>
    </div>
  );
}
