import styles from "./index.module.css";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import {
  CSSProperties,
  ComponentProps,
  ReactNode,
  useRef,
  MutableRefObject,
  useLayoutEffect,
} from "react";
import { cn } from "src/app/utils/cn";

type Props = {
  id?: string;
  viewportId?: string;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  hideX?: boolean;
  viewportStyle?: CSSProperties;
  viewportClassName?: string;
  scrollbarClassName?: string;
  scrollbarMargin?: number;
  scrollbarPadding?: number;
  scrollbarRadius?: number;
  scrollbarSize?: number;
  scrollbarStyle?: CSSProperties;
  type?: ComponentProps<typeof ScrollArea.Root>["type"];
  scrollHideDelay?: ComponentProps<typeof ScrollArea.Root>["scrollHideDelay"];
  overscrollBehavior?: "auto" | "contain" | "none";
};

export const Scrollable = ({
  id,
  viewportId,
  children,
  style = {},
  className = "",
  hideX = false,
  viewportStyle = {},
  viewportClassName = "",
  scrollbarClassName = "",
  scrollbarMargin = 2,
  scrollbarPadding = 0,
  scrollbarRadius = 2,
  scrollbarSize = 4,
  scrollbarStyle = {},
  type,
  scrollHideDelay,
  overscrollBehavior = "auto",
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const hideCorner = hideX;

  useCssVariable(ref, "--scrollbar-margin", `${scrollbarMargin}px`);
  useCssVariable(ref, "--scrollbar-padding", `${scrollbarPadding}px`);
  useCssVariable(ref, "--scrollbar-size", `${scrollbarSize}px`);
  useCssVariable(ref, "--scrollbar-radius", `${scrollbarRadius}px`);
  useCssVariable(ref, "--corner-opacity", scrollbarMargin ? 0 : 1);

  return (
    <ScrollArea.Root
      id={id}
      ref={ref}
      style={style}
      className={cn(styles.root, className)}
      type={type}
      scrollHideDelay={scrollHideDelay}
    >
      <ScrollArea.Viewport
        id={viewportId}
        className={cn(styles.viewport, viewportClassName)}
        style={{ overscrollBehavior, ...viewportStyle }}
      >
        {children}
      </ScrollArea.Viewport>

      <ScrollArea.Scrollbar
        forceMount
        orientation="vertical"
        className={cn(styles.scrollbar, scrollbarClassName)}
        data-hasmargin={!!scrollbarMargin}
        data-nocorner={hideCorner}
        style={scrollbarStyle}
      >
        <ScrollArea.Thumb className={styles.thumb} />
      </ScrollArea.Scrollbar>

      {!hideX && (
        <ScrollArea.Scrollbar
          forceMount
          orientation="horizontal"
          className={cn(styles.scrollbar, scrollbarClassName)}
          data-hasmargin={!!scrollbarMargin}
          data-nocorner={hideCorner}
          style={scrollbarStyle}
        >
          <ScrollArea.Thumb className={styles.thumb} />
        </ScrollArea.Scrollbar>
      )}

      {!hideCorner && <ScrollArea.Corner className={styles.corner} />}
    </ScrollArea.Root>
  );
};

const useCssVariable = (
  ref: MutableRefObject<any>,
  name: string,
  value: string | number,
) => {
  useLayoutEffect(() => {
    ref.current?.style.setProperty(name, value);
  }, [ref, name, value]);
};
