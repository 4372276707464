/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import SVG from "react-inlinesvg";
import homeSrc from "src/media/svg/icons/Home/Picture.svg";
import Moment from "moment";
import {
  Timezone,
  sendCall,
  setDataTimeZone,
} from "../../../../../../config/Utility";
import { useGetExperiencesListQuery } from "src/app/api/queries";
import { Tooltip } from "src/app/components/tooltip";
import { ID_AUCTION_TYPE } from "src/app/const";

// Description for auction
export const ActionAuction = ({
  user,
  userProfile,
  typeAction = "CREATE",
  optionsLot,
  dataCustomerRoles,
  dataAllCustomersActive,
  dataAuctionType,
  participant,
  auction,
  setRefresh,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [experienceSelected, setExperienceSelected] = useState(
    auction.experience?.id || 0,
  );
  const [noLotSelected, setNoLotSelected] = useState(null);
  const [next, setNext] = useState(null);

  // variabili che pescano la data odierna come inizio asta e 7 giorni dopo per la deadline.
  const today = new Date();
  const week = new Date();
  week.setDate(today.getDate() + 7);

  const { data: experienceList } = useGetExperiencesListQuery();

  const [start_date] = useState(Moment(today).format("YYYY-MM-DD HH:mm:ss"));
  const [end_date] = useState(Moment(week).format("YYYY-MM-DD HH:mm:ss"));

  const [maxQuantityChosen, setMaxQuantityChosen] = useState(
    typeAction === "RESELL" || typeAction === "DONATION"
      ? participant.quantity_chosen
      : typeAction === "EDIT" ||
          typeAction === "CLONE" ||
          typeAction === "CREATE-PRIVATE-SALES"
        ? auction.quantity
        : 0,
  );

  switch (typeAction) {
    case "CREATE":
      var pathApi = "auctions/";
      var fileApi = "Create";
      // var genericText = "Create";
      var genericText2 = "Created";
      break;
    case "EDIT":
      pathApi = "auctions/";
      fileApi = "Create";
      // genericText = "Edit";
      genericText2 = "Edited";
      break;
    case "CLONE":
      pathApi = "auctions/";
      fileApi = "Create";
      // genericText = "Clone";
      genericText2 = "Created";
      break;
    case "CREATE-PRIVATE-SALES":
      pathApi = "auctions/";
      fileApi = "Create";
      // genericText = "Create";
      genericText2 = "Created";
      break;
    case "RESELL":
      pathApi = "marketplace/";
      fileApi = "ResellAuction";
      // genericText = "Resell";
      genericText2 = "Sell";
      break;
    case "DONATION":
      pathApi = "marketplace/";
      fileApi = "ResellAuction";
      // genericText = "Resell";
      genericText2 = "Sell";
      break;
    case "INFO":
      fileApi = "";
      // genericText = "Detail";
      genericText2 = "Detail";

      break;
    default:
      pathApi = "auctions/";
      fileApi = "Create";
      // genericText = "Create";
      genericText2 = "Created";
      break;
  }

  const initialValues = {
    id_auction: auction.id_auction ? auction.id_auction : 0,
    id_user:
      typeAction === "EDIT"
        ? auction.id_user
          ? auction.id_user
          : userProfile.id_user
        : userProfile.id_user,
    id_lot: auction.id_lot ? auction.id_lot : 0,
    lot_chosen: auction.lots ? [auction.lots] : [],
    max_quantity: auction.maxQuantity ? auction.maxQuantity : 1,
    increment_selected: auction.increment_selected
      ? auction.increment_selected
      : 10,
    description_auction: auction.descriptionAuction
      ? auction.descriptionAuction
      : "",
    short_description_auction: auction.shortDescriptionAuction
      ? auction.shortDescriptionAuction
      : "",
    more_information: auction.moreInformation ? auction.moreInformation : "",
    description_link: auction.description_link ? auction.description_link : "",
    catalogue: auction.catalogue ? auction.catalogue : "",
    initial_price: auction.initialPrice ? auction.initialPrice : 0,
    crurated_estimated_market_price: auction.crurated_estimated_market_price
      ? auction.crurated_estimated_market_price
      : 0,
    start_data: auction.startDataInput
      ? typeAction === "CREATE-PRIVATE-SALES"
        ? start_date
        : setDataTimeZone(auction.startDataInput, "YYYY-MM-DDTHH:mm:ss")
      : start_date,
    finish_data: auction.finishDataInput
      ? typeAction === "CREATE-PRIVATE-SALES"
        ? end_date
        : setDataTimeZone(auction.finishDataInput, "YYYY-MM-DDTHH:mm:ss")
      : end_date,
    id_auction_type: auction.id_auction_type
      ? typeAction === "CREATE-PRIVATE-SALES"
        ? 3
        : auction.id_auction_type
      : 0,
    tech_fee: auction.tech_fee ? auction.tech_fee : 0,
    is_enabled_tech_fee: auction.is_enabled_tech_fee
      ? auction.is_enabled_tech_fee
      : 0,
    disable_for: auction.disabled_for_input
      ? typeAction === "CREATE-PRIVATE-SALES"
        ? [0]
        : auction.disabled_for_input
      : [],
    visible_for: auction.visible_for_input
      ? typeAction === "CREATE-PRIVATE-SALES"
        ? [15, 14, 13, 12, 3, 2, 1]
        : auction.visible_for_input
      : [],
    for_user: auction.for_user_input
      ? typeAction === "CREATE-PRIVATE-SALES"
        ? [participant.id_user]
        : auction.for_user_input
      : [0],
    for_user_multiselect: auction.for_user_multiselect
      ? typeAction === "CREATE-PRIVATE-SALES"
        ? [
            {
              id_user: participant.id_user,
              fullname: participant.user.fullname,
            },
          ]
        : JSON.parse(auction.for_user_multiselect)
      : [],
    timezone: Timezone(),
    id_event: +experienceSelected || "no events included",
    is_checked_individual:
      auction.for_user_input && parseInt(auction.for_user_input[0]) === 0
        ? typeAction === "CREATE-PRIVATE-SALES"
          ? 1
          : 0
        : 1,
    is_checked_en_primeur: auction.is_en_primeur ? auction.is_en_primeur : 0,

    // CREATE-PRIVATE-SALES || RESELL || DONATION
    id_auction_participant:
      typeAction === "CREATE-PRIVATE-SALES" ||
      typeAction === "RESELL" ||
      typeAction === "DONATION"
        ? participant.id_auction_participant
        : 0,

    // RESELL || DONATION
    resell_id_auction_participant:
      typeAction === "RESELL" || typeAction === "DONATION"
        ? participant.id_auction_participant
        : 0,
    // CREATE-PRIVATE-SALES || RESELL || DONATION
    id_conversion:
      typeAction === "CREATE-PRIVATE-SALES" ||
      typeAction === "RESELL" ||
      typeAction === "DONATION"
        ? participant.id_conversion
        : 0,

    // RESELL || DONATION
    resell_id_conversion:
      typeAction === "RESELL" || typeAction === "DONATION"
        ? participant.id_conversion
        : 0,
    is_resell: typeAction === "RESELL" || typeAction === "DONATION" ? 1 : 0,
    deposit:
      typeAction === "RESELL" || typeAction === "DONATION"
        ? participant.deposit
        : 0,
    id_auction_participant_mp:
      typeAction === "RESELL" || typeAction === "DONATION"
        ? participant.id_auction_participant_mp
        : 0,
    quantity_chosen:
      typeAction === "RESELL" || typeAction === "DONATION"
        ? participant.quantity_chosen
        : typeAction === "EDIT" ||
            typeAction === "CLONE" ||
            typeAction === "CREATE-PRIVATE-SALES"
          ? auction.quantity
          : 0,
    quantity_available:
      typeAction === "RESELL" || typeAction === "DONATION"
        ? participant.quantity_available
        : 0,

    is_charity: auction.is_charity === 1 ? true : false,

    // DONATION
    is_donation: typeAction === "DONATION" ? 1 : 0,
    isSponsored: !!auction.is_sponsored,
    isMultiPurchase: !!auction.is_multi_purchase,
    type_action: typeAction,
    accessToken: user.accessToken,

    terms_and_conditions: auction.terms_and_conditions,
  };

  const validationSchema = Yup.object().shape({
    quantity_chosen: !noLotSelected
      ? Yup.number()
          .required("This field is required")
          .min(1, "It must be greater of 0")
          .max(maxQuantityChosen, `The selected quantities are not available`)
      : "",
    tech_fee: Yup.number()
      .required("This field is required")
      .min(0, "It must be 0 or greater"),
    increment_selected: Yup.number()
      .required("This field is required")
      .min(0, "It must be 0 or greater"),
    max_quantity: !noLotSelected
      ? Yup.number()
          .required("This field is required")
          .min(1, "It must be greater of 0")
          .test({
            name: "max",
            exclusive: false,
            params: {},
            message: "The selected quantities are not available",
            test: function (value) {
              return value <= this.parent.quantity_chosen;
            },
          })
      : "",
    description_auction: Yup.string().required("This field is required"),
    crurated_estimated_market_price: Yup.number()
      .required("This field is required")
      .min(0, "It must be 0 or greater"),
    initial_price: Yup.number()
      .required("This field is required")
      .min(0, "It must be 0 or greater"),
    start_data: Yup.string().required("This field is required"),
    finish_data: Yup.string().test({
      name: "finish_data",
      exclusive: false,
      params: {},
      message: "This field is required",
      test: function (value) {
        if (
          parseInt(this.parent.id_auction_type) === 5 ||
          parseInt(this.parent.id_auction_type) === 6
        ) {
          return 1;
        } else {
          return !value || value === undefined || value === "" ? false : true;
        }
      },
    }),
    id_auction_type: Yup.number()
      .required("This field is required")
      .min(1, "This field is required"),
    visible_for: Yup.array().required("This field is required").min(1),
    disable_for: Yup.array().required("This field is required").min(1),
    for_user: Yup.array().required("This field is required").min(1),
    isSponsored: Yup.boolean(),
    isMultiPurchase: Yup.boolean(),
  });

  useEffect(() => {
    if (auction?.lots || auction?.experience?.id > 0) {
      setNext(true);
      setNoLotSelected(false);
      setExperienceSelected(auction?.id_event);
    }
  }, [auction]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  function onSubmit(values, { setStatus, setSubmitting }) {
    values.id_event = +experienceSelected;
    //var values = JSON.stringify(values, null, 4);
    enableLoading();
    setTimeout(() => {
      values.accessToken = user.accessToken;

      sendCall(pathApi + fileApi + "/", "POST", values)
        .then((result) => {
          disableLoading();

          if (parseInt(result.code) === 200) {
            Swal.fire({
              title: "Auction " + genericText2,
              text: "",
              icon: "success",
              button: "Okay",
              showConfirmButton: false,
              timer: 700,
            }).then(() => {
              setRefresh(true);
              handleClose();
            });
          }

          if (parseInt(result.code) === 500) {
            setStatus("Internal Error");
          }
        })
        .catch(() => {
          setStatus("Internal Error");
        })
        .finally(() => {
          disableLoading();
          setSubmitting(false);
        });
    }, 1000);
  }

  const appendLot = (lotSelected, setFieldValue, values) => {
    let id_lot = 0;
    if (lotSelected.length > 0) {
      id_lot = lotSelected[0].value ? lotSelected[0].value : 0;
    }

    var search = values.lot_chosen.filter(
      (item) => parseInt(item.id_lot) === parseInt(id_lot),
    );

    if (id_lot > 0) {
      if (search.length === 0) {
        sendCall("lots/GetLotSingle/", "GET", {
          accessToken: user.accessToken,
          id_lot: id_lot,
        })
          .then((data) => {
            if (data.code === 200) {
              var dataLot = data.result[0];
              if (dataLot.quantity > 0) {
                // set_input_id_lots([...input_id_lots, dataLot.id_lot]);
                setFieldValue("lot_chosen", [dataLot]);
                setFieldValue("id_lot", id_lot);
                //set_input_max(dataLot.quantity);
                setFieldValue("initial_price", dataLot.exportPrice);
                setMaxQuantityChosen(dataLot.quantity);
                setFieldValue("quantity_chosen", dataLot.quantity);
              } else {
                Swal.fire({
                  title: "The Lot is empty",
                  icon: "warning",
                  button: "Okay",
                });
              }
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        Swal.fire({
          title: "The Lot already append",
          // text: "Please try again",
          icon: "warning",
          button: "Okay",
        }).then(() => {
          // window.location.reload();
        });
      }
    }
  };

  const ref = React.createRef();

  // INPUTS
  var disable_input_lot = false;
  var disable_input_experience = false;
  var disable_input_quantity_chose = false;
  var disable_input_checkbox_tech_fee = false;
  var disable_input_tech_fee = false;
  var disable_input_increment_selected = false;
  var disable_input_max_quantity = false;
  var disable_input_description_auction = false;
  var disable_input_short_description_auction = false;
  var disable_input_description_link = false;
  var disable_input_catalogue = false;
  var disable_input_crurated_estimated_market_price = false;
  var disable_input_initial_price = false;
  var disable_input_start_data = false;
  var disable_input_finish_data = false;
  var disable_input_type_auction = false;
  var disable_input_is_checked_individual = false;
  // var disable_input_is_checked_en_primeur = false;
  var disable_input_visibile_for = false;
  var disable_input_disable_for = false;
  var disable_input_for_user = false;
  var disable_input_more_information = false;
  var disable_input_terms_and_conditions = false;

  if (typeAction === "RESELL") {
    disable_input_lot = true;
    disable_input_experience = true;
  }

  const checkCondition = (values) => {
    if (values?.id_event > 0) {
      return "Next";
    }
    if (values.lot_chosen.length > 0 && experienceSelected) {
      if (!noLotSelected) {
        return "Next";
      }
    }
    if (values.lot_chosen.length > 0 && !experienceSelected) {
      if (!noLotSelected) {
        return "Next";
      }
    }
    if (
      noLotSelected &&
      experienceSelected !== "" &&
      experienceSelected !== 0 &&
      experienceSelected !== "null"
    ) {
      return "Next";
    }
    return null;
  };

  if (
    typeAction === "EDIT" &&
    (+auction.stato === 2 || +auction.closed === 1)
  ) {
    disable_input_lot = true;
    disable_input_experience = true;
    disable_input_quantity_chose = true;
    disable_input_checkbox_tech_fee = true;
    disable_input_tech_fee = true;
    disable_input_increment_selected = true;
    disable_input_max_quantity = parseInt(auction.processed) === 1;
    disable_input_description_auction = true;
    disable_input_description_link = true;
    disable_input_crurated_estimated_market_price = true;
    disable_input_initial_price = true;
    disable_input_start_data = true;
    disable_input_finish_data = parseInt(auction.processed) === 1;
    disable_input_type_auction = true;
    disable_input_is_checked_individual = true;
    disable_input_visibile_for = true;
    disable_input_disable_for = true;
    disable_input_for_user = true;
  } else if (
    typeAction === "EDIT" &&
    (parseInt(auction.numBid) > 0 || parseInt(auction.stato) === 1)
  ) {
    disable_input_lot = true;
    disable_input_experience = true;
    disable_input_quantity_chose = true;
    disable_input_start_data = auction.typeAuction !== "permanent-collections";
    disable_input_type_auction = true;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values, touched, status, setFieldValue }) => {
        return (
          <Form>
            <Modal.Body>
              {!!status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              )}

              {(typeAction === "RESELL" || typeAction === "DONATION") && (
                <div className="card mt-3 mb-3 p-3 bg-light">
                  <h6>For Resell</h6>

                  <div className="col-12">
                    <div className="form-group">
                      <label>
                        Choose Quantity (Max Quantity: {maxQuantityChosen})
                      </label>
                      <Field
                        type="number"
                        className={
                          "form-control" +
                          (errors.quantity_chosen && touched.quantity_chosen
                            ? " is-invalid"
                            : "")
                        }
                        name={`quantity_chosen`}
                        value={values.quantity_chosen}
                        onChange={(e) => {
                          setFieldValue("quantity_chosen", e.target.value);
                        }}
                        placeholder="Quantity Chosen"
                        max={maxQuantityChosen}
                        disabled={disable_input_quantity_chose}
                      />
                      <ErrorMessage
                        name={`quantity_chosen`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              )}
              {!next && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <h3>Choose lot</h3>
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          options={optionsLot}
                          ref={ref}
                          placeholder="Choose..."
                          onChange={(e) => {
                            appendLot(e, setFieldValue, values);
                            e[0]?.value === "no-lot"
                              ? setNoLotSelected(true)
                              : setNoLotSelected(false);
                          }}
                          disabled={disable_input_lot}
                        />
                      </div>
                    </div>
                  </div>

                  <h3>Choose experience</h3>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    options={[
                      { value: "null", name: "No experience" },
                      ...experienceList.map((r) => ({
                        value: r?.id,
                        name: r?.title,
                      })),
                    ]}
                    ref={ref}
                    placeholder="Choose..."
                    onChange={(e) => {
                      const selectedValue = e[0]?.value;
                      setExperienceSelected(selectedValue);
                    }}
                    disabled={disable_input_experience}
                  />
                </>
              )}

              {!!next && (
                <>
                  {/* Table preview */}
                  {!noLotSelected && (
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <h3>Lot Detail</h3>
                          <table className="TableCustomlotPrev table table-hover table-bordered">
                            <thead>
                              <tr>
                                <td>
                                  <span className="svg-icon svg-icon-lg">
                                    <SVG src={homeSrc} />
                                  </span>
                                </td>
                                <td>Producer Name</td>
                                <td>SKU</td>
                                <td>Quantity</td>
                                <td>Initial lot price</td>
                              </tr>
                            </thead>

                            <tbody>
                              {values.lot_chosen.map((lot) => (
                                <tr key={lot.id_lot}>
                                  <td>
                                    <img
                                      src={lot.photo}
                                      className="imgCustomPrev"
                                      alt="img"
                                    />
                                  </td>
                                  <td>{lot.producer_name}</td>
                                  <td>{lot.sku}</td>
                                  <td>{values.quantity_chosen}</td>
                                  <td>{lot.exportPrice}€</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <ErrorMessage
                            name={`quantity_chosen`}
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Principal Field */}

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name={`is_enabled_tech_fee`}
                            className={
                              "m-1 form-control" +
                              (errors.is_enabled_tech_fee &&
                              touched.is_enabled_tech_fee
                                ? " is-invalid"
                                : "")
                            }
                            checked={values.is_enabled_tech_fee > 0}
                            onChange={() => {
                              setFieldValue(
                                `is_enabled_tech_fee`,
                                values.is_enabled_tech_fee > 0 ? 0 : 1,
                              );
                            }}
                            disabled={disable_input_checkbox_tech_fee}
                          />
                          <i style={{ marginRight: "5px" }}>% Tech fee</i>
                          <span />
                        </label>
                        <Field
                          type="number"
                          name={`tech_fee`}
                          className={
                            "form-control" +
                            (errors.tech_fee && touched.tech_fee
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Tech Fee"
                          disabled={disable_input_tech_fee}
                        ></Field>
                        <ErrorMessage
                          name={`tech_fee`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>% increase in bidding options</label>
                        <Field
                          type="number"
                          name={`increment_selected`}
                          className={
                            "form-control" +
                            (errors.increment_selected &&
                            touched.increment_selected
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="% increase in bidding options"
                          disabled={disable_input_increment_selected}
                        ></Field>
                        <ErrorMessage
                          name={`increment_selected`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label>Max Quantity for customer</label>
                        <Field
                          type="number"
                          name={`max_quantity`}
                          className={
                            "form-control" +
                            (errors.max_quantity && touched.max_quantity
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Max Quantity for customer"
                          disabled={disable_input_max_quantity}
                        ></Field>
                        <ErrorMessage
                          name={`max_quantity`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <h3>Info Auction</h3>
                      <div className="form-group">
                        <label>Name</label>
                        <Field
                          type="text"
                          name={`description_auction`}
                          className={
                            "form-control" +
                            (errors.description_auction &&
                            touched.description_auction
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Name"
                          disabled={disable_input_description_auction}
                        ></Field>
                        <ErrorMessage
                          name={`description_auction`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Short Description</label>

                        <textarea
                          type="textarea"
                          name={`short_description_auction`}
                          className={
                            "form-control" +
                            (errors.short_description_auction &&
                            touched.short_description_auction
                              ? " is-invalid"
                              : "")
                          }
                          value={values.short_description_auction}
                          onChange={(e) => {
                            setFieldValue(
                              `short_description_auction`,
                              e.target.value,
                            );
                          }}
                          placeholder="Short Description"
                          disabled={disable_input_short_description_auction}
                        ></textarea>
                        <ErrorMessage
                          name={`short_description_auction`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>More Information</label>

                        <textarea
                          type="textarea"
                          name={`more_information`}
                          className={
                            "form-control" +
                            (errors.more_information && touched.more_information
                              ? " is-invalid"
                              : "")
                          }
                          value={values.more_information}
                          onChange={(e) => {
                            setFieldValue(`more_information`, e.target.value);
                          }}
                          placeholder="More information"
                          disabled={disable_input_more_information}
                        ></textarea>
                        <ErrorMessage
                          name={`more_information`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Terms And Conditions</label>

                        <textarea
                          type="textarea"
                          name={`terms_and_conditions`}
                          className={
                            "form-control" +
                            (errors.terms_and_conditions &&
                            touched.terms_and_conditions
                              ? " is-invalid"
                              : "")
                          }
                          value={values.terms_and_conditions}
                          onChange={(e) => {
                            setFieldValue(
                              `terms_and_conditions`,
                              e.target.value,
                            );
                          }}
                          placeholder="Terms and Conditions"
                          disabled={disable_input_terms_and_conditions}
                        ></textarea>
                        <ErrorMessage
                          name={`more_information`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Description Link</label>
                        <Field
                          type="text"
                          name={`description_link`}
                          className={
                            "form-control" +
                            (errors.description_link && touched.description_link
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Description Link"
                          disabled={disable_input_description_link}
                        ></Field>
                        <ErrorMessage
                          name={`description_link`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <Tooltip
                          position="bottom"
                          content="Estimated Market Price (EMP) it's calculated by
                              Crurated from a range of different sources and
                              considers the condition and rarity of the bottles"
                        >
                          <label id="EMP-label">EMP</label>
                        </Tooltip>
                        <Field
                          type="number"
                          name={`crurated_estimated_market_price`}
                          className={
                            "form-control" +
                            (errors.crurated_estimated_market_price &&
                            touched.crurated_estimated_market_price
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="EMP"
                          disabled={
                            disable_input_crurated_estimated_market_price
                          }
                        ></Field>
                        <ErrorMessage
                          name={`crurated_estimated_market_price`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Initial Auction Price</label>
                        <Field
                          type="number"
                          name={`initial_price`}
                          className={
                            "form-control" +
                            (errors.initial_price && touched.initial_price
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Initial Price"
                          disabled={disable_input_initial_price}
                        ></Field>
                        <ErrorMessage
                          name={`initial_price`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Catalogue</label>
                        <Field
                          type="text"
                          name={`catalogue`}
                          className={
                            "form-control" +
                            (errors.catalogue && touched.catalogue
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Link Catalogue PDF"
                          disabled={disable_input_catalogue}
                        ></Field>
                        <ErrorMessage
                          name={`catalogue`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Start of Auction</label>
                        <input
                          type="datetime-local"
                          name="start_data"
                          value={values.start_data}
                          className={
                            "form-control" +
                            (errors.start_data && touched.start_data
                              ? " is-invalid"
                              : "")
                          }
                          onChange={(e) => {
                            setFieldValue(`start_data`, e.target.value);
                          }}
                          placeholder="Start data"
                          disabled={disable_input_start_data}
                        />
                        <ErrorMessage
                          name={`start_data`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Deadline of Auction</label>
                        {parseInt(values.id_auction_type) === 5 ||
                        parseInt(values.id_auction_type) === 6 ? (
                          <input
                            type="text"
                            value={"No finish data for this auction"}
                            className="form-control"
                            placeholder="Finish data"
                            disabled={true}
                          />
                        ) : (
                          <>
                            <input
                              type="datetime-local"
                              name="finish_data"
                              value={values.finish_data}
                              className={
                                "form-control" +
                                (errors.finish_data && touched.finish_data
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={(e) => {
                                setFieldValue(`finish_data`, e.target.value);
                              }}
                              placeholder="Finish data"
                              disabled={disable_input_finish_data}
                            />
                            <ErrorMessage
                              name={`finish_data`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="isSponsored"
                            className={
                              "m-1 form-control" +
                              (errors.isSponsored && touched.isSponsored
                                ? " is-invalid"
                                : "")
                            }
                            checked={values.isSponsored}
                            onChange={(e) => {
                              setFieldValue("isSponsored", e.target.checked);
                            }}
                          />
                          <i style={{ marginRight: "5px" }}>Sponsored</i>
                          <span />
                        </label>
                        <ErrorMessage
                          name={`isSponsored`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="isMultiPurchase"
                            className={
                              "m-1 form-control" +
                              (errors.isMultiPurchase && touched.isMultiPurchase
                                ? " is-invalid"
                                : "")
                            }
                            checked={values.isMultiPurchase}
                            onChange={(e) => {
                              setFieldValue(
                                "isMultiPurchase",
                                e.target.checked,
                              );
                            }}
                          />
                          <i style={{ marginRight: "5px" }}>Multi Purchasing</i>
                          <span />
                        </label>
                        <ErrorMessage
                          name={`isSponsored`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  {/* Charity */}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="charity"
                            className={"m-1 form-control"}
                            checked={values?.is_charity}
                            onChange={(e) => {
                              setFieldValue("is_charity", e.target.checked);
                            }}
                          />
                          <i style={{ marginRight: "5px" }}>Charity</i>
                          <span />
                        </label>
                        <ErrorMessage
                          name={`charity`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Field for visibility of auction*/}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>Type of Auction</label>
                        <select
                          name={`id_auction_type`}
                          id={`id_auction_type`}
                          onChange={(e) => {
                            setFieldValue(`id_auction_type`, e.target.value);

                            setFieldValue(`visible_for`, []);
                            setFieldValue(`disable_for`, []);
                            setFieldValue(`is_checked_individual`, 0);

                            if (parseInt(e.target.value) !== 3) {
                              setFieldValue(`for_user`, [0]);
                            }

                            if (parseInt(e.target.value) === 5) {
                              setFieldValue(`finish_data`, "");
                            } else {
                              setFieldValue(
                                `finish_data`,
                                values.finish_data ? values.finish_data : "",
                              );
                            }
                          }}
                          className={
                            "form-control form-control-sm" +
                            (errors.id_auction_type && touched.id_auction_type
                              ? " is-invalid"
                              : "")
                          }
                          value={values.id_auction_type}
                          disabled={disable_input_type_auction}
                        >
                          <option value={0}>Select Auction</option>
                          {dataAuctionType.length > 0 ? (
                            dataAuctionType
                              .filter(
                                ({ id_auction_type }) =>
                                  !values.is_charity ||
                                  [
                                    ID_AUCTION_TYPE.COLLECTION,
                                    ID_AUCTION_TYPE.SINGLE_LOTS,
                                    ID_AUCTION_TYPE.PRIVATE_SALE,
                                  ].includes(id_auction_type),
                              )
                              .map((auctionType) => (
                                <option
                                  key={`auction-type-${auctionType.id_auction_type}`}
                                  value={auctionType.id_auction_type}
                                >
                                  {auctionType.name}
                                </option>
                              ))
                          ) : (
                            <option value={0}>Select Auction</option>
                          )}
                        </select>
                        <ErrorMessage
                          name={`id_auction_type`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>

                    {parseInt(values.id_auction_type) === 3 && (
                      <div className="col-12">
                        <div className="form-group">
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              name={`is_checked_individual`}
                              className={
                                "m-1 form-control" +
                                (errors.is_checked_individual &&
                                touched.is_checked_individual
                                  ? " is-invalid"
                                  : "")
                              }
                              checked={values.is_checked_individual > 0}
                              onChange={() => {
                                setFieldValue(
                                  `is_checked_individual`,
                                  values.is_checked_individual > 0 ? 0 : 1,
                                );

                                if (values.is_checked_individual > 0) {
                                  setFieldValue(`visible_for`, []);
                                  setFieldValue(`disable_for`, []);
                                  setFieldValue(`for_user`, [0]);
                                } else {
                                  setFieldValue(
                                    `visible_for`,
                                    [14, 13, 12, 3, 2, 1],
                                  );
                                  setFieldValue(`disable_for`, [0]);
                                  setFieldValue(`for_user`, []);
                                }
                              }}
                              disabled={disable_input_is_checked_individual}
                            />
                            <i style={{ marginRight: "5px" }}>
                              Individual Users
                            </i>
                            <span />
                          </label>
                          <ErrorMessage
                            name={`is_checked_individual`}
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    )}

                    {(parseInt(values.id_auction_type) === 1 ||
                      parseInt(values.id_auction_type) === 2 ||
                      (parseInt(values.id_auction_type) === 3 &&
                        parseInt(values.is_checked_individual)) === 0 ||
                      parseInt(values.id_auction_type) === 5 ||
                      parseInt(values.id_auction_type) === 6) && (
                      <>
                        <div className="col-6">
                          <div className="form-group">
                            <label>
                              Visible For
                              <small>
                                (command or shift + click for multiple select)
                              </small>
                            </label>

                            <select
                              name={`visible_for`}
                              onChange={(e) => {
                                // addSelectedVisibleFor(e)
                                var selectedOptions = [
                                  ...e.target.selectedOptions,
                                ].map((o) => o.value);
                                setFieldValue(`visible_for`, selectedOptions);
                              }}
                              className={
                                "form-control form-control-sm" +
                                (errors.visible_for && touched.visible_for
                                  ? " is-invalid"
                                  : "")
                              }
                              value={values.visible_for}
                              multiple
                              disabled={disable_input_visibile_for}
                            >
                              {dataCustomerRoles.length > 0 ? (
                                dataCustomerRoles.map((e, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={e.id_customer_role}
                                    >
                                      {e.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={0}>No Roles</option>
                              )}
                            </select>
                            <ErrorMessage
                              name={`visible_for`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label>
                              Disable For
                              <small>
                                (command or shift + click for multiple select)
                              </small>
                            </label>

                            <select
                              name={`disable_for`}
                              onChange={(e) => {
                                // addSelectedVisibleFor(e)
                                var selectedOptions = [
                                  ...e.target.selectedOptions,
                                ].map((o) => o.value);
                                setFieldValue(`disable_for`, selectedOptions);
                              }}
                              className={
                                "form-control form-control-sm" +
                                (errors.disable_for && touched.disable_for
                                  ? " is-invalid"
                                  : "")
                              }
                              value={values.disable_for}
                              multiple
                              disabled={disable_input_disable_for}
                            >
                              <option value={0}>No Disable</option>
                              {dataCustomerRoles.length > 0 ? (
                                dataCustomerRoles.map((e, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={e.id_customer_role}
                                    >
                                      {e.name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value={0}>No Roles</option>
                              )}
                            </select>
                            <ErrorMessage
                              name={`disable_for`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {parseInt(values.id_auction_type) === 3 &&
                      parseInt(values.is_checked_individual) > 0 && (
                        <div className="col-12">
                          <div className="form-group">
                            <label>Visible For users</label>

                            <Typeahead
                              id="basic-typeahead-multiple"
                              labelKey="fullname"
                              multiple
                              selected={values.for_user_multiselect} // object
                              onChange={(e) => {
                                var newForUser = e.map((o) => o.id_user);

                                var newarr = [];
                                e.map((o) => {
                                  newarr = [
                                    ...newarr,
                                    {
                                      id_user: o.id_user,
                                      fullname: o.fullname,
                                    },
                                  ];
                                });

                                setFieldValue("for_user", newForUser);
                                setFieldValue("for_user_multiselect", newarr);
                              }}
                              options={dataAllCustomersActive}
                              placeholder="Choose..."
                              disabled={disable_input_for_user}
                            />
                            <ErrorMessage
                              name={`for_user`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </>
              )}
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                name=""
                className="btn btn-secondary btn-secondary-bordered"
                onClick={handleClose}
                disabled={loading}
              >
                Close
              </button>
              <div className="float-right">
                {!next && (
                  <button
                    className="btn btn-primary mr-2"
                    type="button"
                    disabled={!checkCondition(values)}
                    onClick={() => setNext(true)}
                  >
                    Next
                  </button>
                )}
                {!!next && (
                  <button
                    className="btn btn-primary mr-2"
                    // onClick={onSubmit}
                    type="submit"
                    disabled={!checkCondition(values)}
                  >
                    Submit
                    {!!loading && (
                      <>
                        {" "}
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    )}
                  </button>
                )}
              </div>
            </Modal.Footer>
          </Form>
        );
      }}
    </Formik>
  );
};
