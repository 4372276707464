// This service allows the user to pass any type of value to the localStorage
// by nesting it into an object {value: ...}

export class LocalStorage {
  static setItem(key: string, item: any) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.localStorage.setItem(key, JSON.stringify({ item }));
  }

  static getItem(key: string) {
    try {
      const stringifiedItem: any = window.localStorage.getItem(key);
      const item = JSON.parse(stringifiedItem)?.item;
      return item;
    } catch (e) {
      LocalStorage.removeItem(key);
      return null;
    }
  }

  static hasKey(key: string) {
    return window.localStorage.getItem(key) !== null;
  }

  static removeItem(key: string) {
    window.localStorage.removeItem(key);
  }
}
