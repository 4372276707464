import styles from "./index.module.css";
import { CardExpiryElement } from "@stripe/react-stripe-js";
import {
  StripeCardExpiryElementChangeEvent,
  StripeElementType,
} from "@stripe/stripe-js";
import { cn } from "src/app/utils/cn";

export const emptyCardExpiryElementValue: StripeCardExpiryElementChangeEvent = {
  empty: true,
  complete: false,
  error: {
    type: "validation_error",
    code: "custom message",
    message: "Your card's expiry date is incomplete.",
  },
  elementType: "cardExpiry",
};

type Props = {
  onChange: (value: StripeCardExpiryElementChangeEvent) => void;
  onBlur?: (event: { elementType: StripeElementType }) => any;
  className?: string;
};

export const InputCardExpiry = ({
  onChange,
  onBlur,
  className = "",
}: Props) => {
  return (
    <CardExpiryElement
      id="input-card-expiry"
      className={cn(styles.root, className)}
      onChange={(value: StripeCardExpiryElementChangeEvent) => {
        if (!value.complete && !value.error) {
          onChange({
            ...value,
            error: emptyCardExpiryElementValue.error,
          });
        } else {
          onChange(value);
        }
      }}
      onBlur={onBlur}
    />
  );
};
