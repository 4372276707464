import { CruForm } from "src/app/components/cru-form";
import { TierRadio } from "./tier-radio";
import ExplorerCard from "src/images/tier-card/explorer.png";
import ConnoisseurCard from "src/images/tier-card/connoisseur.png";
import CollectorCard from "src/images/tier-card/collector.png";
import AmbassadorCard from "src/images/tier-card/ambassador.png";
import { Switch } from "./switch";
import { useHistory } from "react-router-dom";
import { LoginLink } from "../components/login-link";
import { TierName } from "src/app/const";
import { Button } from "src/app/components/button";
import { Headline } from "../components/headline";
import { SignupStepper } from "../components/signup-stepper";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { path } from "src/app/routes/path";
import { Badge } from "./badge";
import { useState } from "react";

export type SelectTierFormValues = {
  annual: boolean;
  tier: TierName;
};

export const PlanSelection = () => {
  const history = useHistory();
  const [searchParams, setSearchParams] = useTypedSearchParams({
    schema: z.object({ annual: z.boolean().optional() }),
  });
  const [save15, setSave15] = useState(false);

  return (
    <>
      <header
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <SignupStepper description="Select Membership" step={1} />
        <Headline>
          Select your preferred membership plan and start enjoying the benefits
        </Headline>
      </header>

      <CruForm.Form<SelectTierFormValues>
        initialValues={{ annual: !!searchParams.annual, tier: "explorer" }}
        onSubmit={({ tier }) => {
          history.push({
            pathname: path.signup.steps.value(tier),
            search: history.location.search,
          });
        }}
      >
        {(cruForm) => (
          <>
            <div style={{ margin: "20px 0" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Switch
                  cruForm={cruForm}
                  onChangeSideEffect={(annual) => {
                    setSearchParams({ annual });
                    setSave15(!save15);
                  }}
                  label="Switch to yearly plan"
                />
                {!!save15 && <Badge text="Save 15%+" />}
              </div>
              <div id="radiogroup" role="radiogroup">
                <TierRadio
                  cruForm={cruForm}
                  tierName="explorer"
                  cardUrl={ExplorerCard}
                />
                <TierRadio
                  cruForm={cruForm}
                  tierName="connoisseur"
                  cardUrl={ConnoisseurCard}
                />
                <TierRadio
                  cruForm={cruForm}
                  tierName="collector"
                  cardUrl={CollectorCard}
                />
                <TierRadio
                  cruForm={cruForm}
                  tierName="ambassador"
                  cardUrl={AmbassadorCard}
                />
              </div>
            </div>
            <Button label="Next" type="submit" style={{ width: "100%" }} />
            <LoginLink />
          </>
        )}
      </CruForm.Form>
    </>
  );
};
