import { A } from "src/app/components/a";
import styles from "./menu-item.module.css";
import { cn } from "src/app/utils/cn";
import { Button } from "src/app/components/button";
import { ReactNode } from "react";

type Props = {
  to: string;
  hide?: boolean;
  label: ReactNode;
  icon?: ReactNode;
  isSidebarPinned: boolean;
};

export const MenuItem = ({ to, hide, label, icon, isSidebarPinned }: Props) => {
  if (hide) return;

  return (
    <li>
      <Button
        as={A}
        to={to}
        variant="ghost"
        activeClassName={styles.active}
        className={cn(styles.root, !isSidebarPinned && styles.unpinned)}
        label={
          <div className={cn(styles.label, isSidebarPinned && styles.pinned)}>
            {label}
          </div>
        }
        icon={<div className={styles.icon}>{icon}</div>}
      />
    </li>
  );
};
