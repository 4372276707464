import { useSubscriptionPlans } from "src/app/api/subscription";
import { TIER_NAME, TierName } from "src/app/const";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { History } from "history";

export function getIsValidTierName(tierName: string): tierName is TierName {
  return Object.values(TIER_NAME).includes(tierName);
}

type UserPlanOptions = {
  tierName: TierName;
  annual: boolean | undefined;
};

/**
 * Returns a SubscriptionV1 object searching by tier name and recurring type
 */
export const useSubscriptionPlan = ({ tierName, annual }: UserPlanOptions) => {
  const { data } = useSubscriptionPlans();
  if (!data) return null;

  const monthlyPlan = data.find(
    (plan) =>
      plan.name.toLowerCase() === tierName && plan.recurring_type === "monthly",
  );
  const annualPlan = data.find(
    (plan) =>
      plan.name.toLowerCase() === tierName && plan.recurring_type === "annual",
  );

  if (!annualPlan && !monthlyPlan) {
    throw new Error("The selected plan does not exist");
  }
  const plan = annual
    ? (annualPlan ?? monthlyPlan)!
    : (monthlyPlan ?? annualPlan)!;
  return plan;
};

/**
 * Returns a formatted price string (initial_fee) for a subscription by tier name and recurring type
 */
export const usePrice = ({ tierName, annual }: UserPlanOptions) => {
  const plan = useSubscriptionPlan({ tierName, annual });

  if (!plan) return "";
  return plan.initial_fee ? formatCurrency(plan.initial_fee) : "Free";
};

/** Returns a dateString of 18 years ago */
export function getMaxBirthDate() {
  return new Date(new Date().setFullYear(new Date().getFullYear() - 18))
    .toISOString()
    .split("T")[0];
}

/** Returns a dateString of 150 years ago */
export function getMinBirthDate() {
  return new Date(new Date().setFullYear(new Date().getFullYear() - 150))
    .toISOString()
    .split("T")[0];
}

/** Returns the current location pathname with step=1 search parameter */
export function step1Url(history: History) {
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.set("step", "1");
  return {
    pathname: history.location.pathname,
    search: searchParams.toString(),
  };
}
