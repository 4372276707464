import styles from "./form.module.css";
import { useCardCtx } from "src/app/components/auction-card/card-context";
import { Form as AuctionForm } from "src/app/components/auction-card/lg/atoms.form";
import { isAuctionOfLot } from "src/app/models/auctions";

export const Form = () => {
  const cardCtx = useCardCtx();

  if (cardCtx.auction.isForStore) {
    return (
      <div className={styles.root}>
        <AuctionForm.ForStore />
      </div>
    );
  }

  if (isAuctionOfLot(cardCtx.auction) && cardCtx.auction.isOfSingleLot) {
    return (
      <div className={styles.root}>
        <AuctionForm.ForSingleLot />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <AuctionForm.ForCollection />
    </div>
  );
};
