import {
  ID_AUCTION_TYPE,
  ID_PAYMENT_METHOD,
  TYPE_PRODUCT,
} from "src/app/const";
import { RootDictionary } from "..";

/**
 * Leggi il README.md
 */

export const shopMessages: RootDictionary = {
  prompt: {
    default: {
      title: "Please Confirm Purchase",
      content: (
        <>
          Please note, this is an immediate purchase which cannot be canceled.
          An automated payment will be issued using the method provided by you
          in your settings. <br />
          <br /> Do you want to confirm?
        </>
      ),
    },
    [`type-${ID_AUCTION_TYPE.EN_PRIMEUR},add-waiting-list`]: {
      title: "Do You Want To Join The Waitlist?",
      content:
        "By confirming, we will be adding you to the waitlist for this item.",
    },
    [`type-${ID_AUCTION_TYPE.EN_PRIMEUR},remove-waiting-list`]: {
      title: "Remove this product from the waiting list?",
      content:
        "By confirming, you will remove this product from the waiting list.",
    },
  },
  success: {
    default: {
      title: "Congratulations!",
      content: "Your order is now confirmed.",
    },
    [`payment-${ID_PAYMENT_METHOD.CARD}`]: {
      title: "Congratulations!",
      content:
        "Your order is now confirmed, and your card was automatically charged. Go to Your Cellar to view all your purchases!",
    },
    [`payment-${ID_PAYMENT_METHOD.BANK}`]: {
      title: "Congratulations!",
      content:
        "Your order is now confirmed. Please proceed to settle your balance in outstanding payments and mark it as paid.",
    },
    [`product-${TYPE_PRODUCT.BARREL},payment-${ID_PAYMENT_METHOD.CARD}`]: {
      title: "Thank You For Your Purchase!",
      content:
        "A member of our team will approve your order, and process the payment. Afterwhich, you will be able to convert your purchased liters to the available formats in Your Cellar.",
    },
    [`type-${ID_AUCTION_TYPE.EN_PRIMEUR},add-waiting-list`]: {
      title: "You've Joined The Waitlist",
      content:
        "We will notify you as soon as the item will be available again.",
    },
    [`type-${ID_AUCTION_TYPE.EN_PRIMEUR},remove-waiting-list`]: {
      title: "Auction removed from the Waitlist!",
      content:
        "You will not be notified when the item will be available again.",
    },
  },
};
