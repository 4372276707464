import styles from "./table-customer-side.module.css";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { noop, range } from "lodash";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Spinner } from "react-bootstrap";
import { useId } from "react";
import { Checkbox } from "../form-elements/checkbox";
import { TableNoDataBox } from "./table-no-data";
import { SortableTdHeaderContent } from "./sortable-td-header-content";
import { Pagination } from "./pagination";
import { toPx } from "src/app/utils/toPx";
import { cn } from "src/app/utils/cn";
import { Skeleton } from "../skeleton";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";

type TDataBase = { [key: string]: any };
export type RowId = string | number;
export type ColId = string | number;
const defaultOptionsForPageSize = [{ value: 5 }, { value: 10 }, { value: 25 }];
const emptyArray: any[] = [];
const noRender = () => null;

const searchParamsSchema = z.object({
  page: z.number().optional(),
  sortBy: z.union([z.string(), z.number()]).optional(),
  sortDir: z.union([z.literal("asc"), z.literal("desc")]).optional(),
  pageSize: z.number().optional(),
});

export type TableCustomerSideColumnDef<TData extends TDataBase> = {
  alignHeaderDesktop?: "left" | "center" | "right";
  cellDesktop: (row: TData) => ReactNode;
  cellMobile?: (row: TData) => ReactNode;
  getSortingValue?: (row: TData) => string | number;
  header: ReactNode;
  id: string;
  width?: string | number;
};

type Props<TData extends TDataBase> = {
  columns: Array<TableCustomerSideColumnDef<TData>>;
  data: TData[] | undefined;
  enableExpansionForRow?: (row: TData) => boolean;
  enableSelectionForRow?: (row: TData) => boolean;
  fillMissingRows?: boolean;
  getRowId: (row: TData) => string | number;
  isFetching: boolean;
  isLoading: boolean;
  minCellHeightDesktop?: string | number;
  minCellHeightMobile?: string | number;
  noDataContent: ReactNode;
  noDataTitle: ReactNode;
  onChangeSelection?: (selection: RowId[]) => void;
  optionsForPageSize?: Array<{ value: number }>;
  renderSubRow?: (props: { row: TData; isMobile: boolean }) => ReactNode;
  selection?: RowId[];
  urlPrefix: string;
};

export function TableCustomerSide<TData extends TDataBase>({
  columns = emptyArray,
  data = emptyArray as TData[],
  enableExpansionForRow,
  enableSelectionForRow,
  fillMissingRows = true,
  getRowId,
  isFetching = false,
  isLoading = false,
  minCellHeightDesktop = "auto",
  minCellHeightMobile = "auto",
  noDataContent = "",
  noDataTitle = "",
  onChangeSelection = noop,
  optionsForPageSize = defaultOptionsForPageSize,
  renderSubRow = noRender,
  selection = [],
  urlPrefix,
}: Props<TData>) {
  const isMobile = useIsMobile();
  const [expansion, setExpansion] = useState<RowId[]>([]);
  const [searchParams, setSearchParams] = useTypedSearchParams({
    urlPrefix,
    schema: searchParamsSchema,
  });

  const { page = 0, sortBy, sortDir, pageSize = 10 } = searchParams;

  const isSorted = !!sortDir;
  const getSortingValue = columns.find(
    (col) => col.id === sortBy,
  )?.getSortingValue;
  const sortedData =
    !isSorted || !getSortingValue
      ? data
      : [...data].sort((a, b) => {
          const val1 = getSortingValue(a);
          const val2 = getSortingValue(b);
          const comparision =
            typeof val1 === "number" && typeof val2 === "number"
              ? val1 < val2
                ? -1
                : val1 === val2
                  ? 0
                  : 1
              : `${getSortingValue(a)}`.localeCompare(`${getSortingValue(b)}`);
          const multiplier = sortDir === "asc" ? 1 : -1;
          return comparision * multiplier;
        });

  const visibleData = sortedData.slice(
    page * pageSize,
    page * pageSize + pageSize,
  );

  const selectableData = data.filter(enableSelectionForRow || (() => true));
  const areAllRowsSelected =
    selection.length === selectableData.length && !isLoading;

  useEffect(() => {
    if (page > 0 && !isLoading && !visibleData.length) {
      setSearchParams({ page: undefined });
      setExpansion([]);
    }
  }, [visibleData, page, setSearchParams, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      setSearchParams({ page: undefined });
      setExpansion([]);
    }
  }, [data.length, setSearchParams, isLoading]);

  function handleClickSelectRow(row: TData) {
    const selectionId = getRowId(row);
    if (selection.includes(selectionId)) {
      onChangeSelection(selection.filter((rowId) => rowId !== selectionId));
    } else {
      onChangeSelection([...selection, selectionId]);
    }
  }

  function handleClickExpandRow(row: TData) {
    const expansionId = getRowId(row);
    if (expansion.includes(expansionId)) {
      setExpansion(expansion.filter((rowId) => rowId !== expansionId));
    } else {
      setExpansion([...expansion, expansionId]);
    }
  }

  return (
    <div>
      <div>
        <table
          className={`${styles.table} ${
            isFetching && !isLoading ? styles.tableFetching : ""
          }`}
          aria-labelledby="tableTitle"
        >
          {!isMobile && (
            <thead className={styles.tableHead}>
              <tr>
                {/* Used for minCellHeightDesktop */}
                <td style={{ width: 0, padding: 0 }} />

                {!!enableExpansionForRow && (
                  <td className={styles.expansionCell} />
                )}

                {!!enableSelectionForRow && (
                  <td
                    className={cn(styles.tableHeadCell, styles.selectionCell)}
                  >
                    {isFetching && !isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <Checkbox
                        className={styles.checkbox}
                        disabled={
                          isLoading || !data.some(enableSelectionForRow)
                        }
                        checked={areAllRowsSelected}
                        onChange={() => {
                          areAllRowsSelected
                            ? onChangeSelection([])
                            : onChangeSelection(selectableData.map(getRowId));
                        }}
                        aria-label="Select All"
                      />
                    )}
                  </td>
                )}

                {columns.map((col, i) => (
                  <td
                    className={styles.tableHeadCell}
                    key={`header.table-${i}`}
                    align={col.alignHeaderDesktop}
                  >
                    {getIsSortEnabled(col) ? (
                      <SortableTdHeaderContent
                        col={col}
                        sortBy={sortBy}
                        sortDir={sortDir}
                        handleClick={() => {
                          setExpansion([]);
                          setSearchParams({
                            page: undefined,
                            sortBy: col.id,
                            sortDir: getSortDir(sortBy, sortDir, col),
                          });
                        }}
                      >
                        {col.header}
                      </SortableTdHeaderContent>
                    ) : (
                      col.header
                    )}
                  </td>
                ))}
              </tr>
            </thead>
          )}

          {/* DESKTOP Loading */}
          {!!isLoading && !isMobile && (
            <tbody>
              {range(0, pageSize).map((i) => (
                <tr key={`skeleton-row-${i}`}>
                  {/* Sets the min-height of the row */}
                  <td
                    className={styles.tableCell}
                    style={{
                      height: toPx(minCellHeightDesktop),
                      width: 0,
                      padding: 0,
                    }}
                  />

                  {!!enableExpansionForRow && (
                    <td className={cn(styles.tableCell, styles.expansionCell)}>
                      <ArrowExpand isExpanded={false} disabled />
                    </td>
                  )}

                  {!!enableSelectionForRow && (
                    <td className={cn(styles.tableCell, styles.selectionCell)}>
                      <Checkbox
                        className={styles.checkbox}
                        checked={false}
                        disabled
                      />
                    </td>
                  )}

                  {columns.map((col, j) => (
                    <td
                      key={`skeleton-cell-${j}-${i}`}
                      style={{
                        height: toPx(minCellHeightDesktop),
                        width: col.width || "auto",
                      }}
                      className={styles.tableCell}
                    >
                      <Skeleton.Text />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}

          {/* DESKTOP Empty */}
          {!isLoading && !isMobile && !data.length && (
            <tbody>
              <tr>
                <td className={styles.tableCell} colSpan={100}>
                  <TableNoDataBox title={noDataTitle} content={noDataContent} />
                </td>
              </tr>
            </tbody>
          )}
          {/* DESKTOP Data */}
          {!isLoading && !isMobile && !!data.length && (
            <tbody>
              {sortedData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((row, index) => (
                  <RowDesktop<TData>
                    key={`row-deskt-${getRowId(row) || index}-${index}`}
                    row={row}
                    columns={columns}
                    enableSelectionForRow={enableSelectionForRow}
                    isFetching={isFetching}
                    enableExpansionForRow={enableExpansionForRow}
                    minCellHeight={minCellHeightDesktop}
                    onClickSelect={handleClickSelectRow}
                    onClickExpand={handleClickExpandRow}
                    isSelected={selection.includes(getRowId(row))}
                    isExpanded={expansion.includes(getRowId(row))}
                    renderSubRow={renderSubRow}
                    getRowId={getRowId}
                  />
                ))}
              {!!fillMissingRows &&
                Array(pageSize - visibleData.length)
                  .fill(0)
                  .map((_, i) => (
                    <tr
                      key={`empty-row-${i}`}
                      style={{ height: minCellHeightDesktop }}
                    />
                  ))}
            </tbody>
          )}

          {/* MOBILE Loading */}
          {!!isLoading && !!isMobile && (
            <tbody>
              {range(0, pageSize).map((i) => (
                <tr key={`skeleton-row-${i}`}>
                  <td className={styles.tableCell}>
                    <div className={styles.rowMobile}>
                      {!!enableSelectionForRow && (
                        <Fragment>
                          <div>
                            <Checkbox
                              className={styles.checkbox}
                              checked={false}
                              disabled
                            />
                          </div>
                          <div />
                        </Fragment>
                      )}

                      {columns.map((col, j) => (
                        <Fragment key={`skeleton-cell-${j}-${i}`}>
                          <div
                            className={styles.cellHeaderMobile}
                            style={{ minHeight: minCellHeightMobile }}
                          >
                            {col.header}
                          </div>
                          <div
                            style={{
                              minHeight: minCellHeightMobile,
                              height: minCellHeightMobile,
                            }}
                          >
                            <Skeleton.Text />
                          </div>
                        </Fragment>
                      ))}

                      {!!enableExpansionForRow && (
                        <div style={{ gridColumn: "1 / -1" }}>
                          <ArrowExpand
                            disabled={isFetching}
                            isExpanded={false}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {/* MOBILE Empty */}
          {!isLoading && !!isMobile && !data.length && (
            <tbody>
              <tr>
                <td className={styles.tableCell}>
                  <TableNoDataBox title={noDataTitle} content={noDataContent} />
                </td>
              </tr>
            </tbody>
          )}

          {/* MOBILE Data */}
          {!isLoading && !!isMobile && !!data.length && (
            <tbody>
              {sortedData
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((row, index) => (
                  <RowMobile<TData>
                    key={`row-mob-${getRowId(row) || index}-${index}`}
                    columns={columns}
                    row={row}
                    enableExpansionForRow={enableExpansionForRow}
                    enableSelectionForRow={enableSelectionForRow}
                    minCellHeight={minCellHeightMobile}
                    onClickSelect={handleClickSelectRow}
                    onClickExpand={handleClickExpandRow}
                    isSelected={selection.includes(getRowId(row))}
                    isFetching={isFetching}
                    renderSubRow={renderSubRow}
                    isExpanded={expansion.includes(getRowId(row))}
                    getRowId={getRowId}
                  />
                ))}
            </tbody>
          )}
        </table>
      </div>
      <Pagination
        optionsForPageSize={optionsForPageSize}
        total={data.length}
        pageSize={pageSize}
        page={data.length ? page : 0}
        onChangePage={(page) => {
          setExpansion([]);
          setSearchParams({ page });
        }}
        onChangePageSize={(pageSize) => setSearchParams({ pageSize })}
      />
    </div>
  );
}

type RowProps<TData extends TDataBase> = {
  row: TData;
  columns: Props<TData>["columns"];
  enableSelectionForRow: Props<TData>["enableSelectionForRow"];
  enableExpansionForRow: Props<TData>["enableExpansionForRow"];
  getRowId: Props<TData>["getRowId"];
  minCellHeight: Props<TData>["minCellHeightMobile"];
  onClickSelect?: (row: TData) => void;
  onClickExpand?: (row: TData) => void;
  isExpanded?: boolean;
  isSelected?: boolean;
  isFetching?: boolean;
  renderSubRow: Props<TData>["renderSubRow"];
};

function RowMobile<TData extends TDataBase>({
  row,
  columns,
  enableSelectionForRow,
  enableExpansionForRow,
  getRowId,
  minCellHeight,
  onClickSelect = noop,
  onClickExpand = noop,
  isExpanded = false,
  isSelected = false,
  isFetching = false,
  renderSubRow: RenderSubRow = noRender,
}: RowProps<TData>) {
  const id = useId();
  return (
    <Fragment>
      <tr>
        <td className={styles.tableCell}>
          <dl className={styles.rowMobile}>
            {!!enableSelectionForRow && (
              <Fragment>
                <div>
                  <Checkbox
                    className={styles.checkbox}
                    checked={isSelected}
                    disabled={!enableSelectionForRow(row) || isFetching}
                    onChange={() => onClickSelect(row)}
                  />
                </div>
                <div />
              </Fragment>
            )}

            {columns.map((col, i) => (
              <Fragment key={`mob-row-${id}-${getRowId(row)}-${i}`}>
                <dt
                  className={styles.cellHeaderMobile}
                  style={{ minHeight: toPx(minCellHeight) }}
                >
                  {col.header}
                </dt>
                <dd
                  style={{ minHeight: toPx(minCellHeight), gridColumnStart: 2 }}
                >
                  {(col.cellMobile || col.cellDesktop)(row)}
                </dd>
              </Fragment>
            ))}

            {!!enableExpansionForRow && (
              <div style={{ gridColumn: "1 / -1" }}>
                {enableExpansionForRow(row) && (
                  <ArrowExpand
                    disabled={isFetching}
                    onClick={() => onClickExpand(row)}
                    isExpanded={isExpanded}
                  />
                )}
              </div>
            )}
          </dl>
        </td>
      </tr>

      {!!isExpanded && <RenderSubRow row={row} isMobile={true} />}
    </Fragment>
  );
}

function RowDesktop<TData extends TDataBase>({
  row,
  columns,
  enableSelectionForRow,
  enableExpansionForRow,
  getRowId,
  minCellHeight,
  onClickSelect = noop,
  onClickExpand = noop,
  isSelected = false,
  isFetching = false,
  isExpanded = false,
  renderSubRow: RenderSubRow = noRender,
}: RowProps<TData>) {
  return (
    <Fragment>
      <tr
        tabIndex={-1}
        key={`${row.status}-op-row-${getRowId(row)}`}
        className={styles.tableRow}
      >
        <td style={{ height: toPx(minCellHeight), width: 0 }} />

        {!!enableExpansionForRow && (
          <td className={cn(styles.tableCell, styles.expansionCell)}>
            {enableExpansionForRow(row) && (
              <ArrowExpand
                disabled={isFetching}
                onClick={() => onClickExpand(row)}
                isExpanded={isExpanded}
              />
            )}
          </td>
        )}

        {!!enableSelectionForRow && (
          <td className={cn(styles.tableCell, styles.selectionCell)}>
            <Checkbox
              className={styles.checkbox}
              checked={isSelected}
              disabled={!enableSelectionForRow(row) || isFetching}
              onChange={() => onClickSelect(row)}
            />
          </td>
        )}

        {columns.map((col, i) => (
          <td
            className={styles.tableCell}
            key={`desk-row-${getRowId(row)}-${i}`}
            style={{ width: col.width || "auto" }}
          >
            {col.cellDesktop(row)}
          </td>
        ))}
      </tr>

      {!!isExpanded && <RenderSubRow row={row} isMobile={false} />}
    </Fragment>
  );
}

function ArrowExpand({
  disabled,
  isExpanded,
  onClick = noop,
}: {
  disabled: boolean | undefined;
  isExpanded: boolean | undefined;
  onClick?: () => void;
}) {
  const isMobile = useIsMobile();
  return (
    <button
      className={cn(styles.arrowExpand, isMobile && styles.mobile)}
      onClick={() => onClick()}
      disabled={disabled}
    >
      <div
        className={`${styles.triangle} ${!isExpanded ? styles.rotate90 : ""}`}
      />
      <span>{!!isMobile && "Discover More"}</span>
    </button>
  );
}

function getSortDir(
  sortBy: ColId | undefined,
  sortDir: "asc" | "desc" | undefined,
  col: TableCustomerSideColumnDef<any>,
) {
  if (col.id !== sortBy) {
    return "asc";
  }
  if (sortDir === "asc") {
    return "desc";
  }
  if (sortDir === "desc") {
    return;
  }
  return "asc";
}

function getIsSortEnabled(col: TableCustomerSideColumnDef<any>) {
  return col.id && col.getSortingValue;
}

function LoadingSpinner() {
  return (
    <Spinner
      animation="border"
      size="sm"
      style={{
        height: "18px",
        width: "18px",
        opacity: 0.5,
      }}
    />
  );
}
