import styles from "./index.module.css";
import { CardCvcElement } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeElementType,
} from "@stripe/stripe-js";
import { cn } from "src/app/utils/cn";

export const emptyCardCvcElementValue: StripeCardCvcElementChangeEvent = {
  empty: true,
  complete: false,
  error: {
    type: "validation_error",
    code: "custom message",
    message: "Your card's security code is incomplete.",
  },
  elementType: "cardCvc",
};

type Props = {
  onChange: (value: StripeCardCvcElementChangeEvent) => void;
  onBlur?: (event: { elementType: StripeElementType }) => any;
  className?: string;
};

export const InputCardCvc = ({ onChange, onBlur, className = "" }: Props) => {
  return (
    <CardCvcElement
      id="input-card-cvc"
      className={cn(styles.root, className)}
      onChange={(value: StripeCardCvcElementChangeEvent) => {
        if (!value.complete && !value.error) {
          onChange({
            ...value,
            error: emptyCardCvcElementValue.error,
          });
        } else {
          onChange(value);
        }
      }}
      onBlur={onBlur}
    />
  );
};
