import styles from "./index.module.css";
import { BottomLink } from "./bottom-link";
import { path } from "src/app/routes/path";
import { Scrollable } from "src/app/components/scrollable";
import { MenuItem } from "./menu-item";
import { CloseButton } from "./close-button";
import { cn } from "src/app/utils/cn";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { PinButton } from "./pin-button";
import { Button } from "src/app/components/button";
import { A } from "src/app/components/a";
import { RxAvatar } from "react-icons/rx";
import { FaRegEnvelope } from "react-icons/fa";
import { SlWallet } from "react-icons/sl";
import { IoKeyOutline } from "react-icons/io5";
import { ReactComponent as Logo } from "src/media/logos/logo.svg";
import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as AuctionIcon } from "../icons/auction.svg";
import { ReactComponent as ShopIcon } from "../icons/shop.svg";
import { ReactComponent as WineIcon } from "../icons/privatesales.svg";
import { ReactComponent as ClipboardIcon } from "../icons/activities.svg";
import { ReactComponent as BookIcon } from "../icons/mycellar.svg";
import { ReactComponent as BoxIcon } from "../icons/shipment.svg";
import { ReactComponent as TimeclipboardIcon } from "../icons/outstandingpayments.svg";

import { ID_ROLE } from "src/app/const";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useRef } from "react";

type Props = {
  isPinned: boolean;
  onUnpin: () => void;
  onPin: () => void;
};

export const SidebarMenu = ({ isPinned, onUnpin, onPin }: Props) => {
  const ref = useRef<HTMLElement | null>(null);
  const isMobile = useIsMobile();
  const user = useMyUser();

  return (
    <aside
      ref={ref}
      data-nav // used to style menu items
      className={cn(
        styles.nav,
        isPinned ? styles.pinned : styles.unpinned,
        isMobile ? styles.mobile : styles.desktop,
      )}
      onClick={(e) => {
        // unpin on mobile when clicking a link
        const isBtnOrLink = !!(e.target as HTMLDivElement).closest("a,button");
        if (!isBtnOrLink || !isMobile) return;
        onUnpin();
      }}
    >
      <div>
        {!!isMobile && <CloseButton onClose={onUnpin} />}

        <div className={styles.top}>
          <Button
            as={A}
            to="/"
            className={styles.logo}
            tooltip="Go to Dashboard"
            icon={<Logo />}
            variant="ghost"
          />
        </div>

        <div className={styles.navlinks}>
          <Scrollable className={styles.scrollable} hideX>
            <ul className={styles.ul}>
              <MenuItem
                isSidebarPinned={isPinned}
                label="Profile"
                to={path.profile.value()}
                hide={!isMobile}
                icon={<RxAvatar />}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Dashboard"
                to={path.dashboard()}
                icon={<HomeIcon />}
              />
              {/* <MenuItem
                isSidebarPinned={isPinned}
                label="Bordeaux En Primeur"
                to={path.store.enPrimeur()}
                icon={<PiBeerBottleFill />}
              /> */}
              <MenuItem
                isSidebarPinned={isPinned}
                label="Weekly Offers"
                to={path.weekly.value()}
                icon={<AuctionIcon />}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Shop"
                to={path.store.shop()}
                icon={<ShopIcon />}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Private Sale"
                to={path.store.privateSale()}
                icon={<WineIcon style={{ transform: "translateX(2px)" }} />}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Your Activities"
                to={path.myActivities.value()}
                icon={<ClipboardIcon />}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Your Cellar"
                to={path.myAssets.value()}
                icon={<BookIcon />}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Your Shipments"
                to={path.shipments()}
                icon={<BoxIcon style={{ transform: "translateX(1px)" }} />}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Outstanding Payments"
                to={path.outstandingPayments()}
                icon={
                  <TimeclipboardIcon style={{ transform: "translateX(2px)" }} />
                }
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Contact us"
                to={path.contact()}
                icon={<FaRegEnvelope size={18} />}
                hide={!isMobile}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Wallet NFT"
                to={path.walletNft(user.addressKey)}
                icon={<SlWallet size={18} />}
                hide={!isMobile}
              />
              <MenuItem
                isSidebarPinned={isPinned}
                label="Admin Panel"
                to={path.adminLegacy()}
                icon={<IoKeyOutline size={20} />}
                hide={
                  ![
                    ID_ROLE.ADMIN,
                    ID_ROLE.SALES_MANAGER,
                    ID_ROLE.BD_MANAGER,
                    ID_ROLE.LOGISTICS,
                    ID_ROLE.PRODUCT_MANAGER,
                    ID_ROLE.SUPPLIER,
                    ID_ROLE.ACCOUNTING_PROFILE,
                    ID_ROLE.PRO_ADMIN,
                    ID_ROLE.SUPER_ADMIN,
                    ID_ROLE.SALES_TEAM,
                  ].includes(+user.roles)
                }
              />
            </ul>
          </Scrollable>
        </div>

        <div className={styles.bottom}>
          <ul>
            {!!isMobile && <BottomLink to={path.logout()} label="Logout" />}
          </ul>
          {!isMobile && (
            <PinButton
              isSidebarPinned={isPinned}
              onUnpin={onUnpin}
              onPin={onPin}
            />
          )}
        </div>
      </div>
    </aside>
  );
};
