import styles from "./ListAuctionContainer.module.css";

export default function ListAuctionContainer({ auction, index }) {
  return (
    <div className={styles.listAuctionElement}>
      <div className={styles.listAuctionElementBox}>
        <div className={styles.indexElement}>{index + 1}</div>
        <div className={styles.imgContainer}>
          <img
            className={
              auction?.lot?.components[0]?.media?.cover
                ? styles.bottleImg
                : styles.experienceImg
            }
            src={
              auction?.lot?.components[0]?.media?.cover ||
              auction?.experience?.images[0]?.image
            }
            alt={auction?.description}
          />
        </div>
        <div className={styles.description}>
          {auction?.description.length > 75
            ? auction?.description.substring(0, 75) + " ..."
            : auction?.description}
        </div>
      </div>
      <div className={styles.price}>
        {auction?.currentPrice
          ?.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
        €
      </div>
    </div>
  );
}
