import { Banner } from "./Banner";
import { useLocation } from "react-router-dom";
import { useMyUser } from "src/app/hooks/useMyUser";
import { path } from "src/app/routes/path";
import { UserV1 } from "src/app/models/UserV1";
import { PromptSetPassword } from "./prompt-set-password";
import { BannerMessage } from "./banner-message";

export const CheckUserProfileIssues = () => {
  const { pathname } = useLocation();
  const { checkUser, has_pending_gifts } = useMyUser();

  return (
    <>
      {checkUser.warnings
        // remove the shipping address warning in "My Assets"
        .filter((warning) =>
          pathname === path.myAssets.value()
            ? true
            : warning.id !== "shipping_address",
        )
        .slice(0, 1)
        .map((warning, index) =>
          isBanner(warning) ? (
            <Banner key={`warning-user-${index}`} {...warning} />
          ) : // Not sure if there might be other modal types coming from the API
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          isModal(warning) && warning.modal === "password-change" ? (
            <PromptSetPassword title={warning.message} key="promt-set-pwd" />
          ) : null,
        )}
      {!!has_pending_gifts?.status && (
        <BannerMessage
          title="Congratulations!"
          key="warning-user-gift"
          linkText="Redeem"
          linkTo="/pendingGifts"
          message={`You have ${has_pending_gifts.qty} gifts to redeem!`}
        />
      )}
    </>
  );
};

type Warning = UserV1["checkUser"]["warnings"][number];
type BannerWarning = Extract<Warning, { type: "warning" }>;
type ModalWarning = Extract<Warning, { type: "modal" }>;

function isBanner(warning: Warning): warning is BannerWarning {
  return warning.type === "warning";
}

function isModal(warning: Warning): warning is ModalWarning {
  return warning.type === "modal";
}
