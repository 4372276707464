import { AiOutlineClose } from "react-icons/ai";
import styles from "./close-button.module.css";

type Props = { onClose: () => void };

export const CloseButton = ({ onClose }: Props) => {
  return (
    <button
      className={styles.root}
      onClick={() => onClose()}
      title="Close Sidebar"
    >
      <AiOutlineClose className={styles.icon} style={{ fontSize: 24 }} />
    </button>
  );
};
