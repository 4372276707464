import { useThrottleFn } from "src/app/hooks/useThrottleFn";
import styles from "./slider.module.css";
import * as RadixSlider from "@radix-ui/react-slider";

type Props = {
  spaceTopPercentage: number;
  quantity: number;
  onChange: (quantity: number) => void;
  max: number;
  min: number;
  step: number;
  disabled: boolean;
};

export const Slider = ({
  spaceTopPercentage,
  quantity,
  onChange,
  max,
  min,
  step,
  disabled,
}: Props) => {
  const handleChange = useThrottleFn(([value]: [number]) => {
    onChange(Math.max(min, value));
  }, 100);

  return (
    <RadixSlider.Root
      className={styles.root}
      style={{ top: `${spaceTopPercentage}%` }}
      value={[quantity]}
      onValueChange={handleChange}
      orientation="vertical"
      max={max}
      min={0}
      step={step}
      disabled={disabled}
    >
      <RadixSlider.Track className={styles.track}>
        <RadixSlider.Range className={styles.range} />
      </RadixSlider.Track>
      <RadixSlider.Thumb className={styles.thumb} />
    </RadixSlider.Root>
  );
};
