import styles from "./index.module.css";
import { Dialog as HeadlessDialog } from "@headlessui/react";
import { noop } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { useLatest } from "react-use";
import { Scrollable } from "../scrollable";
import { Button } from "../button";
import { MdClear } from "react-icons/md";
import { cn } from "src/app/utils/cn";

const exitAnimationDuration = 150;
const enterAnimationDuration = 200;

interface DialogProps {
  title?: string;
  onClosed?: () => void;
  render: (props: { close: () => void }) => ReactNode;
  disableClose?: boolean;
  mandatory?: boolean;
  width?: string | number;
}

/**
 * @deprecated
 */
export function Dialog({
  title = "",
  onClosed = noop,
  render,
  disableClose = false,
  mandatory = false,
  width = "auto",
}: DialogProps) {
  const onClosedRef = useLatest(onClosed);
  const isMutating = !!useIsMutating();
  const [isOpen, setIsOpen] = useState(true);

  function internalClose() {
    if (disableClose || isMutating || mandatory) return;
    setIsOpen(false);
  }

  function publicClose() {
    if (disableClose || isMutating) return;
    setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen) return;
    const token = setTimeout(
      () => onClosedRef.current(),
      exitAnimationDuration,
    );
    return () => clearTimeout(token);
  }, [isOpen, onClosedRef]);

  const animationDuration = isOpen
    ? `${enterAnimationDuration}ms`
    : `${exitAnimationDuration}ms`;

  return (
    <HeadlessDialog
      open={isOpen}
      className={cn(styles.dialog, !isOpen && styles.closing)}
      style={{ animationDuration }}
      onClose={internalClose}
    >
      <div
        className={cn(styles.backdrop, mandatory && styles.backdropDarker)}
        aria-hidden="true"
      />

      <HeadlessDialog.Panel className={styles.panel} style={{ width }}>
        <div className={styles.heading}>
          <HeadlessDialog.Title className={styles.title}>
            {title}&nbsp;
          </HeadlessDialog.Title>
          <button
            // takes the initial focus to avoid opening the close button tooltip by default
            style={{
              position: "absolute",
              opacity: "0",
              pointerEvents: "none",
            }}
          />
          <Button
            variant="ghost"
            icon={<MdClear size={24} />}
            tooltip=""
            aria-label="Close"
            className={cn(styles.close, mandatory && styles.hide)}
            onClick={internalClose}
          />
        </div>

        <Scrollable
          className={styles.scrollable}
          viewportClassName={styles.viewport}
        >
          {render({ close: publicClose })}
        </Scrollable>
      </HeadlessDialog.Panel>
    </HeadlessDialog>
  );
}
