import styles from "./banner-carousel.module.css";
import { CSSProperties, ReactElement, useState } from "react";
import {
  ShadcnCarousel,
  ShadcnCarouselApi,
  ShadcnCarouselContent,
  useDotButton,
} from "../shadcn-carousel";
import { cn } from "src/app/utils/cn";
import Autoplay from "embla-carousel-autoplay";
import { useWindowSize } from "react-use";

export const bannerMobileBreakpoint = 700;

type Props = {
  style?: CSSProperties;
  slides: ReactElement[];
};

export const BannerCarousel = ({ style, slides }: Props) => {
  const [carouselApi, setCarouselApi] = useState<ShadcnCarouselApi>();
  const { scrollSnaps, onDotButtonClick, selectedIndex } =
    useDotButton(carouselApi);
  const windowsSize = useWindowSize();

  return (
    <ShadcnCarousel
      plugins={[Autoplay({ delay: 6000 })]}
      setApi={setCarouselApi}
      gap={16}
      className={styles.root}
      style={style}
    >
      <ShadcnCarouselContent
        overflow={
          windowsSize.width < bannerMobileBreakpoint ? "hidden" : "visible"
        }
        className={styles.content}
      >
        {slides}
      </ShadcnCarouselContent>
      {scrollSnaps.length > 1 && (
        <div className={styles.pagination}>
          {scrollSnaps.map((_, index) => (
            <button
              key={`ind-bef-${index}`}
              tabIndex={-1}
              className={cn(
                styles.dotButton,
                index === selectedIndex && styles.active,
              )}
              onClick={() => {
                onDotButtonClick(index);
              }}
            >
              <div />
            </button>
          ))}
        </div>
      )}
    </ShadcnCarousel>
  );
};
