import { ReactComponent as LogoWithLabel } from "src/images/common/logowithlabel.svg";
import videoSrc from "src/media/bg/Pouring.mp4";
import styles from "./leftbar.module.css";

export const Leftbar = () => {
  return (
    <div className={styles.root}>
      <video
        className={styles.video}
        playsInline
        muted
        loop
        autoPlay
        src={videoSrc}
      />
      <div className={styles.filter1} />
      <div className={styles.filter2} />
      <div className={styles.noiseFilter} />
      <LogoWithLabel className={styles.logoWithLabel} />
    </div>
  );
};
