import { CreditCardRow } from "./credit-card-row";
import SkeletonAllCards from "../../../modules/Includes/CustomerSide/components/skeleton/SkeletonAllCards";
import {
  useAvailablePaymentMethodsQuery,
  useMyCardsQuery,
} from "src/app/api/payments";
import { AddCardForm } from "./add-card-form";
import { PaymentMethod } from "./payment-method";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "src/app/hooks/useLoadStripe";

const stripePromise = getStripe();

export function MyPayments() {
  const myCardsQuery = useMyCardsQuery();
  const availablePaymentMethodsQuery = useAvailablePaymentMethodsQuery();

  return (
    <Elements stripe={stripePromise}>
      <div>
        <h3 style={{ fontSize: "1.75rem", color: "#111" }}>Payment Methods</h3>
        <p style={{ fontSize: "0.875rem" }}>
          Please note that you will be able to pay via Bank Transfer only the
          wines not your Subscription Fee.
        </p>
        <div style={{ margin: "20px 0 24px" }}>
          {!availablePaymentMethodsQuery.isLoading &&
            !availablePaymentMethodsQuery.data?.length &&
            "No payment method available"}

          {availablePaymentMethodsQuery.data?.map((method_payment, key) => (
            <PaymentMethod
              key={`payment-method-${key}`}
              method_payment={method_payment}
            />
          ))}
        </div>

        <div>
          {!!myCardsQuery.isLoading && (
            <div className="my-cards-table">
              <SkeletonAllCards />
            </div>
          )}

          {myCardsQuery.data?.map((item, index) => (
            <div style={{ marginBottom: "5px" }} key={`card.card.${index}`}>
              <CreditCardRow cardData={item} />
            </div>
          ))}

          <div style={{ marginTop: "24px" }}>
            {!myCardsQuery.isLoading &&
              !availablePaymentMethodsQuery.isLoading && <AddCardForm />}
          </div>
        </div>
      </div>
    </Elements>
  );
}
