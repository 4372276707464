import styles from "./title.module.css";
import { usePageTitleStore } from "src/app/hooks/usePageTitle";

export const Title = () => {
  const pageTitle = usePageTitleStore((state) => state.pageTitle);
  return (
    <h1
      className={styles.root}
      dangerouslySetInnerHTML={{ __html: pageTitle }}
    />
  );
};
