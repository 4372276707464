import {
  SelectMultipleOption,
  SelectMultipleValues,
} from "../components/form-elements/select-multiple/types";

export const useAuctionFilters = ({
  data,
  queryParams,
  rawFilters,
}: {
  data?: Record<
    string,
    {
      filters: {
        label: string | number;
        total: number;
      }[];
      label: string;
    }
  >;
  queryParams: Record<
    string,
    string | number | (string | number | boolean)[] | undefined
  >;
  rawFilters: SelectMultipleValues;
}) => {
  const filterOptions: Record<string, SelectMultipleOption[]> = data
    ? Object.entries(data).reduce(
        (allOptions, [serializedGroupName, groupOptions]) => {
          allOptions[serializedGroupName] = groupOptions.filters.map(
            (filter) => ({
              label: filter.label,
              count: filter.total,
              value: filter.label,
              groupName: groupOptions.label,
              serializedGroupName,
            }),
          );

          return allOptions;
        },
        {} as Record<string, SelectMultipleOption[]>,
      )
    : {};

  // omit "search", "sort" from filters and ensure valid keys AND values
  const allowedFiltersGroupNames = Object.keys(data || {});
  const filtersValues: SelectMultipleValues = Object.keys(filterOptions).length
    ? Object.entries(queryParams).reduce(
        (filters, [serializedGroupName, values]) => {
          // Actual filters are always parsed as arrays
          if (
            !Array.isArray(values) ||
            !allowedFiltersGroupNames.includes(serializedGroupName)
          ) {
            return filters;
          }

          const allowedFiltersValues = values.filter((value) =>
            filterOptions[serializedGroupName].find((el) => el.value === value),
          );
          if (allowedFiltersValues.length) {
            filters[serializedGroupName] = allowedFiltersValues;
          }

          return filters;
        },
        {} as SelectMultipleValues,
      )
    : {};

  return {
    rawValues: rawFilters,
    values: filtersValues,
    options: filterOptions,
  } as const;
};
