import styles from "./warning-banner.module.css";
import { noop } from "lodash";
import { FaExclamationTriangle } from "react-icons/fa";
import { Button } from "src/app/components/button";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";

type Props = {
  message: string;
  onClickPrimary: () => void;
  onClickSecondary?: () => void;
  primaryText: string;
  secondaryText?: string;
};

export function WarningBanner({
  message = "",
  onClickPrimary = noop,
  onClickSecondary = noop,
  primaryText = "",
  secondaryText = "",
}: Props) {
  const isMobile = useIsMobile();

  return (
    <div className={cn(styles.root, isMobile && styles.mobile)}>
      <div className={styles.body}>
        <div className={styles.header}>
          <FaExclamationTriangle
            style={{ color: "#d6a878", marginRight: "0.5rem" }}
          />
          Before bidding
        </div>

        <div
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>

      <div className={styles.actions}>
        {!!primaryText && (
          <Button
            className={styles.button}
            onClick={onClickPrimary}
            label={primaryText}
          />
        )}
        {!!secondaryText && (
          <Button
            className={styles.button}
            onClick={onClickSecondary}
            label={secondaryText}
          />
        )}
      </div>
    </div>
  );
}
