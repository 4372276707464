import {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useEffect,
} from "react";
import { useFieldCtx } from "./field";
import { useDomId } from "src/app/hooks/useDomId";

const defaultComponent = "div" as const;

type Props<TComponent extends ElementType<any>> =
  ComponentPropsWithoutRef<TComponent> & {
    as?: TComponent;
    id?: never;
    children: ReactNode;
  };

export const Description = <
  TComponent extends ElementType<any> = typeof defaultComponent,
>({
  as,
  children,
  ...props
}: Props<TComponent>) => {
  const { setDescriptionIds } = useFieldCtx();
  const Comp = as || defaultComponent;
  const id = useDomId();

  useEffect(() => {
    setDescriptionIds((ids) => [...ids, id]);
    return () => setDescriptionIds((ids) => ids.filter((_id) => _id !== id));
  }, [setDescriptionIds, id]);

  return (
    <Comp id={id} {...props}>
      {children}
    </Comp>
  );
};
