import { cn } from "src/app/utils/cn";
import styles from "./index.module.css";
import { ReactNode } from "react";
import { Spacer } from "../spacer";
import { RenderIcon } from "../button/render-icon";

export type BadgePaletteType =
  | "info"
  | "success"
  | "warning"
  | "mild-danger"
  | "danger"
  | "pastel-blue"
  | "pastel-brown"
  | "pastel-pink"
  | "pastel-yellow"
  | "pastel-green"
  | "pastel-purple"
  | "pastel-deep-purple"
  | "black";

type Props = {
  label: string;
  palette: BadgePaletteType;
  icon?: ReactNode;
};

// TODO: adattare a altri casi d'uso
// (vedere src/app/modules/Includes/CustomerSide/components/my-offers/MyOffersStatus.js)
export const Badge = ({ label, palette, icon }: Props) => {
  return (
    <span className={cn(styles.badge, styles[palette])}>
      {!!icon && (
        <>
          <RenderIcon icon={icon} />
          <Spacer size={6} />
        </>
      )}
      {label}
    </span>
  );
};
