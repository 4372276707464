import { Fragment, ReactNode, useId } from "react";
import { MobileCellContentCurrency } from "./cell-content-currency";
import { formatCurrency } from "src/app/utils/formatCurrency";

type Props = {
  total?: number;
  details: Array<{ amount?: number; label?: ReactNode }>;
  paid?: boolean | number | string; // TODO: boolean only
};

export const MobileCellContentTotalPrice = ({
  total,
  details,
  paid = true,
}: Props) => {
  const id = useId();
  const _paid = typeof paid === "string" ? paid !== "0" : !!paid;
  const _total =
    total ?? details.reduce((tot, { amount = 0 }) => tot + amount, 0);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
        <MobileCellContentCurrency amount={_total} />
        {!_paid && (
          <div
            style={{
              color: "#e33f3f",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            Not Paid
          </div>
        )}
      </div>

      <div
        style={{
          fontSize: "11px",
          marginTop: "4px",
        }}
      >
        {details.map(({ amount, label }, i) => (
          <Fragment key={`${id}-${i}`}>
            {formatCurrency(amount)} {label}
            {details[i + 1]?.amount !== undefined && " + "}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
