import styles from "./cell-content-actions.module.css";
import { ReactNode, useState } from "react";

type Props = {
  disabled: boolean;
  actions: Array<false | { label: ReactNode; onClick: () => void }>;
};

export const DesktopCellContentActions = ({
  disabled,
  actions = [],
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const _actions = actions.filter(Boolean);

  return (
    <div>
      <div className={styles.root}>
        <button
          className={styles.button}
          onClick={() => setIsOpen(true)}
          disabled={disabled || !_actions.length}
        >
          <IconDots className={styles.icon} />
        </button>

        {!!isOpen && (
          <ul className={`fadeIn ${styles.ul}`}>
            {_actions.map((action) => (
              <li
                key={`action-${action.label}`}
                className={styles.li}
                onClick={() => {
                  setIsOpen(false);
                  action.onClick();
                }}
              >
                {action.label}
              </li>
            ))}
          </ul>
        )}

        {!!isOpen && (
          <div className={styles.backdrop} onClick={() => setIsOpen(false)} />
        )}
      </div>
    </div>
  );
};

const IconDots = ({ className = "" }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
    />
  </svg>
);
