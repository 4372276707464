import { useId } from "react";
import bottleRedSrc from "src/media/wine-red.svg";
import bottleRoseSrc from "src/media/wine-rose.svg";
import bottleWhiteSrc from "src/media/wine-white.svg";
import bottleBrutSrc from "src/media/wine-brut-nature-blanc-de-blancs.svg";
import { ApiLotV1 } from "src/app/models/LotV1";

type Props = {
  lot: ApiLotV1;
  className?: string;
};

export function CellContentBottleCount({ lot, className = "" }: Props) {
  const id = useId();

  const colorToCountMap = lot.components.reduce(
    (acc, product) => {
      product.color
        .split(",")
        .map((color) => color.trim())
        .forEach((color) => {
          const key = color
            .toLowerCase()
            .replace("é", "e")
            .replace(/\s+/g, "-");
          if (!(acc[color] as { key: string; count: number } | undefined)) {
            acc[color] = { key, count: 0 };
          }
          acc[color] = {
            key,
            count: (acc[color].count || 0) + product.quantity,
          };
        });

      return acc;
    },
    {} as { [key: string]: { key: string; count: number } },
  );

  return (
    <div className={`${className}`} style={{ display: "flex", gap: "6px" }}>
      {Object.entries(colorToCountMap)
        .sort((a, b) => (a[1].count > b[1].count ? -1 : 1))
        .map(([colorName, color]) => (
          <div
            key={`${id}-${color.key}`}
            title={`${color.count} bottles of ${colorName}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={
                color.key === "red"
                  ? bottleRedSrc
                  : color.key === "rose"
                    ? bottleRoseSrc
                    : color.key === "white"
                      ? bottleWhiteSrc
                      : bottleBrutSrc
              }
              alt={color.key}
            />
            <div
              style={{ whiteSpace: "nowrap", fontSize: "12px" }}
            >{`x ${color.count}`}</div>
          </div>
        ))}
    </div>
  );
}
