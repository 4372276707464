import { z } from "zod";
import { apiLotSchema } from "./Lot";

export const apiLotSchemaV1 = apiLotSchema
  .extend({
    status: z.string(),
    statusNew: z.object({
      id: z.number(),
      label: z.string(),
    }),
    paid: z.boolean(),
    canGift: z.boolean(),
    canShip: z.boolean(),
    serials: z.array(z.string()),
    finance: z.object({
      emp: z.number(),
      pmc: z.number(),
      insurance: z.number(),
      ratio: z.number(),
      total: z.number(),
      total_insurance: z.number(),
    }),
    is_gift: z.boolean(),
    gift: z
      .object({
        sender: z.object({ personalData: z.object({ fullName: z.string() }) }),
      })
      .nullable(),
    quantity_chosen: z.number().optional(), // Used for shipment mutation
    auctions: z
      .object({
        description: z.string(),
      })
      .nullish(), // auctions.description in used in 'my-assets/lots' page, 'auctions' prop can probably be replace with AuctionBase model
  })
  .transform((lot) => ({
    ...lot,
    quantity_chosen: lot.serials.length,
  }));

export type ApiLotV1 = z.infer<typeof apiLotSchemaV1>;
