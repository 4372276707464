import styles from "./login-link.module.css";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";

export const LoginLink = () => {
  return (
    <h2 className={styles.root}>
      Already a Member? <A to={path.login()}>Login</A>
    </h2>
  );
};
