import { usePageTitle } from "src/app/hooks/usePageTitle";
import { PAGE_TITLE } from "src/app/const";
import { useOutstandingPaymentsQueryV1 } from "src/app/api/queries_to_rename_later";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { DesktopCellContentText } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-text";
import { useTableSelection } from "src/app/components/table-customer-side/useTableSelection";
import { DesktopCellContentTotalPrice } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-total-price";
import { TableProductType } from "src/app/modules/Includes/CustomerSide/components/cellar/TableProductType";

import { OutstandingPaymentV1 } from "src/app/models/OutstandingPaymentV1";
import { useMyUser } from "src/app/hooks/useMyUser";
import { CellAmountToPay } from "./cell-amount-to-pay";
import { OutstandingFilters } from "src/app/modules/Includes/CustomerSide/components/outstanding/outstanding-filters";

import { formatDate } from "src/app/utils/formatDate";
import { Spacer } from "src/app/components/spacer";
import { OutstandingPaymentStatus } from "./outstanding-payment-status";
import { MobileCellContentTotalPrice } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-total-price";
import { DesktopCellContentName } from "src/app/pages/outstanding-payments/cell-content-name-mobile";
import { MobileCellContentName } from "src/app/pages/outstanding-payments/cell-content-name-desktop";

export const OutstandingPaymentsPage = () => {
  usePageTitle(PAGE_TITLE.OUTSTANDING_PAYMENTS());
  const user = useMyUser();
  const query = useOutstandingPaymentsQueryV1();
  const selection = useTableSelection({ getRowId, data: query.data });

  return (
    <>
      <Spacer size={24} />
      <OutstandingFilters
        selectedData={selection.data}
        onSettled={() => {
          selection.set([]);
        }}
      />
      <Spacer size={24} />

      <TableCustomerSide
        urlPrefix=""
        data={query.data}
        getRowId={getRowId}
        isFetching={query.isFetching}
        isLoading={query.isLoading}
        selection={selection.rowIds}
        onChangeSelection={selection.set}
        enableSelectionForRow={(payment) =>
          !payment.notice_as_paid && (!!payment.stato || !!user.allow_executive)
        }
        noDataTitle="No Outstanding Payments"
        noDataContent={
          <div>
            <p>No Outstanding Payments</p>
          </div>
        }
        minCellHeightDesktop={108}
        columns={[
          {
            header: "",
            id: "photo",
            cellDesktop: (row) => (
              <TableProductType imgType={row.cellar_type} />
            ),
          },
          {
            header: "Name",
            id: "name",
            width: "40%",
            getSortingValue: (row) => row.name,
            cellDesktop: (row) => (
              <DesktopCellContentName
                content={row.name}
                serif
                minWidth={200}
                maxLines={2}
                details={row.productDetails}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentName
                content={row.name}
                serif
                details={row.productDetails}
              />
            ),
          },
          {
            header: "Qty",
            id: "quantity",
            getSortingValue: (row) => row.totalQuantity,
            cellDesktop: (row) => (
              <DesktopCellContentText content={row.displayTotalQuantity} />
            ),
            cellMobile: (row) => (
              <MobileCellContentText content={row.displayTotalQuantity} />
            ),
          },
          {
            header: "Total / Offer",
            id: "total_offer",
            alignHeaderDesktop: "right",
            getSortingValue: (row) => row.totalToPay,
            cellDesktop: (row) => (
              <DesktopCellContentTotalPrice
                total={row.totalToPay}
                details={[
                  { amount: row.bid, label: "price" },
                  { amount: row.processingFees, label: "processing fee" },
                ]}
                paid={row.totalPaid}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentTotalPrice
                total={row.totalToPay}
                details={[
                  { amount: row.bid, label: "price" },
                  { amount: row.processingFees, label: "processing fee" },
                ]}
                paid={row.totalPaid}
              />
            ),
          },
          {
            header: "Status",
            id: "status",
            alignHeaderDesktop: "left",
            getSortingValue: (row) => +row.notice_as_paid,
            cellDesktop: (row) => (
              <OutstandingPaymentStatus outstandingPayment={row} />
            ),
          },
          {
            header: "Amount to be paid",
            id: "amount_to_pay",
            getSortingValue: (row) => row.totalToPay,
            cellDesktop: (row) => <CellAmountToPay outstandingPayment={row} />,
          },
          {
            header: "Deadline Payment",
            id: "payment_deadline",
            width: "12%",
            getSortingValue: (row) => row.paymentDeadline || "",
            cellDesktop: (row) => (
              <DesktopCellContentText
                content={
                  row.paymentDeadline
                    ? formatDate(row.paymentDeadline, {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    : "-"
                }
                minWidth={150}
              />
            ),
          },
        ]}
      />
    </>
  );
};

function getRowId(payment: OutstandingPaymentV1) {
  return `${payment.key_unique}-${payment.cellar_type}`;
}
