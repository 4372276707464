import { ReactNode } from "react";
import styles from "./index.module.css";
import { Button } from "src/app/components/button";
import sendMailSrc from "src/media/Gift/sendemailicon.png";

export function StatusMessage({
  errorMessage,
  onClose,
}: {
  errorMessage?: string | ReactNode | null;
  onClose: () => void;
}) {
  return (
    <section>
      <div className={styles.sectionBody}>
        {errorMessage ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 24,
              textAlign: "start",
            }}
          >
            <h4>{errorMessage}</h4>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 24,
              textAlign: "start",
            }}
          >
            <img src={sendMailSrc} alt="Gift" />
            <h4>Gift sent successfully</h4>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 24,
        }}
      >
        <Button
          variant="outlined"
          label="Ok"
          style={{ minWidth: 150 }}
          type="button"
          onClick={onClose}
        />
      </div>
    </section>
  );
}
