import styles from "./radiogroup-address.module.css";
import { RadioGroup } from "@headlessui/react";
import { AddressV1 } from "src/app/models/Address";
import { Button } from "src/app/components/button";
import { noop, orderBy } from "lodash";
import { RadioGroupAddressOption } from "./radiogroup-address-option";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useUpdateEffect } from "react-use";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";

type Props = {
  onChange: (value: AddressV1) => void;
  selectedAddress?: AddressV1;
  initialSelectedAddress?: AddressV1;
  onEdit: (value: AddressV1) => void;
  onCreate: () => void;
  onConfirm?: () => void;
  "aria-labelledby"?: string | undefined;
  "aria-describedby"?: string | undefined;
  "aria-errormessage"?: string | undefined;
  "aria-invalid"?: true | undefined;
  id?: string;
  name?: string;
};

export const RadioGroupAddress = ({
  onEdit,
  onCreate,
  onConfirm = noop,
  initialSelectedAddress,
  onChange,
  selectedAddress,
  ...props
}: Props) => {
  const myUser = useMyUser();
  const config = useSystemConfig();
  const pickupAddress = config?.pickup_address;

  const shippingAddressList = orderBy(
    myUser.addressShipping,
    ["created_date", "confirmed", "active"],
    ["desc", "desc", "desc"],
  );

  useUpdateEffect(() => {
    initialSelectedAddress && onChange(initialSelectedAddress);
  }, [initialSelectedAddress]);

  return (
    <div>
      <RadioGroup
        className={styles.radioGroup}
        {...props}
        value={selectedAddress?.id_address}
        onChange={(addressId: number) => {
          let selectedAddress: AddressV1 | undefined;
          const isSelectingPickupAddress =
            pickupAddress && pickupAddress.id_address === addressId;
          if (isSelectingPickupAddress) {
            // TODO: review
            selectedAddress = { ...pickupAddress, phone: myUser.phone };
          } else {
            selectedAddress = myUser.addressShipping.find(
              (addr) => addr.id_address === addressId,
            );
          }
          selectedAddress && onChange(selectedAddress);
        }}
      >
        <p className={styles.pickupAddressLabel}>
          Select your shipping address
        </p>

        {shippingAddressList.length ? (
          shippingAddressList.map((address) => (
            <RadioGroupAddressOption
              key={`address.${address.id_address}`}
              address={address}
              pendingVerification={!address.active && !address.confirmed}
              onEdit={onEdit}
            />
          ))
        ) : (
          <p>No address found</p>
        )}
        {!!pickupAddress && (
          <>
            <p className={styles.pickupAddressLabel}>
              or pickup at our warehouse
            </p>
            <RadioGroupAddressOption address={pickupAddress} />
          </>
        )}
      </RadioGroup>

      <div className={styles.actions}>
        <Button
          type="button"
          onClick={() => onConfirm()}
          disabled={!selectedAddress}
          label="Select this address"
          variant="contained"
        />
        <Button
          type="button"
          onClick={() => onCreate()}
          label="Add new Address"
          variant="outlined"
        />
      </div>
    </div>
  );
};
