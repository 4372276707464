import { Img } from "src/app/components/img";
import styles from "./banner-slide.module.css";
import { useWindowSize } from "react-use";
import { bannerMobileBreakpoint } from "./banner-carousel";
import { ShadcnCarouselItem } from "../shadcn-carousel";
import { CSSProperties, ReactNode } from "react";
import { cn } from "src/app/utils/cn";

type Props = {
  alt: string;
  srcMobile: string;
  srcDesktop: string;
  /**
   * Hide the slide on a specific date
   */
  endDateExcluded?: { day: number; month: number; year: number };
  className?: string;
  style?: CSSProperties;
  /**
   * Markup to add appart from the image, put links here.
   *
   * Note: the markup takes the whole size of the slide by default
   */
  markup?: ReactNode;
};

export const BannerSlide = (props: Props) => {
  const windowsSize = useWindowSize();

  const { day, month, year } = props.endDateExcluded || {
    day: 1,
    month: 1,
    year: new Date().getFullYear() + 10,
  };

  // endDateExcluded = 10 year from now if omitted
  const showBanner =
    new Date().getTime() <= new Date(year, month, day).getTime();

  if (!showBanner) return null;

  return (
    <ShadcnCarouselItem>
      <div className={cn(props.className, styles.root)} style={props.style}>
        {windowsSize.width < bannerMobileBreakpoint ? (
          <Img src={props.srcMobile} alt={props.alt} className={styles.img} />
        ) : (
          <Img src={props.srcDesktop} alt={props.alt} className={styles.img} />
        )}
        {!!props.markup && <div className={styles.markup}>{props.markup}</div>}
      </div>
    </ShadcnCarouselItem>
  );
};
