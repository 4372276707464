import styles from "./index.module.css";
import PlacesAutocomplete, {
  Suggestion,
  geocodeByAddress,
} from "react-places-autocomplete";
import { noop } from "lodash";
import { useCheckAddressMutation } from "../../../api/adresses_to_rename_later";
import { useCountriesQuery } from "../../../api/queries_to_rename_later";
import { DropdownRoot } from "../_atoms/dropdown/root";
import { Fragment, useRef } from "react";
import { Input } from "../input";
import { DropdownOptionGroup } from "../_atoms/dropdown/option-group";
import { DropdownOption } from "../_atoms/dropdown/option";
import { DropdownContainer } from "../_atoms/dropdown/container";
import { MdOutlineLocationOn } from "react-icons/md";

export const emptyAddress: InputAddressValue = {
  full_text: "",
  address_line_1: "",
  city: "",
  country: 0,
  postal_code: "",
  region: "",
  error: "Incomplete address",
  warning: "",
  isCountryAvailableForShipping: true,
};

export type InputAddressValue = {
  full_text: string;
  address_line_1: string;
  city: string;
  country: number;
  postal_code: string;
  region: string;
  error: string;
  warning: string;
  isCountryAvailableForShipping: boolean;
  geocodeResult?: google.maps.GeocoderResult;
};

type Props = {
  className?: string;
  disabled?: boolean;
  fullAddressRequired?: boolean;
  id?: string;
  name?: string;
  notAvailableForShipping: "error" | "warn" | "ignore";
  onBlur?: () => void;
  onChange: (address: InputAddressValue) => void;
  placement?: "top" | "bottom";
  value: InputAddressValue;
  withPortal?: string | boolean;
};

export const InputAddress = ({
  onChange = noop,
  value,
  onBlur = noop,
  name,
  className = "",
  notAvailableForShipping,
  withPortal = false,
  placement = "bottom",
  disabled = false,
  id,
  fullAddressRequired = false,
}: Props) => {
  const anchorRef = useRef<HTMLInputElement | null>(null);

  const shippingCountriesIdsQuery = useCountriesQuery({
    select: (countries) =>
      countries.filter((c) => c.isEnableForShipping).map((c) => c.id),
  });

  const checkAddressMutation = useCheckAddressMutation(true);

  function handleChange(full_text: string) {
    setTimeout(() =>
      onChange({
        ...emptyAddress,
        full_text,
      }),
    );
  }

  async function handleSelectAddress(full_text: string) {
    onChange({
      ...emptyAddress,
      full_text,
      error: "Incomplete address",
    });

    const address = await geocodeByAddress(full_text);
    checkAddressMutation
      .mutateAsync({ full_text, address })
      .then((data) => {
        const isNotAvailableForShipping =
          !!shippingCountriesIdsQuery.data?.length &&
          !shippingCountriesIdsQuery.data.includes(data.country);

        let warning = "";
        let error = "";

        if (isNotAvailableForShipping) {
          if (notAvailableForShipping === "error")
            error = "This country is not available for shipping";
          if (notAvailableForShipping === "warn")
            warning = "This country is not available for shipping";
        }

        if (new Date() < new Date("2025-01-01") && !data.region && !data.city) {
          error = "The region / city is missing";
        }

        if (!data.full_text) {
          error = "This field is required";
        }

        if (fullAddressRequired && (!data.address_line_1 || !data.country)) {
          error = "Street or Country are missing";
        }

        onBlur();
        onChange({
          ...data,
          error,
          warning,
          isCountryAvailableForShipping:
            !data.country || !shippingCountriesIdsQuery.data?.length
              ? true
              : shippingCountriesIdsQuery.data.includes(data.country),
          geocodeResult: address[0],
        });
      })
      .catch(() => onBlur());
  }

  return (
    <PlacesAutocomplete
      value={value.full_text}
      onChange={handleChange}
      onSelect={handleSelectAddress}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <Fragment>
          <Input
            ref={anchorRef}
            className={className}
            name={name}
            id={id}
            {...getInputProps({
              placeholder: "Search Places...",
              disabled: disabled,
              onBlur,
            })}
          />

          {!!suggestions.length && (
            <DropdownRoot
              anchorRef={anchorRef}
              withPortal={withPortal}
              placement={placement}
            >
              <DropdownContainer>
                <DropdownOptionGroup>
                  {suggestions.map((suggestion) => (
                    <DropdownOption
                      {...getSuggestionItemProps(suggestion)}
                      key={`suggest-id-${suggestion.description}-${suggestion.index}`}
                      active={suggestion.description === value.full_text}
                      disabled={false}
                    >
                      {renderSuggestion({ suggestion })}
                    </DropdownOption>
                  ))}
                </DropdownOptionGroup>
              </DropdownContainer>
            </DropdownRoot>
          )}
        </Fragment>
      )}
    </PlacesAutocomplete>
  );
};

function renderSuggestion({ suggestion }: { suggestion: Suggestion }) {
  return (
    <div className={styles.suggestion}>
      <MdOutlineLocationOn size={18} />
      {suggestion.description}
    </div>
  );
}
