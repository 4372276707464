import styles from "./index.module.css";
import { BsCheck } from "react-icons/bs";
import { cn } from "../../../utils/cn";
import { forwardRef, useRef } from "react";
import { noop } from "lodash";
import { toPx } from "src/app/utils/toPx";

type Props = Omit<React.ComponentProps<"div">, "onChange"> & {
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  id?: string;
  name?: string;
  size?: string | number;
};

export const Checkbox = forwardRef<HTMLDivElement, Props>(
  (
    {
      checked,
      onChange = noop,
      disabled,
      className = "",
      id,
      name,
      size,
      style,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <>
        <input
          ref={inputRef}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          style={{ display: "none" }}
          disabled={disabled}
          id={id}
          name={name}
        />
        <div
          ref={ref}
          role="checkbox"
          aria-checked={checked}
          className={cn(
            styles.checkbox,
            disabled && styles.disabled,
            checked && styles.checked,
            className,
          )}
          style={size ? { fontSize: toPx(size), ...style } : style}
          onKeyDown={({ key }) =>
            ["NumpadEnter", "Enter"].includes(key) && inputRef.current?.click()
          }
          onClick={() => inputRef.current?.click()}
          tabIndex={0}
          {...props}
        >
          <BsCheck className={styles.icon} />
        </div>
      </>
    );
  },
);
