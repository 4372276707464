import { kebabCase } from "lodash";
import { SelectMultipleConfigEntry } from "./types";
import { MaybeArray } from "src/app/utils/types";

export function transformConfig<const TData extends object>(
  config: SelectMultipleConfigEntry<TData>[],
) {
  return config.map((entry) => ({
    groupName: entry.groupName,
    serializedGroupName: formatForUrl(entry.groupName),
    getValues(data: TData) {
      return ensureArray(entry.getValue(data)).map(formatForUrl);
    },
    getLabels(data: TData) {
      return ensureArray((entry.getLabel || entry.getValue)(data));
    },
  }));
}

function formatForUrl<const T>(input: T) {
  return (typeof input === "string" ? kebabCase(input) : input) as T;
}

function ensureArray<const T>(data: MaybeArray<T>) {
  return Array.isArray(data) ? data : [data];
}

export function isValidLabel(label: any): label is string | number {
  return (
    (typeof label === "string" && label !== "") || typeof label === "number"
  );
}
