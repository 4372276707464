import { ReactNode } from "react";
import styles from "./headline.module.css";

type Props = {
  children: ReactNode;
};

export const Headline = (props: Props) => {
  return <h1 className={styles.root}>{props.children}</h1>;
};
