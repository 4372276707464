import styles from "./index.module.css";
import { CardNumberElement } from "@stripe/react-stripe-js";
import {
  StripeCardNumberElementChangeEvent,
  StripeElementType,
} from "@stripe/stripe-js";
import { cn } from "src/app/utils/cn";

export const emptyCardNumberElementValue: StripeCardNumberElementChangeEvent = {
  empty: true,
  complete: false,
  error: {
    type: "validation_error",
    code: "custom message",
    message: "Your card number is incomplete.",
  },
  brand: "unknown",
  elementType: "cardNumber",
};

type Props = {
  onChange: (value: StripeCardNumberElementChangeEvent) => void;
  onBlur?: (event: { elementType: StripeElementType }) => any;
  className?: string;
};

export const InputCardNumber = ({
  onChange,
  onBlur,
  className = "",
}: Props) => {
  return (
    <CardNumberElement
      id="input-card-number"
      className={cn(styles.root, className)}
      onChange={(value: StripeCardNumberElementChangeEvent) => {
        if (!value.complete && !value.error) {
          onChange({
            ...value,
            error: emptyCardNumberElementValue.error,
          });
        } else {
          onChange(value);
        }
      }}
      onBlur={onBlur}
      options={{ showIcon: true }}
    />
  );
};
