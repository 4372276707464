import { download as downloadCsvLib, generateCsv } from "export-to-csv";
import { ReactNode, createContext, useContext } from "react";
import { ApiLotV1 } from "../models/LotV1";
import { ApiBarrelV1 } from "../models/BarrelV1";
import { csvEscape } from "../utils/csvEscape";

type ContextType = {
  barrelsCSV: (barrels: ApiBarrelV1[] | undefined) => void;
  bottlesCSV: (lots: ApiLotV1[] | undefined) => void;
};

const DownloadCtx = createContext<null | ContextType>(null);

export const DownloadProvider = (props: { children: ReactNode }) => {
  return (
    <DownloadCtx.Provider
      value={{
        barrelsCSV: (barrels = []) => {
          downloadCSV(
            "Barrels",
            barrels.map((barrel) => ({
              "Barrel Name": barrel.name,
              "Liters Purchased": barrel.liter_winner,
              "Liters Available": barrel.liters_available,
              Color: barrel.color,
              Region: barrel.product_region,
              "Cost (total)": `${barrel.total_bid}€`,
            })),
          );
        },
        bottlesCSV: (lots = []) => {
          downloadCSV(
            "Bottles",
            lots.flatMap((lot) =>
              lot.components.map((product) => ({
                "Lot SKU": lot.sku,
                "Lot Name": lot.case_,
                "Lot Q.ty": lot.serials.length.toString(),
                Status: lot.status,
                "Bottle SKU": product.sku,
                "Bottle Name": product.nameProductWithoutVintage,
                Size: product.size.toString(),
                Color: product.color,
                Winery: product.builder.name,
                Vintage: product.vintage.toString(),
                Region: product.region,
                "Bottle Q.ty (Per Lot)": product.quantity.toString(),
                "Bottle Q.ty (Total)": (
                  product.quantity * lot.serials.length
                ).toString(),
                "Price for Lot":
                  (
                    (lot.finance.pmc + lot.finance.insurance) /
                    product.quantity
                  ).toFixed(2) + "€",
              })),
            ),
          );
        },
      }}
    >
      {props.children}
      {/* Show some feedack on error */}
    </DownloadCtx.Provider>
  );
};

function downloadCSV(filename: string, data: any[]) {
  const config = {
    fieldSeparator: ";",
    quoteStrings: true,
    quoteCharacter: '"',
    decimalSeparator: ",",
    filename,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csv = generateCsv(config)(csvEscape(data));
  return downloadCsvLib(config)(csv);
}

export const useDownload = () => {
  const ctx = useContext(DownloadCtx);
  if (!ctx) {
    throw new Error("DownloadCtx not found");
  }
  return ctx;
};
