import { useEffect } from "react";
import { create } from "zustand";
import { PageTitle } from "../const";

type Store = {
  pageTitle: string;
  setPageTitle: (title?: string) => void;
};

export const usePageTitleStore = create<Store>((set) => ({
  pageTitle: "",
  setPageTitle: (title?: string) => {
    title && set(() => ({ pageTitle: title }));
  },
}));

export const usePageTitle = (title?: PageTitle) => {
  const setPageTitle = usePageTitleStore((state) => state.setPageTitle);

  useEffect(() => {
    setPageTitle(title);
  }, [title, setPageTitle]);
};
