import styles from "./menu-user.module.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { ID_ROLE, ID_TIER } from "src/app/const";
import { useMyUser } from "src/app/hooks/useMyUser";
import { path } from "src/app/routes/path";
import { Menu } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Button } from "src/app/components/button";
import { cn } from "src/app/utils/cn";
import { A } from "src/app/components/a";
import { useIsSmallDesktop } from "./useIsSmallDesktop";
import { RiExternalLinkLine } from "react-icons/ri";

export function MenuUser() {
  const user = useMyUser();
  const [isOpen, setIsOpen] = useState(false);
  const isSmallDesktop = useIsSmallDesktop();

  const isCustomer =
    !!user.id_customer_role || +user.roles === ID_ROLE.CUSTOMER;
  const canSubscribe =
    user.id_customer_role &&
    isCustomer &&
    ![ID_TIER.FOUNDER, ID_TIER.PROFESSIONAL].includes(user.id_customer_role);
  const hasWallet = user.addressKey !== "0";

  return (
    <Menu>
      <div
        className={styles.root}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onKeyDown={({ key }) => {
          ["Escape", "Tab"].includes(key) && setIsOpen(false);
        }}
      >
        <Menu.Button as={Fragment}>
          <Button
            variant="ghost"
            className={styles.trigger}
            label={user.roles === 2 ? user.type_customer : user.type_user}
            onClick={() => setIsOpen(true)}
            onKeyDown={({ key }) => {
              ["NumpadEnter", "Enter", " "].includes(key) &&
                setIsOpen((o) => !o);
              ["Escape", "Tab"].includes(key) && setIsOpen(false);
            }}
            icon={<IoMdArrowDropdown size={20} />}
          />
        </Menu.Button>

        {!!isOpen && (
          <Menu.Items className={styles.dropdownRoot} static>
            <div className={styles.dropdownInner}>
              <Menu.Item>
                {({ active }) => (
                  <A
                    className={cn(styles.menuItem, active && styles.active)}
                    onClick={() => setIsOpen(false)}
                    to={path.profile.value()}
                  >
                    Profile
                  </A>
                )}
              </Menu.Item>

              {!!isSmallDesktop && !!hasWallet && (
                <Menu.Item>
                  {({ active }) => (
                    <A
                      className={cn(styles.menuItem, active && styles.active)}
                      onClick={() => setIsOpen(false)}
                      to={path.walletNft(user.addressKey)}
                    >
                      Wallet &nbsp;
                      <strong>NFT</strong>
                      &nbsp; &nbsp;
                      <RiExternalLinkLine
                        size={16}
                        style={{ transform: "translateY(-1px)" }}
                      />
                    </A>
                  )}
                </Menu.Item>
              )}

              {!!canSubscribe && (
                <Menu.Item>
                  {({ active }) => (
                    <A
                      className={cn(styles.menuItem, active && styles.active)}
                      onClick={() => setIsOpen(false)}
                      to={path.profile.subscription()}
                    >
                      My Subscription
                    </A>
                  )}
                </Menu.Item>
              )}

              {!!canSubscribe && (
                <Menu.Item>
                  {({ active }) => (
                    <A
                      className={cn(styles.menuItem, active && styles.active)}
                      onClick={() => setIsOpen(false)}
                      to={path.profile.payments()}
                    >
                      Payments
                    </A>
                  )}
                </Menu.Item>
              )}

              <Menu.Item>
                {({ active }) => (
                  <A
                    className={cn(styles.menuItem, active && styles.active)}
                    onClick={() => setIsOpen(false)}
                    to={path.logout()}
                  >
                    Logout
                  </A>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        )}
      </div>
    </Menu>
  );
}

// export function MenuUser() {
//   const user = useMyUser();

//   const isCustomer =
//     !!user.id_customer_role || +user.roles === ID_ROLE.CUSTOMER;
//   const canSubscribe =
//     user.id_customer_role &&
//     isCustomer &&
//     ![ID_TIER.FOUNDER, ID_TIER.PROFESSIONAL].includes(user.id_customer_role);

//   return (
//     <div className="header-user-ddown">
//       <span className="header-cust-type">
//         {user.roles === 2 ? user.type_customer : user.type_user}
//       </span>
//       <div className="dropdown-menu">
//         <ul className="navi navi-hover py-4">
//           <li className={"navi-item"}>
//             <NavLink
//               to="/user-profile/account-information"
//               activeClassName="active"
//               style={{ whiteSpace: "nowrap" }}
//             >
//               Profile
//             </NavLink>
//           </li>

//           {!!canSubscribe && (
//             <li>
//               <NavLink
//                 to="/user-profile/my-subscriptions"
//                 activeClassName="active"
//                 style={{ whiteSpace: "nowrap" }}
//               >
//                 My Subscription
//               </NavLink>
//             </li>
//           )}

//           {!!canSubscribe && (
//             <li>
//               <NavLink
//                 to="/user-profile/method-payments"
//                 activeClassName="active"
//                 style={{ whiteSpace: "nowrap" }}
//               >
//                 Payments
//               </NavLink>
//             </li>
//           )}
//           <li>
//             <NavLink
//               to={path.logout()}
//               activeClassName="active"
//               style={{ whiteSpace: "nowrap" }}
//             >
//               Logout
//             </NavLink>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// }
