import styles from "./Root.module.css";
import React from "react";

export const Root = ({ children, className = "", style = {} }) => {
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};
