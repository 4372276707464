import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from "react";

type ContextValueType = {
  show: () => void;
  hide: () => void;
};

const SplashScreenContext = createContext<ContextValueType | null>(null);

export function SplashScreenProvider({ children }: { children: ReactNode }) {
  const [count, setCount] = useState(0);
  const isVisible = !!count;

  const show = useCallback(() => setCount((c) => ++c), []);

  const hide = useCallback(() => setCount((c) => Math.max(0, --c)), []);

  useEffect(() => {
    const splashScreen = document.getElementById("splash-screen");
    if (!splashScreen) return;
    isVisible
      ? splashScreen.classList.remove("hidden")
      : splashScreen.classList.add("hidden");
  }, [isVisible]);

  return (
    <SplashScreenContext.Provider
      value={useMemo(() => ({ show, hide }), [show, hide])}
    >
      {children}
    </SplashScreenContext.Provider>
  );
}

export function SplashScreen() {
  // Everything are ready - remove splashscreen
  const splash = useContext(SplashScreenContext);
  if (!splash) {
    throw new Error("No Splash screen context found");
  }

  useEffect(() => {
    splash.show();

    return () => {
      splash.hide();
    };
  }, [splash]);

  return null;
}
