import styles from "./back-link.module.css";
import { useHistory } from "react-router-dom";

export function BackLink() {
  const history = useHistory();

  // can't go back if there is no history entry
  if (history.length <= 1) return null;

  return (
    <button className={styles.root} onClick={() => history.goBack()}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7391 15.5104L5.45857 10.2299L17.2497 10.2299V7.7698L5.45857 7.7698L10.7391 2.48927L8.99984 0.75L0.75 8.99984L8.99984 17.2497L10.7391 15.5104Z"
          fill="currentColor"
        />
      </svg>
      Back
    </button>
  );
}
