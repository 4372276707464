import {
  CSSProperties,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { CruFormContextType, CruFormErrors, CruFormValues } from "./types";
import { useLatest, useMount } from "react-use";
import { MaybePromise } from "src/app/utils/types";

// no default value = crash if the context is not Provided
const CruFormContext = createContext<CruFormContextType | null>(null);

type State<TFormData extends CruFormValues, TSubError, TSubSuccess> = {
  values: CruFormContextType<TFormData>["values"];
  errors: CruFormContextType<TFormData>["errors"];
  touched: CruFormContextType<TFormData>["touched"];
  submission: CruFormContextType<
    TFormData,
    TSubError,
    TSubSuccess
  >["submission"];
};

type Props<TFormData extends CruFormValues, TSubError, TSubSuccess> = {
  initialValues: TFormData;
  children: (
    cruForm: CruFormContextType<TFormData, TSubError, TSubSuccess>,
  ) => ReactNode;
  onSubmit: (
    values: TFormData,
    cruForm: CruFormContextType<TFormData, TSubError, TSubSuccess>,
  ) => MaybePromise<TSubSuccess>;
  className?: string;
  style?: CSSProperties;
};

export const Form = <
  TFormData extends CruFormValues = CruFormValues,
  TSubError = any,
  TSubSuccess = any,
>({
  initialValues,
  children,
  onSubmit,
  className = "",
  style = {},
}: Props<TFormData, TSubError, TSubSuccess>) => {
  const onSubmitRef = useLatest(onSubmit);
  const validatorsRef = useRef<CruFormContextType["validators"]>({});

  const [isInitialized, setIsInitialized] = useState(false);
  useMount(() => setIsInitialized(true));

  const [state, setState] = useState<State<TFormData, TSubError, TSubSuccess>>({
    values: initialValues,
    touched: {},
    errors: {},
    submission: {
      isLoading: false,
      error: undefined,
      isError: false,
      success: undefined,
      isSuccess: false,
      values: undefined,
    },
  });

  const setValues: CruFormContextType<TFormData>["setValues"] = useCallback(
    (updater) => {
      setState((state) => {
        const newValues = shallowMerge(state.values, updater);

        const newErrors = Object.entries(validatorsRef.current).reduce(
          (acc, [name, validate]) => ({
            ...acc,
            [name]: validate?.(newValues[name], newValues) || undefined,
          }),
          state.errors,
        );

        return {
          ...state,
          errors: newErrors,
          values: newValues,
        };
      });
    },
    [],
  );

  const __setErrors: CruFormContextType<TFormData>["__setErrors"] = useCallback(
    (updater) => {
      setState((state) => {
        const newErrors = shallowMerge(state.errors, updater);

        return {
          ...state,
          errors: newErrors,
        };
      });
    },
    [],
  );

  const setTouched: CruFormContextType<TFormData>["setTouched"] = useCallback(
    (updater) => {
      setState((state) => {
        const newTouched = shallowMerge(state.touched, updater);

        return {
          ...state,
          touched: newTouched,
        };
      });
    },
    [],
  );

  const validateTouchedFields = useCallback(
    ({ alsoTouch }: { alsoTouch?: Array<keyof TFormData> } = {}) => {
      setTimeout(() => {
        setState((state) => {
          const newErrors = Object.entries(validatorsRef.current).reduce(
            (acc, [name, validate]) => ({
              ...acc,
              [name]: validate?.(state.values[name], state.values) || undefined,
            }),
            state.errors,
          );

          const newTouched = alsoTouch
            ? alsoTouch.reduce((newTouched, key) => {
                newTouched[key] = true;
                return newTouched;
              }, state.touched)
            : state.touched;

          return {
            ...state,
            errors: newErrors,
            touched: newTouched,
          };
        });
      });
    },
    [],
  );

  const tryToSubmit = (e?: React.FormEvent<Element>) => {
    e?.preventDefault();

    setState((state) => {
      // touch all validatable fields (= rendered fields with a "validate prop")
      const newTouched = Object.keys(validatorsRef.current).reduce(
        (acc, name) => ({ ...acc, [name]: true }),
        state.touched,
      );

      // set errors for validatable fields (= rendered fields with a "validate prop")
      const newErrors = Object.entries(validatorsRef.current).reduce(
        (acc, [name, validate]) => ({
          ...acc,
          [name]: validate?.(state.values[name], state.values) || undefined,
        }),
        state.errors,
      );

      const hasErrors = !!getErrorsList(newErrors).length;

      if (hasErrors) {
        const newState: typeof state = {
          ...state,
          touched: newTouched,
          errors: newErrors,
          submission: {
            isLoading: false,
            isError: false,
            isSuccess: false,
            error: undefined,
            success: undefined,
            values: undefined,
          },
        };
        return newState;
      }

      const newState: typeof state = {
        ...state,
        touched: newTouched,
        errors: newErrors,
        submission: {
          isLoading: true,
          isError: false,
          isSuccess: false,
          error: undefined,
          success: undefined,
          values: state.values,
        },
      };
      // Submit the form if no error is found
      queueMicrotask(() => submit(newState));
      return newState;
    });
  };

  async function submit(state: State<TFormData, TSubError, TSubSuccess>) {
    const values = state.values;
    try {
      const successResult = await onSubmitRef.current(values, {
        ...cruForm,
        ...state,
      });
      setState({
        ...state,
        submission: {
          success: successResult,
          error: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
          values,
        },
      });
    } catch (e: any) {
      const errorResult: TSubError = e;
      setState({
        ...state,
        submission: {
          success: undefined,
          error: errorResult,
          isLoading: false,
          isError: true,
          isSuccess: false,
          values,
        },
      });
    }
  }

  const cruForm: CruFormContextType<TFormData, TSubError, TSubSuccess> = {
    submission: state.submission,
    values: state.values,
    setValues,
    errors: state.errors,
    __setErrors,
    touched: state.touched,
    setTouched,
    validators: validatorsRef.current,
    hasErrors: !!getErrorsList(state.errors).length,
    isInitialized,
    validateTouchedFields,
    tryToSubmit,
  };

  return (
    <CruFormContext.Provider value={cruForm as CruFormContextType}>
      <form
        noValidate
        onSubmit={cruForm.tryToSubmit}
        className={className}
        style={style}
      >
        {children(cruForm)}
      </form>
    </CruFormContext.Provider>
  );
};

export function useCruForm<
  TFormData extends CruFormValues,
  TSubError = any,
  TSubSuccess = any,
>() {
  const ctx = useContext<CruFormContextType | null>(CruFormContext);
  if (!ctx) {
    throw new Error("CruFormContext not found");
  }
  return ctx as CruFormContextType<TFormData, TSubError, TSubSuccess>;
}

function getErrorsList(errors: CruFormErrors<any>) {
  return Object.values(errors).filter(Boolean);
}

function shallowMerge<T>(
  state: T,
  updater: Partial<T> | ((state: T) => Partial<T>),
): T {
  const partial = typeof updater === "function" ? updater(state) : updater;
  return { ...state, ...partial };
}
