import * as braze from "@braze/web-sdk";
import { useCallback } from "react";

export const useBraze = () => {
  const logCustomEvent = useCallback(
    (eventName: string, eventProperties?: Record<string, any>) => {
      braze.logCustomEvent(eventName, eventProperties);
    },
    [],
  );

  return {
    logCustomEvent,
  };
};
