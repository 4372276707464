import styles from "./index.module.css";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";

export const DevBadges = () => {
  const config = useSystemConfig();

  if (!config || config.dbIsProduction) return null;

  return (
    <div className={styles.root}>
      <div className={styles.badge}>DB TEST</div>
    </div>
  );
};
