import styles from "./HighestBids.module.css";
import Header from "./Header";
import ListAuctionContainer from "./ListAuctionContainer";
import { useAuctionsQueryForCharityPublicPages } from "./queries";
import { usePermanentScroll } from "./usePermanentScroll";
import { SpacerTop } from "./SpacerTop";
import { SpacerBottom } from "./SpacerBottom";
import { isEqual } from "lodash";

export const HIGHEST_BIDS_PATH = "/highest-bids";

export const HighestBids = () => {
  const { data } = useAuctionsQueryForCharityPublicPages(
    {
      forPage: "highest-bids-placed-today",
    },
    {
      onSuccess: (newData) => {
        if (isEqual(data, newData)) return;
        containerRef.current?.scrollTo({ top: 0 });
      },
    },
  );

  const { containerRef, toggleScroll } = usePermanentScroll();

  const filteredData = data?.filter((res) => {
    if (res.lastBidDate !== res.createdDate) {
      const auctionDate = new Date(res.lastBidDate);
      const currentDate = new Date();
      const timeDifference = currentDate - auctionDate;
      const maxTimeDifference = 72 * 60 * 60 * 1000;

      return timeDifference >= 0 && timeDifference <= maxTimeDifference;
    } else {
      return false;
    }
  });

  const totalRaised = data?.reduce((total, res) => {
    if (res.lastBidDate !== res.createdDate) {
      return total + res.currentPrice;
    } else {
      return total;
    }
  }, 0);

  return (
    <div className={styles.root}>
      <Header totalRaised={totalRaised} />
      <div>
        <div className={styles.highestBidsContainer}>
          <h4 className={styles.highestBids}>
            <strong>Highest Bids</strong>
          </h4>
        </div>
        <div
          className={styles.listAuctions}
          ref={containerRef}
          style={{ maxHeight: "68vh", overflowY: "auto" }}
          onClick={toggleScroll}
        >
          <div className={styles.listAuctionsContainer}>
            <SpacerTop />
            {filteredData?.map((res, index) => (
              <ListAuctionContainer auction={res} index={index} key={index} />
            ))}
            <SpacerBottom />
          </div>
        </div>
      </div>
    </div>
  );
};
