import {
  ID_AUCTION_TYPE,
  ID_PAYMENT_METHOD,
  TYPE_PRODUCT,
} from "src/app/const";
import { RootDictionary } from "..";

/**
 * Leggi il README.md
 */

export const auctionMessages: RootDictionary = {
  prompt: {
    default: {
      title: "Please Confirm Your Bid",
      content: (
        <>
          Please note, if your offer is accepted, it cannot be canceled. An
          automated payment will be issued using the method provided by you in
          your settings. <br /> <br />
          Do you want to confirm?
        </>
      ),
    },
    [`type-${ID_AUCTION_TYPE.SINGLE_LOTS},place-bid`]: {
      title: "Please Confirm Max Bid",
      content: (
        <>
          Our automated system will place bids at the next available increment
          to keep you in the lead, but will never exceed the maximum bid limit.
          Note that if your offer is accepted, it cannot be canceled. <br />
          <br /> Do you want to confirm your Max Bid?
        </>
      ),
    },
    [`type-${ID_AUCTION_TYPE.SINGLE_LOTS},increase_bid`]: {
      title: "Please Confirm Bid Increase",
      content: (
        <>
          Please note, if your offer is accepted, it cannot be canceled. An
          automated payment will be issued using the method provided by you in
          your settings. <br />
          <br /> Do you want to confirm your Max Bid?
        </>
      ),
    },
    [`type-${ID_AUCTION_TYPE.COLLECTION}`]: {
      title: "One step closer to making it yours",
      content:
        "Remember that all bids are binding. You agree to pay for this item if you are the highest bidder.",
    },
    [`product-${TYPE_PRODUCT.BARREL},type-${ID_AUCTION_TYPE.COLLECTION}`]: {
      title: "One step closer to making it yours",
      content:
        "Remember that all bids are binding. You agree to pay for this item if you are the highest bidder.",
    },
    [`type-${ID_AUCTION_TYPE.COLLECTION},buy-now`]: {
      title: "You are buying at",
      content:
        "Please note, this is an immediate purchase which cannot be canceled. An automated payment will be issued using the method provided by you in your settings.",
    },
  },
  success: {
    default: {
      title: "Thank You For Bidding!",
      content:
        "We will notify you if your offer is successful within 72 hours of the offer closing.",
    },
    [`type-${ID_AUCTION_TYPE.SINGLE_LOTS},place-bid`]: {
      title: "Thank You For Bidding!",
      content:
        "If the bidding passes your maximum amount, we'll notify you and you can increase your Max Bid if you wish so.",
    },
    [`type-${ID_AUCTION_TYPE.SINGLE_LOTS},increase_bid`]: {
      title: "You Have Increased Your Bid!",
      content:
        "If the bidding passes your maximum amount again, we'll notify you, so you can increase your Max Bid if you wish so.",
    },
    [`payment-${ID_PAYMENT_METHOD.CARD},buy-now`]: {
      title: "Congratulations!",
      content:
        "Your order is now confirmed, and your card was automatically charged. Go to Your Cellar to view all your purchases!",
    },
    [`payment-${ID_PAYMENT_METHOD.BANK},buy-now`]: {
      title: "Congratulations!",
      content:
        "Your order is now confirmed. Please proceed to settle your balance in outstanding payments and mark it as paid.",
    },
    [`product-${TYPE_PRODUCT.BARREL},payment-${ID_PAYMENT_METHOD.CARD},buy-now`]:
      {
        title: "Thank You For Your Purchase!",
        content:
          "A member of our team will approve your order, and process the payment. Afterwhich, you will be able to convert your purchased liters to the available formats in Your Cellar.",
      },
  },
};
