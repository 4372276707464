import { CSSProperties } from "react";
import { BannerCarousel } from "./banner-carousel";
import { BannerSlide } from "./banner-slide";
import hdbDesktop from "./hdb-desktop.png";
import hdbMobile from "./hdb-mobile.png";
import reitDesktop from "src/images/reitano/banner-desktop.jpg";
import reitMobile from "src/images/reitano/banner-mobile-dashboard.jpg";
import { A } from "../a";

type Props = {
  style?: CSSProperties;
};

export function Banner({ style }: Props) {
  return (
    <BannerCarousel
      style={style}
      slides={[
        <BannerSlide
          key="banner-slide-1"
          srcDesktop={reitDesktop}
          srcMobile={reitMobile}
          alt="Reitano Campaign"
          markup={<A to={`/store/shop`} />}
        />,
        <BannerSlide
          key="banner-slide-2"
          srcDesktop={hdbDesktop}
          srcMobile={hdbMobile}
          alt="Hdb banner"
          markup={<A to={`https://page.crurated.com/HDB2024`} />}
        />,
      ]}
    />
  );
}
