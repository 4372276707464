import React, { useState } from "react";
import { SlidePopup } from "./common/SlidePopup";
import { cn } from "src/app/utils/cn";

export default function CruratedSecondaryTitle({
  title,
  text,
  additionalClasses,
  knowmore,
  moreInformation,
}) {
  const [showSlideFlag, setSlideFlag] = useState(false);

  const showSlide = () => {
    setSlideFlag(true);
    document.body.style.overflow = "hidden";
  };

  const closeSlide = () => {
    setSlideFlag(false);
    document.body.style.overflow = "visible";
  };

  return (
    <React.Fragment>
      <p className={cn(additionalClasses)}>
        {text}{" "}
        {!!knowmore && (
          <strong className="card-knowmore" onClick={showSlide}>
            Know More
          </strong>
        )}
      </p>

      {!!showSlideFlag && (
        <SlidePopup
          slideTitle={title}
          moreInformation={moreInformation}
          closeSlide={closeSlide}
        />
      )}
    </React.Fragment>
  );
}
