import styles from "./provenance-subrow.module.css";
import provenanceIconSrc from "src/media/icons/block-icon.png";
import { Fragment, useState } from "react";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { path } from "src/app/routes/path";
import { Tooltip } from "src/app/components/tooltip";
import { Drawer } from "src/app/components/drawer";
import { ApiLotV1 } from "src/app/models/LotV1";
import {
  useProductsOfLotQuery,
  useSerialsOfProductsOfLotQuery,
} from "src/app/api/queries_to_rename_later";
import { cn } from "src/app/utils/cn";

type Props = {
  row: ApiLotV1;
  isMobile: boolean;
};

export const ProvenanceSubRow = ({ row, isMobile }: Props) => {
  const { data: products } = useProductsOfLotQuery(row.id_lot);
  const [showSerialsDrawer, setShowSerialsDrawer] = useState(false);

  return (
    <tr className={styles.gridWrapper}>
      {!isMobile && <td colSpan={2} />}
      <td className={styles.gridContainer} colSpan={100}>
        <div className={cn(styles.grid, styles.gridHead)}>
          <div className={cn(styles.ellipsis, styles.lotHeader)}>
            {row.case_}
          </div>
          <div className={styles.provenanceHeader}>
            <ProvenanceButton onClick={() => setShowSerialsDrawer(true)} />
            {!!showSerialsDrawer && (
              <Drawer
                onClosed={() => setShowSerialsDrawer(false)}
                title="Provenance Details"
                render={() => <TableSerials row={row} />}
                width={600}
              />
            )}
          </div>
        </div>
        <div className={styles.grid}>
          {products?.map((product, index) => (
            <Fragment key={`subrow-${product.id}-${index}`}>
              <div className={styles.nameCell}>
                <Tooltip
                  position="bottom"
                  content={product.nameProductWithoutVintage}
                >
                  <div
                    className={cn(
                      !isMobile && styles.ellipsis,
                      styles.productName,
                    )}
                  >
                    {product.nameProductWithoutVintage}
                  </div>
                </Tooltip>
              </div>
              <div className={cn(styles.ellipsis, styles.vintageCell)}>
                <div className={styles.productVintage}>
                  {product.vintage || "NV"}
                </div>
              </div>
              <div className={cn(styles.ellipsis, styles.provenanceCell)}>
                <div className={styles.productQuantity}>
                  x{product.quantity} of {product.size}L
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </td>
    </tr>
  );
};

function ProvenanceButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      style={{
        border: "none",
        background: "none",
        color: "rgb(100, 5, 156)",
        textDecoration: "underline",
        display: "flex",
        gap: "6px",
        alignItems: "center",
        fontWeight: 500,
        margin: 0,
        padding: 0,
      }}
      onClick={onClick}
    >
      <div style={{ paddingBottom: "1px" }}>Provenance</div>
      <img src={provenanceIconSrc} alt="" height={20} width={20} />
    </button>
  );
}

function TableSerials({ row }: { row: ApiLotV1 }) {
  const {
    data = [],
    isLoading,
    isFetching,
  } = useSerialsOfProductsOfLotQuery(row.id_lot);

  return (
    <TableCustomerSide
      urlPrefix="pv."
      data={data}
      isLoading={isLoading}
      isFetching={isFetching}
      getRowId={(row) => row.id}
      noDataTitle="Nothing found"
      noDataContent=""
      columns={[
        {
          header: "Provenance",
          id: "Provenance",
          width: "65px",
          cellDesktop: (row) =>
            row.serial && (
              <a
                href={`${window.location.origin}${path.provenance.value(
                  row.serial,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  textDecoration: "underline",
                  fontWeight: 500,
                }}
              >
                View
              </a>
            ),
        },
        {
          header: "Product",
          id: "Product",
          getSortingValue: (row) => row.product.nameProductComplete,
          cellDesktop: (row) => (
            <div
              style={{
                whiteSpace: "normal",
                lineClamp: 2,
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                display: "-webkit-box",
              }}
            >
              {row.product.nameProductComplete}
            </div>
          ),
        },
        {
          header: "Serial",
          id: "Serial",
          getSortingValue: (row) => row.serial,
          cellDesktop: (row) => row.serial,
        },
      ]}
    />
  );
}
