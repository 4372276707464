import { Fragment, useState } from "react";
import { NoBidsNew } from "../../modules/Includes/CustomerSide/components/common/NoBidsNew";
import { useBottleTiersQuery, useCustomerRolesQuery } from "../../api/queries";
import {
  AlertPopup,
  AlertPopupMeta,
} from "../../modules/Includes/LayoutSide/components/common/alert-popup";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { useAuctionsInfiniteQuery } from "src/app/api/auctions";
import { Auction } from "src/app/models/auctions";
import { Redirect, useParams } from "react-router-dom";
import { path } from "src/app/routes/path";
import { AuctionCardMd } from "src/app/components/auction-card/md";
import { PAGE_NAME, PAGE_TITLE } from "src/app/const";
import { ValueOf, inferDataType } from "src/app/utils/types";
import { StickyControls } from "src/app/components/sticky-controls";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { useSort } from "./useSort";
import { Select } from "src/app/components/form-elements/select";
import { useScrollToAuction } from "src/app/hooks/useScrollToAuction";
import { SelectMultiple } from "src/app/components/form-elements/select-multiple";
import { useDeboucedInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useDeboucedInputAutocomplete";
import { useFilters } from "./useFilters";
import { BannerCarousel } from "src/app/components/banner-carousel/banner-carousel";
import { BannerSlide } from "src/app/components/banner-carousel/banner-slide";
import hdbDesktop from "src/images/reitano/banner-desktop.jpg";
import hdbMobile from "src/images/reitano/banner-mobile.jpg";

export function PageStore() {
  const { pageName } = useParams<{
    pageName?: ValueOf<typeof PAGE_NAME.STORE>;
  }>();

  if (!pageName || pageName === PAGE_NAME.STORE.EN_PRIMEUR) {
    return <Redirect to={path.store.shop()} />;
  }
  return <PageStoreContent key={pageName} pageName={pageName} />;
}

function PageStoreContent({
  pageName,
}: {
  pageName: ValueOf<typeof PAGE_NAME.STORE>;
}) {
  const pageTitle =
    pageName === PAGE_NAME.STORE.PRIVATE_SALE
      ? PAGE_TITLE.PRIVATE_SALE()
      : pageName === PAGE_NAME.STORE.EN_PRIMEUR
        ? PAGE_TITLE.EN_PRIMEUR()
        : PAGE_TITLE.SHOP();

  usePageTitle(pageTitle);

  const [alertPopupMeta, setAlertPopupMeta] = useState<AlertPopupMeta | null>(
    null,
  );

  const sort = useSort();

  const inputAutocomplete = useDeboucedInputAutocomplete({
    inferDataType: inferDataType<Auction>(),
    getSuggestionText: (auction) => auction.description,
  });

  const customerRolesQuery = useCustomerRolesQuery();
  const bottleTiersQuery = useBottleTiersQuery();

  const auctionsFilters = useFilters(pageName);
  const auctionsQuery = useAuctionsInfiniteQuery(
    pageName,
    auctionsFilters.rawValues,
    auctionsFilters.search, // Avoid using inputAutocomplete value to take advantage of debounced value
    sort.value.value,
  );

  const stickyControlsRef = useScrollToAuction({
    enabled: !!auctionsQuery.data,
    getCardId,
  });

  const isLoading =
    auctionsQuery.isFetching ||
    customerRolesQuery.isLoading ||
    bottleTiersQuery.isLoading;
  const isEmpty = !auctionsQuery.data?.pages.at(0)?.total;
  const autocompleteOptions = auctionsQuery.data?.pages.at(0)?.items || [];

  return (
    <Fragment>
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <BannerCarousel
        slides={[
          <BannerSlide
            key="banner-slide-2"
            srcDesktop={hdbDesktop}
            srcMobile={hdbMobile}
            alt="Reitano Campaign"
          />,
        ]}
        style={{ marginTop: 35 }}
      />

      <StickyControls
        style={
          pageName === "en-primeur"
            ? {
                backgroundColor: "#300211",
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
              }
            : {}
        }
        outerRef={stickyControlsRef}
        slot1={(scrollToTop) => (
          <SelectMultiple
            isLoading={auctionsFilters.isLoadingFilters}
            placeholder="Filters"
            values={auctionsFilters.values}
            onChange={(values) => {
              auctionsFilters.setFilters(values);
              scrollToTop();
            }}
            options={auctionsFilters.options}
          />
        )}
        slot2={(scrollToTop) => (
          <InputAutocomplete
            data={autocompleteOptions}
            getSuggestionText={inputAutocomplete.getSuggestionText}
            value={inputAutocomplete.value}
            onChange={(value) => {
              inputAutocomplete.setValue(value);
              scrollToTop();
            }}
          />
        )}
        slot3={(scrollToTop) => (
          <Select
            onChange={(value) => {
              sort.setValue(value);
              scrollToTop();
            }}
            options={sort.options}
            selectedOption={sort.value}
            maxDropdownHeight={1000}
          />
        )}
      />

      <AuctionCardMd.Grid
        isLoading={isLoading}
        emptyFallback={<NoBidsNew title="Nothing found" />}
        canLoadMore={!!auctionsQuery.hasNextPage}
        onLoadMore={() => auctionsQuery.fetchNextPage()}
        isEmpty={isEmpty}
      >
        {auctionsQuery.data?.pages.map((page) =>
          page.items.map((auction) => (
            <AuctionCardMd.Card
              key={`shop-auction-card-key-${auction.id_auction}-${auction.type_product}`}
              auction={auction}
              cardId={getCardId(auction.id)}
            />
          )),
        )}
      </AuctionCardMd.Grid>
    </Fragment>
  );
}

function getCardId(id: number) {
  return `auction-${id}`;
}

// function withUnbiddableOnBottom(auctions: Auction[]) {
//   return [...auctions].sort((a, b) => {
//     // Priorità 1: Ordinare per canBid, canBid true > canBid false
//     if (b.canBid !== a.canBid) {
//       return +b.canBid - +a.canBid;
//     }

//     // Priorità 2: Se entrambi canBid sono false, ordinare ulteriormente per canAddToWaitingList
//     // canAddToWaitingList false > canAddToWaitingList true
//     if (isAuctionOfLot(a) && isAuctionOfLot(b) && !a.canBid && !b.canBid) {
//       return +b.canAddToWaitingList - +a.canAddToWaitingList;
//     }

//     // Se canBid e canAddToWaitingList sono uguali, mantieni l'ordine originale (stabile)
//     return 0;
//   });
// }
