import { toPx } from "src/app/utils/toPx";
import styles from "./index.module.css";
import { cn } from "src/app/utils/cn";
import { CSSProperties } from "react";

type Props = {
  width?: string | number;
  height: string | number;
  color?: string;
  style?: CSSProperties;
};

export const Rect = ({ width = "100%", height, color, style }: Props) => {
  return (
    <div
      style={{
        ...style,
        width: toPx(width),
        height: toPx(height),
        backgroundColor: color,
      }}
      className={cn(styles.root, styles.rect)}
    />
  );
};
