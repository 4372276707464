import { cn } from "src/app/utils/cn";
import styles from "./status-badge.module.css";
import { ReactNode } from "react";

type Props = {
  variant?: "success" | "warn" | "primary" | "undefined";
  children: ReactNode;
};

export default function StatusBadge({
  variant = "undefined",
  children,
}: Props) {
  const variantStyle =
    variant === "success"
      ? styles.success
      : variant === "warn"
      ? styles.warn
      : variant === "primary"
      ? styles.primary
      : styles.gray;

  return <div className={cn(styles.root, variantStyle)}>{children}</div>;
}
