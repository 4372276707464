import styles from "./address-info.module.css";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdLocalPhone } from "react-icons/md";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { AddressV1 } from "src/app/models/Address";

export const AddressInfo = ({
  dataAddress,
  loading,
  controls,
}: {
  dataAddress: AddressV1;
  loading: boolean;
  controls: JSX.Element;
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.containerInfo}>
        <div>
          {loading ? (
            <SpinnerSm style={{ color: "#00000030" }} />
          ) : (
            <FaRegCircleCheck
              color={dataAddress.selected ? "#783233" : "#00000030"}
              size={20}
            />
          )}
        </div>
        <div className={styles.infoUser}>
          <div>
            {dataAddress.company_name
              ? dataAddress.company_name
              : dataAddress.full_name}
          </div>
          <div className={styles.address}>
            {dataAddress.address.address_complete_without_name}
          </div>
          <div className={styles.phone}>
            <MdLocalPhone />
            <span>{dataAddress.phone}</span>
          </div>
        </div>
      </div>
      {controls}
    </div>
  );
};
