import styles from "./styles.module.css";
import { LuChevronsUpDown } from "react-icons/lu";

type Props = React.ComponentPropsWithoutRef<"div">;

export const IconToggleDropdown = ({ ...props }: Props) => {
  return (
    <div className={styles.toggleDropdownIcon} role="button" {...props}>
      <LuChevronsUpDown size={18} />
    </div>
  );
};
