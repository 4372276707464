import styles from "./index.module.css";
import { Dialog as HeadlessDialog } from "@headlessui/react";
import { toPx } from "../../utils/toPx";
import { cn } from "../../utils/cn";
import { noop } from "lodash";
import { useIsMutating } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLatest } from "react-use";
import { Scrollable } from "../scrollable";
import { Button } from "../button";
import { MdClear } from "react-icons/md";

const enterAnimationDuration = 350;
const exitAnimationDuration = 200;

interface DrawerProps {
  title?: string;
  onClosed?: () => void;
  position?: "left" | "right";
  width?: number | string;
  render: (props: { close: () => void }) => React.ReactNode;
  disableClose?: boolean;
}

/**
 * @deprecated
 */
export const Drawer: React.FC<DrawerProps> = ({
  title = "",
  onClosed = noop,
  position = "right",
  width = "420px",
  render,
  disableClose,
}) => {
  const onClosedRef = useLatest(onClosed);
  const isMutating = !!useIsMutating();
  const [isOpen, setIsOpen] = useState(true);

  function close() {
    if (disableClose || isMutating) return;
    setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen) return;
    const token = setTimeout(
      () => onClosedRef.current(),
      exitAnimationDuration,
    );
    return () => clearTimeout(token);
  }, [isOpen, onClosedRef]);

  const animationDuration = isOpen
    ? `${enterAnimationDuration}ms`
    : `${exitAnimationDuration}ms`;

  return (
    <HeadlessDialog
      open
      className={cn(styles.drawer, !isOpen && styles.closing)}
      onClose={close}
    >
      <div
        className={styles.backdrop}
        style={{ animationDuration }}
        aria-hidden="true"
        onClick={(e) => e.stopPropagation()}
      />

      <HeadlessDialog.Panel
        className={cn(styles.panel, styles[position])}
        style={{ width: toPx(width), animationDuration }}
      >
        <div className={styles.heading}>
          <HeadlessDialog.Title className={styles.title}>
            {title}&nbsp;
          </HeadlessDialog.Title>
          <button
            // takes the initial focus to avoid opening the close button tooltip by default
            style={{
              position: "absolute",
              opacity: "0",
              pointerEvents: "none",
            }}
          />
          <Button
            variant="ghost"
            icon={<MdClear size={24} />}
            tooltip=""
            aria-label="Close"
            className={styles.close}
            onClick={close}
          />
        </div>

        <Scrollable className={styles.scrollable}>
          {render({ close })}
        </Scrollable>
      </HeadlessDialog.Panel>
    </HeadlessDialog>
  );
};
