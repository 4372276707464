import { Redirect, useLocation } from "react-router-dom";
import { PageOffers } from "./offers";
import { PageArchive } from "./archive";
import { PageWishlist } from "./wishlist";
import { path } from "src/app/routes/path";

export const PageMyActivities = () => {
  const { pathname } = useLocation();

  if (pathname === path.myActivities.archive()) {
    return <PageArchive />;
  }
  if (pathname === path.myActivities.offers.definition()) {
    return <PageOffers />;
  }
  if (pathname === path.myActivities.wishlist()) {
    return <PageWishlist />;
  }
  return <Redirect to={path.myActivities.offers.value()} push={false} />;
};
