import styles from "./auction-details.module.css";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { SliderLotsCaptions } from "src/app/modules/Includes/CustomerSide/components/LotsCard/slider-lots-captions";
import { SliderLots } from "src/app/modules/Includes/CustomerSide/components/LotsCard/slider-lots";
import { BarrelInput } from "../../barrel-input";
import { useIsAuctionFinished } from "src/app/hooks/useIsAuctionFinished";
import { ID_IO_ROOT } from "src/app/const";
import { useCardCtx } from "../card-context";
import {
  Auction,
  isAuctionOfBarrel,
  isAuctionOfLot,
} from "src/app/models/auctions";
import { LotDetails } from "./lot-details";
import { BarrelAttributes } from "./atoms/barrel-attributes";
import { Swiper } from "swiper/types";

export default function AuctionDetails() {
  const cardCtx = useCardCtx();
  // const swiperRefParent = useRef<SwiperRef>(null);
  const { ref, isCardVisible, backgroundImage } = useCardBackground(
    cardCtx.auction,
  );
  // TODO: refactor after upgrading to Swiper latest version
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);
  const isFinished = useIsAuctionFinished(cardCtx.auction);

  return (
    <div ref={ref} style={{ backgroundImage }} className={styles.root}>
      {!!cardCtx.auction.experience?.images.length && (
        <div className={styles.overlay} />
      )}

      <div className={styles.details}>
        <div className={styles.leftDetails}>
          <LotDetails auction={cardCtx.auction} />

          {isAuctionOfLot(cardCtx.auction) ? (
            <SliderLotsCaptions
              auction={cardCtx.auction}
              swiperInstance={swiperInstance}
              hasBackground={!!cardCtx.auction.experience?.images.length}
              currentSlideIndex={currentSlideIndex}
              // parentRef={swiperRefParent}
            />
          ) : (
            <BarrelAttributes auction={cardCtx.auction} />
          )}
        </div>

        <div className={styles.rightDetails}>
          {!!isCardVisible && isAuctionOfLot(cardCtx.auction) && (
            <SliderLots
              setCurrentSlideIndex={setCurrentSlideIndex}
              setSliderInstance={setSwiperInstance}
              gallery={
                cardCtx.auction.lot?.components.map(
                  (wine) => wine.media.cover,
                ) || []
              }
            />
          )}
          {isAuctionOfBarrel(cardCtx.auction) && (
            <BarrelInput
              max={cardCtx.auction.options.quantities.max}
              min={cardCtx.auction.options.quantities.min}
              quantity={cardCtx.quantity}
              onChange={cardCtx.setQuantity}
              step={cardCtx.auction.options.quantities.step}
              mul={cardCtx.auction.options.quantities.mul}
              color={cardCtx.auction.barrel.color as any} // TODO: fix, sync 'color' prop for barrel dto
              disabled={!cardCtx.auction.canBid || isFinished}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function useCardBackground(auction: Auction) {
  const { ref, inView } = useInView({
    rootMargin: "600px 0px",
    triggerOnce: true,
    root: document.getElementById(ID_IO_ROOT),
  });

  const src = auction.experience?.images[0]?.image;

  return {
    ref,
    backgroundImage: inView && src ? `url(${src})` : undefined,
    isCardVisible: inView,
  };
}
