import styles from "./Button.module.css";
import { SpinnerSm } from "../spinner-sm";

export const ButtonCharity = ({
  className = "",
  onClick,
  disabled = false,
  greyedOut = false,
  text = "",
  isLoading = false,
}) => {
  return (
    <button
      style={{
        padding: "15px",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
      }}
      className={`btn btn-primary btn-full ${styles.root} ${className} ${
        greyedOut ? styles.greyedOut : ""
      }`}
      onClick={onClick}
      disabled={disabled || greyedOut || isLoading}
    >
      {text}

      {!!isLoading && <SpinnerSm />}
    </button>
  );
};
