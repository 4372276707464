import { useCallback, useState } from "react";

export const useCount = (
  initialCount: number,
  { step = 1 }: { step?: number } = {},
) => {
  const [value, setValue] = useState(initialCount);

  const increment = useCallback(() => setValue((c) => c + step), [step]);
  const decrement = useCallback(() => setValue((c) => c - step), [step]);

  return { value, increment, decrement };
};
