import styles from "./prompt-set-password.module.css";
import { Button } from "../button";
import { CruForm } from "src/app/components/cru-form-3";
import { Dialog } from "../dialog-2";
import { Fragment } from "react";
import { useEditPasswordMutation } from "src/app/api/auth";
import { useAlertPopup } from "../alert-popup/store";
import { useHistory } from "react-router-dom";
import { path } from "src/app/routes/path";
import { validate } from "src/app/utils/validate";
import { InputPassword } from "../form-elements/input-password";

export const PromptSetPassword = ({
  title = "Set your password",
}: {
  title?: string;
}) => {
  const alertPopup = useAlertPopup();
  const history = useHistory();

  const mutation = useEditPasswordMutation();

  const mutationError = parseError(mutation.error);

  return (
    <Dialog
      mandatory
      width={340}
      title={title}
      render={({ startClosing }) => (
        <>
          <p className={styles.description}>Please update your password to continue using your account</p>
          <CruForm.Form
            className={styles.root}
            initialValues={{
              currentPassword: "",
              password: "",
              passwordConfirm: "",
            }}
            onSubmit={(values) => {
              return mutation
                .mutateAsync({
                  new_password: values.password,
                  current_password: values.currentPassword,
                })
                .then(() => {
                  startClosing();
                  setTimeout(
                    () =>
                      alertPopup.success({
                        title: "Your password has been updated with success",
                        content: "You will be logged out now",
                        onClose() {
                          history.push(path.logout());
                        },
                      }),
                    300,
                  );
                });
            }}
          >
            {(ctx) => (
              <Fragment>
                <CruForm.Field
                  ctx={ctx}
                  name="currentPassword"
                  validate={(value) => {
                    if (!value) return "Write your current password";
                  }}
                >
                  {(props) => (
                    <div>
                      <CruForm.Label className={styles.formLabel} required>
                        Current Password
                      </CruForm.Label>

                      <InputPassword
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />

                      <CruForm.ErrorMessage />
                    </div>
                  )}
                </CruForm.Field>
                <CruForm.Field
                  ctx={ctx}
                  name="password"
                  validate={validate.password}
                >
                  {(props) => (
                    <div>
                      <CruForm.Label className={styles.formLabel} required>
                        New Password
                      </CruForm.Label>

                      <InputPassword
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />

                      <CruForm.ErrorMessage />
                    </div>
                  )}
                </CruForm.Field>
                <CruForm.Field
                  ctx={ctx}
                  name="passwordConfirm"
                  validate={(value, getFieldValue) => {
                    if (!value) return "Write your password again";
                    if (value !== getFieldValue("password"))
                      return "Passwords must match";
                  }}
                >
                  {(props) => (
                    <div>
                      <CruForm.Label className={styles.formLabel} required>
                        Verify Password
                      </CruForm.Label>

                      <InputPassword
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />

                      <CruForm.ErrorMessage />
                    </div>
                  )}
                </CruForm.Field>

                {!!mutationError && (
                  <div
                    style={{ fontSize: "12px", color: "red", marginTop: "2px" }}
                  >
                    Your password could not be updated: {mutationError}
                  </div>
                )}

                <div>
                  <Button
                    type="submit"
                    label="Save"
                    isLoading={ctx.submission.isLoading}
                  />
                </div>
              </Fragment>
            )}
          </CruForm.Form>
        </>
      )}
    />
  );
};

// we don't know what the API error looks like
function parseError(error: any) {
  if (!error) {
    return "";
  }
  if (error.error) {
    return String(error.error);
  }
  if (error.message) {
    return String(error.message);
  }
  return JSON.stringify(error);
}
