import { z } from "zod";
import { Product, apiProductSchema } from "./Product";

export const apiBottleSchema = z.object({
  id: z.number(),
  id_serial_product: z.number(),
  id_serial_product_mp: z.string(),
  serial_date: z.string().nullable(),
  deposit: z.string().nullable().optional(),
  serial: z.string(),
  deleted: z.boolean(),
  position_meta: z.number().nullable(),
  product: apiProductSchema,
  IDSeriallot: z.number(),
  history: z.array(
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  ),
  NFTInfo: z.object({
    provenance: z.string(),
    token: z.string().nullable(),
    contract: z.string(),
    transfers: z.number(),
  }),
});

export type ApiBottle = z.infer<typeof apiBottleSchema>;

export class Bottle {
  id: number;
  id_serial_product: number;
  id_serial_product_mp: string;
  serial_date: string | null;
  deposit: string;
  serial: string;
  deleted: boolean;
  position_meta: number | null;
  product: Product;
  IDSeriallot: number;
  history: z.infer<typeof apiBottleSchema>["history"];
  NFTInfo: z.infer<typeof apiBottleSchema>["NFTInfo"];

  constructor(apiLot: ApiBottle) {
    this.id = apiLot.id;
    this.id_serial_product = apiLot.id_serial_product;
    this.id_serial_product_mp = apiLot.id_serial_product_mp;
    this.serial_date = apiLot.serial_date || "";
    this.deposit = apiLot.deposit || "";
    this.serial = apiLot.serial;
    this.deleted = apiLot.deleted;
    this.position_meta = apiLot.position_meta;
    this.product = new Product(apiLot.product);
    this.IDSeriallot = apiLot.IDSeriallot;
    this.history = apiLot.history;
    this.NFTInfo = apiLot.NFTInfo;
  }
}
