import styles from "./index.module.css";
import { Select } from "../../form-elements/select";

type Props = {
  optionsForPageSize: Array<{ value: number }>;
  total: number;
  pageSize: number;
  page: number;
  onChangePage: (x: number) => void;
  onChangePageSize: (x: number) => void;
};

export const Pagination = ({
  optionsForPageSize,
  total,
  pageSize,
  page,
  onChangePage,
  onChangePageSize,
}: Props) => {
  const startIndex = page * pageSize + 1;
  const endIndex = Math.min((page + 1) * pageSize, total);

  return (
    <div className={`${styles.root}`}>
      <label className={styles.rowsPerPage} htmlFor="rows-per-page">
        Rows per page:
        <Select
          className={styles.select}
          options={optionsForPageSize}
          onChange={({ value }) => onChangePageSize(value)}
          selectedOption={optionsForPageSize.find((o) => o.value === pageSize)}
          renderTriggerContent={(o) => o?.value}
          placement="top"
        />
      </label>

      <div className={styles.label}>
        {startIndex}-{endIndex} of {total}
      </div>

      <div className={styles.buttons}>
        <button
          onClick={() => onChangePage(page - 1)}
          disabled={page === 0}
          aria-label="Previous Page"
          className={page === 0 ? styles.disabled : styles.button}
        >
          <span className={styles.containerSvg}>
            <svg className={styles.svgPrevNext}>
              <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
            </svg>
          </span>
        </button>
        <button
          onClick={() => onChangePage(page + 1)}
          disabled={page === Math.ceil(total / pageSize) - 1}
          aria-label="Next Page"
          className={
            page === Math.ceil(total / pageSize) - 1
              ? styles.disabled
              : styles.button
          }
        >
          <span className={styles.containerSvg}>
            <svg className={styles.svgPrevNext}>
              <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
};
