import { z } from "zod";

export const myEarningsSchemaV1 = z.object({
  total_purchases: z.object({
    number_text: z.number(),
    number_label: z.string(),
  }),
  total_crurated_estimated_market_price: z.object({
    number_text: z.number(),
    number_label: z.string(),
  }),
  total_upside: z.object({
    number_text: z.number(),
    number_label: z.string(),
    is_growth: z.boolean(),
  }),
});

type ApiMyEarningsV1 = z.infer<typeof myEarningsSchemaV1>;

export class MyEarningsV1 {
  total_purchases: ApiMyEarningsV1["total_purchases"];
  total_crurated_estimated_market_price: ApiMyEarningsV1["total_crurated_estimated_market_price"];
  total_upside: ApiMyEarningsV1["total_upside"];

  constructor(apiEarnings: ApiMyEarningsV1) {
    this.total_purchases = apiEarnings.total_purchases;
    this.total_crurated_estimated_market_price =
      apiEarnings.total_crurated_estimated_market_price;
    this.total_upside = apiEarnings.total_upside;
  }

  get totalPurchased() {
    return { value: this.total_purchases.number_text };
  }

  get emp() {
    return { value: this.total_crurated_estimated_market_price.number_text };
  }

  get upside() {
    return {
      value: this.total_upside.number_text,
      direction: this.total_upside.is_growth ? (1 as const) : (-1 as const),
    };
  }
}
