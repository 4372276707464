import { ReactNode } from "react";
import { formatCurrency } from "src/app/utils/formatCurrency";

type Props = {
  amount: number;
  rightElement?: ReactNode;
};

export const MobileCellContentCurrency = ({ amount, rightElement }: Props) => {
  return (
    <div
      style={{
        fontSize: "14px",
        fontWeight: 500,
        whiteSpace: "nowrap",
        textAlign: "left",
      }}
    >
      {amount > 0 ? formatCurrency(amount) : formatCurrency(0)}

      {rightElement}
    </div>
  );
};
