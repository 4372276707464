import { noop } from "lodash";
import styles from "./radio-payment-method.module.css";
import { RadioGroup } from "@headlessui/react";
import { FaRegCircle } from "react-icons/fa";
import { FaRegCircleDot } from "react-icons/fa6";
import { ID_PAYMENT_METHOD } from "src/app/const";
import { cn } from "src/app/utils/cn";
import { Skeleton } from "src/app/components/skeleton";

export const RadioBankTransfer = ({
  defaultValue,
  isLoading,
  onChange = noop,
}: {
  defaultValue?: number;
  isLoading: boolean;
  onChange: () => void;
}) => {
  if (isLoading)
    return <Skeleton.Rect style={{ borderRadius: "8px" }} height={90} />;

  return (
    <RadioGroup
      defaultValue={defaultValue}
      onChange={onChange}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <div>
        <RadioGroup.Option
          value={ID_PAYMENT_METHOD.BANK}
          className={({ checked }) =>
            cn(styles.root, checked && styles.checked)
          }
        >
          {({ checked }) => (
            <div className={styles.inner}>
              <div className={styles.left}>
                <div className={cn(styles.radio, !checked && styles.unchecked)}>
                  {!checked ? (
                    <FaRegCircle size={18} />
                  ) : (
                    <FaRegCircleDot size={18} />
                  )}
                </div>
                <div className={styles.bankTransfer}>
                  Pay with <br /> Bank Transfer
                </div>
              </div>
            </div>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
};
