import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { sendCall } from "../config/Utility";
import { useMyUser } from "../hooks/useMyUser";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { QKEY } from "./QKEY";
import { upfetchV1 } from "./upfetch";
import { z } from "zod";
import {
  PaymentMethodV1,
  apiPaymentMethodSchemaV1,
} from "../models/PaymentMethodV1";
import { PaymentCardV1, apiPaymentCardV1 } from "../models/PaymentCardV1";

const getStripeSecret = {
  queryKey: (
    accessToken: string | undefined,
    id_customer_stripe: string | undefined,
  ) => [QKEY.ME, QKEY.STRIPE_SECRET, accessToken, id_customer_stripe],
  queryFn:
    (accessToken: string | undefined, id_customer_stripe: string | undefined) =>
    async () => {
      try {
        return await sendCall("stripe/RetrieveCustomerClientSecret/", "POST", {
          id_customer_stripe,
          accessToken,
        }).then((res) => res.result.clientSecret);
      } catch (e) {
        return "";
      }
    },
};

const useStripeSecretQuery = (options: UseQueryOptions<string> = {}) => {
  const user = useMyUser({ maybeNull: true });

  return useQuery({
    queryKey: getStripeSecret.queryKey(
      user?.accessToken,
      user?.id_customer_stripe,
    ),
    queryFn: getStripeSecret.queryFn(
      user?.accessToken,
      user?.id_customer_stripe,
    ),
    enabled: !!user?.accessToken && !!user.id_customer_stripe,
    cacheTime: Infinity,
    staleTime: Infinity,
    ...options,
  });
};

export const useAddCardMutation = (options: UseMutationOptions = {}) => {
  const user = useMyUser();
  const stripe = useStripe();
  const elements = useElements();
  const { data: clientSecret } = useStripeSecretQuery();
  const { data: myCards = [] } = useMyCardsQuery();
  const chooseDefaultCardMutation = useChooseDefaultCardMutation();

  return useMutation({
    mutationFn: () => {
      if (!clientSecret) return Promise.reject("Missing stripe clientSecret");
      if (!elements) return Promise.reject("Stripe elements not found");
      if (!stripe) return Promise.reject("Stripe instance not found");
      const card = elements.getElement(CardElement);
      if (!card) return Promise.reject("Card Element not found");

      return stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card,
            billing_details: {
              name: user.fullname,
            },
          },
        })
        .then(({ error, setupIntent }) => {
          if (error) {
            return Promise.reject(error);
          } else {
            return setupIntent.payment_method;
          }
        })
        .then((result) => {
          if (!result) return Promise.reject("Method payment ID not found");
          if (!myCards.length) {
            const id_method_payment =
              typeof result === "string" ? result : result.id;

            return chooseDefaultCardMutation.mutateAsync(id_method_payment);
          }
        });
    },
    ...options,
  });
};

const getMyCards = {
  queryKey: (id_customer_stripe: string, id_user: number) => [
    QKEY.ME,
    QKEY.CARDS,
    id_customer_stripe,
    id_user,
  ],
  queryFn:
    (accessToken: string, id_customer_stripe: string, id_user: number) =>
    async () => {
      return upfetchV1({
        url: "stripe/GetAllCards/",
        params: { accessToken, id_user, id_customer_stripe },
        schema: z.array(apiPaymentCardV1),
      }).then((apiCards) => apiCards.map((card) => new PaymentCardV1(card)));
    },
};

export const useMyCardsQuery = (
  options: UseQueryOptions<PaymentCardV1[]> = {},
) => {
  const { accessToken, id_user, id_customer_stripe } = useMyUser();

  return useQuery({
    queryKey: getMyCards.queryKey(id_customer_stripe, id_user),
    queryFn: getMyCards.queryFn(accessToken, id_customer_stripe, id_user),
    ...options,
  });
};

const getAvailablePaymentMethods = {
  queryKey: (accessToken: string) => [QKEY.PAYMENT_METHODS, accessToken],
  queryFn: (accessToken: string) => async () => {
    return upfetchV1({
      url: "users/GetMethodPaymentsActive/",
      params: { accessToken },
      schema: z.array(apiPaymentMethodSchemaV1),
    }).then((apiMethods) =>
      apiMethods.map((method) => new PaymentMethodV1(method)),
    );
  },
};

export const useAvailablePaymentMethodsQuery = (
  options: UseQueryOptions<PaymentMethodV1[]> = {},
) => {
  const { accessToken } = useMyUser();

  return useQuery({
    queryKey: getAvailablePaymentMethods.queryKey(accessToken),
    queryFn: getAvailablePaymentMethods.queryFn(accessToken),
    ...options,
  });
};

export const useChooseDefaultCardMutation = (
  options: UseMutationOptions<any, any, string> = {},
) => {
  const user = useMyUser();

  return useMutation({
    mutationFn: (id_payment_method) => {
      return upfetchV1({
        url: `stripe/ChooseDefaultCard/`,
        method: "POST",
        body: {
          id_customer_stripe: user.id_customer_stripe,
          accessToken: user.accessToken,
          id_payment_method: id_payment_method,
        },
        parseResponse: async (res) => await res.json(),
      }).then((result) => {
        if (result.code !== 200) {
          throw new Error("Error selecting the card, please try again");
        }
      });
    },
    ...options,
  });
};

export const useDeleteCardMutation = (
  options: UseMutationOptions<any, any, string> = {},
) => {
  const user = useMyUser();

  return useMutation({
    mutationFn: (id_payment_method) =>
      upfetchV1({
        url: "stripe/DeleteCard/",
        body: {
          accessToken: user.accessToken,
          id_customer_stripe: user.id_customer_stripe,
          id_payment_method: id_payment_method,
        },
        parseResponse: async (res) => await res.json(),
        method: "POST",
      }).then((result) => {
        if (result.code !== 200) {
          throw new Error("Error deleting the card, please try again");
        }
      }),
    ...options,
  });
};
