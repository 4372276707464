import { useCountriesQuery } from "src/app/api/queries_to_rename_later";
import { AddressType } from "src/app/models/Address";

export interface CountryOption {
  label: string;
  value: number;
  id: number;
  EA: boolean;
  isEnableForShipping: boolean;
}

export const useCountryOptions = (config: {
  addressType: AddressType;
  pickOnlyEA: boolean;
}) => {
  const countriesQuery = useCountriesQuery({
    select: (data) => {
      const countries =
        config.addressType === "shipping"
          ? data.filter((country) => country.isEnableForShipping)
          : data;

      const filteredByEA = countries.filter((country) =>
        config.pickOnlyEA ? country.EA : true,
      );

      return filteredByEA.map(
        (country) =>
          ({
            label: country.name,
            value: country.id_country,
            id: country.id_country,
            EA: country.EA,
            isEnableForShipping: country.isEnableForShipping,
          }) satisfies CountryOption,
      );
    },
  });

  return countriesQuery.data || [];
};
