import { toPx } from "src/app/utils/toPx";
import styles from "./index.module.css";
import { cn } from "src/app/utils/cn";

type Props = {
  width?: string | number;
};

export const Text = ({ width = "100%" }: Props) => {
  return (
    <div
      style={{ width: toPx(width) }}
      className={cn(styles.root, styles.text)}
    >
      &nbsp;
    </div>
  );
};
