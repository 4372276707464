import styles from "./index.module.css";
import { Spacer } from "src/app/components/spacer";
import { Button } from "src/app/components/button";
import { FormContextType } from "src/app/components/cru-form-3/types";
import { GiftFormValues } from ".";
import iconSrc from "src/media/Gift/Crurated_EDM_1.png";

export function Checkout({
  ctx,
  onGoBack,
  isLoading,
}: {
  ctx: FormContextType<GiftFormValues>;
  onGoBack: () => void;
  isLoading: boolean;
}) {
  return (
    <section>
      <div className={styles.sectionBody}>
        <h3>Preview your message</h3>
        <Spacer size={24} />
        <img
          src={iconSrc}
          alt="Gift"
          style={{ width: "100%", marginBottom: 14 }}
        />
        <p>{ctx.values.message}</p>

        <Spacer size={48} />

        <h3>Your Selection</h3>
        <Spacer size={16} />
        <div className={styles.lotsList}>
          <div className={styles.container} style={{ marginBottom: -16 }}>
            <div style={{ fontWeight: "600" }}>Name</div>
            <div style={{ fontWeight: "600" }}>Quantity</div>
          </div>
          {ctx.values.lots.map((lot) => (
            <div key={lot.id} className={styles.container}>
              <div className={styles.description}>{lot.description}</div>
              <input
                disabled
                className={styles.quantity}
                value={lot.quantity_chosen}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.sectionFooter}>
        <Button
          variant="outlined"
          label="Back"
          style={{ minWidth: 150 }}
          type="button"
          onClick={onGoBack}
        />
        <Button
          variant="contained"
          label="Send"
          style={{ minWidth: 150 }}
          isLoading={isLoading}
        />
      </div>
    </section>
  );
}
