import { Switch as HuiSwitch } from "@headlessui/react";
import styles from "./switch.module.css";
import { CruFormContextType } from "src/app/components/cru-form/types";
import { SelectTierFormValues } from ".";
import { cn } from "src/app/utils/cn";

type Props = {
  cruForm: CruFormContextType<SelectTierFormValues>;
  label: string;
  onChangeSideEffect: (checked: boolean) => void;
};

export const Switch = ({ cruForm, label, onChangeSideEffect }: Props) => {
  return (
    <HuiSwitch.Group>
      <div className={styles.root}>
        <HuiSwitch
          checked={cruForm.values.annual}
          onChange={() => {
            const newChecked = !cruForm.values.annual;
            cruForm.setValues({ annual: newChecked });
            onChangeSideEffect(newChecked);
          }}
          className={cn(styles.switch, cruForm.values.annual && styles.enabled)}
        >
          <span
            className={cn(styles.span, cruForm.values.annual && styles.enabled)}
          />
        </HuiSwitch>
        <HuiSwitch.Label className={styles.label}>{label}</HuiSwitch.Label>
      </div>
    </HuiSwitch.Group>
  );
};
