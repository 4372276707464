import React from "react";
import { BarrelSampleBottleItem } from "./BarrelSampleBottleItem";

export function BarrelSampleBottles({
  activeLevelIndex,
  dataBottlesTiers,
  bottlesTiersChoose,
}) {
  return (
    <div className="bgc-bottles">
      <span className="bgc-title">Bottled in</span>
      <div className="bgc-bottles-container" data-active={activeLevelIndex}>
        {bottlesTiersChoose.map((item, index) => {
          let tier_selected = dataBottlesTiers.filter(
            (bottle_tier) =>
              parseInt(bottle_tier.id_barrel_bottle_tier) ===
              parseInt(item.id_barrel_bottle_tier),
          );
          // console.log('Tier length - ' + tier_selected.length);
          if (tier_selected.length > 0) {
            let tier = tier_selected.length > 0 ? tier_selected[0] : [];
            let bottleValueClass = "";
            switch (tier.value.toString()) {
              case "0.375":
                bottleValueClass = "037";
                break;
              case "0.75":
                bottleValueClass = "075";
                break;
              case "1.5":
                bottleValueClass = "15";
                break;
              case "2":
                bottleValueClass = "20";
                break;
              case "2.5":
                bottleValueClass = "25";
                break;
              case "3":
                bottleValueClass = "30";
                break;
              case "3.5":
                bottleValueClass = "35";
                break;
              case "5":
                bottleValueClass = "50";
                break;
              case "6":
                bottleValueClass = "60";
                break;
              case "9":
                bottleValueClass = "90";
                break;
              case "12":
                bottleValueClass = "120";
                break;
              case "15":
                bottleValueClass = "150";
                break;
              case "18":
                bottleValueClass = "180";
                break;

              default:
                break;
            }

            return (
              <BarrelSampleBottleItem
                key={`some-key-${index}-${item.id_barrel_bottle_tier}`}
                bottleName={`bottle-${bottleValueClass}`}
                bottleMeasure={tier.value}
              />
            );
          } else {
            return <></>;
          }
        })}
      </div>
    </div>
  );
}
