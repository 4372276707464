import styles from "./product-link-nft.module.css";
import { ReactComponent as IconNft } from "./nft-icon.svg";
import { FiLink } from "react-icons/fi";
import { CSSProperties } from "react";
import { A } from "src/app/components/a";

export const ProductLinkNft = ({
  style,
  sku,
}: {
  style?: CSSProperties;
  sku: string;
}) => {
  if (!sku) return;
  return (
    <A
      target="_blank"
      to={`/provenance/${sku}`}
      style={{ display: "inline-flex", alignItems: "center", ...style }}
    >
      <IconNft />
      <p className={styles.discoverProvenance}>Discover Provenance</p>
      <FiLink size={16} strokeWidth={3} color="#800c32" />
    </A>
  );
};
