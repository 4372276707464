import { Switch } from "react-router-dom";
import { SentryRoute } from "src/app/routes/sentry-route";
import {
  HIGHEST_BIDS_PATH,
  HighestBids,
} from "src/app/pages/charity-public-pages/HighestBids";
import {
  AUCTION_LOT_LIVE_FEED_PATH,
  AuctionLotLiveFeed,
} from "../pages/charity-public-pages/AuctionLotLiveFeed";
import { TermsAndConditions } from "src/app/pages/terms-and-conditions";
import { AuthRoutes } from "./routes-auth";
import { path } from "./path";
import { RoutesPrivate } from "./routes-private";
import { useOnNavigate } from "../hooks/useOnNavigate";
import { ErrorBoundary } from "../components/error-boundary";
import { useSystemConfigQuery } from "../api/config";
import { PageErrorMaintenance } from "../pages/errors/error-maintenance";
import { SplashScreen } from "../layouts/splash-screen";
import { PageSignup } from "../pages/sign-up";
import { ProvenancePage } from "../pages/provenance";
import NftMetadata from "../pages/nft-metadata";
import { RedeemGiftsPage } from "../modules/Includes/CustomerSide/pages/redeem-gifts-page";

export function Routes() {
  const configQuery = useSystemConfigQuery();

  useOnNavigate(() => {
    window.scrollTo({ top: 0 });
    document.body.scrollTo({ top: 0 });
  });

  if (configQuery.isLoading) {
    return <SplashScreen />;
  }

  if (configQuery.isError || configQuery.data.maintenance) {
    return <PageErrorMaintenance />;
  }

  return (
    <ErrorBoundary>
      <Switch>
        <SentryRoute path={HIGHEST_BIDS_PATH} component={HighestBids} />

        <SentryRoute
          path={AUCTION_LOT_LIVE_FEED_PATH}
          component={AuctionLotLiveFeed}
        />
        <SentryRoute
          path={path.provenance.definition()}
          component={ProvenancePage}
        />
        <SentryRoute path="/redeem-gifts" component={RedeemGiftsPage} />

        <SentryRoute
          path={path.termsAndConditions()}
          component={TermsAndConditions}
        />

        <SentryRoute
          path={path.nftMetadata.definition()}
          component={NftMetadata}
        />

        <SentryRoute path={path.signup.definition()} component={PageSignup} />

        <SentryRoute path="/auth">
          <AuthRoutes />
        </SentryRoute>

        <SentryRoute>
          <RoutesPrivate />
        </SentryRoute>
      </Switch>
    </ErrorBoundary>
  );
}
