import styles from "./why.module.css";
import icon1Spirits from "./assets/why-icon-1-spirit.svg";
import icon1Wine from "./assets/why-icon-1-wine.svg";
import icon2 from "./assets/why-icon-2.svg";
import icon3 from "./assets/why-icon-3.svg";
import { Auction } from "src/app/models/auctions";

export const Why = ({ auction }: { auction: Auction }) => {
  return (
    <div className={styles.root}>
      <div>WHY CRURATED</div>

      <hr className={styles.hr} />

      <ul className={styles.ul}>
        <li className={styles.li}>
          <img src={auction.hasSpirit ? icon1Spirits : icon1Wine} alt="" />
          Direct from Domaine
        </li>
        <li className={styles.li}>
          <img src={icon2} alt="" />
          NFT certified bottles
        </li>
        <li className={styles.li}>
          <img src={icon3} alt="" />
          Free Storage
        </li>
      </ul>
    </div>
  );
};
