import { ReactNode, Suspense } from "react";
import { lazy } from "react";
import { __Model3DProps } from "./model-3d";

const Model3dLazy = lazy(() => import("./model-3d"));

export type Model3DProps = __Model3DProps & {
  suspenseFallback?: ReactNode;
};

export const Model3D = (props: Model3DProps) => {
  return (
    <Suspense fallback={props.suspenseFallback}>
      <Model3dLazy {...props} />
    </Suspense>
  );
};
