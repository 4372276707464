import React from "react";
import moment from "moment";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Tooltip } from "src/app/components/tooltip";

// TODO: rewrite

/**
 * @param {{
 *    status:string
 *    className?:string
 *    available_date: string | null | undefined
 * }} props
 */
export function TableProductStatus({
  status = "",
  className = "",
  available_date = null,
}) {
  let label = "";
  let labelClass = "";

  const isMobile = useIsMobile();

  switch (status) {
    case "in_barrel":
      label = (
        <LabelWithArrivalDate text="In Barrel" arrivalDate={available_date} />
      );
      labelClass = "in-barrel";
      break;
    case "outstanding_payment":
      label = "Outstanding Payment";
      labelClass = "status-outstanding-payment";
      break;
    case "with_producer":
      label = (
        <LabelWithArrivalDate
          text="With Producer"
          arrivalDate={available_date}
        />
      );
      labelClass = "status-with-producer";
      break;
    case "in_our_warehouse":
      label = "In our warehouse";
      labelClass = "status-in-warehouse";
      break;
    case "shipment_request":
      label = "Shipment Request";
      labelClass = "status-shipment-request";
      break;
    case "delivered":
      label = "Delivered";
      labelClass = "status-delivered";
      break;
    case "resell":
      label = "Sell Crurated";
      labelClass = "status-sell-crurated";
      break;
    case "donation":
      label = "Donation";
      labelClass = "status-donation";
      break;
    case "gifted":
      label = "Gifted";
      labelClass = "status-donation";
      break;
    default:
      break;
  }

  return (
    <div className={`tps-content ${className}`} style={{ cursor: "pointer" }}>
      <div
        className={`status-label ${labelClass}`}
        style={{
          cursor: "default",
          whiteSpace: "nowrap",
          width: isMobile ? "auto" : "100%",
          fontSize: "12px",
          margin: 0,
        }}
      >
        {label}
      </div>
    </div>
  );
}

function LabelWithArrivalDate({ text = "", arrivalDate }) {
  return (
    <Tooltip
      delay={150}
      position="bottom"
      content={
        <>
          Available on
          <br />
          {arrivalDate ? moment(arrivalDate).format("MMMM YYYY") : "-"}
        </>
      }
    >
      <div>
        <span>{text}</span>&nbsp;
        <IconTruck />
      </div>
    </Tooltip>
  );
}

function IconTruck() {
  return (
    <svg
      style={{ marginLeft: "6px" }}
      width="20"
      height="17"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14C8.53043 14 9.03914 13.7893 9.41421 13.4142C9.78929 13.0391 10 12.5304 10 12C10 11.4696 9.78929 10.9609 9.41421 10.5858C9.03914 10.2107 8.53043 10 8 10C7.46957 10 6.96086 10.2107 6.58579 10.5858C6.21071 10.9609 6 11.4696 6 12C6 12.5304 6.21071 13.0391 6.58579 13.4142C6.96086 13.7893 7.46957 14 8 14V14ZM18 14C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12C20 11.4696 19.7893 10.9609 19.4142 10.5858C19.0391 10.2107 18.5304 10 18 10C17.4696 10 16.9609 10.2107 16.5858 10.5858C16.2107 10.9609 16 11.4696 16 12C16 12.5304 16.2107 13.0391 16.5858 13.4142C16.9609 13.7893 17.4696 14 18 14V14Z"
        stroke="currentColor"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.05 12H15V1.6C15 1.44087 14.9368 1.28826 14.8243 1.17574C14.7117 1.06321 14.5591 1 14.4 1H1M5.65 12H3.6C3.52121 12 3.44319 11.9845 3.37039 11.9543C3.29759 11.9242 3.23145 11.88 3.17574 11.8243C3.12002 11.7685 3.07583 11.7024 3.04567 11.6296C3.01552 11.5568 3 11.4788 3 11.4V6.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M2 4H6"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4H20.61C20.726 4.00003 20.8395 4.03367 20.9367 4.09685C21.034 4.16003 21.1108 4.25005 21.158 4.356L22.948 8.384C22.9821 8.46048 22.9998 8.54326 23 8.627V11.4C23 11.4788 22.9845 11.5568 22.9543 11.6296C22.9242 11.7024 22.88 11.7685 22.8243 11.8243C22.7685 11.88 22.7024 11.9242 22.6296 11.9543C22.5568 11.9845 22.4788 12 22.4 12H20.5M15 12H16"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
}
