import { Button } from "src/app/components/button";
import { ErrorBaseUI } from "./error-base-ui";
import { path } from "src/app/routes/path";
import { A } from "src/app/components/a";

export const PageErrorForbidden = () => {
  return (
    <ErrorBaseUI
      title="Forbidden"
      text="You do not have the permissions to access this page"
      actions={[
        <Button
          key="btn-home"
          as={A}
          to={path.dashboard()}
          variant="outlined"
          label="Go back to the homepage"
        />,
      ]}
    />
  );
};
