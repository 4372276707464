import { Redirect, useHistory, useParams } from "react-router-dom";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { CruForm } from "src/app/components/cru-form";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { getIsValidTierName, step1Url, useSubscriptionPlan } from "../utils";
import { TierName } from "src/app/const";
import {
  InputAddressValue,
  emptyAddress,
} from "src/app/components/form-elements/input-address";
import { Fragment } from "react";
import { Step3 } from "./step3";
import { isEmpty } from "lodash";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { usePayAndSubscribeMutation } from "src/app/api/subscription";
import { SplashScreen } from "src/app/layouts/splash-screen";
import { SignupSuccess } from "./signup-success";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { emptyCardNumberElementValue } from "src/app/components/form-elements/input-card-number";
import { emptyCardExpiryElementValue } from "src/app/components/form-elements/input-card-expiry";
import { emptyCardCvcElementValue } from "src/app/components/form-elements/input-card-cvc";
import styles from "./index.module.css";
import { InputCouponValue, emptyCouponValue } from "../components/input-coupon";
import { parseApiErrorV1 } from "src/app/api/_parse-api-error";
import { path } from "src/app/routes/path";
import { createCookieService } from "crustack/hooks";
import { CountryOption } from "src/app/components/address-form/use-country-options";

export type SignupFormValues = {
  firstName: string;
  lastName: string;
  birthday: string;
  email: string;
  country?: CountryOption;
  password: string;
  confirmPassword: string;
  termsAccepted: boolean;
  profiling: boolean;
  marketing: boolean;
  phone: string;
  billingAddress: InputAddressValue;
  companyName: string;
  vat: string;
  isBillingAddressTheSameAsShipping: boolean;
  shippingAddress: InputAddressValue;
  shippingInfo: string;
  useWarehouse: boolean;
  cardNumber: StripeCardNumberElementChangeEvent;
  cardExpiry: StripeCardExpiryElementChangeEvent;
  cardCvc: StripeCardCvcElementChangeEvent;
  coupon: InputCouponValue;
  ref?: any;
};

const initialValues = {
  firstName: "",
  lastName: "",
  birthday: "",
  email: "",
  country: undefined,
  password: "",
  confirmPassword: "",
  termsAccepted: false,
  profiling: false,
  marketing: true,
  phone: "",
  billingAddress: emptyAddress,
  companyName: "",
  vat: "",
  isBillingAddressTheSameAsShipping: true,
  shippingAddress: emptyAddress,
  shippingInfo: "",
  useWarehouse: false,
  cardNumber: emptyCardNumberElementValue,
  cardExpiry: emptyCardExpiryElementValue,
  cardCvc: emptyCardCvcElementValue,
  coupon: emptyCouponValue,
};

const cookieService = createCookieService();

export const RegistrationSteps = () => {
  const stripe = useStripe();
  const stripeElements = useElements();
  const history = useHistory();
  const { tier } = useParams<{ tier: TierName }>();
  const [{ annual, step = 1 }, setSearchParams] = useTypedSearchParams({
    push: true,
    schema: z.object({
      annual: z.boolean().optional(),
      step: z.number().optional(),
    }),
  });

  const refCookie = cookieService.get("ref", { validate: (ref: any) => ref });

  const subscriptionPlan = useSubscriptionPlan({ tierName: tier, annual });

  const subscribeMutation = usePayAndSubscribeMutation();

  if (!getIsValidTierName(tier)) {
    return <Redirect to={path.signup.planSelection()} push={false} />;
  }

  if (!subscriptionPlan || !stripe || !stripeElements) return <SplashScreen />;

  return (
    <CruForm.Form<SignupFormValues, { error: string }>
      initialValues={initialValues}
      onSubmit={async (formValues: SignupFormValues) => {
        const shouldGoToNextStep = tier !== "explorer" && step !== 3;

        if (shouldGoToNextStep) {
          return setSearchParams({ step: step + 1 });
        }

        // Active campaign must be performed before subscribing
        // await activeCampaignMutation.mutateAsync(formValues).catch(() => {}); // prevent throw
        return subscribeMutation.mutateAsync({
          formValues: {
            ...formValues,
            ref: refCookie,
            profiling: true,
            marketing: true,
          },
          subscriptionPlan,
          stripe,
          stripeElements,
        });
      }}
    >
      {(cruForm) =>
        subscribeMutation.isSuccess ? (
          <SignupSuccess
            email={cruForm.values.email}
            idAccount={subscribeMutation.data}
          />
        ) : (
          <Fragment>
            {step !== 1 && isEmpty(cruForm.touched) && (
              <Redirect to={step1Url(history)} push={false} />
            )}
            {step === 1 && <Step1 cruForm={cruForm} tier={tier} />}
            {step === 2 && <Step2 cruForm={cruForm} />}
            {step === 3 && (
              <Step3 cruForm={cruForm} tier={tier} annual={!!annual} />
            )}
            {!!cruForm.submission.isError && (
              <div className={styles.error}>
                {parseApiErrorV1(cruForm.submission.error)}
              </div>
            )}
          </Fragment>
        )
      }
    </CruForm.Form>
  );
};
