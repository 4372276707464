import styles from "./index.module.css";
import { toPx } from "../../utils/toPx";
import { cn } from "../../utils/cn";
import { noop } from "lodash";
import { useIsMutating } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLatest } from "react-use";
import { Scrollable } from "../scrollable";
import { Button } from "../button";
import { MdClear } from "react-icons/md";
import { HtmlDialog } from "../html-dialog";

interface DrawerProps {
  title?: string;
  onClosed?: () => void;
  position?: "left" | "right";
  width?: number | string;
  render: (props: { startClosing: () => void }) => React.ReactNode;
  disableClose?: boolean;
}

export const Drawer: React.FC<DrawerProps> = ({
  title = "",
  onClosed = noop,
  position = "right",
  width = 420,
  render,
  disableClose,
}) => {
  const onClosedRef = useLatest(onClosed);
  const isMutating = !!useIsMutating();
  const [isOpen, setIsOpen] = useState(true);

  function startClosing() {
    if (disableClose || isMutating) return;
    setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen) return;
    const token = setTimeout(
      () => onClosedRef.current(),
      150, // must match the exit animation duration of the css
    );
    return () => clearTimeout(token);
  }, [isOpen, onClosedRef]);

  return (
    <HtmlDialog
      className={cn(styles.root, styles[position], !isOpen && styles.closing)}
      style={{ width: toPx(width) }}
      onClose={startClosing}
      isOpen
    >
      <div className={styles.heading}>
        <h2 className={styles.title}>{title}&nbsp;</h2>

        <Button
          variant="ghost"
          type="button"
          icon={<MdClear size={24} />}
          tooltip=""
          aria-label="Close"
          className={styles.close}
          onClick={startClosing}
        />
      </div>

      <Scrollable className={styles.scrollable}>
        {render({ startClosing })}
      </Scrollable>
    </HtmlDialog>
  );
};
