import styles from "./cell-amount-to-pay.module.css";
import { OutstandingPaymentV1 } from "src/app/models/OutstandingPaymentV1";
import { formatCurrency } from "src/app/utils/formatCurrency";

type Props = {
  outstandingPayment: OutstandingPaymentV1;
};

export const CellAmountToPay = ({ outstandingPayment }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.totalAmount}>
        {formatCurrency(outstandingPayment.totalToPay)}
      </div>
      <div className={styles.outstandingPayment}>Outstanding Payment</div>
      <div>
        Total Offer: <b>{formatCurrency(outstandingPayment.totalToPay)}</b>
      </div>
      <div>
        Already paid: <b>{formatCurrency(outstandingPayment.totalPaid)}</b>
      </div>
    </div>
  );
};
