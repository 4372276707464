import { noop, range } from "lodash";
import styles from "./BidControlsCharity.module.css";
import React from "react";
import { ID_AUCTION_TYPE, MERCH_TYPE } from "src/app/const";
import { Tooltip } from "../tooltip";

export const BidControlsCharity = ({
  optionsQuantity = [],
  optionsBid = [],
  quantity,
  onChangeQuantity = noop,
  price,
  onChangePrice = noop,
  merchType,
  greyedOut,
  auction,
}) => {
  const isExperience = !!auction.experience;
  const isSingleLot = auction.type.id === ID_AUCTION_TYPE.SINGLE_LOTS;
  const isBarrel = auction.type_product === "barrel";

  return (
    <div className={`${styles.root} ${greyedOut ? styles.greyedOut : ""}`}>
      <div style={{ display: "flex" }}>
        {!isSingleLot && (
          <SelectQuantity
            label="Quantity"
            quantity={quantity}
            onChange={onChangeQuantity}
            options={optionsQuantity}
            merchType={merchType}
            disabled={greyedOut || isSingleLot}
          />
        )}

        {!!isSingleLot && (
          <ShowCurrentPrice
            label="Current price"
            price={auction.currentPrice}
            disabled
          />
        )}

        <SelectPrice
          label={
            isSingleLot
              ? "Make your offer"
              : isExperience
                ? "Offer per Pass"
                : isBarrel
                  ? `Offer per ${auction.barrel.nft_liters} L`
                  : "Offer per Lot"
          }
          price={price}
          options={optionsBid}
          onChange={onChangePrice}
          disabled={greyedOut}
          isSingleLot={isSingleLot}
        />
      </div>
      <TotalPrice
        price={price}
        quantity={quantity}
        textTaxes={
          auction.is_charity
            ? "Excl. of tax if appl."
            : "Excl. of processing fees and taxes"
        }
      />
    </div>
  );
};

function SelectQuantity({
  label,
  options = [],
  quantity,
  onChange = noop,
  merchType,
  disabled = false,
}) {
  const isLocked = options.length <= 1;
  const _options =
    merchType === MERCH_TYPE.BARRELS
      ? range(options.min, options.max + 1).map((qty) => ({
          value: qty * options.step,
          name: `${qty * options.step * (options.mul || 1)} L`,
        }))
      : range(options.min, options.max + 1, options.step).map((qty) => ({
          value: qty,
          name: `${qty}`,
        }));

  return (
    <label
      className={`${styles.select} ${styles.selectQuantity} ${
        isLocked ? styles.locked : ""
      }`}
    >
      {!!label && <div className={styles.label}>{label}</div>}
      <select
        onChange={(e) => onChange(+e.target.value)}
        value={quantity}
        disabled={disabled || isLocked}
      >
        {_options.map((item, index) => (
          <option
            key={`${merchType}-select-quantity-${index}`}
            value={item.value}
          >
            {item.name}
          </option>
        ))}
      </select>
    </label>
  );
}

function SelectPrice({
  label,
  options = [],
  price,
  onChange = noop,
  merchType,
  disabled = false,
}) {
  const isLocked = options.length <= 1;
  return (
    <label
      className={`${styles.select} ${styles.selectPrice} ${
        isLocked ? styles.locked : ""
      }`}
    >
      <div className={styles.label}>{label}</div>
      <select
        onChange={(e) => onChange(+e.target.value)}
        value={price}
        disabled={disabled || isLocked}
      >
        {options.map((price, index) => (
          <option key={`${merchType}-select-price-${index}`} value={price}>
            {price}€
          </option>
        ))}
      </select>
    </label>
  );
}

function TotalPrice({ price, quantity, textTaxes }) {
  return (
    <div className={styles.totalPriceContainer}>
      <div className={styles.total}>
        <span style={{ fontWeight: 800, fontSize: "22px" }}>
          &euro;{price * quantity}
        </span>
        <Tooltip content="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details.">
          <div className={styles.taxes}>{textTaxes}</div>
        </Tooltip>
      </div>
    </div>
  );
}

function ShowCurrentPrice({ price, label }) {
  return (
    <label
      className={`${styles.currentPrice} 
      }`}
    >
      <div className={styles.label}>{label}</div>
      <div className={styles.priceText}>{price}€</div>
    </label>
  );
}
