import { useState, forwardRef, ComponentProps, useRef } from "react";
import styles from "./index.module.css";
import { Input } from "../input";
import { cn } from "src/app/utils/cn";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useMergeRefs } from "src/app/hooks/useMergeRefs";

type Props = Omit<ComponentProps<"input">, "type" | "ref">;

export const InputPassword = forwardRef<HTMLInputElement, Props>(
  ({ className = "", ...props }, forwardedRef) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const ref = useMergeRefs(inputRef, forwardedRef);

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
      const input = inputRef.current;
      if (!input) return;
      // focus the input since the toggle is inside of the input
      input.focus();
      setTimeout(() => {
        input.selectionStart = input.selectionEnd = input.value.length;
      });
    };

    return (
      <div className={styles.root}>
        <Input
          ref={ref}
          type={showPassword ? "text" : "password"}
          className={cn(styles.input, className)}
          {...props}
        />
        <button
          type="button"
          onClick={toggleShowPassword}
          className={styles.button}
          title="Toggle password"
          tabIndex={-1}
        >
          {showPassword ? (
            <FaRegEyeSlash className={styles.icon} />
          ) : (
            <FaRegEye className={styles.icon} />
          )}
        </button>
      </div>
    );
  },
);
