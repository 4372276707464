import React, { useMemo, useRef, useState } from "react";
import * as Slider from "@radix-ui/react-slider";
import styles from "./BarrelInput.module.css";
// import barrelLeftSrc from "src/media/barrel/barrel-container-left.png";
import barrelLeftSrc from "src/media/barrel.svg";
import barrelBorderSrc from "src/media/barrel/bordered-barrel.svg";
import redWineSrc from "src/media/barrel/barrel-half-container-filled-2.svg";
import whiteWineSrc from "src/media/barrel/barrel-half-container-filled-white-wine.svg";
import { nanoid } from "nanoid";

const spaceTopPercentage = 3.75;
const throttleMs = 150;
const noop = () => {};

/**@deprecated */
export function BarrelInput({
  className = "",
  style = {},
  max,
  step,
  onChange = noop,
  value,
  wineColor = "RED",
  barrelHeight = 211,
  hideTotal = false,
  disabled = false,
}) {
  const trailingTimeoutRef = useRef();
  const lastCallTimestampRef = useRef(0);

  function handleChange([value]) {
    if (!value) return; // prevent 0
    const now = Date.now();
    clearTimeout(trailingTimeoutRef.current);
    if (now - lastCallTimestampRef.current < throttleMs) {
      const remainingMs =
        throttleMs - (now - lastCallTimestampRef.current) + 50;
      trailingTimeoutRef.current = setTimeout(() => {
        onChange(value);
      }, remainingMs);
      return;
    }
    onChange(value);
    lastCallTimestampRef.current = now;
  }

  return (
    <div
      className={`barrel-container-wrapper ${className}`}
      style={{ userSelect: "none", ...style }}
    >
      <div
        className="bgc-barrel-holder"
        style={{ width: `${(200 / 211) * barrelHeight}px` }}
      >
        <div className="barrel-container-wrapper">
          <div className="bcw-left">
            <img
              src={barrelLeftSrc}
              alt="Barrel"
              className="img-responsive bcw-left-image"
              // style={{ height: barrelHeight }}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="bcw-right">
            <Scale max={max} numberOfSteps={Math.min(5, max / step)} />

            <div className="bcw-half-barrel-holder">
              <img
                src={barrelBorderSrc}
                alt="barrel"
                className="bcw-barrel-half-new"
                style={{ height: barrelHeight }}
              />

              <WavyWineAnimation
                color={wineColor}
                fillHeight={(value / max) * (100 - spaceTopPercentage)}
              />

              <Slider.Root
                className={styles.sliderRoot}
                style={{ top: `${spaceTopPercentage}%` }}
                value={[value]}
                // do not allow 0
                onValueChange={handleChange}
                orientation="vertical"
                max={max}
                min={0}
                step={step}
                disabled={disabled}
              >
                <Slider.Track className={styles.sliderTrack}>
                  <Slider.Range className={styles.sliderRange} />
                </Slider.Track>
                <Slider.Thumb className={styles.sliderThumb} />
              </Slider.Root>
            </div>
          </div>
        </div>
        {!hideTotal && (
          <div
            className="barrel-max-capacity-label"
            style={{ color: "currentColor" }}
          >
            {`${value} L`}
          </div>
        )}
      </div>
    </div>
  );
}

function Scale({ max, numberOfSteps }) {
  const [keySuffix] = useState(nanoid());
  const steps = useMemo(
    () =>
      Array(Math.round(numberOfSteps))
        .fill(0)
        .map((_, index) => +((max / numberOfSteps) * index).toFixed(2))
        .concat(max)
        .reverse(),
    [max, numberOfSteps],
  );

  return (
    <div className={styles.scaleRoot} style={{ top: `${spaceTopPercentage}%` }}>
      {steps.map((step, i) => (
        <div
          className={styles.scaleStepLine}
          key={`step-${step}-${keySuffix}`}
          style={{ opacity: i !== steps.length - 1 ? 1 : 0 }}
        >
          <div className={styles.scaleStepValue}>{step}</div>
        </div>
      ))}
    </div>
  );
}

function WavyWineAnimation({ color, fillHeight = 0 }) {
  return (
    <div className="bcw-filler-container-top" style={{ zIndex: 0 }}>
      <div
        className="bcw-filler-container"
        style={{
          height: `${fillHeight}%`,
          background: "white",
        }}
      >
        <div className="filler-wave-wrap">
          <div className="filler-wave">
            <svg>
              <path
                className="waveshape"
                d="M300,300V2.5c0,0-0.6-0.1-1.1-0.1c0,0-25.5-2.3-40.5-2.4c-15,0-40.6,2.4-40.6,2.4c-12.3,1.1-30.3,1.8-31.9,1.9c-2-0.1-19.7-0.8-32-1.9c0,0-25.8-2.3-40.8-2.4c-15,0-40.8,2.4-40.8,2.4c-12.3,1.1-30.4,1.8-32,1.9c-2-0.1-20-0.8-32.2-1.9c0,0-3.1-0.3-8.1-0.7V300H300z"
              ></path>
            </svg>
          </div>
        </div>
        {color.toLowerCase() === "red" ? (
          <img
            src={redWineSrc}
            alt="barrel-filled"
            className="filled-barrel-red"
          />
        ) : (
          <img
            src={whiteWineSrc}
            alt="barrel-filled"
            className="filled-barrel-red"
          />
        )}
      </div>
    </div>
  );
}
