import { NavLinks } from "src/app/components/nav-links";
import { path } from "src/app/routes/path";

export const Navigation = () => {
  return (
    <NavLinks
      links={[
        { to: path.myActivities.archive(), label: "Archive" },
        { to: path.myActivities.offers.value(), label: "Your Offers" },
        { to: path.myActivities.wishlist(), label: "Wishlist" },
      ]}
    />
  );
};
