import { forwardRef, useRef } from "react";
import styles from "./index.module.css";
import { noop } from "lodash";
import { FaCheck } from "react-icons/fa6";
import { toPx } from "src/app/utils/toPx";
import { cn } from "src/app/utils/cn";

type Props = Omit<React.ComponentProps<"div">, "onChange"> & {
  checked: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  disabled?: boolean;
  className?: string;
  id?: string;
  name?: string;
  size?: string | number;
  value?: string;
};

export const Radio = forwardRef<HTMLDivElement, Props>(
  (
    {
      checked,
      onChange = noop,
      disabled,
      className = "",
      id,
      name,
      size,
      value,
      style,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <div className={cn(styles.root, className)}>
        <input
          ref={inputRef}
          type="radio"
          className={styles.input}
          style={size ? { fontSize: toPx(size) } : {}}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          id={id}
          name={name}
          value={value}
        />

        <div
          className={styles.fake}
          ref={ref}
          style={size ? { fontSize: toPx(size), ...style } : style}
          {...props}
        >
          <FaCheck className={styles.icon} />
        </div>
      </div>
    );
  },
);
