import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

export const useClearAllStores = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    return new Promise((resolve) => {
      localStorage.clear();
      queryClient.clear();
      queryClient.invalidateQueries();
      clearCookies();
      setTimeout(() => resolve(true));
    });
  }, [queryClient]);
};

// 3rd answer of https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
function clearCookies() {
  const cookies = document.cookie.split("; ");
  for (let c = 0; c < cookies.length; c++) {
    const domains = window.location.hostname.split(".");
    while (domains.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        domains.join(".") +
        " ;path=";
      const urlPathSlice = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (urlPathSlice.length > 0) {
        document.cookie = cookieBase + urlPathSlice.join("/");
        urlPathSlice.pop();
      }
      domains.shift();
    }
  }
}
