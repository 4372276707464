import {
  Auction,
  isAuctionOfBarrel,
  isAuctionOfLot,
} from "src/app/models/auctions";
import { CardForStoreLot } from "./card-for-store.lot";
import { CardForStoreBarrel } from "./card-for-store.barrel";
import { CardCtxProvider } from "../../card-context";

export type CardProps = {
  className?: string;
  cardId: string;
  auction: Auction;
};

/**
 * @description automatically switches the auction type to delegate to the proper card
 */
export const Card = ({ auction, ...props }: CardProps) => {
  if (auction.isForStore && isAuctionOfBarrel(auction)) {
    return (
      <CardCtxProvider initialAuction={auction}>
        <CardForStoreBarrel {...props} />
      </CardCtxProvider>
    );
  }
  if (auction.isForStore && isAuctionOfLot(auction)) {
    return (
      <CardCtxProvider initialAuction={auction}>
        <CardForStoreLot {...props} />
      </CardCtxProvider>
    );
  }
  return null;
};
