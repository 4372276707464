import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";
import { CURRENCY, formatCurrency } from "../utils/formatCurrency";
import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
import { ID_AUCTION_TYPE } from "../const";
import { SubscriptionV1 } from "src/app/models/SubscriptionV1";

export type GaEventConfig = {
  nameOrOptions: string | UaEventOptions;
  params?: any;
};

export function triggerGaEvent(event: GaEventConfig) {
  ReactGA.event(event.nameOrOptions, event.params);
}

/**
 * RACCOMENDED GOOGLE EVENTS
 * @link
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=it&client_type=gtag
 *
 * CUSTOM EVENTS
 * @link https://support.google.com/analytics/answer/12229021?hl=en
 */

export const gaEventConfig = {
  viewAuctionDetails(auction: Auction): GaEventConfig {
    return {
      nameOrOptions: "view_item",
      params: {
        currency: CURRENCY.EURO,
        value: auction.currentPrice,
        items: [
          {
            item_id: auction.id,
            item_name: auction.description,
            item_variant: auction.type_product,
            item_category: !isAuctionOfBarrel(auction)
              ? auction.rootCategory
              : null,
          },
        ],
      },
    };
  },

  makeABid({
    price,
    quantity,
    id_bet,
    auction,
  }: {
    price: number;
    quantity: number;
    id_bet: number;
    auction: Auction;
  }) {
    const isPurchase = [
      ID_AUCTION_TYPE.PERMANENT,
      ID_AUCTION_TYPE.PRIVATE_SALE,
      ID_AUCTION_TYPE.EN_PRIMEUR,
    ].includes(auction.type.id);

    if (isPurchase) {
      return {
        nameOrOptions: "purchase",
        params: {
          transaction_id: id_bet,
          currency: CURRENCY.EURO,
          value: price,
          items: [
            {
              item_id: auction.id,
              item_name: auction.description,
              item_variant: auction.type_product,
              quantity,
              item_category: isAuctionOfBarrel(auction)
                ? null
                : auction.rootCategory,
            },
          ],
        },
      };
    } else {
      return {
        nameOrOptions: {
          action: "bid_auction",
          category: "Auction",
          label: `Bid of ${formatCurrency(price)} made on auction ${
            auction.id
          }.`,
          value: auction.id, //Required for the metrics
          nonInteraction: false,
        },
        // params: {
        //   auction_name: auction.description,
        //   type_product: auction.type_product,
        //   price: price,
        //   quantity,
        //   id_bet,
        //   auction_id: auction.id,
        //   root_categories: isAuctionOfBarrel(auction)
        //     ? null
        //     : auction.rootCategory,
        // },
      };
    }
  },
  signup(method?: string | number): GaEventConfig {
    return {
      nameOrOptions: "sign_up",
      params: { method: `${method}` },
    };
  },
  subscription(
    subscription: SubscriptionV1,
    type: "create" | "downgrade" | "upgrade",
  ): GaEventConfig {
    return {
      nameOrOptions: {
        action: `subscription_${type}`,
        category: "User",
        label: `User subscribed to plan ${subscription.id_subscription}`,
        value: subscription.id_subscription,
        nonInteraction: false,
      },
    };
  },
};
