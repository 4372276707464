import { cn } from "src/app/utils/cn";
import styles from "./styles.module.css";
import { MdClear } from "react-icons/md";

type Props = React.ComponentPropsWithoutRef<"div">;

export const IconClear = ({ className = "", ...props }: Props) => {
  return (
    <div className={cn(styles.clearIcon, className)} role="button" {...props}>
      <MdClear size={18} />
    </div>
  );
};
