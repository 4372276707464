import { CSSProperties } from "react";
import { toPx } from "../../utils/toPx";

type Props = {
  size?: string | number;
  className?: string;
  style?: CSSProperties;
  strokeWidth?: number;
};

export const SpinnerSm = ({
  size = 18,
  className = "",
  style,
  strokeWidth = 12,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={toPx(size)}
      height={toPx(size)}
      style={style}
      className={className}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  );
};
