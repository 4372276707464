import moment from "moment-timezone";
import { STORAGE_KEY } from "../const";
import { env } from "src/env";

export const GetBrowser = () => {
  var name;
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    name = "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    name = "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    name = "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    name = "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    name = "IE";
  } else {
    name = "unknown";
  }

  return name;
};

//var secret_key = "we7fyn347xtrym340tc34780t2cmy34780mtxy78340mxyt780";

// Description for auction
export const setDataTimeZone = (date, format) => {
  function RefreshDataTimeZone(date) {
    var dateType = date;

    var event = new Date(dateType);

    const timezoneInMinutes = TimezoneInMinutes();

    var minutes = timezoneInMinutes.minutes;

    if (minutes > 0) {
      var dateSet = moment(event).add(minutes, "minutes").format(format);
    } else if (minutes < 0) {
      dateSet = moment(event).subtract(minutes, "minutes").format(format);
    } else {
      dateSet = moment(event).format(format);
    }

    return dateSet;
  }

  return RefreshDataTimeZone(date);
};

export const Timezone = () => {
  const timezoneInMinutes = TimezoneInMinutes();

  var minutes = timezoneInMinutes.minutes;
  var sign = timezoneInMinutes.sign;

  return sign + minutes;
};

const TimezoneInMinutes = () => {
  var tz_guess = moment.tz.guess();
  var tz_tm = moment.tz(tz_guess).format("Z");

  var sign = tz_tm.charAt(0);
  var result = tz_tm.substring(1) + ":00";
  var hms = result; // your input string
  var a = hms.split(":"); // split it at the colons
  // Hours are worth 60 minutes.
  var minutes = +a[0] * 60 + +a[1];

  return { minutes: minutes, sign: sign };
};

async function extractAccessToken(parameters) {
  try {
    return localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  } catch (e) {
    return parameters?.accessToken || "";
  }
}

/**
 * @param {string} path
 * @param {string} method
 * @param {any} parameters
 * @param {any} options
 */
export const sendCall = async (
  path,
  method = "GET",
  parameters = null,
  options = {},
) => {
  const accessToken = await extractAccessToken(parameters);
  const headers =
    parameters instanceof FormData
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${accessToken}`,
        };
  if (path.startsWith("users/LoginUser") || options.noAuth || !accessToken) {
    delete headers.Authorization;
  }
  const body =
    method === "GET"
      ? undefined
      : parameters instanceof FormData
        ? parameters
        : JSON.stringify(parameters);
  if (body && body instanceof FormData) {
    body.delete("accessToken");
  }
  const search =
    method === "GET"
      ? new URLSearchParams({
          ...getSearchParams(path),
          ...parameters,
        }).toString()
      : new URLSearchParams(getSearchParams(path)).toString();

  const url = new URL(path, env.REACT_APP_API_URL_NEW);
  url.search = search;

  return fetch(url, {
    headers,
    body,
    method,
  }).then((res) => res.json());
};

function getSearchParams(path) {
  const params = {};
  const searchParams = new URL(
    path,
    path.startsWith("http") ? undefined : env.REACT_APP_API_URL_NEW,
  ).searchParams;
  for (let [key, val] of searchParams.entries()) {
    params[key] = val;
  }
  return params;
}
