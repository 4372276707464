import { useState } from "react";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { Dialog } from "src/app/components/dialog-2";
import { StepSummary } from "./step-summary";
import { CheckPurchase } from "../check-purchase";
import StepMissingInfo from "./step-missing-info";
import { PurchaseMethod } from "src/app/api/mutations_new_to_rename";
import { AUCTION_ERROR_TYPE } from "src/app/const";

type Props = {
  action: Exclude<PurchaseMethod, "buy_now">;
  auction: AuctionOfBarrel | AuctionOfLot;
  onConfirm: () => void;
  onClose: () => void;
  closeButton?: {
    label: string;
  };
  actionButton?: {
    label: string;
    onClick?: (onClose: () => void) => void;
  };
  bidDetails: {
    price: number;
    quantity: number;
  };
  showTaxDisclaimer?: boolean;
};

type PaymentStep = "check-bid" | "summary" | "missing-info";

export default function ConfirmBidModal({
  auction,
  action,
  bidDetails,
  onConfirm,
  onClose,
  showTaxDisclaimer = true,
}: Props) {
  const [currentStep, setStep] = useState<PaymentStep>("check-bid");

  return (
    <Dialog
      width={360}
      mandatory={false}
      onClosed={onClose}
      render={({ startClosing }) => (
        <>
          {currentStep === "check-bid" ? (
            <CheckPurchase
              auction={auction}
              purchaseMethod={action}
              onSettled={(errors) => {
                const isMissingInfo =
                  errors &&
                  errors.length &&
                  errors.reduce(
                    (isMissingInfo, el) =>
                      isMissingInfo ||
                      [
                        AUCTION_ERROR_TYPE.address,
                        AUCTION_ERROR_TYPE.card,
                      ].includes(el.type),
                    false,
                  );

                setStep(isMissingInfo ? "missing-info" : "summary");
              }}
              onClose={startClosing}
            />
          ) : currentStep === "missing-info" ? (
            <StepMissingInfo
              onClose={startClosing}
              onComplete={() => setStep("check-bid")}
            />
          ) : (
            <StepSummary
              auction={auction}
              action={action}
              bidDetails={bidDetails}
              onConfirm={onConfirm}
              onClose={startClosing}
              showTaxDisclaimer={showTaxDisclaimer}
            />
          )}
        </>
      )}
    />
  );
}
