import styles from "./add-card-form.module.css";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { CruForm } from "src/app/components/cru-form";
import {
  InputCard,
  emptyCardElementValue,
} from "src/app/components/form-elements/input-card";
import {
  InputAddress,
  InputAddressValue,
  emptyAddress,
} from "src/app/components/form-elements/input-address";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useAddCardMutation } from "src/app/api/payments";
import { useInvoiceAddressMutation } from "src/app/api/adresses_to_rename_later";
import { FaExclamationTriangle } from "react-icons/fa";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { cn } from "src/app/utils/cn";

type FormData = {
  card: StripeCardElementChangeEvent;
  billingAddress: InputAddressValue;
};

export const AddCardForm = ({
  onError,
  onSuccess,
  className,
}: {
  onError?: () => void;
  onSuccess?: () => void;
  className?: string;
}) => {
  const isMobile = useIsMobile();
  const user = useMyUser();
  const alertPopup = useAlertPopup();
  const askForBillingAddress = !user.addressInvoice.length;
  const isFetching = useIsFetching();

  const addCardMutation = useAddCardMutation();
  const addBillingAddressMutation = useInvoiceAddressMutation();

  const isMutating =
    addCardMutation.isLoading || addBillingAddressMutation.isLoading;

  const queryClient = useQueryClient();

  function handleSubmit(values: FormData) {
    if (values.billingAddress.warning) {
      alertPopup.prompt({
        actionButton: {
          label: "Yes",
          onClick: () => submit(values.billingAddress),
        },
        title: "Are you sure?",
        content: "Your billing address country is not available for shipping.",
      });
    } else {
      submit(values.billingAddress);
    }
  }

  async function submit(billingAddress: InputAddressValue) {
    let error: string = "";
    try {
      await addCardMutation.mutateAsync();
    } catch (e) {
      error = "We could not update your card details, please try again later.";
    }
    if (!error && askForBillingAddress) {
      try {
        const result =
          await addBillingAddressMutation.mutateAsync(billingAddress);
        if (result.error) throw new Error();
      } catch (e) {
        error =
          "We could not update your billing address, please try again later.";
      }
    }
    //TODO: Portare popup fuori il componente, usare solo onError e OnSuccess
    if (error) {
      alertPopup.error({
        title: error || "An error has occured, please try again later",
        onClose: onError,
      });
    } else {
      await queryClient.invalidateQueries();
      alertPopup.success({
        title: "Your payment info have been updated with success",
        onClose: onSuccess,
      });
    }
  }

  return (
    <>
      <CruForm.Form<FormData>
        className={cn(className, styles.form, isMobile && styles.mobile)}
        initialValues={{
          card: emptyCardElementValue,
          billingAddress: emptyAddress,
        }}
        onSubmit={handleSubmit}
      >
        {(cruForm) => (
          <div>
            <div className={styles.label}>Add a new card</div>
            <CruForm.Field
              component={InputCard}
              cruForm={cruForm}
              onChange={(e) => cruForm.setValues({ card: e })}
              onBlur={() => cruForm.setTouched({ card: true })}
              name="card"
              validate={(e) => e.error?.message}
            />
            <CruForm.ErrorMessage cruForm={cruForm} name="card" />

            {!!askForBillingAddress && (
              <div style={{ marginTop: "20px" }}>
                <div className={styles.label}>Billing Address</div>
                <CruForm.Field
                  component={InputAddress}
                  cruForm={cruForm}
                  onChange={(e) => cruForm.setValues({ billingAddress: e })}
                  onBlur={() => cruForm.setTouched({ billingAddress: true })}
                  value={cruForm.values.billingAddress}
                  name="billingAddress"
                  notAvailableForShipping="warn"
                  validate={(v) => v.error}
                />

                <CruForm.ErrorMessage cruForm={cruForm} name="billingAddress" />

                {!!cruForm.values.billingAddress.warning && (
                  <div className={styles.warning}>
                    <FaExclamationTriangle />
                    {cruForm.values.billingAddress.warning}
                  </div>
                )}
              </div>
            )}

            <div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  marginTop: "32px",
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "16px",
                  gap: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                disabled={isMutating || !!isFetching}
              >
                Submit
                {(!!isMutating || !!isFetching) && <SpinnerSm />}
              </button>
            </div>
          </div>
        )}
      </CruForm.Form>
    </>
  );
};
