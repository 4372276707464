import styles from "./message-box.module.css";
import { CSSProperties, ReactNode } from "react";
import { cn } from "src/app/utils/cn";

type Props = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  intent: "warning" | "success";
};

export const MessageBox = ({ className, intent, ...props }: Props) => {
  return (
    <div
      role="alert"
      className={cn(styles.root, styles[intent], className)}
      {...props}
    />
  );
};
