import styles from "./bulk-actions.module.css";
import { IoCloseOutline } from "react-icons/io5";
import { noop } from "lodash";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { SelectAction } from "./select-action";
import useResizeObserver from "use-resize-observer";
import { CSSProperties, ComponentProps } from "react";

type Action = ComponentProps<typeof SelectAction>["actions"][number];

type Props = {
  selection: Array<string | number>;
  onClearSelection: (selection: (string | number)[]) => void;
  actions: Array<Action | false>;
  style: CSSProperties;
};

export function BulkActions({
  selection = [],
  onClearSelection = noop,
  actions = [],
  style = {},
}: Props) {
  const isMobile = useIsMobile();

  const { width: tableWidth } = useResizeObserver({
    ref: document.querySelector("table"),
  });

  if (!actions.length) return null;

  return (
    <>
      <SelectAction style={style} actions={actions.filter(Boolean)} />

      {!!selection.length && (
        <div
          className={`${styles.root} ${isMobile ? styles.mobile : ""}`}
          style={{ width: tableWidth ? `${tableWidth}px` : "100%" }}
        >
          <span className={styles.left}>
            <IoCloseOutline size="2em" onClick={() => onClearSelection([])} />
            {selection.length} Selected
          </span>

          <SelectAction
            actions={actions.filter(Boolean)}
            style={{ minWidth: isMobile ? 140 : 200 }}
            placement="top"
          />
        </div>
      )}
    </>
  );
}
