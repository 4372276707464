import styles from "./HistoryButton.module.css";
import React from "react";
import { noop } from "lodash";

export const HistoryButton = ({
  count,
  onMouseEnter = noop,
  onMouseLeave = noop,
  style = {},
}) => {
  if (!count) {
    return null;
  }

  return (
    <button
      className={styles.root}
      style={style}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.count}>{count}</div>
    </button>
  );
};
