/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import iconSrc from "src/media/common/no-bids.svg";

export function NoBidsNew({ title = "" }) {
  return (
    <div className="no-bids-container">
      <div className="nbc-inner">
        <figure>
          <img src={iconSrc} alt="no-bids" />
        </figure>
        <span className="nbc-title">{title ?? "No live offers currently"}</span>
      </div>
    </div>
  );
}
