import { Button } from "../../../button";
import { useCardCtx } from "../../card-context";

export const ButtonToggleProductList = () => {
  const cardCtx = useCardCtx();
  return (
    <Button
      variant="ghost"
      icon={cardCtx.showLotProductList ? <IconClose /> : <IconMenu />}
      tooltip={
        cardCtx.showLotProductList ? "Close product list" : "Open product list"
      }
      onClick={cardCtx.toggleProductList}
    />
  );
};

function IconMenu() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="9" width="12" height="1" rx="1" fill="#000000" />
      <rect x="6" y="3" width="12" height="1" rx="1" fill="#000000" />
      <rect x="6" y="15" width="12" height="1" rx="1" fill="#000000" />
      <rect x="2" y="3" width="2" height="1" rx="1" fill="#000000" />
      <rect x="2" y="9" width="2" height="1" rx="1" fill="#000000" />
      <rect x="2" y="15" width="2" height="1" rx="1" fill="#000000" />
    </svg>
  );
}

function IconClose() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5L5 15M5 5L15 15"
        stroke="#000000cc"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
