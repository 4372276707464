import { Tooltip } from "../tooltip";
import styles from "./BottlingDate.module.css";
export function BottlingDate({ date }) {
  if (!date) return null;

  return (
    <Tooltip position="bottom" content="Date Bottling">
      <div className={styles.root}>
        <span className={styles.icon}></span>
        <span className={styles.text}>{date}</span>
      </div>
    </Tooltip>
  );
}
