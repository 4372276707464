import styles from "./index.module.css";
import { ComponentProps, ReactNode, useCallback, useState } from "react";
import { useInView } from "react-intersection-observer";
import { cn } from "../../utils/cn";
import { ID_IO_ROOT } from "../../const";

type Props = ComponentProps<"img"> & {
  src: string;
  fallback?: ReactNode;
  alt: string;
  lazyLoad?: boolean;
};

export const Img = ({
  src,
  fallback = null,
  lazyLoad = true,
  className = "",
  alt = "",
  ...props
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  const { ref, inView } = useInView({
    rootMargin: "600px",
    triggerOnce: true,
    root: document.getElementById(ID_IO_ROOT),
  });

  const _src = inView || !lazyLoad ? src : "";

  // Handle image loaded from the cache
  const cbRef = useCallback(
    (img: HTMLImageElement | null | undefined) => {
      lazyLoad && ref(img);
      _src && img?.complete && setIsLoading(false);
    },
    [ref, _src, lazyLoad],
  );

  return (
    <>
      {!!isLoading && fallback}
      <img
        ref={cbRef}
        className={cn(
          styles.img,
          className,
          isLoading ? styles.hide : styles.show,
        )}
        src={inView || !lazyLoad ? src : ""}
        onLoad={() => setIsLoading(false)}
        alt={alt}
        {...props}
      />
    </>
  );
};
