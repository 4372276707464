import { noop } from "lodash";
import { useEffect, useRef } from "react";

export const useInterval = (
  cb: (interval: NodeJS.Timeout) => void = noop,
  delayMs: number,
) => {
  const cbRef = useRef(cb);
  cbRef.current = cb;

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(
      () => cbRef.current(interval),
      delayMs,
    );

    return () => clearInterval(interval);
  }, [delayMs]);
};
