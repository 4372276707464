import styles from "./index.module.css";
import React, {
  ElementType,
  ComponentPropsWithoutRef,
  forwardRef,
  ForwardedRef,
  ReactNode,
} from "react";
import { GaEventConfig, triggerGaEvent } from "src/app/models/GaEvent";
import { cn } from "src/app/utils/cn";
import { Tooltip, TooltipProps } from "../tooltip";
import { toPx } from "src/app/utils/toPx";
import { omit } from "lodash";
import { RenderIcon } from "./render-icon";

// fix forwardRef type inference (https://fettblog.eu/typescript-react-generic-forward-refs/)
declare module "react" {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

type BaseProps<T extends ElementType<any>> = ComponentPropsWithoutRef<T> & {
  as?: T;
  disabled?: boolean;
  forbidden?: boolean;
  isLoading?: boolean;
  variant?: "contained" | "outlined" | "ghost";
  gaEventConfig?: GaEventConfig;
  children?: never;
};

type LabelledButtonProps<T extends ElementType<any>> = BaseProps<T> & {
  icon?: ReactNode;
  label: ReactNode;
  size?: never;
  tooltip?: never;
};

type IconButtonProps<T extends ElementType<any>> = BaseProps<T> & {
  icon: ReactNode;
  size?: number | string;
  label?: never;
  tooltip:
    | string
    | {
        content: string;
        position?: TooltipProps["position"];
        delay?: TooltipProps["delay"];
      };
};

export type ButtonProps<T extends ElementType<any> = "button"> =
  | IconButtonProps<T>
  | LabelledButtonProps<T>;

export const Button = forwardRef(
  <T extends ElementType<any> = "button">(
    props: ButtonProps<T>,
    ref: ForwardedRef<any>, // TODO: fix ref type
  ) => {
    const Comp = props.as || "button";
    const disabled = !!props.isLoading || !!props.forbidden || !!props.disabled;

    function handleClick(e: React.MouseEvent) {
      props.onClick?.(e);
      props.gaEventConfig && triggerGaEvent(props.gaEventConfig);
    }

    // Makes a recursion on the Button component if there is a tooltip
    // in order to wrap it with the Tooltip component
    const copy = { ...props };
    if ("tooltip" in copy && copy.tooltip) {
      const tooltipProps =
        typeof copy.tooltip === "object"
          ? copy.tooltip
          : { content: copy.tooltip };

      return (
        <Tooltip {...tooltipProps}>
          <Button
            aria-label={
              typeof copy.tooltip === "object"
                ? copy.tooltip.content
                : copy.tooltip
            }
            {...(omit(copy, ["tooltip"]) as IconButtonProps<T>)}
          />
        </Tooltip>
      );
    }

    return (
      <Comp
        ref={ref}
        // remove invalid DOM attributes
        {...omit(props, [
          "as",
          "forbidden",
          "isLoading",
          "variant",
          "gaEventConfig",
        ])}
        className={cn(
          styles.button,
          styles[props.variant || "contained"],
          isIconBtn(props) && styles.iconbutton,
          props.forbidden && styles.forbidden,
          disabled && styles.disabled,
          props.isLoading && styles.loading,
          props.className,
        )}
        style={
          isIconBtn(props)
            ? {
                height: toPx(props.size),
                width: toPx(props.size),
                ...props.style,
              }
            : props.style
        }
        aria-disabled={disabled}
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        onClick={handleClick}
      >
        <RenderIcon icon={props.icon} isLoading={props.isLoading} />

        {!isIconBtn(props) && typeof props.label === "string" ? (
          // Fix for Google Translate, see https://github.com/facebook/react/issues/11538#issuecomment-390386520
          <span>{props.label}</span>
        ) : (
          props.label
        )}
      </Comp>
    );
  },
);

function isIconBtn(props: ButtonProps<any>): props is IconButtonProps<any> {
  return !(props as LabelledButtonProps<any>).label;
}
