import styles from "./index.module.css";
import { Combobox } from "@headlessui/react";
import { toSearchString } from "src/app/utils/toSearchString";
import { DropdownRoot } from "../_atoms/dropdown/root";
import { DropdownContainer } from "../_atoms/dropdown/container";
import { Scrollable } from "../../scrollable";
import { DropdownOptionGroup } from "../_atoms/dropdown/option-group";
import { Fragment, ReactNode, useRef } from "react";
import { IconSearch } from "../_atoms/icon/search";
import { IconClear } from "../_atoms/icon/clear";
import { DropdownOption } from "../_atoms/dropdown/option";
import { escapeRegExp } from "lodash";
import { createFilterFn, stringifySuggestion } from "./utils";

type Props<TData extends object> = {
  value: string | undefined;
  onChange: (value: string) => void;
  data: TData[];
  getSuggestionText: (dataItem: TData) => string | string[];
  placeholder?: string;
  maxSuggestions?: number;
  withPortal?: boolean | string;
};

export const InputAutocomplete = <TData extends object>({
  value = "",
  onChange,
  data,
  placeholder = "Search",
  getSuggestionText,
  maxSuggestions = 5,
  withPortal = false,
}: Props<TData>) => {
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const visibleSuggestionDataItems = data
    .filter(createFilterFn({ value, getSuggestionText, passIfNoValue: false }))
    .slice(0, maxSuggestions);

  const handleSelect = (dataItem: TData | string) => {
    if (typeof dataItem === "string") return; // this happens onBlur
    onChange(stringifySuggestion(getSuggestionText(dataItem)));
  };

  return (
    <Combobox onChange={handleSelect}>
      <div className={styles.root} ref={anchorRef}>
        <IconSearch className={styles.searchIcon} />

        <Combobox.Input
          className={styles.input}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          value={value}
          autoComplete="off"
        />
        {!!value && (
          <IconClear
            className={styles.clearIcon}
            onClick={() => onChange("")}
          />
        )}
      </div>
      {(value !== "" || !!visibleSuggestionDataItems.length) && (
        <DropdownRoot
          anchorRef={anchorRef}
          withPortal={withPortal}
          placement="bottom"
        >
          <Combobox.Options as={DropdownContainer}>
            <Scrollable overscrollBehavior="contain">
              {!visibleSuggestionDataItems.length && (
                <div className={styles.noSuggestionText}>Nothing found.</div>
              )}

              {/* This prevents the Combobox.Input from being cleared if no value is matched */}
              <Combobox.Option as={Fragment} value={value}>
                <div className={styles.hiddenValue}>{value}</div>
              </Combobox.Option>

              {!!visibleSuggestionDataItems.length && (
                <DropdownOptionGroup>
                  {visibleSuggestionDataItems.map((dataItem, i) => (
                    <Combobox.Option
                      key={`suggestion-${stringifySuggestion(
                        getSuggestionText(dataItem),
                      ).replace(/\s+/g, "-")}-${i}`}
                      value={dataItem}
                      as={Fragment}
                    >
                      {({ active }) =>
                        renderSuggestion({
                          text: stringifySuggestion(
                            getSuggestionText(dataItem),
                          ),
                          active,
                          searchString: value,
                        })
                      }
                    </Combobox.Option>
                  ))}
                </DropdownOptionGroup>
              )}
            </Scrollable>
          </Combobox.Options>
        </DropdownRoot>
      )}
    </Combobox>
  );
};

type RenderSuggestionConfig = {
  text: string;
  searchString: string;
  active: boolean;
};

const renderSuggestion = ({
  text,
  searchString,
  active,
}: RenderSuggestionConfig) => {
  const highlightedElements = highlightSearchString(text, searchString);

  return (
    <DropdownOption
      key={`suggestion-${text}`}
      className={styles.suggestion}
      disabled={false}
      active={active}
    >
      <div>{highlightedElements}</div>
    </DropdownOption>
  );
};

function highlightSearchString(
  optionTextContent: string,
  searchString: string,
) {
  const regExp = new RegExp(
    `${escapeRegExp(toSearchString(searchString.trim()))}`,
    "g",
  );

  let match;
  let nodes: ReactNode[] = [optionTextContent];

  while ((match = regExp.exec(toSearchString(optionTextContent))) !== null) {
    const originalMatch = optionTextContent.slice(
      match.index,
      match.index + match[0].length,
    );
    nodes = nodes.flatMap((entry) => {
      if (typeof entry !== "string") return entry;
      return entry
        .split(new RegExp(`(${originalMatch})`, "g")) // TODO: find a solution without regexp as some strings might crash it
        .filter(Boolean)
        .map((str: string, i) => {
          return str === originalMatch ? (
            <span
              key={`key1-${optionTextContent}-${str}-${i}`}
              className={styles.highlighted}
            >
              {str}
            </span>
          ) : (
            <span key={`key2-${optionTextContent}-${str}-${i}`}>{str}</span>
          );
        });
    });
  }
  return nodes;
}
