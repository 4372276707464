import { omit } from "lodash";
import { forwardRef } from "react";
import { NavLink } from "react-router-dom";

type Props = React.ComponentProps<NavLink>;

export const A = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  if (
    typeof props.to === "string" &&
    (props.to.startsWith("http") || props.to.startsWith("mailto"))
  ) {
    return (
      <a
        ref={ref}
        target="_blank"
        rel="noopener noreferrer"
        {...omit(props, [
          "to",
          "activeClassName",
          "activeStyle",
          "sensitive",
          "exact",
          "strict",
          "isActive",
          "location",
        ])}
        href={props.to}
        className={
          typeof props.className === "function"
            ? props.className(false)
            : props.className
        }
        style={
          typeof props.style === "function" ? props.style(false) : props.style
        }
      >
        {props.children}
      </a>
    );
  }

  return <NavLink ref={ref} {...props} />;
});
