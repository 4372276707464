import { TableNoDataBox } from "../../LayoutSide/components/common/TableNoDataBox";
import { path } from "src/app/routes/path";
import { useMount } from "react-use";
import { useLoginAndRedeemGiftMutation } from "src/app/api/mutations_new_to_rename";
import { Button } from "src/app/components/button";
import { A } from "src/app/components/a";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";

export const RedeemGiftsPage = () => {
  const redeemGiftMutation = useLoginAndRedeemGiftMutation();

  const [{ code, person, id_gift }] = useTypedSearchParams({
    schema: z.object({
      code: z.string(),
      person: z
        .number()
        .or(z.string())
        .transform((n) => String(n)),
      id_gift: z
        .number()
        .or(z.string())
        .transform((n) => String(n)),
    }),
  });

  useMount(() => {
    if (code && person && id_gift) {
      redeemGiftMutation.mutate({ code, person, id_gift });
    }
  });

  if (!code || !person || !id_gift || redeemGiftMutation.isError) {
    return (
      <div className="Preview">
        <TableNoDataBox
          title="Error redeeming the gift"
          content={
            redeemGiftMutation.isError
              ? redeemGiftMutation.error
              : !code || !person || !id_gift
                ? "Code, person or id gift not found!"
                : "Unexpected error"
          }
        />
      </div>
    );
  }

  if (redeemGiftMutation.isSuccess) {
    return (
      <div className="Preview">
        <h3>Gift successfully redeemed!</h3>
        <p>Go to your cellar page to see your new asset</p>
        <Button
          as={A}
          variant="contained"
          to={path.myAssets.value()}
          label="Go to My Cellar"
        />
      </div>
    );
  }

  return (
    <div className="Preview">
      <img
        src="media/logos/crurated-loader-2.gif"
        className="Preview-logo"
        alt="logo"
      />

      <p>Checking your browser before accessing crurated.com</p>
      <p>
        This process is automatic. Your browser will redirect to your requested
        content shortly.
      </p>
    </div>
  );
};
