import styles from "./index.module.css";
import { UserProfileSidebar } from "../profile-sidebar";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { useEditPasswordMutation } from "src/app/api/auth";
import { parseApiErrorV1 } from "src/app/api/_parse-api-error";
import { CruForm } from "src/app/components/cru-form-3";
import { InputPassword } from "src/app/components/form-elements/input-password";
import { validate } from "src/app/utils/validate";
import { Button } from "src/app/components/button";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { A } from "src/app/components/a";

export function MyPassword() {
  const alertPopup = useAlertPopup();

  const editPasswordMutation = useEditPasswordMutation({
    onError: (error) =>
      alertPopup.error({
        title: "Error Updating Password",
        content: parseApiErrorV1(error),
      }),
  });

  return (
    <div className="user-profile-container">
      <UserProfileSidebar />
      <div className="upc-mainbody">
        {editPasswordMutation.isSuccess ? (
          <div className={styles.rootMessage}>
            <div className={styles.messageInner}>
              <IoMdCheckmarkCircleOutline color="green" />
              <h1>Password has been changed!</h1>
              <h4>You can now login with your new password</h4>
            </div>
          </div>
        ) : (
          <div>
            <CruForm.Form
              initialValues={{
                currentPassword: "",
                password: "",
                passwordConfirm: "",
              }}
              onSubmit={(values) =>
                editPasswordMutation.mutate({
                  current_password: values.currentPassword,
                  new_password: values.password,
                })
              }
              className={styles.form}
            >
              {(ctx) => (
                <>
                  <CruForm.Field
                    ctx={ctx}
                    name="currentPassword"
                    validate={(value) => {
                      if (!value) return "Write your current password";
                    }}
                  >
                    {(props) => (
                      <>
                        <CruForm.Label className={styles.formLabel} required>
                          Current Password
                        </CruForm.Label>
                        <div>
                          <InputPassword
                            {...props}
                            value={ctx.getFieldValue(props.name)}
                            onChange={(e) => {
                              ctx.setFieldsValues({
                                [props.name]: e.target.value,
                              });
                            }}
                            onBlur={() =>
                              ctx.setFieldsTouched({ [props.name]: true })
                            }
                            className={styles.input}
                          />

                          <CruForm.ErrorMessage />
                        </div>
                      </>
                    )}
                  </CruForm.Field>
                  <CruForm.Field
                    ctx={ctx}
                    name="password"
                    validate={validate.password}
                  >
                    {(props) => (
                      <>
                        <CruForm.Label className={styles.formLabel} required>
                          New Password
                        </CruForm.Label>
                        <div>
                          <InputPassword
                            {...props}
                            value={ctx.getFieldValue(props.name)}
                            onChange={(e) => {
                              ctx.setFieldsValues({
                                [props.name]: e.target.value,
                              });
                            }}
                            onBlur={() =>
                              ctx.setFieldsTouched({ [props.name]: true })
                            }
                            className={styles.input}
                          />

                          <CruForm.ErrorMessage />
                        </div>
                      </>
                    )}
                  </CruForm.Field>
                  <CruForm.Field
                    ctx={ctx}
                    name="passwordConfirm"
                    validate={(value, getFieldValue) => {
                      if (!value) return "Write your password again";
                      if (value !== getFieldValue("password"))
                        return "Passwords must match";
                    }}
                  >
                    {(props) => (
                      <>
                        <CruForm.Label className={styles.formLabel} required>
                          Verify Password
                        </CruForm.Label>
                        <div>
                          <InputPassword
                            {...props}
                            value={ctx.getFieldValue(props.name)}
                            onChange={(e) => {
                              ctx.setFieldsValues({
                                [props.name]: e.target.value,
                              });
                            }}
                            onBlur={() =>
                              ctx.setFieldsTouched({ [props.name]: true })
                            }
                            className={styles.input}
                          />

                          <CruForm.ErrorMessage />
                        </div>
                      </>
                    )}
                  </CruForm.Field>
                  <div />
                  <div className={styles.formButtons}>
                    <Button
                      variant="contained"
                      label="Save New Password"
                      isLoading={editPasswordMutation.isLoading}
                    />
                    <Button
                      variant="outlined"
                      label="Cancel"
                      as={A}
                      to="/user-profile/profile-overview"
                    />
                  </div>
                </>
              )}
            </CruForm.Form>
          </div>
        )}
      </div>
    </div>
  );
}
