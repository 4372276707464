import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Button } from "../button";
import styles from "./index.module.css";
import { CSSProperties, useState } from "react";
import { cn } from "src/app/utils/cn";
import { path } from "src/app/routes/path";

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const CookieBanner = ({ className = "", style }: Props) => {
  const [hasCookie, setHasCookie] = useState(getHasCookie());
  const isMobile = useIsMobile();

  const handleAcceptCookies = () => {
    addCookie();
    setHasCookie(true);
  };

  if (hasCookie) return null;

  return (
    <div
      style={style}
      className={cn(styles.root, isMobile && styles.mobile, className)}
    >
      <div className={styles.message}>
        <span>
          This website uses cookies to ensure you get the best experience on our
          website.
        </span>
        <a href={path.cookiePolicy()} target="_blank" rel="noreferrer">
          Cookie Policy
        </a>
      </div>

      <Button
        label="I Understand"
        variant="outlined"
        className={styles.button}
        onClick={handleAcceptCookies}
      />
    </div>
  );
};

function addCookie(daysToExpire: number = 365) {
  const now = new Date();
  now.setTime(now.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = now.toUTCString();
  document.cookie = "CookiesCrurated=true; expires=" + expires + "; path=/";
  return true;
}

function getHasCookie() {
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    const cookiePair = cookieArray[i].split("=");
    if (cookiePair[0].trim() === "CookiesCrurated") {
      return true;
    }
  }
  return false;
}
