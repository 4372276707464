import styles from "../styles.module.css";
import { Fragment } from "react";
import { minutes } from "src/app/utils/time";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { DesktopCellContentText } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-text";
import { SelectMultipleFromData } from "src/app/components/form-elements/select-multiple/from-data";
import { path } from "src/app/routes/path";
import { Img } from "src/app/components/img";
import { CellContentDates } from "./cell-content-dates";
import { CellContentIcons } from "./cell-content-icons";
import { Button } from "src/app/components/button";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useMyExperiencesQuery } from "src/app/api/queries_to_rename_later";
import { CellarExperience } from "src/app/models/CellarExperience";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { useSelectMultipleFromData } from "src/app/components/form-elements/select-multiple/useSelectMultipleFromData";
import { inferDataType } from "src/app/utils/types";
import { useInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useInputAutocomplete";
import { A } from "src/app/components/a";

const getRowId = (row: CellarExperience) => row.id;

export const PageExperiences = () => {
  const isMobile = useIsMobile();
  const user = useMyUser();

  const experiencesQuery = useMyExperiencesQuery(user.id_user, {
    staleTime: minutes(1),
  });

  const selectMultiple = useSelectMultipleFromData({
    inferDataType: inferDataType<CellarExperience>(),
    config: [
      {
        groupName: "Winery",
        getValue: (experience) => experience.experience.builder.name,
      },
      {
        groupName: "Type",
        getValue: (experience) =>
          experience.experience.attributes.map((attr) => attr.attribute),
      },
    ],
  });

  const inputAutocomplete = useInputAutocomplete({
    inferDataType: inferDataType<CellarExperience>(),
    getSuggestionText: (experience) => experience.experience.title,
  });

  const filteredExp = (experiencesQuery.data || [])
    .filter(selectMultiple.filterFn)
    .filter(inputAutocomplete.filterFn);

  return (
    <Fragment>
      <div className={`${styles.controlBar} ${isMobile ? styles.mobile : ""}`}>
        <div className={styles.filters}>
          <SelectMultipleFromData
            placeholder="Filters"
            data={filteredExp}
            values={selectMultiple.values}
            onChange={selectMultiple.setValues}
            config={selectMultiple.config}
          />
        </div>

        <InputAutocomplete
          data={filteredExp}
          getSuggestionText={inputAutocomplete.getSuggestionText}
          onChange={inputAutocomplete.setValue}
          value={inputAutocomplete.value}
        />
      </div>

      <TableCustomerSide
        urlPrefix=""
        data={filteredExp}
        isLoading={experiencesQuery.isLoading}
        isFetching={experiencesQuery.isFetching}
        minCellHeightDesktop="100px"
        noDataTitle="No experience found"
        fillMissingRows={false}
        getRowId={getRowId}
        noDataContent={
          <div>
            <p>View live auctions to make a new bid.</p>
            <Button as={A} label="Go to Auctions" to={path.weekly.value()} />
          </div>
        }
        columns={[
          {
            id: "picture",
            header: "",
            width: 100,
            cellDesktop: (experience) => (
              <Img
                src={experience.experience.images[0]?.image}
                style={{ borderRadius: "2px" }}
                width={80}
                alt=""
              />
            ),
            cellMobile: (experience) => (
              <Img
                src={experience.experience.images[0]?.image}
                style={{ borderRadius: "2px" }}
                height={80}
                alt=""
              />
            ),
          },
          {
            id: "Name",
            header: "Name",
            getSortingValue: (experience) => experience.experience.title,
            cellDesktop: (experience) => (
              <DesktopCellContentText
                content={experience.experience.title}
                serif
                maxLines={3}
              />
            ),
            cellMobile: (experience) => (
              <MobileCellContentText
                content={experience.experience.title}
                serif
              />
            ),
          },
          {
            id: "Winery",
            header: "Winery",
            getSortingValue: (experience) => experience.experience.builder.name,
            cellDesktop: (experience) => (
              <DesktopCellContentText
                content={experience.experience.builder.name}
                serif
                maxLines={3}
              />
            ),
            cellMobile: (experience) => (
              <MobileCellContentText
                content={experience.experience.builder.name}
                serif
              />
            ),
          },
          // TODO: always "0.00" => need to fix
          // {
          //   id: "EMP",
          //   header: "EMP",
          //   alignHeaderDesktop: "right",
          //   getSortingValue: (experience) =>
          //     +experience.experience.estimated_price,
          //   cellDesktop: (experience) => (
          //     <DesktopCellContentEmp
          //       emp={+experience.experience.estimated_price}
          //     />
          //   ),
          //   cellMobile: (experience) => (
          //     <MobileCellContentEmp
          //       emp={+experience.experience.estimated_price}
          //     />
          //   ),
          // },
          {
            header: "Date",
            id: "Date",
            getSortingValue: (experience) => experience.totalBid,
            alignHeaderDesktop: "center",
            cellDesktop: (experience) => (
              <CellContentDates
                start={experience.experience.start_experience_date}
                end={experience.experience.end_experience_date}
                deadline={experience.experience.expiration_date}
              />
            ),
          },
          {
            header: "Type",
            id: "Type",
            cellDesktop: (experience) => (
              <CellContentIcons experience={experience} minIconsPerLine={4} />
            ),
          },
          // {
          //   header: "Status",
          //   id: "Status",
          //   cellDesktop: () => (
          //     <div style={{ display: "flex", flexWrap: "nowrap", gap: "4px" }}>
          //       Status
          //     </div>
          //   ),
          // },
        ]}
      />
    </Fragment>
  );
};
