import { ComponentPropsWithoutRef, useState } from "react";
import { useMount } from "react-use";

interface Props extends Omit<ComponentPropsWithoutRef<"output">, "htmlFor"> {
  name: string;
  forInputNames: (string | number)[];
}

export const HtmlOutput = ({ forInputNames, ...props }: Props) => {
  const [, rerender] = useState({});
  useMount(() => rerender({}));

  const inputs = [
    ...document.querySelectorAll(
      forInputNames.map((name) => `form [name="${name}"]`).join(","),
    ),
  ];

  const htmlFor = inputs.map((input) => input.id).join(" ");

  return <output htmlFor={htmlFor} {...props} />;
};
