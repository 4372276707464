import styles from "./index.module.css";
import { Spacer } from "src/app/components/spacer";
import { Button } from "src/app/components/button";
import { FormContextType } from "src/app/components/cru-form-3/types";
import { GiftFormValues } from ".";
import { CruForm } from "src/app/components/cru-form-3";
import { ApiLotV1 } from "src/app/models/LotV1";

export function LotSelection({
  ctx,
  onClose,
}: {
  ctx: FormContextType<GiftFormValues>;
  onClose: () => void;
}) {
  return (
    <section>
      <div className={styles.sectionBody}>
        <h3>Your Selection</h3>
        <Spacer size={24} />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <div style={{ fontWeight: "600" }}>Name</div>
          <div style={{ fontWeight: "600" }}>Quantity</div>
        </div>
        {ctx.values.lots.map((lot, index) => (
          <CruForm.Field
            key={`lots.${lot.id}`}
            ctx={ctx}
            name={`lots.${index}`}
            validate={(value: ApiLotV1) => {
              const isQuantityValid =
                value.quantity_chosen >= 0 &&
                lot.quantity_chosen <= lot.serials.length;

              return !isQuantityValid && "Invalid quantity";
            }}
          >
            {(props) => (
              <div className={styles.lotsInputGroup}>
                <p style={{ fontSize: 13 }}>{lot.description}</p>

                <input
                  {...props}
                  type="number"
                  placeholder="0"
                  value={ctx.getFieldValue(props.name).quantity_chosen}
                  min={1}
                  max={lot.serials.length}
                  className={styles.inputLotQuantity}
                  onChange={(e) => {
                    if (+e.target.value < 1) return;
                    const lot = ctx.getFieldValue(props.name);
                    lot.quantity_chosen = +e.target.value;
                    ctx.setFieldsValues({
                      [props.name]: lot as any,
                    });
                  }}
                  onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                />

                <CruForm.ErrorMessage className={styles.quantityError} />

                <CruForm.Description
                  as="span"
                  style={{
                    fontSize: 12,
                    gridArea: "availaibility",
                    justifySelf: "center",
                  }}
                >
                  {lot.serials.length} Available
                </CruForm.Description>
              </div>
            )}
          </CruForm.Field>
        ))}
      </div>

      <div className={styles.sectionFooter}>
        <Button
          variant="outlined"
          label="Select more lots"
          style={{ minWidth: 150 }}
          type="button"
          onClick={onClose}
        />
        <Button variant="contained" label="Next" style={{ minWidth: 150 }} />
      </div>
    </section>
  );
}
