import styles from "./display-selected-address.module.css";
import { AddressV1 } from "src/app/models/Address";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";
import { useMyUser } from "src/app/hooks/useMyUser";
import {
  MdOutlineLocationOn,
  MdOutlinePhone,
  MdOutlineMail,
} from "react-icons/md";

type Props = {
  address: AddressV1;
};

export const DisplaySelectedAddress = ({ address }: Props) => {
  const config = useSystemConfig();
  const user = useMyUser();

  const isPickupAddress = address.id === config?.pickup_address.id;

  const companyName = isPickupAddress
    ? user.company_name
    : address.company_name;
  const fullName = isPickupAddress ? user.fullname : address.full_name;

  return (
    <dl className={styles.root}>
      <dt className={styles.legend}>
        <MdOutlineLocationOn size={16} />
      </dt>
      {!!companyName && (
        <dd className={styles.data}>
          <b>Company name:&nbsp;</b>
          {companyName}
        </dd>
      )}
      {!!fullName && (
        <dd className={styles.data}>
          <b>Full name:&nbsp;</b>
          {fullName}
        </dd>
      )}
      <dd className={styles.data}>{address.address.address_line_1}</dd>
      {!!address.address.address_line_2 && (
        <dd className={styles.data}>{address.address.address_line_2}</dd>
      )}
      <dd className={styles.data}>{address.address.city}</dd>
      <dd className={styles.data}>
        {address.address.region}, {address.address.country.name}
      </dd>
      <dd className={styles.data}>{address.address.postal_code}</dd>

      <dt className={styles.legend}>
        <MdOutlineMail size={16} />
      </dt>
      <dd className={styles.data}>{user.email}</dd>

      {!isPickupAddress && (
        <>
          <dt className={styles.legend}>
            <MdOutlinePhone size={16} />
          </dt>
          <dd className={styles.data}>{address.phone}</dd>
        </>
      )}
    </dl>
  );
};
