import { noop, range } from "lodash";
import styles from "./BidControls.module.css";
import React from "react";
import { Tooltip } from "../tooltip";
import { MERCH_TYPE } from "src/app/const";

export const BidControls = ({
  optionsQuantity = [],
  optionsBid = [],
  quantity,
  onChangeQuantity = noop,
  price,
  onChangePrice = noop,
  merchType,
  greyedOut,
}) => {
  return (
    <div className={`${styles.root} ${greyedOut ? styles.greyedOut : ""}`}>
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <SelectQuantity
          label="Quantity"
          quantity={quantity}
          onChange={onChangeQuantity}
          options={optionsQuantity}
          merchType={merchType}
          disabled={greyedOut}
        />

        <SelectPrice
          // label={`Your offer for ${optionsQuantity[0]?.name ?? "-"}`}
          label={`Price`}
          price={price}
          options={optionsBid}
          onChange={onChangePrice}
          disabled={greyedOut}
        />
      </div>
      <TotalPrice price={price} quantity={quantity} />
    </div>
  );
};

function SelectQuantity({
  label,
  options = [],
  quantity,
  onChange = noop,
  merchType,
  disabled = false,
}) {
  const isLocked = options.length <= 1;
  const _options =
    merchType === MERCH_TYPE.BARRELS
      ? range(options.min, options.max + 1, options.step).map((qty) => ({
          value: qty,
          name: `${qty * 0.75} L`,
        }))
      : range(options.min, options.max + 1, options.step).map((qty) => ({
          value: qty,
          name: `${qty}`,
        }));

  return (
    <label
      className={`${styles.select} ${styles.selectQuantity} ${
        isLocked ? styles.locked : ""
      }`}
    >
      {!!label && <div className={styles.label}>{label}</div>}
      <select
        onChange={(e) => onChange(+e.target.value)}
        value={quantity}
        disabled={disabled || isLocked}
      >
        {_options.map((item, index) => (
          <option
            key={`${merchType}-select-quantity-${index}`}
            value={item.value}
          >
            {item.name}
          </option>
        ))}
      </select>
    </label>
  );
}

function SelectPrice({
  label,
  options = [],
  price,
  onChange = noop,
  merchType,
  disabled = false,
}) {
  const isLocked = options.length <= 1;
  return (
    <label
      className={`${styles.select} ${styles.selectPrice} ${
        isLocked ? styles.locked : ""
      }`}
    >
      <div className={styles.label}>{label}</div>
      <select
        onChange={(e) => onChange(+e.target.value)}
        value={price}
        disabled={disabled || isLocked}
      >
        {options.map((price, index) => (
          <option key={`${merchType}-select-price-${index}`} value={price}>
            {price}€
          </option>
        ))}
      </select>
    </label>
  );
}

function TotalPrice({ price, quantity }) {
  return (
    <div className={styles.totalPriceContainer}>
      <div className={styles.total}>
        <span style={{ fontWeight: 800, fontSize: "22px" }}>
          &euro;{price * quantity}
        </span>
        <Tooltip
          position="bottom"
          content="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details."
        >
          <div className={styles.taxes}>Excl. of processing fees and taxes</div>
        </Tooltip>
      </div>
    </div>
  );
}
