import { useQuery } from "@tanstack/react-query";
import { upfetch } from "./upfetch";

const getBrazeApiKey = {
  queryKey: () => ["BRAZE_KEY"],
  queryFn: () => async () => {
    return upfetch({
      url: "a-k/braze",
    });
  },
};

export const useGetBrazeApiKey = (options = {}) => {
  return useQuery({
    queryKey: getBrazeApiKey.queryKey(),
    queryFn: getBrazeApiKey.queryFn(),
    ...options,
  });
};
