import styles from "./pin-button.module.css";
import { Button } from "src/app/components/button";
import { MdOutlinePushPin, MdPushPin } from "react-icons/md";

type Props = {
  isSidebarPinned: boolean;
  onPin: () => void;
  onUnpin: () => void;
};

export const PinButton = ({ isSidebarPinned, onPin, onUnpin }: Props) => {
  return (
    <div className={styles.root}>
      <Button
        variant="ghost"
        onClick={isSidebarPinned ? onUnpin : onPin}
        className={styles.button}
        icon={
          isSidebarPinned ? (
            <MdPushPin className={styles.icon} />
          ) : (
            <MdOutlinePushPin className={styles.icon} />
          )
        }
        tooltip={isSidebarPinned ? "Unpin" : "Pin"}
      />
    </div>
  );
};
