import { kebabCase } from "lodash";

/**
 * @description re-export to clarify the intent
 * Standardizes strings:
 * - removes accents
 * - remove special chars
 * - trims consecutive '-'
 */
export const slugify = (str?: string) => {
  return kebabCase(str);
};
