import { useState, useEffect } from "react";
import CruratedHeaderTitle from "../../../CustomerSide/components/CruratedHeaderTitle";
import CruratedSecondaryTitle from "../../../CustomerSide/components/CruratedSecondaryTitle";
import { ContCardSlidePopup } from "../../../CustomerSide/components/common/ContCardSlidePopup";
import { CruratedCardsImg } from "../../../CustomerSide/components/CruratedCardsImg";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import { useBottleTiersQuery } from "src/app/api/queries";
import { noop } from "lodash";
import { BarrelInput } from "src/app/components/card-for-auction/BarrelInput";
import { ID_ROLE } from "src/app/const";
import wineBottleLabelledSrc from "src/media/barrel/wine-bottle-labelled.png";
import barrelStep2BottleSrc from "src/media/barrel/barrel-step-2-bottle.png";
import barrelFullSrc from "src/media/barrel/barrel-full.png";
import bigArrowGreySrc from "src/media/barrel/big-arrow-grey.svg";
import { InputBottle } from "./InputBottle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { upfetchV1 } from "src/app/api/upfetch";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { parseApiError } from "src/app/api/_parse-api-error";
import { Skeleton } from "src/app/components/skeleton";

export function BarrelConvertPopupDynamic({
  participant,
  dataParticipant,
  idUserProfile,
  closeHandler,
  colorWine,
  user,
}) {
  let bottleArr = [];

  var initLiter = participant.liters_available;
  var newBarrelTotal = initLiter;
  const [filledBottlesArr, setFilledBottlesArr] = useState(bottleArr);
  const [activeSlideClass, setSlideActive] = useState("");
  const [barrelConfirmation, setBarrelConfirmation] = useState(false);
  const [barrelBottleLabeling, setBarrelBottleLabeling] = useState(false);
  const alertPopup = useAlertPopup();

  const { data: bottleTiers, isLoading } = useBottleTiersQuery();

  const queryClient = useQueryClient();

  const removeActive = () => {
    setSlideActive("");
    document.body.style.overflow = "visible";
    setTimeout(function () {
      closeHandler();
    }, 400);
  };

  const closeBarrelConfirmation = () => {
    setBarrelConfirmation(false);
  };
  const openBarrelConfirmation = () => {
    setBarrelConfirmation(true);
  };

  const closeBarrelBottleLabeling = () => {
    setBarrelBottleLabeling(false);
  };

  const openBarrelBottleLabeling = () => {
    setBarrelBottleLabeling(true);
  };

  const convertedLiters = filledBottlesArr.reduce((acc, a) => acc + a, 0);
  const remainingLiters = participant.liters_available - convertedLiters;

  const handleRemoveBottle = (size, setFieldValue, values) => {
    bottleArr = Array.from(filledBottlesArr);
    var idx = bottleArr.findIndex((p) => +p === +size);
    if (idx !== -1) {
      bottleArr.splice(idx, 1);
    }
    bottleArr.sort();
    setFilledBottlesArr(bottleArr);

    var valueBottleArr = values.bottles;
    idx = valueBottleArr.findIndex((p) => +p.bottle_size === +size);
    if (idx !== -1) {
      valueBottleArr.splice(idx, 1);
    }
    valueBottleArr.sort();

    setFieldValue(
      "liters_converted",
      bottleArr.reduce((partialSum, a) => partialSum + a, 0),
    );
    setFieldValue("bottles", valueBottleArr);
  };

  const handleAddBottle = (liter, setFieldValue) => {
    if (newBarrelTotal >= 0) {
      bottleArr = Array.from(filledBottlesArr);
      bottleArr.push(liter);
      bottleArr.sort();
      setFilledBottlesArr(bottleArr);

      var valueBottleArr = bottleArr.map((item) => {
        if (dataParticipant.auction.enable_custom_label_bottle > 0) {
          let tier_selected = custom_label_for_bottles_tiers.map((x) =>
            x.filter((bottle) => parseFloat(bottle) === item),
          );

          return {
            bottle_size: item,
            have_custom_label: tier_selected.length > 0 ? 1 : 0,
            text_custom_label: "",
          };
        } else {
          return {
            bottle_size: item,
            have_custom_label: 0,
            text_custom_label: "",
          };
        }
      });

      setFieldValue(
        "liters_converted",
        bottleArr.reduce((partialSum, a) => partialSum + a, 0),
      );
      setFieldValue("bottles", valueBottleArr);
    }
  };

  const handleFillAllRemaining = (size, setFieldValue) => {
    const numberOfBottlesToAdd = Math.floor(remainingLiters / +size);
    const newBottlesList = [
      ...filledBottlesArr,
      ...Array(numberOfBottlesToAdd).fill(+size),
    ];
    setFilledBottlesArr(newBottlesList);
    setFieldValue(
      "liters_converted",
      newBottlesList.reduce((partialSum, a) => partialSum + a, 0),
    );

    var valueBottleArr = newBottlesList.map((size) => {
      if (dataParticipant.auction.enable_custom_label_bottle > 0) {
        let tier_selected = custom_label_for_bottles_tiers.map((x) =>
          x.filter((bottle) => parseFloat(bottle) === size),
        );

        return {
          bottle_size: size,
          have_custom_label: tier_selected.length > 0 ? 1 : 0,
          text_custom_label: "",
        };
      } else {
        return {
          bottle_size: size,
          have_custom_label: 0,
          text_custom_label: "",
        };
      }
    });
    setFieldValue("bottles", valueBottleArr);
  };

  const [allLabelCheck, setAllLabelCheck] = useState(false);

  const onAllLabelCheckSwitch = () => {
    setAllLabelCheck(!allLabelCheck);
  };

  const [sellerTitle] = useState(dataParticipant.auction.barrels.winery_name);
  const [sellerDescription] = useState(
    dataParticipant.auction.nameBarrelAuction,
  );

  const [moreInformation] = useState(
    <ContCardSlidePopup auction={dataParticipant.auction} type="BARRELS" />,
  );

  var bottles_tiers = dataParticipant.auction.barrels
    ? JSON.parse(dataParticipant.auction.barrels.bottles_tiers)
    : [];
  var custom_label_for_bottles_tiers = dataParticipant.auction
    ? JSON.parse(dataParticipant.auction.custom_label_for_bottles_tiers)
    : [];

  if (!isLoading) {
    if (custom_label_for_bottles_tiers.length > 0) {
      custom_label_for_bottles_tiers = custom_label_for_bottles_tiers.map(
        (item) => {
          let tier_selected = bottleTiers.filter(
            (bottle_tier) =>
              parseInt(bottle_tier.id_barrel_bottle_tier) ===
              parseInt(item.id_barrel_bottle_tier),
          );
          return tier_selected;
        },
      );
    } else {
      custom_label_for_bottles_tiers = [];
    }
  }

  var enable_custom_label_bottle = dataParticipant.auction
    ? dataParticipant.auction.enable_custom_label_bottle
    : [];

  useEffect(() => {
    setTimeout(function () {
      setSlideActive("active");
      document.body.style.overflow = "hidden";
    }, 100);
  }, []);

  const handleBarrelsConversion = useMutation({
    mutationFn: (values) => {
      return upfetchV1({
        url: "barrels/BarrelConversion/",
        method: "POST",
        body: values,
      });
    },
    onSuccess: () => {
      closeHandler();
      queryClient.invalidateQueries();
    },
    onError: (err) =>
      alertPopup.error({
        title: "Error on converting barrel",
        content: parseApiError(err),
      }),
  });

  return (
    <>
      <div className={`barrel-popup-overlay ${activeSlideClass}`} />
      <div className={`barrel-convert-popup ${activeSlideClass}`}>
        <div className="bcp-close" onClick={removeActive}>
          Close
        </div>
        <Formik
          initialValues={{
            bottles: [],
            id_user_profile: idUserProfile,
            same_name_label: 0,
            liters_converted: 0,
            auction_participant: dataParticipant,
            id_barrel_auction_participant:
              dataParticipant.id_barrel_auction_participant,
            type_action: "CREATE",
            barrel: dataParticipant.auction.barrels,
            accessToken: user.accessToken,
          }}
          validationSchema={Yup.object().shape({
            bottles: Yup.array()
              .min(1)
              .required("You must select at least one bottle")
              .of(
                Yup.object().shape({
                  text_custom_label: Yup.string().max(
                    30,
                    "Maximum 30 characters",
                  ),
                }),
              ),
          })}
        >
          {({ errors, values, touched, status, setFieldValue }) => {
            return (
              <Form>
                {isLoading ? (
                  <Skeleton.Rect className="m-0 p-0" height={300} />
                ) : (
                  <>
                    <div className="bcp-top">
                      <CruratedHeaderTitle text={sellerTitle} />
                      <CruratedSecondaryTitle
                        title={sellerTitle}
                        text={sellerDescription}
                        additionalClasses="card-sub-desc"
                        moreInformation={moreInformation}
                        knowmore={false}
                      />
                      <CruratedCardsImg
                        barrel={dataParticipant.auction.barrels}
                        type={"BARRELS"}
                      />
                    </div>
                    {/* Choose bottle */}
                    <div className="bcp-middle">
                      <BarrelInput
                        max={+participant.maxCapacity}
                        value={remainingLiters}
                        step={
                          +(
                            dataParticipant.auction?.barrels?.nft_liters ||
                            dataParticipant.nft_liters
                          )
                        }
                        wineColor={dataParticipant.color}
                      />

                      <div
                        className="bcp-placeholder-bottles"
                        style={{ padding: "0 16px" }}
                      >
                        {bottleTiers?.map((tier) => (
                          <InputBottle
                            key={`input-bottle-${tier.value}`}
                            size={tier.value}
                            color={colorWine}
                            isHiddenToCustomer={getIsHiddenToCustomer(
                              tier,
                              bottles_tiers,
                            )}
                            isCustomer={user.roles === ID_ROLE.CUSTOMER}
                            onClickPlus={() =>
                              handleAddBottle(
                                +tier.value,
                                setFieldValue,
                                values,
                              )
                            }
                            onClickMinus={() =>
                              handleRemoveBottle(
                                +tier.value,
                                setFieldValue,
                                values,
                              )
                            }
                            onClickFillRemaining={() =>
                              handleFillAllRemaining(
                                +tier.value,
                                setFieldValue,
                                values,
                              )
                            }
                            showPlus={remainingLiters >= +tier.value}
                            showMinus={filledBottlesArr.includes(+tier.value)}
                          />
                        ))}
                      </div>

                      <div className="bcp-converted-box">
                        <div className="bcp-converted-bottles">
                          {!!errors.bottles && !!touched.bottles && (
                            <span className="text-danger">
                              {errors.bottles}
                            </span>
                          )}
                          {filledBottlesArr
                            .filter((x, i, a) => a.indexOf(x) === i)
                            .sort((a, b) => a - b)
                            .map((item, index) => (
                              <div
                                key={`bad-key-again-${index}`}
                                className="bcp-converted-bottle-item"
                                data-unit={`${item}`}
                              >
                                <figure>
                                  <img
                                    src={wineBottleLabelledSrc}
                                    alt=""
                                    className="img-responsive"
                                  />
                                </figure>
                                <div className="bcp-bottle-units">{`${item} L (${
                                  filledBottlesArr.filter((x) => +x === +item)
                                    .length
                                })`}</div>
                              </div>
                            ))}
                        </div>
                        <div className="bcp-particulars">
                          <div className="bcp-particulars-counts">
                            <span className="bcp-items-total-label">Total</span>
                            <span className="bcp-items-count">
                              {`${convertedLiters}L`} ({filledBottlesArr.length}
                              ) Wine{" "}
                              {filledBottlesArr.length > 1
                                ? `bottles`
                                : `bottle`}
                            </span>
                          </div>
                          <button
                            className="btn-white"
                            onClick={
                              !errors.bottles && values.bottles.length > 0
                                ? enable_custom_label_bottle > 0
                                  ? openBarrelBottleLabeling
                                  : openBarrelConfirmation
                                : noop
                            }
                          >
                            Convert
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* END Choose bottle */}

                    {/* Choose Custom Label */}
                    {!!barrelBottleLabeling &&
                      enable_custom_label_bottle > 0 && (
                        <div className="bottles-labeling-convert-popup">
                          <div
                            className="bcp-back-slide"
                            onClick={closeBarrelBottleLabeling}
                          >
                            Back
                          </div>
                          <div className="bottles-labeling-convert-popup-inner">
                            <div className="blc-left">
                              <img
                                src={barrelStep2BottleSrc}
                                alt="bottle-label-placeholder"
                                className="blc-left-placeholder"
                              />
                            </div>
                            <div className="blc-right">
                              <div className="blc-right-top">
                                <h3 className="blc-right-title">
                                  Do you want to add your name on the label?
                                </h3>
                                <span className="blc-right-optional">
                                  optional
                                </span>
                              </div>
                              <div className="blc-right-bottle-count">
                                {filledBottlesArr.length} bottles
                              </div>
                              {parseInt(
                                dataParticipant.auction.barrels
                                  .labeling_time_left_days,
                              ) === 0 && (
                                <div className="blc-right-bottle-count text-danger">
                                  The days to insert the custom label are over{" "}
                                </div>
                              )}
                              <div className="blc-labels-form-wrap">
                                <FieldArray name="bottles">
                                  {() =>
                                    values.bottles.map((bottle, index) => {
                                      const bottles_errors =
                                        (errors.bottles?.length &&
                                          errors.bottles[index]) ||
                                        {};
                                      const bottles_touched =
                                        (touched.bottles?.length &&
                                          touched.bottles[index]) ||
                                        {};

                                      return (
                                        <div
                                          key={"sdfojhsdfkjn" + index}
                                          className="blc-labels-form-row"
                                        >
                                          <div className="blc-labels-col-left">
                                            <label>Bottle {index + 1}</label>
                                            <span className="blc-labels-meter">
                                              {
                                                values.bottles[index]
                                                  .bottle_size
                                              }{" "}
                                              L
                                            </span>
                                          </div>
                                          <div className="blc-labels-col-right">
                                            <div className="inputwrap">
                                              <input
                                                type="text"
                                                name={`bottles.${index}.text_custom_label`}
                                                className={`inputbox ${
                                                  bottles_errors.text_custom_label &&
                                                  bottles_touched.text_custom_label
                                                    ? " is-invalid"
                                                    : ""
                                                }`}
                                                maxLength={30}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `bottles.${index}.text_custom_label`,
                                                    e.target.value,
                                                  );
                                                }}
                                                disabled={
                                                  parseInt(
                                                    dataParticipant.auction
                                                      .barrels
                                                      .labeling_time_left_days,
                                                  ) === 0 ||
                                                  (index > 0 &&
                                                    allLabelCheck) ||
                                                  parseInt(
                                                    values.bottles[index]
                                                      .have_custom_label,
                                                  ) === 0
                                                    ? parseInt(user.roles) === 2
                                                    : null
                                                }
                                              />
                                              {parseInt(user.roles) !== 2 &&
                                                (parseInt(
                                                  dataParticipant.auction
                                                    .barrels
                                                    .labeling_time_left_days,
                                                ) === 0 ||
                                                  parseInt(
                                                    values.bottles[index]
                                                      .have_custom_label,
                                                  ) === 0) && (
                                                  <div className="pt-3 pb-3 text-danger">
                                                    This label is disabled from
                                                    the customer side
                                                  </div>
                                                )}
                                              <ErrorMessage
                                                name={`bottles.${index}.text_custom_label`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            {+index === 0 &&
                                              filledBottlesArr.length > 1 &&
                                              parseInt(user.roles) === 2 && (
                                                <label className="apply-label-check-wrap">
                                                  <input
                                                    type="checkbox"
                                                    name={`same_name_label`}
                                                    value={
                                                      values.same_name_label
                                                    }
                                                    onChange={() => {
                                                      onAllLabelCheckSwitch();
                                                      setFieldValue(
                                                        `same_name_label`,
                                                        values.same_name_label >
                                                          0
                                                          ? 0
                                                          : 1,
                                                      );
                                                    }}
                                                    disabled={
                                                      parseInt(user.roles) ===
                                                        2 &&
                                                      (parseInt(
                                                        dataParticipant.auction
                                                          .barrels
                                                          .labeling_time_left_days,
                                                      ) === 0 ||
                                                        parseInt(
                                                          values.bottles[index]
                                                            .have_custom_label,
                                                        ) === 0)
                                                    }
                                                  />
                                                  <span>
                                                    Apply the same name to all
                                                    the bottles
                                                  </span>
                                                </label>
                                              )}
                                          </div>
                                        </div>
                                      );
                                    })
                                  }
                                </FieldArray>
                              </div>
                              <div className="blc-right-btns-wrap">
                                <button
                                  name="blc-continue"
                                  type="button"
                                  className="btn btn-primary btn-big"
                                  onClick={openBarrelConfirmation}
                                >
                                  Continue
                                </button>
                                <button
                                  name="blc-continue"
                                  type="button"
                                  className="btn btn-transparent btn-big"
                                  onClick={openBarrelConfirmation}
                                >
                                  Skip
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {/* END Choose Custom Label */}

                    {/* Confirmation div */}
                    {!!barrelConfirmation && (
                      <div className="confirm-convert-popup">
                        <div
                          className="bcp-back-slide"
                          onClick={closeBarrelConfirmation}
                        >
                          Back
                        </div>
                        <div className="confirm-convert-popup-inner">
                          {!!status && (
                            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                              <div className="alert-text font-weight-bold">
                                {status}
                              </div>
                            </div>
                          )}
                          <div className="ccpi-top">
                            <div className="ccpi-top-left">
                              <img
                                src={barrelFullSrc}
                                alt="barrel"
                                className="img-responsive"
                              />
                              <div className="ccpi-top-left-unit">{`${convertedLiters}L`}</div>
                            </div>
                            <div className="ccpi-top-middle">
                              <img
                                src={bigArrowGreySrc}
                                alt="To"
                                className="img-responsive"
                              />
                            </div>
                            <div className="ccpi-top-end">
                              <div className="bcp-converted-bottles">
                                {filledBottlesArr
                                  .filter((x, i, a) => a.indexOf(x) === i)
                                  .sort((a, b) => a - b)
                                  .map((bottleSize, index) => (
                                    <div
                                      key={`this-is-bad-${bottleSize}-${index}`}
                                      className="bcp-converted-bottle-item"
                                      data-unit={`${bottleSize}`}
                                    >
                                      <figure>
                                        <img
                                          src={wineBottleLabelledSrc}
                                          alt="wine-bottle"
                                          className="img-responsive"
                                        />
                                      </figure>
                                      <div className="bcp-bottle-units">
                                        {`${bottleSize} L `}
                                        <br />
                                        {`(${
                                          filledBottlesArr.filter(
                                            (x) => +x === +bottleSize,
                                          ).length
                                        })`}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="ccpi-bottom">
                            <span className="ccpi-barrel-liter">
                              You are converting {`${convertedLiters}L`} from
                              barrels to {filledBottlesArr.length} Bottles
                            </span>
                            <button
                              type="submit"
                              className="btn btn-primary btn-large btn-big"
                              disabled={
                                !!handleBarrelsConversion.isLoading ||
                                !!errors.bottles
                              }
                              onClick={() =>
                                handleBarrelsConversion.mutate(values)
                              }
                            >
                              Confirm
                              {!!handleBarrelsConversion.isLoading && (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">Loading...</span>
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* END Confirmation div */}
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

function getIsHiddenToCustomer(tier, barrelBottleTiers) {
  return !barrelBottleTiers.find(
    (bottle_tier) =>
      +bottle_tier.id_barrel_bottle_tier === +tier.id_barrel_bottle_tier,
  );
}
