import { useLocalStorage } from "src/app/hooks/useLocalStorage";

const SIDEBAR_STATUS_LOCALSTORAGE_KEY = "sidebar_status";
type SidebarStatus = "pinned" | "unpinned";

export const useSidebarStatus = () => {
  const [sidebarStatus, setSidebarStatus] = useLocalStorage<SidebarStatus>(
    SIDEBAR_STATUS_LOCALSTORAGE_KEY,
    "unpinned",
  );

  return {
    status: sidebarStatus,
    pin: () => setSidebarStatus("pinned"),
    unpin: () => setSidebarStatus("unpinned"),
    isPinned: sidebarStatus === "pinned",
  };
};
