export const Avatar = ({
  picSrc,
  onClick,
}: {
  picSrc: string;
  onClick: () => void;
}) => {
  return (
    <div
      style={{
        background: `center/100% no-repeat url(${picSrc})`,
      }}
      className="avatar-preview"
    >
      <div className="add-avatar-cta" onClick={onClick} />
    </div>
  );
};
