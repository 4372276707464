import CryptoAES from "crypto-js/aes";
import { newUserWallet } from "../Nft/createWallet";
import { sendCall } from "../../config/Utility";
import { env } from "src/env";

export const fetchDataCustomerRoles = (user, setData, setLoadData) => {
  sendCall("users/GetAllCustomerRolesActive/", "GET", {
    accessToken: user.accessToken,
  })
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(data.result);
      }
      setLoadData(false);
    })
    .catch(() => {});
};

/**
 * @deprecated see [useOutstandingPaymentsQueryV1](../../api/queries.js)
 **/
export const fetchDataOutstandingPayments = (
  user,
  setData,
  setLoadData,
  additionalQuery = "",
) => {
  sendCall(
    `myCellar/GetOutstandingPayments/?accessToken=${user.accessToken}&id_user=${user.id_user}${additionalQuery}`,
    "GET",
    {},
  )
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(data.result);
      } else {
        setData([]);
      }
      setLoadData(false);
    })
    .catch(() => {});
};

export const fetchDataMySubscriptions = (
  user,
  setData,
  setLoadData,
  additionalQuery = "",
) => {
  sendCall(
    `users/GetMySubscriptionHandling/?accessToken=${user.accessToken}${additionalQuery}`,
    "GET",
    {},
  )
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(data.result);
      }
      setLoadData(false);
    })
    .catch(() => {});
};

export const fetchDataAllSubscriptionsActiveUserNew = (
  user,
  setData,
  setLoadData,
) => {
  sendCall(`users/GetAllSubscriptionsActiveUserNew/`, "GET", {
    accessToken: user.accessToken,
  })
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(data.result);
      }
      setLoadData(false);
    })
    .catch(() => {});
};

export const activateAccountFromInvitation = (
  setData,
  setLoadData,
  code,
  person,
  setError,
  setMessageError,
  finalAction,
) => {
  if (code && person) {
    sendCall("users/ActivateAccount/", "POST", {
      // "user_id": user.id,
      code: code,
      person: person,
    })
      .then((data) => {
        setLoadData(false);
        if (data.code === 200) {
          var user = data.result;

          if (user.addressKey === "0" || user.privateKey === "0") {
            CreateWalletUserNft(user.id_user);
          }

          setData(user);
          finalAction(user);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setError(true);
        console.error("Error:", error);
        setLoadData(false);
      });
  } else {
    setLoadData(false);
    setError(true);
  }
};

export const activateAccount = (
  code,
  person,
  setGeneralMessage,
  setGeneralError,
) => {
  if (code && person) {
    // Create PaymentIntent as soon as the page loads
    sendCall("users/ActivateAccount/", "POST", {
      code: code,
      person: person,
    })
      .then((result) => {
        if (result.code === 200) {
          setGeneralMessage("Your Account is active, please login");
        } else {
          if (parseInt(result.code) === 500) {
            setGeneralError("Internal Error");
          }

          if (parseInt(result.code) === 404) {
            setGeneralError("User not found");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setGeneralError("Internal Error");
      });
  } else {
    setGeneralError("Internal Error");
  }
};

const CreateWalletUserNft = (idUser) => {
  const newUserWalletNft = newUserWallet();
  var addressKey = newUserWalletNft.address;
  var privateKey = CryptoAES.encrypt(
    JSON.stringify(newUserWalletNft.privateKey),
    env.REACT_APP_SECRET_KEY,
  ).toString();

  if (idUser > 0 && addressKey !== "" && privateKey !== "") {
    sendCall("nft/CreateWalletUserNft/", {
      id_user: idUser,
      addressKey: addressKey,
      privateKey: privateKey,
    })
      .then((result) => {
        if (result.code === 200) {
          return 1;
        }
      })
      .catch(() => {
        console.error();
      });
  }
};

export const redeemGifts = (
  user,
  setData,
  setLoadData,
  idUser,
  idGift,
  setError,
  setMessageError,
  finalAction,
) => {
  sendCall("gifts/RedeemGifts/", "POST", {
    user: user,
    accessToken: user.accessToken,
    id_gift: idGift,
    id_user: idUser,
  })
    .then((data) => {
      setLoadData(false);
      if (data.code === 200) {
        finalAction(user.email, user.accessToken);
      } else {
        setMessageError(data.message);
        setError(true);
      }
    })
    .catch((error) => {
      setError(true);
      console.error("Error:", error);
    });
};

export const countryCheck = (
  address,
  setDataGoogle,
  setFieldValue,
  setStatusGoogle,
  setErrorStatusGoogle,
  type = "",
  defaultAddress = null,
) => {
  let address_line_1 = "address_line_1";
  let country = "country";
  let region = "region";
  let city = "city";
  let postal_code = "postal_code";
  let billing = false;
  switch (type) {
    case "REGISTRATION-SHIPPING":
      address_line_1 = "shipping_address_line_1";
      country = "shipping_country";
      region = "shipping_region";
      city = "shipping_city";
      postal_code = "shipping_postal_code";
      billing = false;
      break;
    case "REGISTRATION-BILLING":
      address_line_1 = "billing_address_line_1";
      country = "billing_country";
      region = "billing_region";
      city = "billing_city";
      postal_code = "billing_postal_code";
      billing = true;
      break;
    default:
      break;
  }
  sendCall("users/CountryCheck/", "POST", {
    address: address[0].address_components,
    billing: billing,
  })
    .then((data) => {
      if (data.code === 200) {
        setDataGoogle(data.result);

        setFieldValue(
          address_line_1,
          `${
            data.result.address_line_1StreetNumber
              ? data.result.address_line_1StreetNumber + ", "
              : ""
          }${data.result.address_line_1 ? data.result.address_line_1 : ""}`,
        );
        setFieldValue(
          country,
          data.result.id_country ? data.result.id_country : 0,
        );
        setFieldValue(region, data.result.region ? data.result.region : "");
        setFieldValue(city, data.result.city ? data.result.city : "");
        setFieldValue(
          postal_code,
          data.result.postal_code ? data.result.postal_code : "",
        );

        setStatusGoogle("");
        setErrorStatusGoogle(false);
      }

      if (data.code === 2) {
        setFieldValue(address_line_1, defaultAddress?.address_line_1 || "");
        setFieldValue(country, defaultAddress?.country.id || 0);
        setFieldValue(region, defaultAddress?.region || "");
        setFieldValue(city, defaultAddress?.city || "");
        setFieldValue(postal_code, defaultAddress?.postal_code || "");

        setStatusGoogle(data.message);
        setErrorStatusGoogle(true);
      }
    })
    .catch(() => {
      setFieldValue(address_line_1, "");
      setFieldValue(country, "");
      setFieldValue(region, "");
      setFieldValue(city, "");
      setFieldValue(postal_code, "");

      setStatusGoogle("Internal Error");
      setErrorStatusGoogle(true);
    });
};

export const fetchActionAddressUser = (
  values,
  type,
  textResult,
  textForm,
  fileOnApi,
  setLoading,
  refreshOnSubmit,
  setRefresh,
  openAlertPopup,
  close,
) => {
  if (type === "REQUEST") {
    var title_alert_success = "Request Send";
    var title_alert_error = "Error on request";
  } else {
    title_alert_success = "Address " + textResult + " successfully";
    title_alert_error = "Error " + textForm + " address";
  }
  setLoading(true);
  sendCall("users/" + fileOnApi + "/", "POST", values)
    .then((result) => {
      setLoading(false);
      if (result.code === 200) {
        openAlertPopup({
          title: title_alert_success,
          subTitle: "",
          alertType: "success",
          onClose: () => {
            if (refreshOnSubmit === 1) {
              window.location.reload();
            } else {
              setRefresh(true);
              close();
            }
          },
        });
      } else {
        openAlertPopup({
          title: title_alert_error,
          subTitle: "Please try again",
          alertType: "error",
          onClose: () => {
            if (refreshOnSubmit === 1) {
              window.location.reload();
            } else {
              setRefresh(true);
              close();
            }
          },
        });
      }
    })
    .catch((error) => {
      setLoading(false);
      console.error("Error:", error);

      openAlertPopup({
        title: title_alert_error,
        subTitle: "Please try again",
        alertType: "error",
        onClose: () => {
          if (refreshOnSubmit === 1) {
            window.location.reload();
          } else {
            setRefresh(true);
            close();
          }
        },
      });
    });
};

export const fetchDataCountries = (setData, setLoadData) => {
  sendCall("v2/countries/list/", "GET", {})
    .then((data) => {
      if (parseInt(data.code) === 200) {
        const dataToOldModel = data.result.map((country) => ({
          id_country: country.id,
          phone_code: country.phoneCode ?? country.PhoneCode,
          country_code: country.countryISO,
          is_ea: country.EA,
          country_name: country.name,
          active: country.isEnableForShipping ? 1 : 0,
          deleted: country.deleted ? 1 : 0,
          isEnableForShipping: country.isEnableForShipping,
        }));
        setData(dataToOldModel);
      }
      setLoadData(false);
    })

    .catch(() => {});
};

export const fetchDataAuctionType = (user, setData, setLoadData) => {
  sendCall("auctions/GetTypeAuctions/", "GET", {
    accessToken: user.accessToken,
  })
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(data.result);
      }
      setLoadData(false);
    })
    .catch(() => {});
};

export const fetchDataAllCustomersActive = (user, setData, setLoadData) => {
  sendCall("users/GetAllCustomersActive/", "GET", {
    accessToken: user.accessToken,
  })
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(data.result);
      }
      setLoadData(false);
    })
    .catch(() => {});
};

export const fetchDataLotForSelect = (user, setData, setLoadData) => {
  sendCall("lots/GetAllActiveLotsForSelect/", "GET", {
    accessToken: user.accessToken,
  })
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(
          data.result.map(({ value, name, sku }) => ({
            value,
            name: `${sku} - ${name}`,
          })),
        );
      }
      setLoadData(false);
    })
    .catch(() => {});
};

export const fetchDataListAddressUser = (
  user,
  idUser,
  setData,
  setLoadData,
) => {
  sendCall("users/getAddressesOfUser/", "GET", {
    accessToken: user.accessToken,
    id_user: idUser,
    type: "shipping",
    only_confirmed: false,
  })
    .then((data) => {
      if (parseInt(data.code) === 200) {
        setData(data.result);
      }
      setLoadData(false);
    })
    .catch(() => {});
};
