import { Control } from "./control";
import { Description } from "./description";
import { ErrorMessage } from "./error-message";
import { Field } from "./field";
import { Form } from "./form";
import { Label } from "./label";

export const CruForm = {
  Form: Form,
  Field: Field,
  Control: Control,
  Description: Description,
  Label: Label,
  ErrorMessage: ErrorMessage,
};
