import styles from "./option.module.css";
import { cn } from "src/app/utils/cn";
import { ReactNode, forwardRef } from "react";

type Props = React.ComponentPropsWithoutRef<"li"> & {
  children?: ReactNode;
  disabled: boolean;
  active: boolean;
};

export const DropdownOption = forwardRef<HTMLLIElement, Props>(
  ({ children, disabled, active, className = "", ...props }, ref) => {
    return (
      <li
        ref={ref}
        className={cn(
          styles.root,
          disabled && styles.disabled,
          active && styles.active,
          className,
        )}
        {...props}
      >
        {children}
      </li>
    );
  },
);
