import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { STORAGE_KEY } from "src/app/const";
import { useClearAllStores } from "src/app/hooks/useClearAllStores";
import { path } from "src/app/routes/path";

export const PageImpersonate = () => {
  const history = useHistory();
  const search = useLocation().search;
  const accessToken = new URLSearchParams(search).get("accessToken");
  const clearAllStores = useClearAllStores();

  useEffect(() => {
    clearAllStores().then(() => {
      accessToken &&
        localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
      localStorage.setItem(STORAGE_KEY.ADMIN_IMPERSONATE, "true");
      const redirectTo = accessToken ? path.dashboard() : path.logout();
      window.location.href = new URL(
        redirectTo,
        window.location.origin,
      ).toString();
    });
  }, [clearAllStores, accessToken, history]);

  return null;
};
