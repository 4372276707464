import {
  MutableRefObject,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useInterval } from "./useInterval";
import { useLatest } from "react-use";
import { addTimezoneOffset } from "../utils/addTimezoneOffset";
import { noop } from "lodash";

const NowContext = createContext<MutableRefObject<Set<(now: number) => void>>>({
  current: new Set<(now: number) => void>(),
});

export function NowProvider({ children }: { children: ReactNode }) {
  const callbacksRef = useRef(new Set<(now: number) => void>());

  useInterval(() => {
    callbacksRef.current.forEach((cb) => cb(Date.now()));
  }, 1000);

  return (
    <NowContext.Provider value={callbacksRef}>{children}</NowContext.Provider>
  );
}

type Options = {
  to: string | Date | null | undefined | false;
  onTick: (remainingTime: number) => void;
  onDone?: () => void;
  disabled?: boolean;
  unit?: "seconds" | "milliseconds";
  isApiDate?: boolean;
};

export const useCountdown = ({
  to,
  onTick,
  onDone = noop,
  disabled = false,
  unit = "milliseconds",
  isApiDate = true,
}: Options) => {
  const onTickRef = useLatest(onTick);
  const onDoneRef = useLatest(onDone);
  const isDoneRef = useRef(getRemainingMs(to, Date.now(), isApiDate) <= 0);
  const callbacksRef = useContext(NowContext);

  useEffect(() => {
    if (disabled || !to) return;
    const callback = (now: number) => {
      const remainingMs =
        new Date(isApiDate ? addTimezoneOffset(to) : to).getTime() - now;
      const remainingSeconds = Math.round(remainingMs / 1000);
      if (remainingSeconds > 1) {
        isDoneRef.current = false;
      }
      if (remainingSeconds >= 0) {
        onTickRef.current(unit === "seconds" ? remainingSeconds : remainingMs);
      }
      if (remainingSeconds <= 0 && !isDoneRef.current) {
        onDoneRef.current();
        isDoneRef.current = true;
      }
    };
    const callbacks = callbacksRef.current;
    callbacks.add(callback);
    return () => {
      callbacks.delete(callback);
    };
  }, [onTickRef, isApiDate, onDoneRef, callbacksRef, disabled, to, unit]);
};

function getRemainingMs(to: Options["to"], now: number, isApiDate: boolean) {
  if (!to) return 0;
  return new Date(isApiDate ? addTimezoneOffset(to) : to).getTime() - now;
}
