import { cn } from "src/app/utils/cn";
import styles from "./container.module.css";
import { ReactNode, forwardRef } from "react";

type Props = React.ComponentPropsWithoutRef<"ul"> & {
  children?: ReactNode;
};

export const DropdownContainer = forwardRef<HTMLUListElement, Props>(
  ({ children, className = "", ...props }, ref) => {
    return (
      <ul ref={ref} className={cn(styles.root, className)} {...props}>
        {children}
      </ul>
    );
  },
);
