import { useState, useEffect } from "react";
import { ActionAuction } from "../../../LayoutSide/components/common/customComponents/ActionAuction";
import { sendCall } from "../../../../../config/Utility";
import {
  fetchDataCustomerRoles,
  fetchDataAuctionType,
  fetchDataAllCustomersActive,
  fetchDataLotForSelect,
} from "../../../../fetch/fetchData";
import { useMyUser } from "src/app/hooks/useMyUser";

export function ResellActionSlide({ closeSlide, offersSelected, refresh }) {
  const user = useMyUser();

  useEffect(() => {
    fetchDataCustomerRoles(
      user,
      setDataCustomerRoles,
      setLoadDataCustomerRoles,
    );
    fetchDataAuctionType(user, setDataAuctionType, setLoadDataAuctionType);
    fetchDataAllCustomersActive(
      user,
      setDataAllCustomersActive,
      setLoadDataAllCustomersActive,
    );
    fetchDataLotForSelect(user, setOptionsLot, setLoadDataLot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        loadFetch &&
        !loadDataCustomerRoles &&
        !loadDataAuctionType &&
        !loadDataAllCustomersActive &&
        !loadDataLot &&
        !loadDataAuction
      ) {
        setLoadFetch(false);
      }
    }, 1000);
    return () => clearInterval(intervalId); //This is important
  });

  useEffect(() => {
    if (activeCount === 0) {
      setTimeout(function () {
        setSlideActive("active");
      }, 100);
      setActiveCount(activeCount + 1);
      checkOffers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [optionsLot, setOptionsLot] = useState([]);
  const [dataCustomerRoles, setDataCustomerRoles] = useState([]);
  const [dataAllCustomersActive, setDataAllCustomersActive] = useState([]);
  const [dataAuctionType, setDataAuctionType] = useState([]);
  const [dataAuction, setDataAuction] = useState([]);

  const [loadDataCustomerRoles, setLoadDataCustomerRoles] = useState(true);
  const [loadDataAuctionType, setLoadDataAuctionType] = useState(true);
  const [loadDataAllCustomersActive, setLoadDataAllCustomersActive] =
    useState(true);
  const [loadDataLot, setLoadDataLot] = useState(true);
  const [loadDataAuction, setLoadDataAuction] = useState(true);
  const [loadFetch, setLoadFetch] = useState(true);

  const [, setSlideActive] = useState("");
  const [activeCount, setActiveCount] = useState(0);

  const [selectedAuctionsParticipant, setSelectedAuctionsParticipant] =
    useState([]);

  const [isOk, setIsOk] = useState(true);

  const removeActive = () => {
    setSlideActive("");
    closeSlide();
  };

  const checkOffers = () => {
    var isOk = true;

    var offersSelectedFinal = [];

    if (offersSelected.length === 1) {
      offersSelected.map((item) => {
        item.quantity_chosen = item.serials.length;

        if (
          item.status !== "in_our_warehouse" &&
          item.status !== "with_producer"
        ) {
          isOk = false;
          setIsOk(false);
        }

        if (item.is_gift) {
          isOk = false;
          setIsOk(false);
        }

        offersSelectedFinal.push(item);
      });
    } else {
      isOk = false;
      setIsOk(false);
    }

    if (isOk) {
      setSelectedAuctionsParticipant(offersSelectedFinal);

      if (offersSelectedFinal[0].id_auction) {
        sendCall(`auctions/GetSingleAuction/`, "GET", {
          accessToken: user.accessToken,
          id_auction: offersSelectedFinal[0].id_auction,
          type: "RESELL",
        })
          .then((data) => {
            if (parseInt(data.code) === 200) {
              setDataAuction(data.result.auction);
            }
            setLoadDataAuction(false);
          })
          .catch(() => {});
      } else {
        setSelectedAuctionsParticipant(offersSelectedFinal);
        setLoadDataAuction(false);

        var id_lot = offersSelectedFinal[0].id_lot;
        var lot_chosen = [];
        sendCall("lots/GetLotSingle/", "GET", {
          accessToken: user.accessToken,
          id_lot: id_lot,
        })
          .then((data) => {
            if (data.code === 200) {
              lot_chosen = data.result[0];
              setLoadDataAuction(false);
            }
            setDataAuction({
              id_lot: offersSelectedFinal[0].id_lot,
              id_conversion: offersSelectedFinal[0].id_conversion,
              lots: lot_chosen,
            });
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  return activeCount > 0 ? (
    <>
      <div className="cellar-slide-form-wrap">
        {!loadFetch ? (
          <>
            {isOk ? (
              <ActionAuction
                user={user}
                userProfile={user}
                auction={dataAuction}
                participant={selectedAuctionsParticipant[0]}
                optionsLot={optionsLot}
                dataCustomerRoles={dataCustomerRoles}
                dataAllCustomersActive={dataAllCustomersActive}
                dataAuctionType={dataAuctionType}
                setRefresh={refresh}
                typeAction={"RESELL"}
                handleClose={() => {
                  removeActive();
                }}
              />
            ) : (
              <div className="slide-icon-holder">
                <div className="slide-icon-box slide-icon-info"></div>
                <div className="slide-icon-note">
                  Selected offers cannot be resell
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {isOk ? (
              <p>Loading</p>
            ) : (
              <div className="slide-icon-holder">
                <div className="slide-icon-box slide-icon-info"></div>
                <div className="slide-icon-note">
                  Selected offers cannot be resell
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  ) : (
    <></>
  );
}
