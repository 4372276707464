import styles from "../styles.module.css";
import { useBottleListQuery } from "src/app/api/queries_to_rename_later";
import { A } from "src/app/components/a";
import { Button } from "src/app/components/button";
import { useInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useInputAutocomplete";
import { useSelectMultipleFromData } from "src/app/components/form-elements/select-multiple/useSelectMultipleFromData";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { Bottle } from "src/app/models/Bottle";
import { inferDataType } from "src/app/utils/types";
import { path } from "src/app/routes/path";
import { SelectMultipleFromData } from "src/app/components/form-elements/select-multiple/from-data";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { DesktopCellContentText } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-text";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { DesktopCellContentNumber } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-numbers";
import { MobileCellContentNumber } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-number";

export function PageBottles() {
  const bottleListQuery = useBottleListQuery();
  const isMobile = useIsMobile();

  const selectMultiple = useSelectMultipleFromData({
    inferDataType: inferDataType<Bottle>(),
    config: [
      {
        groupName: "Region",
        getValue: (bottle) => bottle.product.region,
      },
      {
        groupName: "Color",
        getValue: (bottle) => bottle.product.color,
      },
      {
        groupName: "Bottle Size",
        getValue: (bottle) => bottle.product.size,
      },
      {
        groupName: "Winery",
        getValue: (bottle) => bottle.product.builder.name,
      },
      {
        groupName: "Vintage",
        getValue: (bottle) =>
          +bottle.product.vintage === 0 ? "No Vintage" : bottle.product.vintage,
      },
    ],
  });

  const inputAutocomplete = useInputAutocomplete({
    inferDataType: inferDataType<Bottle>(),
    getSuggestionText: (bottle) => [bottle.product.nameProductComplete],
  });

  const filteredBottles = (bottleListQuery.data || [])
    .filter(selectMultiple.filterFn)
    .filter(inputAutocomplete.filterFn);

  return (
    <div>
      <div
        className={`${styles.controlBar} ${isMobile ? styles.mobile : ""}`}
        style={{ gridTemplateColumns: isMobile ? "1fr" : undefined }}
      >
        <div className={styles.filters}>
          <SelectMultipleFromData
            placeholder="Filters"
            data={filteredBottles}
            values={selectMultiple.values}
            onChange={selectMultiple.setValues}
            config={selectMultiple.config}
          />
        </div>

        <InputAutocomplete
          data={filteredBottles}
          value={inputAutocomplete.value}
          onChange={inputAutocomplete.setValue}
          getSuggestionText={inputAutocomplete.getSuggestionText}
        />
      </div>

      <TableCustomerSide
        urlPrefix=""
        data={filteredBottles}
        getRowId={getRowId}
        isFetching={bottleListQuery.isFetching}
        isLoading={bottleListQuery.isLoading}
        minCellHeightDesktop="134px"
        noDataTitle="Your Cellar is empty"
        noDataContent={
          <div>
            <p>View live auctions to make a new bid.</p>
            <Button as={A} label="Go to Auctions" to={path.weekly.value()} />
          </div>
        }
        columns={[
          {
            header: "Photo",
            id: "photo",
            cellDesktop: (bottle) => (
              <img
                src={bottle.product.media.cover}
                alt={`${bottle.product.nameProductWithoutVintage} photo`}
                height={100}
              />
            ),
            cellMobile: (bottle) => (
              <img
                src={bottle.product.media.cover}
                alt={`${bottle.product.nameProductWithoutVintage} photo`}
                height={100}
              />
            ),
          },
          {
            header: "Name",
            id: "name",
            getSortingValue: (bottle) =>
              bottle.product.nameProductWithoutVintage,
            cellDesktop: (bottle) => (
              <DesktopCellContentText
                content={bottle.product.nameProductWithoutVintage}
                serif
                minWidth={200}
                maxLines={2}
              />
            ),
            cellMobile: (bottle) => (
              <MobileCellContentText
                content={bottle.product.nameProductWithoutVintage}
                serif
              />
            ),
          },
          {
            header: "Vintage",
            id: "vintage",
            alignHeaderDesktop: "center",
            getSortingValue: (bottle) => bottle.product.vintage,
            cellDesktop: (bottle) => (
              <DesktopCellContentNumber number={bottle.product.vintage} />
            ),
            cellMobile: (bottle) => (
              <MobileCellContentNumber number={bottle.product.vintage} />
            ),
          },
          {
            header: "Winery",
            id: "winery",
            getSortingValue: (bottle) => bottle.product.builder.name,
            cellDesktop: (bottle) => (
              <DesktopCellContentText
                content={bottle.product.builder.name}
                serif
              />
            ),
            cellMobile: (bottle) => (
              <MobileCellContentText
                content={bottle.product.builder.name}
                serif
              />
            ),
          },
          {
            header: "Color",
            id: "color",
            getSortingValue: (bottle) => bottle.product.color,
            cellDesktop: (bottle) => (
              <DesktopCellContentText content={bottle.product.color} serif />
            ),
            cellMobile: (bottle) => (
              <MobileCellContentText content={bottle.product.color} serif />
            ),
          },
          {
            header: "Region",
            id: "region",
            getSortingValue: (bottle) => bottle.product.region,
            cellDesktop: (bottle) => (
              <DesktopCellContentText content={bottle.product.region} serif />
            ),
            cellMobile: (bottle) => (
              <MobileCellContentText content={bottle.product.region} serif />
            ),
          },
        ]}
      />
    </div>
  );
}

const getRowId = (bottle: Bottle) => `${bottle.id}-${bottle.id_serial_product}`;
