import { A } from "src/app/components/a";
import styles from "./step-add-new-card.module.css";
import { Button } from "src/app/components/button";
import { path } from "src/app/routes/path";

export const StepAddNewCard = ({ onBack }: { onBack: () => void }) => {
  return (
    <div className={styles.root}>
      <h2>You are going to be directed to your account</h2>
      <p>
        In order to add a new Debit or Credit card, you are going to be directed
        to the payment method page on your account, leaving the checkout.
      </p>
      <div className={styles.rootButton}>
        <Button
          as={A}
          to={path.profile.payments}
          className={styles.button}
          label="Continue to add new card"
        />
        <Button
          onClick={onBack}
          className={styles.button}
          label="Go back"
          variant="outlined"
        />
      </div>
    </div>
  );
};
