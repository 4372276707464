import styles from "../styles.module.css";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { Fragment } from "react";
import { minutes } from "src/app/utils/time";
import { ProvenanceSubRow } from "./provenance-subrow";
import { ResellActionSlide } from "../../../modules/Includes/CustomerSide/components/cellar/ResellActionSlide";
import { BulkActions } from "../bulk-actions";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { ID_LOT_STATUS, ID_ROLE, ID_TIER } from "src/app/const";
import { CellContentBottleCount } from "./cell-content-bottle-count";
import { SelectMultipleFromData } from "src/app/components/form-elements/select-multiple/from-data";
import { path } from "src/app/routes/path";
import { Button } from "src/app/components/button";
import { inferDataType } from "src/app/utils/types";
import { ApiLotV1 } from "src/app/models/LotV1";
import { useMyLotsQuery } from "src/app/api/queries_to_rename_later";
import { DesktopCellContentText } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-text";
import { DesktopCellContentNumber } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-numbers";
import { MobileCellContentNumber } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-number";
import { DesktopCellContentTotalPrice } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-total-price";
import { MobileCellContentTotalPrice } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-total-price";
import { DesktopCellContentEmp } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-emp";
import { MobileCellContentEmp } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-emp";
import { CellContentStatus } from "./cell-content-status";
import { DesktopCellContentActions } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-actions";
import { MobileCellContentActions } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-actions";
import { useMyUser } from "src/app/hooks/useMyUser";
import { Drawer } from "src/app/components/drawer";
import { Drawer as Drawer2 } from "src/app/components/drawer-2";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { useTableSelection } from "src/app/components/table-customer-side/useTableSelection";
import { useSelectMultipleFromData } from "src/app/components/form-elements/select-multiple/useSelectMultipleFromData";
import { useInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useInputAutocomplete";
import { A } from "src/app/components/a";
import { useDownload } from "src/app/hooks/useDownload";
import { useSlot } from "src/app/hooks/useSlot";
import { useRequestShipmentMutation } from "src/app/api/shipments";
import { ShipmentForm } from "src/app/components/shipment-form/shipment-form";
import SendGiftForm from "src/app/components/cellar-forms/send-gift-form";
import { useIsMutating } from "@tanstack/react-query";
import { CellarResellStatusSlide } from "src/app/modules/Includes/CustomerSide/components/cellar/cellar-resell-status-slide";

const getRowId = (lot: ApiLotV1) => `${lot.id}-${lot.status}`;

export const PageLots = () => {
  const isMobile = useIsMobile();
  const user = useMyUser();
  const slot = useSlot();

  const isMutating = useIsMutating();

  const download = useDownload();
  const lotsQuery = useMyLotsQuery(user.id_user, { staleTime: minutes(1) });

  const requestShipmentMutation = useRequestShipmentMutation();

  const selection = useTableSelection({ getRowId, data: lotsQuery.data });

  const selectMultiple = useSelectMultipleFromData({
    inferDataType: inferDataType<ApiLotV1>(),
    config: [
      {
        groupName: "Status",
        getValue: (lot) => lot.statusNew.label,
      },
      {
        groupName: "Region",
        getValue: (lot) => lot.components.map((comp) => comp.region),
      },
      {
        groupName: "Color",
        getValue: (lot) => lot.components.map((comp) => comp.color),
      },
      {
        groupName: "Bottle Size",
        getValue: (lot) => lot.components.map((comp) => comp.size),
      },
      {
        groupName: "Winery",
        getValue: (lot) => lot.components.map((comp) => comp.builder.name),
      },
      {
        groupName: "Vintage",
        getValue: (lot) =>
          lot.components.map((comp) =>
            +comp.vintage === 0 ? " No Vintage" : comp.vintage,
          ),
      },
    ],
  });

  const inputAutocomplete = useInputAutocomplete({
    inferDataType: inferDataType<ApiLotV1>(),
    getSuggestionText: (lot) => [
      lot.case_,
      lot.auctions?.description || lot.description,
    ],
  });

  const filteredLots = (lotsQuery.data || [])
    .filter(selectMultiple.filterFn)
    .filter(inputAutocomplete.filterFn)
    .filter((lot) => {
      if (
        !selectMultiple.values["Status"]
          ?.map((v) => v.toString().toLowerCase())
          .includes("gifted")
      ) {
        return lot.statusNew.id !== ID_LOT_STATUS.GIFTED;
      }
      return true;
    });

  // TODO: ping Giacomo to remove (should come from BE)
  function canGiftLot(lot: ApiLotV1) {
    if (user.id_customer_role !== ID_TIER.PROFESSIONAL && !lot.paid)
      return false;
    return (
      [ID_ROLE.CUSTOMER, ID_ROLE.SUPER_ADMIN].includes(+user.roles) &&
      lot.canGift
    );
  }

  // TODO: ping Giacomo to remove (should come from BE)
  function canShipLot(lot: ApiLotV1) {
    if (user.id_customer_role !== ID_TIER.PROFESSIONAL && !lot.paid)
      return false;
    return (
      [ID_ROLE.CUSTOMER, ID_ROLE.SUPER_ADMIN].includes(+user.roles) &&
      lot.canShip
    );
  }

  // TODO: ping Giacomo to remove (should come from BE)
  function canResellLot(lot: ApiLotV1) {
    if (user.id_customer_role !== ID_TIER.PROFESSIONAL && !lot.paid)
      return false;
    return (
      [ID_ROLE.CUSTOMER, ID_ROLE.SUPER_ADMIN].includes(+user.roles) &&
      +user.allow_resell_crurated === 1 &&
      [ID_LOT_STATUS.IN_OUR_WAREHOUSE, ID_LOT_STATUS.WITH_PRODUCER].includes(
        lot.statusNew.id,
      )
    );
  }

  function openGiftDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer
        title="Gift an asset"
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={({ close }) => (
          <SendGiftForm selectedLots={lots} onClose={() => close()} />
        )}
      />
    ));
  }

  function openShipDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer2
        title="Ship an asset"
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={({ startClosing }) => (
          <ShipmentForm
            selectedLots={lots}
            mutateAsync={requestShipmentMutation.mutateAsync}
            submitButtonText="Send Request"
            onCancel={startClosing}
            onError={startClosing}
            onSuccess={startClosing}
          />
        )}
      />
    ));
  }

  function openResellStatusDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer2
        title={`Resell for ${lots[0]?.case_}`}
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={() => (
          //TODO: Rewrite all render components of this page
          <CellarResellStatusSlide id_lot={lots[0].id_lot} />
        )}
      />
    ));
  }

  function openResellCruratedDrawer(lots: ApiLotV1[]) {
    slot.show((dismiss) => (
      <Drawer
        title="Resell Auction"
        position="right"
        width={570}
        onClosed={dismiss}
        disableClose={!!isMutating}
        render={({ close }) => (
          <ResellActionSlide
            offersSelected={lots}
            refresh={() => lotsQuery.refetch()}
            closeSlide={close}
          />
        )}
      />
    ));
  }

  return (
    <Fragment>
      {slot.output}

      <div className={`${styles.controlBar} ${isMobile ? styles.mobile : ""}`}>
        <div className={styles.filters}>
          <SelectMultipleFromData
            placeholder="Filters"
            data={filteredLots}
            values={selectMultiple.values}
            onChange={selectMultiple.setValues}
            config={selectMultiple.config}
          />
        </div>

        <InputAutocomplete
          data={filteredLots}
          value={inputAutocomplete.value}
          onChange={inputAutocomplete.setValue}
          getSuggestionText={inputAutocomplete.getSuggestionText}
        />

        <BulkActions
          style={{ width: "100%" }}
          selection={selection.rowIds}
          onClearSelection={selection.set}
          actions={[
            {
              label: "Gift",
              onClick: () => openGiftDrawer(selection.data),
              disabled:
                !selection.data.length || !selection.data.every(canGiftLot),
            },
            {
              label: "Ship",
              onClick: () => openShipDrawer(selection.data),
              disabled:
                !selection.data.length || !selection.data.every(canShipLot),
            },
            +user.allow_resell_crurated === 1 && {
              label: "Resell for Crurated",
              onClick: () => openResellCruratedDrawer(selection.data),
              disabled:
                !selection.data.length || !selection.data.every(canResellLot),
            },
            {
              label: "Download Bottles Report",
              onClick: () => download.bottlesCSV(lotsQuery.data),
              disabled: !lotsQuery.data?.length,
            },
          ]}
        />
      </div>

      <TableCustomerSide
        urlPrefix="l."
        enableSelectionForRow={(lot) => {
          return canShipLot(lot) || canGiftLot(lot) || canResellLot(lot);
        }}
        enableExpansionForRow={() => true}
        data={filteredLots}
        getRowId={getRowId}
        isFetching={lotsQuery.isFetching}
        isLoading={lotsQuery.isLoading}
        minCellHeightDesktop="78px"
        noDataTitle="Your Cellar is empty"
        noDataContent={
          <div>
            <p>View live auctions to make a new bid.</p>
            <Button as={A} label="Go to Auctions" to={path.weekly.value()} />
          </div>
        }
        renderSubRow={ProvenanceSubRow}
        selection={selection.rowIds}
        onChangeSelection={selection.set}
        columns={[
          {
            header: "Products",
            id: "description",
            getSortingValue: (lot) =>
              lot.auctions?.description || lot.description,
            cellDesktop: (lot) => (
              <DesktopCellContentText
                content={lot.auctions?.description || lot.description}
                serif
                minWidth="200px"
                maxLines={2}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentText
                content={lot.auctions?.description || lot.description}
                serif
              />
            ),
          },
          {
            header: "Qty.",
            id: "quantity",
            getSortingValue: (lot) => lot.serials.length,
            cellDesktop: (lot) => (
              <DesktopCellContentNumber number={lot.serials.length} />
            ),
            cellMobile: (lot) => (
              <MobileCellContentNumber number={lot.serials.length} />
            ),
          },
          {
            header: "Price per Lot",
            id: "price-per-lot",
            getSortingValue: (lot) => lot.finance.pmc + lot.finance.insurance,
            cellDesktop: (lot) => (
              <DesktopCellContentTotalPrice
                details={[
                  {
                    amount: lot.finance.pmc,
                    label: "price",
                  },
                  {
                    amount: lot.finance.insurance,
                    label: "processing fee",
                  },
                  { label: "Excl. of processing fees and taxes" },
                ]}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentTotalPrice
                details={[
                  {
                    amount: lot.finance.pmc,
                    label: "price",
                  },
                  {
                    amount: lot.finance.insurance,
                    label: "processing fee",
                  },
                  {
                    label: (
                      <span style={{ fontStyle: "italic" }}>
                        Excl. of processing fees and taxes
                      </span>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            header: "EMP",
            id: "emp",
            getSortingValue: (lot) => lot.finance.emp,
            alignHeaderDesktop: "center",
            cellDesktop: (lot) => (
              <DesktopCellContentEmp
                emp={lot.finance.emp}
                ratio={lot.finance.ratio}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentEmp
                emp={lot.finance.emp}
                ratio={lot.finance.ratio}
              />
            ),
          },
          {
            header: "Total",
            id: "total",
            getSortingValue: (lot) =>
              lot.finance.total + lot.finance.total_insurance,
            alignHeaderDesktop: "center",
            cellDesktop: (lot) => (
              <DesktopCellContentTotalPrice
                paid={lot.paid}
                details={[
                  {
                    amount: lot.finance.total,
                    label: "price",
                  },
                  {
                    amount: lot.finance.total_insurance,
                    label: "processing fee",
                  },
                ]}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentTotalPrice
                paid={lot.paid}
                details={[
                  { amount: lot.finance.total, label: "price" },
                  {
                    amount: lot.finance.total_insurance,
                    label: "processing fee",
                  },
                ]}
              />
            ),
          },
          {
            header: "Btl.",
            id: "bottles",
            getSortingValue: (lot) =>
              lot.components.reduce((sum, cur) => sum + cur.quantity, 0),
            cellDesktop: (lot) => <CellContentBottleCount lot={lot} />,
          },
          {
            header: "Status",
            id: "status",
            getSortingValue: (row) => row.status,
            cellDesktop: (lot) => (
              <div>
                <CellContentStatus
                  status={lot.statusNew}
                  available_date={lot.available_date}
                />

                {!!lot.gift && (
                  <div
                    className={`${styles.giftRibbon} ${
                      isMobile ? styles.mobile : ""
                    }`}
                  >
                    <div>Gift from {lot.gift.sender.personalData.fullName}</div>
                  </div>
                )}
              </div>
            ),
          },
          {
            header: "Actions",
            id: "actions",
            cellDesktop: (lot) => (
              <DesktopCellContentActions
                disabled={lotsQuery.isFetching}
                actions={[
                  canGiftLot(lot) && {
                    label: "Gift",
                    onClick: () => openGiftDrawer([lot]),
                  },
                  canShipLot(lot) && {
                    label: "Ship",
                    onClick: () => openShipDrawer([lot]),
                  },
                  canResellLot(lot) && {
                    label: "Resell for Crurated",
                    onClick: () => openResellCruratedDrawer([lot]),
                  },
                  lot.status === "resell" && {
                    label: "Show Resell Status",
                    onClick: () => openResellStatusDrawer([lot]),
                  },
                ]}
              />
            ),
            cellMobile: (lot) => (
              <MobileCellContentActions
                disabled={lotsQuery.isFetching}
                actions={[
                  canGiftLot(lot) && {
                    label: "Gift",
                    onClick: () => openGiftDrawer([lot]),
                  },
                  canShipLot(lot) && {
                    label: "Ship",
                    onClick: () => openShipDrawer([lot]),
                  },
                  canResellLot(lot) && {
                    label: "Resell for Crurated",
                    onClick: () => openResellCruratedDrawer([lot]),
                  },
                  lot.status === "resell" && {
                    label: "Show Resell Status",
                    onClick: () => openResellStatusDrawer([lot]),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </Fragment>
  );
};
