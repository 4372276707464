import { z } from "zod";

export const validate = {
  email: (email: string) => {
    const zodResult = z.string().email().safeParse(email);
    return !zodResult.success && "Invalid email";
  },
  password: (pwd: string) => {
    if (pwd.length < 8) {
      return "Your password must be at least 8 characters long";
    }
    if (pwd.length > 24) {
      return "Your password must not exceed 24 characters";
    }
    if (!/\d/.test(pwd)) {
      return "Your password must contain at least on number";
    }
    if (!/[A-Z]/.test(pwd)) {
      return "Your password must contain at least 1 uppercase character";
    }
    // if (!/[-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./ ]/.test(pwd)) {
    //   return "Your password must contain at least 1 special character";
    // }
  },
  text:
    (options: { required: boolean; allowDigits: boolean }) =>
    (text: string) => {
      if (options.required && !text) {
        return "This field is required";
      }
      // digits
      if (!options.allowDigits && /\d/.test(text)) {
        return "Digits are not allowed";
      }
    },
};
