import { z } from "zod";

export const apiPendingGiftsSchemaV1 = z.object({
  id_gift: z.string(),
  code: z.string(),
  user_sender: z.string(),
  date_to_send_gift: z.string(),
  description_auction: z.string(),
});

export type ApiPendingGiftsV1 = z.infer<typeof apiPendingGiftsSchemaV1>;
