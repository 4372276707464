import styles from "./index.module.css";
import { CSSProperties, Fragment, ReactNode, useRef } from "react";
import { cn } from "../../../utils/cn";
import { toPx } from "../../../utils/toPx";
import { BsCheck } from "react-icons/bs";
import { Listbox } from "@headlessui/react";
import { Scrollable } from "../../scrollable";
import { DropdownRoot } from "../_atoms/dropdown/root";
import { DropdownContainer } from "../_atoms/dropdown/container";
import { DropdownOption } from "../_atoms/dropdown/option";
import { DropdownOptionGroup } from "../_atoms/dropdown/option-group";
import { IconToggleDropdown } from "../_atoms/icon/toggle-dropdown";
import { SpinnerSm } from "../../spinner-sm";

type OptionBase = {
  value: string | number | boolean | null | undefined;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  [key: string]: any;
};

type Props<TOption extends OptionBase> = {
  maxDropdownHeight?: number;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  selectedOption?: TOption | null;
  onChange: (option: TOption) => void;
  options: TOption[];
  renderOption?: (props: {
    option: TOption;
    selected: boolean;
    active: boolean;
  }) => JSX.Element;
  renderTriggerContent?: (selectedOption?: TOption | null) => ReactNode;
  disabled?: boolean;
  withPortal?: boolean | string;
  placement?: "top" | "bottom";
};

export const Select = <TOption extends OptionBase>({
  selectedOption,
  onChange,
  options = [],
  renderOption = defaultRenderOption,
  renderTriggerContent = defaultRenderTriggerContent,
  className = "",
  placeholder,
  maxDropdownHeight = 200,
  disabled = false,
  style,
  withPortal = false,
  placement = "bottom",
}: Props<TOption>) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const isPlaceholderShown = !renderTriggerContent(selectedOption);

  return (
    <Listbox value={selectedOption} onChange={onChange} disabled={disabled}>
      <Listbox.Button
        ref={buttonRef}
        className={cn(styles.button, className)}
        style={style}
      >
        {isPlaceholderShown ? (
          <div className={styles.placeholder}>{placeholder}</div>
        ) : (
          renderTriggerContent(selectedOption)
        )}

        <IconToggleDropdown />
      </Listbox.Button>

      <DropdownRoot
        anchorRef={buttonRef}
        withPortal={withPortal}
        placement={placement}
      >
        <Listbox.Options as={DropdownContainer}>
          <Scrollable
            overscrollBehavior="contain"
            style={{
              maxHeight: toPx(maxDropdownHeight),
            }}
          >
            <DropdownOptionGroup>
              {options.filter(Boolean).map((option, i) => (
                <Listbox.Option
                  key={`select-${option.value}-${i}`}
                  value={option}
                  disabled={!!option.disabled}
                  as={Fragment}
                >
                  {({ selected, active }) =>
                    renderOption({
                      selected,
                      active,
                      option,
                    })
                  }
                </Listbox.Option>
              ))}
            </DropdownOptionGroup>
          </Scrollable>
        </Listbox.Options>
      </DropdownRoot>
    </Listbox>
  );
};

const defaultRenderOption = ({
  option,
  selected,
  active,
}: {
  option: OptionBase;
  selected: boolean;
  active: boolean;
}) => {
  return (
    <DropdownOption
      disabled={!!option.disabled || !!option.loading}
      active={active}
    >
      <div className={styles.option}>
        {option.label || option.value}
        {!!option.loading && <SpinnerSm />}
        {!!selected && <BsCheck style={{ fontSize: "20px", flexShrink: 0 }} />}
      </div>
    </DropdownOption>
  );
};

function defaultRenderTriggerContent<TOption extends OptionBase>(
  selectedOption?: TOption | null,
) {
  return selectedOption?.label ?? selectedOption?.value;
}
