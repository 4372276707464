import { isAuctionOfBarrel } from "src/app/models/auctions";
import { BarrelInput } from "../../../barrel-input";
import { useCardCtx } from "../../card-context";

export const Barrel = ({ height = 200 }: { height?: number }) => {
  const cardCtx = useCardCtx();

  if (!isAuctionOfBarrel(cardCtx.auction)) return null;

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        alignItems: "center",
        padding: "1.5rem 0 1rem",
      }}
    >
      <BarrelInput
        max={cardCtx.auction.options.quantities.max}
        min={cardCtx.auction.options.quantities.min}
        step={cardCtx.auction.options.quantities.step}
        onChange={cardCtx.setQuantity}
        quantity={cardCtx.quantity}
        mul={cardCtx.auction.options.quantities.mul}
        color={cardCtx.auction.barrel.color as "RED" | "WHITE"}
        barrelHeight={height}
        hideTotalLiters
        disabled={!cardCtx.auction.canBid}
      />
    </div>
  );
};
