import { isAuctionOfBarrel, isAuctionOfLot } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";

export function Line() {
  const cardCtx = useCardCtx();
  const totalItems = isAuctionOfLot(cardCtx.auction)
    ? cardCtx.auction.lot?.components.length || 0
    : 0;
  const showActiveIndexLine =
    !cardCtx.showLotProductList &&
    !isAuctionOfBarrel(cardCtx.auction) &&
    totalItems > 1;
  const linePosition = (cardCtx.slideIndex / totalItems) * 100;
  const lineWidth = (1 / totalItems) * 100;

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        height: "1px",
        backgroundColor: "#E1DBD8",
        flexShrink: "0",
      }}
    >
      {!!showActiveIndexLine && (
        <div
          style={{
            width: `${lineWidth}%`,
            height: "1px",
            backgroundColor: "#800C32",
            position: "absolute",
            top: "0",
            left: `${linePosition}%`,
            transition: "left 0.3s ease",
          }}
        />
      )}
    </div>
  );
}
