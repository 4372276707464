/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useQuery } from "@tanstack/react-query";
import { isAuctionOfBarrel } from "src/app/models/auctions";
import {
  AuctionOfBarrel,
  apiAuctionOfBarrelSchema,
} from "src/app/models/auctions/AuctionOfBarrel";
import {
  AuctionOfLot,
  apiAuctionOfLotSchema,
} from "src/app/models/auctions/AuctionOfLot";
import { upfetch } from "src/app/api/upfetch";
import { uniqBy } from "lodash";
import { z } from "zod";
import { QKEY } from "src/app/api/QKEY";

const getAuctions = {
  queryKey: (params) => [QKEY.AUCTION, params],
  queryFn: (params) => async () => {
    const apiAuctions = await upfetch({
      url: "auctions/",
      params,
      schema: z.array(
        z.union([apiAuctionOfBarrelSchema, apiAuctionOfLotSchema]),
      ),
    });
    return uniqBy(apiAuctions || [], (a) => a.id_auction + a.type_product).map(
      (apiAuction) =>
        isAuctionOfBarrel(apiAuction)
          ? new AuctionOfBarrel(apiAuction)
          : new AuctionOfLot(apiAuction),
    );
  },
};

/**
 * @param {{
 *    forPage: 'highest-bids-placed-today' | 'auction-lot-live-feed'
 * }} payload
 * @returns {import('@tanstack/react-query').UseQueryResult<Array<Auction>>}
 */
export const useAuctionsQueryForCharityPublicPages = (
  payload,
  options = {},
) => {
  const params = buildParams(payload);

  return useQuery({
    queryKey: getAuctions.queryKey(params),
    queryFn: getAuctions.queryFn(params),
    cacheTime: Infinity,
    refetchInterval: 5000,
    ...options,
  });
};

/**
 * @param {{
 *    forPage: 'charity'
 * }} payload
 */
function buildParams(payload) {
  let params = { expand: true, onGoing: true, charity: true };
  if (payload.forPage === "highest-bids-placed-today") {
    return {
      ...params,
      sorting: "currentPrice",
    };
  } else if (payload.forPage === "auction-lot-live-feed") {
    return {
      ...params,
      sorting: "lastBid",
    };
  }
  return params;
}
