import { CSSProperties } from "react";
import styles from "./bid-liner.module.css";

type Props = {
  bidPercentage: number | null | undefined;
  style?: CSSProperties;
};

export function BidLiner({ bidPercentage = 0, style = {} }: Props) {
  const zoneNumber = getZoneNumber(bidPercentage || 0);

  const color = getColor(zoneNumber);
  const text = getText(zoneNumber);
  const leftPercentage = getLeftPercentage(zoneNumber);
  return (
    <div className={styles.root} style={style} data-bid-liner="true">
      <div className={styles.container}>
        {/* Text */}
        <span className={styles.text} style={{ left: `${leftPercentage}%` }}>
          {text}
        </span>
        {/* Arrow */}
        <span
          className={styles.triangle}
          style={{ color, left: `${leftPercentage}%` }}
        />
        <div
          className={`${styles.green} ${styles.reducedOpacity} ${styles.roundedLeft}`}
        />
        <div className={`${styles.yellow} ${styles.reducedOpacity}`} />
        <div
          className={`${styles.red}  ${styles.reducedOpacity} ${styles.roundedRight}`}
        />
      </div>
    </div>
  );
}

function getZoneNumber(percentage: number) {
  if (+percentage <= 20) {
    return 1;
  }
  if (+percentage < 50) {
    return 2;
  }
  return 3;
}

function getColor(zoneNumber: number) {
  if (zoneNumber === 1) {
    return "#9bf09e";
  }
  if (zoneNumber === 2) {
    return "#ffba7b";
  }
  return "#fb6960";
}

function getText(zoneNumber: number) {
  if (zoneNumber === 1) {
    return "Early Bid";
  }
  if (zoneNumber === 2) {
    return "Getting Competitive";
  }
  return "Highly Competitive";
}

function getLeftPercentage(zoneNumber: number) {
  const zoneSize = 100 / 3;
  return zoneNumber * zoneSize - zoneSize / 2;
}
