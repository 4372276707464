import styles from "./auction-features.module.css";
import { Img } from "src/app/components/img";
import {
  Auction,
  isAuctionOfBarrel,
  isAuctionOfLot,
} from "src/app/models/auctions";
import iconCollection from "./assets/collection.svg";
import iconSingleLot from "./assets/single-lot.svg";
import iconBarrel from "./assets/barrel.svg";
import iconBottleSizes from "./assets/bottle-sizes.svg";
import iconCustomLabel from "./assets/custom-label.svg";
import iconSpirit from "./assets/spirit.svg";
import { uniq } from "lodash";

type Props = { auction: Auction };

export const AuctionFeatures = ({ auction }: Props) => {
  if (isAuctionOfLot(auction) && auction.lot) {
    const numberOfBottles = auction.lot.components.reduce(
      (sum, product) => sum + product.quantity,
      0,
    );
    const collectionLabel = `${auction.isOfSingleLot ? "Single lot" : "Collection"} (${numberOfBottles} bottle${
      numberOfBottles > 1 ? "s" : ""
    })`;

    const spiritType =
      auction.hasSpirit &&
      uniq(
        auction.lot.components.map((product) =>
          product.category.name.toLocaleLowerCase(),
        ),
      ).join(", ");

    const bottleSizes = uniq(
      auction.lot.components.map((product) => product.size),
    );

    const bottleSizesLabel = `Bottle size${bottleSizes.length > 1 ? "s" : ""}:`;

    return (
      <section className={styles.root}>
        <div className={styles.feature}>
          <Img
            src={auction.isOfSingleLot ? iconSingleLot : iconCollection}
            alt=""
          />
          <span>{collectionLabel}</span>
        </div>
        {!!auction.hasSpirit && (
          <div className={styles.feature}>
            <Img src={iconSpirit} alt="" />
            <span style={{ textTransform: "capitalize" }}>{spiritType}</span>
          </div>
        )}
        <div className={styles.feature}>
          <Img src={iconBottleSizes} alt="" />
          <span>
            <span>{bottleSizesLabel}</span>
            &nbsp;
            <span>{formatBottleSizes(bottleSizes)}</span>
          </span>
        </div>
      </section>
    );
  }

  if (isAuctionOfBarrel(auction)) {
    const bottleSizes = uniq(
      auction.barrel.availableBottleTiers.map((tier) => tier.value),
    );

    const bottleSizesLabel = `Bottle format option${
      bottleSizes.length > 1 ? "s" : ""
    }:`;

    return (
      <section className={styles.root}>
        <div className={styles.feature}>
          <Img src={iconBarrel} alt="" />
          <span>Currently in the Barrel</span>
        </div>
        <div className={styles.feature}>
          <Img src={iconBottleSizes} alt="" />
          <span>
            <span>{bottleSizesLabel}</span>
            &nbsp;
            <span>{formatBottleSizes(bottleSizes)}</span>
          </span>
        </div>
        {!!auction.enable_custom_label_bottle && (
          <div className={styles.feature}>
            <Img src={iconCustomLabel} alt="" />
            <span>Custom label available</span>
          </div>
        )}
      </section>
    );
  }

  return null;
};

function formatBottleSizes(sizes: number[]) {
  if (sizes.length === 1) {
    return `${sizes[0]}L`;
  }
  let result = sizes.slice(0, -1).join(", ");
  if (sizes.at(-1)) {
    result = result.concat(` & ${sizes.at(-1)}L`);
  }
  return result;
}
