import { range } from "lodash";
import styles from "./index.module.css";
import { Skeleton } from "../skeleton";
import { cn } from "src/app/utils/cn";
import { useState } from "react";
import useResizeObserver from "use-resize-observer";
import { Button } from "../button";
import { LuArrowDown } from "react-icons/lu";

type SingleStat = {
  name: string;
  value: string | number;
  description: string;
  detail?: string;
};

const skeletonCount = 6;
const minCellWith = 140;

export const PageStats = (props: { stats: undefined | Array<SingleStat> }) => {
  const totalNumberOfCells = props.stats?.length ?? skeletonCount;
  const { ref, width = 0 } = useResizeObserver();
  const maxNumberOfCellsInOneRow = Math.floor(width / minCellWith);
  const canFitAllCellsInOneRow = maxNumberOfCellsInOneRow >= totalNumberOfCells;
  const [showAllStats, setShowAllStats] = useState(false);

  const stats = props.stats?.slice(
    0,
    showAllStats ? undefined : maxNumberOfCellsInOneRow,
  );

  return (
    <section
      className={cn(styles.root)}
      ref={ref}
      // @ts-expect-error css variables are not typed
      style={{ "--min-cell-width": `${minCellWith}px` }}
    >
      <div className={styles.inner}>
        {stats
          ? stats.map((stat) => (
              <div key={`stat-${stat.name}`} className={styles.stat}>
                <div className={styles.title}>{stat.name}</div>
                <div className={styles.value}>{stat.value}</div>
                <div className={styles.description}>{stat.description}</div>
                <div className={styles.detail}>&nbsp;{stat.detail}&nbsp;</div>
              </div>
            ))
          : range(0, skeletonCount)
              .slice(0, maxNumberOfCellsInOneRow)
              .map((i) => (
                <div key={`stat-skel-${i}`} className={styles.stat}>
                  <Skeleton.Rect height={90} width="60%" />
                </div>
              ))}
      </div>

      {!canFitAllCellsInOneRow && (
        <Button
          className={cn(styles.showAll, showAllStats && styles.rotate)}
          tooltip="Show all stats"
          icon={<LuArrowDown size={24} />}
          size={36}
          onClick={() => setShowAllStats((x) => !x)}
        />
      )}
    </section>
  );
};
