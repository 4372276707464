import { z } from "zod";
import { addressSchemaV1 } from "./Address";

export const apiSystemConfigSchemaV1 = z.object({
  dataBankTransfer: z.object({
    Beneficiary: z.string(),
    IBAN: z.string(),
    BIC: z.string(),
    BeneficiaryAddress: z.string(),
    PaymentInstitution: z.string(),
    PaymentInstitutionAddress: z.string(),
  }),
  projectName: z.string(),
  projectVersion: z.string(),
  default_months_free: z.number(),
  pickup_address: addressSchemaV1,
  legal_address: addressSchemaV1,
  maintenance: z.union([z.literal(0), z.literal(1)]),
  dbIsProduction: z.boolean(),
  date: z.string(),
  asPrimeur: z.boolean(),
});

export type SystemConfigV1 = z.infer<typeof apiSystemConfigSchemaV1>;
