export function IconNext() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 12.5C21.5 17.7379 17.2379 22 12 22C6.76214 22 2.5 17.7379 2.5 12.5C2.5 7.26214 6.76214 3 12 3C17.2379 3 21.5 7.26214 21.5 12.5Z"
        stroke="currentColor"
      />
      <path
        d="M11.1567 9.3433L13.717 11.9036L8 11.9036L8 13.0964L13.717 13.0964L11.1567 15.6567L12 16.5L16 12.5L12 8.5L11.1567 9.3433Z"
        fill="currentColor"
      />
    </svg>
  );
}
