import { RiExternalLinkLine } from "react-icons/ri";
import styles from "./wallet.module.css";
import { A } from "src/app/components/a";
import { useMyUser } from "src/app/hooks/useMyUser";
import { path } from "src/app/routes/path";
import { Button } from "src/app/components/button";
import { useIsSmallDesktop } from "./useIsSmallDesktop";

export const Wallet = () => {
  const user = useMyUser();
  const hasWallet = user.addressKey !== "0";
  const isSmallDesktop = useIsSmallDesktop();

  if (!hasWallet || isSmallDesktop) return null;

  return (
    <Button
      as={A}
      to={path.walletNft(user.addressKey)}
      label={
        <span>
          Wallet <strong>NFT</strong>
        </span>
      }
      icon={<RiExternalLinkLine size={15} />}
      className={styles.root}
    />
  );
};
