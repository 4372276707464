export function getIsScrollable(el: HTMLElement | undefined | null | false) {
  if (!el) return { x: false, y: false };
  const isOverflowing = {
    x: el.clientWidth < el.scrollWidth,
    y: el.clientHeight < el.scrollHeight,
  };
  // early escape here because the next step is expensive
  if (!isOverflowing.x && !isOverflowing.y) return { x: false, y: false };
  const computedStyles = window.getComputedStyle(el);
  const isOverflowScrollable = {
    x:
      computedStyles.overflowX.includes("scroll") ||
      computedStyles.overflowX.includes("auto"),
    y:
      computedStyles.overflowY.includes("scroll") ||
      computedStyles.overflowY.includes("auto"),
  };
  return {
    x: isOverflowing.x && isOverflowScrollable.x,
    y: isOverflowing.y && isOverflowScrollable.y,
  };
}
