import styles from "./styles.module.css";
import { Fragment, ReactNode } from "react";

type Props = {
  title: ReactNode;
  text: ReactNode;
  actions?: ReactNode[];
};

export const ErrorBaseUI = ({ title, text, actions }: Props) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.h2}>{title}</h2>
      <div className={styles.text}>{text}</div>

      <div className={styles.actions}>
        {actions?.map((action, i) => (
          <Fragment key={`action-${i}`}>{action}</Fragment>
        ))}
      </div>
    </div>
  );
};
