import { nanoid } from "nanoid";
import styles from "./ProductList.module.css";
import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { getIsScrollable } from "src/app/utils/getIsScrollable";

export const ProductList = ({ products }) => {
  const ref = useRef();
  const [id] = useState(nanoid());

  const [isScrollable, setIsScrollable] = useState(false);

  useLayoutEffect(() => {
    setIsScrollable(getIsScrollable(ref.current).y);
  }, []);

  return (
    <div
      ref={ref}
      className={`${styles.root} ${isScrollable ? styles.paddingRight : ""}`}
    >
      {products.map((product) => (
        <Fragment key={`auction-product-${id}-${JSON.stringify(product)}`}>
          <div className={styles.name}>
            {product.onlyNameProductWithoutVintage ||
              product.nameProductWithoutVintage ||
              product.NameProductWithoutVintage}
          </div>
          <div>{+product.vintage || "NV"}</div>
          <div>
            <div className={styles.quantity}>
              x{product.quantity} of {product.size}L
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};
