export function IconPrev() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 12.5C2.5 7.26214 6.76214 3 12 3C17.2379 3 21.5 7.26214 21.5 12.5C21.5 17.7379 17.2379 22 12 22C6.76214 22 2.5 17.7379 2.5 12.5Z"
        stroke="currentColor"
      />
      <path
        d="M12.8433 15.6567L10.283 13.0964H16V11.9036H10.283L12.8433 9.3433L12 8.5L8 12.5L12 16.5L12.8433 15.6567Z"
        fill="currentColor"
      />
    </svg>
  );
}
