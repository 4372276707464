import { getIsScrollable } from "src/app/utils/getIsScrollable";

type ScrollDirection = "up" | "down" | "left" | "right";

export function getCanScroll(dir: ScrollDirection, target: HTMLElement) {
  // loop all parents, including the initial target and the modal
  // eslint-disable-next-line
  while (true) {
    const isScrollable = getIsScrollable(target);
    if (isScrollable.y) {
      const canScrollUp = !!target.scrollTop;
      if (dir === "up" && canScrollUp) return true;
      const canScrollDown =
        target.scrollTop + target.clientHeight < target.scrollHeight;
      if (dir === "down" && canScrollDown) return true;
    }
    if (isScrollable.x) {
      const canScrollLeft = !!target.scrollLeft;
      if (dir === "left" && canScrollLeft) return true;
      const canScrollRight =
        target.scrollLeft + target.clientWidth < target.scrollWidth;
      if (dir === "right" && canScrollRight) return true;
    }
    if (target.tagName === "DIALOG" || !target.parentElement) break;
    target = target.parentElement;
  }
  return false;
}

export function getScrollMainDirection(e: WheelEvent): ScrollDirection {
  const mainAxis = Math.abs(e.deltaX) > Math.abs(e.deltaY) ? "x" : "y";

  if (mainAxis === "x") {
    return e.deltaX < 0 ? "left" : "right";
  }
  return e.deltaY < 0 ? "up" : "down";
}
