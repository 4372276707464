import styles from "./step2.module.css";
import { SignupFormValues } from ".";
import { CruPhoneInput } from "../components/phone-input";
import { CruForm } from "src/app/components/cru-form";
import { z } from "zod";
import { isValidPhoneNumber } from "libphonenumber-js";
import { InputAddress } from "src/app/components/form-elements/input-address";
import { Label } from "../components/label";
import { Input } from "src/app/components/form-elements/input";
import { CheckboxWithLabel } from "../../../components/form-elements/checkbox/checkbox-with-label";
import { Button } from "src/app/components/button";
import { Headline } from "../components/headline";
import { SignupStepper } from "../components/signup-stepper";
import { CruFormContextType } from "src/app/components/cru-form/types";
import { MessageBox } from "../components/message-box";
import { Checkbox } from "src/app/components/form-elements/checkbox";

type Props = {
  cruForm: CruFormContextType<SignupFormValues>;
};

export const Step2 = ({ cruForm }: Props) => {
  return (
    <>
      <header
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <SignupStepper description="Shipping Details" step={3} />
        <Headline>
          Please provide the following details to complete your registration
        </Headline>
      </header>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          marginBottom: 30,
        }}
      >
        <div>
          <Label required htmlFor="input-phone">
            Phone Number
          </Label>

          <CruForm.Field
            id="input-phone"
            cruForm={cruForm}
            component={CruPhoneInput}
            name="phone"
            type="tel"
            placeholder="+1 333 333 3333"
            onChange={(e) => cruForm.setValues({ phone: e || "" })}
            onBlur={() => cruForm.setTouched({ phone: true })}
            value={cruForm.values.phone}
            validate={(value) => {
              const validation = z
                .string()
                .min(2, "Phone is required")
                .refine((v) => isValidPhoneNumber(v), "Invalid phone number")
                .safeParse(value);

              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="phone" />
        </div>

        <div>
          <Label required htmlFor="input-shippingAddress">
            Shipping Address
          </Label>
          <CruForm.Field
            id="input-shippingAddress"
            cruForm={cruForm}
            component={InputAddress}
            name="shippingAddress"
            className={styles.inputAddress}
            onChange={(e) => cruForm.setValues({ shippingAddress: e })}
            onBlur={() => cruForm.setTouched({ shippingAddress: true })}
            value={cruForm.values.shippingAddress}
            fullAddressRequired
            validate={(value, values) => {
              if (value.error && !values.useWarehouse) {
                //InputAddress does an internal async validation
                return value.error;
              }
              if (!value.full_text) return "Shipping address is required";
            }}
            notAvailableForShipping="warn"
            disabled={cruForm.values.useWarehouse}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="shippingAddress" />
        </div>

        <div>
          <Label htmlFor="input-shippingInfo">Additional Shipping Info</Label>
          <CruForm.Field
            id="input-shippingInfo"
            name="shippingInfo"
            className={styles.shippingInfo}
            style={{ height: 50 }}
            component={Input}
            type="text"
            placeholder="Additional Shipping Info"
            cruForm={cruForm}
            onChange={(e) =>
              cruForm.setValues({ shippingInfo: e.target.value })
            }
            onBlur={() => cruForm.setTouched({ shippingInfo: true })}
            value={cruForm.values.shippingInfo}
            disabled={cruForm.values.useWarehouse}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="shippingInfo" />
        </div>

        {!cruForm.values.shippingAddress.isCountryAvailableForShipping && (
          <MessageBox
            intent="warning"
            className={styles.shippingWarning}
            style={{ marginTop: "10px" }}
          >
            <h4>Country not available for shipping</h4>
            <p>
              You can choose an alternative Country for delivery, or we can
              securely store your products in our premium cellar in Burgundy for
              free.
            </p>

            <div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <CruForm.Field
                  cruForm={cruForm}
                  name="useWarehouse"
                  id="useWarehouse"
                  component={Checkbox}
                  size={20}
                  checked={cruForm.values.useWarehouse}
                  onChange={(e) => {
                    cruForm.setValues({ useWarehouse: e.target.checked });
                    cruForm.setTouched({ useWarehouse: true });
                  }}
                  validate={(useWarehouse) =>
                    !useWarehouse &&
                    "You must either accept to store in Crurated warehouse or change your shipping address."
                  }
                />
                <label
                  htmlFor="useWarehouse"
                  style={{ fontWeight: 500, color: "black", fontSize: "12px" }}
                >
                  I accept to store my products in the Crurated warehouse.
                </label>
              </div>
              <CruForm.ErrorMessage cruForm={cruForm} name="useWarehouse" />
            </div>
          </MessageBox>
        )}

        <div>
          <Label htmlFor="input-companyName">Company Name</Label>
          <CruForm.Field
            id="input-companyName"
            name="companyName"
            style={{ height: 50 }}
            component={Input}
            type="text"
            placeholder="Company Name"
            cruForm={cruForm}
            onChange={(e) => cruForm.setValues({ companyName: e.target.value })}
            onBlur={() => cruForm.setTouched({ companyName: true })}
            value={cruForm.values.companyName}
            validate={(value) => {
              const validation = z.string().optional().safeParse(value);

              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          />

          <CruForm.ErrorMessage cruForm={cruForm} name="companyName" />
        </div>

        <div>
          <Label htmlFor="input-vat">VAT Number</Label>
          <CruForm.Field
            id="input-vat"
            name="vat"
            style={{ height: 50 }}
            component={Input}
            type="text"
            placeholder="FR12345678901"
            cruForm={cruForm}
            onChange={(e) => cruForm.setValues({ vat: e.target.value })}
            onBlur={() => cruForm.setTouched({ vat: true })}
            value={cruForm.values.vat}
            validate={(value) => {
              const validation = z.string().optional().safeParse(value);
              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="vat" />
        </div>
      </section>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginBottom: "32px",
        }}
      >
        <div>
          <CheckboxWithLabel
            label="Is billing address different from shipping address?"
            checked={!cruForm.values.isBillingAddressTheSameAsShipping}
            onChange={(e) => {
              cruForm.setValues({
                isBillingAddressTheSameAsShipping: !e.target.checked,
              });
            }}
          />
        </div>

        {!cruForm.values.isBillingAddressTheSameAsShipping && (
          <>
            <div>
              <Label required htmlFor="input-billingAddress">
                Billing Address
              </Label>
              <CruForm.Field
                id="input-billingAddress"
                cruForm={cruForm}
                component={InputAddress}
                name="billingAddress"
                className={styles.inputAddress}
                onChange={(e) => cruForm.setValues({ billingAddress: e })}
                onBlur={() => cruForm.setTouched({ billingAddress: true })}
                value={cruForm.values.billingAddress}
                validate={(value) => {
                  if (value.error) {
                    //InputAddress does an internal async validation
                    return value.error;
                  }
                  if (!value.full_text) return "Billing address is required";
                }}
                notAvailableForShipping="ignore"
              />
              <CruForm.ErrorMessage cruForm={cruForm} name="billingAddress" />
            </div>
          </>
        )}
      </section>
      <Button label="Next" style={{ width: "100%" }} />
    </>
  );
};
