/* eslint-disable react/no-unescaped-entities */
import iconSrc from "src/media/logos/crurated-icon.png";
import { Layout } from "../../../_metronic/layout";
import { usePageTitle } from "../../hooks/usePageTitle";
import styles from "./index.module.css";
import { cn } from "src/app/utils/cn";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { PAGE_TITLE } from "src/app/const";

// TODO: remove global styles

export const TermsAndConditions = () => {
  usePageTitle(PAGE_TITLE.TERMS_AND_CONDITIONS());
  const isMobile = useIsMobile();

  return (
    <Layout>
      <div
        className={cn(styles.root, isMobile ? styles.mobile : styles.desktop)}
      >
        <div className={styles.logoContainer}>
          <img src={iconSrc} alt="Crurated logo" />
        </div>

        <p>TERMS AND CONDITIONS OF CRURATED – UPDATED AS OF NOVEMBER 9 2023</p>

        <p style={{ fontWeight: 600 }}>1. RECITALS</p>
        <p>
          Welcome to Crurated ("Crurated'', also referred to as the "Platform"),
          a specialized platform dedicated to the sale of and services related
          to various products. This section outlines the General Terms and
          Conditions ("T&Cs") that govern the relationship between Crurated
          ("we", "us", "our") and you (individually referred to as a "User" or
          "Member" and collectively referred to as "Users '' or "Members").
          Together, Crurated and you are referred to as the "Parties".
        </p>
        <p>
          Before using the Platform, please read this Agreement carefully and
          confirm your acceptance during the registration process. If you don't
          agree with these terms, you are not permitted to use our services.
        </p>
        <p>
          We reserve the right to revise these T&Cs at any time, with changes
          effective from July 1,2023.
        </p>
        <p>
          Changes will take effect upon posting, and your continued use of the
          Platform signifies your acceptance of any updated T&Cs. As a User, it
          is your responsibility to review these T&Cs regularly.
        </p>
        <p style={{ fontWeight: 600 }}>2. ABOUT CRURATED </p>
        <p>
          Crurated (www.crurated.com) is a website operated by Crurated Limited,
          a private limited company registered under the laws of England & Wales
          (Company Number: 13176905, VAT Number: GB 376 8329 51).
        </p>
        <p>
          Incorporated under the Companies Act 2006 on February 3, 2021, our
          registered office is located at 90 Chancery Lane, London (UK), WC2A
          1EU. You may contact us via email at hello@crurated.com.
        </p>
        <p>
          We specialize in the online sale of Wine, other alcoholic beverages,
          and related accessories ("Products"), available either through
          fixed-price direct sales or through auctions as detailed in these
          T&Cs. For the purposes of these Terms, we may interchangeably use
          "Wine," representing our primary offering, and specific product names
          with the term "Product" to precisely indicate the product in question.
        </p>
        <p>
          The company has introduced a new category within its scope of
          services, referred to as 'Experiences.'
        </p>
        <p>
          These experiences, each subject to its own specific terms and
          conditions, may be detailed on the company's website.
        </p>
        <p>
          For further details or inquiries regarding these experiences, members
          are encouraged to contact our support team to learn more about our
          experience offerings.
        </p>
        <p style={{ fontWeight: 600 }}>3. OUR SERVICES </p>
        <p style={{ fontWeight: 600 }}>3.1 Membership</p>
        <p>Your Crurated membership will continue until terminated.</p>
        <p>
          To use Crurated you must have internet access and you should provide
          us with one or more payment methods among which credit cards or bank
          wire transfers so that you must register to become a member.
        </p>
        <p>
          Membership fees and all other payments will be paid by the users
          through credit card or bank transfer.
        </p>
        <p>
          Unless you cancel your membership before your billing date, you
          authorize us to charge the membership fee for the next billing cycle
          to your payment methods, as per this clause.
        </p>
        <p style={{ fontWeight: 600 }}>3.2 Membership plans</p>
        <p>
          We may offer a number of membership plans. Our cost membership plans
          are set out in point 3.3., stating that Users can choose to upgrade
          their membership at any point in time. We will bill the prorated
          amount for the remainder of the cycle, and it will be charged to the
          User’s credit card on file.
        </p>
        <p>
          The User can downgrade his plan by submitting a request: Crurated will
          review and approve the downgrade request. The downgrade can be of one
          level below his current plan and will follow the order as below
          reported: from Ambassador to Collector; from Collector to Connoisseur.
        </p>
        <p>
          It’s not possible to downgrade the plan from Connoisseur to Explorer
          even in the case the customer never bought with Crurated.
        </p>
        <p style={{ fontWeight: 600 }}>3.3 Membership costs and services</p>
        <p>
          There are 4 (four) different types of batches of Products: Limited,
          Exclusive, Selected and Crurated, respectively dedicated to the
          different categories of Users.
        </p>
        <p>
          <b>Limited:</b> is only available to Ambassador members.
        </p>
        <p>
          <b>Exclusive:</b> is only available to Ambassador and Collector
          members.{" "}
        </p>
        <p>
          <b>Selected:</b> is available to Connoisseur, Ambassador and
          Collector.
        </p>
        <p>
          <b>Crurated:</b> is available to Connoisseur, Ambassador, Collector
          and Explorer members.{" "}
        </p>
        <p>
          Crurated decided at its discretion the definition of each category and
          will independently decide which batches fall under each category.
        </p>
        <p style={{ fontWeight: 600 }}>3.3.1 Explorer </p>
        <p>(i) Cost: no monthly fee.</p>
        <p>(ii) Benefits: </p>
        <p>1. Access to “Crurated” batches for all different sales types.</p>
        <p>
          2. Explorer members are eligible to submit offers for only 1 (one)
          batch for all the offers on the Platform.
        </p>
        <p>
          3. In case of tied offers, these members will have 4th priority in
          terms of allocation in collections.{" "}
        </p>
        <p>
          4. Complimentary storage for all the batches purchased on Crurated in
          Crurated warehouse. Complimentary storage is limited to 50 (fifty)
          bottles present in the warehouse at any given time. Only applies to
          active members.
        </p>
        <p>
          5. Every bottle will be registered with a unique blockchain
          certificate upon shipment from the warehouse.{" "}
        </p>
        <p>6. No buyer’s premium will be applied on any of the purchases. </p>
        <p>
          7. The purchased Products will be protected and insured against such
          events as breakage, loss, theft or fire and other adverse events that
          may occur in Crurated warehouse.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>3.3.2 Connoisseur </p>
        <p>
          (i) Cost: Monthly membership fee € 30 (thirty) plus VAT; Annual
          membership fee: € 300 (three hundred) plus VAT. Membership fees are
          charged the first day of the month.{" "}
        </p>
        <p>(ii) Benefits: </p>
        <p>
          1. Access to ‘’Selected’’ and “Crurated” batches for all different
          sales types.
        </p>
        <p>
          2. Connoisseur members are eligible to submit offers for only 1 (one)
          batch for all the offers on the Platform.
        </p>
        <p>
          3. In case of tied offers, these members will have 3rd priority in
          terms of allocation in collections.{" "}
        </p>
        <p>
          4. Complimentary storage for all the batches purchased on Crurated in
          Crurated warehouse. Complimentary storage is limited to 500 (five
          hundred) bottles present in the warehouse at any given time. Only
          applies to active members.{" "}
        </p>
        <p>
          5. Every bottle will be registered with a unique blockchain address
          upon shipment from the warehouse.{" "}
        </p>
        <p>6. No buyer’s premium will be applied on any of the purchases. </p>
        <p>
          7. The purchased Products will be protected and insured against such
          events as breakage, loss, theft or fire and other adverse events that
          may occur in Crurated warehouse.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>3.3.3 Collector </p>
        <p>
          (i) Cost: Monthly membership fee: € 150 (one hundred and fifty) plus
          VAT, Annual membership fee: €1500 plus VAT. Membership fees are
          charged the first day of the month.{" "}
        </p>
        <p>(ii) Benefits: </p>
        <p>
          1. Access to “Exclusive”, “Crurated” and ‘’Selected’’ batches for all
          different sales types.
        </p>
        <p>
          2. Collector members are eligible to submit offers for more than 1
          (one) batch, depending on the availability. The number of offers that
          the Collector member can put an offer for will be determined by the
          Owner and will be different for all batches. Allocation of the
          additional cases will be based on availability.{" "}
        </p>
        <p>
          3. In case of tied offers, these members will have 2nd priority in
          terms of allocation in collections.{" "}
        </p>
        <p>
          4.Unlimited complimentary storage for all the batches purchased on
          Crurated in Crurated warehouse. Only applies to active members.{" "}
        </p>
        <p>
          5. Every bottle will be registered with a unique blockchain address
          upon shipment from the warehouse.{" "}
        </p>
        <p>6. No buyer’s premium will be applied on any of the purchases. </p>
        <p>
          7. The purchased Products will be protected and insured against such
          events as breakage, loss, theft or fire and other adverse events that
          may occur in Crurated warehouse.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>3.3.4. Ambassador </p>
        <p>
          (i) Cost: Monthly membership fee: €500 (five hundred) plus VAT, Annual
          membership fee: €5000 (five thousand) plus VAT. Membership fees are
          charged the first day of the month.{" "}
        </p>
        <p>(ii) Benefits:</p>
        <p>
          1. Access to “Limited”, “Exclusive”, “Crurated” and ‘’Selected’’
          batches for all different sale types.{" "}
        </p>
        <p>
          2. Ambassador members are eligible to submit offers for more than 1
          (one) batch, depending on the availability. The number of offers that
          the Ambassador member can put an offer for will be determined by the
          Owner and will be different for all batches. Allocation of the
          additional cases will be based on availability.{" "}
        </p>
        <p>
          3. Ambassador members will have 1st priority in allocation of all
          batches.{" "}
        </p>
        <p>
          4. In case of tied offers, these members will have 1st priority in
          terms of allocation in collections.{" "}
        </p>
        <p>
          5. Unlimited complimentary storage for all the batches purchased on
          Crurated in Crurated warehouse. Only applies to active members.{" "}
        </p>
        <p>
          6. Every bottle will be registered with a unique blockchain address
          upon shipment from the warehouse.{" "}
        </p>
        <p>7. No buyer’s premium will be applied on any of the purchases. </p>
        <p>
          8. The purchased Products will be protected and insured against such
          events as breakage, loss, theft or fire and other adverse events that
          may occur in Crurated warehouse.{" "}
        </p>
        <p>
          3.3.4 The owner has the right to determine the allocation of batches
          to different categories of membership. Users will be able to update
          their membership plan to be able to bid for batches that are otherwise
          unavailable. Different subscription types will have the opportunity to
          submit bids.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>3.5 Registration</p>
        <p>
          You must be of a legal drinking age within your country of residence
          to acquire the status of member of the Crurated community.{" "}
        </p>
        <p>
          To use the services under this section or any part of it, Users must
          register in a truthful, complete, and correct manner filling all the
          required data in the relevant registration form.
        </p>
        <p>
          The Users must also accept the privacy policy that you find clicking
          on this link: https://crurated.com/privacy-policy and these T&C in
          full.{" "}
        </p>
        <p>
          Users are responsible for keeping their login credentials strictly
          confidential.{" "}
        </p>
        <p>
          It remains understood that Owner shall not be deemed liable under any
          circumstances in case of loss, disclosure, theft or unauthorized use
          by third parties, for whatever reason, of the Users’ access
          credential.
        </p>
        <p>
          Once you have registered you should not permit any third party to use
          or access your account on your behalf or otherwise. You will be liable
          for all bids made via your account.
        </p>
        <p>
          After their first purchase, Users will be requested to complete the
          identity verification process.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>3.6 Suspension of Membership</p>
        <p>
          In case a User does not fulfill the outstanding payments, he/she needs
          to update the payment method within 1 (one) week if the favorite
          Payment method is Credit Card or 2 (two) weeks if the favorite Payment
          method is Bank Transfer. If he/she fails to update the payment
          information, the Owner has the right to suspend the account. In this
          case the member will not be able to:
        </p>
        <p>- Participate in new auctions</p>
        <p>- Ship wines </p>
        <p>
          Even if suspended, the Member will continue to be charged for
          Membership fees. The account cannot have a negative balance for more
          than 12 (twelve) months. After 12 (twelve) months of negative balance,
          Crurated has the right to sell a portion of the wines of the Member in
          its marketplace for the company to bring the account into zero
          balance, subject to its standard marketplace fees.{" "}
        </p>
        <p>
          The User will receive monthly notifications about the negative balance
          of the account and in case the balance is negative for more than 12
          (twelve) months it will receive a notification about upcoming sales
          and results of his/her wines 2 (two) weeks before and 1 (one) week
          before the date of the sale.
        </p>
        <p style={{ fontWeight: 600 }}>3.7 Cancellation </p>
        <p>
          You can cancel your Crurated membership at any time, and you will
          continue to have access to the Platform until your billing period
          ends.{" "}
        </p>
        <p>
          Membership fees are non-refundable, and we do not provide refunds or
          credits for any partial membership periods.
        </p>
        <p>
          If You wish to cancel, please go to the “Account” page, and follow the
          instructions herein for cancellation.
        </p>
        <p>
          If you cancel your membership, your account will be automatically
          closed at the end of your current billing period.
        </p>
        <p>
          To see when your account will close, click “Billing details” on the
          “Account” page. If you choose to cancel your membership with Crurated,
          you will have 30 (thirty) days to organize the delivery of your
          bottles.
        </p>
        <p>
          If you do not arrange delivery within this time, we will charge you
          €2/month per bottle (included in your voucher(s)) per year in advance
          from the expiry of that 30-day period. More info in the Storage
          clause.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>4. TERMS AND CONDITIONS OF SALE</p>
        <p>
          These terms of sale outline the conditions under which we offer our
          products on the Platform. We retain the right to exclude specific
          countries and Users from purchasing certain Products, as long as these
          exclusions align with the agreements we have made with our suppliers.{" "}
        </p>
        <p>
          We offer six distinct methods of purchase or sale on our platform:
        </p>
        <p>(I) Collections. </p>
        <p>(II) Single Lot Auctions.</p>
        <p>(III) Fixed Price Offers.</p>
        <p>(IV) Private Sales.</p>
        <p>(V) Direct Offers.</p>
        <p>(VI) Barrels.</p>
        <p>
          Each method operates under different procedures and guidelines, which
          are detailed in the following sections.
        </p>
        <p style={{ fontWeight: 600 }}>4.1 Collections.</p>
        <p>
          Collections are offers to purchase products, subject to availability
          and discretionary acceptance by Crurated. They operate within cycles,
          which determine the duration for which the collection is open for
          bids. The highest offers from Users win the available cases, with
          Users being able to place offers on multiple collections and set a
          spending ceiling per cycle. The platform does not exceed this spending
          limit.
        </p>
        <p>
          Winners are determined based on the highest offers and in case of
          ties, the priority is given based on membership type and submission
          time. If a User doesn't meet the winning criteria for a certain lot,
          it won't be allocated to them. An offer submission doesn't guarantee
          placement of the order; Users will be notified of their winning or
          losing status within 72 hours after the sale closure.{" "}
        </p>
        <p>
          Winners will be charged the outstanding amount on their credit card
          and given a week to fulfill the payment. If the User fails to make the
          payment within the given time, the Platform reserves the right not to
          allocate the lot to them. Allocation of more than 1 (one) lot for a
          single member will only happen in case there are lots available after
          maximizing distribution across all members that submitted offers for a
          specific lot above the minimum offer (or reserve price).{" "}
        </p>
        <p>
          This is irrespective of the price offered by the member for that
          specific lot. A User that has a negative balance in their account will
          not be able to participate in auctions and/or ship his/her Wines until
          all outstanding balance has been paid.
        </p>
        <p>
          In addition, and for the avoidance of doubt, Users are expressly
          prohibited from canceling an order once it has been placed.
        </p>
        <p>
          This stipulation is crucial to uphold the fluidity and fairness of the
          transactional process.
        </p>
        <p style={{ fontWeight: 600 }}>4.2 Single Lot Auction </p>
        <p>
          4.2.1 Single lot auctions involve individual Wine, each unique and
          available only once. Each of these unique Wines, will have one
          successful bidder, who will be the sole winner.
        </p>
        <p>
          4.2.2 Users are free to place a maximum bid on the Wine of their
          choice. However, this bid must exceed the highest current bid at the
          time of bidding. If a higher bid is placed, the outbid User will be
          notified and given the opportunity to bid again. There are no
          restrictions on the number of bids a User can place during an active
          auction. Even though auctions have a specified expiration date/time,
          last-minute bidding within the final 30 seconds of the auction can
          extend this deadline. If a bid is placed within this window, the
          30-second countdown is reset until no more bids are received. This
          format emulates the excitement and competitive spirit of traditional
          live auctions. At the close of the auction, the winning bidder will be
          notified via email. The winning amount will be automatically charged
          to their credit card. They will have a period of 48 hours from the
          email notification to complete the payment. If payment is not received
          within this timeframe, the platform reserves the right to withhold the
          allocation of the lot to the User.
        </p>
        <p>
          4.2.3 A User with a negative account balance will be ineligible to
          participate in auctions or to ship any Wines until all outstanding
          dues are settled.
        </p>
        <p style={{ fontWeight: 600 }}>
          4.3 Online bidding process for Single Lots
        </p>
        <p>
          4.3.1 Once you have successfully registered as a User you will be
          eligible to bid online. During the sale process, Users can bid for a
          Wine by clicking the bid button in accordance with point 3 above. Each
          bid shall be final and bidding as soon as you click the bid button.
          The current bid will be displayed on your screen during the sale.{" "}
        </p>
        <p>
          4.3.2. The User expressly acknowledges that he/she is bidding in a
          live auction and, therefore, irrevocably agrees that any bid
          submitted, pursuant to these T&C, shall be deemed irrevocable so that
          it cannot be amended or modified, even if accidentally submitted and
          notified in the context of the auction. As such, the User assumes full
          responsibility for all bids submitted through his online bidding
          account.{" "}
        </p>
        <p>
          4.3.3 All bids submitted through the Platform are considered final and
          binding upon submission. By placing a bid, Users acknowledge and
          accept that they are making a committed offer to purchase the
          specified lot at their bid price, and that this commitment cannot be
          withdrawn or cancelled once the bid has been submitted. As such, Users
          are advised to exercise due diligence and careful consideration when
          placing bids. All bids are immediately executed upon submission.
        </p>
        <p>
          4.3.4 While bid amounts can be displayed in a currency other than the
          primary currency of the auction, the selected currency will be
          considered the binding currency for the bid. This selected currency
          will be clearly indicated in brackets on the Platform once the User
          has made a selection. The inclusion of these amounts in various
          currencies aims to provide full awareness to the User regarding the
          potential bid amounts. These alternative currency values are
          calculated using recent and official exchange rates. However, for
          clarity and to avoid any potential misunderstanding, the bid and final
          payment, following the successful auction, will be executed in the
          selected currency. This ensures that the User is not exposed to
          potential increases in cost due to fluctuations in currency exchange
          rates.
        </p>
        <p style={{ fontWeight: 600 }}>4.4 Private Sales</p>
        <p>
          Crurated may offer a specific number of lots exclusively to select
          Users through our Private Sales service. These selected Users will be
          able to view and participate in the Private Sales dedicated to them
          within the dedicated section on the Platform, along with the specific
          closing time for each Private Sale.{" "}
        </p>
        <p>
          In order to participate in a Private Sale, Users must submit a bid
          that meets or exceeds the minimum offer displayed on the platform.
          Private Sales may operate under a sealed-offer format, whereby the
          highest number of bids within a certain range (x) are considered
          winning offers. The number of successful bidders will depend on the
          total number of cases available for each particular Private Sale.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>4.5 Direct Offers </p>
        <p>
          4.5.1 In coordination with producers, Crurated will make available a
          specified number of Wines at a predetermined fixed price. Users have
          the ability to view the number of available Wines at any time. These
          offers operate on a "first come, first served" basis. Once all Wines
          are purchased, the status for Users will show as "Sold Out".
        </p>
        <p style={{ fontWeight: 600 }}>4.6 Barrels</p>
        <p>
          Barrels, subject to availability and Crurated's discretionary
          acceptance, operate in specified cycles indicative of their active
          bidding period. Users place bids, which must be equal to or higher
          than the minimum listed on the Platform.{" "}
        </p>
        <p>
          Employing a sealed-offer format, the top "x" number of bids will be
          selected as winners. Users bid on the available minimum format
          multiples, with the total number of winners determined by the volume
          of liters available.
        </p>
        <p>
          Should a User's bid not meet the winning criteria for a particular
          lot, they will not be allocated that lot. Each bid represents an offer
          to purchase the products, contingent on Crurated's acceptance.{" "}
        </p>
        <p>4.6.1 Winning Criteria </p>
        <p>
          The available liters (x) will be allocated to the highest offers (x)
          submitted by eligible Users . In cases of tied offers from more than
          one User, Crurated will prioritize based on:
        </p>
        <p>a) The higher membership type, and</p>
        <p>
          b) If the Users have the same membership type, the submission time
          will be considered.
        </p>
        <p>4.6.2 Offer Submission and Order Placement</p>
        <p>
          Submitting an offer does not equate to order placement. Members will
          receive an email notification within 72 hours after the sale closure,
          informing them about their bid status (winning or losing). Winning
          members will be automatically charged the outstanding amount on their
          credit card.
        </p>
        <p>
          A one-week period, commencing from the email notification, will be
          provided for the User to complete the payment. If payment is not made
          within this time, Crurated reserves the right to withhold the lot
          allocation.
        </p>
        <p>4.6.3 Conversion of Won Liters to Bottle</p>
        <p>
          Users can select the bottle format during the specified time window on
          the Platform to convert won liters into bottles. If a User fails to
          choose a bottle format, the won liters will automatically be converted
          and bottled in the minimum available format.
        </p>
        <p>4.6.4 Account Balance </p>
        <p>
          A User with a negative account balance will be ineligible to
          participate in auctions or ship Wines until all outstanding balances
          are cleared.
        </p>
        <p>4.6.5 Wine Descriptions</p>
        <p>
          All Wines under these T&C have the following features until the
          Agreement expires:
        </p>
        <p>
          (I) Wine details and individual bottle information within the Wine are
          exclusively visible to Users on the Platform. Displayed information
          includes the producer's name, cuvee name, classifications,
          appellation, vintage, bottle conditions, and the number of specific
          bottles.
        </p>
        <p>
          (II) Crurated reserves the right to create combinations of bottles for
          each lot at their discretion.
        </p>
        <p style={{ fontWeight: 600 }}>4.7 Crurated Estimated Market Price</p>
        <p>
          The estimated market price is Crurated's subjective evaluation and
          serves as a guide for Users . It draws from a variety of sources and
          accounts for the condition and rarity of the bottles. However, this is
          purely an estimate, and we advise Users to undertake independent
          investigation of market prices before placing any order.
        </p>
        <p style={{ fontWeight: 600 }}>4.8 Order completion</p>
        <p>
          If lots are awarded, the following sections will become visible to the
          Users:
        </p>
        <p>
          (I)"Outstanding payments": This is shown if the payment hasn't been
          fulfilled yet.
        </p>
        <p>
          (II)"Your Cellar": Once the payment is completed, Users will be able
          to view their products under this section.
        </p>
        <p style={{ fontWeight: 600 }}>4.9 Vouchers & Ownership/Title Title </p>
        <p>
          4.9.1 When Users purchase Wine through the Platform, they are actually
          buying a voucher for that Wine, which they can redeem at a later time,
          rather than buying the Wine outright. To collect the Wine or arrange
          for delivery, Users need to redeem their voucher through the Platform
          and pay the applicable fees (as outlined in clauses 4.9 and 7).
        </p>
        <p>
          4.9.2 There may be a significant time period between the awarding of
          the lot, placing the order, and issuance of the voucher and the actual
          delivery of the products. In such cases, Crurated will handle the
          storage of the products. The goods will remain the property of
          Crurated and won't be shipped until all due invoices or costs have
          been fully paid by the Users . If a User wins an auction (as per
          points 4.3.1, 4.4.1, and 4.6.1) or purchases through Fixed Price Sale,
          Private Sale, and Direct Sale (as per points 4.5.1, 4.6.5, and 4.6.6),
          the Collection will be reserved for that User in our database, and it
          will be visible to such Users on the Platform. Even when the date or
          place of delivery for the Products are unknown, Crurated will issue
          vouchers and a receipt.
        </p>
        <p>
          4.9.3 To redeem vouchers, or to receive the ownership of Products,
          Users must ensure that all due fees and other payments are up-to-date.
          Provided all payments are up-to-date, the title to products transfers
          to Users as outlined in these Terms.
        </p>
        <p>
          <b>5. PAYMENT METHODS</b>
        </p>
        <p>
          5.1 Users must ensure that all payments, including allocated Products,
          membership fees, relevant taxes, duties, delivery charges, and
          processing fee costs, are settled via approved payment methods, and it
          is the User's responsibility to maintain current and accurate payment
          information on their account.
        </p>
        <p>
          5.2 Payments must be completed within seven (7) business days
          following a successful purchase for credit card payments and within
          fourteen (14) business days for bank transfer payments. Failure to
          meet this deadline gives Crurated the right to withhold the allocation
          of the lot to the Member and may lead to account suspension.
        </p>
        <p>
          5.3 Be aware that certain payment methods may be subject to additional
          fees imposed by the issuing entity, such as foreign transaction fees
          or processing charges or other local tax or VAT charges. Crurated
          bears no responsibility for such costs and/or charges.
        </p>
        <p>
          5.4 Users may request bank transfers as a payment method, subject to
          approval and activation by Crurated. This request can be made via the
          “My Account” section. If a User has outstanding membership fees, the
          bank transfer payment option will be unavailable until all outstanding
          fees are settled. Upon settlement, the bank transfer option may be
          reactivated, subject to Crurated's approval and agreeable terms on a
          case-by-case basis.
        </p>
        <p>
          <b>6. PACKAGING AND DELIVERY GUIDELINES</b>
        </p>
        <p style={{ fontWeight: 600 }}>6.1 Packaging </p>
        <p>
          6.1.1. Inspection and Labeling: Crurated conducts a meticulous
          inspection of each Wine bottle and its packaging, including opening of
          the Original Wooden Cases (“OWC”). A Crurated label with a unique
          serial number enabling NFT technology is affixed to each Wine bottle
          sold.
        </p>
        <p>
          6.1.2 Wooden Cases Packaging: OWCs may suffer breakage during
          inspection due to their structure. Although utmost care is taken,
          Crurated does not guarantee against such breakage. OWCs are
          reconditioned when possible, and are not retained if the lot contains
          fewer Wine bottles than the case. Any loss or breakage resulting from
          a shipment arranged by Crurated should be reported in writing within
          three (3) days of the delivery. This process should involve the
          examination of the property in the presence of the carrier upon
          arrival. Please refer to clauses 6.2, 6.3 and 6.5 for more information
          on delivery guidelines.{" "}
        </p>
        <p>
          6.1.3 In the interest of safeguarding the Wines' condition during
          transit, our packaging process may involve repacking the Wines in
          protective containers. Please be aware that the original wooden cases,
          while carefully preserved during the inspection, are not automatically
          included with the Wines as part of the packaging. Their inclusion
          depends on factors such as the shipping regulations and the specific
          nature of the shipment.
        </p>
        <p style={{ fontWeight: 600 }}>6.2 Delivery Process</p>
        <p>
          6.2.1 Crurated provides delivery options for physical items, clarified
          on our Platform during the order process. For non-Wine products such
          as accessories, the delivery details and charges are indicated as you
          place your order. To arrange Wine product delivery, redeem a voucher
          (which automatically triggers on delivery request), furnish necessary
          shipping details, and settle all relevant charges as stated on the
          Platform. Risk of loss or damage and ownership (once full payment is
          received) of items transferred to you on delivery or the date of first
          attempted delivery by us, whichever occurs first. We strive to deliver
          Products within the estimated timelines, but unforeseen factors can
          sometimes cause delays. In these instances, Crurated is not liable for
          any delay or failure to deliver within the estimated timelines.
        </p>
        <p>
          6.2.2 Product Shipment and Delivery: Products are not automatically
          dispatched for delivery. Once we've received payment, you can arrange
          delivery when the status of the Wine in 'Your Cellar' changes to 'In
          Our Warehouse'. Please note that it can take time for some Wines to
          arrive at our warehouse. Crurated is not responsible for any delays
          beyond our control, such as shipping disruptions, supplier
          inconsistencies, or other external factors that could affect our
          estimated timelines. Remember, these dates are estimations and might
          change at any time. Consequently, any modifications to these dates
          fall outside the purview of Crurated's control.
        </p>
        <p>
          6.2.3 Crurated prioritizes the safety of all Products, including Wine,
          and adheres strictly to shipping regulations, which may require us to
          repack items in protective containers for optimal protection. For
          orders under sixty (60) bottles or with a total Wine value of less
          than €7,500, we typically provide quotes for expedited delivery
          services, such as DHL, UPS, or equivalent carriers. Larger orders are
          usually shipped via freight service, often referred to as a 'pallet'
          shipment, which essentially means transportation by truck
        </p>
        <p>
          For any special delivery requests, such as delivery to a bonded
          warehouse, specific time- slots, narrow access roads, or historic town
          centers, we ask that you please make these known before initiating the
          shipment request process by emailing us at hello@crurated.com. These
          special requests may require alternative arrangements or additional
          costs.
        </p>
        <p>
          6.2.3.1 Shipping Large Bottles: For Wine bottles larger than three
          liters (3L), expedited delivery services may not be suitable due to
          safety considerations. In these cases, our Customer Support logistics
          team will directly contact Users to arrange the most appropriate and
          secure shipping method.
        </p>
        <p>
          6.2.4 Warehouse Pickup: Users self-collecting at the warehouse must
          contact the Crurated Logistics Team on hello@crurated.com to initiate
          the packaging process, allowing a minimum of three working days
          post-payment. Such pickups are subject to French VAT.
        </p>
        <p>
          6.2.5 OWCs and special packaging are not automatically included in the
          delivery. OWCs can be shipped separately or included in pallet
          shipments, subject to suitability. For self-collecting Users ,
          available OWCs will be provided. However, due to specific regulations
          in some countries, OWCs might not be included. Please note that the
          handling and delivery of OWCs fall under the same conditions and
          potential risks as noted in clause 6.1.{" "}
        </p>
        <p style={{ fontWeight: 600 }}>6.3 Delivery Protocols</p>
        <p>
          6.3.1 Crurated maintains a bonded warehouse in Burgundy, France, where
          Wines are stored. Delivery charges are variable and depend on several
          factors including the destination, the quantity of the order, and any
          additional transportation services required. If specific
          transportation services are necessary, it is the responsibility of the
          customer to inform Crurated's Team in advance. All of these elements
          combined determine the final delivery charges.
        </p>
        <p>
          6.3.2 Crurated supports shipments to various EU countries and select
          international destinations at competitive rates (subject to clause
          6.3) . Upon arrival of the Wine at our warehouse, the User may request
          a shipment and a quote for shipping costs will be provided. Note that
          for some destinations, customs duties and taxes may be applicable and
          payable by the User upon the arrival of goods in the country. As per
          article 6.3.1, 6.3.2, and 6.3.3, Crurated does not take responsibility
          for accurately estimating these charges nor paying them on the User's
          behalf.
        </p>
        <p>
          6.3.3 Crurated will make reasonable efforts to protect Wines against
          severe weather conditions that could cause shipment delays during
          periods of extreme high or low temperatures. In instances of extreme
          weather, Crurated will request the customer's signature on a
          'Temperature Waiver.' This document specifies that any
          transport-related damages caused by temperature fluctuations will not
          be covered by Crurated's insurance.
        </p>
        <p>
          6.3.4 All Wines are insured against loss, damage, fire, or other
          mishaps during transit to the User’s designated destination by
          Crurated. Any loss or breakage should be reported in writing within
          three (3) days of delivery.
        </p>
        <p style={{ fontWeight: 600 }}>6.4 User-Managed Deliveries</p>
        <p>
          6.4.1 For Users whose desired shipping destinations are not currently
          supported by Crurated, we offer free unlimited storage in our Burgundy
          warehouse. Users may choose to organize their own shipping arrangement
          in such cases, working with us to devise a custom solution.
        </p>
        <p>
          6.4.2 If a User decides to manage the delivery of the product
          independently and at their own expense, Crurated retains the right to
          impose additional handling fees to prepare the order for
          transportation. This includes packaging, preparing accompanying
          documents, and other related tasks. The mode of delivery will be
          defined as either Ex-Works (EXW) or Free Carrier (FCA) as per the
          Incoterms' latest edition. It's worth noting that these International
          Commercial Terms, as published by the International Chamber of
          Commerce (ICC), determine contractual terms used in national and
          international sales contracts. Specifically, in EXW, the User assumes
          all transportation risks, while in FCA, risk transfers when goods are
          made available to the carrier chosen by the User. These delivery
          methods do not fall under the insurance coverage detailed in 6.2.1.
        </p>
        <p>
          6.4.3 If the delivery address lies outside the European Union, the
          User must provide Crurated with the cleared eAD and export declaration
          or an equivalent proof of export, compliant with French customs
          regulations for the Products transported.
        </p>
        <p>
          6.4.4 Please note that all shipments require an adult signature upon
          delivery.
        </p>
        <p style={{ fontWeight: 600 }}>6.5 User Responsibilities</p>
        <p>
          6.5.1 Legal Compliance: Users are advised to understand the various
          restrictions in their respective jurisdictions on the quantity of
          alcoholic beverages that can be purchased and shipped from other
          jurisdictions. Users are obligated to ensure they comply with these
          regulations, including any necessary permits or licenses. Before
          purchasing, Users are required to research and comprehend the
          restrictions, prohibitions, and requirements of the laws and
          regulations applicable in their country pertaining to the shipment and
          delivery of alcoholic beverages.
        </p>
        <p>
          6.5.2 Legal Disclaimer: Crurated does not provide any guarantee or
          representation pertaining to the legality or legitimacy of shipping
          alcoholic beverages across jurisdictions. It is the User's
          responsibility to verify this before making a purchase.
        </p>
        <p>
          6.5.3 Insurance Limitations: Users should be fully aware that the
          Crurated insurance coverage (refer to point 6.2.1) does not include
          losses due to confiscation or other restrictive measures issued by any
          national or local governmental authority, arising from alleged
          violations of applicable laws by the purchasers.
        </p>
        <p>
          6.5.4 Delivery Acceptance and Verification: Users are required to be
          personally present to accept delivery, unless they have an authorized
          representative who can accept it on their behalf. Any appointed
          representative must have adequate authorization proof, be of legal
          drinking age in the shipment destination country, and must be able to
          sign all delivery related documents. Upon delivery, Users should
          verify the contents of the order and note any discrepancies in the
          delivery form to preserve their rights for potential claims or
          returns.
        </p>
        <p>
          6.5.5 Consequences of Non-delivery: Crurated accepts no liability for
          non-delivery or damage to the purchased products if the non-delivery
          results from the User's negligence in providing accurate delivery data
          (e.g., phone number, shipping address, etc.). In such cases, Crurated
          reserves the right to retrieve the non-delivered goods at its own
          discretion but at the User's expense.
        </p>
        <p style={{ fontWeight: 600 }}>
          6.6 Spirits Offering and Complimentary Storage Terms
        </p>
        <p>
          6.6.1 Introduction of Spirits: Crurated has added a selection of fine
          spirits (“Spirits”) to our product offerings.{" "}
        </p>
        <p>
          6.6.2 Shipping Limitations: While we strive to deliver our full range
          of products, including Spirits, to a wide array of destinations, there
          may be specific markets where shipping constraints such as regulatory
          restrictions, logistical challenges, or other market-specific
          limitations, prevent us from delivering these items directly to you.
          For complimentary storage options regarding Spirits, please refer to
          clause 7.4 for detailed information
        </p>
        <p>
          <b>7. STORAGE</b>
        </p>
        <p>
          7.1 Crurated provides its Ambassador and Collector tier members with
          unlimited storage at no extra cost while their memberships remain
          active. For Connoisseur tier members, storage is complimentary for up
          to 500 bottles; any bottles exceeding this limit incur a storage fee
          of €2 per bottle per month in advance. Explorer tier members are
          allowed storage of up to 50 bottles at no charge, with each additional
          bottle costing €2 per month, also billed monthly in advance.
        </p>
        <p>
          7.2 If a User decides to cancel their registration with the Crurated
          platform, they are obligated to arrange delivery of their stored Wines
          within 30 days from the date of cancellation. Failure to adhere to
          this deadline will result in the application of a storage fee of €2
          per bottle per month, payable in advance.
        </p>
        <p>
          7.3 Complimentary Storage Solution: Recognizing these shipping
          limitations of Spirits offering, Crurated provides a complementary
          storage service for customers affected by these constraints. This
          service allows customers in markets where direct shipping of spirits
          is not feasible to store their purchased spirits at no additional
          cost.{" "}
        </p>
        <p>
          <b>8. SALES AND OTHER TAXES</b>
        </p>
        <p>
          8.1 Users shall be accountable for any tax, tariff, or additional
          charges that may be imposed by the customs authorities of each
          destination country. If required by law, Crurated will charge or remit
          any such costs associated with the sale, which cannot be
          predetermined. All displayed prices for Users /members are deemed
          exclusive of any sales tax, including United Kingdom Value Added Tax
          (VAT), or any other applicable tax of a similar nature. The
          User/member shall pay these taxes at the prevailing rate, if
          applicable. VAT or sales tax, as applicable, will be charged on the
          total amount invoiced to the User when the product is dispatched from
          the warehouse for delivery. This total amount is comprised of the
          price of the Wine, the processing fee, and the delivery charges.
        </p>
        <p>
          8.2 Users asserting exemption from sales, use, or other taxes must
          furnish Crurated with supporting documents. The adequacy of these
          documents for exemption purposes will be determined by Crurated's sole
          discretion and judgment, prior to the release of property to the
          member.
        </p>
        <p>
          <b>9. DUTIES AND LIABILITIES</b>
        </p>
        <p>
          9.1 While Crurated endeavors to ensure continuous, uninterrupted
          access to the Platform, it cannot guarantee that the Platform will
          always be available or free of faults, defects, or interruptions.
          Crurated will not be liable for any delay, fault, or interruption in
          the Platform due to: force majeure or accident; power supply
          interruption; lack of connection; software errors; hardware failure;
          database problems; errors not attributable to wilful misconduct or
          negligence on the part of Crurated, or any third-party actions,
          including those of Crurated's suppliers.
        </p>
        <p>
          9.2 Nothing in these T&C seeks to exclude or limit liability for death
          or personal injury caused by negligence, fraudulent misrepresentation,
          or any other liability which may not be limited or excluded under
          applicable law.
        </p>
        <p>
          9.3 While Crurated strives to maintain the integrity of its site and
          Platform, it does not warrant that the site will always be error-free,
          that defects will be corrected in a timely manner, or that the site or
          the server that hosts it is free of viruses or other harmful elements.
          Crurated does not guarantee that the site or Platform will always
          function without disruptions, delays, or imperfections. Crurated will
          not be responsible or liable to you for any loss of content or
          material uploaded or transmitted through the site.
        </p>
        <p>
          9.4 Except as expressly provided in these T&C, all other warranties,
          terms, and conditions (whether express or implied) are hereby excluded
          to the fullest extent permitted under applicable law, subject always
          to your statutory and common law consumer rights.
        </p>
        <p>9.5 Under no circumstances will Crurated be liable for:</p>
        <p>
          - economic losses (including, without limitation, loss of revenues,
          data, profits, contracts, business, or anticipated savings); or
        </p>
        <p>- loss of goodwill or reputation; or</p>
        <p>
          - special, indirect, consequential, incidental, or punitive damages,
          arising out of or in connection with these T&C or your use of the
          Platform, regardless of the form of action, whether in contract, tort
          (including negligence), strict liability, or otherwise, except where
          such exclusions are prohibited by law.
        </p>
        <p>
          <b>10. RIGHT TO CANCEL FOR UK USERS</b>
        </p>
        <p>
          10.1 The provisions under these Terms & Conditions apply exclusively
          to UK Users making purchases as consumers.
        </p>
        <p>
          10.2 In case of cancellation of a purchase, you, as the User, have the
          right to a refund of all payments made, including administrative and
          processing fees. However, these processing fees may be non-refundable
          in certain instances where Crurated has already undertaken
          administrative tasks relating to your purchase. This could include
          instances where a purchase process has been initiated, or where we
          have already incurred costs on your behalf. We will provide specific
          details of any non-refundable fees at the time of purchase.
        </p>
        <p>
          10.3 If a purchase cancellation is initiated, Crurated shall reimburse
          all payments received from the User, excluding non-refundable
          processing fees and any applicable VAT, along with the delivery costs
          (excluding any additional costs resulting from your choice of a
          delivery method other than the least expensive type of standard
          delivery offered by us). You will bear the direct cost of returning
          the Products.
        </p>
        <p>
          10.4 Crurated will process the refund without undue delay, and no
          later than 14 days after receiving the returned goods or proof of
          their return, whichever is earlier. The refund will be made via the
          same payment method used for the initial transaction.
        </p>
        <p>
          10.5 Crurated reserves the right to deduct from the refund any loss in
          value of the Products if the loss is the result of unnecessary
          handling by you. Please note, this right of cancellation does not
          apply to bottles of Wine which have been opened.
        </p>
        <p>
          10.6 If you have the Products in your possession, you are obliged to
          take reasonable care of them. You are expected to send the Products
          back to our contact address at your own expense (unless we delivered
          the item to you by mistake or the items are damaged or defective) as
          soon as possible once you have canceled the contract.
        </p>
        <p>
          10.7 Crurated reserves the right to charge a fee not exceeding our
          direct costs of recovering the Products if you fail to return them at
          your own expense.
        </p>
        <p>
          10.8 Please note, the right to cancel a purchase does not apply for:
        </p>
        <p>
          a) The supply of Products, the price of which is dependent on
          fluctuations in the financial market that are beyond our control.
        </p>
        <p>
          b) The supply of Products made to your specifications or clearly
          personalized or which by reason of their nature cannot be returned or
          are liable to deteriorate or expire rapidly.
        </p>
        <p>
          In any case, to exercise the right to cancel a purchase, you should
          inform Crurated of such a decision by a clear written statement to our
          email contact: hello@crurated.com no later than the end of the 14-day
          cancellation period. You also have the right to cancel any bid prior
          to its acceptance by Crurated (i.e. before the end of the auction).
        </p>
        <p>
          <b>11. CREDIT POLICY</b>
        </p>
        <p>
          Crurated has implemented a new Credit Policy. This policy is designed
          to manage credit risks and involves close monitoring of overdue
          receivables, issuing monthly statements to customers with overdue
          accounts, and implementing stringent risk management procedures. Our
          goal is to safeguard the financial interests of both our customers and
          Crurated.
        </p>
        <p>
          Please be advised of the following specific details of the newly
          implemented Credit Policy, effective January 1, 2024:
        </p>
        <ol style={{ marginLeft: "2ch" }}>
          <li style={{ paddingLeft: ".5ch", marginBottom: "1ch" }}>
            <b>Interest on Outstanding Balances:</b> All outstanding balances
            over three (3) months will incur an interest charge. This charge
            will be calculated at the rate of LIBOR+3% on each overdue invoice,
            applicable from the invoice date until the date of payment.
          </li>
          <li style={{ paddingLeft: ".5ch", marginBottom: "1ch" }}>
            <b>Issuance and Payment of Interest Invoices:</b> Interest invoices
            will be issued on the date the payment is received for overdue
            invoices. It is mandatory to settle these interest invoices within
            30 days of issuance. Failure to clear the interest invoice may
            result in the suspension of future shipments, and the customer may
            become ineligible for further credit. Failure to settle outstanding
            invoices for over six (6) months will result in immediate suspension
            of shipments.
          </li>
          <li style={{ paddingLeft: ".5ch", marginBottom: "1ch" }}>
            <b>Grace Period for Existing Overdue Balances:</b> To facilitate a
            smooth transition to this policy, customers will have a two (2)
            month grace period (November 2023 and December 2023) to settle all
            existing overdue balances. Post the implementation date of January
            1, 2024, interest will be charged on all invoices that are overdue
            by more than ninety (90) days. This interest charge will be applied
            retrospectively.
          </li>
        </ol>
        <p>
          In the event of invoices remaining unpaid for a period exceeding one
          (1) year, Crurated will initiate a notification process to the
          customer prior to reaching the one-year threshold. Formal reminders
          will be sent via email at intervals of three (3) months and then two
          (2) months before the one-year mark, as a notification of the
          outstanding balance to be settled. Should these reminders fail to
          prompt a customer’s settlement of the outstanding invoices upon
          reaching one (1) year, Crurated unequivocally reserves the right, as
          stipulated in its terms and conditions, to acquire possession and
          ownership of the respective stock of the customer and engage in its
          subsequent sale.
        </p>
        <p>
          By continuing to use Crurated’s platform and services, all registered
          and future customers agree to adhere to the terms of this Credit
          Policy, as outlined in our updated Terms & Conditions. It is crucial
          that our customers familiarize themselves with these changes, as they
          are binding and affect the use of all our services. For a detailed
          overview of the Credit Policy and its implications, please refer to
          the policy here. Your continued use of our services signifies your
          acceptance and compliance with these updated terms.
        </p>
        <p>
          Should you have any questions or require further clarification
          regarding this policy, please do not hesitate to contact our Customer
          Service team
        </p>
        <p>
          <b>12. INTELLECTUAL PROPERTY RIGHTS</b>
        </p>
        <p>
          You acknowledge that the services contain a variety of components such
          as information, text, software, photographs, music, audio and video
          clips, graphics, and links, which are protected by copyright,
          trademark, or other proprietary rights or intellectual property rights
          of Crurated or third parties.All such components collectively referred
          to as “Content” are owned by or licensed to us. You agree that all
          intellectual property rights, including but not limited to copyright
          and trademarks, in all materials and/or Content made available to you
          as part of your use of this site shall remain at all times vested in
          us or our licensors.
        </p>
        <p>
          Crurated maintains copyright in the selection, coordination,
          arrangement, and enhancement of the Content, as well as the content
          original to Crurated under applicable copyright law. As a User, you
          are authorized to use this material only as expressly permitted by us
          or our licensors.
        </p>
        <p>
          Any other use of the Content is strictly prohibited. You hereby agree
          not to engage, or assist or facilitate any third party in engaging, in
          actions such as copying, reproducing, transmitting, publishing,
          displaying, distributing, commercially exploiting or creating
          derivative works of such Content. Please note that Crurated does not
          grant permission to Users of its services to reproduce images of
          original works of art.
        </p>
        <p>
          Crurated maintains copyright in the selection, coordination,
          arrangement, and enhancement of the Content, as well as the content
          original to Crurated under applicable copyright law. As a User, you
          are authorized to use this material only as expressly permitted by us
          or our licensors. Crurated respects the rights of all copyright
          holders.
        </p>
        <p>
          <b>13. COMMUNICATIONS</b>
        </p>
        <p>
          All communications directed to us must be sent to the contact
          information provided on this website. We reserve the right to issue
          communications to you at either the electronic mail or physical
          mailing address you furnish to us during the process of making a
          purchase. A communication will be deemed received and appropriately
          served 24 hours subsequent to the transmission of an email, or three
          days following the date of dispatch of any physical letter. To
          validate the service of any communication, it will suffice to
          demonstrate, in the context of a physical letter, that the letter was
          correctly addressed, stamped, and deposited in the mail. In the
          context of an email, it will suffice to demonstrate that the email was
          dispatched to the specified electronic mail address of the recipient.
        </p>
        <p>
          <b>14. GOVERNING LAW</b>
        </p>
        <p>
          These T&Cs shall be governed by and construed in accordance with the
          laws of England, and the English language shall be the authoritative
          and controlling language for all purposes related to these T&C. Any
          contract for the purchase of Products, goods and/or services from this
          Platform shall be subject to English law. In the event of any dispute
          or claim arising out of or in connection with such a contract, the
          parties agree to submit to the exclusive jurisdiction of the courts of
          England.
        </p>
        <p>
          <b>15. FORCE MAJEURE</b>
        </p>
        <p>
          Crurated shall be under no liability for any delay or failure to
          deliver Products or otherwise perform any obligation as specified in
          these T&C if the same is wholly or partly caused, directly or
          indirectly, by circumstances beyond its reasonable control.
        </p>
        <p>
          <b>16. EXTERNAL LINKS</b>
        </p>
        <p>
          To provide increased value to our Users , we may provide links to
          other websites or resources for you to access at your sole discretion.
          You acknowledge and agree that as you have chosen to enter the linked
          website, we are not responsible for the availability of such external
          sites or resources. We do not review or endorse and are not
          responsible or liable, directly or indirectly, for (i) the privacy
          practices of such websites, (ii) the content of such websites,
          including (without limitation) any advertising, content, products,
          goods or other materials or services on or available from such
          websites or resources, or (iii) the use to which others make of these
          websites or resources, nor for any damage, loss or offence caused or
          alleged to be caused by, or in connection with, the use of or reliance
          on any such advertising, content, products, goods or other materials
          or services available on such external websites or resources.
        </p>
        <p>
          <b>17. ASSIGNMENT AND SUBCONTRACTING</b>
        </p>
        <p>
          You may not assign or subcontract any of your rights or obligations
          under these T&C or any related order for Products to any third party
          unless agreed upon in writing by Crurated. Crurated, in turn, reserves
          the right to transfer, assign, novate or subcontract the benefit of
          the whole or part of any of its rights or obligations under these T&C
          or any related contract to any third party.
        </p>
        <p>
          <b>18. SEVERABILITY </b>
        </p>
        <p>
          If any portion of these T&C is held by any competent authority to be
          invalid or unenforceable in whole or in part, the validity or
          enforceability of the other sections of these T&C shall not be
          affected.
        </p>
        <p>
          <b>19. THIRD PARTY RIGHTS</b>
        </p>
        <p>
          These T&Cs do not extend any rights or benefits to any individual or
          entity not a party to these T&C, with the following exceptions:
        </p>
        <p>
          a) A person who is a legally recognized successor or assignee may
          enforce the rights or benefits provided by these T&C.
        </p>
        <p>
          b) Changes or termination of these T&C by the parties involved do not
          require consent from any third party, even if such changes affect
          rights or benefits previously extended to those third parties.
        </p>
        <p>
          <b>20. WAIVER</b>
        </p>
        <p>
          No delay or failure by Crurated to exercise any powers, rights or
          remedies under these T&C will operate as a waiver of them, nor will
          any single or partial exercise of any such powers, rights or remedies
          preclude any other or further exercise of them. Any waiver to be
          effective must be in writing and signed by an authorized
          representative of Crurated.
        </p>
        <p>
          <b>21. ENTIRE AGREEMENT</b>
        </p>
        <p>
          These T&C, including the documents or other sources referred to in
          these T&C, supersede all prior representations, understandings, and
          agreements between you and Crurated relating to the use of this site
          (including the order of Products) and sets forth the entire Agreement
          and understanding between you and Crurated for your use of this site.
        </p>
      </div>
    </Layout>
  );
};
