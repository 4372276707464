import styles from "./index.module.css";
import { ReactNode } from "react";
import { Leftbar } from "./leftbar";
import { ID_IO_ROOT } from "../const";
import { CookieBanner } from "../components/cookie-banner";

type Props = {
  children: ReactNode;
};

export const Layout = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <Leftbar />
      </div>

      <div className={styles.content} id={ID_IO_ROOT}>
        <div className={styles.inner}>{props.children}</div>
      </div>

      <CookieBanner />
    </div>
  );
};
