import { useIsMutating } from "@tanstack/react-query";
import { Button } from "src/app/components/button";
import styles from "./step-summary.module.css";
import { useBidProcessingFeesQuery } from "src/app/api/queries_to_rename_later";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { isAuctionOfBarrel, isAuctionOfLot } from "src/app/models/auctions";
import { getAuctionMessage } from "src/app/components/auction-card/locale";
import { useMyUser } from "src/app/hooks/useMyUser";
import { IdPaymentMethod } from "src/app/const";
import { PurchaseMethod } from "src/app/api/mutations_new_to_rename";
import useAuctionStatus from "src/app/hooks/useAuctionStatus";
import { cn } from "src/app/utils/cn";

type Props = {
  auction: AuctionOfBarrel | AuctionOfLot;
  action: Exclude<PurchaseMethod, "buy_now">;
  bidDetails: {
    price: number;
    quantity: number;
  };
  onConfirm?: () => void;
  onClose?: () => void;
  showTaxDisclaimer?: boolean;
};

export const StepSummary = ({
  auction,
  action,
  bidDetails,
  onClose,
  onConfirm,
  showTaxDisclaimer = true,
}: Props) => {
  const user = useMyUser();
  const { data } = useBidProcessingFeesQuery(
    {
      auction_type: auction.type_product,
      id_auction: auction.id,
      quantity: bidDetails.quantity,
      single_bid: bidDetails.price,
    },
    { enabled: !!bidDetails },
  );

  const { isEnabledMaxBid, isSingleBid } = useAuctionStatus({
    auction,
    price: bidDetails.price,
  });
  const isMutating = !!useIsMutating();

  const message = getAuctionMessage({
    messagesObject: auction.isForStore ? "shop" : "auction",
    messageType: "prompt",
    idAuctionType: auction.type.id,
    idPaymentMethod: user.method_payment?.active
      .id_method_payment as IdPaymentMethod,
    typeProduct: auction.type_product,
    additionalKeys: isEnabledMaxBid
      ? [isSingleBid ? "place_bid" : "increase_bid"]
      : undefined,
  });

  const isSingleLot = isAuctionOfLot(auction) && auction.isOfSingleLot;

  let confirmLabel = "Confirm Bid";
  if (action === "place_bid") {
    if (auction.isForStore) {
      confirmLabel = "Confirm Purchase";
    } else if (isSingleLot) {
      confirmLabel = "Yes";
    }
  }

  let closeLabel = "Cancel";
  if (action === "place_bid") {
    if (auction.isForStore) {
      closeLabel = "Edit Purchase";
    } else if (isSingleLot) {
      closeLabel = "No, Cancel";
    } else {
      closeLabel = "Edit Bid";
    }
  }

  return (
    <div className={styles.content}>
      <h2>{message.title}</h2>
      {!isSingleLot && (
        <div className={styles.bidDetails}>
          <div className={styles.top}>
            <div className={styles.detailItem}>
              <div
                className={styles.value}
              >{`${bidDetails.quantity * (auction.options.quantities.mul || 1)} ${isAuctionOfBarrel(auction) ? "L" : bidDetails.quantity > 1 ? "lots" : "lot"}`}</div>
              <div className={styles.label}>Quantity selected</div>
            </div>
            <div className={styles.detailItem}>
              <div className={styles.value}>
                {formatCurrency(bidDetails.price * bidDetails.quantity)}
              </div>
              <div className={styles.label}>Price</div>
            </div>
          </div>
          <div className={styles.bottom}>
            + {formatCurrency(data)} processing fees
          </div>
        </div>
      )}
      <div className={styles.description}>{message.content}</div>
      {!!showTaxDisclaimer && !isSingleLot && (
        <div className={styles.disclaimer}>
          Taxes / Duties / VAT if applicable, will be calculated at the moment
          of the shipment based on the shipping country.
        </div>
      )}
      <div className={styles.footer}>
        <div
          className={cn(
            styles.buttonsWrapper,
            !isSingleLot && styles.fullWidthButtons,
          )}
        >
          <Button
            className={styles.button}
            label={closeLabel}
            onClick={onClose}
            variant={"outlined"}
            isLoading={isMutating}
          />
          <Button
            as={"button"}
            className={styles.button}
            label={confirmLabel}
            onClick={onConfirm}
            isLoading={isMutating}
          />
        </div>
      </div>
    </div>
  );
};
