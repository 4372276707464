import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { Country, apiCountrySchema } from "../models/Country";
import { minutes } from "../utils/time";
import { QKEY } from "./QKEY";
import { upfetch, upfetchV1, upfetchV1New } from "./upfetch";
import { z } from "zod";
import { ApiLotV1, apiLotSchemaV1 } from "../models/LotV1";
import { apiBarrelSchemaV1 } from "../models/BarrelV1";
import { useMyUser } from "../hooks/useMyUser";
import {
  SerialProductV1,
  serialProductSchemaV1,
} from "../models/SerialProductV1";
import { Product, apiProductSchema } from "../models/Product";
import { MyEarningsV1, myEarningsSchemaV1 } from "../models/MyEarningsV1";
import {
  CellarExperience,
  apiCellarExperienceSchema,
} from "../models/CellarExperience";
import { Bottle, apiBottleSchema } from "../models/Bottle";
import {
  MyAssetsStat,
  apiMyAssetsStatsSchemaV1,
} from "../models/MyAssetsStatsV1";
import {
  OutstandingPaymentForBarrelV1,
  OutstandingPaymentForLotV1,
  OutstandingPaymentV1,
  apiOPBarrelSchemaV1,
  apiOPLotSchemaV1,
  isPaymentOfBarrel,
  isPaymentOfLot,
} from "../models/OutstandingPaymentV1";
import {
  ApiPendingGiftsV1,
  apiPendingGiftsSchemaV1,
} from "../models/PendingGiftsV1";
import { apiAuctionOfBarrelSchema } from "../models/auctions/AuctionOfBarrel";
import { apiAuctionOfLotSchema } from "../models/auctions/AuctionOfLot";
import { env } from "src/env";

const getCountries = {
  queryKey: () => [QKEY.COUNTRY],
  queryFn: () => async () => {
    return upfetch({
      url: `countries/list/`,
      schema: z.array(apiCountrySchema),
    })
      .then((apiCountries) => apiCountries.map((c) => new Country(c)))
      .catch(() => []);
  },
};

export const useCountriesQuery = <TData = Country[]>(
  options: UseQueryOptions<Country[], any, TData> = {},
) => {
  return useQuery({
    queryKey: getCountries.queryKey(),
    queryFn: getCountries.queryFn(),
    staleTime: minutes(60),
    ...options,
  });
};

const getMyLots = (id_user: number) => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.LOT, id_user],
  queryFn: () => {
    return upfetchV1({
      url: "myCellar/GetPhysicalCellarLots/",
      params: { id_user },
      schema: z.array(apiLotSchemaV1),
    });
  },
});

export const useMyLotsQuery = (
  id_user: number,
  options: UseQueryOptions<ApiLotV1[]> = {},
) => {
  return useQuery({
    queryKey: getMyLots(id_user).queryKey,
    queryFn: getMyLots(id_user).queryFn,
    ...options,
  });
};

const getMyBarrels = (id_user: number) => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.BARREL, id_user],
  queryFn: () => {
    return upfetchV1({
      url: "myCellar/GetPhysicalCellarBarrels/",
      params: { id_user },
      schema: z.array(apiBarrelSchemaV1),
    });
  },
});

export const useMyBarrelsQuery = (id_user: number, options = {}) => {
  return useQuery({
    queryKey: getMyBarrels(id_user).queryKey,
    queryFn: getMyBarrels(id_user).queryFn,
    ...options,
  });
};

const getProductsOfLot = (accessToken: string, id_lot: number) => ({
  queryKey: [QKEY.LOT, QKEY.PRODUCT, accessToken, id_lot],
  queryFn: async () =>
    upfetchV1({
      url: "lots/productsOfLot/",
      params: { accessToken, id_lot },
      parseResponse: (res) => res.json().then((data) => data.result || []),
      schema: z.array(apiProductSchema),
    }).then((products) => products.map((product) => new Product(product))),
});

export const useProductsOfLotQuery = (
  id_lot: number,
  options: UseQueryOptions<Product[]> = {},
) => {
  const { accessToken } = useMyUser();

  return useQuery({
    queryKey: getProductsOfLot(accessToken, id_lot).queryKey,
    queryFn: getProductsOfLot(accessToken, id_lot).queryFn,
    staleTime: minutes(5),
    ...options,
  });
};

const getSerialsOfProductsOfLot = (
  accessToken: string,
  id_lot: number,
  id_user: number,
) => ({
  queryKey: [QKEY.LOT, QKEY.PRODUCT, QKEY.SERIAL, accessToken, id_lot, id_user],
  queryFn: async () => {
    return upfetchV1({
      url: "lots/getSerialsOfProductsOfLot/",
      params: { accessToken, id_lot, id_user },
      parseResponse: (res) => res.json().then((data) => data.result || []),
      schema: z.array(serialProductSchemaV1),
    });
  },
});

export const useSerialsOfProductsOfLotQuery = (
  id_lot: number,
  options: UseQueryOptions<SerialProductV1[]> = {},
) => {
  const { accessToken, id_user } = useMyUser();

  return useQuery({
    queryKey: getSerialsOfProductsOfLot(accessToken, id_lot, id_user).queryKey,
    queryFn: getSerialsOfProductsOfLot(accessToken, id_lot, id_user).queryFn,
    staleTime: minutes(5),
    ...options,
  });
};

const getMyEarnings = (accessToken: string) => ({
  queryKey: [QKEY.MY_EARNINGS, accessToken],
  queryFn: async () => {
    return upfetchV1({
      url: "statistics/GetTotalEarns/",
      params: { accessToken },
      schema: myEarningsSchemaV1,
    }).then((apiEarnings) => new MyEarningsV1(apiEarnings));
  },
});

export const useMyEarningsQuery = (
  options: UseQueryOptions<MyEarningsV1> = {},
) => {
  const user = useMyUser();

  return useQuery({
    queryKey: getMyEarnings(user.accessToken).queryKey,
    queryFn: getMyEarnings(user.accessToken).queryFn,
    ...options,
  });
};

const getMyExperiences = (id_user: number) => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.EXPERIENCES, id_user],
  queryFn: () => {
    return upfetch({
      url: `users/Customer/${id_user}/cellar/?type=experience`,
      schema: z.array(apiCellarExperienceSchema),
    }).then((exps) => exps.map((exp) => new CellarExperience(exp)));
  },
});

export const useMyExperiencesQuery = (id_user: number, options = {}) => {
  return useQuery({
    queryKey: getMyExperiences(id_user).queryKey,
    queryFn: getMyExperiences(id_user).queryFn,
    ...options,
  });
};

const getBottleList = (id_user: number) => ({
  queryKey: [QKEY.BOTTLE_LIST, id_user],
  queryFn: () => {
    return upfetch({
      url: `users/Customer/${id_user}/bottles/`,
      schema: z.array(apiBottleSchema),
    }).then((bottles) => bottles.map((bottle) => new Bottle(bottle)));
  },
});

export const useBottleListQuery = (options: UseQueryOptions<Bottle[]> = {}) => {
  const user = useMyUser();

  return useQuery({
    queryKey: getBottleList(user.id).queryKey,
    queryFn: getBottleList(user.id).queryFn,
    ...options,
  });
};

const getMyAssetsStats = (params: {
  accessToken: string;
  id_user: number;
}) => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.MY_ASSETS_STATS, params],
  queryFn: async () => {
    return upfetchV1({
      url: `statistics/GetMyCellarStats/`,
      params,
      schema: apiMyAssetsStatsSchemaV1,
    });
  },
});

export const useMyAssetsStats = (
  options: UseQueryOptions<MyAssetsStat[]> = {},
) => {
  const myUser = useMyUser();

  return useQuery({
    queryKey: getMyAssetsStats(myUser).queryKey,
    queryFn: getMyAssetsStats(myUser).queryFn,
    ...options,
  });
};

const getOutstandingPaymentsV1 = () => ({
  queryKey: [QKEY.OUTSTANDING_PAYMENTS],
  queryFn: () => {
    return upfetchV1({
      url: `myCellar/GetOutstandingPayments/`,
      schema: z.array(z.union([apiOPBarrelSchemaV1, apiOPLotSchemaV1])),
    }).then((payments) =>
      payments.reduce((acc, payment) => {
        if (isPaymentOfLot(payment))
          acc.push(new OutstandingPaymentForLotV1(payment));
        if (isPaymentOfBarrel(payment))
          acc.push(new OutstandingPaymentForBarrelV1(payment));

        return acc;
      }, new Array<OutstandingPaymentV1>()),
    );
  },
});

export const useOutstandingPaymentsQueryV1 = () => {
  return useQuery({
    queryKey: getOutstandingPaymentsV1().queryKey,
    queryFn: getOutstandingPaymentsV1().queryFn,
  });
};

const getSerialMinted = (params: { id_token: string }) => ({
  queryKey: [QKEY.NFT_METADATA, params],
  queryFn: async () => {
    return upfetchV1New({
      url: `nft/get-serial-minted`,
      params,
    });
  },
});

export const useSerialMintedQueryV1 = (
  id_token: string,
  options: UseQueryOptions = {},
) => {
  return useQuery({
    queryKey: getSerialMinted({ id_token }).queryKey,
    queryFn: getSerialMinted({ id_token }).queryFn,
    ...options,
  });
};

const getWhatsNew = {
  queryKey: () => [QKEY.WHATS_NEW],
  queryFn: () => async () =>
    await upfetch({
      url: "auctions",
      params: {
        dashboard: true,
        onGoing: true,
        expand: true,
      },
      schema: z.array(
        z.union([apiAuctionOfBarrelSchema, apiAuctionOfLotSchema]),
      ),
    }).then((auctions) =>
      auctions.sort((a, b) => {
        const idA = a.id;
        const idB = b.id;
        return idA < idB ? 1 : -1;
      }),
    ),
};

export const useGetWhatsNewQuery = (options = {}) => {
  return useQuery({
    queryKey: getWhatsNew.queryKey(),
    queryFn: getWhatsNew.queryFn(),
    ...options,
  });
};

const getMyPendingGifts = () => ({
  queryKey: ["PENDING_GIFTS"],
  queryFn: async () => {
    return upfetchV1({
      url: `gifts/giftViewForUser/`,
      schema: z.array(apiPendingGiftsSchemaV1),
    });
  },
});

export const useMyPendingGifts = (
  options: UseQueryOptions<ApiPendingGiftsV1[]> = {},
) => {
  return useQuery({
    queryKey: getMyPendingGifts().queryKey,
    queryFn: getMyPendingGifts().queryFn,
    ...options,
  });
};

type BidProcessingFeesPayloadType = {
  id_auction: number;
  auction_type: "lot" | "barrel";
  quantity: number;
  single_bid: number;
};

const getBidProcessingFees = (params: BidProcessingFeesPayloadType) => ({
  queryKey: ["FEES", params],
  queryFn: async () => {
    return upfetch({
      baseUrl: env.REACT_APP_API_URL_NEW,
      url: `api/v2_1/auctions/${params.id_auction}/processing-fees`,
      params: {
        auction_type: params.auction_type,
        quantity: params.quantity,
        single_bid: params.single_bid,
      },
    });
  },
});

export const useBidProcessingFeesQuery = (
  params: BidProcessingFeesPayloadType,
  options: UseQueryOptions<any> = {},
) => {
  return useQuery({
    queryKey: getBidProcessingFees(params).queryKey,
    queryFn: getBidProcessingFees(params).queryFn,
    ...options,
  });
};

const getHandlingResellData = (config: { userId: number; lotId: number }) => ({
  queryKey: [QKEY.MY_ASSETS, QKEY.MY_ASSETS_STATS, config],
  queryFn: async () => {
    return upfetchV1({
      url: "myCellar/GetAllInformationHandlingResell/",
      body: {
        id_user: config.userId,
        id_lot: config.lotId,
      },
      method: "POST",
    });
  },
});

export const useGetHandlingResellData = (lotId: number) => {
  const { id_user } = useMyUser();
  const config = { userId: id_user, lotId };

  return useQuery({
    queryKey: getHandlingResellData(config).queryKey,
    queryFn: getHandlingResellData(config).queryFn,
  });
};
