// ! Do not move in another file

import { cleanEnv, str, url } from "envalid";

// eslint-disable-next-line no-restricted-globals
export const env = cleanEnv(process.env, {
  REACT_APP_API_URL_NEW: url(),
  REACT_APP_SERVICES_API: url(),
  REACT_APP_AUT: str(),
  REACT_APP_AUTH_PROD: str(),
  REACT_APP_SECRET_KEY: str(),
  REACT_APP_PUBLIC_KEY_STRIPE: str(),
  REACT_APP_EXPLORER_URL: url(),
  REACT_APP_API_KEY_HISTORY: str(),
  REACT_APP_CONTRACT_ADDRESS_HISTORY: str(),
  REACT_APP_CONTRACT_ADDRESS: str(),
  REACT_APP_LINK_WEB_3: url(),
  REACT_APP_ADMIN_LEGACY_URL: url(),
  REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID: str(),
  REACT_APP_SENTRY_DNS: url(),
  REACT_APP_WEBSITE: url(),
});
