import { z } from "zod";
import { booleanishSchema } from "./_booleanish-schema";
import { PAYMENT_STATUS, PaymentStatus } from "../const";

export const baseOpSchemaV1 = z.object({
  single_bid: z.number(),
  single_bid_winner: z.number(),
  is_winner: booleanishSchema,
  total_bid: z.number(),
  total_pay: z.number(),
  quantity: z.number(),
  quantity_winner: z.number(),
  insurance: z.number(),
  notice_as_paid: z.nativeEnum(PAYMENT_STATUS),
  date_winner: z.string(),
  deleted: booleanishSchema,
  bid: z.number(),
  bid_winner: z.number(),
  bid_date: z.string(),
  bid_date_control: z.string(),
  description: z.string(),
  edited_date: z.string().nullable(),
  id_user: z.number(),
  is_request: booleanishSchema,
  key_unique: z.number(),
  note: z.string().nullable(),
  payment_deadline_countdown: z.string().nullable(),
  priority: booleanishSchema,
  returned: booleanishSchema,
  status_availability: booleanishSchema,
  stato: booleanishSchema,
  auction: z.any(),
  user: z.any(),
});

export const apiOPBarrelSchemaV1 = baseOpSchemaV1.extend({
  cellar_type: z.literal("barrels"),
  name_barrel: z.string(),
  id_barrel: z.number(),
  id_barrel_auction: z.number(),
  id_barrel_auction_participant: z.union([z.number(), z.string()]),
  payment_deadline: z.object({ date: z.string() }).nullable(),
  redeem: booleanishSchema,
  sku_barrel: z.string(),
  single_liter: z.number(),
  single_liter_winner: z.number(),
});

export const apiOPLotSchemaV1 = baseOpSchemaV1.extend({
  cellar_type: z.literal("lots"),
  quantity_serialized: z.number(),
  id_auction: z.number(),
  id_auction_participant: z.number(),
  id_auction_participant_mp: z.string(),
  id_gift: z.number(),
  is_gift: booleanishSchema,
  id_lot: z.number(),
  is_automatic: booleanishSchema,
  name_lot: z.string().optional(),
  payment_deadline: z.string().nullable(),
  serial_assigned: booleanishSchema,
  sku_lot: z.string(),
});

type ApiOutstandingPaymentV1 =
  | ApiOutstandingPaymentForBarrelV1
  | ApiOutstandingPaymentForLotV1;

export type ApiOutstandingPaymentForBarrelV1 = z.output<
  typeof apiOPBarrelSchemaV1
>;
export type ApiOutstandingPaymentForLotV1 = z.output<typeof apiOPLotSchemaV1>;
export type OutstandingPaymentV1 =
  | OutstandingPaymentForBarrelV1
  | OutstandingPaymentForLotV1;

export class OutstandingPaymentForBarrelV1 {
  cellar_type: "barrels";
  sku_barrel: string;
  single_bid: number;
  single_bid_winner: number;
  is_winner: boolean;
  total_bid: number;
  total_pay: number;
  quantity: number;
  quantity_winner: number;
  insurance: number;
  notice_as_paid: PaymentStatus;
  id_barrel: number;
  id_barrel_auction: number;
  id_barrel_auction_participant: number | string;
  date_winner: string;
  deleted: boolean;
  bid: number;
  bid_winner: number;
  bid_date: string;
  bid_date_control: string;
  description: string;
  edited_date: string | null;
  id_user: number;
  is_request: boolean;
  key_unique: number;
  name_barrel: string;
  note: string | null;
  payment_deadline: { date: string } | null;
  payment_deadline_countdown: string | null;
  priority: boolean;
  redeem: boolean;
  returned: boolean;
  status_availability: boolean;
  stato: boolean;
  single_liter: number;
  single_liter_winner: number;

  auction: any;
  user: any;

  constructor(apiOpSchema: ApiOutstandingPaymentForBarrelV1) {
    this.cellar_type = apiOpSchema.cellar_type;
    this.sku_barrel = apiOpSchema.sku_barrel;
    this.single_bid = apiOpSchema.single_bid;
    this.single_bid_winner = apiOpSchema.single_bid_winner;
    this.is_winner = !!apiOpSchema.is_winner;
    this.total_bid = apiOpSchema.total_bid;
    this.total_pay = apiOpSchema.total_pay;
    this.quantity = apiOpSchema.quantity;
    this.quantity_winner = apiOpSchema.quantity_winner;
    this.insurance = apiOpSchema.insurance;
    this.notice_as_paid = apiOpSchema.notice_as_paid;
    this.id_barrel = apiOpSchema.id_barrel;
    this.id_barrel_auction = apiOpSchema.id_barrel_auction;
    this.id_barrel_auction_participant =
      apiOpSchema.id_barrel_auction_participant;
    this.date_winner = apiOpSchema.date_winner;
    this.deleted = !!apiOpSchema.deleted;
    this.bid = apiOpSchema.bid;
    this.bid_winner = apiOpSchema.bid_winner;
    this.bid_date = apiOpSchema.bid_date;
    this.bid_date_control = apiOpSchema.bid_date_control;
    this.description = apiOpSchema.description;
    this.edited_date = apiOpSchema.edited_date;
    this.id_user = apiOpSchema.id_user;
    this.is_request = !!apiOpSchema.is_request;
    this.key_unique = apiOpSchema.key_unique;
    this.name_barrel = apiOpSchema.name_barrel;
    this.note = apiOpSchema.note;
    this.payment_deadline = apiOpSchema.payment_deadline;
    this.payment_deadline_countdown = apiOpSchema.payment_deadline_countdown;
    this.priority = !!apiOpSchema.priority;
    this.redeem = !!apiOpSchema.redeem;
    this.returned = !!apiOpSchema.returned;
    this.status_availability = !!apiOpSchema.status_availability;
    this.stato = !!apiOpSchema.stato;
    this.single_liter = apiOpSchema.single_liter;
    this.single_liter_winner = apiOpSchema.single_liter_winner;
    this.auction = apiOpSchema.auction;
    this.user = apiOpSchema.user;
  }

  get name() {
    return this.description;
  }

  get productDetails() {
    return this.name_barrel;
  }

  get totalQuantity() {
    return this.quantity_winner * this.single_liter_winner;
  }

  get displayTotalQuantity() {
    return `${this.quantity_winner * this.single_liter_winner}L`;
  }

  get totalToPay() {
    return this.total_bid - this.total_pay;
  }

  get processingFees() {
    return this.insurance;
  }

  get paymentDeadline() {
    return this.payment_deadline?.date;
  }

  get totalPaid() {
    return this.total_pay;
  }
}

export class OutstandingPaymentForLotV1 {
  cellar_type: "lots";
  sku_lot: string;
  single_bid: number;
  single_bid_winner: number;
  is_winner: boolean;
  total_bid: number;
  total_pay: number;
  quantity: number;
  quantity_winner: number;
  quantity_serialized: number;
  insurance: number;
  notice_as_paid: PaymentStatus;
  id_auction: number;
  id_auction_participant: number;
  id_auction_participant_mp: string;
  date_winner: string;
  deleted: boolean;
  bid: number;
  bid_winner: number;
  bid_date: string;
  bid_date_control: string;
  id_gift: number;
  is_gift: boolean;
  description: string;
  edited_date: string | null;
  id_lot: number;
  id_user: number;
  is_automatic: boolean;
  is_request: boolean;
  key_unique: number;
  name_lot: string | undefined;
  note: string | null;
  payment_deadline: string | null;
  payment_deadline_countdown: string | null;
  priority: boolean;
  returned: boolean;
  status_availability: boolean;
  stato: boolean;
  serial_assigned: boolean;

  auction: any;
  user: any;

  constructor(apiOpSchema: ApiOutstandingPaymentForLotV1) {
    this.cellar_type = apiOpSchema.cellar_type;
    this.sku_lot = apiOpSchema.sku_lot;
    this.single_bid = apiOpSchema.single_bid;
    this.single_bid_winner = apiOpSchema.single_bid_winner;
    this.is_winner = !!apiOpSchema.is_winner;
    this.total_bid = apiOpSchema.total_bid;
    this.total_pay = apiOpSchema.total_pay;
    this.quantity = apiOpSchema.quantity;
    this.quantity_winner = apiOpSchema.quantity_winner;
    this.quantity_serialized = apiOpSchema.quantity_serialized;
    this.insurance = apiOpSchema.insurance;
    this.notice_as_paid = apiOpSchema.notice_as_paid;
    this.id_auction = apiOpSchema.id_auction;
    this.id_auction_participant = apiOpSchema.id_auction_participant;
    this.id_auction_participant_mp = apiOpSchema.id_auction_participant_mp;
    this.date_winner = apiOpSchema.date_winner;
    this.deleted = !!apiOpSchema.deleted;
    this.bid = apiOpSchema.bid;
    this.bid_winner = apiOpSchema.bid_winner;
    this.bid_date = apiOpSchema.bid_date;
    this.bid_date_control = apiOpSchema.bid_date_control;
    this.id_gift = apiOpSchema.id_gift;
    this.is_gift = !!apiOpSchema.is_gift;
    this.description = apiOpSchema.description;
    this.edited_date = apiOpSchema.edited_date;
    this.id_lot = apiOpSchema.id_lot;
    this.id_user = apiOpSchema.id_user;
    this.is_automatic = !!apiOpSchema.is_automatic;
    this.is_request = !!apiOpSchema.is_request;
    this.key_unique = apiOpSchema.key_unique;
    this.name_lot = apiOpSchema.name_lot;
    this.note = apiOpSchema.note;
    this.payment_deadline = apiOpSchema.payment_deadline;
    this.payment_deadline_countdown = apiOpSchema.payment_deadline_countdown;
    this.priority = !!apiOpSchema.priority;
    this.returned = !!apiOpSchema.returned;
    this.status_availability = !!apiOpSchema.status_availability;
    this.stato = !!apiOpSchema.stato;
    this.serial_assigned = !!apiOpSchema.serial_assigned;
    this.auction = apiOpSchema.auction;
    this.user = apiOpSchema.user;
  }

  get name() {
    return this.description;
  }

  get productDetails() {
    return this.name_lot;
  }

  get totalQuantity() {
    return this.quantity_winner;
  }

  get displayTotalQuantity() {
    return this.quantity_winner;
  }

  get totalToPay() {
    return this.total_bid - this.total_pay;
  }

  get processingFees() {
    return this.insurance;
  }

  get paymentDeadline() {
    return this.payment_deadline;
  }

  get totalPaid() {
    return this.total_pay;
  }
}

export function isPaymentOfLot(
  payment: ApiOutstandingPaymentV1,
): payment is OutstandingPaymentForLotV1 {
  return payment.cellar_type === "lots";
}

export const isPaymentOfBarrel = (
  payment: ApiOutstandingPaymentV1,
): payment is OutstandingPaymentForBarrelV1 => {
  return payment.cellar_type === "barrels";
};
