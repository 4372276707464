import { ComponentProps, forwardRef } from "react";
import styles from "./label.module.css";

type Props = ComponentProps<"label"> & { required?: boolean };

export const Label = forwardRef<HTMLLabelElement, Props>(
  ({ required = false, ...props }, ref) => {
    return (
      <label ref={ref} className={styles.root} {...props}>
        {props.children} {!!required && <span>*</span>}
      </label>
    );
  },
);
