import { useQuery } from "@tanstack/react-query";
import { useMyUser } from "../hooks/useMyUser";
import { QKEY } from "./QKEY";
import { upfetch } from "./upfetch";
import { seconds } from "../utils/time";
import { z } from "zod";
import { PAGE_NAME } from "../const";
import { ValueOf } from "../utils/types";

const getAuctionCount = {
  queryKey: () => [QKEY.BADGE],
  queryFn: () => async () => {
    return upfetch({
      url: "auctions/counter/",
      schema: z.object({
        charity: z.number(),
        collection: z.number(),
        singleLots: z.number(),
        barrel: z.number(),
      }),
    }).then(
      (count) =>
        ({
          [PAGE_NAME.WEEKLY.ALL]:
            count.barrel + count.charity + count.collection + count.singleLots,
          [PAGE_NAME.WEEKLY.BARRELS]: count.barrel,
          [PAGE_NAME.WEEKLY.CHARITY]: count.charity,
          [PAGE_NAME.WEEKLY.COLLECTIONS]: count.collection,
          [PAGE_NAME.WEEKLY.SINGLE_LOTS]: count.singleLots,
        }) as Record<ValueOf<typeof PAGE_NAME.WEEKLY>, number>,
    );
  },
};

export const useAuctionCountQuery = () => {
  const user = useMyUser({ maybeNull: true });

  return useQuery({
    queryKey: getAuctionCount.queryKey(),
    queryFn: getAuctionCount.queryFn(),
    cacheTime: Infinity,
    enabled: !!user?.accessToken,
    refetchInterval: seconds(45),
  });
};
