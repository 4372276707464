import { z } from "zod";
import { Lot, apiLotSchema } from "./Lot";

const apiNotesSchemaV1 = z.object({
  id: z.number(),
  id_shipping_notes: z.number(),
  notes: z.string(),
  date_note: z.string(),
  deleted: z.boolean(),
});

const lotForShipmentV1Schema = apiLotSchema.extend({ quantity: z.number() });

const apiShippingInfoSchemaV1 = z.object({
  extra_fee: z.number(),
  extra_fee_description: z.string().nullable(),
  other_taxes: z.number(),
  other_taxes_description: z.string().nullable(),
  vat_shipping_price: z.number(),
  wines_price: z.number(),
  vat_extra_fee: z.number(),
  total_price: z.number(),
  request_date_frontend: z.string(),
  request_date: z.string(),
  shipped_on: z.string(),
  shipped_on_frontend: z.string(),
  id_shipping: z.union([z.string(), z.number()]),
  id_order: z.union([z.string(), z.number()]),
  id_user: z.union([z.string(), z.number()]),
  fullname: z.string(),
  country: z.string(),
  status_shipping: z.number(),
  id_shipping_mp: z.union([z.string(), z.number()]),
  id_address: z.union([z.string(), z.number()]),
  id_country_vat: z.union([z.string(), z.number()]),
  shipping_price: z.number(),
  country_name: z.string(),
  tracking: z.string().nullable(),
  shipping_date: z.string(),
  pickup_address: z.boolean(),
  link_tracking: z.string().nullable(),
  courier_name: z.string().nullable(),
});

export const apiShipmentSchemaV1 = z.object({
  id_shipping: z.number(),
  id_order: z.union([z.string(), z.number()]),
  id_user: z.number(),
  fullname: z.string(),
  country: z.string(),
  status_shipping: z.number(),
  id_shipping_mp: z.union([z.string(), z.number()]),
  id_address: z.number(),
  id_country_vat: z.union([z.string(), z.number()]),
  shipping_price: z.number(),
  extra_fee: z.number(),
  extra_fee_description: z.string().nullable(),
  other_taxes: z.number(),
  other_taxes_description: z.string().nullable(),
  request_date: z.string(),
  country_name: z.string(),
  tracking: z.string().nullable(),
  shipping_date: z.string(),
  pickup_address: z.boolean(),
  id_courier_service: z.union([z.string(), z.number()]).nullable(),
  link_tracking: z.string().nullable(),
  courier_name: z.string().nullable(),
  total_price: z.number(),
  vat_extra_fee: z.number(),
  vat_shipping_price: z.number(),
  wines_price: z.number(),
  vat_wines_price: z.number(),
  request_date_frontend: z.string(),
  shipped_on: z.string(),
  shipped_on_frontend: z.string(),
  shipping_info: apiShippingInfoSchemaV1,
  user_info: z.any(),
  shipping_address: z.any(), // TODO: fix
  notes: z.array(apiNotesSchemaV1),
  status_mp: z.number(),
  lots: z.array(lotForShipmentV1Schema),
});

type ApiShipmentV1 = z.infer<typeof apiShipmentSchemaV1>;

class LotWithQuantity extends Lot {
  quantity: number;

  constructor(apiLotWithQuantity: z.infer<typeof lotForShipmentV1Schema>) {
    super(apiLotWithQuantity);
    this.quantity = apiLotWithQuantity.quantity;
  }
}

export class ShipmentV1 {
  id: number;
  id_shipping: number;
  id_order: string | number;
  id_user: number;
  fullname: string;
  country: string;
  status_shipping: number;
  id_shipping_mp: string | number;
  id_address: number;
  id_country_vat: string | number;
  shipping_price: number;
  extra_fee: number;
  extra_fee_description: string;
  other_taxes: number;
  other_taxes_description: string;
  request_date: string;
  country_name: string;
  tracking: string | null;
  shipping_date: string;
  pickup_address: boolean;
  id_courier_service: string | number | null;
  link_tracking: string | null;
  courier_name: string | null;
  total_price: number;
  vat_extra_fee: number;
  vat_shipping_price: number;
  wines_price: number;
  vat_wines_price: number;
  request_date_frontend: string;
  shipped_on: string;
  shipped_on_frontend: string;
  shipping_info: z.infer<typeof apiShippingInfoSchemaV1>;
  user_info: any;
  shipping_address: any; // TODO: address
  notes?: Array<z.infer<typeof apiNotesSchemaV1>>;
  status_mp: number;
  lots?: LotWithQuantity[];

  constructor(apiShipment: ApiShipmentV1) {
    this.id = apiShipment.id_shipping;
    this.id_shipping = apiShipment.id_shipping;
    this.id_order = apiShipment.id_order;
    this.id_user = apiShipment.id_user;
    this.fullname = apiShipment.fullname;
    this.country = apiShipment.country;
    this.status_shipping = apiShipment.status_shipping;
    this.id_shipping_mp = apiShipment.id_shipping_mp;
    this.id_address = apiShipment.id_address;
    this.id_country_vat = apiShipment.id_country_vat;
    this.shipping_price = apiShipment.shipping_price;
    this.extra_fee = apiShipment.extra_fee;
    this.extra_fee_description = apiShipment.extra_fee_description || "";
    this.other_taxes = apiShipment.other_taxes;
    this.other_taxes_description = apiShipment.other_taxes_description || "";
    this.request_date = apiShipment.request_date;
    this.country_name = apiShipment.country_name;
    this.tracking = apiShipment.tracking;
    this.shipping_date = apiShipment.shipping_date;
    this.pickup_address = apiShipment.pickup_address;
    this.id_courier_service = apiShipment.id_courier_service;
    this.link_tracking = apiShipment.link_tracking;
    this.courier_name = apiShipment.courier_name;
    this.total_price = apiShipment.total_price;
    this.vat_extra_fee = apiShipment.vat_extra_fee;
    this.vat_shipping_price = apiShipment.vat_shipping_price;
    this.wines_price = apiShipment.wines_price;
    this.vat_wines_price = apiShipment.vat_wines_price;
    this.request_date_frontend = apiShipment.request_date_frontend;
    this.shipped_on = apiShipment.shipped_on;
    this.shipped_on_frontend = apiShipment.shipped_on_frontend;
    this.shipping_info = apiShipment.shipping_info;
    this.user_info = apiShipment.user_info;
    this.shipping_address = apiShipment.shipping_address;
    this.notes = apiShipment.notes;
    this.status_mp = apiShipment.status_mp;
    this.lots = apiShipment.lots.map((apiLot) => new LotWithQuantity(apiLot));
  }
}
