import React, { useState } from "react";
import { AlertPopup } from "src/app/modules/Includes/LayoutSide/components/common/alert-popup";
import { useInterval } from "src/app/hooks/useInterval";
import { minutes } from "src/app/utils/time";
import { useMyUser } from "src/app/hooks/useMyUser";
import { path } from "src/app/routes/path";
import { sendCall } from "src/app/config/Utility";
import { addTimezoneOffset } from "src/app/utils/addTimezoneOffset";

// TODO: check issues and enable

export const CheckUserSession = () => {
  const isExpired = useIsUserTokenExpired();

  if (!isExpired) return null;

  return (
    <AlertPopup
      alertPopupMeta={{
        title: "Session Expired",
        subTitle:
          "Your user session has expired, please login again to continue.",
        alertType: "warning",
        onClickAction: () => {
          window.location.href = path.login();
        },
      }}
    />
  );
};

function useIsUserTokenExpired() {
  const user = useMyUser({ maybeNull: true });
  const [isExpired, setIsExpired] = useState(false);

  // check if the session is valid every 5 seconds after expiration date
  useInterval((interval) => {
    if (!user || isExpired) return;
    const expireDate = addTimezoneOffset(user.expire_token);
    const expiresInMs = expireDate.getTime() - Date.now();
    if (expiresInMs > 0) return;
    fetchUserByToken(user.accessToken).then((res) => {
      if (res.code === 200) return;
      clearInterval(interval);
      setIsExpired(true);
    });
  }, 5000);

  // check if the session is valid every 5 minutes
  useInterval((interval) => {
    if (!user || isExpired) return;
    fetchUserByToken(user.accessToken).then((res) => {
      if (res.code === 200) return;
      clearInterval(interval);
      setIsExpired(true);
    });
  }, minutes(5));

  return false;
  // return isExpired;
}

function fetchUserByToken(token) {
  return sendCall("users/GetUserByToken/", "GET", {
    token,
  });
}
