import { useCardCtx } from "../../card-context";
import { isAuctionOfBarrel } from "src/app/models/auctions";
import { Tags } from "../../atoms/tags";
import { IconButtons } from "../../atoms/icon-buttons";
import { CardProps } from ".";
import { Root } from "../atoms/root";
import { Title } from "../atoms/title";
import { Line } from "../atoms/line";
import { Barrel } from "../atoms/barrel";
import { ProductName } from "../atoms/product-name";
import { Details } from "../atoms/details";
import { Emp } from "../atoms/emp";
import { StoreControls } from "../atoms/store-controls";
import { ExclOfTaxes } from "../atoms/excl-taxes";

export function CardForStoreBarrel({
  className = "",
  cardId,
}: Omit<CardProps, "auction">) {
  const cardCtx = useCardCtx();

  if (!isAuctionOfBarrel(cardCtx.auction)) return null;

  return (
    <Root className={className} cardId={cardId}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "4px",
        }}
      >
        <Tags />
        <IconButtons />
      </div>

      <Title />
      <Line />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
        }}
      >
        <Barrel />
        <ProductName />
      </div>

      <Details />

      <Emp />

      <StoreControls />
      <ExclOfTaxes />
    </Root>
  );
}
