import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index-deprecated.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "./css-deprecated/styles/styleAuction.css";
import "./css-deprecated/components.css";
import "./styles/global.css";
import { env } from "./env";
import App from "./app/App";
import ReactGA from "react-ga4";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NowProvider } from "./app/hooks/useCountdown";
import { minutes } from "./app/utils/time";
import { assertLatestVersion } from "./version-control/assert-latest-version";
import { SplashScreenProvider } from "./app/layouts/splash-screen";
import TagManager from "react-gtm-module";

assertLatestVersion();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: true,
      staleTime: 0,
      cacheTime: minutes(10),
    },
  },
});

if (env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
  ReactGA.initialize(env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
    testMode: !env.isProd,
  });
}

if (env.isProd) {
  TagManager.initialize({ gtmId: "GTM-WQ8FZ3F9" });
}

const root = createRoot(document.getElementById("root")!);

root.render(
  <NowProvider>
    <SplashScreenProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </SplashScreenProvider>
  </NowProvider>,
);
