import MySubscriptionTable from "./my-subscription-table";
import SubscriptionForm from "./subscription-form";

function MySubscriptionPage() {
  return (
    <>
      <section style={{ marginBottom: 60 }}>
        <h1>Plans</h1>
        <h2 style={{ fontSize: 16, fontWeight: 400, fontFamily: "Inter" }}>
          Upgrade your subscription plan to unlock benefits
        </h2>
        <SubscriptionForm />
      </section>
      <section>
        <h1 style={{ marginBottom: 30 }}>My Subscription</h1>
        <MySubscriptionTable />
      </section>
    </>
  );
}

export default MySubscriptionPage;
