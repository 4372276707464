import { omit } from "lodash";
import { useAuctionsFilters } from "src/app/api/auctions";
import { SelectMultipleValues } from "src/app/components/form-elements/select-multiple/types";
import { PageName } from "src/app/const";
import { useAuctionFilters } from "src/app/hooks/useAuctionsFilters";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";

export const useFilters = (pageName: PageName) => {
  const [queryParams, setQueryParams] = useTypedSearchParams({
    schema: z.record(
      z.string(),
      z
        .union([
          z.string(), // this is required to also support 'search' and 'sort' which are removed later on
          z.number(), // this is required to restore scroll position to auction card via '?id={collectionID}'
          z.array(z.union([z.string(), z.number(), z.boolean()])),
        ])
        .optional(),
    ),
  });

  // TODO: find a better way to only include actual filter which doesn't imply waiting for the filters query data
  const rawFilters: SelectMultipleValues = omit(queryParams, [
    "search",
    "sort",
    "id",
  ]) as SelectMultipleValues;
  const filtersQuery = useAuctionsFilters(pageName, rawFilters);

  const isLoadingFilters = filtersQuery.isLoading;

  const auctionFilter = useAuctionFilters({
    queryParams: queryParams,
    rawFilters: rawFilters,
    data: filtersQuery.data,
  });

  return {
    rawValues: rawFilters,
    values: auctionFilter.values,
    isLoadingFilters: isLoadingFilters,
    options: auctionFilter.options,
    setFilters: setQueryParams,
    search: queryParams.search as string,
  } as const;
};
