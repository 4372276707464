import styles from "./outstanding-filters.module.css";
import { useState } from "react";
import { useMyUser } from "src/app/hooks/useMyUser";
import { Drawer } from "src/app/components/drawer";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { Button } from "src/app/components/button";
import { OutstandingPaymentV1 } from "src/app/models/OutstandingPaymentV1";
import { usePayOutstandingPaymentsMutation } from "src/app/api/mutations_new_to_rename";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { ID_PAYMENT_METHOD, ID_ROLE } from "src/app/const";
import { DrawerPaymentRequest } from "./drawer-payment-request";
import { useSlot } from "src/app/hooks/useSlot";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
  selectedData: OutstandingPaymentV1[];
  onSettled: () => void;
};

export const OutstandingFilters = ({ selectedData = [], onSettled }: Props) => {
  const user = useMyUser();
  const alertPopup = useAlertPopup();
  const slot = useSlot();
  const queryClient = useQueryClient();

  const paymentMutation = usePayOutstandingPaymentsMutation({
    onSuccess: () => {
      alertPopup.success({
        title: "Auctions were paid",
      });
    },
    onError: (error) => {
      console.log("mutation error", error);
      const errorContent = new Array(error.warnings).map((body: string, i) => {
        return (
          <div
            key={`response-warning-${i}`}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        );
      });
      let errorTitle: any = "Payment not made";
      if (error.code && error.code === 406) {
        errorTitle = "Not all Auctions were paid for";
      }

      alertPopup.error({
        title: errorTitle,
        content: errorContent,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries();
      onSettled();
    },
  });

  const payWithCard = () => {
    if (!selectedData.length) {
      alertPopup.error({
        title: "No row selected",
      });

      return;
    }

    return paymentMutation.mutate({
      total_pay: totalToPay,
      auctions_participant: selectedData,
    });
  };

  const [loadingTransfer, setLoadingTransfer] = useState(false);
  const totalToPay = selectedData.reduce(
    (total, payment) => total + (payment.total_bid - payment.total_pay),
    0,
  );

  // Pay selected auction
  const showPaymentDrawer = () => {
    const isBankPaymentEnabled =
      user.roles === ID_ROLE.CUSTOMER &&
      user.method_payment?.active.id_method_payment === ID_PAYMENT_METHOD.BANK;

    if (isBankPaymentEnabled) {
      // TODO: find out why nesting a drawer-2 instance and an useAlertPopup()
      // breaks mutations loading status (isMutating).
      // Temporary fix fallback to drawer instance
      slot.show((dismiss) => (
        <Drawer
          title="Pay with Bank Transfer"
          position="right"
          width={570}
          onClosed={dismiss}
          render={({ close }) => (
            <DrawerPaymentRequest
              selectedData={selectedData}
              totalToPay={totalToPay}
              onClose={() => {
                onSettled();
                close();
                queryClient.invalidateQueries();
              }}
            />
          )}
        />
      ));
    } else {
      setLoadingTransfer(true);
      const alertText = user.method_payment?.permissions.is_enabled
        ? "You have to change your payment method to pay by bank transfer"
        : "You must apply to enable payments by bank transfer";

      alertPopup.prompt({
        title: "Attention",
        content: alertText,
        actionButton: {
          label: "Yes, change method payment",
          onClick: () =>
            (window.location.href = "/user-profile/method-payments"),
        },
        closeButton: { label: "No, Cancel" },
        onClose: () => {
          setLoadingTransfer(false);
        },
      });
    }
  };

  return (
    <>
      {slot.output}
      <div className={styles.root}>
        <div className={styles.actionButtons}>
          {user.method_payment?.active.id_method_payment ===
            ID_PAYMENT_METHOD.CARD && (
            <Button
              className={styles.button}
              label={
                <span>
                  Pay with card
                  {!!totalToPay && (
                    <span>&nbsp;({formatCurrency(totalToPay)})</span>
                  )}
                </span>
              }
              disabled={!totalToPay}
              isLoading={paymentMutation.isLoading}
              onClick={() => payWithCard()}
            />
          )}

          <Button
            type="button"
            className={styles.button}
            label={
              <span>
                Pay with bank transfer
                {!!totalToPay && (
                  <span>&nbsp;({formatCurrency(totalToPay)})</span>
                )}
              </span>
            }
            disabled={
              !totalToPay || !!loadingTransfer || !!paymentMutation.isLoading
            }
            isLoading={loadingTransfer}
            onClick={() => showPaymentDrawer()}
          />
        </div>
      </div>
    </>
  );
};
