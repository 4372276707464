import { z } from "zod";
import { apiAuctionBaseSchema } from "./AuctionBase";
import {
  ID_AUCTION_TYPE,
  ID_PRODUCT_CATEGORY,
  TYPE_PRODUCT,
} from "src/app/const";
import { Barrel, apiBarrelSchema } from "../Barrel";
import { Experience, apiExperienceSchema } from "../Experience";
import { Bid } from "./Bid";
import { range } from "lodash";
import { formatCurrency } from "src/app/utils/formatCurrency";

export const apiAuctionOfBarrelSchema = apiAuctionBaseSchema.extend({
  type_product: z.literal(TYPE_PRODUCT.BARREL),
  barrel: apiBarrelSchema,
  enable_custom_label_bottle: z.union([z.literal(0), z.literal(1)]),
  experience: apiExperienceSchema.nullable().optional(),
  options: z.object({
    bids: z.array(z.number()),
    quantities: z
      .object({
        min: z.union([z.string(), z.number()]),
        max: z.union([z.string(), z.number()]),
        step: z.union([z.string(), z.number()]),
        mul: z.union([z.string(), z.number()]),
      })
      .transform((qties) => ({
        min: Math.round(+qties.min),
        max: Math.round(Math.max(1, +qties.max)),
        step: +qties.step,
        mul: +qties.mul,
      })),
  }),
  summer_sale: z.boolean(),
  type: z.object({
    id: z.union([
      z.literal(ID_AUCTION_TYPE.PRIVATE_SALE),
      z.literal(ID_AUCTION_TYPE.COLLECTION),
      z.literal(ID_AUCTION_TYPE.PERMANENT),
    ]),
    name: z.string(),
    slug: z.union([
      z.literal("private-sale"), // TODO: remove
      z.literal("private-sales"), // this is used from now on
      z.literal("collections"),
      z.literal("permanent-collections"),
    ]),
  }),
});

export type ApiAuctionOfBarrel = z.infer<typeof apiAuctionOfBarrelSchema>;

export class AuctionOfBarrel {
  barrel: Barrel;
  bets: Bid[];
  bid_percentage: number | null | undefined;
  canBid: boolean;
  canBuyNow: boolean;
  catalogue: string;
  closed: boolean;
  createdDate: string;
  created_data: string;
  crurated_estimated_market_price: number;
  currentPrice: number;
  description: string;
  disabled_for: Array<{ id_customer_role: number }>;
  enable_custom_label_bottle: boolean;
  experience?: Experience | null;
  finish_data: string | null;
  id: number;
  id_auction: number;
  increment_selected: number;
  initial_price: number;
  isArchive: boolean;
  isWishlist: boolean;
  is_charity: boolean;
  is_enabled_tech_fee: boolean;
  is_multi_purchase: boolean;
  is_new: boolean;
  is_no_deposit: boolean;
  is_resell: boolean;
  is_sponsored: boolean;
  lastBidDate: string | null | undefined;
  more_information: string;
  only_for_you: boolean;
  options: ApiAuctionOfBarrel["options"];
  processed: boolean;
  rootCategory: [typeof ID_PRODUCT_CATEGORY.WINE];
  short_description: string;
  stato: boolean;
  summer_sale: boolean;
  tags: string[];
  tech_fee: number;
  whyCantBid: string | null;
  whyCantBuyNow?: string | null;
  type: {
    id:
      | (typeof ID_AUCTION_TYPE)["PRIVATE_SALE"]
      | (typeof ID_AUCTION_TYPE)["COLLECTION"]
      | (typeof ID_AUCTION_TYPE)["PERMANENT"];
    name: string;
    slug: 
      | "private-sale"  // TODO: remove 
      | "private-sales" // this is used from now on
      | "collections" 
      | "permanent-collections";
  };
  type_product: typeof TYPE_PRODUCT.BARREL;
  visible_for: Array<{ id_customer_role: number }>;
  is_buy_now_enabled: boolean | null;
  buy_now_price: number | null;

  constructor(apiAuction: ApiAuctionOfBarrel) {
    this.barrel = new Barrel(apiAuction.barrel);
    this.bets = apiAuction.bets.length
      ? apiAuction.bets.map((bid) => new Bid(bid))
      : [];
    this.bid_percentage = apiAuction.bid_percentage;
    this.canBid = apiAuction.canBid;
    this.canBuyNow = apiAuction.canBuyNow;
    this.catalogue = apiAuction.catalogue ?? "";
    this.closed = !!apiAuction.closed;
    this.createdDate = apiAuction.createdDate ?? apiAuction.created_data;
    this.created_data = apiAuction.created_data;
    this.crurated_estimated_market_price =
      apiAuction.crurated_estimated_market_price;
    this.currentPrice = apiAuction.currentPrice;
    this.description = apiAuction.description ?? "";
    this.disabled_for = parseJSON(apiAuction.disabled_for);
    this.enable_custom_label_bottle = !!apiAuction.enable_custom_label_bottle;
    this.experience = apiAuction.experience
      ? new Experience(apiAuction.experience)
      : null;
    this.finish_data = apiAuction.finish_data;
    this.id = apiAuction.id;
    this.id_auction = apiAuction.id_auction;
    this.increment_selected = apiAuction.increment_selected;
    this.initial_price = apiAuction.initial_price;
    this.isArchive = !!apiAuction.isArchive;
    this.isWishlist = !!apiAuction.isWishlist;
    this.is_charity = apiAuction.is_charity;
    this.is_enabled_tech_fee = apiAuction.is_enabled_tech_fee;
    this.is_multi_purchase = !!apiAuction.is_multi_purchase;
    this.is_new = !!apiAuction.is_new;
    this.is_no_deposit = !!apiAuction.is_no_deposit;
    this.is_resell = !!apiAuction.is_resell;
    this.is_sponsored = apiAuction.is_sponsored;
    this.whyCantBid = apiAuction.whyCantBid ?? "";
    this.lastBidDate = apiAuction.lastBidDate;
    this.more_information = apiAuction.more_information ?? "";
    this.only_for_you = apiAuction.only_for_you;
    this.options = apiAuction.options;
    this.processed = apiAuction.processed;
    this.rootCategory = [ID_PRODUCT_CATEGORY.WINE];
    this.short_description = apiAuction.short_description ?? "";
    this.stato = apiAuction.stato;
    this.summer_sale = apiAuction.summer_sale;
    this.tags = this.only_for_you
      ? ["Only for you", ...apiAuction.tags]
      : apiAuction.tags;
    this.tech_fee = apiAuction.tech_fee;
    this.type = apiAuction.type;
    this.type_product = apiAuction.type_product;
    this.visible_for = parseJSON(apiAuction.visible_for);
    this.is_buy_now_enabled = apiAuction.is_buy_now_enabled;
    this.buy_now_price = apiAuction.buy_now_price;
    this.whyCantBuyNow = apiAuction.whyCantBuyNow;
  }

  get productCategories() {
    return this.rootCategory;
  }

  get hasSpirit() {
    return this.rootCategory.includes(ID_PRODUCT_CATEGORY.SPIRIT);
  }

  get hasExperience() {
    return !!this.experience;
  }

  get currentBiddingOffer() {
    const lastBid = this.bets
      .filter((bid) => bid.type === "bid")
      .sort((a, b) =>
        a.bid_date > b.bid_date ? -1 : a.bid_date === b.bid_date ? 0 : 1,
      )
      .find((bet) => bet.isPending || bet.isWinner);

    return lastBid;
  }

  get isBiddingEnabled() {
    return this.canBid || this.canEdit;
  }

  get isBuyNowEnabled() {
    return this.is_buy_now_enabled && this.buy_now_price;
  }

  get hasPurchasedWithBuyNow() {
    return (
      !!this.is_buy_now_enabled &&
      this.myLastBid?.isWinner &&
      this.myLastBid.type === "buy_now"
    );
  }

  get isForStore() {
    return (
      this.type.id === ID_AUCTION_TYPE.PERMANENT ||
      this.type.id === ID_AUCTION_TYPE.PRIVATE_SALE
    );
  }

  get initialQuantity() {
    if (this.myLastPendingBid) {
      return this.myLastPendingBid.bid_quantity;
    }
    if (this.myWinningBid) {
      return this.myWinningBid.bid_quantity;
    }
    return this.options.quantities.min || 1;
  }

  get quantityOptions() {
    return range(
      this.options.quantities.min,
      this.options.quantities.max + 1,
    ).map((qty) => ({
      value: qty * this.options.quantities.step,
      label: `${
        qty * this.options.quantities.step * (this.options.quantities.mul || 1)
      } L`,
    }));
  }

  get priceOptions() {
    return this.options.bids.map((price) => ({
      value: price,
      label: formatCurrency(price),
    }));
  }

  get initialPrice() {
    if (this.type.id === ID_AUCTION_TYPE.COLLECTION) {
      return this.initial_price;
    } else if (this.myLastPendingBid) {
      return this.myLastPendingBid.bid_offer;
    } else {
      return this.initial_price;
    }
  }

  get myPendingBids() {
    return this.bets.filter((bid) => bid.isPending);
  }

  get myLastPendingBid() {
    if (!this.myPendingBids.length) return null;
    return this.myPendingBids.sort((a, b) =>
      a.bid_date > b.bid_date ? -1 : a.bid_date === b.bid_date ? 0 : 1,
    )[0];
  }

  get myLastBid() {
    if (!this.bets.length) return null;
    return this.bets.sort((a, b) =>
      a.bid_date > b.bid_date ? -1 : a.bid_date === b.bid_date ? 0 : 1,
    )[0];
  }

  get myWinningBid() {
    if (!this.bets.length) return null;
    return this.bets.find((bid) => bid.isWinner);
  }

  get canEdit() {
    return this.bets.some((bid) => bid.type === "bid" && bid.canEdit);
  }
}

function parseJSON(visible_for: string): Array<{ id_customer_role: number }> {
  try {
    return JSON.parse(visible_for, (_, value) => +value);
  } catch {
    return visible_for as any;
  }
}
