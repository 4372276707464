import { Switch } from "react-router-dom";
import { Layout } from "../../layout.public";
import { SentryRoute } from "src/app/routes/sentry-route";
import { path } from "src/app/routes/path";
import { PlanSelection } from "./plan-selection";
import { RegistrationSteps } from "./registration-steps";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "src/app/hooks/useLoadStripe";

const stripePromise = getStripe();

export const PageSignup = () => {
  return (
    <Layout>
      <Elements stripe={stripePromise}>
        <Switch>
          <SentryRoute
            path={path.signup.steps.definition()}
            component={RegistrationSteps}
          />
          <SentryRoute
            path={path.signup.planSelection()}
            component={PlanSelection}
          />
        </Switch>
      </Elements>
    </Layout>
  );
};
