import styles from "./bottle-count.module.css";
import bottleRedSrc from "src/media/wine-red.svg";
import bottleRoseSrc from "src/media/wine-rose.svg";
import bottleWhiteSrc from "src/media/wine-white.svg";
import bottleBrutSrc from "src/media/wine-brut-nature-blanc-de-blancs.svg";
import { isAuctionOfLot } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";
import { ID_AUCTION_TYPE } from "src/app/const";

export function BottleCount({ productIndex }: { productIndex: number }) {
  const cardCtx = useCardCtx();
  const isEnPrimeur = cardCtx.auction.type.id === ID_AUCTION_TYPE.EN_PRIMEUR;
  if (!isAuctionOfLot(cardCtx.auction) || !cardCtx.auction.lot) return null;
  const products = cardCtx.auction.lot.components;
  if (productIndex <= 0 && productIndex > products.length) return null;

  const currentProduct = products[productIndex];

  const textContent = isEnPrimeur
    ? `${currentProduct.size}L`
    : `${currentProduct.quantity} x ${currentProduct.size}L`;

  return (
    <div className={styles.root}>
      <img
        className={styles.img}
        alt="bottle"
        src={
          currentProduct.color === "Red"
            ? bottleRedSrc
            : currentProduct.color === "White"
              ? bottleWhiteSrc
              : currentProduct.color === "Rose"
                ? bottleRoseSrc
                : bottleBrutSrc
        }
      />
      <span className={styles.vintage}>{currentProduct.vintage || "NV"}</span>
      {textContent}
    </div>
  );
}
