import { useMutation, useQueryClient } from "@tanstack/react-query";
import { upfetch } from "src/app/api/upfetch";
import { Button } from "src/app/components/button";
import { Dialog } from "src/app/components/dialog-2";

const ConsentProfilingModal = ({
  userId,
  onClosed,
}: {
  userId: number;
  onClosed: () => void;
}) => {
  const queryClient = useQueryClient();
  const handleUsersResponse = useMutation({
    mutationFn: (value: boolean) => {
      return upfetch({
        url: `users/Customer/${userId}/editConsentProfiling/`,
        method: "POST",
        body: { consent_profiling: value, is_view_consent_profiling: true },
      });
    },
  });

  return (
    <>
      <Dialog
        title="Profiling Terms"
        onClosed={onClosed}
        mandatory
        width={800}
        render={({ startClosing }) => (
          <>
            <p style={{ fontSize: "0.875rem" }}>
              To ensure you have the best experience and customised services, we
              kindly request the following consent: I hereby acknowledge and
              consent to Crurated&apos;s use of Artificial Intelligence (AI)
              technologies to enhance and personalize my experience on the
              platform. This includes the application of AI to analyze my
              activities and interactions, allowing for a customized user
              experience that is uniquely tailored to my preferences and usage
              patterns.
            </p>
            {!!handleUsersResponse.isError && (
              <p style={{ color: "red", fontSize: "0.875rem" }}>
                Unexpected error occured, please try again
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "12px",
                flexWrap: "wrap",
                marginTop: "20px",
              }}
            >
              <Button
                style={{ flexGrow: "1" }}
                label="No"
                disabled={handleUsersResponse.isLoading}
                variant="outlined"
                onClick={() => {
                  handleUsersResponse.mutateAsync(false).then(() => {
                    queryClient.invalidateQueries();
                    startClosing();
                  });
                }}
              />
              <Button
                style={{ flexGrow: "1" }}
                label="Yes"
                disabled={handleUsersResponse.isLoading}
                variant="contained"
                onClick={() => {
                  handleUsersResponse.mutateAsync(true).then(() => {
                    queryClient.invalidateQueries();
                    startClosing();
                  });
                }}
              />
            </div>
          </>
        )}
      />
    </>
  );
};

export default ConsentProfilingModal;
