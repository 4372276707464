import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TypedSearchParams } from "../utils/TypedSearchParams";
import { isEqual, mapKeys } from "lodash";
import { ZodSchema } from "zod";
import { useLatest } from "react-use";

type Updater<T> = T | ((prev: T) => T);
const emptyObject = {};

/**
 * @description Shallow merge
 */
export const useTypedSearchParams = <T extends Record<string, any>>({
  urlPrefix = "",
  schema,
  push = false,
}: {
  urlPrefix?: string;
  schema: ZodSchema<T>;
  push?: boolean;
}) => {
  const schemaRef = useLatest(schema);
  const history = useHistory();
  const location = useLocation();
  const method: keyof typeof history = push ? "push" : "replace";

  const searchParams: T = useMemo(() => {
    try {
      const prefixedParams = new TypedSearchParams<Record<string, any>>(
        location.search,
      ).toObject();
      // remove prefix
      const result = mapKeys<any>(prefixedParams, (_, key) =>
        key.startsWith(urlPrefix) ? key.replace(urlPrefix, "") : key,
      ) as T;
      return schemaRef.current.parse(result);
    } catch (e) {
      console.error("Could not parse the search params", {e});
      history.replace(history.location.pathname); // remove the search from the url
      return emptyObject as T;
    }
  }, [history, location.search, urlPrefix, schemaRef]);

  const setSearchParams = useCallback(
    (updater: Updater<Partial<T>>) => {
      try {
        const current = new TypedSearchParams<Record<string, any>>(
          history.location.search,
        );
        const value =
          typeof updater === "function"
            ? updater(current.toObject() as Partial<T>)
            : updater;
        if (isEqual(current.toObject(), value)) return;
        history[method]({
          pathname: history.location.pathname,
          search: current
            .update(mapKeys(value, (_, key) => urlPrefix + key)) // apply prefix
            .toString(),
        });
      } catch (e) {
        // Clear search params
        console.error("Could not set the search params", e);
        !!history.location.search && history.replace(history.location.pathname);
      }
    },
    [history, urlPrefix, method],
  );

  return [searchParams, setSearchParams] as const;
};
