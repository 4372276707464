import { sortBy } from "lodash";
import { Tooltip } from "src/app/components/tooltip";

export const CellContentIcons = ({ experience, minIconsPerLine }) => {
  const attributes = sortBy(experience.experience.attributes, "attribute");
  const groupedBy3 = attributes.reduce(
    (acc, attr) => {
      const lastGroup = acc[acc.length - 1];
      if (lastGroup.length < minIconsPerLine) {
        lastGroup.push(attr);
      } else {
        acc.push([attr]);
      }
      return acc;
    },
    [[]],
  );

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
      {groupedBy3.map((groupOf3, groupIndex) => (
        <div
          key={`${experience.id}-${groupIndex}`}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: "4px",
          }}
        >
          {groupOf3.map((attribute) => (
            <Icon
              key={`${experience.id}-${experience.experience.id}-${attribute.id}`}
              attribute={attribute}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

function Icon({ attribute }) {
  return (
    <Tooltip position="bottom" content={attribute.attribute}>
      <img
        style={{
          width: "30px",
          backgroundColor: "#5f302f",
          padding: "5px",
          borderRadius: "50%",
          height: "30px",
          objectFit: "contain",
        }}
        src={attribute.icon}
        alt={attribute.attribute}
      />
    </Tooltip>
  );
}
