import { z } from "zod";

export const serialProductSchemaV1 = z.object({
  id: z.number(),
  id_serial_product: z.number(),
  id_serial_product_mp: z.string(),
  serial_date: z.string().nullable(),
  deposit: z.string().nullable(),
  serial: z.string(),
  deleted: z.boolean(),
  IDSeriallot: z.number(),
  product: z.object({
    nameProductComplete: z.string(),
  }),
  history: z.array(
    z.object({
      label: z.string(),
      value: z.string().nullable(),
    }),
  ),
  NFTInfo: z.object({
    provenance: z.string(),
    token: z.string().nullable(),
    contract: z.string(),
    transfers: z.number(),
  }),
});

export type SerialProductV1 = z.infer<typeof serialProductSchemaV1>;
