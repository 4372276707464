import styles from "./index.module.css";
import { useHistory } from "react-router";
import { useMount } from "react-use";
import { useActivateAccountMutation, useLoginMutation } from "src/app/api/auth";
import { STORAGE_KEY } from "src/app/const";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import logoSrc from "src/media/logos/logo-default.gif";
import { Img } from "src/app/components/img";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Spacer } from "src/app/components/spacer";
import { cn } from "src/app/utils/cn";
import { CruForm } from "src/app/components/cru-form";
import { Input } from "src/app/components/form-elements/input";
import { validate } from "src/app/utils/validate";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";
import { Button } from "src/app/components/button";

export const LoginPage = () => {
  const isMobile = useIsMobile();
  const history = useHistory<{ from?: Location } | undefined>();

  const [searchParams] = useTypedSearchParams({
    schema: z.object({
      code: z.union([z.number(), z.string()]).optional(),
      person: z.union([z.number(), z.string()]).optional(),
      redirectTo: z.string().optional(),
    }),
  });

  const activateAccountMutation = useActivateAccountMutation();

  useMount(() => {
    if (!searchParams.code || !searchParams.person) return;
    activateAccountMutation.mutate({
      code: searchParams.code,
      person: searchParams.person,
    });
  });

  const loginMutation = useLoginMutation({
    onSuccess(token) {
      localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, token);
      if (searchParams.redirectTo) {
        history.replace(decodeURIComponent(searchParams.redirectTo));
        return;
      }
      if (
        history.location.state?.from &&
        !history.location.state.from.pathname.includes("/auth")
      ) {
        history.replace(history.location.state.from);
        return;
      }
      history.push("/");
    },
  });

  return (
    <section className={styles.root}>
      {!!isMobile && (
        <div>
          <Img className={styles.logo} src={logoSrc} alt="Crurated" />
          <Spacer size={30} />
        </div>
      )}
      <h2 className={styles.title}>Log in to your account</h2>
      <Spacer size={8} />
      <p className={styles.greeting}>Welcome back!</p>
      <Spacer size={22} />

      {!!activateAccountMutation.isSuccess && (
        <div>
          <div className={cn(styles.alert, styles.success)} role="alert">
            Your Account is active, please login
          </div>
          <Spacer size={22} />
        </div>
      )}

      {!!activateAccountMutation.isError && (
        <div>
          <div className={cn(styles.alert, styles.error)} role="alert">
            User not found
          </div>
          <Spacer size={22} />
        </div>
      )}

      {!!loginMutation.isError && (
        <div>
          <div className={cn(styles.alert, styles.error)} role="alert">
            {loginMutation.error}
          </div>
          <Spacer size={22} />
        </div>
      )}

      <CruForm.Form
        onSubmit={(values) => loginMutation.mutateAsync(values)}
        initialValues={{ email: "", password: "" }}
        className={styles.form}
      >
        {(cruForm) => (
          <div>
            <CruForm.Field
              cruForm={cruForm}
              placeholder="Email"
              name="email"
              type="email"
              component={Input}
              className={styles.input}
              onChange={(e) =>
                cruForm.setValues({ email: e.currentTarget.value })
              }
              onBlur={() => cruForm.setTouched({ email: true })}
              validate={validate.email}
            />
            <CruForm.ErrorMessage
              cruForm={cruForm}
              name="email"
              className={styles.error}
            />

            <Spacer size={28} />

            <CruForm.Field
              cruForm={cruForm}
              placeholder="Password"
              name="password"
              type="password"
              component={Input}
              onChange={(e) =>
                cruForm.setValues({ password: e.currentTarget.value })
              }
              onBlur={() => cruForm.setTouched({ password: true })}
              className={styles.input}
            />
            <CruForm.ErrorMessage
              cruForm={cruForm}
              name="password"
              className={styles.error}
            />

            <Spacer size={28} />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <A to={path.forgotPassword()} className={styles.link}>
                Forgot password?
              </A>
            </div>

            <Spacer size={17} />

            <Button
              className={styles.submit}
              type="submit"
              label="Continue with email"
              isLoading={cruForm.submission.isLoading}
            />
          </div>
        )}
      </CruForm.Form>

      <Spacer size={26} />
      <div className={styles.or}>OR</div>
      <Spacer size={20} />

      <p style={{ textAlign: "center" }}>
        {"Don't have an account? "}
        <A to={path.signup.planSelection()} className={styles.link}>
          Sign up here
        </A>
      </p>
    </section>
  );
};
