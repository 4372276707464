import { useQueryClient } from "@tanstack/react-query";
import styles from "./controls-address.module.css";
import { FaClock, FaPencilAlt, FaRegTrashAlt } from "react-icons/fa";
import { IoCheckbox } from "react-icons/io5";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { Button } from "src/app/components/button";
import { AddressV1 } from "src/app/models/Address";
import { useDeleteAddressMutation } from "src/app/api/adresses_to_rename_later";
import { Tooltip } from "src/app/components/tooltip";
import { QKEY } from "src/app/api/QKEY";
import { useMyUser } from "src/app/hooks/useMyUser";

export const ControlsAddress = ({
  dataAddress,
  typeAddress,
  onClickEdit,
}: {
  dataAddress: AddressV1;
  typeAddress: "invoice" | "shipping";
  onClickEdit: (address: AddressV1) => void;
}) => {
  const alertPopup = useAlertPopup();

  const user = useMyUser();

  const queryClient = useQueryClient();

  const deleteAddressMutation = useDeleteAddressMutation({
    onSuccess() {
      alertPopup.success({
        title: "Address deleted",
      });
      queryClient.invalidateQueries({ queryKey: [QKEY.ME] });
    },
    onError() {
      alertPopup.error({
        title: "Address not deleted",
      });
      queryClient.invalidateQueries({ queryKey: [QKEY.ME] });
    },
  });

  return (
    <div className={styles.controls}>
      {!!dataAddress.active && !!dataAddress.confirmed ? (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Tooltip content="Verified">
            <div>
              <IoCheckbox color="green" size={25} />
            </div>
          </Tooltip>
          <Button
            label=""
            icon={<FaPencilAlt />}
            className={styles.button}
            onClick={(e) => {
              e.stopPropagation();
              onClickEdit(dataAddress);
            }}
          />
        </div>
      ) : (
        <Tooltip position="top" content="Pending verification">
          <div>
            <FaClock size={20} color="orange" />
          </div>
        </Tooltip>
      )}
      {typeAddress !== "invoice" &&
        user.addressShipping.length > 1 &&
        !dataAddress.selected && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              alertPopup.prompt({
                title: "Are you sure to delete this address?",
                actionButton: {
                  label: "Yes",
                  onClick: () =>
                    deleteAddressMutation.mutate({
                      id_address: dataAddress.id_address,
                    }),
                },
                closeButton: {
                  label: "No",
                },
              });
            }}
            className={styles.button}
            label=""
            icon={<FaRegTrashAlt />}
          />
        )}
    </div>
  );
};
