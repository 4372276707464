import { z } from "zod";
import { Builder, apiBuilderSchema } from "./Builder";
import { Country, apiCountrySchema } from "./Country";

export const apiExperienceSchema = z.object({
  id: z.number(),
  title: z.string(),
  id_supplier: z.number(),
  short_description: z.string(),
  long_description: z.string().nullable(),
  link_video: z.string().nullable(),
  sku: z.string(),
  estimated_price: z.number().or(z.string()),
  attributes: z.array(
    z.object({
      id: z.number(),
      id_event: z.number(),
      attribute: z.string(),
      created_at: z.string(),
      updated_at: z.string().nullable(),
      icon: z.string(),
    }),
  ),
  images: z.array(
    z.object({
      id: z.number(),
      id_event: z.number(),
      image: z.string(),
      created_at: z.string(),
      updated_at: z.string().nullable(),
    }),
  ),
  location: z.string(),
  num_max_tickets: z.number(),
  expiration_date: z.string().nullable(),
  start_experience_date: z.string().nullable(),
  end_experience_date: z.string().nullable(),
  type_date: z.number(),
  address: z.string(),
  contact_name: z.string().nullable(),
  contact_email: z.string().nullable(),
  contact_phone: z.string().nullable(),
  builder: apiBuilderSchema,
  country: apiCountrySchema,
});

export type ApiExperience = z.infer<typeof apiExperienceSchema>;

export class Experience {
  id: number;
  title: string;
  id_supplier: number;
  short_description: string;
  long_description: string | null;
  link_video: string | null;
  sku: string;
  estimated_price: number;
  attributes: Array<{
    id: number;
    id_event: number;
    attribute: string;
    created_at: string;
    updated_at: string | null;
    icon: string;
  }>;
  images: Array<{
    id: number;
    id_event: number;
    image: string;
    created_at: string;
    updated_at: string | null;
  }>;
  location: string;
  num_max_tickets: number;
  expiration_date: string | null;
  start_experience_date: string | null;
  end_experience_date: string | null;
  type_date: number;
  address: string;
  contact_name: string | null;
  contact_email: string | null;
  contact_phone: string | null;
  builder: Builder;
  country: Country;

  constructor(apiExperience: ApiExperience) {
    this.id = apiExperience.id;
    this.title = apiExperience.title;
    this.id_supplier = apiExperience.id_supplier;
    this.short_description = apiExperience.short_description;
    this.long_description = apiExperience.long_description;
    this.link_video = apiExperience.link_video;
    this.sku = apiExperience.sku;
    this.estimated_price = +apiExperience.estimated_price;
    this.attributes = apiExperience.attributes;
    this.images = apiExperience.images;
    this.location = apiExperience.location;
    this.num_max_tickets = apiExperience.num_max_tickets;
    this.expiration_date = apiExperience.expiration_date;
    this.start_experience_date = apiExperience.start_experience_date;
    this.end_experience_date = apiExperience.end_experience_date;
    this.type_date = apiExperience.type_date;
    this.address = apiExperience.address;
    this.contact_name = apiExperience.contact_name;
    this.contact_email = apiExperience.contact_email;
    this.contact_phone = apiExperience.contact_phone;
    this.builder = new Builder(apiExperience.builder);
    this.country = new Country(apiExperience.country);
  }
}
