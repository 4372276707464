import iconSrc from "src/media/logos/crurated-icon.png";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { LoginPage } from "../pages/auth.login";
import { path } from "./path";
import { PageImpersonate } from "../pages/auth.impersonate";
import { ErrorBoundary } from "../components/error-boundary";
import { SentryRoute } from "./sentry-route";
import { CookieBanner } from "../components/cookie-banner";
import { LogoutPage } from "../pages/auth.logout";
import ForgotPassword from "../pages/auth.forgot-password";
import ResetPassword from "../pages/auth.reset-password";

export function AuthRoutes() {
  const location = useLocation();
  return (
    <AuthLayout>
      <ErrorBoundary>
        <Switch>
          <SentryRoute path={path.login()} component={LoginPage} />
          <SentryRoute path="/auth/impersonate" component={PageImpersonate} />
          <SentryRoute
            path="/auth/forgot-password"
            component={ForgotPassword}
          />
          <SentryRoute path="/auth/reset-password" component={ResetPassword} />
          <SentryRoute path={path.logout()} component={LogoutPage} exact />
          <SentryRoute>
            <Redirect
              to={{ pathname: path.login(), state: { from: location } }}
            />
          </SentryRoute>
        </Switch>
      </ErrorBoundary>
    </AuthLayout>
  );
}

function AuthLayout({ children }) {
  return (
    <div className="d-flex flex-column flex-root">
      <CookieBanner />
      {/*begin::Login*/}
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid login-screen-main"
        id="kt_login"
      >
        {/*begin::Aside*/}
        <div
          className="login-aside"
          style={{ backgroundColor: "rgb(100 54 56)" }}
        >
          {/*begin: Aside Container*/}
          <div className="login-aside-inner">
            {/* start:: Aside header */}
            <a href={window.location.origin} className="login-logo">
              <img alt="Logo" className="img-responsive" src={iconSrc} />
            </a>
            {/* end:: Aside header */}

            {/* start:: Aside content */}
            <div className="aside-content">
              <h3>Welcome to Crurated</h3>
              <p style={{ padding: "0 16px" }}>
                Sign up today and unlock access to a world of exclusive wines &
                spirits selections handpicked for connoisseurs and enthusiasts
                alike.
              </p>
            </div>
            {/* end:: Aside content */}
          </div>
          {/*end: Aside Container*/}
        </div>
        {/*begin::Aside*/}

        {/*begin::Content*/}
        <div className="flex-row-fluid d-flex flex-column login-body">
          <div className="d-flex flex-column-fluid flex-center login-body-inner">
            {children}
          </div>
          {/*end::Content body*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Login*/}
    </div>
  );
}
