/* eslint-disable no-restricted-imports */
import styles from "./my-address-row.module.css";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AddressV1 } from "src/app/models/Address";
import { RadioGroup } from "@headlessui/react";
import { AddressInfo } from "./address-info";
import { ControlsAddress } from "./controls-address";
import { useSelectAddressMutation } from "src/app/api/adresses_to_rename_later";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { useSlot } from "src/app/hooks/useSlot";
import { Drawer } from "src/app/components/drawer-2";
import { AddressForm } from "src/app/components/address-form/address-form";

export function MyAddressRow({
  dataAddress,
  typeAddress,
}: {
  dataAddress: AddressV1;
  typeAddress: "shipping" | "invoice";
}) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const alertPopup = useAlertPopup();

  const slot = useSlot();

  const selectAddressMutation = useSelectAddressMutation({
    onSuccess() {
      setLoading(true);
      queryClient.invalidateQueries().then(() => setLoading(false));
    },
    onError() {
      setLoading(true);
      queryClient
        .invalidateQueries()
        .then(() => setLoading(false))
        .then(() =>
          alertPopup.error({
            title: "Internal Error, please try again",
          }),
        );
    },
  });

  return (
    <>
      {slot.output}
      <RadioGroup
        value={dataAddress}
        onChange={() => {
          if (dataAddress.selected) return;
          selectAddressMutation.mutate({
            id_address: dataAddress.id_address,
            type_address: typeAddress,
          });
        }}
      >
        <RadioGroup.Option
          value={dataAddress.id_address}
          disabled={!dataAddress}
        >
          <AddressInfo
            loading={loading}
            dataAddress={dataAddress}
            controls={
              <ControlsAddress
                onClickEdit={(address) =>
                  slot.show((dismiss) => (
                    <Drawer
                      title={`Edit address for ${address.type}`}
                      position="right"
                      width={570}
                      onClosed={() => {
                        dismiss();
                      }}
                      render={({ startClosing }) => (
                        <AddressForm
                          onSuccess={() => startClosing()}
                          onCancel={() => startClosing()}
                          addressType={address.type}
                          initialAddress={address}
                        />
                      )}
                    />
                  ))
                }
                dataAddress={dataAddress}
                typeAddress={typeAddress}
              />
            }
          />
          <div className={styles.line} />
        </RadioGroup.Option>
      </RadioGroup>
    </>
  );
}
