import { AvailableDate } from "./AvailableDate";
import { Badge } from "./Badge";
import { BarrelInput } from "./BarrelInput";
import { BidControls } from "./BidControls";
import { BidControlsCharity } from "./BidControlsCharity";
import { BidLiner } from "./BidLiner";
import { BottleCount } from "./BottleCount";
import { BottlingDate } from "./BottlingDate";
import { Button } from "./Button";
import { ButtonCharity } from "./ButtonCharity";
import { CatalogueLink } from "./CatalogueLink";
import { Emp } from "./Emp";
import { EmpCharity } from "./EmpCharity";
import { GridMd } from "./GridMd";
import { Head } from "./Head";
import { HistoryButton } from "./HistoryButton";
import { HistoryPanel } from "./HistoryPanel";
import { KnowMore } from "./KnowMore";
import { ViewDetails } from "./view-details";
import { Link } from "./Link";
import { ProductList } from "./ProductList";
import { Root } from "./Root";
import { SkeletonMd } from "./SkeletonMd";
import { Subtitle } from "./Subtitle";
import { TabsForBarrels } from "./TabsForBarrels";
import { Title } from "./Title";
import { Warning } from "./Warning";
import { Countdown } from "../countdown";

/**
 * @deprecated
 */
export const CardForAuction = {
  AvailableDate,
  Badge,
  BarrelInput,
  BidControls,
  BidControlsCharity,
  BidLiner,
  BottleCount,
  BottlingDate,
  Button,
  ButtonCharity,
  CatalogueLink,
  Countdown,
  Emp,
  EmpCharity,
  GridMd,
  Head,
  HistoryButton,
  HistoryPanel,
  KnowMore,
  ViewDetails,
  Link,
  ProductList,
  Root,
  SkeletonMd,
  Subtitle,
  TabsForBarrels,
  Title,
  Warning,
};
