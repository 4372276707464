type Listener<Message> = (msg: Message) => any;

export class Emitter<Message> {
  #listeners = new Set<Listener<Message>>();
  #lastMsg?: Message;
  #msgCount = 0;

  addListener(listener: Listener<Message>) {
    this.#listeners.add(listener);
    return () => this.removeListener(listener);
  }

  removeListener(listener: Listener<Message>) {
    this.#listeners.delete(listener);
  }

  emit(msg: Message) {
    this.#lastMsg = msg;
    this.#msgCount++;
    this.#listeners.forEach((listener) => listener(msg));
  }

  get lastMsg() {
    return this.#lastMsg;
  }

  get msgCount() {
    return this.#msgCount;
  }

  get size() {
    return this.#listeners.size;
  }

  reset() {
    this.#lastMsg = undefined;
    this.#msgCount = 0;
    this.#listeners = new Set();
  }
}
