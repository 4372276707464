import { ButtonShare } from "./button-share";
import { useCardCtx } from "../card-context";
import { isAuctionOfLot } from "src/app/models/auctions";
import { ButtonToggleProductList } from "../md/atoms/button-toggle-product-list";
import { ButtonArchive } from "../md/atoms/button-archive";
import { ButtonWishlist } from "../md/atoms/button-wishlist";
import { ID_AUCTION_TYPE } from "src/app/const";

type Props = {
  className?: string;
  style?: object;
};

export const IconButtons = ({ className = "", style = {} }: Props) => {
  const cardCtx = useCardCtx();
  const isEnprimeur = cardCtx.auction.type.id === ID_AUCTION_TYPE.EN_PRIMEUR;
  const showButtonToggleProductList =
    isAuctionOfLot(cardCtx.auction) && !!cardCtx.auction.lot?.components.length;

  return (
    <div
      className={className}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "baseline",
        gap: "8px",
        ...style,
      }}
    >
      {!!showButtonToggleProductList && <ButtonToggleProductList />}
      {!!cardCtx.auction.isForStore && !isEnprimeur && <ButtonArchive />}
      {!!cardCtx.auction.isForStore && (
        <ButtonWishlist style={{ marginRight: "-2px" }} />
      )}
      <ButtonShare />
    </div>
  );
};
