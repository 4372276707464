import useResizeObserver from "use-resize-observer";
import styles from "./root.module.css";
import { ReactNode } from "react";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";
import { useCardCtx } from "../../card-context";

type RootProps = {
  className?: string;
  style?: object;
  children: ReactNode;
  cardId: string;
};

export const Root = ({
  className = "",
  style = {},
  children,
  cardId,
}: RootProps) => {
  const cardCtx = useCardCtx();
  const isMobile = useIsMobile();
  const { ref: innerRef, width, height } = useResizeObserver();

  return (
    <div
      className={cn(styles.root, isMobile && styles.mobile, className)}
      style={style}
      role="listitem"
      ref={cardCtx.outerMostRef}
      id={cardId}
    >
      {!cardCtx.inView && !!width && !!height ? (
        <div style={{ width, height }} />
      ) : (
        <div ref={innerRef} className={styles.inner}>
          {children}
        </div>
      )}
    </div>
  );
};
