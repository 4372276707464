import React from "react";
import styles from "./Head.module.css";

export const Head = ({ children, className = "", style = {} }) => {
  return (
    <div className={`${styles.root} ${className}`} style={style}>
      {children}
    </div>
  );
};
