import { useEffect, useState } from "react";
import { FaCheck, FaClipboard } from "react-icons/fa";
import { Button } from "src/app/components/button";

export const ButtonCopyToClipboard = ({
  text,
}: {
  text: string | number | undefined;
}) => {
  const [isDone, setDone] = useState(false);

  const handlingCopy = () => {
    navigator.clipboard.writeText(String(text)).then(() => setDone(true));
  };

  useEffect(() => {
    if (!isDone) return;

    const timer = setTimeout(() => {
      setDone(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isDone]);

  return (
    <Button
      icon={isDone ? <FaCheck color="" /> : <FaClipboard color="inherit" />}
      onClick={() => handlingCopy()}
      tooltip={isDone ? "Copied!" : "Copy to Clip Board"}
      disabled={!text}
      size={"small"}
      variant={"ghost"}
      style={{ height: "auto", color: "var(--color-primary)" }}
    />
  );
};
