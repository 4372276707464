import styles from "./cell-content-emp.module.css";
import { MobileCellContentCurrency } from "./cell-content-currency";

type Props = {
  emp: number;
  ratio?: number;
};

export const MobileCellContentEmp = ({ emp, ratio }: Props) => {
  return (
    <MobileCellContentCurrency
      amount={emp}
      rightElement={
        typeof ratio === "number" && (
          <span
            className={`${styles.root} ${ratio > 0 ? styles.up : styles.down}`}
          >
            <div>
              <div className={styles.arrow} />
              <div className={styles.number}>{ratio.toFixed(0)}%</div>
            </div>
          </span>
        )
      }
    />
  );
};
