import styles from "./index.module.css";
import { NavLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useIsMobile } from "../../hooks/useIsMobile";
import { cn } from "../../utils/cn";
import { Select } from "../form-elements/select";

type Props = {
  links: Array<{
    to: string;
    label: string;
    count?: string | number;
  }>;
};

export function NavLinks(props: Props) {
  const isMobile = useIsMobile();

  return isMobile ? (
    <MobileNavLinks {...props} />
  ) : (
    <DesktopNavLinks {...props} />
  );
}

const DesktopNavLinks = ({ links = [] }: Props) => {
  return (
    <ul className={`${styles.ul}`}>
      {links.filter(Boolean).map(({ to, label, count }) => (
        <li key={`link-${to}`}>
          <NavLink
            to={to}
            activeClassName={styles.active}
            className={styles.link}
          >
            {label}
            {!!count && (
              <strong className={styles.count}>&nbsp;({count})</strong>
            )}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

const MobileNavLinks = ({ links = [] }: Props) => {
  const history = useHistory();
  const location = useLocation();

  // Determine the source of navigation items. Use 'links' if it has items, otherwise fall back to 'button'.
  const options = links.filter(Boolean).map(({ to, label, count }, i) => ({
    label,
    value: to || label.replace(/\s+/g, "") || `navlink-${i}`,
    count,
    onClick: () => history.push(to),
  }));

  const selectedOption = options.find(
    (opt) => location.pathname === opt.value.split("?")[0],
  );

  const handleChange = (option: (typeof options)[number]) => {
    option.onClick();
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <Select
      className={styles.select}
      selectedOption={selectedOption}
      options={options}
      onChange={handleChange}
      renderOption={({ option, active }) => (
        <div className={cn(styles.option, active && styles.active)}>
          <div>{option.label || option.value}</div>
          {!!option.count && <div>{`(${option.count})`}</div>}
        </div>
      )}
    />
  );
};
