import styles from "./shipment-form.module.css";
import { useMyUser } from "src/app/hooks/useMyUser";
import { ApiLotV1 } from "src/app/models/LotV1";
import { AddressV1 } from "src/app/models/Address";
import { UseMutateAsyncFunction, useQueryClient } from "@tanstack/react-query";
import { CruForm } from "src/app/components/cru-form-3";
import { Button } from "src/app/components/button";
import { useSlot } from "src/app/hooks/useSlot";
import { parseApiErrorV1 } from "src/app/api/_parse-api-error";
import { FaExclamationCircle } from "react-icons/fa";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { Spacer } from "src/app/components/spacer";
import { useState } from "react";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";
import { FaBullhorn } from "react-icons/fa";
import { HtmlOutput } from "../html-output";
import { FormFieldName } from "../cru-form-3/types";
import { RadioGroupAddress } from "./radiogroup-address";
import { DisplaySelectedAddress } from "./display-selected-address";
import { AddressForm } from "../address-form/address-form";
import { Dialog } from "../dialog-2";

export type ShipmentFormValues = {
  lots: ApiLotV1[];
  selectedAddress?: AddressV1;
};

type Props = {
  selectedLots: ApiLotV1[];
  mutateAsync: UseMutateAsyncFunction<any, any, ShipmentFormValues>;
  onCancel: () => void;
  onError: () => void;
  onSuccess: () => void;
  submitButtonText: string;
};

export function ShipmentForm({
  selectedLots,
  mutateAsync,
  onCancel,
  onError,
  onSuccess,
  submitButtonText,
}: Props) {
  const user = useMyUser();
  const queryClient = useQueryClient();
  const slot = useSlot();
  const alertPopup = useAlertPopup();
  const [showAddressSelection, setShowAddressSelection] = useState(false);
  const config = useSystemConfig();
  const pickupAddress = config?.pickup_address;

  const initialSelectedAddress =
    user.addressShipping.find((addr) => addr.selected) ||
    user.addressShipping.at(0);

  const onCreateOrEdit = (address?: AddressV1) => {
    slot.show((dismiss) => (
      <Dialog
        title={`${address ? "Edit" : "Add"} Address for shipping`}
        onClosed={dismiss}
        render={({ startClosing }) => (
          <AddressForm
            addressType="shipping"
            onSuccess={startClosing}
            onError={startClosing}
            onCancel={startClosing}
            initialAddress={address}
          />
        )}
      />
    ));
  };

  return (
    <div className={styles.root}>
      {slot.output}

      <CruForm.Form
        initialValues={{
          lots: selectedLots,
          selectedAddress: initialSelectedAddress,
          totalQuantity: selectedLots.reduce(
            (total, lot) => total + lot.quantity_chosen,
            0,
          ),
        }}
        onSubmit={(values) =>
          mutateAsync(values)
            .then(() => {
              queryClient.invalidateQueries();
              onSuccess();
            })
            .catch(() => {
              queryClient.invalidateQueries();
              alertPopup.error({
                title: "Failed to request Shipping",
                onClose: () => void onError(),
              });
            })
        }
      >
        {(ctx) => (
          <>
            <h3>Lots</h3>
            <Spacer size={8} />

            <div className={styles.gridOfLots}>
              {ctx.values.lots.map((lot, index) => (
                <CruForm.Field
                  key={`lots.${lot.id}`}
                  ctx={ctx}
                  name={`lots.${index}`}
                  validate={(value: ApiLotV1) => {
                    const isQuantityValid =
                      value.quantity_chosen >= 0 &&
                      lot.quantity_chosen <= lot.serials.length;

                    return !isQuantityValid && "Invalid quantity";
                  }}
                >
                  {(props) => (
                    <>
                      <p>{lot.case_}</p>

                      <div>
                        <input
                          {...props}
                          type="number"
                          placeholder="Quantity to be shipped"
                          value={ctx.getFieldValue(props.name).quantity_chosen}
                          min={0}
                          max={lot.serials.length}
                          className={styles.inputLotQuantity}
                          onChange={(e) => {
                            const lot = ctx.getFieldValue(props.name);
                            lot.quantity_chosen = +e.target.value;
                            ctx.setFieldsValues({
                              [props.name]: lot as any,
                              // works fine because "lot" is mutated
                              totalQuantity: ctx.values.lots.reduce(
                                (total, lot) => total + lot.quantity_chosen,
                                0,
                              ),
                            });
                            ctx.setFieldsTouched({ totalQuantity: true });
                          }}
                          onBlur={() =>
                            ctx.setFieldsTouched({ [props.name]: true })
                          }
                        />
                        <Spacer size={8} inline />

                        <CruForm.Description as="span">
                          ({lot.serials.length} Available)
                        </CruForm.Description>
                      </div>

                      <CruForm.ErrorMessage className={styles.quantityError} />
                    </>
                  )}
                </CruForm.Field>
              ))}
            </div>

            <CruForm.Field
              ctx={ctx}
              name="totalQuantity"
              validate={(totalQuantity) =>
                totalQuantity <= 0 && "Please select at least one lot"
              }
            >
              {(props) => (
                <div>
                  <div className="sr-only">
                    <span>Total lots:&nbsp;</span>
                    <HtmlOutput
                      forInputNames={ctx.values.lots.map(
                        (_, i) =>
                          `lots.${i}` satisfies FormFieldName<ShipmentFormValues>,
                      )}
                      {...props}
                    >
                      {ctx.getFieldValue(props.name)}&nbsp;
                    </HtmlOutput>
                  </div>
                  <CruForm.ErrorMessage />
                </div>
              )}
            </CruForm.Field>

            <Spacer size={40} />

            <div>
              <h3>Shipping Address</h3>
              <Spacer size={16} />

              {!!ctx.getFieldValue("selectedAddress") &&
                !showAddressSelection && (
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "start",
                      flexWrap: "wrap",
                    }}
                  >
                    <DisplaySelectedAddress
                      address={ctx.getFieldValue("selectedAddress")!}
                    />
                    <Button
                      label="Change Address"
                      type="button"
                      onClick={() => setShowAddressSelection(true)}
                      disabled={ctx.submission.isLoading}
                    />
                  </div>
                )}

              {(!!showAddressSelection ||
                !ctx.getFieldValue("selectedAddress")) && (
                <CruForm.Field
                  ctx={ctx}
                  name="selectedAddress"
                  validate={(value) => !value?.id_address && "Invalid address"}
                >
                  {(props) => (
                    <>
                      <RadioGroupAddress
                        {...props}
                        onChange={(address) => {
                          ctx.setFieldsTouched({ [props.name]: true });
                          ctx.setFieldsValues({ [props.name]: address });
                        }}
                        selectedAddress={ctx.getFieldValue(props.name)}
                        initialSelectedAddress={initialSelectedAddress}
                        onCreate={onCreateOrEdit}
                        onEdit={onCreateOrEdit}
                        onConfirm={() => setShowAddressSelection(false)}
                      />

                      <CruForm.ErrorMessage />
                    </>
                  )}
                </CruForm.Field>
              )}
              {!showAddressSelection &&
                !!pickupAddress &&
                pickupAddress.id_address ===
                  ctx.getFieldValue("selectedAddress")?.id_address && (
                  <div>
                    <Spacer size={16} />
                    <div className={styles.alertMessage} role="alert">
                      <FaBullhorn size={24} />
                      <div>
                        <h6>Pickup at our warehouse</h6>
                        <p>
                          Please note this option is pick up by the final client
                          only.
                        </p>
                        <p>
                          If your transporter is picking up the goods, you must
                          enter the final destination of goods in the shipping
                          address!
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>

            <Spacer size={18} />
            <hr />
            <Spacer size={18} />
            <div className={styles.actions}>
              <Button
                variant="contained"
                type="submit"
                isLoading={ctx.submission.isLoading}
                label={submitButtonText}
                disabled={showAddressSelection}
              />
              <Spacer inline size={12} />
              <Button
                type="button"
                onClick={onCancel}
                disabled={ctx.submission.isLoading || showAddressSelection}
                variant="outlined"
                label="Close"
              />
            </div>

            {!!ctx.submission.error && (
              <div className={styles.errorMessage}>
                <FaExclamationCircle size={24} />
                <div>{parseApiErrorV1(ctx.submission.error)}</div>
              </div>
            )}
          </>
        )}
      </CruForm.Form>
    </div>
  );
}
