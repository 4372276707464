import { noop } from "lodash";
import styles from "./select.module.css";
import { ReactNode } from "react";

type BaseOption = {
  value: string | number;
  label: string;
};

type Props<TOption extends BaseOption> = {
  value: TOption["value"];
  label: string;
  icon?: ReactNode;
  options: TOption[];
  disabled: boolean;
  onChange?: (value: TOption["value"]) => void;
};

export const Select = <TOption extends BaseOption>({
  value,
  label,
  options = [],
  disabled,
  icon,
  onChange = noop,
}: Props<TOption>) => {
  const isDisabled = disabled || options.length <= 1;
  const selectId = label.replace(/\s+/g, "-") + "-select";

  return (
    <div className={styles.root} aria-disabled={isDisabled ? "true" : "false"}>
      {!!label && (
        <label htmlFor={selectId} className={styles.label}>
          {label}
          {icon}
        </label>
      )}
      <select
        id={selectId}
        value={value}
        onChange={(e) => onChange(+e.target.value)}
        disabled={isDisabled}
      >
        {options.map((option, index) => (
          <option key={`${selectId}-option-${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
