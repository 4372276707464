import React from "react";
import { Tooltip } from "../tooltip";

import styles from "./AvailableDate.module.css";

export function AvailableDate({ date, style = {} }) {
  if (!date) return null;

  return (
    <Tooltip position="bottom" content="Estimated arrival">
      <div className={styles.root} style={style}>
        <span className={styles.icon} />
        <span className={styles.text}>{date}</span>
      </div>
    </Tooltip>
  );
}
