import { CSSProperties } from "react";
import { Button } from "../../../button";
import { useCardCtx } from "../../card-context";

export const ButtonWishlist = ({ style }: { style?: CSSProperties }) => {
  const cardCtx = useCardCtx();
  return (
    <Button
      variant="ghost"
      style={style}
      icon={
        <Icon fill={cardCtx.isWished ? "var(--color-primary)" : undefined} />
      }
      tooltip={cardCtx.isWished ? "Remove from Wishlist" : "Add to Wishlist"}
      onClick={cardCtx.toggleIsWished}
      isLoading={cardCtx.isWishLoading}
    />
  );
};

function Icon({ fill }: { fill?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill || "none"}
      stroke={fill || "black"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3651 3.84123C16.9395 3.41541 16.4342 3.07761 15.8779 2.84714C15.3217 2.61667 14.7255 2.49805 14.1235 2.49805C13.5214 2.49805 12.9252 2.61667 12.369 2.84714C11.8128 3.07761 11.3074 3.41541 10.8818 3.84123L9.99847 4.72457L9.11514 3.84123C8.25539 2.98149 7.08933 2.49849 5.87347 2.49849C4.65761 2.49849 3.49155 2.98149 2.6318 3.84123C1.77206 4.70098 1.28906 5.86704 1.28906 7.0829C1.28906 8.29876 1.77206 9.46482 2.6318 10.3246L3.51514 11.2079L9.99847 17.6912L16.4818 11.2079L17.3651 10.3246C17.791 9.89894 18.1288 9.39358 18.3592 8.83736C18.5897 8.28115 18.7083 7.68497 18.7083 7.0829C18.7083 6.48083 18.5897 5.88465 18.3592 5.32844C18.1288 4.77222 17.791 4.26686 17.3651 3.84123V3.84123Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
