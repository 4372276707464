import { useEffect, useState } from "react";
import { Auction, isAuctionOfLot } from "../models/auctions";
import { useIsAuctionFinished } from "./useIsAuctionFinished";

type Props = {
  auction?: Auction;
  price: number;
};

type AuctionStateType = {
  isFinished: boolean;
  isEnabledMaxBid: boolean;
  isFirstBid: boolean;
  isOutbid: boolean;
  isSingleBid: boolean;
  isWinning: boolean;
  status: "none" | "top" | "outbid" | "won";
};

export default function useAuctionStatus({
  auction,
  price,
}: Props): AuctionStateType {
  if (!auction) {
    throw new Error("No auction found");
  }

  const isFinished = useIsAuctionFinished(auction);

  const initialState: AuctionStateType = {
    isFinished,
    isEnabledMaxBid: false,
    isFirstBid: true,
    isOutbid: false,
    isSingleBid: true,
    isWinning: false,
    status: "none",
  };

  const [auctionState, setAuctionState] =
    useState<AuctionStateType>(initialState);

  useEffect(() => {
    if (isAuctionOfLot(auction) && auction.isOfSingleLot) {
      const isEnabledMaxBid = auction.max_bid_feature_is_available;
      const isFirstBid = !auction.myLastBid;
      const isOutbid = !auction.is_highest_bid;
      const isSingleBid =
        (isOutbid || isFirstBid) && price === auction.priceOptions[0].value;
      const isWinning = auction.is_highest_bid;
      const status =
        isWinning && isFinished
          ? "won"
          : isOutbid
          ? "outbid"
          : !isFirstBid
          ? "top"
          : "none";

      setAuctionState({
        isFinished,
        isEnabledMaxBid,
        isFirstBid,
        isOutbid,
        isSingleBid,
        isWinning,
        status,
      });
    }
  }, [auction, price, isFinished]);

  return auctionState;
}
