import { Button } from "src/app/components/button";
import styles from "./about-the-estate.module.css";
import { Builder } from "src/app/models/Builder";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";
import {
  ShadcnCarousel,
  ShadcnCarouselApi,
  ShadcnCarouselContent,
  ShadcnCarouselItem,
  useDotButton,
} from "src/app/components/shadcn-carousel";
import { Img } from "src/app/components/img";
import { useState } from "react";
import { cn } from "src/app/utils/cn";
import Autoplay from "embla-carousel-autoplay";

type Props = {
  builder: Builder | undefined;
};

export const AboutTheEstate = ({ builder }: Props) => {
  if (!builder) return null;

  const [firstSentence, ...otherSentences] =
    builder.winery_description.split(".");

  return (
    <section>
      <h3 className={styles.title}>ABOUT THE ESTATE</h3>
      <hr className={styles.hr} />

      <Carousel className={styles.carousel} builder={builder} />

      {!!firstSentence && (
        <p className={styles.firstSentence}>
          <em>{`${firstSentence}.`}</em>
        </p>
      )}
      {!!otherSentences.length && (
        <p className={styles.otherSentences}>{otherSentences.join(".")}</p>
      )}

      <Button
        className={styles.knowMore}
        as={A}
        variant="ghost"
        label="Know more about the producer"
        icon={"->"}
        to={path.website.singleProducer(builder)}
      />
    </section>
  );
};

function Carousel({
  builder,
  className = "",
}: {
  builder: Builder;
  className?: string;
}) {
  const [carouselApi, setCarouselApi] = useState<ShadcnCarouselApi>();

  const images = [
    builder.gallery_horizontal_0,
    builder.gallery_horizontal_1,
    builder.gallery_horizontal_2,
    builder.gallery_horizontal_3,
    builder.gallery_horizontal_4,
    builder.gallery_horizontal_5,
  ].filter(Boolean);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(carouselApi);

  if (!images.length) return null;

  return (
    <ShadcnCarousel
      opts={{
        align: "center",
        slidesToScroll: 1,
        skipSnaps: true,
      }}
      plugins={[Autoplay({})]}
      gap="3rem"
      className={cn(styles.shadcnCarouselRoot, className)}
      setApi={setCarouselApi}
    >
      <ShadcnCarouselContent>
        {images.map((src, index) => (
          <ShadcnCarouselItem
            key={`slide-${index}-${src}`}
            className={styles.shadcnCarouselItem}
          >
            <div className={styles.item}>
              <Img src={src} className={styles.img} alt="" />
            </div>
          </ShadcnCarouselItem>
        ))}
      </ShadcnCarouselContent>

      {/* Dot Pagination */}
      {scrollSnaps.length > 1 && (
        <div className={styles.pagination}>
          {scrollSnaps.map((_, index) => (
            <button
              key={`ind-bef-${index}`}
              tabIndex={-1}
              className={cn(
                styles.dotButton,
                index === selectedIndex && styles.active,
              )}
              onClick={() => onDotButtonClick(index)}
            >
              <div />
            </button>
          ))}
        </div>
      )}
    </ShadcnCarousel>
  );
}
