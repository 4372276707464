export const AvailabilityLabel = ({ text }: { text: string }) => {
  return (
    <span
      style={{
        color: "#CC1915",
        fontSize: "12px",
        fontWeight: 700,
        paddingTop: "8px",
        paddingLeft: "2px",
      }}
    >
      {text}
    </span>
  );
};
