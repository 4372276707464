import "./styles.css";
import { Fragment, useState } from "react";
import { useGetBannersQuery } from "src/app/api/queries";
import { filter, findKey, omit, size } from "lodash";
import { Redirect, useParams } from "react-router-dom";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import {
  AlertPopup,
  AlertPopupMeta,
} from "src/app/modules/Includes/LayoutSide/components/common/alert-popup";
import { NoBidsNew } from "src/app/modules/Includes/CustomerSide/components/common/NoBidsNew";
import { AdsBanner } from "./ads-banner";
import { NavLinks } from "src/app/components/nav-links";
import { path } from "src/app/routes/path";
import { PAGE_NAME, PAGE_TITLE } from "src/app/const";
import { ValueOf, inferDataType } from "src/app/utils/types";
import { useAuctionCountQuery } from "src/app/api/auction-count";
import { Auction } from "src/app/models/auctions";
import { useAuctionsQuery } from "src/app/api/auctions";
import { Warnings } from "./warnings";
import { StickyControls } from "src/app/components/sticky-controls";
import { Grid } from "src/app/components/auction-card/lg/grid";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { useScrollToAuction } from "src/app/hooks/useScrollToAuction";
import { AuctionCardLg } from "src/app/components/auction-card/lg";
import { useFiltersForWeekly } from "./useFilters";
import { SelectMultiple } from "src/app/components/form-elements/select-multiple";
import { useDeboucedInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useDeboucedInputAutocomplete";
import { useIsMobile } from "src/app/hooks/useIsMobile";

type WeeklyPageName = ValueOf<typeof PAGE_NAME.WEEKLY>;

export const WeeklyAuctionsPage = () => {
  usePageTitle(PAGE_TITLE.WEEKLY_AUCTIONS());
  const { pageName } = useParams<{
    pageName?: WeeklyPageName;
  }>();

  const { data: count } = useAuctionCountQuery();

  if (!count) {
    return null;
  }

  const hasNoAuction = !Object.values(count).reduce((acc, n) => acc + n, 0);

  if (hasNoAuction) {
    return <NoBidsNew title="No live Auction" />;
  }

  const showAllTab = size(filter(omit(count, ["all"]), Boolean)) > 1;

  const defaultTabPath = showAllTab
    ? path.weekly.value(PAGE_NAME.WEEKLY.ALL)
    : path.weekly.value(
        findKey(omit(count, ["all"]), Boolean) as ValueOf<
          Omit<typeof PAGE_NAME.WEEKLY, "ALL">
        >,
      );

  if (!pageName) {
    return <Redirect to={defaultTabPath} />;
  }

  return <Content showAllTab={showAllTab} />;
};

const Content = ({ showAllTab = false }) => {
  const isMobile = useIsMobile();
  const { pageName } = useParams<{
    pageName: WeeklyPageName;
  }>();

  const [alertPopupMeta, setAlertPopupMeta] = useState<AlertPopupMeta | null>(
    null,
  );

  const bannersQuery = useGetBannersQuery({
    enabled: pageName === PAGE_NAME.WEEKLY.CHARITY,
  });
  const { data: count } = useAuctionCountQuery();

  const filtersWeekly = useFiltersForWeekly({
    pageName: pageName,
  });

  const auctionsQuery = useAuctionsQuery({
    per_page: isMobile ? 6 : 12,
    pageName: pageName,
    filters: filtersWeekly.rawValues,
    search: filtersWeekly.search,
  });

  const autocompleteOptions = auctionsQuery.data?.pages.at(0)?.items || [];

  const stickyControlsRef = useScrollToAuction({
    enabled: !!auctionsQuery.data,
    getCardId,
  });

  const inputAutocomplete = useDeboucedInputAutocomplete({
    inferDataType: inferDataType<Auction>(),
    getSuggestionText: (auction) => auction.description,
  });

  const filteredAuctions = (auctionsQuery.data?.pages || []).flatMap(
    (page) => page.items,
  );

  return (
    <>
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <div>
        {pageName === PAGE_NAME.WEEKLY.CHARITY && <Warnings />}

        <StickyControls
          outerRef={stickyControlsRef}
          slot1={() => (
            <NavLinks
              links={[
                !!showAllTab && {
                  to: path.weekly.value("all"),
                  label: "All",
                  count: count?.all,
                },
                !!count?.["charity"] && {
                  to: path.weekly.value("charity"),
                  label: "Charity",
                  count: count.charity,
                },
                !!count?.["collections"] && {
                  to: path.weekly.value("collections"),
                  label: "Collections",
                  count: count["collections"],
                },
                !!count?.["single-lots"] && {
                  to: path.weekly.value("single-lots"),
                  label: "Single Lots",
                  count: count["single-lots"],
                },
                !!count?.barrels && {
                  to: path.weekly.value("barrels"),
                  label: "Barrels",
                  count: count["barrels"],
                },
              ].filter(Boolean)}
            />
          )}
          slot2={(scrollToTop) => (
            <SelectMultiple
              isLoading={filtersWeekly.isLoading}
              placeholder="Filters"
              values={filtersWeekly.values}
              onChange={(values) => {
                filtersWeekly.setFilters(values);
                scrollToTop();
              }}
              options={filtersWeekly.options}
            />
          )}
          slot3={(scrollToTop) => (
            <InputAutocomplete
              data={autocompleteOptions}
              getSuggestionText={inputAutocomplete.getSuggestionText}
              value={inputAutocomplete.value}
              onChange={(value) => {
                inputAutocomplete.setValue(value);
                scrollToTop();
              }}
            />
          )}
        />

        <Grid
          isLoading={auctionsQuery.isFetching}
          canLoadMore={auctionsQuery.hasNextPage}
          onLoadMore={auctionsQuery.fetchNextPage}
        >
          {!auctionsQuery.isLoading &&
            !auctionsQuery.isFetching &&
            !filteredAuctions.length && <NoBidsNew title="Nothing found" />}

          {filteredAuctions.map((auction, auctionIndex) => (
            <Fragment
              key={`auction-xl-${auction.id_auction}-${auction.type_product}`}
            >
              <AuctionCardLg.Card
                auction={auction}
                cardId={getCardId(auction.id)}
              />

              {/* Add banner every two auctions  */}
              {shouldShowBanner({
                pageName,
                auctionIndex,
                auctions: filteredAuctions,
                bannersData: bannersQuery.data,
              }) && (
                <AdsBanner
                  src={`data:image/png;base64,${
                    bannersQuery.data[Math.floor(auctionIndex / 2)]?.image
                  }`}
                />
              )}
            </Fragment>
          ))}
        </Grid>
      </div>
    </>
  );
};

type Config = {
  pageName: WeeklyPageName;
  auctionIndex: number;
  auctions: Auction[];
  bannersData: Array<{ image: string }>;
};

function shouldShowBanner({
  pageName,
  auctionIndex,
  auctions,
  bannersData,
}: Config) {
  if (pageName !== PAGE_NAME.WEEKLY.CHARITY) return false;
  // no banner after the last auction
  if (auctionIndex === auctions.length - 1) return false;
  // check if there is a banner image
  if (!bannersData[Math.floor(auctionIndex / 2)]?.image) return false;
  return (auctionIndex + 1) % 2 === 0;
}

function getCardId(id: number) {
  return `card-xl-${id}`;
}
