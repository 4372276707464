import { useMyUser } from "src/app/hooks/useMyUser";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { LayoutDesktop } from "src/app/layout.private/layout-desktop";
import { LayoutMobile } from "src/app/layout.private/layout-mobile";

export function Layout({ children }) {
  const user = useMyUser();

  const isMobile = useIsMobile();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!user) return <>{children}</>;

  if (isMobile) {
    return <LayoutMobile>{children}</LayoutMobile>;
  }

  return <LayoutDesktop>{children}</LayoutDesktop>;
}
