import { z } from "zod";
import { Product, apiProductSchema } from "./Product";

export class SerialProduct {
  id: number;
  id_serial_product: number;
  id_serial_product_mp: string;
  serial_date: string | null;
  deposit: string | null;
  serial: string;
  deleted: boolean;
  position_meta: null | number;
  product: Product;
  IDSeriallot: number;
  history: Array<{ label: string; value: string | null }>;
  NFTInfo: {
    contract: string;
    provenance: string;
    token: string | null;
    transfers: number;
  };

  constructor(apiSerialProduct: ApiSerial) {
    this.id = apiSerialProduct.id;
    this.id_serial_product = apiSerialProduct.id_serial_product;
    this.id_serial_product_mp = apiSerialProduct.id_serial_product_mp;
    this.serial_date = apiSerialProduct.serial_date || "";
    this.deposit = apiSerialProduct.deposit || "";
    this.serial = apiSerialProduct.serial;
    this.deleted = apiSerialProduct.deleted;
    this.position_meta = apiSerialProduct.position_meta;
    this.product = new Product(apiSerialProduct.product);
    this.IDSeriallot = apiSerialProduct.IDSeriallot;
    this.NFTInfo = apiSerialProduct.NFTInfo;
    this.history = apiSerialProduct.history;
  }
}

export type ApiSerial = z.infer<typeof apiSerialProductSchema>;

const apiSerialProductSchema = z.object({
  id: z.number(),
  id_serial_product: z.number(),
  id_serial_product_mp: z.string(),
  serial_date: z.string().nullable(),
  deposit: z.string().nullable(),
  serial: z.string(),
  deleted: z.boolean(),
  position_meta: z.number().nullable(),
  product: apiProductSchema,
  IDSeriallot: z.number(),
  NFTInfo: z.object({
    contract: z.string(),
    provenance: z.string(),
    token: z.string().nullable(),
    transfers: z.number(),
  }),
  history: z.array(
    z.object({ label: z.string(), value: z.string().nullable() }),
  ),
});
