import styles from "./collection-of-lot-accordion.module.css";
import * as Accordion from "@radix-ui/react-accordion";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import { Button } from "src/app/components/button";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { cn } from "src/app/utils/cn";
import { ProductDetails } from "./product-details";

type Props = {
  auction: AuctionOfLot;
  activeSlideIndex: number;
  openSlideIndex: number | null;
  onChangeActiveSlideIndex: (index: number) => void;
  onChangeOpenSlideIndex: (index: number | null) => void;
};

export const CollectionOfLotAccordion = ({
  auction,
  activeSlideIndex,
  openSlideIndex,
  onChangeActiveSlideIndex,
  onChangeOpenSlideIndex,
}: Props) => {
  return (
    <Accordion.Root
      type="single"
      value={openSlideIndex?.toString() || ""}
      collapsible
    >
      {auction.lot?.components.map((product, index) => (
        <Accordion.Item
          key={`product-${product.id}-${index}`}
          value={index.toString()}
        >
          <Accordion.Header
            className={cn(
              styles.header,
              activeSlideIndex === index && styles.active,
            )}
            onClick={() => onChangeActiveSlideIndex(index)}
          >
            <div className={styles.headerLeft}>
              <div className={styles.headerTitle}>
                {product.nameProductWithoutVintage}
              </div>
              <div className={styles.headerSubtitle}>
                {`${product.quantity} x ${product.size}L`}
                {!!product.vintage && `  ·  ${product.vintage}`}
              </div>
            </div>
            <Accordion.Trigger asChild>
              <Button
                className={styles.trigger}
                variant="ghost"
                tooltip="View more details"
                onClick={() => {
                  onChangeActiveSlideIndex(index);
                  onChangeOpenSlideIndex(
                    index === openSlideIndex ? null : index,
                  );
                }}
                icon={
                  <>
                    {/* Switch via css */}
                    <LuPlusCircle size={24} className={styles.plus} />
                    <LuMinusCircle size={24} className={styles.minus} />
                  </>
                }
              />
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className={styles.content}>
            <ProductDetails product={product} hideTitle />
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};
