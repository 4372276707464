import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

export function useOnNavigate(listener: () => void) {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;

  const history = useHistory();
  useEffect(() => {
    return history.listen((e, action) => {
      if (action === "REPLACE") return;
      listenerRef.current();
    });
  }, [history]);
}
