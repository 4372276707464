import { env } from "src/env";
import { Suspense } from "react";
import { Router } from "react-router-dom";
import { MaterialThemeProvider } from "../_metronic/layout";
import { CheckUserSession } from "./components/check-user-session/CheckUserSession";
import { CheckAppVersion } from "./components/check-app-version";
import { PrefetchQueries } from "./prefetch-queries";
import { Routes } from "./routes";
import { AlertPopupProvider } from "./components/alert-popup/provider";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { SplashScreen } from "./layouts/splash-screen";
import { useMount } from "react-use";
import { DownloadProvider } from "./hooks/useDownload";
import { useRefCookie } from "./hooks/useRefCookie";

const history = createBrowserHistory();

if (env.isProd) {
  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
      // new CaptureConsole({ levels: ["error"] }), <- not a good idea, too much stuff gets reported to Sentry
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets crashes the app in prod because API calls give a CORS error because of the "Bagage" header
    // tracePropagationTargets: [/^https:\/\/api\.crurated\.com/],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 0.2,
  });
}

export default function App() {
  useRefCookie();

  useMount(() => {
    if (env.isProd) return;
    document.title = `Crurated - ${window.location.hostname.split(".")[0]}`;
  });

  return (
    <Suspense fallback={<SplashScreen />}>
      <Router history={history}>
        <MaterialThemeProvider>
          <DownloadProvider>
            <AlertPopupProvider />
            <CheckUserSession />
            <CheckAppVersion />
            <PrefetchQueries />
            <Routes />
          </DownloadProvider>
        </MaterialThemeProvider>
      </Router>
    </Suspense>
  );
}
