import { isAuctionOfBarrel } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";
import { LinkViewDetails } from "../../atoms/link-view-details";
import { BottleCount } from "./bottle-count";

export const Details = () => {
  const cardCtx = useCardCtx();

  if (isAuctionOfBarrel(cardCtx.auction)) {
    return (
      <div
        style={{
          display: "flex",
          gap: "20px",
          position: "relative",
          top: "1px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#656565",
            fontSize: "14px",
          }}
        >
          {cardCtx.auction.barrel.color}
        </div>

        <LinkViewDetails />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        position: "relative",
        top: "1px",
      }}
    >
      {!cardCtx.showLotProductList && (
        <BottleCount productIndex={cardCtx.slideIndex} />
      )}

      <LinkViewDetails />
    </div>
  );
};
