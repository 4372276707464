import { ReactComponent as CrediCardIcon } from "src/media/credit-card-icon.svg";

export const CreditCardLabel = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
      }}
    >
      <p style={{ fontSize: "11px", margin: 0 }}>
        All major credits cards accepted
      </p>
      <CrediCardIcon width={120} height={30} />
    </div>
  );
};
