import styles from "./product-name.module.css";
import { cn } from "src/app/utils/cn";
import { CSSProperties } from "react";
import { Tooltip } from "../../../tooltip";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { useCardCtx } from "../../card-context";
import { isAuctionOfLot } from "src/app/models/auctions";

type Props = {
  className?: string;
  style?: CSSProperties;
};

export const ProductName = ({ className = "", style = {} }: Props) => {
  const cardCtx = useCardCtx();
  const isMobile = useIsMobile();

  const title = isAuctionOfLot(cardCtx.auction)
    ? cardCtx.auction.lot?.components[cardCtx.slideIndex]
        .nameProductWithoutVintage ?? ""
    : cardCtx.auction.barrel.name;

  return (
    <h3
      className={cn(styles.root, className, isMobile && styles.mobile)}
      style={style}
    >
      <Tooltip content={title}>
        <div className={styles.content}>{title}</div>
      </Tooltip>
    </h3>
  );
};
