import { useIsMobile } from "src/app/hooks/useIsMobile";
import styles from "./index.module.css";
import logoSrc from "src/media/logos/logo-default.gif";
import { CruForm } from "src/app/components/cru-form-3";
import { Input } from "src/app/components/form-elements/input";
import { Button } from "src/app/components/button";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";
import { GetBrowser } from "src/app/config/Utility";
import { useForgotPasswordMutation } from "src/app/api/auth";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { validate } from "src/app/utils/validate";

export default function ForgotPassword() {
  const isMobile = useIsMobile();

  const forgotPasswordMutation = useForgotPasswordMutation();

  if (forgotPasswordMutation.isSuccess)
    return (
      <div className={styles.success}>
        <FaEnvelopeOpenText style={{ fontSize: "50px" }} />
        <h1>Check your e-mail!</h1>
        <h3>An email was sent to you to change the password</h3>
      </div>
    );

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        {!!isMobile && <img src={logoSrc} alt="Crurated logo" />}
        <h3>Forgotten Password?</h3>
        <p>Enter your email to reset your password</p>
      </div>
      <div>
        <CruForm.Form
          initialValues={{ email: "", browser: GetBrowser() }}
          onSubmit={(values) => forgotPasswordMutation.mutateAsync(values)}
        >
          {(ctx) => (
            <>
              <CruForm.Field ctx={ctx} name="email" validate={validate.email}>
                {(props) => (
                  <div className={styles.inputGroup}>
                    <Input
                      {...props}
                      type="text"
                      placeholder="Email"
                      value={ctx.getFieldValue(props.name)}
                      onChange={(e) => {
                        ctx.setFieldsValues({ [props.name]: e.target.value });
                      }}
                      onBlur={() =>
                        ctx.setFieldsTouched({ [props.name]: true })
                      }
                      className={styles.input}
                    />

                    {ctx.submission.isError &&
                    ctx.submission.values.email ===
                      ctx.getFieldValue("email") ? (
                      <div className={styles.error}>The user is not active</div>
                    ) : (
                      <CruForm.ErrorMessage />
                    )}
                  </div>
                )}
              </CruForm.Field>

              <div>
                <Button
                  variant="contained"
                  label="Submit"
                  style={{ marginBottom: 20 }}
                  className={styles.button}
                  isLoading={forgotPasswordMutation.isLoading}
                />
                <Button
                  variant="ghost"
                  label="Cancel"
                  style={{ color: "#ffffff" }}
                  className={styles.button}
                  as={A}
                  to={path.login()}
                  disabled={forgotPasswordMutation.isLoading}
                />
              </div>
            </>
          )}
        </CruForm.Form>
      </div>
    </div>
  );
}
