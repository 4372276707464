import { useIsMutating } from "@tanstack/react-query";
import styles from "./Button.module.css";

export const Button = ({
  className = "",
  onClick,
  disabled = false,
  greyedOut = false,
  text = "",
}) => {
  const isMutating = !!useIsMutating();

  return (
    <button
      className={`btn btn-primary btn-full ${styles.root} ${className} ${
        greyedOut ? styles.greyedOut : ""
      }`}
      onClick={onClick}
      disabled={disabled || greyedOut || isMutating}
    >
      {text}
    </button>
  );
};
