import styles from "./index.module.css";
import { noop } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { useLatest } from "react-use";
import { Scrollable } from "../scrollable";
import { Button } from "../button";
import { MdClear } from "react-icons/md";
import { cn } from "src/app/utils/cn";
import { HtmlDialog } from "../html-dialog";

interface DialogProps {
  title?: string;
  onClosed?: () => void;
  render: (props: { startClosing: () => void }) => ReactNode;
  disableClose?: boolean;
  mandatory?: boolean;
  width?: string | number;
}

export function Dialog({
  title = "",
  onClosed = noop,
  render,
  disableClose = false,
  mandatory = false,
  width = "fit-content",
}: DialogProps) {
  const onClosedRef = useLatest(onClosed);
  const isMutating = !!useIsMutating();
  const [isOpen, setIsOpen] = useState(true);

  function internalClose() {
    if (disableClose || isMutating || mandatory) return;
    setIsOpen(false);
  }

  function startClosing() {
    if (disableClose || isMutating) return;
    setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen) return;
    const token = setTimeout(
      () => onClosedRef.current(),
      150, // must match the exit animation duration of the css
    );
    return () => clearTimeout(token);
  }, [isOpen, onClosedRef]);

  return (
    <HtmlDialog
      className={cn(
        styles.root,
        mandatory && styles.mandatory,
        !isOpen && styles.closing,
      )}
      style={{ width }}
      onClose={internalClose}
      isOpen
      preventAutofocus
      mandatory={mandatory}
    >
      <div
        style={{ minHeight: title ? "54px" : "0" }}
        className={styles.heading}
      >
        {title ? <h2 className={styles.title}>{title}</h2> : <div />}

        <Button
          variant="ghost"
          icon={<MdClear size={24} />}
          type="button"
          size={28}
          tooltip=""
          style={{ marginBottom: title ? "" : "-15px" }}
          aria-label="Close"
          className={cn(styles.close, mandatory && styles.hide)}
          onClick={internalClose}
        />
      </div>

      <Scrollable
        className={styles.scrollable}
        viewportClassName={styles.viewport}
      >
        {render({ startClosing })}
      </Scrollable>
    </HtmlDialog>
  );
}
