import { ComponentProps } from "react";
import { sortBy, uniqBy } from "lodash";
import { SelectMultiple } from ".";
import { SelectMultipleConfigEntry, SelectMultipleOption } from "./types";
import { isValidLabel, transformConfig } from "./utils";

type Props<TData extends object> = Omit<
  ComponentProps<typeof SelectMultiple>,
  "options"
> & {
  data: TData[] | undefined;
  config: SelectMultipleConfigEntry<TData>[];
};

export function SelectMultipleFromData<TData extends object>({
  config,
  data = [],
  ...props
}: Props<TData>) {
  const options = transformConfig(config).reduce(
    (options, configEntry) => {
      options[configEntry.serializedGroupName] = sortBy(
        data.reduce((groupOptions, dataEntry) => {
          const { values, labels } = sanitize({
            values: configEntry.getValues(dataEntry),
            labels: configEntry.getLabels(dataEntry),
          });
          if (values.length !== labels.length) {
            throw new Error(
              "Both getValue and getLabel should return an array of the same length, or primitive values (string, number, boolean)",
            );
          }

          addOptions(groupOptions, {
            values,
            labels,
            groupName: configEntry.groupName,
            serializedGroupName: configEntry.serializedGroupName,
          });
          return groupOptions;
        }, [] as SelectMultipleOption[]),
        "label",
      );
      return options;
    },
    {} as Record<string, SelectMultipleOption[]>,
  );

  return <SelectMultiple options={options} {...props} />;
}

function addOptions(
  groupOptions: SelectMultipleOption[],
  {
    values,
    labels,
    groupName,
    serializedGroupName,
  }: {
    values: Array<SelectMultipleOption["value"]>;
    labels: Array<SelectMultipleOption["label"]>;
    groupName: string;
    serializedGroupName: string;
  },
): SelectMultipleOption[] {
  const optionsToAdd = uniqBy(
    values
      .map((val, i) => {
        if (typeof val === "undefined") return undefined;
        return {
          value: val,
          label: labels[i]!.toString(),
          groupName,
          serializedGroupName,
        };
      })
      .filter(Boolean)
      .filter((opt) => opt.label !== ""),
    "value",
  );

  optionsToAdd.forEach((option) => {
    const alreadyAddedOption = groupOptions.find(
      (opt) => opt.value === option.value,
    );
    if (alreadyAddedOption) {
      alreadyAddedOption.count += 1;
    } else {
      groupOptions.push({ ...option, count: 1 });
    }
  });
  return groupOptions;
}

// remove undefined from values
// remove boolean | undefined from labels
function sanitize(data: {
  values: Array<string | number | boolean | undefined>;
  labels: Array<string | number | boolean | undefined>;
}): {
  values: Array<string | number | boolean>;
  labels: Array<string | number>;
} {
  const labels: any[] = [...data.labels];
  const values: any[] = [...data.values];

  // remove entries with invalid labels
  // valid labels are non-empty strings and numbers
  for (let i = labels.length - 1; i >= 0; i--) {
    if (isValidLabel(labels[i]) && typeof values[i] !== "undefined") continue;
    values.splice(i, 1);
    labels.splice(i, 1);
  }

  return { labels, values };
}
