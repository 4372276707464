import { z } from "zod";
import { Builder, apiBuilderSchema } from "./Builder";
import { formatPercentage } from "../utils/formatPercentage";
import { upperFirst } from "lodash";

const availableBottleTierSchema = z.object({
  id: z.number(),
  id_barrel_bottle_tier: z.number(),
  name: z.string(),
  value: z.number(),
});

type AvailableBottleTier = z.infer<typeof availableBottleTierSchema>;

export const apiBarrelSchema = z.object({
  availableBottleTiers: z.array(availableBottleTierSchema),
  barrel_capacity: z.number(),
  bottles_tiers: z.string(),
  builder: apiBuilderSchema,
  color: z.string(),
  cost_price: z.number(),
  deleted: z.union([z.literal(0), z.literal(1)]),
  description: z.string(),
  dinner_with_producer: z.union([z.literal(0), z.literal(1)]),
  final_bottling_date: z.string().nullable(),
  final_custom_label_date_end: z.string().nullable(),
  id: z.number(),
  id_barrel: z.number(),
  id_barrel_custom: z.union([z.number(), z.string()]),
  id_user: z.number(),
  initial_bottling_date: z.string().nullable(),
  is_cost_price_percentage: z.union([z.literal(0), z.literal(1)]),
  lots_available_date: z.string().nullable(),
  name: z.string(),
  nft_liters: z.number(),
  notes_dinner_with_producer: z.string().nullable(),
  notes_visit_cellar_of_producer: z.string().nullable(),
  price: z.number(),
  product_alcohol_level: z.string(),
  product_appellation: z.string(),
  product_appellation_characteristic: z.string(),
  product_average_age_of_vines: z.string(),
  product_classification: z.string(),
  product_clos: z.string(),
  product_color: z.string(),
  product_cork: z.any(),
  product_description: z.string(),
  product_duration_of_aging: z.string(),
  product_excise_code: z.string(),
  product_is_special_packing: z.union([z.literal(0), z.literal(1)]),
  product_label: z.string(),
  product_level_of_wine: z.any(),
  product_link_video: z.string(),
  product_region: z.string(),
  product_soil_composition: z.string(),
  product_varietal: z.string(),
  product_vineyard_location: z.string(),
  product_vintage: z.string(),
  product_vintage_characteristics: z.string(),
  sku: z.string(),
  stato: z.union([z.literal(0), z.literal(1)]),
  supplier: z.any(),
  visit_cellar_of_producer: z.union([z.literal(0), z.literal(1)]),
});

export type ApiBarrel = z.infer<typeof apiBarrelSchema>;

export class Barrel {
  id: number;
  id_user: number;
  id_barrel: number;
  id_barrel_custom: number;
  nft_liters: number;
  barrel_capacity: number;
  price: number;
  cost_price: number;
  is_cost_price_percentage: boolean;
  initial_bottling_date: string | null;
  final_bottling_date: string | null;
  lots_available_date: string | null;
  final_custom_label_date_end: string | null;
  sku: string;
  name: string;
  description: string;
  color: string;
  product_region: string;
  product_appellation: string;
  product_clos: string;
  product_classification: string;
  product_vintage: number;
  product_alcohol_level: number;
  product_varietal: string;
  product_duration_of_aging: string;
  product_average_age_of_vines: string;
  product_excise_code: string;
  product_color: string;
  product_description: string;
  product_vintage_characteristics: string;
  product_vineyard_location: string;
  product_soil_composition: string;
  product_label: string;
  product_cork: any;
  product_level_of_wine: any;
  product_is_special_packing: boolean;
  product_appellation_characteristic: string;
  product_link_video: string;
  dinner_with_producer: boolean;
  notes_dinner_with_producer: string;
  visit_cellar_of_producer: boolean;
  notes_visit_cellar_of_producer: string;
  bottles_tiers: Array<{ id_barrel_bottle_tier: number }>;
  stato: boolean;
  deleted: boolean;
  supplier: any;
  builder: Builder;
  availableBottleTiers: AvailableBottleTier[];

  constructor(apiBarrel: ApiBarrel) {
    this.id = apiBarrel.id;
    this.id_user = apiBarrel.id_user;
    this.id_barrel = apiBarrel.id_barrel;
    this.id_barrel_custom = +apiBarrel.id_barrel_custom;
    this.nft_liters = apiBarrel.nft_liters;
    this.barrel_capacity = apiBarrel.barrel_capacity;
    this.price = apiBarrel.price;
    this.cost_price = apiBarrel.cost_price;
    this.is_cost_price_percentage = !!apiBarrel.is_cost_price_percentage;
    this.initial_bottling_date = apiBarrel.initial_bottling_date;
    this.final_bottling_date = apiBarrel.final_bottling_date;
    this.lots_available_date = apiBarrel.lots_available_date;
    this.final_custom_label_date_end = apiBarrel.final_custom_label_date_end;
    this.sku = apiBarrel.sku;
    this.name = apiBarrel.name;
    this.description = apiBarrel.description;
    this.color = apiBarrel.color;
    this.product_region = apiBarrel.product_region;
    this.product_appellation = apiBarrel.product_appellation;
    this.product_clos = apiBarrel.product_clos;
    this.product_classification = apiBarrel.product_classification;
    this.product_vintage = +apiBarrel.product_vintage;
    this.product_alcohol_level = +apiBarrel.product_alcohol_level;
    this.product_varietal = apiBarrel.product_varietal;
    this.product_duration_of_aging = apiBarrel.product_duration_of_aging;
    this.product_average_age_of_vines = apiBarrel.product_average_age_of_vines;
    this.product_excise_code = apiBarrel.product_excise_code;
    this.product_color = apiBarrel.product_color;
    this.product_description = apiBarrel.product_description;
    this.product_vintage_characteristics =
      apiBarrel.product_vintage_characteristics;
    this.product_vineyard_location = apiBarrel.product_vineyard_location;
    this.product_soil_composition = apiBarrel.product_soil_composition;
    this.product_label = apiBarrel.product_label;
    this.product_cork = apiBarrel.product_cork;
    this.product_level_of_wine = apiBarrel.product_level_of_wine;
    this.product_is_special_packing = !!apiBarrel.product_is_special_packing;
    this.product_appellation_characteristic =
      apiBarrel.product_appellation_characteristic;
    this.product_link_video = apiBarrel.product_link_video;
    this.dinner_with_producer = !!apiBarrel.dinner_with_producer;
    this.notes_dinner_with_producer =
      apiBarrel.notes_dinner_with_producer ?? "";
    this.visit_cellar_of_producer = !!apiBarrel.visit_cellar_of_producer;
    this.notes_visit_cellar_of_producer =
      apiBarrel.notes_visit_cellar_of_producer ?? "";
    this.bottles_tiers = parseJSON(apiBarrel.bottles_tiers);
    this.stato = !!apiBarrel.stato;
    this.deleted = !!apiBarrel.deleted;
    this.supplier = apiBarrel.supplier;
    this.builder = new Builder(apiBarrel.builder);
    this.availableBottleTiers = apiBarrel.availableBottleTiers;
  }

  get formattedProvenanceLocation() {
    const country = this.builder.country.name;
    const region = this.product_region || this.builder.region;
    return [country, region].filter(Boolean).join(", ");
  }

  get formattedAlcoholLevel() {
    return formatPercentage(this.product_alcohol_level);
  }

  get formattedTypeOfWine() {
    return this.product_excise_code === "W200" ? "Still" : "Sparkling";
  }

  get formattedColor() {
    return upperFirst(this.color.toLocaleLowerCase());
  }
}

function parseJSON(
  visible_for: string,
): Array<{ id_barrel_bottle_tier: number }> {
  try {
    return JSON.parse(visible_for, (_, value) => +value);
  } catch {
    return visible_for as any;
  }
}
