import { Skeleton } from "src/app/components/skeleton";

export default function SkeletonAllCards() {
  return (
    <>
      <label className={`cards-table-row`}>
        <div className="col">
          <Skeleton.Rect className="m-0 p-0" height={50} />
        </div>
      </label>
      <label className={`cards-table-row`}>
        <div className="col">
          <Skeleton.Rect className="m-0 p-0" height={50} />
        </div>
      </label>
      <label className={`cards-table-row`}>
        <div className="col">
          <Skeleton.Rect className="m-0 p-0" height={50} />
        </div>
      </label>
      <label className={`cards-table-row`}>
        <div className="col">
          <Skeleton.Rect className="m-0 p-0" height={50} />
        </div>
      </label>
      <label className={`cards-table-row`}>
        <div className="col">
          <Skeleton.Rect className="m-0 p-0" height={50} />
        </div>
      </label>
    </>
  );
}
