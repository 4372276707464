import { ReactNode, useState } from "react";
import { GoTriangleDown } from "react-icons/go";

type Props = {
  isWide: boolean;
  icon?: ReactNode;
  title?: string;
  message?: string;
  isAccordion?: boolean;
};

export default function InfoMessage({
  isWide,
  icon,
  title,
  message,
  isAccordion = false,
}: Props) {
  const [isMessageOpen, setIsMessageOpen] = useState(!isAccordion);

  return (
    <div style={{ maxWidth: isWide ? 300 : "100%", margin: "8px 0px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "start",
          gap: "0.6ch",
          marginBottom: 3,
          fontWeight: 500,
          fontSize: 14,
          cursor: isAccordion ? "pointer" : "text",
          userSelect: isAccordion ? "none" : "text",
        }}
        role={isAccordion ? "button" : "heading"}
        onClick={() => {
          if (isAccordion) return setIsMessageOpen((prev) => !prev);
        }}
      >
        <div style={{ flexShrink: 0, marginTop: 3 }}>{icon}</div>
        {!!title && <div>{title}</div>}
        {!!isAccordion && (
          <GoTriangleDown
            style={{
              transform: isMessageOpen ? "rotate(-180deg)" : "",
              transition: "transform 100ms",
            }}
          />
        )}
      </div>
      {!!message && (
        <div
          style={{
            marginLeft: 22,
            fontSize: 12,
            fontWeight: 300,
            lineHeight: "0.9375rem",
            whiteSpace: "normal",
            overflow: "hidden",
            height: isAccordion ? (isMessageOpen ? "100%" : "0px") : "100%",
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
}
