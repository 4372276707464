import React, { useRef } from "react";
import styles from "./TabsForBarrels.module.css";
import { useState } from "react";
import { useBottleTiersQuery } from "src/app/api/queries";
import { BarrelSampleBottles } from "src/app/modules/Includes/LayoutSide/components/barrels/BarrelSampleBottles";
import tabIcon1Src from "src/media/common/ccbth-icon-1.svg";
import tabIcon2Src from "src/media/common/ccbth-icon-2.svg";
import tabIcon3Src from "src/media/common/ccbth-icon-3.svg";
import tabIcon4Src from "src/media/common/ccbth-icon-4.svg";

export function TabsForBarrels({
  auction,
  fallbackPanel,
  width = undefined,
  height = undefined,
  initialPanelIndex = -1,
}) {
  const [panelIndex, setPanelIndex] = usePanelIndex(initialPanelIndex);

  return (
    <div className={styles.generalContainer} style={{ width, height }}>
      <div className={styles.container}>
        <div className={styles.divisor} />

        <div className={styles.iconContainer}>
          <img
            className={styles.image}
            src={tabIcon1Src}
            alt="icon-1"
            onMouseEnter={() => setPanelIndex(0)}
            onMouseOut={() => setPanelIndex(-1)}
          />
          {!!auction.enable_custom_label_bottle && (
            <img
              className={styles.image}
              src={tabIcon2Src}
              alt="icon-2"
              onMouseEnter={() => setPanelIndex(1)}
              onMouseOut={() => setPanelIndex(-1)}
            />
          )}
          {!!auction.barrel.visit_cellar_of_producer && (
            <img
              className={styles.image}
              src={tabIcon3Src}
              alt="icon-3"
              onMouseEnter={() => setPanelIndex(2)}
              onMouseOut={() => setPanelIndex(-1)}
            />
          )}
          {!!auction.barrel.dinner_with_producer && (
            <img
              className={styles.image}
              src={tabIcon4Src}
              alt="icon-4"
              onMouseEnter={() => setPanelIndex(3)}
              onMouseOut={() => setPanelIndex(-1)}
            />
          )}
        </div>
        <div className={styles.divisor} />
      </div>
      <div className={styles.panelContainer}>
        {getActivePanel({
          panelIndex,
          auction,
          onMouseEnter: setPanelIndex,
          onMouseLeave: setPanelIndex,
        }) || fallbackPanel}
      </div>
    </div>
  );
}

function getActivePanel({ panelIndex, auction, onMouseEnter, onMouseLeave }) {
  if (panelIndex === 0) {
    return (
      <PanelForBottledIn
        auction={auction}
        onMouseEnter={() => onMouseEnter(0)}
        onMouseLeave={() => onMouseLeave(-1)}
      />
    );
  }
  if (panelIndex === 1) {
    return (
      <PanelForCustomLabels
        auction={auction}
        onMouseEnter={() => onMouseEnter(1)}
        onMouseLeave={() => onMouseLeave(-1)}
      />
    );
  }
  if (panelIndex === 2) {
    return (
      <PanelForVisit
        auction={auction}
        onMouseEnter={() => onMouseEnter(2)}
        onMouseLeave={() => onMouseLeave(-1)}
      />
    );
  }
  if (panelIndex === 3) {
    return (
      <PanelForDinner
        auction={auction}
        onMouseEnter={() => onMouseEnter(3)}
        onMouseLeave={() => onMouseLeave(-1)}
      />
    );
  }
}

function PanelForBottledIn({ auction, onMouseEnter, onMouseLeave }) {
  const { data: bottleTiers, isLoading } = useBottleTiersQuery();

  return (
    <div
      className={styles.panel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!!isLoading && "Loading..."}
      <BarrelSampleBottles
        bottlesTiersChoose={JSON.parse(auction.barrel.bottles_tiers || "[]")}
        activeLevelIndex={auction.barrel.stock_available}
        dataBottlesTiers={bottleTiers}
      />
    </div>
  );
}

function PanelForCustomLabels({ auction, onMouseEnter, onMouseLeave }) {
  const { data: bottleTiers, isLoading } = useBottleTiersQuery();

  const getText = () => {
    let text_custom_label = "";
    const tiers =
      typeof auction.custom_label_for_bottles_tiers === "string"
        ? JSON.parse(auction.custom_label_for_bottles_tiers || "[]")
        : auction.custom_label_for_bottles_tiers;
    tiers.forEach((tier, key) => {
      var dataTier = bottleTiers.filter(
        (item) =>
          parseInt(item.id_barrel_bottle_tier) ===
          parseInt(tier.id_barrel_bottle_tier),
      );
      if (tiers.length === key + 1) {
        var pointer = "";
      } else {
        pointer = " | ";
      }
      if (dataTier.length > 0) {
        text_custom_label += `${dataTier[0].name}L${pointer}`;
      }
    });

    return text_custom_label || "-";
  };

  return (
    <div
      className={styles.panel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading ? "Loading..." : null}
      {bottleTiers ? (
        <div>
          <div>Custom label available for these formats:</div>
          <div>{getText()}</div>
        </div>
      ) : null}
    </div>
  );
}

function PanelForVisit({ auction, onMouseEnter, onMouseLeave }) {
  return (
    <div
      className={styles.panel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {auction.barrel.notes_visit_cellar_of_producer || "-"}
    </div>
  );
}

function PanelForDinner({ auction, onMouseEnter, onMouseLeave }) {
  return (
    <div
      className={styles.panel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {auction.barrel.notes_dinner_with_producer || "-"}
    </div>
  );
}

function usePanelIndex(initialIndex) {
  const timeoutRef = useRef();
  const [index, _setIndex] = useState(initialIndex);

  function setIndex(index) {
    if (index !== -1) {
      clearTimeout(timeoutRef.current);
      _setIndex(index);
    } else {
      timeoutRef.current = setTimeout(() => {
        _setIndex(-1);
      }, 500);
    }
  }

  return [index, setIndex];
}
