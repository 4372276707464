import iconSrc from "src/media/no-cellar-icon.png";
import styles from "./table-no-data.module.css";
import { ReactNode } from "react";

export function TableNoDataBox({
  title,
  content = null,
}: {
  title: ReactNode;
  content?: ReactNode;
}) {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <figure>
          <img src={iconSrc} alt="no-data" className="img-responsive" />
        </figure>
        <h3>{title ? title : "Nothing found"}</h3>

        {content}
      </div>
    </div>
  );
}
