import { SelectMultipleConfigEntry, SelectMultipleValues } from "./types";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { transformConfig } from "./utils";

type Config<TData extends object> = {
  inferDataType: TData; // forces explicit type declaration
  config: SelectMultipleConfigEntry<TData>[];
};

export function useSelectMultipleFromData<TData extends object>(
  props: Config<TData>,
) {
  const schema = z.object(
    transformConfig(props.config).reduce(
      (acc, entry) => {
        acc[entry.serializedGroupName] = z
          .array(z.union([z.string(), z.number(), z.boolean()]))
          .optional();
        return acc;
      },
      {} as Record<string, any>,
    ),
  );

  const [values, setValues] = useTypedSearchParams<SelectMultipleValues>({
    schema,
  });

  return {
    values,
    setValues,
    filterFn: (dataEntry: TData) => {
      if (!Object.values(values).flat().filter(Boolean).length) return true;

      const passedForGroup: Record<string, boolean> = {};

      for (const [serializedGroupName, vals = []] of Object.entries(values)) {
        const getValues = transformConfig(props.config).find(
          (configEntry) =>
            configEntry.serializedGroupName === serializedGroupName,
        )?.getValues;
        const values = getValues?.(dataEntry);
        if (!values) {
          passedForGroup[serializedGroupName] = false;
        } else {
          passedForGroup[serializedGroupName] = !!values.find((val) =>
            vals.includes(val as any),
          );
        }
      }
      return Object.values(passedForGroup).every(Boolean);
    },
    config: props.config,
  } as const;
}
