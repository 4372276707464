import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useMyUser } from "src/app/hooks/useMyUser";
import {
  AlertPopup,
  AlertPopupMeta,
} from "../../modules/Includes/LayoutSide/components/common/alert-popup";
import { sendCall } from "../../config/Utility";
import okSrc from "src/media/ok.svg";
import { usePageTitle } from "../../hooks/usePageTitle";
import styles from "./index.module.css";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { UserV1 } from "src/app/models/UserV1";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";
import { PAGE_TITLE } from "src/app/const";

export const Contact = () => {
  usePageTitle(PAGE_TITLE.CONTACT());
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [alertPopupMeta, setAlertPopupMeta] = useState<AlertPopupMeta | null>(
    null,
  );
  const user = useMyUser();
  const isMobile = useIsMobile();

  if (isMessageSent) {
    return <MessageSendSuccessfully />;
  }

  return (
    <>
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <div
        className={cn(
          styles.container,
          isMobile ? styles.mobile : styles.desktop,
        )}
      >
        <h1
          style={{
            fontSize: "30px",
          }}
        >
          Contact us
        </h1>
        <p style={{ fontSize: "14px" }}>
          Message us and one of our customer service experts will help you out!
        </p>
        <div>
          <Formik
            initialValues={{
              body: "",
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              sendMessage({ user: user, body: values.body })
                .then(() => setIsMessageSent(true))
                .catch((err) => {
                  setAlertPopupMeta({
                    title:
                      err.message ||
                      "An error has occured, please try again later.",
                    alertType: "warning",
                  });
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className={styles.form}>
                  <Field
                    name="body"
                    component="textarea"
                    placeholder="Write your message here..."
                    className={styles.textarea}
                    validate={(body: string) =>
                      body.length < 10 && "Must be at least 10 characters"
                    }
                  />
                  <ErrorMessage
                    name="body"
                    component="div"
                    className={styles.errorsBody}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={!!isSubmitting || !!alertPopupMeta}
                    className={styles.button}
                  >
                    <span style={{ marginRight: "10px" }}>Send Message</span>

                    {!!isSubmitting && <SpinnerSm />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

type sendMessageProps = {
  body: string;
  user: UserV1;
};

const sendMessage = async ({ user, body }: sendMessageProps) => {
  const data = await sendCall("users/createContactRequest/", "POST", {
    name: [user.first_name, user.last_name].join(" "),
    email: user.email,
    accessToken: user.accessToken,
    body,
  });

  if (data.code === 200) {
    return data.result;
  }
  throw new Error(data.error);
};

function MessageSendSuccessfully() {
  const history = useHistory();
  return (
    <div className={styles.containerMessageSendSuccessfully}>
      <img src={okSrc} alt="ok-logo" style={{ width: "100px" }} />
      <h1 style={{ fontSize: "30px" }}>Thank you</h1>
      <h3 style={{ fontFamily: "Inter", fontSize: "14px" }}>
        Your message has been sent
      </h3>

      <button onClick={() => history.goBack()} className={styles.goBack}>
        Go back
      </button>
    </div>
  );
}
