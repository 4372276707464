import { formatCurrency } from "src/app/utils/formatCurrency";
import styles from "./emp.module.css";
import { Tooltip } from "../../../tooltip";
import { useCardCtx } from "../../card-context";

export const Emp = () => {
  const cardCtx = useCardCtx();

  return (
    <div className={styles.root}>
      <Tooltip content="Estimated Market Price (EMP) is calculated by Crurated using a range of different sources, taking into account the condition and rarity of the bottles.">
        <span className={styles.label}>Estimated Market Price</span>
      </Tooltip>
      <span className={styles.value}>{formatCurrency(cardCtx.emp)}</span>
    </div>
  );
};
