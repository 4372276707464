import { ReactElement } from "react"
import { useFieldCtx } from "./field"
import { useForm } from "./form"

type ChildrenRenderProps = (accessibilityProps: {
  "aria-labelledby": string | undefined
  "aria-describedby": string | undefined
  "aria-errormessage": string | undefined
  "aria-invalid": true | undefined
  id: string
  name: string
}) => ReactElement

type Props = {
  children: ChildrenRenderProps
}

export function Control({ children }: Props) {
  const { name, labelIds, descriptionIds, errorMsgIds, nativeLabelCount } =
    useFieldCtx()
  const { controlId } = useFieldCtx()
  const ctx = useForm()
  const error = ctx.getFieldError(name)?.trim()
  const isInvalid = !!error && !!ctx.getFieldTouched(name)

  if (nativeLabelCount > 1) {
    console.error(
      'An element can only have 1 native <label>, please use the "as" prop on the secondary <CruForm.Label> to something else than "label"',
    )
  }

  const accessibilityProps = {
    "aria-labelledby": labelIds.join(" ") || undefined,
    "aria-describedby": descriptionIds.join(" ") || undefined,
    "aria-errormessage": errorMsgIds.join(" ") || undefined,
    "aria-invalid": isInvalid || undefined,
    id: controlId,
    name: name,
  }

  return children(accessibilityProps)
}
