import styles from "./single-value-badge.module.css";
import { SelectMultipleOption } from "./types";

export function SingleValueBadge({
  value,
  label,
  onRemove,
}: {
  value: SelectMultipleOption["value"];
  label: SelectMultipleOption["label"] | undefined;
  onRemove: () => void;
}) {
  return (
    <div
      className={styles.root}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onRemove();
      }}
      title={(label ?? value).toString()}
    >
      <div className={styles.inner}>{(label ?? value).toString()}</div>
    </div>
  );
}
