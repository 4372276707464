import { Checkbox } from "src/app/components/form-elements/checkbox";
import styles from "./checkbox-with-label.module.css";
import { cn } from "src/app/utils/cn";
import { ComponentProps, ReactNode } from "react";
import { omit } from "lodash";

type Props = ComponentProps<typeof Checkbox> & {
  label: string | ReactNode;
  description?: string;
  renderError?: ReactNode;
  required?: boolean;
};

export const CheckboxWithLabel = (props: Props) => {
  return (
    <label className={styles.root}>
      <Checkbox
        {...omit(props, ["label", "description", "renderError"])}
        className={styles.checkbox}
        size={20}
      />
      <h3
        className={cn(
          styles.text,
          styles.label,
          props.required && styles.required,
        )}
      >
        {props.label}
      </h3>
      <div className={cn(styles.text, styles.description)}>
        {props.description} {props.renderError}
      </div>
    </label>
  );
};
