import { Barrel } from "src/app/models/Barrel";
import styles from "./product-details.module.css";
import { Product } from "src/app/models/Product";
import { ProductLinkNft } from "./product-link-nft";

type Props = {
  product: Product | Barrel | undefined;
  hideTitle?: boolean;
};

export const ProductDetails = ({ product, hideTitle }: Props) => {
  if (!product) return null;

  if (isBarrel(product)) {
    return (
      <div className={styles.root}>
        {!hideTitle && <h3 className={styles.title}>Details</h3>}
        {!hideTitle && <hr className={styles.titleUnderline} />}
        <div className={styles.about}>{product.description}</div>
        <div className={styles.grid}>
          <div>
            <div className={styles.gridItemName}>Country / Region</div>
            <div className={styles.gridItemValue}>
              {product.formattedProvenanceLocation}
            </div>
          </div>
          <div>
            <div className={styles.gridItemName}>Alcohol level</div>
            <div className={styles.gridItemValue}>
              {product.formattedAlcoholLevel}
            </div>
          </div>

          <div>
            <div className={styles.gridItemName}>Color</div>
            <div className={styles.gridItemValue}>{product.formattedColor}</div>
          </div>
          <div>
            <div className={styles.gridItemName}>Type of wine</div>
            <div className={styles.gridItemValue}>
              {product.formattedTypeOfWine}
            </div>
          </div>

          <div>
            <div className={styles.gridItemName}>Varietal</div>
            <div className={styles.gridItemValue}>
              {product.product_varietal}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {!hideTitle && <h3 className={styles.title}>Details</h3>}
      {!hideTitle && <hr className={styles.titleUnderline} />}
      <div className={styles.about}>{product.about}</div>
      <div className={styles.grid} style={{ marginBottom: "15px" }}>
        <div>
          <div className={styles.gridItemName}>Country / Region</div>
          <div className={styles.gridItemValue}>
            {product.formattedProvenanceLocation}
          </div>
        </div>
        <div>
          <div className={styles.gridItemName}>Alcohol level</div>
          <div className={styles.gridItemValue}>
            {product.formattedAlcoholLevel}
          </div>
        </div>
        {product.isWine ? (
          <div>
            <div className={styles.gridItemName}>Bottle Size</div>
            <div className={styles.gridItemValue}>
              {product.formattedBottleSize}
            </div>
          </div>
        ) : product.isSpirit && product.age ? (
          <div>
            <div className={styles.gridItemName}>Age</div>
            <div className={styles.gridItemValue}>{product.age} years</div>
          </div>
        ) : null}
        {!product.isSpirit && (
          <div>
            <div className={styles.gridItemName}>Color</div>
            <div className={styles.gridItemValue}>{product.formattedColor}</div>
          </div>
        )}
        {product.isWine ? (
          <div>
            <div className={styles.gridItemName}>Type of wine</div>
            <div className={styles.gridItemValue}>
              {product.formattedTypeOfWine}
            </div>
          </div>
        ) : product.isSpirit && product.bottler ? (
          <div>
            <div className={styles.gridItemName}>Bottler</div>
            <div className={styles.gridItemValue}>{product.bottler}</div>
          </div>
        ) : null}
        {!product.isSpirit && (
          <div>
            <div className={styles.gridItemName}>Varietal</div>
            <div className={styles.gridItemValue}>{product.varietal}</div>
          </div>
        )}
      </div>
      <ProductLinkNft sku={product.sku} />
    </div>
  );
};

function isBarrel(product: Product | Barrel): product is Barrel {
  return !!(product as Barrel).id_barrel;
}
