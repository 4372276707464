import styles from "./index.module.css";
import { CardElement } from "@stripe/react-stripe-js";
import {
  StripeCardElementChangeEvent,
  StripeElementType,
} from "@stripe/stripe-js";

export const emptyCardElementValue: StripeCardElementChangeEvent = {
  empty: true,
  complete: false,
  error: {
    type: "validation_error",
    code: "custom message",
    message: "Please fill in all card details",
  },
  brand: "unknown",
  elementType: "card",
  value: { postalCode: "" },
};

type Props = {
  onChange: (value: StripeCardElementChangeEvent) => void;
  onBlur?: (event: { elementType: StripeElementType }) => any;
};

export const InputCard = ({ onChange, onBlur }: Props) => {
  return (
    <CardElement
      id="card-element"
      className={styles.root}
      options={{ hidePostalCode: true }}
      onChange={(value: StripeCardElementChangeEvent) => {
        if (!value.complete && !value.error) {
          onChange({
            ...value,
            error: emptyCardElementValue.error,
          });
        } else {
          onChange(value);
        }
      }}
      onBlur={onBlur}
    />
  );
};
