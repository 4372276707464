import { toSearchString } from "src/app/utils/toSearchString";

export function stringifySuggestion(
  suggestionTextOrArray: string | string[],
): string {
  return Array.isArray(suggestionTextOrArray)
    ? suggestionTextOrArray.join(" - ")
    : suggestionTextOrArray;
}

export function createFilterFn<TData extends object>({
  value,
  getSuggestionText,
  passIfNoValue,
}: {
  value: string;
  getSuggestionText: (dataItem: TData) => string | string[];
  passIfNoValue: boolean;
}) {
  return (dataItem: TData) => {
    if (!value) return passIfNoValue;
    const suggestionText = stringifySuggestion(getSuggestionText(dataItem));
    return toSearchString(suggestionText).includes(toSearchString(value));
  };
}
