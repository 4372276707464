import styles from "./excl-taxes.module.css";
import { Tooltip } from "../../../tooltip";
export const ExclOfTaxes = () => {
  return (
    <span className={styles.taxes}>
      <Tooltip
        position="top"
        delay={400}
        content="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details."
      >
        <div>*Excl. of processing fees and taxes</div>
      </Tooltip>
    </span>
  );
};
