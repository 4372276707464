import {
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { getCanScroll, getScrollMainDirection } from "./utils";

type Props = Omit<React.ComponentPropsWithoutRef<"dialog">, "open"> & {
  mandatory?: boolean;
  preventAutofocus?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

/**
 * Easy to use native <dialog> element
 * - automatically manages z-index
 * - scroll trap
 * - focus trap
 * - screen readers trap
 */
export const HtmlDialog = ({
  mandatory,
  preventAutofocus,
  onClose,
  isOpen,
  children,
  ...props
}: Props) => {
  const ref = useRef<HTMLDialogElement | null>(null);

  useScrollTrapForSafari(ref);

  useLayoutEffect(() => {
    isOpen ? ref.current?.showModal() : ref.current?.close();
  }, [isOpen]);

  return (
    <dialog
      onCancel={(e) => {
        mandatory && e.preventDefault();
      }}
      onClose={() => onClose()}
      onClick={(e) => {
        if (mandatory) return;
        // Close on backdrop click
        if (e.target !== e.currentTarget) return;
        if (!e.clientX || !e.clientY) return;
        const dialog = e.currentTarget;
        const rect = dialog.getBoundingClientRect();
        const isClickOutside =
          rect.left > e.clientX ||
          rect.right < e.clientX ||
          rect.top > e.clientY ||
          rect.bottom < e.clientY;
        if (isClickOutside) {
          onClose();
        }
      }}
      ref={ref}
      {...props}
    >
      {!!preventAutofocus && <PreventAutofocus />}
      {children}
    </dialog>
  );
};

/**
 * dialogEl.stopPropagation & dialogEl.stopImmediatePropagation do not wok
 * dialogEl.preventDefault prevents scroll even in its children
 * Therefore here we check if we are scrolling in a scrollable element
 * If not we can preventDefault and stopPropagation
 *
 * We also don't set overflow:hidden on the body to avoid layout shift (scrollbars would dissapear)
 */
function useScrollTrapForSafari(
  ref: MutableRefObject<HTMLDialogElement | null>,
) {
  useEffect(() => {
    const dialog = ref.current;
    if (!dialog) return;
    const listener = (e: WheelEvent) => {
      const target: HTMLElement | null = e.target as any;
      if (!target) return;
      const scrollDir = getScrollMainDirection(e);
      const canScroll = getCanScroll(scrollDir, target);
      if (canScroll) return;
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
    };
    dialog.addEventListener("wheel", listener as any, { passive: false });
    return () => dialog.removeEventListener("wheel", listener as any);
  }, [ref]);
}

/**
 * A button that captures the initial focus of the dialog
 * in order to prevent focus-visible styles on the first tabIndex
 * After this button is blurred it can be discarted
 */
function PreventAutofocus() {
  const [isBlurred, setIsBlurred] = useState(false);

  if (isBlurred) return null;

  return (
    <button
      autoFocus
      aria-hidden="true"
      onBlur={() => setIsBlurred(true)}
      type="button"
      style={{
        position: "absolute",
        width: "1px",
        height: "1px",
        padding: 0,
        margin: "-1px",
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        borderWidth: 0,
        opacity: 0,
        pointerEvents: "none",
      }}
    />
  );
}
