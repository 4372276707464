import { useRef } from "react";
import { useUnmount } from "react-use";

export function useThrottleFn<Args extends any[]>(
  cb: (...args: Args) => void,
  delay: number,
) {
  const trailingTimeoutRef = useRef<NodeJS.Timeout>();
  const lastCallTimestampRef = useRef(0);

  useUnmount(() => clearTimeout(trailingTimeoutRef.current));

  return (...args: Args) => {
    const now = Date.now();
    clearTimeout(trailingTimeoutRef.current);
    if (now - lastCallTimestampRef.current < delay) {
      const remainingMs = delay - (now - lastCallTimestampRef.current) + 50;
      trailingTimeoutRef.current = setTimeout(() => {
        cb(...args);
      }, remainingMs);
      return;
    }
    cb(...args);
    lastCallTimestampRef.current = now;
  };
}
