import styles from "./address-form.module.css";
import { useMyUser } from "src/app/hooks/useMyUser";
import { AddressType, AddressV1 } from "src/app/models/Address";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { CruForm } from "src/app/components/cru-form-3";
import { ID_ROLE } from "src/app/const";
import { Button } from "src/app/components/button";
import { useQueryClient } from "@tanstack/react-query";
import { Spacer } from "src/app/components/spacer";
import { parseApiErrorV1 } from "src/app/api/_parse-api-error";
import { CustomerInfoSection, emptyCountry } from "./customer-info-section";
import { ContactInfoSection } from "./contact-info-section";
import { FaExclamationCircle } from "react-icons/fa";
import { CountryOption, useCountryOptions } from "./use-country-options";
import { find } from "lodash";
import {
  useInvoiceAddressMutation,
  useShippingAddressMutation,
} from "src/app/api/adresses_to_rename_later";
import { UserV1 } from "src/app/models/UserV1";

type Props = {
  addressType: AddressType;
  initialAddress?: AddressV1;
  onError?: () => void;
  onSuccess?: () => void;
  onCancel: () => void;
};

export function AddressForm({
  addressType,
  initialAddress,
  onError,
  onSuccess,
  onCancel,
}: Props) {
  const user = useMyUser();
  const isNewAddress = !initialAddress;
  const isInvoiceAddressEA = !!user.addressInvoice[0]?.address.country.EA;
  const queryClient = useQueryClient();
  const alertPopup = useAlertPopup();
  const shippingMutation = useShippingAddressMutation();
  const invoiceMutation = useInvoiceAddressMutation();
  const countryOptions = useCountryOptions({
    addressType,
    pickOnlyEA: false,
  });

  // most invoice address fields cannot be edited
  const disableDetailFields =
    !isNewAddress &&
    addressType === "invoice" &&
    user.roles === ID_ROLE.CUSTOMER;

  const onSubmit = (values: AddressFormValues) => {
    const mutation =
      addressType === "invoice"
        ? invoiceMutation.mutateAsync(values)
        : shippingMutation.mutateAsync(values);
    //TODO: Portare popup fuori il componente, usare solo onError e OnSuccess
    return mutation
      .then(async () => {
        await queryClient.invalidateQueries();
        alertPopup.success({
          title: initialAddress
            ? "Address Edited Successfully"
            : "Address Added Successfully",
          onClose: onSuccess,
        });
      })
      .catch(async () => {
        await queryClient.invalidateQueries();
        alertPopup.error({
          title: initialAddress
            ? "Failed to Edit the Address"
            : "Failed to Add the Address",
          onClose: onError,
        });
      });
  };

  return (
    <CruForm.Form
      onSubmit={onSubmit}
      className={styles.root}
      initialValues={getInitialValues({
        initialAddress,
        countryOptions,
        isInvoiceAddressEA,
        user,
      })}
    >
      {(ctx) => (
        <div>
          <CustomerInfoSection
            ctx={ctx}
            disableDetailFields={disableDetailFields}
            addressType={addressType}
            countryOptions={countryOptions}
          />
          {/*TODO: check if it should be always visible or not  */}
          <Spacer size={16} />
          <hr />
          <Spacer size={16} />

          {!!ctx.getFieldValue("google_confirmed") && (
            <ContactInfoSection
              ctx={ctx}
              countryOptions={countryOptions}
              disableDetailFields={disableDetailFields}
            />
          )}
          <Spacer size={16} />

          <div style={{ position: "sticky", bottom: 0, background: "white" }}>
            <Spacer size={16} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                type="submit"
                isLoading={ctx.submission.isLoading}
                label="Submit"
              />
              <Spacer inline size={12} />
              <Button
                type="button"
                onClick={onCancel}
                disabled={ctx.submission.isLoading}
                variant="outlined"
                label="Cancel"
              />
            </div>
            <Spacer size={6} />
          </div>

          {!!ctx.submission.error && (
            <div className={styles.errorMessage}>
              <FaExclamationCircle size={24} />
              <div>{parseApiErrorV1(ctx.submission.error)}</div>
            </div>
          )}
        </div>
      )}
    </CruForm.Form>
  );
}

export type AddressFormValues = ReturnType<typeof getInitialValues>;

function getInitialValues({
  initialAddress,
  countryOptions,
  isInvoiceAddressEA,
  user,
}: {
  initialAddress: AddressV1 | undefined;
  countryOptions: CountryOption[];
  isInvoiceAddressEA: boolean;
  user: UserV1;
}) {
  return {
    id_address: initialAddress?.id_address,
    full_name: initialAddress?.full_name || user.fullname,
    company_name: initialAddress?.company_name || "",
    vat: initialAddress?.vat || "",
    address_line_1: initialAddress?.address.address_line_1 || "",
    address_line_2: initialAddress?.address.address_line_2 || "",
    additional_information: initialAddress?.additional_information || "",
    country:
      find(countryOptions, {
        id: initialAddress?.address.country.id_country,
      }) || emptyCountry,
    region: initialAddress?.address.region || "",
    city: initialAddress?.address.city || "",
    phone: initialAddress?.phone || "",
    postal_code: initialAddress?.address.postal_code || "",
    full_text: "",
    google_confirmed: !!initialAddress,
    confirmed: true,
    billing_is_ea: isInvoiceAddressEA,
  };
}
