import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { upfetchV1 } from "./upfetch";
import { InputAddressValue } from "../components/form-elements/input-address";
import { useMyUser } from "../hooks/useMyUser";
import { useCountriesQuery } from "./queries_to_rename_later";
import { AddressType, AddressV1, addressSchemaV1 } from "../models/Address";
import { QKEY } from "./QKEY";

/**
 * QUERIES
 */
type UseAddressesQueryConfig = {
  token: string;
  userId: number;
  type: AddressType;
  confirmedOnly: boolean;
};

const getAddresses = (config: UseAddressesQueryConfig) => ({
  queryKey: [QKEY.ADDRESS, config],
  queryFn: async () => {
    return upfetchV1({
      url: "users/getAddressesOfUser/",
      params: {
        accessToken: config.token,
        id_user: config.userId,
        type: config.type,
        only_confirmed: config.confirmedOnly,
      },
      schema: z.array(addressSchemaV1),
    });
  },
});

export const useAddressesQuery = (
  type: AddressType = "shipping",
  confirmedOnly: boolean = false,
  options: UseQueryOptions<AddressV1[]> = {},
) => {
  const { accessToken, id_user } = useMyUser();
  const config = { token: accessToken, userId: id_user, type, confirmedOnly };

  return useQuery({
    queryKey: getAddresses(config).queryKey,
    queryFn: getAddresses(config).queryFn,
    ...options,
  });
};

export const useCheckAddressMutation = (
  ignoreErrors: boolean,
  options: UseMutationOptions<
    InputAddressValue,
    any,
    { full_text: string; address: google.maps.GeocoderResult[] }
  > = {},
) => {
  const shippingCountriesIdsQuery = useCountriesQuery({
    select: (countries) =>
      countries.filter((c) => c.isEnableForShipping).map((c) => c.id),
  });

  return useMutation({
    mutationFn: ({ full_text, address }) => {
      return upfetchV1({
        url: "users/CountryCheck/",
        method: "POST",
        body: {
          address: address[0].address_components,
          billing: ignoreErrors,
        },
      }).then((result) => {
        return {
          full_text,
          address_line_1: [
            result.address_line_1StreetNumber,
            result.address_line_1,
          ]
            .filter(Boolean)
            .join(", "),
          country: result.id_country || 0,
          region: result.region || "",
          city: result.city || "",
          postal_code: result.postal_code || "",
          error: "",
          warning: "",
          isCountryAvailableForShipping: !!shippingCountriesIdsQuery.data?.find(
            (id) => result.id_country === id,
          ),
        };
      });
    },
    ...options,
  });
};

type ShippingAddressPayload = {
  vat: string;
  company_name?: string;
  additional_information?: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  region: string;
  postal_code: string;
  full_text: string;
  lat?: string;
  lng?: string;
  country: { value: number; EA: boolean } | number;
  // in case of edit
  id_address?: number;
};

export const useShippingAddressMutation = (
  options: UseMutationOptions<any, any, ShippingAddressPayload> = {},
) => {
  const user = useMyUser();
  const countriesQuery = useCountriesQuery();

  return useMutation({
    mutationFn: (payload) => {
      return upfetchV1({
        url: payload.id_address
          ? "users/EditAddressUser/"
          : "users/AddAddressUser/",
        method: "POST",
        body: {
          accessToken: user.accessToken,
          id_user: user.id_user,
          id_user_profile: user.id_user,
          type: "shipping",
          confirmed: true,
          google_confirmed: true,
          ...payload,
          country:
            typeof payload.country === "object"
              ? payload.country.value
              : payload.country,
          is_ea:
            typeof payload.country === "object"
              ? payload.country.EA
              : countriesQuery.data?.find((c) => c.id === payload.country)?.EA,
          billing_is_ea: !!user.addressInvoice[0]?.address.country.EA,
          lat: "",
          lng: "",
        },
      });
    },
    ...options,
  });
};

type InvoiceAddressPayload = {
  vat?: string;
  company_name?: string;
  additional_information?: string;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  region: string;
  postal_code: string;
  full_text: string;
  lat?: string;
  lng?: string;
  country: { value: number; EA: boolean } | number;
  // in case of edit
  id_address?: number;
};

export const useInvoiceAddressMutation = (
  options: UseMutationOptions<any, any, InvoiceAddressPayload> = {},
) => {
  const { accessToken, id_user } = useMyUser();
  const countriesQuery = useCountriesQuery();

  // countryOptions.find((c) => c.value === address.country) ||
  // emptyCountry

  return useMutation({
    mutationFn: (payload) => {
      return upfetchV1({
        url: payload.id_address
          ? "users/EditAddressUser/"
          : "users/AddAddressUser/",
        method: "POST",
        body: {
          accessToken,
          id_user,
          id_user_profile: id_user,
          type: "invoice",
          confirmed: true,
          google_confirmed: true,
          ...payload,
          country:
            typeof payload.country === "object"
              ? payload.country.value
              : payload.country,
          is_ea:
            typeof payload.country === "object"
              ? payload.country.EA
              : countriesQuery.data?.find((c) => c.id === payload.country)?.EA,
          lat: "",
          lng: "",
        },
      });
    },
    ...options,
  });
};

export const useSelectAddressMutation = (
  options: UseMutationOptions<
    any,
    any,
    { id_address: number; type_address: "shipping" | "invoice" }
  > = {},
) => {
  const user = useMyUser();

  return useMutation({
    ...options,
    mutationFn: ({
      id_address,
      type_address,
    }: {
      id_address: number;
      type_address: "invoice" | "shipping";
    }) => {
      return upfetchV1({
        url: "users/SelectAddressUser/",
        method: "POST",
        body: {
          id_user: user.id_user,
          id_user_profile: user.id_user,
          id_address: id_address,
          type_address: type_address,
          accessToken: user.accessToken,
        },
        parseResponse: (res) => res.json(),
      }).then((result) => {
        if (result.code !== 200) {
          throw new Error("Could not select the address");
        }
      });
    },
  });
};

export const useDeleteAddressMutation = (
  options: UseMutationOptions<any, any, { id_address: number }> = {},
) => {
  const user = useMyUser();

  return useMutation<any, any, { id_address: number }>({
    ...options,
    mutationFn: ({ id_address }) => {
      return upfetchV1({
        url: "users/DeleteAddressUser/",
        method: "POST",
        body: {
          id_user: user.id_user,
          id_user_profile: user.id_user,
          id_address: id_address,
          accessToken: user.accessToken,
        },
        parseResponse: (res) => res.json(),
      }).then((result) => {
        if (result.code !== 200) {
          throw new Error("Could not delete the address");
        }
        return result;
      });
    },
  });
};
