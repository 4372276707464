import { toPx } from "../../utils/toPx";

type Props = {
  iso: string;
  width?: number;
  height?: number;
};

export const Flag = ({ iso, width = 30, height = 20 }: Props) => {
  return (
    <div
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(/flags/4x3/${iso.toLowerCase()}.svg)`,
        width: toPx(width),
        height: toPx(height),
      }}
    />
  );
};
