import styles from "./layout-desktop.module.css";
import { Scrollable } from "../components/scrollable";
import { ID_IO_ROOT, ID_TOPBAR_CONTAINER } from "../const";
import { cn } from "../utils/cn";
import { FooterDesktop } from "./footer-desktop";
import { SidebarMenu } from "./sidebar-menu";
import { TopbarDesktop } from "./topbar-desktop";
import { CheckUserProfileIssues } from "../components/check-user-profile-issues";
import { ReactNode } from "react";
import { useSidebarStatus } from "./sidebar-menu/useSidebarStatus";
import { CookieBanner } from "../components/cookie-banner";

type Props = {
  children: ReactNode;
};

export const LayoutDesktop = ({ children }: Props) => {
  const sidebar = useSidebarStatus();

  return (
    <div className={cn(styles.root, sidebar.isPinned && styles.sidebarPinned)}>
      <div className={styles.sidebar}>
        <SidebarMenu
          isPinned={sidebar.isPinned}
          onUnpin={sidebar.unpin}
          onPin={sidebar.pin}
        />
      </div>

      <div className={styles.topbar} id={ID_TOPBAR_CONTAINER}>
        <TopbarDesktop />
      </div>

      <main className={styles.main}>
        <Scrollable
          key={location.pathname} // reset scroll on navigate
          viewportId={ID_IO_ROOT}
          className={styles.scrollable}
          viewportClassName="d-flex flex-column flex-root pr-1"
          type="auto"
          scrollbarMargin={0}
          scrollbarSize={6}
          scrollbarStyle={{ zIndex: 1209 }}
          overscrollBehavior="none"
          hideX
        >
          <div
            className="d-flex flex-row flex-column-fluid page"
            style={{ minHeight: "100%" }}
          >
            <div
              className={cn(
                "d-flex",
                "flex-column",
                "flex-row-fluid",
                // styles.wrapper,
                // sidebar.isPinned && styles.locked,
              )}
              id="kt_wrapper"
            >
              <div
                id="kt_content"
                className={`content d-flex flex-column flex-column-fluid`}
              >
                <div className="d-flex flex-column-fluid">
                  <div className="kt-content-inner">
                    <CheckUserProfileIssues />

                    {children}
                  </div>
                </div>
              </div>

              <FooterDesktop />
            </div>
          </div>
        </Scrollable>
      </main>

      <CookieBanner />
    </div>
  );
};
