import { A } from "../a";
import styles from "./Title.module.css";

export const Title = ({ title, linkTo, className = "", style = {} }) => {
  return (
    <h3 className={`title ${styles.root} ${className}`} style={style}>
      <A to={linkTo}>{title}</A>
    </h3>
  );
};
