import { range } from "lodash";
import { ReactNode } from "react";
import { useInView } from "react-intersection-observer";
import SkeletonCollectionCard from "src/app/modules/Includes/CustomerSide/components/skeleton/SkeletonCollectionCard";

type Props = {
  children: ReactNode[];
  isLoading: boolean;
  canLoadMore?: boolean;
  onLoadMore?: () => void;
  rootMargin?: number;
  limit?: number;
};

export const Grid = ({
  children,
  isLoading,
  canLoadMore,
  limit = 20,
  onLoadMore,
  rootMargin = 100,
}: Props) => {
  const cleanChildren = children.filter(Boolean).flat();
  const { ref } = useInView({
    rootMargin: `0px 0px ${rootMargin}px 0px`,
    onChange(inView) {
      if (inView && canLoadMore && onLoadMore) {
        onLoadMore();
      }
    },
  });

  return (
    <div
      className="lots-collection-cards-container"
      style={{ paddingBottom: "24px" }}
    >
      {cleanChildren}
      {!!isLoading &&
        range(0, Math.floor(limit / 2)).map((n) => (
          <SkeletonCollectionCard key={`skel-card-lg-${n}`} />
        ))}
      <div ref={ref} />
      <div />
      <div />
    </div>
  );
};
