import {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useEffect,
} from "react";
import { useFieldCtx } from "./field";
import { useDomId } from "src/app/hooks/useDomId";

const defaultComponent = "label" as const;

type Props<TComponent extends ElementType<any>> =
  ComponentPropsWithoutRef<TComponent> & {
    as?: TComponent;
    id?: never;
    children: ReactNode;
    required?: boolean;
  };

export const Label = <
  TComponent extends ElementType<any> = typeof defaultComponent,
>({
  as,
  children,
  required = false,
  ...props
}: Props<TComponent>) => {
  const { setLabelIds, controlId, setNativeLabelCount } = useFieldCtx();
  const Comp = as || defaultComponent;
  const isLabelElement = !as;
  const id = useDomId();

  useEffect(() => {
    if (isLabelElement) {
      setNativeLabelCount((c) => ++c);
      return () => setNativeLabelCount((c) => --c);
    } else {
      setLabelIds((ids) => [...ids, id]);
      return () => setLabelIds((ids) => ids.filter((_id) => _id !== id));
    }
  }, [setLabelIds, setNativeLabelCount, id, isLabelElement]);

  if (isLabelElement) {
    return (
      <label id={id} htmlFor={controlId} {...props}>
        {children}
        {!!required && <span style={{ color: "red" }}>&nbsp;*</span>}
      </label>
    );
  }

  return (
    <Comp id={id} {...props}>
      {children}
    </Comp>
  );
};
