import styles from "./button-discover-more.module.css";
import { gaEventConfig } from "src/app/models/GaEvent";
import { path } from "src/app/routes/path";
import { useHistory } from "react-router-dom";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";
import { cn } from "src/app/utils/cn";
import { Button } from "src/app/components/button";

export const ButtonDiscoverMore = ({ auction }: { auction: Auction }) => {
  const history = useHistory();
  const [, setSearchParams] = useTypedSearchParams({
    schema: z.object({ id: z.number().optional() }),
  });
  const hasExperienceImages =
    !!auction.experience && auction.experience.images.length > 0;

  return (
    <Button
      onClick={() => {
        setSearchParams({ id: auction.id }); // required for "back" button
        history.push(path.sales.value(auction));
      }}
      label="View Details"
      variant="ghost"
      className={cn(styles.root, hasExperienceImages && styles.experience)}
      style={{
        marginLeft: `${isAuctionOfBarrel(auction) ? "5px" : 0}`,
      }}
      gaEventConfig={gaEventConfig.viewAuctionDetails(auction)}
    />
  );
};
