import { MutableRefObject, useCallback } from "react";

export const useScrollToNextOrPrevChild = (
  parentRef: MutableRefObject<HTMLElement | null>,
) => {
  const scrollToNext = useCallback(() => {
    const parent = parentRef.current;
    if (!parent) return;
    parent.style.position = "relative";
    const children = [...parent.children] as HTMLElement[];
    const currentIndex = children.findIndex(
      (child) => child.offsetLeft + child.clientWidth > parent.scrollLeft,
    );
    const nextIndex = Math.min(currentIndex + 1, children.length - 1);
    parent.scrollTo({
      left: children[nextIndex]?.offsetLeft, // optional chaining fixes sentry error
      behavior: "smooth",
    });
  }, [parentRef]);

  const scrollToPrev = useCallback(() => {
    const parent = parentRef.current;
    if (!parent) return;
    parent.style.position = "relative";
    const children = [...parent.children] as HTMLElement[];
    const currentIndex = children.findIndex(
      (child) => child.offsetLeft >= parent.scrollLeft,
    );
    const prevIndex = Math.max(0, currentIndex - 1);
    parent.scrollTo({
      left: children[prevIndex]?.offsetLeft, // optional chaining fixes sentry error
      behavior: "smooth",
    });
  }, [parentRef]);

  return { scrollToNext, scrollToPrev };
};
