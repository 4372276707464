import styles from "./lot-details.module.css";
import { path } from "src/app/routes/path";
import { cn } from "src/app/utils/cn";
import { A } from "../../a";
import { Auction } from "src/app/models/auctions";
import { AuctionTags } from "./atoms/auction-tags";
import { BottlingDates } from "./atoms/bottling-dates";
import { ButtonDiscoverMore } from "./atoms/button-discover-more";
import { AuctionAttributes } from "./atoms/auction-attributes";
import { BottleCount } from "./atoms/bottle-count";

const strLengthMax = 150;

type Props = {
  auction: Auction;
};

export const LotDetails = ({ auction }: Props) => {
  const hasExperienceImages =
    !!auction.experience && auction.experience.images.length > 0;

  return (
    <>
      <div className={styles.root}>
        <AuctionTags auction={auction} />

        <h3
          className={cn(styles.title, hasExperienceImages && styles.lightText)}
        >
          <A to={path.sales.value(auction)}>{auction.description}</A>
        </h3>

        <div className={cn(hasExperienceImages && styles.lightText)}>
          <AuctionAttributes auction={auction} />
          <BottleCount auction={auction} />
          {!!auction.experience && (
            <div className={styles.location}>
              {location(auction.experience.country.name)}
            </div>
          )}
          <div className={styles.datesWrapper}>
            <BottlingDates auction={auction} />
            <ButtonDiscoverMore auction={auction} />
          </div>
        </div>
      </div>
    </>
  );
};

const location = (loc: string) => {
  const parts = loc.split(", ");
  if (parts.length >= 3) {
    return `${parts[parts.length - 3] + ", " + parts[parts.length - 1]}`;
  } else {
    return loc;
  }
};
