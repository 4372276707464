export const CURRENCY = {
  EURO: "EUR",
} as const;

type Options = { currency?: string; decimals?: number };

export function formatCurrency(value: undefined, options?: Options): undefined;
export function formatCurrency(value: number, options?: Options): string;
export function formatCurrency(
  value: number | undefined,
  options?: Options,
): string | undefined;
export function formatCurrency(
  value: number | undefined,
  { currency = CURRENCY.EURO, decimals = 2 }: Options = {},
) {
  if (typeof value === "undefined") return;

  return new Intl.NumberFormat([...navigator.languages], {
    style: "currency",
    currency,
    maximumFractionDigits: Number.isInteger(value) ? 0 : decimals,
  }).format(value);
}
