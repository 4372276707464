import styles from "./index.module.css";
import { useParams } from "react-router";
import { Flag } from "src/app/components/flag";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import {
  useProvenanceFromSerialQuery,
  useProvenanceFromSkuQuery,
} from "src/app/api/queries";
import logoDefaultSrc from "src/media/logos/logo-default.gif";
import { Redirect } from "react-router-dom";
import { path } from "src/app/routes/path";
import { SkeletonProvenance } from "./skeleton";
import { VideoVimeo } from "src/app/components/video-vimeo";
import { formatDate } from "src/app/utils/formatDate";
import { isValidDate } from "src/app/utils/isValidDate";
import { cn } from "src/app/utils/cn";
import { Model3D } from "src/app/components/model-3d";
import { useSearchParam } from "react-use";

export function ProvenancePage() {
  const isMobile = useIsMobile();
  const { sku, serial } = usePageParams();
  const s = useSearchParam("s");

  const fromSerialQuery = useProvenanceFromSerialQuery(serial);
  const fromSkuQuery = useProvenanceFromSkuQuery(sku);

  const product = serial ? fromSerialQuery.data?.product : fromSkuQuery.data;
  const isError = serial ? fromSerialQuery.isError : fromSkuQuery.isError;
  const isLoading = serial ? fromSerialQuery.isLoading : fromSkuQuery.isLoading;

  const VALID_SERIAL = [
    "04622682EB6E80",
    "04623C82EB6E80",
    "04623E82EB6E80",
    "04779872BA1190",
    "048E9872BA1190",
    "04A49872BA1190",
    "0415C972BA1191",
    "0426C972BA1191",
    "04702072BA1190",
  ];

  if (VALID_SERIAL.includes(serial)) {
    return (
      <iframe
        style={{ height: "100%" }}
        src={`https://provenance-new-phi.vercel.app/?serial=${serial}${s ? `&s=${s}` : ""}`}
      />
    );
  }

  if (isLoading) {
    return (
      <section className={styles.provenanceNewMain}>
        <SkeletonProvenance />
      </section>
    );
  }

  if (isError || !product) {
    return <Redirect to={path[404]()} />;
  }

  return (
    <section
      className={cn(styles.provenanceNewMain, isMobile && styles.mobile)}
    >
      {!isMobile && (
        <div className={styles.pnmLeft}>
          <ImageOfBottle
            id_product={product.id_product}
            model3d={product.media["3d"]}
          />
        </div>
      )}
      <div className={styles.pnmRight}>
        <div className={styles.pnmHeader}>
          <div className={styles.pnmHeaderTitles}>
            <div className={styles.pnmYear}>{product.vintage || "NV"}</div>
            <h1>{product.builder.name}</h1>
            <h2>{product.nameProductComplete}</h2>
          </div>
          <div className={styles.pnmHeaderLogo}>
            <img
              src={logoDefaultSrc}
              alt="Crurated"
              className={styles.imgResponsive}
            />
          </div>
        </div>

        {!!isMobile && (
          <div className={styles.pnmBottleMobileMain}>
            <ImageOfBottle
              id_product={product.id_product}
              model3d={product.media["3d"]}
            />
          </div>
        )}

        <div className={styles.pnmWineMeta2}>
          <div className={styles.pnmWineMeta2Col}>
            <div className={styles.pnmWineMetaLabel}>ALCOHOL LEVEL</div>
            <div className={styles.pnmWineMetaValue}>
              {product.alcool_level}%
            </div>
          </div>
          <div className={styles.pnmWineMeta2Col}>
            <div className={styles.pnmWineMetaLabel}>COUNTRY</div>
            <div className={styles.pnmWineMetaValue}>
              <Flag iso={product.builder.country.countryISO} />
            </div>
          </div>
          <div className={styles.pnmWineMeta2Col}>
            <div className={styles.pnmWineMetaLabel}>REGION</div>
            <div className={styles.pnmWineMetaValue}>{product.region}</div>
          </div>
          {!!product.isWine && (
            <div className={styles.pnmWineMeta2Col}>
              <div className={styles.pnmWineMetaLabel}>COLOR</div>
              <div className={styles.pnmWineMetaValue}>{product.color}</div>
            </div>
          )}
          <div className={styles.pnmWineMeta2Col}>
            <div className={styles.pnmWineMetaLabel}>BOTTLE SIZE</div>
            <div className={styles.pnmWineMetaValue}>{product.size}</div>
          </div>
          <div className={styles.pnmWineMeta2Col}>
            <div className={styles.pnmWineMetaLabel}>
              TYPE OF {product.isWine ? "WINE" : "SPIRIT"}
            </div>
            <div className={styles.pnmWineMetaValue}>
              {product.formattedTypeOfWine}
            </div>
          </div>
        </div>

        {!!fromSerialQuery.data && (
          <InfoNft
            history={fromSerialQuery.data.history}
            provenance={fromSerialQuery.data.NFTInfo.provenance}
          />
        )}

        <div className={styles.pnmDatabox}>
          {!!product.about && (
            <p>
              <span className={styles.sectionTitle}>ABOUT</span>
              {product.about}
            </p>
          )}

          {!!product.vintage_characteristics && (
            <p>
              <span className={styles.sectionTitle}>THE VINTAGE</span>
              {product.vintage_characteristics}
            </p>
          )}

          {!!product.builder.winery_description && (
            <p>
              <span className={styles.sectionTitle}>THE WINERY</span>
              {product.builder.winery_description}
            </p>
          )}

          <VideoVimeo
            src={product.link_video}
            maxWidth={640}
            style={{ marginTop: "20px" }}
          />
        </div>
      </div>
    </section>
  );
}

type InfoNftProps = {
  history: Array<{ label: string; value: string | null }>;
  provenance: string;
};

const InfoNft = ({ history, provenance }: InfoNftProps) => {
  return (
    <div className={styles.pnmStagesMain}>
      <div className={styles.pnmStagesHeader}>
        <h3>Provenance</h3>
        <p>
          <a
            style={{ color: "white" }}
            href={`${provenance}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {provenance}
          </a>
        </p>
      </div>
      <div className={styles.pnmStagesBody}>
        <div className={styles.pnmStages}>
          {history.map((entry) => (
            <div className={styles.pnmStage} key={JSON.stringify(entry)}>
              <span className={styles.pnmStageLabel}>{entry.label}</span>
              <div className={styles.pnmStageDot}></div>
              <span className={styles.pnmStageYear}>
                {isValidDate(entry.value) &&
                  formatDate(entry.value, {
                    day: undefined,
                    month: entry.value.length === 4 ? undefined : "2-digit",
                  })}

                {!isValidDate(entry.value) && entry.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

function ImageOfBottle({
  id_product,
  model3d,
}: {
  id_product: number;
  model3d: string;
}) {
  return (
    <div className={styles.pnm360Main}>
      <div className={styles.pnm360BottleHolder}>
        <Model3D id={id_product} src={model3d} />
      </div>
    </div>
  );
}

function usePageParams() {
  const { skuOrSerial = "" } = useParams<{
    skuOrSerial?: string;
    s?: string;
  }>();

  const isSku = skuOrSerial.startsWith("W") || skuOrSerial.startsWith("S");

  if (isSku) {
    return { sku: skuOrSerial, serial: "" };
  } else {
    return { sku: "", serial: skuOrSerial };
  }
}
