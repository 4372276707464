import styles from "./navbar.module.css";
import { useMyUser } from "src/app/hooks/useMyUser";
import { NavLink, useLocation } from "react-router-dom";
import { ID_TIER, ID_ROLE } from "src/app/const";
import { useIsMobile } from "src/app/hooks/useIsMobile";

export function Navbar() {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();
  const user = useMyUser();

  const isProfileTabActive = [
    "/user-profile/account-information",
    "/user-profile/my-address",
    "/user-profile/change-password",
  ].includes(pathname);

  const isCustomer = !!user.id_customer_role || user.roles === ID_ROLE.CUSTOMER;
  const canSubscribe =
    isCustomer &&
    ![ID_TIER.FOUNDER, ID_TIER.PROFESSIONAL].includes(
      user.id_customer_role ?? -1,
    );

  return (
    <div className={`${styles.root} ${isMobile ? styles.mobile : ""}`}>
      <ul className={styles.ul}>
        <li>
          <NavLink
            to="/user-profile/account-information"
            className={`${styles.navlink} ${
              isProfileTabActive ? "active" : ""
            }`}
          >
            Profile
          </NavLink>
        </li>
        {!!canSubscribe && (
          <li>
            <NavLink
              to="/user-profile/my-subscriptions"
              activeClassName="active"
              className={styles.navlink}
            >
              Subscriptions
            </NavLink>
          </li>
        )}
        {!!isCustomer && (
          <li>
            <NavLink
              to="/user-profile/method-payments"
              activeClassName="active"
              className={styles.navlink}
            >
              Payments
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}
