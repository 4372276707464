import styles from "./cell-content-actions.module.css";
import { ReactNode, useId } from "react";

type Props = {
  disabled: boolean;
  actions: Array<false | { label: ReactNode; onClick: () => void }>;
  rightSlot?: ReactNode;
};

export const MobileCellContentActions = ({
  disabled,
  actions = [],
  rightSlot,
}: Props) => {
  const key = useId();

  return (
    <div className={styles.root}>
      {actions.filter(Boolean).map((action) => (
        <button
          className={styles.action}
          onClick={() => action.onClick()}
          key={`${key}-${action.label}`}
          disabled={disabled}
        >
          {action.label}
        </button>
      ))}

      <div className={styles.rightSlot}>{rightSlot}</div>
    </div>
  );
};
