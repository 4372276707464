import styles from "./auction-tags.module.css";
import { Auction } from "src/app/models/auctions";

export const AuctionTags = ({ auction }: { auction: Auction }) => {
  return (
    <div className={styles.root}>
      {auction.tags.map((tag, index) => {
        return (
          <div key={`auction-tag-${index}`} className={styles.labelBadge}>
            <div className={styles.label}>{tag.toLowerCase()}</div>
          </div>
        );
      })}
    </div>
  );
};
