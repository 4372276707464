import styles from "./index.module.css";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { MutableRefObject, ReactNode, useRef } from "react";
import { cn } from "src/app/utils/cn";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

type Props = {
  slot1: (scrollToTop: () => void) => ReactNode;
  slot2?: (scrollToTop: () => void) => ReactNode;
  slot3?: (scrollToTop: () => void) => ReactNode;
  outerRef?: MutableRefObject<HTMLDivElement | null>;
  helloBar?: { isVisible: boolean; height: number };
  style?: CSSProperties;
};

export const StickyControls = ({
  slot1,
  slot2,
  slot3,
  outerRef,
  helloBar,
  style,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();

  const fallback = isMobile ? null : <div />;

  function scrollToTop() {
    ref.current?.scrollIntoView({ block: "start" });
  }

  return (
    <>
      <div ref={ref} />
      <div
        className={cn(styles.root, isMobile && styles.mobile)}
        style={{
          top: isMobile && helloBar?.isVisible ? `${helloBar.height}px` : 0,
          ...style,
        }}
        ref={outerRef}
      >
        <div style={isMobile ? { gridColumn: "1 / -1" } : {}}>
          {slot1(scrollToTop)}
        </div>

        {slot2?.(scrollToTop) ?? fallback}
        {slot3?.(scrollToTop) ?? fallback}
      </div>
    </>
  );
};
