import { MutableRefObject, Ref, useCallback } from "react";

export const useMergeRefs = <T>(
  ...refs: Array<
    Ref<T> | MutableRefObject<T> | React.LegacyRef<T> | undefined | null
  >
) => {
  return useCallback((val: T) => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === "function") {
        ref(val);
      } else {
        (ref as MutableRefObject<T>).current = val;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
};
