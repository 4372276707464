import { uniq } from "lodash";
import styles from "./heading.module.css";
import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";

type Props = {
  auction: Auction;
};

export const Heading = ({ auction }: Props) => {
  const builders = isAuctionOfBarrel(auction)
    ? [auction.barrel.builder.name.toLocaleLowerCase()]
    : uniq(
        auction.lot?.components
          .map((product) => product.builder.name.toLocaleLowerCase())
          .filter(Boolean),
      );

  return (
    <div className={styles.root}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2>{auction.description}</h2>
          {!!builders.length && <h3>{builders.join(", ")}</h3>}
        </div>
      </div>
    </div>
  );
};
