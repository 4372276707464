import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import * as Popover from "@radix-ui/react-popover";
import { Button } from "../button";
import { ID_IO_ROOT } from "src/app/const";
import { Portal } from "../portal";

export type TooltipProps = {
  position?: "top" | "right" | "bottom" | "left";
  content: ReactNode;
  children: ReactNode;
  delay?: number;
};

export const Tooltip = ({
  position,
  content,
  children,
  delay = 500,
}: TooltipProps) => {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={delay}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <Portal>
          <RadixTooltip.Content
            side={position}
            className={styles.content}
            sideOffset={5}
          >
            {content}
            <RadixTooltip.Arrow className={styles.arrow} />
          </RadixTooltip.Content>
        </Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export const ClickTooltip = ({
  position = "top",
  content,
  children,
}: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const openTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      setOpen(false);
    };

    const scrollContainer =
      document.getElementById(ID_IO_ROOT) || document.body;

    if (open) {
      scrollContainer.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }

    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, [open]);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
  };

  const handleMouseEnter = () => {
    openTimer.current = setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    if (openTimer.current) {
      clearTimeout(openTimer.current);
      openTimer.current = null;
    }
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={handleOpenChange}>
      <Popover.Trigger
        asChild
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Button variant="ghost" label={children} style={{ padding: 0 }} />
      </Popover.Trigger>
      <Portal>
        <Popover.Content
          side={position}
          className={styles.content}
          sideOffset={5}
        >
          {content}
        </Popover.Content>
      </Portal>
    </Popover.Root>
  );
};
