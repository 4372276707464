import { CruFormContextType } from "src/app/components/cru-form/types";
import { SelectTierFormValues } from ".";
import { CruForm } from "src/app/components/cru-form";
import styles from "./tier-radio.module.css";
import { Radio } from "src/app/components/form-elements/radio";
import { TierName } from "src/app/const";
import { usePrice } from "../utils";

type Props = {
  cruForm: CruFormContextType<SelectTierFormValues>;
  tierName: TierName;
  cardUrl: string;
};

export const TierRadio = ({ cardUrl, cruForm, tierName }: Props) => {
  const isChecked = cruForm.values.tier === tierName;
  const annual = cruForm.values.annual;

  const price = usePrice({
    tierName: tierName,
    annual,
  });

  return (
    <label className={styles.root}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.card}>
            <img src={cardUrl} alt={`card-${tierName}`} />
          </div>
          <div className={styles.title}>
            {tierName}
            <div className={styles.price}>
              <span>{price}</span>&nbsp;
              {tierName !== "explorer" && (
                <span>{`/ ${annual ? "yr" : "mo"}`}</span>
              )}
            </div>
          </div>
        </div>
        <CruForm.Field
          name="tier"
          component={Radio}
          cruForm={cruForm}
          onChange={(e) => {
            cruForm.setValues({ tier: e.target.value as TierName });
          }}
          value={tierName}
          checked={isChecked}
          size={20}
        />
      </div>
    </label>
  );
};
