import { z } from "zod";

export const apiPaymentCardV1 = z.object({
  id_payment_method: z.string(),
  brand: z.string(),
  country: z.string(),
  exp_month: z.number(),
  exp_year: z.number(),
  last4: z.string(),
  is_selected: z.union([z.literal(0), z.literal(1)]),
});

type ApiPaymentCardV1 = z.infer<typeof apiPaymentCardV1>;

export class PaymentCardV1 {
  id_payment_method: string;
  brand: string;
  country: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  is_selected: boolean;

  constructor(apiCard: ApiPaymentCardV1) {
    this.id_payment_method = apiCard.id_payment_method;
    this.brand = apiCard.brand;
    this.country = apiCard.country;
    this.exp_month = apiCard.exp_month;
    this.exp_year = apiCard.exp_year;
    this.last4 = apiCard.last4;
    this.is_selected = !!apiCard.is_selected;
  }
}
