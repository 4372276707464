import { z } from "zod";

export const apiPaymentMethodSchemaV1 = z.object({
  id_method_payment: z.number(),
  name: z.string(),
  slug: z.string(),
  to_confirm: z.union([z.literal(0), z.literal(1)]),
  active: z.union([z.literal(0), z.literal(1)]),
  deleted: z.union([z.literal(0), z.literal(1)]),
});

type ApiPaymentMethodV1 = z.infer<typeof apiPaymentMethodSchemaV1>;

export class PaymentMethodV1 {
  id_method_payment: number;
  name: string;
  slug: string;
  to_confirm: boolean;
  active: boolean;
  deleted: boolean;

  constructor(apiMethod: ApiPaymentMethodV1) {
    this.id_method_payment = apiMethod.id_method_payment;
    this.name = apiMethod.name;
    this.slug = apiMethod.slug;
    this.to_confirm = !!apiMethod.to_confirm;
    this.active = !!apiMethod.active;
    this.deleted = !!apiMethod.deleted;
  }
}
