import styles from "./index.module.css";
import "react-phone-number-input/style.css";
import { MyAddressRow } from "./my-address-row";
import { UserProfileSidebar } from "../profile-sidebar";
import { useMyUser } from "src/app/hooks/useMyUser";
import { AddressForm } from "src/app/components/address-form/address-form";
import { Button } from "src/app/components/button";
import { useIsFetching } from "@tanstack/react-query";
import { QKEY } from "src/app/api/QKEY";
import { cn } from "src/app/utils/cn";
import { Drawer } from "src/app/components/drawer-2";
import { useSlot } from "src/app/hooks/useSlot";

export function MyAddress() {
  const user = useMyUser();
  const slot = useSlot();
  const isFetching = useIsFetching({ queryKey: [QKEY.ME] });

  return (
    <>
      {slot.output}
      <div className={styles.root}>
        <UserProfileSidebar />
        <div className={cn(styles.inner, !!isFetching && styles.isFetching)}>
          <h3>Invoice Address</h3>
          {!user.addressInvoice.length && "No address for invoice"}
          {user.addressInvoice.map((item, index) => (
            <MyAddressRow
              key={index}
              dataAddress={item}
              typeAddress={item.type}
            />
          ))}
          {user.addressInvoice.length === 0 && (
            <Button
              style={{ marginTop: "24px", marginLeft: "24px" }}
              label="Add Invoice Address"
              disabled={!!isFetching}
              onClick={() =>
                slot.show((dismiss) => (
                  <Drawer
                    title="Add Address for invoice"
                    position="right"
                    width={570}
                    onClosed={() => {
                      dismiss();
                    }}
                    render={({ startClosing }) => (
                      <AddressForm
                        onSuccess={() => startClosing()}
                        onCancel={() => startClosing()}
                        addressType="invoice"
                      />
                    )}
                  />
                ))
              }
            />
          )}
          <h3 style={{ marginTop: "24px" }}>Shipping Address</h3>
          {!user.addressShipping.length && "No Address For Shipping"}
          {user.addressShipping.map((item, index) => (
            <MyAddressRow
              key={index}
              dataAddress={item}
              typeAddress={item.type}
            />
          ))}
          <Button
            style={{ marginTop: "24px", marginLeft: "24px" }}
            disabled={!!isFetching}
            label="Add Shipping Address"
            onClick={() =>
              slot.show((dismiss) => (
                <Drawer
                  title="Add Address for shipping"
                  position="right"
                  width={570}
                  onClosed={() => {
                    dismiss();
                  }}
                  render={({ startClosing }) => (
                    <AddressForm
                      onSuccess={() => startClosing()}
                      onCancel={() => startClosing()}
                      addressType="shipping"
                    />
                  )}
                />
              ))
            }
          />
        </div>
      </div>
    </>
  );
}
