// const hasCard = !user.checkUser.is_missing_card;
// (!user.addressInvoice.length || !hasCard) &&
//                 user.id_customer_role !== 14
//                   ? setShowAddressAndCardModal(true)
//                   : cardCtx.handleSubmit()

import styles from "./step-missing-info.module.css";
import { useState } from "react";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useMyCardsQuery } from "src/app/api/payments";
import { AddCardForm } from "src/app/pages/my-profile/my-payments/add-card-form";
import { PiWarningCircleDuotone } from "react-icons/pi";
import { getStripe } from "src/app/hooks/useLoadStripe";
import { Elements } from "@stripe/react-stripe-js";
import { Button } from "src/app/components/button";
import { AddressForm } from "src/app/components/address-form/address-form";

type Props = {
  onClose: () => void;
  onComplete: () => void;
  isForAuction?: boolean;
};

type Step = "init" | "address" | "card";

const stripePromise = getStripe();

export default function StepMissingInfo({
  onClose,
  onComplete,
  isForAuction,
}: Props) {
  const [step, setStep] = useState<Step>("init");
  const user = useMyUser();
  const { data: cardsData } = useMyCardsQuery({ staleTime: 2000 });
  const hasCard = !!cardsData?.length;
  const hasAddress = !!user.addressInvoice.length;
  const handleConfirm = () => {
    setStep(hasAddress ? "card" : "address");
  };

  return step === "address" ? (
    <StepAddress
      onClose={onClose}
      onSuccess={() => (hasCard ? onComplete() : setStep("card"))}
    />
  ) : step === "card" ? (
    <StepCard onClose={onClose} onSuccess={() => onComplete()} />
  ) : (
    <StepInit
      isForAuction={isForAuction}
      onCancel={onClose}
      onConfirm={handleConfirm}
    />
  );
}

function StepInit({
  onCancel,
  onConfirm,
  isForAuction,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  isForAuction?: boolean;
}) {
  return (
    <>
      <div className={styles.step1}>
        <PiWarningCircleDuotone size={40} color="dodgerblue" />
        <h3 style={{ marginBottom: "0", marginTop: "12px" }}>
          Information Required
        </h3>
        <div className={styles.step1Text}>
          {isForAuction
            ? "To proceed with your offer, please enter your billing address and a valid credit card."
            : "To proceed with your purchase, please enter your billing address and a valid credit card."}
        </div>
      </div>
      <div className={styles.step1Button}>
        <Button
          variant="outlined"
          label="Cancel"
          type="button"
          onClick={onCancel}
        />
        <Button
          variant="contained"
          label="Continue"
          type="button"
          onClick={onConfirm}
        />
      </div>
    </>
  );
}

function StepAddress({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <AddressForm
      addressType="invoice"
      onCancel={onClose}
      onError={onClose}
      onSuccess={onSuccess}
    />
  );
}

function StepCard({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <Elements stripe={stripePromise}>
      <AddCardForm
        className={styles.stepCardForm}
        onError={onClose}
        onSuccess={onSuccess}
      />
    </Elements>
  );
}
