import styles from "./scale.module.css";
import { useId, useMemo } from "react";

type Props = {
  max: number;
  mul: number;
  numberOfSteps: number;
  spaceTopPercentage: number;
};

export function Scale({ max, mul, numberOfSteps, spaceTopPercentage }: Props) {
  const keySuffix = useId();

  const steps = useMemo(
    () =>
      Array(Math.round(numberOfSteps))
        .fill(0)
        .map((_, index) => +((max / numberOfSteps) * index).toFixed(2))
        .concat(max)
        .reverse(),
    [max, numberOfSteps],
  );

  return (
    <div className={styles.root} style={{ top: `${spaceTopPercentage}%` }}>
      {steps.map((step, i) => (
        <div
          className={styles.line}
          key={`step-${step}-${keySuffix}`}
          style={{ opacity: i !== steps.length - 1 ? 1 : 0 }}
        >
          <div className={styles.value}>{displayLiters(step * mul)}</div>
        </div>
      ))}
    </div>
  );
}

function displayLiters(liters: number) {
  if (!liters) return liters;
  return new Intl.NumberFormat([...navigator.languages], {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format(liters);
}
