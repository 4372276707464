import { useParams } from "react-router";
import { useSerialMintedQueryV1 } from "src/app/api/queries_to_rename_later";
import styles from "./index.module.css";
import { SplashScreen } from "src/app/layouts/splash-screen";

function NftMetadata() {
  const syntaxHighlight = (json: string) => {
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?)|(\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      (match) => {
        let cls = styles.number;
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = styles.key;
          } else {
            cls = styles.string;
          }
        } else if (/true|false/.test(match)) {
          cls = styles.boolean;
        } else if (/null/.test(match)) {
          cls = styles.null;
        }
        return `<span class="${cls}">${match}</span>`;
      },
    );
  };

  const { tokenId } = useParams<{ tokenId: string }>();

  const { data, isLoading, isError } = useSerialMintedQueryV1(tokenId);

  if (isLoading) return <SplashScreen />;
  if (isError || !data) return;

  const highlightedJson = syntaxHighlight(JSON.stringify(data, null, 2));
  return (
    <div className={styles.jsonContainer}>
      <pre
        className={styles.jsonPre}
        dangerouslySetInnerHTML={{ __html: highlightedJson }}
      />
    </div>
  );
}

export default NftMetadata;
