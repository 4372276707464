import { useQueryClient } from "@tanstack/react-query";
import { parseApiError } from "src/app/api/_parse-api-error";
import {
  useRedeemAllGiftsMutation,
  useRedeemGiftMutation,
} from "src/app/api/mutations_new_to_rename";
import { useMyPendingGifts } from "src/app/api/queries_to_rename_later";
import { A } from "src/app/components/a";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { Button } from "src/app/components/button";
import { DesktopCellContentText } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-text";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { PAGE_TITLE } from "src/app/const";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { path } from "src/app/routes/path";
import { formatDate } from "src/app/utils/formatDate";

export default function PendingGiftsPage() {
  usePageTitle(PAGE_TITLE.PENDING_GIFTS());

  const alertPopup = useAlertPopup();
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useMyPendingGifts();

  const redeemGiftMutation = useRedeemGiftMutation({
    onSuccess: () => {
      queryClient.invalidateQueries();
      return alertPopup.success({
        title: "Gift successfully redeemed!",
        content: "Go to your cellar to see your new asset",
      });
    },
    onError: (error) => {
      return alertPopup.error({
        title: "Error redeeming your gift",
        content: parseApiError(error),
      });
    },
  });

  const redeemAllGiftsMutation = useRedeemAllGiftsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries();
      return alertPopup.success({
        title: "Gifts successfully redeemed!",
        content: "Go to your cellar to see your new assets",
      });
    },
    onError: (error) => {
      return alertPopup.error({
        title: "Error redeeming your gifts",
        content: parseApiError(error),
      });
    },
  });

  return (
    <div style={{ margin: "20px 0px" }}>
      <Button
        variant="contained"
        label="Redeem All"
        disabled={!data || !data.length || isFetching}
        onClick={() => {
          alertPopup.prompt({
            title: `Redeem gift`,
            content: `Do you want to redeem all gifts?`,
            actionButton: {
              label: "Yes, please",
              onClick: () => redeemAllGiftsMutation.mutate({}),
            },
          });
        }}
        style={{ marginBottom: 20 }}
      />
      <TableCustomerSide
        urlPrefix=""
        data={data}
        getRowId={(row) => row.id_gift}
        isFetching={isFetching}
        isLoading={isLoading}
        noDataTitle="No Gifts"
        noDataContent={
          <div>
            <p>You have no pending gifts to redeem</p>
            <Button
              as={A}
              to={path.myAssets.value()}
              label="Go to Your Cellar"
            />
          </div>
        }
        columns={[
          {
            header: "Gift ID",
            id: "gift-id",
            getSortingValue: (row) => row.id_gift,
            cellDesktop: (row) => (
              <DesktopCellContentText content={row.id_gift} />
            ),
            cellMobile: (row) => (
              <MobileCellContentText content={row.id_gift} />
            ),
          },
          {
            header: "Gifted By",
            id: "gifted-by",
            getSortingValue: (row) => row.user_sender,
            cellDesktop: (row) => (
              <DesktopCellContentText content={row.user_sender} />
            ),
            cellMobile: (row) => (
              <MobileCellContentText content={row.user_sender} />
            ),
          },
          {
            header: "Description Gift",
            id: "description-gift",
            getSortingValue: (row) => row.description_auction,
            cellDesktop: (row) => (
              <DesktopCellContentText content={row.description_auction} />
            ),
            cellMobile: (row) => (
              <MobileCellContentText content={row.description_auction} />
            ),
          },
          {
            header: "Date",
            id: "gift-date",
            getSortingValue: (row) => formatDate(row.date_to_send_gift),
            cellDesktop: (row) => (
              <DesktopCellContentText
                content={formatDate(row.date_to_send_gift)}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentText
                content={formatDate(row.date_to_send_gift)}
              />
            ),
          },
          {
            header: "Redeem",
            id: "actions",
            width: 120,
            cellDesktop: (row) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Button
                  variant="contained"
                  label="Redeem"
                  style={{ minHeight: 36, fontSize: 12 }}
                  isLoading={redeemGiftMutation.isLoading}
                  onClick={() => {
                    alertPopup.prompt({
                      title: `Redeem gift`,
                      content: `Do you want to redeem this gift by ${row.user_sender}?`,
                      actionButton: {
                        label: "Yes, please",
                        onClick: () =>
                          redeemGiftMutation.mutate({
                            id_gift: row.id_gift,
                          }),
                      },
                    });
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
