import { useState } from "react";
import { useInterval } from "src/app/hooks/useInterval";
import { AlertPopup } from "src/app/modules/Includes/LayoutSide/components/common/alert-popup";
import { fetchInfoConfig } from "src/app/api/config";
import { minutes } from "src/app/utils/time";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";

export const CheckAppVersion = () => {
  const hasChanged = useHasVersionChanged();

  if (!hasChanged) return null;

  return (
    <AlertPopup
      alertPopupMeta={{
        alertType: "question",
        title: "New version available",
        subTitle: "Please refresh the page to update",
        closeButton: "Do it later",
        confirmButton: "Update now",
        onConfirmButton: () => window.location.reload(),
      }}
    />
  );
};

function useHasVersionChanged() {
  const [hasChanged, setHasChanged] = useState(false);

  const currentProjectVersion = useSystemConfig()?.projectVersion;

  // check the version every 5 minutes
  useInterval((interval) => {
    fetchInfoConfig().then((config) => {
      if (!currentProjectVersion) return;
      if (config.projectVersion === currentProjectVersion) return;
      setHasChanged(true);
      clearInterval(interval);
    });
  }, minutes(5));

  return hasChanged;
}
