import { isResponseError } from "up-fetch";

export const parseApiError = (error: any) => {
  if (!error) return "";
  if (isResponseError<{ error: string }>(error)) {
    if (!error.response.data.error)
      return error.message || "An error has occurred, please try again later.";
    return error.response.data.error.replace("CruratedEx:", "").trim();
  }
  return "An error has occurred, please try again later.";
};

export const parseApiErrorV1 = (error: any) => {
  if (!error) return "";
  if (isResponseError<{ error: string }>(error)) {
    if (!error.response.data.error)
      return error.message || "An error has occurred, please try again later.";
    return error.response.data.error.replace("CruratedEx:", "").trim();
  }
  return "An error has occurred, please try again later.";
};
