import { Auction, isAuctionOfLot } from "src/app/models/auctions";
import { CardCtxProvider } from "../../card-context";
import { CardForStore } from "./card-for-store";
import { CardForCollection } from "./card-for-collection";
import { CardForSingleLot } from "./card-for-single-lot";

export type CardProps = {
  className?: string;
  cardId: string;
  auction: Auction;
};

export const Card = ({ auction, ...props }: CardProps) => {
  if (auction.isForStore) {
    return (
      <CardCtxProvider initialAuction={auction}>
        <CardForStore {...props} />
      </CardCtxProvider>
    );
  }

  if (isAuctionOfLot(auction) && auction.isOfSingleLot) {
    return (
      <CardCtxProvider initialAuction={auction}>
        <CardForSingleLot {...props} />
      </CardCtxProvider>
    );
  }

  return (
    <CardCtxProvider initialAuction={auction}>
      <CardForCollection {...props} />
    </CardCtxProvider>
  );
};
