import { Stripe, loadStripe } from "@stripe/stripe-js";
import { env } from "src/env";

/**
 * @see https://vercel.com/guides/getting-started-with-nextjs-typescript-stripe
 */

let stripePromise: Promise<Stripe | null>;

export const getStripe = () => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!stripePromise) {
    stripePromise = loadStripe(env.REACT_APP_PUBLIC_KEY_STRIPE);
  }
  return stripePromise;
};
