import { formatDateTime } from "src/app/utils/formatDate";
import styles from "./cell-content-dates.module.css";

export function CellContentDates({ start, end, deadline }) {
  const _start = start || end;
  const _end = end || start;
  const isOnSpecificDate = !!_start && !!end && _start === _end;
  const isDateRange = !isOnSpecificDate && !!_start && !!end;
  const isDeadline = !isOnSpecificDate && !isDateRange && !!deadline;

  if (isOnSpecificDate) {
    return (
      <div className={styles.root}>
        <div className={styles.label}>Happens on: </div>
        <div className={styles.date}>
          {formatDateTime(_start, { ignoreTimezone: true })}
        </div>
      </div>
    );
  }
  if (isDateRange) {
    return (
      <div className={styles.root}>
        <div className={styles.label}>From: </div>
        <div className={styles.date}>
          {formatDateTime(_start, { ignoreTimezone: true })}
        </div>
        <div className={styles.label}>To:</div>
        <div className={styles.date}>
          {formatDateTime(_end, { ignoreTimezone: true })}
        </div>
      </div>
    );
  }
  if (isDeadline) {
    return (
      <div className={styles.root}>
        <div className={styles.label}>Deadline:</div>
        <div className={styles.date}>
          {formatDateTime(deadline, { ignoreTimezone: true })}
        </div>
      </div>
    );
  }
  return "-";
}
