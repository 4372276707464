/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  useCancelSubscriptionMutation,
  useMySubscriptionsQuery,
  usePaySubscriptionMutation,
} from "src/app/api/subscription";
import { Badge, BadgePaletteType } from "src/app/components/badge";
import { Button } from "src/app/components/button";
import { DesktopCellContentText } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-text";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { setDataTimeZone } from "src/app/config/Utility";
import { MobileCellContentName } from "src/app/pages/outstanding-payments/cell-content-name-desktop";
import { DesktopCellContentName } from "src/app/pages/outstanding-payments/cell-content-name-mobile";
import { CgDetailsMore } from "react-icons/cg";
import { FaTrash } from "react-icons/fa";
import { useSlot } from "src/app/hooks/useSlot";
import { Drawer } from "src/app/components/drawer-2";
import SubscriptionHistoryTable from "./subscription-history-table";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { useQueryClient } from "@tanstack/react-query";
import { QKEY } from "src/app/api/QKEY";
import { parseApiErrorV1 } from "src/app/api/_parse-api-error";

export const subscriptionStates: {
  [key: number]: {
    label: string;
    palette: BadgePaletteType;
  };
} = {
  0: {
    label: "Cancelled",
    palette: "mild-danger",
  },
  1: {
    label: "Active",
    palette: "success",
  },
  2: {
    label: "Unpaid",
    palette: "danger",
  },
  3: {
    label: "Trialing",
    palette: "pastel-deep-purple",
  },
  4: {
    label: "Past due",
    palette: "pastel-blue",
  },
  5: {
    label: "Incomplete",
    palette: "pastel-brown",
  },
  6: {
    label: "Incomplete expired",
    palette: "pastel-pink",
  },
  7: {
    label: "Downgrade",
    palette: "pastel-yellow",
  },
  8: {
    label: "Upgrade",
    palette: "pastel-green",
  },
  9: {
    label: "Pending Cancel",
    palette: "pastel-purple",
  },
  10: {
    label: "Pending Downgrade",
    palette: "warning",
  },
};

export default function MySubscriptionTable() {
  const queryClient = useQueryClient();
  const slot = useSlot();
  const alertPopup = useAlertPopup();

  const { data, isFetching, isLoading, refetch } = useMySubscriptionsQuery();

  const cancelSubscriptionMutation = useCancelSubscriptionMutation({
    onSuccess: () => {
      alertPopup.success({
        title: "Subscription successfully canceled",
      });
      queryClient.invalidateQueries([QKEY.ME]);
      refetch();
    },
    onError: (error) => {
      alertPopup.error({
        title: "Error on canceling subscription",
        content: parseApiErrorV1(error),
      });
      queryClient.invalidateQueries([QKEY.ME]);
      refetch();
    },
  });

  const paySubscriptionMutation = usePaySubscriptionMutation({
    onSuccess: () => {
      alertPopup.success({
        title: "Subscription successfully paid",
      });
      queryClient.invalidateQueries([QKEY.ME]);
      refetch();
    },
    onError: (error) => {
      alertPopup.error({
        title: "Error on paying subscription",
        content: parseApiErrorV1(error),
      });
      queryClient.invalidateQueries([QKEY.ME]);
      refetch();
    },
  });

  return (
    <>
      {slot.output}
      <TableCustomerSide
        urlPrefix=""
        data={data}
        getRowId={(row) => row.id_subscription_stripe}
        isFetching={isFetching}
        isLoading={isLoading}
        noDataTitle="No Subscriptions"
        noDataContent={
          <div>
            <p>No Subscriptions</p>
          </div>
        }
        columns={[
          {
            header: "Date Subscription",
            id: "date-subscription",
            getSortingValue: (row) =>
              setDataTimeZone(row.date_subscription, "YYYY-MM-DD"),
            cellDesktop: (row) => (
              <DesktopCellContentText
                content={setDataTimeZone(row.date_subscription, "YYYY-MM-DD")}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentText
                content={setDataTimeZone(row.date_subscription, "YYYY-MM-DD")}
              />
            ),
          },
          {
            header: "Status",
            id: "status",
            getSortingValue: (row) => row.status,
            cellDesktop: (row) => (
              <Badge
                label={subscriptionStates[row.status]?.label || "Undefined"}
                palette={subscriptionStates[row.status]?.palette || "black"}
              />
            ),
          },
          {
            header: "Plan",
            id: "plan",
            getSortingValue: (row) => row.name,
            cellDesktop: (row) => (
              <DesktopCellContentName
                content={row.name}
                serif
                details={`(${row.recurring_type})`}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentName
                content={row.name}
                serif
                details={`(${row.recurring_type})`}
              />
            ),
          },
          {
            header: "Next Payment Cycle",
            id: "next-payment-cycle",
            getSortingValue: (row) =>
              setDataTimeZone(row.date_end_subscription, "YYYY-MM-DD"),
            cellDesktop: (row) => (
              <DesktopCellContentText
                content={setDataTimeZone(
                  row.date_end_subscription,
                  "YYYY-MM-DD",
                )}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentText
                content={setDataTimeZone(
                  row.date_end_subscription,
                  "YYYY-MM-DD",
                )}
              />
            ),
          },
          {
            header: "Actions",
            id: "actions",
            cellDesktop: (row) => (
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <Button
                  variant="outlined"
                  icon={<CgDetailsMore />}
                  tooltip={"View History"}
                  style={{ height: 36 }}
                  onClick={() =>
                    slot.show((dismiss) => (
                      <Drawer
                        title={"Subscription History"}
                        onClosed={dismiss}
                        width={1000}
                        render={() => (
                          <SubscriptionHistoryTable
                            data={row.story_subscription}
                          />
                        )}
                      />
                    ))
                  }
                />
                {[2, 4, 5, 6].includes(row.status) && (
                  <Button
                    variant="contained"
                    label={"Pay now"}
                    style={{ minHeight: 36, fontSize: 12 }}
                    isLoading={paySubscriptionMutation.isLoading}
                    onClick={() =>
                      paySubscriptionMutation.mutate({
                        id_subscription_handling: row.id_subscription_handling,
                      })
                    }
                  />
                )}
                {[1, 3].includes(row.status) && (
                  <Button
                    variant="contained"
                    icon={<FaTrash size={14} />}
                    tooltip={"Cancel Subscription"}
                    style={{ height: 36 }}
                    onClick={() =>
                      alertPopup.prompt({
                        title: "Are you sure to cancel this subscription?",
                        content: "You won't be able to revert this!",
                        actionButton: {
                          label: "Confirm",
                          onClick: () => {
                            cancelSubscriptionMutation.mutate({
                              id_subscription_handling:
                                row.id_subscription_handling,
                            });
                          },
                        },
                      })
                    }
                  />
                )}
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
