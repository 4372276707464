import { useEffect, useState } from "react";

export const ButtonFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => () => document.exitFullscreen(), []);

  useEffect(() => {
    const listener = () => setIsFullScreen(!!document.fullscreenElement);
    document.addEventListener("fullscreenchange", listener);
    return () => document.removeEventListener("fullscreenchange", listener);
  });

  function handleFullScreen() {
    const root = document.getElementById("root");
    if (!("requestFullscreen" in root)) return;
    root.requestFullscreen();
    setIsFullScreen(true);
  }

  if (isFullScreen) return null;

  return (
    <button
      onClick={handleFullScreen}
      style={{
        background: "#0b7ebd",
        color: "white",
        padding: "8px 14px",
        borderRadius: "3px",
        border: "none",
        fontSize: "18px",
        fontWeight: 500,
      }}
    >
      Enter Full Screen Mode
    </button>
  );
};
