import { Dispatch, SetStateAction } from "react";
import styles from "./collection-of-lot-slider.module.css";
import {
  ShadcnCarousel,
  ShadcnCarouselApi,
  ShadcnCarouselContent,
  ShadcnCarouselItem,
  useDotButton,
} from "src/app/components/shadcn-carousel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { Img } from "src/app/components/img";
import { cn } from "src/app/utils/cn";

type Props = {
  auction: AuctionOfLot;
  setCarouselApi: Dispatch<SetStateAction<ShadcnCarouselApi>>;
  carouselApi: ShadcnCarouselApi;
  activeSlideIndex: number;
  onChangeActiveSlideIndex: (index: number) => void;
};

export const CollectionOfLotSlider = ({
  auction,
  setCarouselApi,
  carouselApi,
  activeSlideIndex,
  onChangeActiveSlideIndex,
}: Props) => {
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(carouselApi);

  return (
    <ShadcnCarousel
      opts={{
        align: "start",
        skipSnaps: true,
        slidesToScroll: 1,
      }}
      setApi={setCarouselApi}
      gap="3rem"
      className={styles.shadcnCarouselRoot}
    >
      <ShadcnCarouselContent>
        {auction.lot?.components.map((product, index) => (
          <ShadcnCarouselItem
            key={`slide-${index}-${product.id}`}
            className={styles.shadcnCarouselItem}
          >
            <div
              onClick={() => onChangeActiveSlideIndex(index)}
              className={cn(
                styles.item,
                activeSlideIndex === index && styles.active,
              )}
            >
              <Img src={product.media.cover} className={styles.img} alt="" />
            </div>
          </ShadcnCarouselItem>
        ))}
      </ShadcnCarouselContent>

      {/* Dot Pagination */}
      {scrollSnaps.length > 1 && (
        <div className={styles.pagination}>
          {scrollSnaps.map((_, index) => (
            <button
              key={`ind-bef-${index}`}
              tabIndex={-1}
              className={cn(
                styles.dotButton,
                index === selectedIndex && styles.active,
              )}
              onClick={() => {
                onDotButtonClick(index);
                onChangeActiveSlideIndex(index);
              }}
            >
              <div />
            </button>
          ))}
        </div>
      )}
    </ShadcnCarousel>
  );
};
