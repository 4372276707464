import {
  BaseValues,
  FormErrors,
  FormFieldName,
  FormTouched,
  FormValidators,
  FormValues,
  GetFieldError,
  GetFieldTouched,
  GetFieldValue,
  ValidateFn,
} from "./types";

export function __scrollToFirstErrorField() {
  // exit if the code is running on the server
  if (typeof window === "undefined") return;
  const firstInvalidElement = document.querySelectorAll(
    '[aria-invalid="true"]',
  )[0] as HTMLElement | null;
  if (!firstInvalidElement) return;
  const scrollMarginBlockStart =
    getComputedStyle(firstInvalidElement).scrollMarginBlockStart;
  firstInvalidElement.style.scrollMarginBlockStart = "140px";
  firstInvalidElement.scrollIntoView({ behavior: "smooth", block: "start" });
  firstInvalidElement.style.scrollMarginBlockStart = scrollMarginBlockStart;
}

export function __getFieldValue<TValues extends BaseValues>(
  values: FormValues<TValues>,
): GetFieldValue<TValues> {
  return (name) => values[name];
}

export function __getFieldError<TValues extends BaseValues>(
  errors: FormErrors<TValues>,
): GetFieldError<TValues> {
  return (name) => errors[name];
}

export function __getFieldTouched<TValues extends BaseValues>(
  touched: FormTouched<TValues>,
): GetFieldTouched<TValues> {
  return (name) => touched[name];
}

export function __getErrorsList<TValues extends BaseValues>(
  errors: FormErrors<TValues>,
): string[] {
  return Object.values(errors as Record<string, string>).filter(Boolean);
}

export function __computeErrors<TValues extends BaseValues>(
  validators: FormValidators<TValues>,
  values: FormValues<TValues>,
) {
  return Object.entries(validators).reduce((acc, entry) => {
    type FieldName = FormFieldName<TValues>;
    type Validate = ValidateFn<TValues, FormFieldName<TValues>> | undefined;
    const fieldName: FieldName = entry[0] as any;
    const validateFn: Validate = entry[1] as any;
    const getFieldValue = __getFieldValue(values);
    const error =
      validateFn?.(getFieldValue(fieldName), getFieldValue) || undefined;
    return { ...acc, [fieldName]: error };
  }, {} as FormErrors<TValues>);
}

export function __toFormValues<TValues extends BaseValues>(
  values: TValues,
): FormValues<TValues> {
  const formValues = {} as any;

  Object.entries(values).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      val.forEach((v, i) => {
        formValues[`${key}.${i}`] = v;
      });
    } else {
      formValues[key] = val;
    }
  });
  return formValues;
}

export function __toOriginalValues<TValues extends BaseValues>(
  values: FormValues<TValues>,
): TValues {
  const output = {} as TValues;
  for (const entry of Object.entries(values)) {
    const fieldName: FormFieldName<TValues> = entry[0] as any;
    const value: FormValues<TValues>[FormFieldName<TValues>] = entry[1] as any;
    __set(output, fieldName, value);
  }
  return output;
}

function __set(output: Record<string, any>, fieldName: string, value: any) {
  const [key, index] = fieldName.split(".") as [string, string | undefined];
  if (index && !output[key]) {
    output[key] = [];
  }
  index ? (output[key][index] = value) : (output[key] = value);
}
