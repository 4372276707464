import styles from "./index.module.css";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";

export function FetchingIndicator() {
  const isMobile = useIsMobile();
  const isFetching = !!useIsFetching();
  const isMutating = !!useIsMutating();

  return (
    <div
      className={cn(
        styles.root,
        isMobile ? styles.mobile : styles.desktop,
        !isFetching && !isMutating ? styles.hidden : "",
      )}
    >
      <div className={styles.background} />
      <div className={styles.gradient} />
    </div>
  );
}
