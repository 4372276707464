import React from "react";
import "./styles.css";
import { cn } from "src/app/utils/cn";

export default function CruratedHeaderTitle({
  text,
  subTitle,
  subNote,
  additionalClasses,
}) {
  var strLengthMax = 150;

  if (subTitle) {
    var newSubtitle =
      subTitle.length > strLengthMax
        ? subTitle.substring(0, strLengthMax - 3) + "..."
        : subTitle;
  } else {
    newSubtitle = subTitle;
  }

  return (
    <>
      <div className="card-header-group">
        <h3 className={cn("title", additionalClasses)}>{text}</h3>
        {newSubtitle ? <span className="subTitle"> {newSubtitle}</span> : <></>}
        {subNote ? <span className="subNote"> {subNote} </span> : <></>}
      </div>
    </>
  );
}
