import { RadioCard } from "./radio-card";
import { RadioBankTransfer } from "./radio-bank-transfer";
import {
  useChooseDefaultCardMutation,
  useMyCardsQuery,
} from "src/app/api/payments";
import { useChangePaymentMethodMutation } from "src/app/api/mutations_new_to_rename";
import { ID_PAYMENT_METHOD, IdPaymentMethod } from "src/app/const";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "src/app/components/skeleton";
import { QKEY } from "src/app/api/QKEY";

export const RadioPaymentMethod = ({
  userMethodPaymentDefault,
  onClose,
}: {
  userMethodPaymentDefault: IdPaymentMethod;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const isMutating = !!useIsMutating();

  const chooseDefaultCardMutation = useChooseDefaultCardMutation({
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [QKEY.ME, QKEY.CARDS],
      }),
  });
  const chooseDefaultMethodPayment = useChangePaymentMethodMutation({
    onSettled: () => queryClient.invalidateQueries(),
  });

  const { data: myCards = [], isLoading: isLoadingMyCards } = useMyCardsQuery();

  const userDefaultCard = myCards.find((el) => el.is_selected);

  const handleCardChange = async (cardID: string) => {
    await chooseDefaultCardMutation.mutateAsync(cardID);

    if (userMethodPaymentDefault !== ID_PAYMENT_METHOD.CARD) {
      await chooseDefaultMethodPayment.mutateAsync({
        id_method_payment: ID_PAYMENT_METHOD.CARD,
      });
    }
  };

  const handleBankTransferChange = async () => {
    await chooseDefaultMethodPayment.mutateAsync({
      id_method_payment: ID_PAYMENT_METHOD.BANK,
    });
  };

  const isLoading = isMutating || isLoadingMyCards;

  return (
    <>
      {isLoading ? (
        <Skeleton.Rect style={{ borderRadius: "8px" }} height={88} />
      ) : (
        <>
          <RadioCard
            onChange={handleCardChange}
            defaultCard={
              userMethodPaymentDefault === ID_PAYMENT_METHOD.CARD
                ? userDefaultCard?.id_payment_method
                : undefined
            }
            myCards={myCards}
            isLoading={isLoadingMyCards}
            onClose={onClose}
          />
          {userMethodPaymentDefault === ID_PAYMENT_METHOD.BANK && (
            <RadioBankTransfer
              defaultValue={ID_PAYMENT_METHOD.BANK}
              isLoading={isLoadingMyCards}
              onChange={handleBankTransferChange}
            />
          )}
        </>
      )}
    </>
  );
};
