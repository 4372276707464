import { ReactNode } from "react";
import styles from "./container-mobile.module.css";
import { BackLink } from "./back-link";

type Props =
  | {
      isCollection: boolean;
      isBarrel: boolean;
      isSingleLot: boolean;
      heading: ReactNode;
      form: ReactNode;
      why: ReactNode;
      collectionOfLotSlider: ReactNode;
      collectionOfLotAccordion: ReactNode;
      auctionFeatures: ReactNode;
      aboutTheEstate: ReactNode;
      moreProducts: ReactNode;
      youMightAlsoLike: ReactNode;
      singleLotImage: ReactNode;
      productDetails: ReactNode;
      barrelInput: ReactNode;
    }
  | {
      empty: true;
    };

export const ContainerMobile = (props: Props) => {
  if ("empty" in props) {
    return (
      <section className={styles.root}>
        <div style={{ marginBottom: "0.75rem" }}>
          <BackLink />
        </div>
      </section>
    );
  }

  return (
    <section className={styles.root}>
      <div style={{ marginBottom: "0.75rem" }}>
        <BackLink />
      </div>
      <div style={{ marginBottom: "20px" }}>{props.heading}</div>
      {!!props.isCollection && (
        <div style={{ marginBottom: "30px" }}>
          {props.collectionOfLotSlider}
        </div>
      )}
      {(!!props.isSingleLot || !!props.isBarrel) && (
        <div style={{ marginBottom: "30px" }} className={styles.lightBg}>
          {props.singleLotImage || props.barrelInput}
        </div>
      )}
      <div style={{ marginBottom: "24px" }}>{props.form}</div>
      <div style={{ marginBottom: "20px" }}>{props.auctionFeatures}</div>
      <hr className={styles.hr} />
      {props.isCollection ? (
        <div style={{ marginBottom: "24px" }}>
          {props.collectionOfLotAccordion}
        </div>
      ) : (
        <div style={{ marginTop: "20px", marginBottom: "24px" }}>
          {props.productDetails}
        </div>
      )}
      <div style={{ marginBottom: "50px" }}>{props.why}</div>
      {/* TODO: quick links in the website */}
      <div style={{ marginBottom: "50px" }}>{props.aboutTheEstate}</div>
      <hr style={{ marginBottom: "50px" }} className={styles.hr} />
      <div style={{ marginBottom: "60px" }}>{props.moreProducts}</div>
      <div style={{ marginBottom: "20px" }}>{props.youMightAlsoLike}</div>
    </section>
  );
};
