import { env } from "src/env";
import { CruForm } from "src/app/components/cru-form-3";
import { Input } from "src/app/components/form-elements/input";
import { FormContextType } from "../cru-form-3/types";
import { AddressFormValues } from "./address-form";
import styles from "./contact-info-section.module.css";
import { Select } from "../form-elements/select";
import { Spacer } from "../spacer";
import { CountryOption } from "./use-country-options";

type Props = {
  ctx: FormContextType<AddressFormValues>;
  countryOptions: CountryOption[];
  disableDetailFields: boolean;
};

export function ContactInfoSection({
  ctx,
  countryOptions,
  disableDetailFields,
}: Props) {
  return (
    <>
      <h4>Contact Info</h4>

      <CruForm.Field
        ctx={ctx}
        name="address_line_1"
        validate={(value) => !value && "Street Address is required"}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel} required>
              Address Line 1
            </CruForm.Label>

            <Input
              {...props}
              type="text"
              placeholder="Street Address"
              value={ctx.getFieldValue(props.name)}
              onChange={(e) => {
                ctx.setFieldsValues({ [props.name]: e.target.value });
              }}
              onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
              disabled={disableDetailFields}
            />

            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>
      <Spacer size={18} />
      <CruForm.Field ctx={ctx} name="address_line_2">
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel}>
              Address Line 2
            </CruForm.Label>

            <Input
              {...props}
              type="text"
              placeholder="Street Address 2"
              value={ctx.getFieldValue(props.name)}
              onChange={(e) => {
                ctx.setFieldsValues({ [props.name]: e.target.value });
              }}
              onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
              disabled={disableDetailFields}
            />

            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>
      <Spacer size={18} />
      {!!env.isDev && (
        <>
          <CruForm.Field ctx={ctx} name="additional_information">
            {(props) => (
              <>
                <CruForm.Label className={styles.formLabel}>
                  Additional Information
                </CruForm.Label>

                <Input
                  {...props}
                  type="text"
                  placeholder="Additional Information"
                  value={ctx.getFieldValue(props.name)}
                  onChange={(e) => {
                    ctx.setFieldsValues({ [props.name]: e.target.value });
                  }}
                  onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                  disabled={disableDetailFields}
                />

                <CruForm.ErrorMessage />
              </>
            )}
          </CruForm.Field>
          <Spacer size={18} />
        </>
      )}

      <CruForm.Field
        ctx={ctx}
        name="country"
        validate={(option) => !option.value && "Country is required"}
      >
        {(props) => (
          <>
            <CruForm.Label as="p" className={styles.formLabel} required>
              Country
            </CruForm.Label>

            <Select
              {...props}
              selectedOption={ctx.getFieldValue(props.name)}
              onChange={(option) => {
                ctx.setFieldsValues({ [props.name]: option });
                ctx.setFieldsTouched({ [props.name]: true });
              }}
              disabled={disableDetailFields}
              options={countryOptions}
              className={styles.countrySelect}
            />

            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>
      <Spacer size={18} />
      <CruForm.Field
        ctx={ctx}
        name="region"
        validate={(value) => !value && "State / Prov / Dept is required"}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel} required>
              State / Prov / Dept
            </CruForm.Label>

            <Input
              {...props}
              type="text"
              placeholder="Region"
              value={ctx.getFieldValue(props.name)}
              onChange={(e) => {
                ctx.setFieldsValues({ [props.name]: e.target.value });
              }}
              onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
              disabled={disableDetailFields}
            />

            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>
      <Spacer size={18} />
      <CruForm.Field
        ctx={ctx}
        name="city"
        validate={(value) => !value && "City is required"}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel} required>
              City{" "}
            </CruForm.Label>
            <Input
              {...props}
              type="text"
              placeholder="City"
              value={ctx.getFieldValue(props.name)}
              onChange={(e) => {
                ctx.setFieldsValues({
                  [props.name]: e.target.value,
                });
              }}
              onBlur={() =>
                ctx.setFieldsTouched({
                  [props.name]: true,
                })
              }
              disabled={disableDetailFields}
            />
            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>
      <Spacer size={18} />
      <CruForm.Field
        ctx={ctx}
        name="postal_code"
        validate={(value) => {
          if (!value) {
            return "Postal Code is required";
          }
          if (value.length < 3) {
            return "Postal Code should have at least 3 characters";
          }
          if (value.length > 12) {
            return "Postal Code should have at most 12 characters";
          }
        }}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel} required>
              Postal Code
            </CruForm.Label>

            <Input
              {...props}
              type="text"
              placeholder="Postal Code"
              value={ctx.getFieldValue(props.name)}
              onChange={(e) => {
                ctx.setFieldsValues({ [props.name]: e.target.value });
              }}
              onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
              disabled={disableDetailFields}
            />

            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>
    </>
  );
}
