import { ID_AUCTION_TYPE, PAGE_TITLE } from "src/app/const";
import { Auction } from "src/app/models/auctions";

export function getAuctionPageTitle(auction: Auction | undefined) {
  if (auction?.type.id === ID_AUCTION_TYPE.PRIVATE_SALE) {
    return PAGE_TITLE.PRIVATE_SALE();
  }
  if (auction?.type.id === ID_AUCTION_TYPE.PERMANENT) {
    return PAGE_TITLE.SHOP();
  }
  return PAGE_TITLE.NONE();
}
