import styles from "./CatalogueLink.module.css";
import React from "react";

export const CatalogueLink = ({ to }) => {
  if (!to) return null;

  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.root}
    >
      Catalogue
    </a>
  );
};
