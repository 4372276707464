import { isAuctionOfLot } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";
import { Carousel } from "../../../carousel";
import badge from "src/images/reitano/badge.png";

type Props = {
  minHeight?: number;
  maxHeight?: number;
};

export const LotCarousel = ({ minHeight = 200, maxHeight = 300 }: Props) => {
  const cardCtx = useCardCtx();
  if (!isAuctionOfLot(cardCtx.auction) || !cardCtx.auction.lot) return null;

  return (
    <div style={{ position: "relative" }}>
      <Carousel
        aspectRatio={[300, 220]}
        maxHeight={maxHeight}
        minHeight={minHeight}
        iconMargin={0}
        images={cardCtx.auction.lot.components.map((p) => p.media.cover)}
        controlled
        index={cardCtx.slideIndex}
        style={{ width: "100%" }}
        onChangeIndex={cardCtx.setSlideIndex}
        slideStyle={{ padding: "36px 16px 16px" }}
      />
      {!!cardCtx.auction.is_sommelier_selection && (
        <img
          src={badge}
          style={{
            position: "absolute",
            top: 40,
            right: "20%",
            width: 70,
            height: 70,
          }}
        />
      )}
    </div>
  );
};
