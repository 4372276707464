import styles from "./tier-card-link.module.css";
import { ID_TIER, IdTier } from "src/app/const";
import tierCardExplorerSrc from "src/images/tier-card/explorer.png";
import tierCardCollectorSrc from "src/images/tier-card/collector.png";
import tierCardConnoisseurSrc from "src/images/tier-card/connoisseur.png";
import tierCardAmbassadorSrc from "src/images/tier-card/ambassador.png";
import { path } from "src/app/routes/path";
import { useMyUser } from "src/app/hooks/useMyUser";
import { Img } from "src/app/components/img";
import { A } from "src/app/components/a";
import { useIsSmallDesktop } from "./useIsSmallDesktop";
import { useSidebarStatus } from "../sidebar-menu/useSidebarStatus";

export const TierCardLink = () => {
  const user = useMyUser({ maybeNull: true });
  const isSmallDesktop = useIsSmallDesktop();
  const sidebar = useSidebarStatus();

  if (!user) return null;
  if (sidebar.isPinned && isSmallDesktop) return null;

  return (
    <A to={path.profile.value()} className={styles.root}>
      <div>
        <Img
          className={styles.image}
          src={getTierCardSrc(user.id_customer_role)}
          alt={user.type_customer}
          lazyLoad={false}
        />
      </div>
    </A>
  );
};

function getTierCardSrc(id_customer_role: IdTier | null) {
  if (id_customer_role === ID_TIER.EXPLORER) {
    return tierCardExplorerSrc;
  }
  if (id_customer_role === ID_TIER.COLLECTOR) {
    return tierCardCollectorSrc;
  }
  if (id_customer_role === ID_TIER.CONNOISSEUR) {
    return tierCardConnoisseurSrc;
  }
  return tierCardAmbassadorSrc;
}
