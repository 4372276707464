import { env } from "src/env";
import Web3 from "web3";

// crea l'istanza di {web3}
const web3 = new Web3(env.REACT_APP_LINK_WEB_3);

// Crea un nuovo wallet e ritorna un oggetto con l'indirizzo e la chiave privata
export const newUserWallet = () => {
  let newAccount = web3.eth.accounts.create();
  return newAccount;
};
