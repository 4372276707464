import styles from "./index.module.css";
import { UserProfileSidebar } from "../profile-sidebar";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { Checkbox } from "src/app/components/form-elements/checkbox";
import { CruForm } from "src/app/components/cru-form-3";
import { Input } from "src/app/components/form-elements/input";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { Button } from "src/app/components/button";
import { useRef } from "react";
import { Avatar } from "./avatar";
import {
  UpdateUserPayload,
  useUpdateMyUserMutation,
} from "src/app/api/mutations_new_to_rename";
import { UserV1 } from "src/app/models/UserV1";
import { useMyUser } from "src/app/hooks/useMyUser";
import { toImgSrc } from "src/app/utils/toImgSrc";

export function MyProfile() {
  const user = useMyUser();
  const queryClient = useQueryClient();
  const alertPopup = useAlertPopup();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const initialValues = getInitialValues(user);
  const isFetching = !!useIsFetching();

  const updateMyUserMutation = useUpdateMyUserMutation({
    onSuccess() {
      queryClient.invalidateQueries();
      alertPopup.success({
        title: "Your information has been changed",
      });
    },
    onError() {
      queryClient.invalidateQueries();
      alertPopup.error({
        title: "Error updating your information",
      });
    },
  });

  const isLoading = updateMyUserMutation.isLoading || isFetching;

  return (
    <div className="user-profile-container">
      <UserProfileSidebar />
      <div className="upc-mainbody">
        <CruForm.Form
          className={styles.root}
          initialValues={initialValues}
          onSubmit={(values) => updateMyUserMutation.mutate(values)}
        >
          {(ctx) => (
            <>
              <CruForm.Field ctx={ctx} name="pic">
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left} />
                    <div className={styles.right}>
                      <Avatar
                        picSrc={
                          ctx.values.pic ? toImgSrc(ctx.values.pic) : user.pic
                        }
                        onClick={() => {
                          if (!fileInputRef.current) return;
                          fileInputRef.current.click();
                        }}
                      />
                      <div className="input-note">
                        Allowed file type: png, jpg, jpeg, gif
                      </div>
                      <Input
                        disabled={isLoading}
                        ref={fileInputRef}
                        type="file"
                        {...props}
                        className="fileitem file-hidden"
                        onChange={(e) => {
                          if (!e.target.files) return;
                          ctx.setFieldsValues({
                            [props.name]: e.target.files[0],
                          });
                        }}
                        accept=".png, .jpg, .jpeg, .gif"
                      />
                    </div>
                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>
              <CruForm.Field
                ctx={ctx}
                name="first_name"
                validate={(value) => {
                  if (!value) return "First Name is required";
                }}
              >
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left}>
                      <CruForm.Label className={styles.label} required>
                        First Name
                      </CruForm.Label>
                    </div>
                    <div className={styles.right}>
                      <Input
                        disabled={isLoading}
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />
                    </div>

                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>
              <CruForm.Field
                ctx={ctx}
                name="last_name"
                validate={(value) => {
                  if (!value) return "Last Name is required";
                }}
              >
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left}>
                      <CruForm.Label className={styles.label} required>
                        Last Name
                      </CruForm.Label>
                    </div>
                    <div className={styles.right}>
                      <Input
                        disabled={isLoading}
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />
                    </div>
                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>
              <CruForm.Field ctx={ctx} name="company_name">
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left}>
                      <CruForm.Label className={styles.label}>
                        Company Name
                      </CruForm.Label>
                    </div>
                    <div className={styles.right}>
                      <Input
                        disabled={isLoading}
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />
                      <div className="input-note">
                        If you want your invoices addresses to a company. Leave
                        blank to use your full name.
                      </div>
                    </div>

                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>
              <CruForm.Field
                ctx={ctx}
                name="email"
                validate={(value) => {
                  if (!value) return "Email Address is required";
                }}
              >
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left}>
                      <CruForm.Label className={styles.label} required>
                        Email Address
                      </CruForm.Label>
                    </div>
                    <div className={styles.right}>
                      <Input
                        disabled={isLoading}
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />
                      <div className="input-note">
                        Email will not be publicly displayed.
                      </div>
                    </div>
                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>
              <CruForm.Field
                ctx={ctx}
                name="birthday"
                validate={(value) => {
                  if (!value) return "Birt Day is required";
                }}
              >
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left}>
                      <CruForm.Label className={styles.label} required>
                        Birth Day
                      </CruForm.Label>
                    </div>
                    <div className={styles.right}>
                      <Input
                        disabled={isLoading}
                        type="date"
                        {...props}
                        value={ctx.getFieldValue(props.name)}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.value,
                          });
                        }}
                        onBlur={() =>
                          ctx.setFieldsTouched({ [props.name]: true })
                        }
                      />
                    </div>
                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>
              <CruForm.Field ctx={ctx} name="consent_profiling">
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left} />
                    <div
                      className={styles.right}
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <Checkbox
                        disabled={isLoading}
                        {...props}
                        checked={ctx.values.consent_profiling}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.checked,
                          });
                        }}
                      />
                      <CruForm.ErrorMessage />
                      <CruForm.Label className={styles.checkboxLabel}>
                        Profiling: I acknowledge that Crurated utilizes
                        Artificial Intelligence (AI) technologies to enhance my
                        user experience, specifically corresponding and tailored
                        to my own activity on the platform.
                      </CruForm.Label>
                    </div>
                  </div>
                )}
              </CruForm.Field>

              <CruForm.Field ctx={ctx} name="consent_marketing">
                {(props) => (
                  <div className={styles.formRow}>
                    <div className={styles.left} />
                    <div
                      className={styles.right}
                      style={{ display: "flex", gap: ".5rem" }}
                    >
                      <Checkbox
                        disabled={isLoading}
                        {...props}
                        checked={ctx.values.consent_marketing}
                        onChange={(e) => {
                          ctx.setFieldsValues({
                            [props.name]: e.target.checked,
                          });
                        }}
                      />
                      <CruForm.ErrorMessage />
                      <CruForm.Label className={styles.checkboxLabel}>
                        Marketing: I consent to being a recipient of updates,
                        tailored recommendations, marketing, events, and
                        promotional content from Crurated.
                      </CruForm.Label>
                    </div>
                  </div>
                )}
              </CruForm.Field>
              <div className={styles.button}>
                <Button
                  type="submit"
                  label="Save Changes"
                  disabled={
                    isLoading ||
                    isEqual(initialValues, ctx.values) ||
                    ctx.hasErrors
                  }
                />
                <Button
                  disabled={
                    isLoading ||
                    isEqual(initialValues, ctx.values) ||
                    ctx.hasErrors
                  }
                  type="button"
                  onClick={() => ctx.setFieldsValues(initialValues)}
                  variant="outlined"
                  label="Cancel"
                />
              </div>
            </>
          )}
        </CruForm.Form>
      </div>
    </div>
  );
}

function getInitialValues(user: UserV1) {
  return {
    pic: undefined,
    first_name: user.first_name || "",
    last_name: user.last_name || "",
    company_name: user.company_name || "",
    birthday: user.birthday || "",
    vat: user.addressInvoice[0]?.vat || "",
    email: user.email || "",
    consent_marketing: user.consent_marketing,
    consent_profiling: user.consent_profiling,
  } as UpdateUserPayload;
}
