import { Button } from "src/app/components/button";
import { useCardCtx } from "../../card-context";

export const ButtonArchive = () => {
  const cardCtx = useCardCtx();
  return (
    <Button
      variant="ghost"
      icon={
        <Icon fill={cardCtx.isArchived ? "var(--color-primary)" : undefined} />
      }
      tooltip={cardCtx.isArchived ? "Remove from Archive" : "Add to Archive"}
      onClick={cardCtx.toggleArchive}
      isLoading={cardCtx.isArchiveLoading}
    />
  );
};

function Icon({ fill }: { fill?: string }) {
  if (!fill)
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        stroke="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.4987 6.66667V17.5H2.4987V6.66667M8.33203 10H11.6654M0.832031 2.5H19.1654V6.66667H0.832031V2.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="8" width="16" height="10" rx="1" fill={fill} />
      <rect x="1" y="2" width="18" height="5" rx="1" fill={fill} />
      <line
        x1="8.5"
        y1="10.5"
        x2="11.5"
        y2="10.5"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}
