/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import styles from "./credit-card-row.module.css";
import { RadioGroup } from "@headlessui/react";
import srcGenericCard from "src/media/common/generic-card.png";
import srcMasterCard from "src/media/common/master-card.png";
import srcVisaCard from "src/media/common/visa-card.png";
import srcAmericanExpressCard from "src/media/common/american-express-card.png";
import srcDinnersClubCard from "src/media/common/dinersclub-card.png";
import srcDiscoverCard from "src/media/common/discover-card.png";
import srcJcbCard from "src/media/common/jcb-card.png";
import srcUnionPayCard from "src/media/common/unionpay-card.png";
import { useDeleteCardMutation } from "src/app/api/payments";
import { useQueryClient } from "@tanstack/react-query";
import { useChooseDefaultCardMutation } from "src/app/api/payments";
import { PaymentCardV1 } from "src/app/models/PaymentCardV1";
import { Img } from "src/app/components/img";
import { FaRegCircleCheck } from "react-icons/fa6";
import { cn } from "src/app/utils/cn";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { FaRegTrashAlt } from "react-icons/fa";
import { Button } from "src/app/components/button";
import { QKEY } from "src/app/api/QKEY";
import { useMyUser } from "src/app/hooks/useMyUser";

export function CreditCardRow({ cardData }: { cardData: PaymentCardV1 }) {
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const alertPopup = useAlertPopup();
  const user = useMyUser();

  const chooseDefaultCardMutation = useChooseDefaultCardMutation({
    onSuccess() {
      setLoading(true);
      queryClient
        .invalidateQueries({
          queryKey: [
            QKEY.ME,
            QKEY.CARDS,
            user.id_customer_stripe,
            user.id_user,
          ],
        })
        .then(() => setLoading(false));
    },
    onError() {
      setLoading(true);
      queryClient
        .invalidateQueries({
          queryKey: [
            QKEY.ME,
            QKEY.CARDS,
            user.id_customer_stripe,
            user.id_user,
          ],
        })
        .then(() => setLoading(false))
        .then(() =>
          alertPopup.error({
            title: "Credit card not changed",
          }),
        );
    },
  });

  const deleteCardMutation = useDeleteCardMutation({
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QKEY.ME, QKEY.CARDS, user.id_customer_stripe, user.id_user],
      });
      alertPopup.success({
        title: "Credit Card Deleted",
      });
    },
    onError() {
      queryClient.invalidateQueries({
        queryKey: [QKEY.ME, QKEY.CARDS, user.id_customer_stripe, user.id_user],
      });
      alertPopup.error({
        title: "Error deleting the card",
      });
    },
  });

  return (
    <RadioGroup
      value={cardData}
      onChange={() => {
        if (cardData.is_selected) return;
        chooseDefaultCardMutation.mutate(cardData.id_payment_method);
      }}
    >
      <RadioGroup.Option
        value={cardData.id_payment_method}
        disabled={!cardData}
      >
        <div
          className={cn(
            styles.root,
            cardData.is_selected && !loading && styles.active,
          )}
        >
          <div className={styles.cardInfo}>
            {loading ? (
              <SpinnerSm size={20} style={{ color: "#783233" }} />
            ) : (
              <FaRegCircleCheck
                color={
                  cardData.is_selected && !loading ? "#783233" : "#00000030"
                }
                size={20}
              />
            )}

            <div className={styles.creditCardImage}>
              <Img alt="credit-card-brand" src={getCardImage(cardData.brand)} />
            </div>
            <div>{cardNumberMask(cardData.last4)}</div>
            <div>
              Expiration date: {cardData.exp_month}/{cardData.exp_year}
            </div>
          </div>
          {!cardData.is_selected && (
            <div>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  alertPopup.prompt({
                    title: "Are you sure to delete this card?",
                    actionButton: {
                      label: "Yes",
                      onClick: () =>
                        deleteCardMutation.mutate(cardData.id_payment_method),
                    },
                    closeButton: {
                      label: "No",
                    },
                  });
                }}
                className={styles.trashButton}
                label=""
                icon={<FaRegTrashAlt />}
              />
            </div>
          )}
        </div>
      </RadioGroup.Option>
    </RadioGroup>
  );
}

export function getCardImage(type: string) {
  const cardImages: Record<string, string> = {
    mastercard: srcMasterCard,
    visa: srcVisaCard,
    amex: srcAmericanExpressCard,
    diners: srcDinnersClubCard,
    discover: srcDiscoverCard,
    jcb: srcJcbCard,
    unionpay: srcUnionPayCard,
  };

  return cardImages[type.toLowerCase()] || srcGenericCard;
}

function cardNumberMask(last4: string) {
  return `**** **** **** ${last4}`;
}
