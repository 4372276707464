import { toPx } from "src/app/utils/toPx";
import styles from "./index.module.css";
import { cn } from "src/app/utils/cn";
import { CSSProperties } from "react";

type Props = {
  size?: string | number;
  color?: string;
  style?: CSSProperties;
};

export const Circle = ({ size = "100%", color, style }: Props) => {
  return (
    <div
      style={{
        ...style,
        width: toPx(size),
        height: toPx(size),
        backgroundColor: color,
      }}
      className={cn(styles.root, styles.circle)}
    />
  );
};
