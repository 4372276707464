import style from "./cell-content-shipment-details.module.css";
import { Tooltip } from "src/app/components/tooltip";

type Props = {
  title: string;
  note: string;
};

export function CellContentShipmentDetails({ title, note }: Props) {
  return (
    <div>
      <Tooltip position="bottom" content={title}>
        <h4 className={style.title}>{title}</h4>
      </Tooltip>

      <span className={style.note}>{note}</span>
    </div>
  );
}
