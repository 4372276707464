import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export function CruratedCardsImg({ products, barrel, extraClasses, type }) {
  return type === "LOTS" ? (
    <>
      {products && products.length > 0 ? (
        <figure className={`lots-cards-block ${extraClasses}`}>
          {products.map((product, key) => {
            if (
              product.photo_label !== "" &&
              product.photo_label !== "/media/products/default.jpg"
            ) {
              return (
                <img
                  key={key}
                  className="cruratedCardsImg"
                  src={product.photo_label}
                  alt="cards"
                />
              );
            } else {
              return false;
            }
          })}
        </figure>
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      {barrel && barrel.product_photo_label ? (
        <figure className={`lots-cards-block ${extraClasses}`}>
          {barrel.product_photo_label !== "" &&
          barrel.product_photo_label !==
            "/media/barrels/products/label/default.jpg" ? (
            <Zoom>
              <img
                key={1}
                className="cruratedCardsImg"
                src={barrel.product_photo_label}
                alt="cards"
              />
            </Zoom>
          ) : (
            <></>
          )}
        </figure>
      ) : (
        <></>
      )}
    </>
  );
}
