import { PaymentIntentResult } from "@stripe/stripe-js";
import styles from "./step-payment-settled.module.css";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosWarning } from "react-icons/io";
import { Button } from "src/app/components/button";
import { useMyUser } from "src/app/hooks/useMyUser";

export const StepPaymentSettled = ({
  outcome,
  onClose,
}: {
  onClose: () => void;
  outcome: PaymentIntentResult | undefined;
}) => {
  const user = useMyUser();

  const isError = !!outcome?.error?.message || !outcome?.paymentIntent;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {isError ? (
          <IoIosWarning size={32} color="#A41F1F" />
        ) : (
          <FaCircleCheck size={32} color="#027A48" />
        )}
        <p className={styles.title}>
          {isError ? "Important Message" : "Order Confirmed"}
        </p>
      </div>
      <div className={styles.content}>
        <p className={styles.contentP}>
          {isError ? (
            <span>
              {outcome?.error?.message ||
                "Payment for your order has been refused. To proceed with the order, please update your payment method."}
            </span>
          ) : (
            <span>
              We will be sending you an email confirmation to&nbsp;
              <span style={{ fontWeight: 700 }}>{user.email}</span>. You will
              find your order available in your cellar.
            </span>
          )}
        </p>
      </div>
      <div>
        <Button onClick={onClose} className={styles.button} label="Close" />
      </div>
    </div>
  );
};
