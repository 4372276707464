import { ReactNode } from "react";
import styles from "./bottom-link.module.css";
import { A } from "src/app/components/a";

export const BottomLink = ({ label, to }: { label: ReactNode; to: string }) => {
  return (
    <li>
      <A to={to} className={styles.root}>
        {label}
      </A>
    </li>
  );
};
