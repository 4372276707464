import { IdAuctionType, IdPaymentMethod, TypeProduct } from "src/app/const";
import { MessageContent, RootDictionary } from ".";
import { shopMessages } from "./dictionaries/shopMessages";
import { auctionMessages } from "./dictionaries/auctionMessages";

/**
 * Recupera il messaggio più specifico disponibile in base ai tag forniti nei dizionari.
 * Per aggiungere/modificare messaggi sei nel posto sbagliato, leggi il README di questa dir.
 *
 * @param params - Un oggetto contenente le seguenti proprietà:
 * @param params.messagesObject - Specifica se recuperare i messaggi dal dizionario "shop" o "auction".
 * @param params.messageType - Specifica il tipo di messaggio da recuperare: "prompt" o "success".
 * @param [params.typeProduct] - Un tag opzionale che specifica il tipo di prodotto (lot, barrel).
 * @param [params.idAuctionType] - Un tag opzionale che specifica il tipo di asta (single_lot, en_primeur, ...).
 * @param [params.idPaymentMethod] - Un tag opzionale che specifica il metodo di pagamento (3 = card, 4 = bank).
 * @param [params.additionalKeys] - Un array opzionale di tag aggiuntivi per messaggi più specifici.
 * @returns L'oggetto MessageContent più specifico disponibile in base ai tag forniti.
 * - `title`: Il titolo del messaggio.
 * - `content`: Il contenuto del messaggio, che può essere una stringa o un JSX.Element.
 *
 * @example
 * // Utilizzo di base con typeProduct e idAuctionType
 * const message = getAuctionMessage({
 *   messagesObject: 'shop',
 *   messageType: 'prompt',
 *   typeProduct: TYPE_PRODUCT.BARREL,
 *   idAuctionType: ID_AUCTION_TYPE.SINGLE_LOTS,
 * });
 * console.log(message);
 * // Output: { title: "Please Confirm Your Purchase", content: "..." }
 *
 * @example
 * // Utilizzo con tag aggiuntivi
 * const message = getAuctionMessage({
 *   messagesObject: 'auction',
 *   messageType: 'success',
 *   idAuctionType: ID_AUCTION_TYPE.SINGLE_LOTS,
 *   additionalKeys: ['place-bid'],
 * });
 * console.log(message);
 * // Output: { title: "Thank You For Bidding!", content: "If the bidding passes your maximum amount, we'll notify you and you can increase your Max Bid if you wish so." }
 *
 * @example
 * // Utilizzo con metodo di pagamento
 * const message = getAuctionMessage({
 *   messagesObject: 'shop',
 *   messageType: 'success',
 *   idPaymentMethod: ID_PAYMENT_METHOD.CARD,
 * });
 * console.log(message);
 * // Output: { title: "Congratulations!", content: "Your order is now confirmed, and your card was automatically charged. Go to Your Cellar to view all your purchases!" }
 */

export const getAuctionMessage = ({
  messagesObject,
  messageType,
  typeProduct,
  idAuctionType,
  idPaymentMethod,
  additionalKeys,
}: {
  messagesObject: "shop" | "auction";
  messageType: "prompt" | "success";
  typeProduct?: TypeProduct;
  idAuctionType?: IdAuctionType;
  idPaymentMethod?: IdPaymentMethod;
  additionalKeys?: string[];
}): MessageContent => {
  const tags: string[] = [];
  if (typeProduct) tags.push(`product-${typeProduct}`);
  if (idAuctionType) tags.push(`type-${idAuctionType}`);
  if (idPaymentMethod) tags.push(`payment-${idPaymentMethod}`);
  if (additionalKeys) tags.push(...additionalKeys);

  // genera i subset per i tag
  const tagCombinations = generateTagCombinations(tags);

  const dictionary: RootDictionary =
    messagesObject === "shop" ? shopMessages : auctionMessages;

  for (const tagCombination of tagCombinations) {
    const key = tagCombination.join(",");
    const message = dictionary[messageType][key];
    if (message) {
      return message;
    }
  }

  return dictionary[messageType].default!;
};

// subsets generation
const generateTagCombinations = (tags: string[]): string[][] => {
  const combinations: string[][] = [];
  const generateCombinations = (
    currentCombination: string[],
    index: number,
  ) => {
    if (index === tags.length) {
      combinations.push([...currentCombination]);
      return;
    }
    generateCombinations(currentCombination, index + 1);
    currentCombination.push(tags[index]);
    generateCombinations(currentCombination, index + 1);
    currentCombination.pop();
  };
  generateCombinations([], 0);
  return combinations.sort((a, b) => b.length - a.length);
};
