import styles from "./form.module.css";
import { CSSProperties, ReactNode } from "react";
import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";
import { Tooltip } from "src/app/components/tooltip";
import { Countdown } from "src/app/components/countdown";

type Props = {
  auction: Auction;
  action?: ReactNode;
  action2?: ReactNode;
  headingLabel: string;
  empLabel: string;
  empTooltipContent: string;
  inputQty: ReactNode;
  inputPrice: ReactNode;
  totalLabel: string;
  totalTooltipContent: string;
  creditCardLabel?: ReactNode;
  infoMessage?: ReactNode;
  style?: CSSProperties;
  actionButtons?: ReactNode;
};

export const FormCollection = ({
  auction,
  headingLabel,
  action,
  action2,
  empLabel,
  empTooltipContent,
  inputQty,
  inputPrice,
  totalLabel,
  totalTooltipContent,
  creditCardLabel,
  infoMessage,
  actionButtons,
  style,
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <form
      className={cn(styles.root, isMobile ? styles.mobile : styles.desktop)}
      onSubmit={(e) => e.preventDefault()}
      style={style}
    >
      <div className={styles.head}>
        <Countdown targetDate={auction.finish_data} />

        <div className={styles.toprightButtons}>
          {actionButtons}
          {/* <ButtonAuctionRefresh /> */}
        </div>
      </div>

      <div className={styles.bottomGroup}>
        <div className={cn(styles.controls, styles.collection)}>
          {!!auction.currentBiddingOffer && (
            <div className={styles.collectonBidInfos}>
              <span>My current offer is&nbsp;</span>
              <span>
                {formatCurrency(
                  auction.currentBiddingOffer.bid_offer *
                    auction.currentBiddingOffer.bid_quantity,
                )}
                &nbsp;
              </span>
              <span>
                (
                {auction.currentBiddingOffer.bid_quantity *
                  (auction.options.quantities.mul || 1)}
                &nbsp;
              </span>
              <span>
                &nbsp;
                {isAuctionOfBarrel(auction)
                  ? "liters"
                  : auction.currentBiddingOffer.bid_quantity > 1
                    ? "lots"
                    : "lot"}
                )
              </span>
            </div>
          )}

          <h2 className={styles.heading}>{headingLabel}</h2>

          <div className={styles.total}>
            <span className={styles.totalValue}>
              {formatCurrency(auction.initialPrice)}
            </span>
            <Tooltip content={totalTooltipContent}>
              <span className={styles.totalLabel}>{totalLabel}</span>
            </Tooltip>
          </div>

          <hr className={styles.divider} />

          <div className={styles.emp}>
            <Tooltip content={empTooltipContent}>
              <span>{empLabel}</span>
            </Tooltip>
            <span>
              {formatCurrency(auction.crurated_estimated_market_price)}
            </span>
          </div>

          <div className={styles.inputs}>{inputQty}</div>
        </div>

        <div
          style={{
            padding: "0px 16px",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div className={styles.inputs}>{inputPrice}</div>

          <div className={cn(styles.actions, styles.collection)}>
            <div className={styles.actions}>{action}</div>

            {!!action && !!action2 && (
              <div className={styles.actionsDivider}>
                <div className={styles.line} />
                <div className={styles.text}>or</div>
                <div className={styles.line} />
              </div>
            )}

            <div className={styles.actions}>{action2}</div>
          </div>

          {infoMessage}

          <div className={styles.crediCard}>{creditCardLabel}</div>
        </div>
      </div>
    </form>
  );
};
