import styles from "./radiogroup-address-option.module.css";
import { FaPencilAlt } from "react-icons/fa";
import { RadioGroup } from "@headlessui/react";
import {
  MdOutlineMail,
  MdOutlinePhone,
  MdOutlineLocationOn,
} from "react-icons/md";
import { Button } from "src/app/components/button";
import { AddressV1 } from "src/app/models/Address";
import { cn } from "src/app/utils/cn";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useMyUserQuery } from "src/app/api/auth";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { useMyUser } from "src/app/hooks/useMyUser";

export const RadioGroupAddressOption = ({
  address,
  pendingVerification = false,
  onEdit,
}: {
  address: AddressV1;
  pendingVerification?: boolean;
  onEdit?: (address: AddressV1) => void;
}) => {
  const user = useMyUser();
  const { isFetching: isFetchingAddresses } = useMyUserQuery();

  return (
    <RadioGroup.Option
      value={address.id_address}
      className={({ checked }) => cn(styles.root, checked && styles.checked)}
      disabled={pendingVerification}
    >
      {({ checked }) => (
        <div className={styles.inner}>
          <div className={cn(styles.radio, !checked && styles.unchecked)}>
            {isFetchingAddresses ? (
              <SpinnerSm />
            ) : (
              <FaRegCircleCheck size={20} />
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.icon}>
              <MdOutlineLocationOn size={16} />
            </div>
            <RadioGroup.Label as="p">
              {address.address_complete}
            </RadioGroup.Label>

            <div className={styles.icon}>
              <MdOutlineMail size={16} />
            </div>
            <RadioGroup.Label as="p">{user.email}</RadioGroup.Label>

            {!!address.phone && (
              <>
                <div className={styles.icon}>
                  <MdOutlinePhone size={16} />
                </div>
                <span>{address.phone}</span>
              </>
            )}
          </div>
          {!!onEdit && (
            <div className={styles.action}>
              {pendingVerification ? (
                <div>Pending Verification</div>
              ) : (
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => onEdit(address)}
                  tooltip="Edit"
                  icon={<FaPencilAlt />}
                />
              )}
            </div>
          )}
        </div>
      )}
    </RadioGroup.Option>
  );
};
