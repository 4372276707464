import { useId } from "react";
import styles from "./BottleCount.module.css";
import React from "react";
import bottleRedSrc from "src/media/wine-red.svg";
import bottleRoseSrc from "src/media/wine-rose.svg";
import bottleWhiteSrc from "src/media/wine-white.svg";
import bottleBrutSrc from "src/media/wine-brut-nature-blanc-de-blancs.svg";

export function BottleCount({ lot, className = "" }) {
  const id = useId();

  const colorToCountMap = (lot.products || lot.components).reduce(
    (acc, product) => {
      product.color
        .split(",")
        .map((color) => color.trim())
        .forEach((color) => {
          const key = color
            .toLowerCase()
            .replace("é", "e")
            .replace(/\s+/g, "-");
          if (!acc[color]) acc[color] = {};
          acc[color] = {
            key,
            count: (acc[color].count || 0) + product.quantity,
          };
        });

      return acc;
    },
    {},
  );

  return (
    <div className={`${styles.root} ${className}`}>
      {Object.entries(colorToCountMap)
        .sort((a, b) => (a[1].count > b[1].count ? -1 : 1))
        .map(([colorName, color]) => (
          <div
            key={`${id}-${color.key}`}
            className={styles.count}
            title={`${color.count} bottles of ${colorName}`}
          >
            <img
              src={
                color.key === "red"
                  ? bottleRedSrc
                  : color.key === "rose"
                  ? bottleRoseSrc
                  : color.key === "white"
                  ? bottleWhiteSrc
                  : bottleBrutSrc
              }
              alt="lot-barrels"
            />
            <div>{`x ${color.count}`}</div>
          </div>
        ))}
    </div>
  );
}
