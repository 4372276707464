import { Fragment, useEffect } from "react";
import { PiWarningCircleDuotone } from "react-icons/pi";
import {
  CheckBidErrorResponse,
  PurchaseMethod,
  useCheckBidMutation,
} from "src/app/api/mutations_new_to_rename";
import { Button } from "src/app/components/button";
import { Spacer } from "src/app/components/spacer";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";

type Props = {
  auction: AuctionOfBarrel | AuctionOfLot;
  purchaseMethod: PurchaseMethod;
  onSettled: (errors?: CheckBidErrorResponse) => void;
  onClose: () => void;
};

export const CheckPurchase = ({
  auction,
  purchaseMethod,
  onSettled,
  onClose,
}: Props) => {
  const { mutate, error, isLoading, isError } = useCheckBidMutation({
    onSuccess: () => onSettled(),
    onError: (errors) => {
      const fatalErrors = getGenericErrors(errors);

      // If fatal error this component will take care of displaying it
      // otherwise return control to parent to hadle further execution
      if (!fatalErrors.length) {
        onSettled(errors);
      }
    },
  });

  const bidErrors =
    isError && Array.isArray(error)
      ? getGenericErrors(error).map((err) => err.message)
      : ["There was an error processing your request, please try again"];

  useEffect(() => {
    mutate({
      auction_type: auction.type_product,
      id_auction: auction.id_auction,
      method: purchaseMethod,
    });
  }, [auction.id_auction, auction.type_product, mutate, purchaseMethod]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {isLoading ? (
        <div style={{ padding: 36 }}>
          <SpinnerSm size={30} />
        </div>
      ) : isError ? (
        <>
          <PiWarningCircleDuotone size={48} color="rgb(175, 56, 54)" />
          <Spacer size={14} />
          <h2 style={{ fontSize: "1.5rem" }}>You cannot buy this item</h2>
          {bidErrors.map((message, i) => (
            <Fragment key={i}>
              <p style={{ marginBottom: 0, fontSize: 14 }}>{message}</p>
              <Spacer size={12} />
            </Fragment>
          ))}
          <Spacer size={12} />
          <div>
            <Button label="OK" onClick={onClose} style={{ minWidth: 120 }} />
          </div>
        </>
      ) : null}
    </div>
  );
};

function getGenericErrors(response: CheckBidErrorResponse) {
  return response.filter((err) => err.type === "generic");
}
