import React, { useEffect, useState } from "react";

export function SlidePopup({ closeSlide, moreInformation }) {
  const [activeSlideClass, setSlideActive] = useState("");
  const [activeCount, setActiveCount] = useState(0);

  useEffect(() => {
    if (activeCount === 0) {
      setTimeout(function () {
        setSlideActive("active");
      }, 100);
      setActiveCount(activeCount + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeActive = () => {
    setSlideActive("");
    setTimeout(function () {
      closeSlide();
    }, 400);
  };

  return (
    <React.Fragment>
      <div
        className={`slide-overlay ${activeSlideClass && `active`}`}
        onClick={removeActive}
      />
      <div className={`side-slide-popup ${activeSlideClass && `active`}`}>
        <div className="slide-popup-close" onClick={removeActive}></div>
        <div className="side-slide-popup-inner">
          <div className="side-slide-data postdata">
            <span>{moreInformation}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
