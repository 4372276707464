import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { path } from "src/app/routes/path";
import { useClearAllStores } from "src/app/hooks/useClearAllStores";

export function LogoutPage() {
  const history = useHistory();

  const clearAllStores = useClearAllStores();

  useEffect(() => {
    const from = (history.location.state as any)?.from;
    clearAllStores().then(() =>
      history.push({
        pathname: path.login(),
        state: { from },
      }),
    );
  }, [clearAllStores, history]);
}
