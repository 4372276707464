import styles from "./barrel-attributes.module.css";
import bottles from "src/media/bottles-bottom.svg";
import custom_label from "src/media/custom-label.svg";
import visit_label from "src/media/common/ccbth-icon-3.svg";
import dinner_label from "src/media/common/ccbth-icon-4.svg";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { Tooltip } from "src/app/components/tooltip";

export const BarrelAttributes = ({ auction }: { auction: AuctionOfBarrel }) => {
  return (
    <div className={styles.root}>
      <div className={styles.attribute}>
        <div>
          <img src={bottles} alt="bottled in" />
        </div>
        <p>
          <small>
            <span>Bottled in&nbsp;</span>
            <span>
              {auction.barrel.availableBottleTiers
                .map((tier) => tier.value)
                .join(", ")}
            </span>
            <span>&nbsp;L</span>
          </small>
        </p>
      </div>
      {!!auction.enable_custom_label_bottle && (
        <div className={styles.attribute}>
          <div>
            <img src={custom_label} alt="custom label" />
          </div>
          <p>
            <small>Custom label available</small>
          </p>
        </div>
      )}
      {!!auction.barrel.notes_visit_cellar_of_producer && (
        <div className={styles.attribute}>
          <div>
            <Tooltip content={auction.barrel.notes_visit_cellar_of_producer}>
              <img
                src={visit_label}
                alt="custom label"
                style={{ position: "relative", left: "-6px" }}
              />
            </Tooltip>
          </div>
          <p>
            <small>Visit to producer</small>
          </p>
        </div>
      )}
      {!!auction.barrel.dinner_with_producer && (
        <div className={styles.attribute}>
          <div>
            <Tooltip content={auction.barrel.notes_dinner_with_producer}>
              <img src={dinner_label} alt="custom label" />
            </Tooltip>
          </div>
          <p>
            <small>Dinner with producer</small>
          </p>
        </div>
      )}
    </div>
  );
};
