import { formatCurrency } from "src/app/utils/formatCurrency";
import styles from "./HistoryPanel.module.css";
import React from "react";
import { formatDate } from "src/app/utils/formatDate";
import { noop } from "lodash";

export function HistoryPanel({
  width,
  height = "100%",
  history = [],
  className = "",
  style = {},
  onMouseEnter = noop,
  onMouseLeave = noop,
}) {
  return (
    <div
      className={`show-scrollbars ${className}`}
      style={{
        width,
        height,
        ...style,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Qty</th>
            <th>Total</th>
            <th>Date</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {history.map((bid) => (
            <tr key={`bid-${bid.bid_id}`}>
              <td>{bid.bid_quantity}</td>
              <td>{formatCurrency(bid.bid_total)}</td>
              <td>{formatDate(bid.bid_date)}</td>
              <td>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className={`${styles.status} ${
                      styles[bid.bid_status.label]
                    }`}
                  >
                    {bid.bid_status.label}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
