import { useCallback, useEffect, useState } from "react";
import { LocalStorage } from "../utils/LocalStorage";
import { Emitter } from "../utils/Emitter";

const emitter = new Emitter<{ publishedKey: string; updater: any }>();

/**
 * Similar to a global state, all callers are synchronized on change.
 */
export function useLocalStorage<T>(key: string, defaultValue: T) {
  const [state, _setState] = useState<T>(
    LocalStorage.getItem(key) ?? defaultValue,
  );

  const setStateIfKeyMatches = useCallback(
    ({
      publishedKey,
      updater,
    }: {
      publishedKey: string;
      updater: ((currentState: T) => T) | T;
    }) => {
      if (publishedKey !== key) {
        return;
      }
      _setState((prev) => {
        const actualValue =
          typeof updater === "function"
            ? (updater as (currentState: T) => T)(prev)
            : updater;
        LocalStorage.setItem(key, actualValue);
        return actualValue;
      });
    },
    [key],
  );

  const setState = useCallback(
    (updater: ((currentState: T) => T) | T) => {
      emitter.emit({
        publishedKey: key,
        updater,
      });
    },
    [key],
  );

  useEffect(() => {
    return emitter.addListener(setStateIfKeyMatches);
  }, [setStateIfKeyMatches]);

  return [state, setState] as const;
}
