import styles from "./bottle-count.module.css";
import { Auction, isAuctionOfLot } from "src/app/models/auctions";

export const BottleCount = ({ auction }: { auction: Auction }) => {
  if (
    !isAuctionOfLot(auction) ||
    !auction.lot?.components.length ||
    !getTypeFromTags(auction.tags, "wine")
  )
    return null;
  const totalBottles = auction.lot.components.reduce(
    (acc, current) => acc + (current.quantity || 0),
    0,
  );
  return (
    <div className={styles.root}>
      <span>{totalBottles}&nbsp;</span>
      <span>{totalBottles > 1 ? " Bottles" : " Bottle"}</span>
      <span>{!!auction.experience && " | "}</span>
    </div>
  );
};

const getTypeFromTags = (tags: string[], element: string) => {
  return tags.some((tag) =>
    tag.toLowerCase().startsWith(element.toLowerCase()),
  );
};
