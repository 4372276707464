import { z } from "zod";

export const apiMyAssetsStatsSchemaV1 = z
  .array(
    z.union([
      z.tuple([]),
      z.object({
        cellar_type: z.literal("lots"),
        status: z.string(),
        total_lots: z.number(),
        total_products: z.number(),
        deposit: z.never().optional(),
        total_bid: z.never().optional(),
        total_liters: z.never().optional(),
      }),
      z.object({
        cellar_type: z.literal("barrels"),
        deposit: z.number(),
        total_bid: z.number(),
        total_liters: z.number(),
        status: z.never().optional(),
        total_lots: z.never().optional(),
        total_products: z.never().optional(),
      }),
    ]),
  )
  .transform((statistics) => {
    // remove empty array type
    const stats: Exclude<(typeof statistics)[number], any[]>[] =
      statistics.filter((stat) => !Array.isArray(stat)) as any;
    const inBarrels = stats.find((stat) => stat.cellar_type === "barrels");

    return [
      createLotStat("TOTAL", {
        total_lots: stats.reduce(
          (total, cur) => total + (cur.total_lots ?? 0),
          0,
        ),
        total_products: stats.reduce(
          (total, cur) => total + (cur.total_products ?? 0),
          0,
        ),
      }),
      {
        name: "IN BARRELS",
        value: inBarrels?.total_liters ?? 0,
        description: "Liters to convert",
      },
      createLotStat(
        "WITH PRODUCERS",
        stats.find((stat) => stat.status === "with_producer"),
      ),
      createLotStat(
        "IN WAREHOUSE",
        stats.find((stat) => stat.status === "in_our_warehouse"),
      ),
      createLotStat(
        "REQUESTED TO SHIP",
        stats.find((stat) => stat.status === "shipment_request"),
      ),
      createLotStat(
        "DELIVERED",
        stats.find((stat) => stat.status === "delivered"),
      ),
      createLotStat(
        "GIFT",
        stats.find((stat) => stat.status === "gifted"),
      ),
      ...stats
        .filter(
          (stat) =>
            stat.cellar_type === "lots" &&
            stat.status &&
            ![
              "with_producer",
              "in_our_warehouse",
              "shipment_request",
              "delivered",
              "gifted",
            ].includes(stat.status),
        )
        .map((lotStat) =>
          createLotStat(
            (lotStat.status as string)
              .split("_")
              .map((word) => word.toUpperCase())
              .join(" "),
            lotStat,
          ),
        ),
    ] satisfies Array<MyAssetsStat>;
  });

function createLotStat(
  name: string,
  stat?: {
    total_lots?: number;
    total_products?: number;
  },
): MyAssetsStat {
  return {
    name,
    value: stat?.total_lots ?? 0,
    description: `Lot${(stat?.total_lots ?? 0) === 1 ? "" : "s"}`,
    detail: `${stat?.total_products ?? ""} Bottle${
      (stat?.total_products ?? 0) === 1 ? "" : "s"
    }`,
  };
}

export type MyAssetsStat = {
  name: string;
  value: string | number;
  description: string;
  detail?: string;
};
