import styles from "./Warning.module.css";
import React from "react";

export const Warning = ({ text, className = "" }) => {
  return (
    <div className={`flaticon2-warning ${styles.root} ${className}`}>
      {text}
    </div>
  );
};
