import { useMyUserQuery } from "../api/auth";
import { UserV1 } from "../models/UserV1";

export function useMyUser(options?: { maybeNull: false } | undefined): UserV1;
export function useMyUser(options?: { maybeNull: true }): UserV1 | null;
export function useMyUser(options?: { maybeNull?: boolean }): UserV1 | null {
  const data = useMyUserQuery().data || null;
  if (!options?.maybeNull && !data) {
    throw new Error("User data not found");
  }
  return useMyUserQuery().data || null;
}
