import { useIsMobile } from "src/app/hooks/useIsMobile";
import styles from "./index.module.css";
import logoSrc from "src/media/logos/logo-default.gif";
import { CruForm } from "src/app/components/cru-form-3";
import { Button } from "src/app/components/button";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";
import { validate } from "src/app/utils/validate";
import { InputPassword } from "src/app/components/form-elements/input-password";
import {
  useEditPasswordUserNotLoggedMutation,
  useRequestPasswordChangeQuery,
} from "src/app/api/auth";
import { useSearchParam } from "react-use";
import { parseApiErrorV1 } from "src/app/api/_parse-api-error";
import { MdErrorOutline } from "react-icons/md";
import { SplashScreen } from "src/app/layouts/splash-screen";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useAlertPopup } from "src/app/components/alert-popup/store";

export default function ResetPassword() {
  const isMobile = useIsMobile();
  const alertPopup = useAlertPopup();

  const code = useSearchParam("code");
  const token = useSearchParam("prn");
  const req = useSearchParam("req");

  const { data, isLoading, isError, error } = useRequestPasswordChangeQuery({
    code,
    token,
    req,
  });

  const editPasswordMutation = useEditPasswordUserNotLoggedMutation({
    onError: (error) =>
      alertPopup.error({
        title: "Error Updating Password",
        content: parseApiErrorV1(error),
      }),
  });

  if (isLoading) return <SplashScreen />;

  if (data === -1 || data === -2 || isError || !code || !token || !req)
    return (
      <div className={styles.rootMessage}>
        <div className={styles.messageInner}>
          <MdErrorOutline />
          <h1>
            {data === -1 || !code || !token || !req
              ? "Request Denied"
              : data === -2
                ? "User not found"
                : "Unexpected Error"}
          </h1>
          {!!error && <h4>{parseApiErrorV1(error)}</h4>}
        </div>
        <Button as={A} to={path.forgotPassword()} label="Try Again" />
      </div>
    );

  if (editPasswordMutation.isSuccess)
    return (
      <div className={styles.rootMessage}>
        <div className={styles.messageInner}>
          <IoMdCheckmarkCircleOutline />
          <h1>Reset Password complete!</h1>
          <h4>You can now login with your new password</h4>
        </div>
        <Button as={A} to={path.login()} label="Login" />
      </div>
    );

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        {!!isMobile && <img src={logoSrc} alt="Crurated logo" />}
        <h3>Change Password</h3>
        <p>Change your account password</p>
      </div>
      <div>
        <CruForm.Form
          initialValues={{ password: "", confirmPassword: "" }}
          onSubmit={(values) =>
            editPasswordMutation.mutate({
              code,
              token,
              req,
              new_password: values.password,
            })
          }
        >
          {(ctx) => (
            <>
              <CruForm.Field
                ctx={ctx}
                name="password"
                validate={validate.password}
              >
                {(props) => (
                  <div className={styles.inputGroup}>
                    <CruForm.Label className={styles.formLabel} required>
                      New Password
                    </CruForm.Label>

                    <InputPassword
                      {...props}
                      value={ctx.getFieldValue(props.name)}
                      onChange={(e) => {
                        ctx.setFieldsValues({ [props.name]: e.target.value });
                      }}
                      onBlur={() =>
                        ctx.setFieldsTouched({ [props.name]: true })
                      }
                      className={styles.input}
                    />

                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>

              <CruForm.Field
                ctx={ctx}
                name="confirmPassword"
                validate={(value, getFieldValue) => {
                  if (!value) return "Write your password again";
                  if (value !== getFieldValue("password"))
                    return "Passwords must match";
                }}
              >
                {(props) => (
                  <div className={styles.inputGroup}>
                    <CruForm.Label className={styles.formLabel} required>
                      Confirm New Password
                    </CruForm.Label>

                    <InputPassword
                      {...props}
                      value={ctx.getFieldValue(props.name)}
                      onChange={(e) => {
                        ctx.setFieldsValues({ [props.name]: e.target.value });
                      }}
                      onBlur={() =>
                        ctx.setFieldsTouched({ [props.name]: true })
                      }
                      className={styles.input}
                    />

                    <CruForm.ErrorMessage />
                  </div>
                )}
              </CruForm.Field>

              <Button
                variant="contained"
                label="Save New Password"
                className={styles.button}
                isLoading={editPasswordMutation.isLoading}
              />
            </>
          )}
        </CruForm.Form>
      </div>
    </div>
  );
}
