import { useCallback } from "react";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { Auction } from "src/app/models/auctions";
import { z } from "zod";

type Option = {
  sortingFn: (a: Auction, b: Auction) => number;
  label: string;
  value: string | undefined;
};

const options: Option[] = [
  { sortingFn: () => 0, label: "Sort: Default", value: undefined },

  {
    sortingFn: (a, b) => (a.initial_price > b.initial_price ? 1 : -1),
    label: "Sort: Price (lowest first)",
    value: "price-asc",
  },
  {
    sortingFn: (a, b) => (a.initial_price > b.initial_price ? -1 : 1),
    label: "Sort: Price (highest first)",
    value: "price-desc",
  },

  {
    sortingFn: (a, b) => (a.description > b.description ? 1 : -1),
    label: "Sort: A to Z",
    value: "a-to-z",
  },
  {
    sortingFn: (a, b) => (a.description > b.description ? -1 : 1),
    label: "Sort: Z to A",
    value: "z-to-a",
  },

  {
    sortingFn: (a, b) => (a.created_data > b.created_data ? 1 : -1),
    label: "Sort: Newest first",
    value: "newest-first",
  },
  {
    sortingFn: (a, b) => (a.created_data > b.created_data ? -1 : 1),
    label: "Sort: Oldest first",
    value: "oldest-first",
  },
];

const schema = z.object({
  sort: z.string().optional(),
});

export const useSort = () => {
  const [searchParams, setSearchParams] = useTypedSearchParams({ schema });

  const value =
    options.find((opt) => opt.value === searchParams.sort) || options[0];

  const setValue = useCallback(
    (opt: Option) => {
      setSearchParams({ sort: opt.value });
    },
    [setSearchParams],
  );

  return { value, setValue, options } as const;
};
