import { BID_STATUS } from "src/app/const";
import { ValueOf } from "src/app/utils/types";
import { z } from "zod";

export const apiBidSchema = z.object({
  bid_id: z.number(),
  bid_quantity: z.number(),
  bid_status: z.object({ label: z.nativeEnum(BID_STATUS) }),
  bid_date: z.string(),
  bid_offer: z.number(),
  canEdit: z.boolean(),
  type: z.union([z.literal("bid"), z.literal("buy_now")]),
});

export type ApiBid = z.infer<typeof apiBidSchema>;

export class Bid {
  bid_id: number;
  bid_quantity: number;
  bid_status: { label: ValueOf<typeof BID_STATUS> };
  bid_date: string;
  bid_offer: number;
  canEdit: boolean;
  type: "bid" | "buy_now";

  constructor(apiBid: ApiBid) {
    this.bid_id = apiBid.bid_id;
    this.bid_quantity = apiBid.bid_quantity;
    this.bid_status = apiBid.bid_status;
    this.bid_date = apiBid.bid_date;
    this.bid_offer = apiBid.bid_offer;
    this.canEdit = apiBid.canEdit;
    this.type = apiBid.type;
  }

  get isPending() {
    return this.bid_status.label.toLowerCase() === "pending";
  }

  get isWinner() {
    return this.bid_status.label === BID_STATUS.WINNER;
  }
}
