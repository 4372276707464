import { cn } from "../../utils/cn";
import styles from "./index.module.css";
import { CSSProperties } from "react";

type Props = {
  src: string | null;
  aspectRatio?: [number, number];
  maxHeight?: number;
  maxWidth?: number;
  style?: CSSProperties;
  className?: string;
};

export function VideoVimeo({
  src,
  aspectRatio = [16, 9],
  maxHeight,
  maxWidth,
  style,
  className = "",
}: Props) {
  // Incorrect format = "https://vimeo.com/868448924?share=copy"
  // Correct format = "https://player.vimeo.com/video/495058799?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&autoplay=1#t="

  if (!src) return null;
  const isIncorrectFormat = getIsVideoSrcIncorrect(src);
  const _src = isIncorrectFormat ? getCorrectVideoSrc(src) : src;
  const _maxWidth = maxHeight
    ? (maxHeight * aspectRatio[0]) / aspectRatio[1]
    : maxWidth;

  if (!_src) return null;

  return (
    <iframe
      className={cn(styles.root, className)}
      src={removeAutoplay(_src)}
      title="video"
      width="100%"
      height="auto"
      style={{
        maxWidth: _maxWidth,
        maxHeight,
        aspectRatio: aspectRatio.join(" / "),
        ...style,
      }}
    />
  );
}

function getIsVideoSrcIncorrect(src: string) {
  try {
    return src.startsWith("https://vimeo.com/");
  } catch (e) {
    return false;
  }
}

function getCorrectVideoSrc(src: string) {
  try {
    const videoId = src.replace("https://vimeo.com/", "").split("?")[0];
    return `https://player.vimeo.com/video/${videoId}?color&controls=1&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1&autoplay=0#t=`;
  } catch (e) {
    return "";
  }
}

function removeAutoplay(src: string) {
  return src
    .replace("autoplay=1", "autoplay=0")
    .replace("autoplay=1#t=", "autoplay=0");
}
