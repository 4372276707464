import styles from "./radio-payment-method.module.css";
import { RadioGroup } from "@headlessui/react";
import { FaRegCircleDot } from "react-icons/fa6";
import { FaRegCircle } from "react-icons/fa";
import { cn } from "src/app/utils/cn";
import srcGenericCard from "src/media/common/generic-card.png";
import srcMasterCard from "src/media/common/master-card.png";
import srcVisaCard from "src/media/common/visa-card.png";
import srcAmericanExpressCard from "src/media/common/american-express-card.png";
import srcDinnersClubCard from "src/media/common/dinersclub-card.png";
import srcDiscoverCard from "src/media/common/discover-card.png";
import srcJcbCard from "src/media/common/jcb-card.png";
import srcUnionPayCard from "src/media/common/unionpay-card.png";
import { useMyUser } from "src/app/hooks/useMyUser";
import { Skeleton } from "src/app/components/skeleton";
import { PaymentCardV1 } from "src/app/models/PaymentCardV1";
import { path } from "src/app/routes/path";
import { A } from "src/app/components/a";
import { noop } from "lodash";

export const RadioCard = ({
  myCards,
  onClose,
  isLoading,
  defaultCard = "",
  onChange = noop,
}: {
  myCards: PaymentCardV1[];
  isLoading: boolean;
  onClose: () => void;
  defaultCard?: string;
  onChange: (cardID: string) => void;
}) => {
  const user = useMyUser();

  if (isLoading)
    return <Skeleton.Rect style={{ borderRadius: "8px" }} height={90} />;

  if (!myCards.length) return null;

  return (
    <RadioGroup
      defaultValue={defaultCard}
      onChange={(cardId) => onChange(cardId)}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {myCards.map((card) => (
        <RadioGroup.Option
          key={card.id_payment_method}
          value={card.id_payment_method}
          className={({ checked }) =>
            cn(styles.root, checked && styles.checked)
          }
        >
          {({ checked }) => (
            <div className={styles.inner}>
              <div className={styles.left}>
                <div className={cn(styles.radio, !checked && styles.unchecked)}>
                  {!checked ? (
                    <FaRegCircle size={18} />
                  ) : (
                    <FaRegCircleDot size={18} />
                  )}
                </div>
                <div className={styles.content}>
                  <RadioGroup.Label as="p" style={{ margin: 0 }}>
                    {`${capitalizeFirstLetter(card.brand)} xxxx${card.last4}`}
                  </RadioGroup.Label>
                  <RadioGroup.Label as="p" style={{ margin: 0 }}>
                    {`${user.first_name} ${user.last_name}`}
                  </RadioGroup.Label>
                  <RadioGroup.Label as="p" style={{ margin: 0 }}>
                    {card.exp_month} / {card.exp_year}
                  </RadioGroup.Label>
                </div>
              </div>
              <div className={styles.creditCardImage}>
                <img alt="credit-card-brand" src={getCardImage(card.brand)} />
              </div>
            </div>
          )}
        </RadioGroup.Option>
      ))}
      <div style={{ textAlign: "left" }}>
        <A
          to={path.profile.payments}
          onClick={onClose}
          className={styles.addCreditCard}
        >
          + Add a debit/credit card
        </A>
      </div>
    </RadioGroup>
  );
};

export function getCardImage(type: string) {
  const cardImages: Record<string, string> = {
    mastercard: srcMasterCard,
    visa: srcVisaCard,
    amex: srcAmericanExpressCard,
    diners: srcDinnersClubCard,
    discover: srcDiscoverCard,
    jcb: srcJcbCard,
    unionpay: srcUnionPayCard,
  };

  return cardImages[type.toLowerCase()] || srcGenericCard;
}

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
