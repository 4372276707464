import { noop } from "lodash";
import styles from "./view-details.module.css";
import React from "react";

export const ViewDetails = ({ onClick = noop }) => {
  return (
    <button className={styles.root} onClick={onClick}>
      Discover More
    </button>
  );
};
