import { z } from "zod";
import { Experience, apiExperienceSchema } from "./Experience";

export const apiCellarExperienceSchema = z.object({
  id: z.number(),
  id_auction_participant: z.number(),
  returned: z.union([z.literal(0), z.literal(1)]),
  is_winner: z.union([z.literal(0), z.literal(1)]),
  status_availability: z.union([z.literal(0), z.literal(1)]),
  date_winner: z.string(),
  quantity_winner: z.number(),
  single_bid_winner: z.number(),
  insurance: z.number(),
  priority: z.union([z.literal(0), z.literal(1)]),
  single_bid: z.number(),
  quantity: z.number(),
  deleted: z.union([z.literal(0), z.literal(1)]),
  bid_date: z.string(),
  note: z.unknown(),
  notice_as_paid: z.union([z.literal(0), z.literal(1)]),
  payment_deadline: z.string().nullable(),
  is_gift: z.union([z.literal(0), z.literal(1)]),
  id_auction_participant_mp: z.string(),
  isPaid: z.boolean(),
  totalBid: z.number(),
  bidWinner: z.number(),
  wareHouseInformation: z.unknown(),
  experience: apiExperienceSchema,
});

type ApiCellarExperienceSchema = z.infer<typeof apiCellarExperienceSchema>;

export class CellarExperience {
  id: number;
  id_auction_participant: number;
  returned: boolean;
  is_winner: boolean;
  status_availability: boolean;
  date_winner: string;
  quantity_winner: number;
  single_bid_winner: number;
  insurance: number;
  priority: boolean;
  single_bid: number;
  quantity: number;
  deleted: boolean;
  bid_date: string;
  note: unknown;
  notice_as_paid: boolean;
  payment_deadline: string | null;
  is_gift: boolean;
  id_auction_participant_mp: string;
  isPaid: boolean;
  totalBid: number;
  bidWinner: number;
  wareHouseInformation: unknown;
  experience: Experience;

  constructor(apiItem: ApiCellarExperienceSchema) {
    this.id = apiItem.id;
    this.id_auction_participant = apiItem.id_auction_participant;
    this.returned = !!apiItem.returned;
    this.is_winner = !!apiItem.is_winner;
    this.status_availability = !!apiItem.status_availability;
    this.date_winner = apiItem.date_winner;
    this.quantity_winner = apiItem.quantity_winner;
    this.single_bid_winner = apiItem.single_bid_winner;
    this.insurance = apiItem.insurance;
    this.priority = !!apiItem.priority;
    this.single_bid = apiItem.single_bid;
    this.quantity = apiItem.quantity;
    this.deleted = !!apiItem.deleted;
    this.bid_date = apiItem.bid_date;
    this.note = apiItem.note;
    this.notice_as_paid = !!apiItem.notice_as_paid;
    this.payment_deadline = apiItem.payment_deadline;
    this.is_gift = !!apiItem.is_gift;
    this.id_auction_participant_mp = apiItem.id_auction_participant_mp;
    this.isPaid = apiItem.isPaid;
    this.totalBid = apiItem.totalBid;
    this.bidWinner = apiItem.bidWinner;
    this.wareHouseInformation = apiItem.wareHouseInformation;
    this.experience = new Experience(apiItem.experience);
  }
}
