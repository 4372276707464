import { CSSProperties } from "react";
import { toPx } from "../../utils/toPx";

type Props = {
  size: number | string;
  className?: string;
  id?: string;
  style?: CSSProperties;
  inline?: boolean;
};

export function Spacer({ size, className = "", id = "", inline }: Props) {
  return (
    <div
      role="none"
      className={className}
      id={id}
      style={{
        width: toPx(size),
        height: toPx(size),
        flexShrink: 0,
        display: inline ? "inline-block" : "block",
      }}
    />
  );
}
