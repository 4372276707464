import { Select } from "src/app/components/form-elements/select";

type Action = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
};

type Props = {
  actions: Action[];
  style?: React.CSSProperties;
  placement?: "top" | "bottom";
};

export const SelectAction = ({
  actions,
  placement = "bottom",
  style = {},
}: Props) => {
  function handleChange(action: Action) {
    action.onClick();
  }

  return (
    <Select
      options={actions.map((action) => ({ ...action, value: action.label }))}
      onChange={handleChange}
      style={style}
      selectedOption={null}
      placement={placement}
      renderTriggerContent={() => "Actions"}
    />
  );
};
