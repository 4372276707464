/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { Fragment } from "react";
import styles from "./ContCardSlidePopup.module.css";
import { ID_AUCTION_TYPE } from "src/app/const";
import { Carousel } from "src/app/components/carousel";
import { VideoVimeo } from "src/app/components/video-vimeo";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { isAuctionOfLot } from "src/app/models/auctions";
import { Countdown } from "src/app/components/countdown";
import { RiExternalLinkLine } from "react-icons/ri";

type ContCardSlidePopupProps = {
  auction: AuctionOfLot | AuctionOfBarrel;
  hideDescription?: boolean;
};

export function ContCardSlidePopup({
  auction,
  hideDescription,
}: ContCardSlidePopupProps) {
  const moreInformation =
    isAuctionOfLot(auction) &&
    auction.lot?.components.some(
      (component) => component.about.trim() === auction.more_information.trim(),
    )
      ? ""
      : auction?.more_information;
  const producerName = isAuctionOfLot(auction)
    ? auction.lot?.components[0]?.builder?.name ||
      auction.experience?.builder?.name
    : auction.barrel?.builder?.name;
  const shortDescriptionAuction = auction.short_description;
  const supplierLinkVideo = isAuctionOfLot(auction)
    ? auction?.lot?.components[0]?.builder.winery_link_video ||
      auction?.experience?.builder?.winery_link_video
    : auction.barrel?.builder.winery_link_video;
  const supplierDescription = isAuctionOfLot(auction)
    ? auction?.lot?.components[0]?.builder.winery_description ||
      auction?.experience?.builder?.winery_description
    : auction.barrel?.builder.winery_description;
  const lotDescription = isAuctionOfLot(auction)
    ? auction.lot?.case_ || auction.experience?.builder?.name
    : auction.description;
  const name = isAuctionOfLot(auction)
    ? auction.lot?.case_
    : auction.barrel?.name;
  const finishDate = auction.finish_data;

  return (
    <React.Fragment>
      <div>
        {![ID_AUCTION_TYPE.PERMANENT, ID_AUCTION_TYPE.PRIVATE_SALE].includes(
          auction.type.id as any,
        ) && (
          <Countdown targetDate={finishDate} style={{ marginBottom: "1rem" }} />
        )}
        <p>{name}</p>
        {!!producerName && !hideDescription && producerName !== "" && (
          <p className={styles.Title1} style={{ margin: "0.5rem 0" }}>
            {producerName}
          </p>
        )}

        <div className={styles.Bid}></div>

        {!!auction.catalogue && (
          <a
            href={auction.catalogue}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              gap: "6px",
              margin: "20px 0 14px",
            }}
          >
            Catalogue <RiExternalLinkLine size={17} />
          </a>
        )}

        {!!auction.is_charity && (
          <p className={styles.sponsorMessage}>
            To learn about Special Offers & Rates from Gucci, VistaJet, and
            Octavin – The 2023 Golden Vines® Sponsors & Partners – please click{" "}
            <a href="https://liquidicons.com/the-2023-special-offers-and-rates">
              here
            </a>
            .
          </p>
        )}

        {!!shortDescriptionAuction && shortDescriptionAuction !== "" && (
          <p className={styles.Description}>{shortDescriptionAuction}</p>
        )}

        {!!auction.experience && (
          <>
            <p className={styles.Tastings} style={{ marginBottom: "10px" }}>
              EXPERIENCE
            </p>
            <div className={styles.Divider}></div>

            <VideoVimeo src={auction.experience.link_video} />

            <Carousel
              style={{ marginBottom: "12px" }}
              images={auction.experience.images.map((i) => i.image)}
              aspectRatio={[16, 9]}
              iconMargin={16}
              objectFit="cover"
            />
            <p>{auction.experience?.short_description}</p>
          </>
        )}
      </div>

      <div>
        {!!lotDescription && lotDescription !== "" && (
          <>
            <p className={styles.Tastings} style={{ marginBottom: "10px" }}>
              MORE DETAILS
            </p>
            <div className={styles.Divider}></div>
          </>
        )}

        <div style={{ display: "flex", flexDirection: "column" }}>
          {isAuctionOfLot(auction) &&
            auction?.lot?.components?.map((component, index: number) => (
              <Fragment key={`key-${index}`}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    style={{
                      width: "100px",
                      height: "139px",
                      zIndex: "1000",
                      objectFit: "contain",
                    }}
                    src={component.media.cover}
                    alt={`bottle-${index}`}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p className={styles.Clos} style={{ marginBottom: "0" }}>
                      {component.nameProductWithoutVintage}
                    </p>
                    <p
                      className={styles.Description}
                      style={{ color: "#656565", marginBottom: 0 }}
                    >
                      {`${component.color} ${component.vintage || "NV"} | ${
                        component.quantity
                      } X ${component.size}L`}
                    </p>
                  </div>
                </div>
                <p>{component.about}</p>
                <div className={styles.BidBottle}></div>
              </Fragment>
            ))}
        </div>
        {!!supplierDescription && supplierDescription !== "" && (
          <>
            <p
              className={styles.Tastings}
              style={{ marginBottom: "10px", marginTop: "20px" }}
            >
              THE ESTATE
            </p>
            <div className={styles.Divider}></div>
            <p className={styles.Description}>{supplierDescription}</p>
          </>
        )}
        {!!moreInformation && (
          <>
            <p
              className={styles.Tastings}
              style={{ marginBottom: "10px", marginTop: "20px" }}
            >
              MORE INFORMATION
            </p>
            <div className={styles.Divider}></div>
            <p className={styles.Description}>{moreInformation}</p>
          </>
        )}

        {!!supplierLinkVideo?.includes("player.vimeo.com") && (
          <VideoVimeo src={supplierLinkVideo} />
        )}
      </div>
    </React.Fragment>
  );
}
