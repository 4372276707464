import { useInView } from "react-intersection-observer";
import { ID_IO_ROOT } from "src/app/const";

export const AdsBanner = ({ src }: { src: string }) => {
  const { ref, inView } = useInView({
    rootMargin: "600px 0px",
    triggerOnce: true,
    root: document.getElementById(ID_IO_ROOT),
  });

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        background: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {!!inView && <img src={src} style={{ width: "100%" }} alt="banners" />}
    </div>
  );
};
