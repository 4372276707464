import { ErrorMessage } from "./error-message";
import { Field } from "./field";
import { Form } from "./form";

/**
 * @deprecated
 */
export const CruForm = {
  Form: Form,
  Field: Field,
  ErrorMessage: ErrorMessage,
};
