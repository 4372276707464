export function formatPercentage(
  value: undefined,
  options?: Intl.NumberFormatOptions,
): undefined;
export function formatPercentage(
  value: number,
  options?: Intl.NumberFormatOptions,
): string;
export function formatPercentage(
  value: number | undefined,
  options?: Intl.NumberFormatOptions,
): string | undefined;
export function formatPercentage(
  value: number | undefined,
  {
    style = "percent",
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    ...options
  }: Intl.NumberFormatOptions = {},
) {
  if (typeof value === "undefined") return;

  return new Intl.NumberFormat([...navigator.languages], {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    ...options,
  }).format(value / 100);
}
