import styles from "./Link.module.css";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const Link = ({ className = "", to, text = "" }) => {
  return (
    <RouterLink
      className={`btn btn-primary btn-full ${styles.root} ${className}`}
      to={to}
    >
      {text}
    </RouterLink>
  );
};
