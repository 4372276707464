import React from "react";

/**
 * @deprecated prefer src/app/components/badge/index.tsx.
 * da adattare algi casi d'usp
 */
export function MyOfferStatus({ status, type, notes }) {
  status = parseInt(status);

  // STATUS OF OUTSTANDING PAYMENT STATUS (type: "OUTSTANDING-PAYMENT"):
  // 0 = Pending
  // 1 = Paid
  // 2 = Marked as paid
  // -1 = Unpaid

  // STATUS OF MY OFFER PENDING STATUS (type: "MY-OFFERS-PENDING"):
  // -1 = Begin
  // 0 = Suspended
  // 1 = Open
  // 2 = Closed

  // STATUS OF MY OFFER PENDING STATUS (type: "MY-OFFERS-HISTORY"):
  // -1 = Unsuccessful
  // 0 = Pending
  // 1 = Winner
  // 2 = Lost Bid
  // 3 = Highest Bidder

  // STATUS OF MY SUBSCRIPTIONS (type: "SUBSCRIPTIONS"):
  // 0 = Canceled
  // 1 = Active
  // 2 = Unpaid
  // 3 = Trialing
  // 4 = Past due
  // 5 = Incomplete
  // 6 = Incomplete expired
  // 7 = Downgrade
  // 8 = Upgrade
  // 9 = Pending Cancel
  // 10 = Pending Downgrade

  // STATUS OF SHIPMENTS (type: "SHIPMENTS"):
  // 0 = Shipping Request
  // 1 = Pending Payment
  // 2 = Under processing
  // 3 = Shipped
  // 4 = Delivered

  var statusLabel = "Undefined";
  var statusClass = "undefined";

  if (type === "OUTSTANDING-PAYMENT") {
    status = parseInt(status);

    switch (status) {
      case -1:
        statusClass = "unpaid";
        statusLabel = "Unpaid";
        break;

      case 0:
        statusClass = "pending";
        statusLabel = "Pending";
        break;

      case 1:
        statusClass = "paid";
        statusLabel = "Paid";
        break;

      case 2:
        statusClass = "marked-as-paid";
        statusLabel = "Marked as paid";
        break;

      default:
        break;
    }
  } else if (type === "MY-OFFERS-PENDING") {
    switch (status) {
      case -1:
        statusClass = "begin";
        statusLabel = "Begin";
        break;

      case 0:
        statusClass = "suspended";
        statusLabel = "Suspended";
        break;

      case 1:
        statusClass = "open";
        statusLabel = "Open";
        break;

      case 2:
        statusClass = "closed";
        statusLabel = "Closed";
        break;

      default:
        break;
    }
  } else if (type === "MY-OFFERS-HISTORY") {
    switch (status) {
      case -1:
        statusClass = "unsuccessful";
        statusLabel = "Unsuccessful";
        break;

      case 0:
        statusClass = "pending";
        statusLabel = "Pending";
        break;

      case 1:
        statusClass = "winner";
        statusLabel = "Winner";
        break;

      case 2:
        statusClass = "lost-bid";
        statusLabel = "Lost Bid";
        break;

      case 3:
        statusClass = "highest-bidder";
        statusLabel = "Highest Bidder";
        break;

      default:
        break;
    }
  } else if (type === "SUBSCRIPTIONS") {
    switch (status) {
      case 0:
        statusClass = "cancelled";
        statusLabel = "Cancelled";
        break;
      case 1:
        statusClass = "active";
        statusLabel = "Active";
        break;
      case 2:
        statusClass = "unpaid";
        statusLabel = "Unpaid";
        break;
      case 3:
        statusClass = "trialing";
        statusLabel = "Trialing";
        break;
      case 4:
        statusClass = "past-due";
        statusLabel = "Past due";
        break;
      case 5:
        statusClass = "incomplete";
        statusLabel = "Incomplete";
        break;
      case 6:
        statusClass = "incomplete-expired";
        statusLabel = "Incomplete expired";
        break;
      case 7:
        statusClass = "downgrade";
        statusLabel = "Downgrade";
        break;
      case 8:
        statusClass = "upgrade";
        statusLabel = "Upgrade";
        break;
      case 9:
        statusClass = "pending-cancel";
        statusLabel = "Pending Cancel";
        break;
      case 10:
        statusClass = "pending-downgrade";
        statusLabel = "Pending Downgrade";
        break;

      default:
        break;
    }
  } else if (type === "SHIPMENTS") {
    switch (status) {
      case 0:
        statusClass = "shipping-request";
        statusLabel = "Shipping Request";
        break;
      case 1:
        statusClass = "pending-payment";
        statusLabel = "Pending Payment";
        break;
      case 2:
        statusClass = "under-processing";
        statusLabel = "Under processing";
        break;
      case 3:
        statusClass = "shipped";
        statusLabel = "Shipped";
        break;
      case 4:
        statusClass = "delivered";
        statusLabel = "Delivered";
        break;

      default:
        break;
    }
  }

  return (
    <div className="my-offer-status">
      <span className={`mos-status-label mos-status-${statusClass}`}>
        {statusLabel}
      </span>
      {!!notes && (
        <div className="mt-5">
          <span className="text-danger">{notes}</span>
        </div>
      )}
    </div>
  );
}
