import "swiper/swiper-bundle.min.css";
import styles from "./slider-lots.module.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import { useInView } from "react-intersection-observer";
import { Img } from "src/app/components/img";
import { ID_IO_ROOT } from "src/app/const";
import { cn } from "src/app/utils/cn";
import { Swiper as SwiperType } from "swiper/types";

type Props = {
  gallery: string[];
  setCurrentSlideIndex: (index: number) => void;
  setSliderInstance: (swiper: SwiperType) => void;
  height?: number;
};

export const SliderLots = ({
  gallery,
  setCurrentSlideIndex,
  setSliderInstance,
  height,
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: "600px 0px",
    triggerOnce: true,
    root: document.getElementById(ID_IO_ROOT),
  });

  if (!inView) {
    return <div ref={ref} />;
  }

  return (
    <div
      className={cn(styles.SliderLots, "swiperCollections")}
      style={{ height: height }}
    >
      <Swiper
        allowTouchMove={false}
        navigation={gallery.length > 1}
        modules={[EffectFade, Navigation]}
        onSlideChange={(swiper) => {
          setCurrentSlideIndex(swiper.activeIndex);
        }}
        onSwiper={(swiper) => setSliderInstance(swiper)}
      >
        {gallery.flat().map((photo, index) => {
          return (
            <SwiperSlide key={`slide-${index}`}>
              <div className={styles.image_loader}>
                <Img
                  className="mw-100"
                  src={photo}
                  alt="slide wine bottle"
                  lazyLoad={false}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
