import {
  Dispatch,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { useUnmount } from "react-use";
import {
  FormContextType,
  ValidateFn,
  BaseValues,
  FormFieldName,
} from "./types";
import { useDomId } from "src/app/hooks/useDomId";

const FieldCtx = createContext<null | {
  name: string;
  controlId: string;
  errorMsgIds: string[];
  nativeLabelCount: number;
  labelIds: string[];
  descriptionIds: string[];
  setErrorMsgIds: Dispatch<SetStateAction<string[]>>;
  setNativeLabelCount: Dispatch<SetStateAction<number>>;
  setLabelIds: Dispatch<SetStateAction<string[]>>;
  setDescriptionIds: Dispatch<SetStateAction<string[]>>;
}>(null);

type ChildrenRenderProps<
  TValues extends BaseValues,
  TFieldName extends FormFieldName<TValues>,
> = (accessibilityProps: {
  "aria-labelledby": string | undefined;
  "aria-describedby": string | undefined;
  "aria-errormessage": string | undefined;
  "aria-invalid": true | undefined;
  id: string;
  name: TFieldName;
}) => ReactElement;

type Props<
  TValues extends BaseValues,
  TFieldName extends FormFieldName<TValues>,
> = {
  ctx: FormContextType<TValues, any, any>;
  name: TFieldName;
  validate?: ValidateFn<TValues, TFieldName>;
  children: ChildrenRenderProps<TValues, TFieldName>;
};

export const Field = <
  TValues extends BaseValues,
  TFieldName extends FormFieldName<TValues>,
>({
  ctx,
  name,
  validate,
  children,
}: Props<TValues, TFieldName>) => {
  const controlId = useDomId();
  const [errorMsgIds, setErrorMsgIds] = useState<string[]>([]);
  const [labelIds, setLabelIds] = useState<string[]>([]);
  const [descriptionIds, setDescriptionIds] = useState<string[]>([]);
  const [nativeLabelCount, setNativeLabelCount] = useState(0);
  const isFirstRenderRef = useRef(true);

  ctx.__validators[name] = validate;

  if (isFirstRenderRef.current) {
    ctx.__errors[name] =
      validate?.(ctx.getFieldValue(name), ctx.getFieldValue) || undefined;
    isFirstRenderRef.current = false;
  }

  const error = ctx.getFieldError(name)?.trim();
  const isInvalid = !!error && !!ctx.getFieldTouched(name);

  if (nativeLabelCount > 1) {
    console.error(
      'An element can only have 1 native <label>, please use the "as" prop on the secondary <CruForm.Label> to something else than "label"',
    );
  }

  const accessibilityProps = {
    "aria-labelledby": labelIds.join(" ") || undefined,
    "aria-describedby": descriptionIds.join(" ") || undefined,
    "aria-errormessage": errorMsgIds.join(" ") || undefined,
    "aria-invalid": isInvalid || undefined,
    id: controlId,
    name: name,
  };

  useUnmount(() => {
    ctx.__validators[name] = undefined;
    ctx.__resetFieldError(name);
  });

  return (
    <FieldCtx.Provider
      value={{
        controlId,
        errorMsgIds,
        setErrorMsgIds,
        name: name as string,
        nativeLabelCount,
        setNativeLabelCount,
        labelIds,
        setLabelIds,
        descriptionIds,
        setDescriptionIds,
      }}
    >
      {children(accessibilityProps)}
    </FieldCtx.Provider>
  );
};

export const useFieldCtx = () => {
  const ctx = useContext(FieldCtx);
  if (!ctx) {
    throw new Error("This component should be used inside <CruForm.Field>");
  }
  return ctx;
};
