import { ElementType, useRef } from "react";
import { CruFormContextType, CruFormValues } from "./types";
import { useUnmount } from "react-use";

type Props<
  TFormData extends CruFormValues,
  TName extends keyof TFormData,
  TComponentProps extends object,
> = TComponentProps & {
  cruForm: CruFormContextType<TFormData>;
  component: ElementType<TComponentProps>;
  name: TName;
  validate?: (
    value: TFormData[TName],
    values: TFormData,
  ) => string | false | null | undefined | void;
};

export function Field<
  TFormData extends CruFormValues,
  TName extends keyof TFormData,
  TComponentProps extends object,
>({
  component,
  cruForm,
  name,
  validate,
  ...props
}: Props<TFormData, TName, TComponentProps>) {
  const Component = component as any;
  const isFirstRenderRef = useRef(true);

  cruForm.validators[name] = validate;

  if (isFirstRenderRef.current) {
    cruForm.errors[name] =
      validate?.(cruForm.values[name], cruForm.values) || undefined;
    isFirstRenderRef.current = false;
  }

  useUnmount(() => {
    delete cruForm.validators[name];
    cruForm.__setErrors({ [name as any]: undefined });
  });

  return <Component name={name} {...props} />;
}
