import { useCardCtx } from "../card-context";

export const Tags = () => {
  const cardCtx = useCardCtx();
  const tags = cardCtx.auction.tags;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "6px",
      }}
    >
      {tags.map((element, index: number) => (
        <div
          key={`shop-card-tags-${index}-${cardCtx.auction.id}-${cardCtx.auction.type_product}`}
          style={{
            textTransform: "capitalize",
            borderRadius: "16px",
            background: "#ebdede",
            padding: "3px 10px ",
            fontSize: "12px",
            color: "rgb(101, 101, 101)",
          }}
        >
          {element.toLowerCase()}
        </div>
      ))}
    </div>
  );
};
