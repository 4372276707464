import styles from "./payment-method.module.css";
import { RadioGroup } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import { QKEY } from "src/app/api/QKEY";
import { useChangePaymentMethodMutation } from "src/app/api/mutations_new_to_rename";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { useMyUser } from "src/app/hooks/useMyUser";
import { PaymentMethodV1 } from "src/app/models/PaymentMethodV1";

export const PaymentMethod = ({
  method_payment,
}: {
  method_payment: PaymentMethodV1;
}) => {
  const user = useMyUser();
  const alertPopup = useAlertPopup();
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const isSelected =
    method_payment.id_method_payment ===
    user.method_payment?.active.id_method_payment;

  const isPending =
    !!user.method_payment?.pending &&
    user.method_payment.pending.slug === method_payment.slug;

  const changePaymentMethodMutation = useChangePaymentMethodMutation({
    onSuccess() {
      setLoading(true);
      queryClient
        .invalidateQueries({ queryKey: [QKEY.ME] })
        .then(() => setLoading(false));
    },
    onError(err) {
      const errorMessage = err.response?.data?.message ?? "Error on Method Payment Change"
      setLoading(true);
      queryClient
        .invalidateQueries({ queryKey: [QKEY.ME] })
        .then(() => setLoading(false))
        .then(() =>
          alertPopup.error({
            title: errorMessage,
          }),
        );
    },
  });

  return (
    <RadioGroup
      value={isSelected}
      onChange={() =>
        changePaymentMethodMutation.mutate({
          id_method_payment: method_payment.id_method_payment,
        })
      }
    >
      <RadioGroup.Option
        value={method_payment.id_method_payment}
        disabled={!!user.checkUser.is_missing_card || isPending}
      >
        <div className={styles.root}>
          {loading ? (
            <SpinnerSm size={20} style={{ color: "#00000030" }} />
          ) : (
            <FaRegCircleCheck
              color={isSelected ? "#783233" : "#00000030"}
              size={20}
            />
          )}
          <div className={styles.text}>
            Pay by {method_payment.name}
            {!!isPending && !!user.method_payment?.pending && (
              <div className={styles.badge}>
                Request on {user.method_payment.pending.handling_date_frontend}
              </div>
            )}
          </div>
        </div>
      </RadioGroup.Option>
    </RadioGroup>
  );
};
