import styles from "./Badge.module.css";
import React from "react";

export const Badge = ({ show = true, title, className = "", style = {} }) => {
  if (!show) return;

  const _title = typeof title === "string" ? [title] : title;

  return (
    <div
      className={`${_title.length === 1 ? "pb-2" : ""} ${
        styles.root
      } ${className}`}
      style={style}
    >
      {_title.map((text, index) => (
        <div key={`CardBadgeText-${index}`}>{text}</div>
      ))}
    </div>
  );
};
