import styles from "./step3.module.css";
import { Label } from "../components/label";
import { Button } from "src/app/components/button";
import { cn } from "src/app/utils/cn";
import { TierName } from "src/app/const";
import { usePrice, useSubscriptionPlan } from "../utils";
import { Headline } from "../components/headline";
import { SignupStepper } from "../components/signup-stepper";
import { SignupFormValues } from ".";
import { CruForm } from "src/app/components/cru-form";
import { InputCardNumber } from "src/app/components/form-elements/input-card-number";
import { InputCardExpiry } from "src/app/components/form-elements/input-card-expiry";
import { InputCardCvc } from "src/app/components/form-elements/input-card-cvc";
import { InputCoupon } from "../components/input-coupon";
import { CruFormContextType } from "src/app/components/cru-form/types";
import { Redirect } from "react-router-dom";
import { path } from "src/app/routes/path";
import { MessageBox } from "../components/message-box";

type Props = {
  tier: TierName;
  cruForm: CruFormContextType<SignupFormValues>;
  annual: boolean;
};

export const Step3 = ({ tier, annual, cruForm }: Props) => {
  const subscriptionPlan = useSubscriptionPlan({
    tierName: tier,
    annual: annual,
  });

  const price = usePrice({
    tierName: tier,
    annual: annual,
  });

  const showCreditCard =
    !cruForm.values.coupon.data ||
    cruForm.values.coupon.data.check_credit_card_on_registration;

  if (!subscriptionPlan) {
    return <Redirect to={path.signup.planSelection()} push={false} />;
  }

  return (
    <>
      <header
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <SignupStepper description="Payment Details" step={4} />
        <Headline>Please enter your preferred payment method</Headline>
      </header>

      {!!showCreditCard && (
        <div className={styles.cardGrid}>
          <div className={styles.spanTwo}>
            <Label htmlFor="input-card-number">Card Number</Label>
            <CruForm.Field
              component={InputCardNumber}
              cruForm={cruForm}
              onChange={(e) => cruForm.setValues({ cardNumber: e })}
              onBlur={() => cruForm.setTouched({ cardNumber: true })}
              name="cardNumber"
              validate={(value) => {
                if (
                  !cruForm.values.coupon.data ||
                  cruForm.values.coupon.data.check_credit_card_on_registration
                )
                  return value.error?.message;
              }}
            />
            <CruForm.ErrorMessage cruForm={cruForm} name="cardNumber" />
          </div>
          <div>
            <Label htmlFor="input-card-expiry">Expiration date</Label>
            <CruForm.Field
              component={InputCardExpiry}
              cruForm={cruForm}
              onChange={(e) => cruForm.setValues({ cardExpiry: e })}
              onBlur={() => cruForm.setTouched({ cardExpiry: true })}
              name="cardExpiry"
              validate={(value) => {
                if (
                  !cruForm.values.coupon.data ||
                  cruForm.values.coupon.data.check_credit_card_on_registration
                )
                  return value.error?.message;
              }}
            />
            <CruForm.ErrorMessage cruForm={cruForm} name="cardExpiry" />
          </div>
          <div>
            <Label htmlFor="input-card-cvc">Security code</Label>
            <CruForm.Field
              component={InputCardCvc}
              cruForm={cruForm}
              onChange={(e) => cruForm.setValues({ cardCvc: e })}
              onBlur={() => cruForm.setTouched({ cardCvc: true })}
              name="cardCvc"
              validate={(value) => {
                if (
                  !cruForm.values.coupon.data ||
                  cruForm.values.coupon.data.check_credit_card_on_registration
                )
                  return value.error?.message;
              }}
            />
            <CruForm.ErrorMessage cruForm={cruForm} name="cardCvc" />
          </div>
          <div className={cn(styles.spanTwo, styles.disclaimer)}>
            By providing your card details you authorize CRURATED to charge
            future payments to it in accordance with the respective terms and
            conditions.
          </div>
        </div>
      )}

      <div style={{ marginBottom: 20 }}>
        <Label htmlFor="input-coupon">Coupon Code</Label>
        <CruForm.Field
          idTier={subscriptionPlan.id_customer_role}
          cruForm={cruForm}
          component={InputCoupon}
          name="coupon"
          onChange={(e) => {
            cruForm.setValues({ coupon: e });
            cruForm.validateTouchedFields();
          }}
          value={cruForm.values.coupon}
        />
        {!!cruForm.values.coupon.data && (
          <MessageBox intent="success" style={{ marginTop: "20px" }}>
            <div>{`Coupon "${cruForm.values.coupon.data.name}" applied.`}</div>
            <div
              style={{ fontSize: "0.875rem", fontWeight: 600 }}
            >{`${cruForm.values.coupon.freeMonths} Free Months of subscription`}</div>
          </MessageBox>
        )}
      </div>

      <Button
        label={
          cruForm.values.coupon.freeMonths
            ? "Subscribe for Free"
            : `Pay ${price}`
        }
        style={{ width: "100%" }}
        isLoading={cruForm.submission.isLoading}
      />
    </>
  );
};
