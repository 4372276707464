import { ReactNode, useState } from "react";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { PaymentIntentResult } from "@stripe/stripe-js";
import { StepPaymentMethod } from "./step-payment-method";
import { StepPaymentSettled } from "./step-payment-settled";
import { Dialog } from "src/app/components/dialog-2";
import { StepAddNewCard } from "./step-add-new-card";
import { CheckPurchase } from "../check-purchase";
import { AUCTION_ERROR_TYPE } from "src/app/const";

type Props = {
  title: string;
  content: ReactNode | string;
  auction: AuctionOfBarrel | AuctionOfLot;
  onConfirm: () => void;
  onClose: () => void;
  bidDetails?: {
    price: number;
    quantity: number;
  };
  showTaxDisclaimer?: boolean;
};

type PaymentStep = "check-bid" | "summary" | "settled" | "missing-info";

export default function ConfirmPaymentModal({ onClose, ...props }: Props) {
  const [currentStep, setStep] = useState<PaymentStep>("check-bid");
  const [paymentOutcome, setOutcome] = useState<
    PaymentIntentResult | undefined
  >();

  return (
    <Dialog
      width={360}
      onClosed={onClose}
      render={({ startClosing }) => (
        <>
          {currentStep === "check-bid" ? (
            <CheckPurchase
              auction={props.auction}
              purchaseMethod={"buy_now"}
              onSettled={(errors) => {
                const isMissingInfo =
                  errors?.reduce(
                    (isMissingInfo, el) =>
                      isMissingInfo ||
                      [
                        AUCTION_ERROR_TYPE.address,
                        AUCTION_ERROR_TYPE.card,
                      ].includes(el.type),
                    false,
                  ) || false;

                setStep(isMissingInfo ? "missing-info" : "summary");
              }}
              onClose={startClosing}
            />
          ) : currentStep === "settled" ? (
            <StepPaymentSettled onClose={onClose} outcome={paymentOutcome} />
          ) : currentStep === "missing-info" ? (
            <StepAddNewCard onBack={() => setStep("summary")} />
          ) : (
            <StepPaymentMethod
              {...props}
              onAddNewCard={() => setStep("missing-info")}
              onPaymentSettled={(outcome) => {
                setOutcome(outcome);
                setStep("settled");
              }}
              onClose={startClosing}
            />
          )}
        </>
      )}
    />
  );
}
