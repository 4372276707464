import { ReactNode } from "react";

type Props = {
  liters: number;
  rightElement?: ReactNode;
};

export const MobileCellContentLiters = ({ liters, rightElement }: Props) => {
  return (
    <div
      style={{
        fontSize: "14px",
        fontWeight: 500,
        whiteSpace: "nowrap",
        textAlign: "left",
      }}
    >
      {liters} L {rightElement}
    </div>
  );
};
