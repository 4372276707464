import styles from "./img-barrel-border.module.css";
import barrelBorderSrc from "src/media/barrel/bordered-barrel.svg";

type Props = {
  height: number;
};

export const ImgBarrelBorder = ({ height }: Props) => {
  return (
    <img
      className={styles.root}
      src={barrelBorderSrc}
      height={height}
      alt="barrel right"
    />
  );
};
