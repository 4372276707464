import { useCardCtx } from "../../card-context";
import { isAuctionOfLot } from "src/app/models/auctions";
import { Tags } from "../../atoms/tags";
import { IconButtons } from "../../atoms/icon-buttons";
import { CardProps } from ".";
import { Root } from "../atoms/root";
import { Title } from "../atoms/title";
import { Line } from "../atoms/line";
import { LotCarousel } from "../atoms/lot-carousel";
import { LotProductList } from "../atoms/lot-product-list";
import { ProductName } from "../atoms/product-name";
import { Details } from "../atoms/details";
import { Emp } from "../atoms/emp";
import { StoreControls } from "../atoms/store-controls";
import { ExclOfTaxes } from "../atoms/excl-taxes";

export function CardForStoreLot({
  className = "",
  cardId,
}: Omit<CardProps, "auction">) {
  const cardCtx = useCardCtx();

  if (!isAuctionOfLot(cardCtx.auction)) return null;
  if (!cardCtx.auction.lot) return null;

  return (
    <Root className={className} cardId={cardId}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "4px",
        }}
      >
        <Tags />
        <IconButtons />
      </div>

      <Title />
      <Line />

      <div style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
          }}
        >
          <LotCarousel />
          <LotProductList />
          <ProductName />
        </div>
        <Details />
      </div>

      <Emp />

      <StoreControls />
      <ExclOfTaxes />
    </Root>
  );
}
