import styles from "./index.module.css";
import { CSSProperties, Fragment, useRef, useState } from "react";
import { Listbox } from "@headlessui/react";
import { SelectMultipleOption, SelectMultipleValues } from "./types";
import { SingleValueBadge } from "./single-value-badge";
import { Checkbox } from "../checkbox";
import { cn } from "src/app/utils/cn";
import { Scrollable } from "../../scrollable";
import { toPx } from "src/app/utils/toPx";
import { SelectMultipleSearch, searchContainerHeight } from "./search";
import { toSearchString } from "src/app/utils/toSearchString";
import { find } from "lodash";
import { DropdownRoot } from "../_atoms/dropdown/root";
import { DropdownContainer } from "../_atoms/dropdown/container";
import { DropdownOption } from "../_atoms/dropdown/option";
import { DropdownOptionGroup } from "../_atoms/dropdown/option-group";
import { IconClear } from "../_atoms/icon/clear";
import { IconToggleDropdown } from "../_atoms/icon/toggle-dropdown";
import { SpinnerSm } from "../../spinner-sm";

type Props = {
  style?: CSSProperties;
  className?: string;
  placeholder: string;
  maxDropdownHeight?: number;
  values: SelectMultipleValues;
  onChange: (values: SelectMultipleValues) => void;
  options: Record<string, SelectMultipleOption[]>;
  isLoading?: boolean;
  withPortal?: boolean | string;
};

export const SelectMultiple = ({
  style = {},
  className = "",
  placeholder,
  values,
  onChange,
  maxDropdownHeight = 300,
  options,
  isLoading,
  withPortal = false,
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [search, setSearch] = useState("");
  const showPlaceholder = !Object.values(values).flat().length;
  const filteredOptions = filterOptions(options, search);
  const hasNoOption = !Object.values(filteredOptions).flat().length;

  function handleChange(selectionOptions: SelectMultipleOption[]) {
    const prevValues = { ...values };
    const newValues = selectionOptions.reduce((acc, option) => {
      acc[option.serializedGroupName] = [
        ...(acc[option.serializedGroupName] || []),
        option.value,
      ];
      return acc;
    }, {} as SelectMultipleValues);
    // explicitely remove values by explicitely setting them to undefined
    Object.keys(prevValues).forEach((key) => {
      newValues[key] = newValues[key] || undefined;
    });
    setSearch("");
    onChange(newValues);
  }

  function handleClickBadge(
    serializedGroupName: string,
    value: SelectMultipleOption["value"],
  ) {
    const newValues = {
      ...values,
      [serializedGroupName]: removeEmptyArray(
        (values[serializedGroupName] || []).filter((val) => val !== value),
      ),
    };
    onChange(newValues);
  }

  return (
    <Listbox
      value={Object.values(options)
        .flat()
        .filter((option) =>
          values[option.serializedGroupName]?.includes(option.value),
        )}
      onChange={handleChange}
      multiple
    >
      <Listbox.Button
        ref={buttonRef}
        style={style}
        className={cn(styles.button, className)}
      >
        {showPlaceholder ? (
          <div className={styles.placeholder}>{placeholder}</div>
        ) : (
          <div className={styles.values}>
            {Object.entries(values).flatMap(
              ([serializedGroupName, values = []]) =>
                values.map((value) => (
                  <SingleValueBadge
                    key={`sm-val-${serializedGroupName}-${value.toString()}`}
                    onRemove={() =>
                      handleClickBadge(serializedGroupName, value)
                    }
                    value={value}
                    label={
                      find(filteredOptions[serializedGroupName], {
                        value,
                      })?.label
                    }
                  />
                )),
            )}
          </div>
        )}

        {!showPlaceholder && (
          <IconClear
            onClick={(e) => {
              e.stopPropagation();
              handleChange([]);
            }}
          />
        )}

        <IconToggleDropdown />
      </Listbox.Button>

      <DropdownRoot
        anchorRef={buttonRef}
        placement="bottom"
        withPortal={withPortal}
      >
        <Listbox.Options as={DropdownContainer}>
          <SelectMultipleSearch onChange={setSearch} search={search} />

          <Scrollable
            overscrollBehavior="contain"
            style={{
              maxHeight: toPx(maxDropdownHeight - searchContainerHeight),
            }}
          >
            {!!isLoading && (
              <div className={styles.loading}>
                <SpinnerSm size={18} style={{ color: "#800c32" }} />
              </div>
            )}

            {!!hasNoOption && !isLoading && (
              <div className={styles.noOption}>Nothing found</div>
            )}

            {Object.entries(filteredOptions).map(
              ([serializedGroupName, groupOptions]) => (
                <div key={`sm-${serializedGroupName}`}>
                  {!!groupOptions[0].groupName && (
                    <div className={styles.groupName}>
                      {groupOptions[0].groupName}
                    </div>
                  )}

                  <DropdownOptionGroup>
                    {groupOptions.map((option) => (
                      <Listbox.Option
                        key={`sm-${serializedGroupName}-${option.label}`}
                        value={option}
                        as={Fragment}
                      >
                        {({ active, disabled }) => (
                          <DropdownOption
                            title={option.label.toString()}
                            active={active}
                            disabled={disabled}
                          >
                            <div className={styles.option}>
                              <Checkbox
                                checked={
                                  !!values[serializedGroupName]?.includes(
                                    option.value,
                                  )
                                }
                                disabled={disabled}
                              />
                              <div className={styles.label}>{option.label}</div>
                              <div className={styles.count}>{option.count}</div>
                            </div>
                          </DropdownOption>
                        )}
                      </Listbox.Option>
                    ))}
                  </DropdownOptionGroup>
                </div>
              ),
            )}
          </Scrollable>
        </Listbox.Options>
      </DropdownRoot>
    </Listbox>
  );
};

function filterOptions(
  options: Record<string, SelectMultipleOption[]>,
  search: string,
) {
  const result: Record<string, SelectMultipleOption[]> = {};
  for (const [serializedGroupName, groupOptions] of Object.entries(options)) {
    const filteredOptions = groupOptions.filter((option) =>
      toSearchString(option.label.toString()).includes(toSearchString(search)),
    );
    if (!filteredOptions.length) continue;
    result[serializedGroupName] = filteredOptions;
  }
  return result;
}

function removeEmptyArray<T extends any[]>(array: T): T | undefined {
  return array.length ? array : undefined;
}
