import React from "react";
import styles from "./Emp.module.css";
import { Tooltip } from "../tooltip";

export const Emp = ({ emp, quantity = 1, greyedOut = false }) => {
  return (
    <Tooltip
      position="bottom"
      content="Estimated Market Price (EMP) it's calculated by Crurated from a range of different sources and considers the condition and rarity of the bottles"
    >
      <div className={`${styles.root} ${greyedOut ? styles.greyedOut : ""}`}>
        <p>Crurated Estimated Market Price</p>
        <p>&euro; {emp * quantity}</p>
      </div>
    </Tooltip>
  );
};
