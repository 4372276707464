import { useEffect, useMemo, useState } from "react";
import { addTimezoneOffset } from "../utils/addTimezoneOffset";
import { Auction } from "../models/auctions";
import { isValidDate } from "../utils/isValidDate";

export const useIsAuctionFinished = (auction: Auction) => {
  const finishDate = useMemo(() => {
    const date = auction.finish_data;
    return isValidDate(date) ? new Date(date) : null;
  }, [auction.finish_data]);

  const [isFinished, setIsFinished] = useState(() => {
    if (!finishDate) return false;
    const remainingMs =
      new Date(addTimezoneOffset(finishDate)).getTime() - Date.now();
    return remainingMs <= 0;
  });

  useEffect(() => {
    if (isFinished || !finishDate) return;
    const interval = setInterval(() => {
      const distanceMs =
        new Date(addTimezoneOffset(finishDate)).getTime() - Date.now();

      setIsFinished(distanceMs <= 0);
    }, 1000);
    return () => clearInterval(interval);
  }, [isFinished, finishDate]);

  return isFinished;
};
