import { createFilterFn } from "./utils";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { useCallback, useState } from "react";
import { useDebounce } from "react-use";

type Config<TData extends object> = {
  inferDataType: TData; // forces explicit type declaration
  getSuggestionText: (dataItem: TData) => string | string[];
  debounceMs?: number;
  initialValue?: string;
};

const schema = z.object({
  search: z.string().optional(),
});

export function useDeboucedInputAutocomplete<TData extends object>({
  getSuggestionText,
  debounceMs = 350,
}: Config<TData>) {
  const [searchParams, setSearchParams] = useTypedSearchParams({
    schema,
  });
  const [valueAutocomplete, setValueAutocomplete] = useState<string>(
    searchParams.search || "",
  );

  const setParamsValue = useCallback(
    (updater: string | ((prev: string) => string)) => {
      setSearchParams((prev) => {
        const newValue =
          typeof updater === "function" ? updater(prev.search ?? "") : updater;
        return { search: newValue || undefined };
      });
    },
    [setSearchParams],
  );

  useDebounce(
    () => {
      // update query params only if search string is empty (removed) or longer than 4 chars
      if (!valueAutocomplete || valueAutocomplete.length >= 4) {
        setParamsValue(valueAutocomplete);
      }
    },
    debounceMs,
    [valueAutocomplete, setParamsValue],
  );

  return {
    value: valueAutocomplete,
    setValue: setValueAutocomplete,
    filterFn: createFilterFn({
      value: valueAutocomplete,
      getSuggestionText,
      passIfNoValue: true,
    }),
    getSuggestionText,
  };
}
