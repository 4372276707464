import styles from "./AuctionLotLiveFeed.module.css";
import Header from "./Header";
import ListAuctionContainer from "./ListAuctionContainer";
import { useAuctionsQueryForCharityPublicPages } from "./queries";
import { usePermanentScroll } from "./usePermanentScroll";
import { SpacerBottom } from "./SpacerBottom";
import { SpacerTop } from "./SpacerTop";
import { isEqual } from "lodash";

export const AUCTION_LOT_LIVE_FEED_PATH = "/auction-lot-live-feed";

export const AuctionLotLiveFeed = () => {
  const { data } = useAuctionsQueryForCharityPublicPages(
    {
      forPage: "auction-lot-live-feed",
    },
    {
      onSuccess: (newData) => {
        if (isEqual(data, newData)) return;
        containerRef.current?.scrollTo({ top: 0 });
      },
    },
  );

  const filteredData = data?.filter(
    (res) => res.lastBidDate !== res.createdDate,
  );

  const { containerRef, toggleScroll } = usePermanentScroll();

  const totalRaised = data?.reduce((total, res) => {
    if (res.lastBidDate !== res.createdDate) {
      return total + res.currentPrice;
    } else {
      return total;
    }
  }, 0);

  return (
    <div className={styles.root}>
      <Header totalRaised={totalRaised} />

      <div>
        <div className={styles.highestBidsContainer}>
          <h4 className={styles.highestBids}>
            Auction Lot <strong>Live Feed</strong>
          </h4>
        </div>
        <div
          className={styles.listAuctions}
          ref={containerRef}
          onClick={toggleScroll}
          style={{ maxHeight: "68vh", overflowY: "auto" }}
        >
          <div className={styles.listAuctionsContainer}>
            <SpacerTop />
            {filteredData?.map((res, index) => (
              <ListAuctionContainer auction={res} index={index} key={index} />
            ))}

            <SpacerBottom />
          </div>
        </div>
      </div>
    </div>
  );
};
