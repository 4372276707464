import { definePortal } from "crustack/portal";

const defaultRoots = [
  "dialog[open]",
  "[role=dialog]",
  "[data-headlessui-portal] [data-headlessui-state=open][data-open]",
  "#root",
];

export const getDefaultPortalRoots = () =>
  defaultRoots
    .map((root) => document.querySelector(root))
    .concat(document.body)
    .filter(Boolean) as HTMLElement[];

export const { Portal } = definePortal({
  defaultRoots,
  spa: true,
});
