import { useState, useEffect, useRef, MutableRefObject } from "react";

/**
 * Enables click a drag to scroll (X axis) an HTML element
 */
export function useClickAndDragScrollX(
  elRef: MutableRefObject<HTMLElement | null>,
) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const clientXStartRef = useRef(0);
  const scrollLeftStartRef = useRef(0);
  const originalScrollSnapType = useRef("none");

  // handle mouse down
  useEffect(() => {
    const el = elRef.current;
    if (!el) return;
    const listener = (e: MouseEvent) => {
      const target: HTMLElement | null = e.target as any;
      if (!target || target.closest("a") || target.closest("button")) return;
      setIsMouseDown(true);
      scrollLeftStartRef.current = el.scrollLeft;
      clientXStartRef.current = e.clientX;
      originalScrollSnapType.current =
        getComputedStyle(el).getPropertyValue("scroll-snap-type") || "none";
      el.style.scrollSnapType = "none";
    };
    el.addEventListener("mousedown", listener);
    return () => el.removeEventListener("mousedown", listener);
  }, [elRef]);

  // handle mouse up
  useEffect(() => {
    const el = elRef.current;
    if (!el) return;
    const listener = () => {
      setIsMouseDown(false);
      el.style.scrollSnapType = originalScrollSnapType.current;
    };
    document.addEventListener("mouseup", listener);
    return () => document.removeEventListener("mouseup", listener);
  }, [elRef]);

  // handle mouse move
  useEffect(() => {
    const el = elRef.current;
    if (!isMouseDown || !el) return;
    const listener = (e: MouseEvent) => {
      el.scrollTo({
        left: scrollLeftStartRef.current + clientXStartRef.current - e.clientX,
      });
    };
    document.addEventListener("mousemove", listener);
    return () => document.removeEventListener("mousemove", listener);
  }, [isMouseDown, elRef]);
}
