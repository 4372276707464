import { useDebounceValue } from "src/app/hooks/useDebounceValue";
import { createFilterFn } from "./utils";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { useCallback } from "react";

type Config<TData extends object> = {
  inferDataType: TData; // forces explicit type declaration
  getSuggestionText: (dataItem: TData) => string | string[];
  debounceMs?: number;
  initialValue?: string;
};

const schema = z.object({
  search: z.string().optional(),
});

export function useInputAutocomplete<TData extends object>({
  getSuggestionText,
  debounceMs = 350,
}: Config<TData>) {
  const [searchParams, setSearchParams] = useTypedSearchParams({
    schema,
  });
  const debouncedValue = useDebounceValue(
    searchParams.search ?? "",
    debounceMs,
  );

  return {
    value: searchParams.search,
    setValue: useCallback(
      (updater: string | ((prev: string) => string)) => {
        setSearchParams((prev) => {
          const newValue =
            typeof updater === "function"
              ? updater(prev.search ?? "")
              : updater;
          return { search: newValue || undefined };
        });
      },
      [setSearchParams],
    ),
    filterFn: createFilterFn({
      value: debouncedValue,
      getSuggestionText,
      passIfNoValue: true,
    }),
    getSuggestionText,
  };
}
