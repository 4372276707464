import styles from "./total-market-value.module.css";
import { useMyEarningsQuery } from "src/app/api/queries_to_rename_later";
import { STORAGE_KEY } from "src/app/const";
import { useLocalStorage } from "src/app/hooks/useLocalStorage";
import { formatCurrency } from "src/app/utils/formatCurrency";

export function TotalMarketValue() {
  return (
    <h2 className={styles.root}>
      Your total cellar holdings have an estimated market value of&nbsp;
      <Value />
    </h2>
  );
}

function Value() {
  const myEarnings = useMyEarningsQuery();
  const [showMyEarnings] = useLocalStorage(STORAGE_KEY.SHOW_MY_EARNINGS, true);
  const value =
    myEarnings.data?.total_crurated_estimated_market_price.number_text;

  if (!showMyEarnings) {
    return <span className={styles.hiddenValue}>&nbsp;******</span>;
  }

  if (!value) return <strong>...</strong>;

  return <strong>{formatCurrency(value)}</strong>;
}
