import { ReactNode } from "react";
import { formatCurrency } from "src/app/utils/formatCurrency";

type Props = {
  amount: number;
  rightElement?: ReactNode;
  align?: "left" | "center" | "right";
};

export const DesktopCellContentCurrency = ({
  amount,
  rightElement,
  align = "right",
}: Props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "center"
              ? "center"
              : "flex-start",
        fontSize: "14px",
        whiteSpace: "nowrap",
      }}
    >
      {amount > 0 ? formatCurrency(amount) : formatCurrency(0)}

      {rightElement}
    </div>
  );
};
