import { noop } from "lodash";
import { ChangeEvent } from "react";
import { useCouponMutation } from "src/app/api/subscription";
import { Button } from "src/app/components/button";
import { Input } from "src/app/components/form-elements/input";
import { IdTier } from "src/app/const";
import { CouponV1 } from "src/app/models/Coupon";

export const emptyCouponValue: InputCouponValue = {
  input: "",
  data: null,
  error: "",
  freeMonths: 0,
};

export type InputCouponValue = {
  input: string;
  data: CouponV1 | null;
  error: string;
  freeMonths: number;
};

type Props = {
  name: string;
  value: InputCouponValue;
  onChange: (coupon: InputCouponValue) => void;
  idTier: IdTier;
  id?: string;
  className?: string;
  onBlur?: () => void;
};

export const InputCoupon = ({
  value,
  name,
  id,
  idTier,
  className,
  onChange = noop,
  onBlur,
}: Props) => {
  const { mutate, isLoading } = useCouponMutation({
    onError: () =>
      onChange({
        ...value,
        error: "Invalid Coupon",
        data: null,
        freeMonths: 0,
      }),
    onSuccess: (data) => {
      if (!value.input) return onChange(emptyCouponValue);
      if (!data) {
        return onChange({
          ...value,
          error: "Invalid Coupon.",
          data: null,
          freeMonths: 0,
        });
      }
      const isExpired =
        !data.is_infinity &&
        data.expiration_date &&
        new Date() > new Date(data.expiration_date);

      const isCouponValid = !isExpired && data.active && !data.deleted;

      const canBeUsedForMyTier = data.roles.some(
        (role) => role.id_customer_role === idTier,
      );

      if (!isCouponValid || !canBeUsedForMyTier) {
        return onChange({
          ...value,
          error:
            "Coupon has expired, is inactive, or is not valid for the selected plan.",
          data: null,
          freeMonths: 0,
        });
      }

      return onChange({
        ...value,
        error: "",
        data,
        freeMonths: getNumberOfFreeMonths(data, idTier),
      });
    },
  });

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange({
      ...value,
      input: event.target.value,
    });
  }

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", gap: 12 }}
        className={className}
      >
        <Input
          value={value.input.toUpperCase()}
          onChange={handleChange}
          name={name}
          id={id}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault(); // prevent form submit
              mutate(value.input);
            }
          }}
          onBlur={onBlur}
        />
        <Button
          variant="outlined"
          type="button"
          label="Apply"
          onClick={() => {
            mutate(value.input);
          }}
          isLoading={isLoading}
        />
      </div>
      {!!value.error && (
        <div style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>
          {value.error}
        </div>
      )}
    </>
  );
};

function getNumberOfFreeMonths(
  couponData: InputCouponValue["data"] | undefined | null,
  subscriptionIdTier: IdTier | undefined | null,
): number {
  if (!couponData || !subscriptionIdTier) return 0;
  return (
    couponData.roles.find(
      (role) => role.id_customer_role === subscriptionIdTier,
    )?.months || 0
  );
}
