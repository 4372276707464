import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { FormDeleteShipment } from "./forms/form-delete-shipment";
import { HiTruck } from "react-icons/hi";
import { useShipmentsQuery } from "src/app/api/shipments";
import { path } from "src/app/routes/path";
import { ShipmentsTableSubrow } from "./table/subrow";
import { setDataTimeZone } from "src/app/config/Utility";
import { DesktopCellContentTotalPrice } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-total-price";
import { MobileCellContentTotalPrice } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-total-price";
import { MyOfferStatus } from "src/app/modules/Includes/CustomerSide/components/my-offers/MyOffersStatus";
import { DesktopCellContentActions } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-actions";
import { MobileCellContentActions } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-actions";
import { ShipmentV1 } from "src/app/models/ShipmentV1";
import { Dialog } from "src/app/components/dialog";
import { Drawer } from "src/app/components/drawer";
import { FormDetailsAndPayShipment } from "./forms/form-details-and-pay-shipment";
import { CellContentShipmentDetails } from "./cell-content-shipment-details";
import { Button } from "src/app/components/button";
import { useTableSelection } from "src/app/components/table-customer-side/useTableSelection";
import { A } from "src/app/components/a";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { PAGE_TITLE, SHIPPING_STATUS } from "src/app/const";
import { useSlot } from "src/app/hooks/useSlot";
import { CombineShipmentForm } from "src/app/components/shipment-form/forms/combine-shipment-form";
import { EditShipmentForm } from "src/app/components/shipment-form/forms/edit-shipment-form";

const getRowId = (row: ShipmentV1) => row.id_shipping;

export const ShipmentsPage = () => {
  usePageTitle(PAGE_TITLE.MY_SHIPMENTS());
  const shipmentsQuery = useShipmentsQuery();
  const selection = useTableSelection({ getRowId, data: shipmentsQuery.data });
  const slot = useSlot();

  const getActions = (shipment: ShipmentV1) => [
    {
      label: "Show Details",
      onClick: () =>
        slot.show((dismiss) => (
          <Drawer
            title={`Shipment order: ${shipment.id_shipping}`}
            position="right"
            width={570}
            onClosed={dismiss}
            render={({ close }) => (
              <FormDetailsAndPayShipment
                shipment={shipment}
                onClose={close}
                onSettled={() => shipmentsQuery.refetch()}
              />
            )}
          />
        )),
    },
    [
      SHIPPING_STATUS.SHIPMENT_REQUEST,
      SHIPPING_STATUS.PENDING_PAYMENT,
    ].includes(shipment.status_shipping) && {
      label: "Delete",
      onClick: () => {
        slot.show((dismiss) => (
          <Dialog
            title="Delete Shipment"
            width={528}
            onClosed={dismiss}
            render={({ close }) => (
              <FormDeleteShipment
                onClose={close}
                shipment={shipment}
                onSettled={() => shipmentsQuery.refetch()}
              />
            )}
          />
        ));
      },
    },
    [SHIPPING_STATUS.SHIPMENT_REQUEST].includes(shipment.status_shipping) && {
      label: "Edit",
      onClick: () =>
        slot.show((dismiss) => (
          <Drawer
            title={`Edit Shipment ${shipment.id_shipping}`}
            position="right"
            width={570}
            onClosed={dismiss}
            render={({ close }) => (
              <EditShipmentForm shipping={shipment} closeDrawer={close} />
            )}
          />
        )),
    },
    [SHIPPING_STATUS.PENDING_PAYMENT].includes(shipment.status_shipping) && {
      label: "Pay",
      onClick: () =>
        slot.show((dismiss) => (
          <Drawer
            title={`Shipment order: ${shipment.id_shipping}`}
            position="right"
            width={570}
            onClosed={dismiss}
            render={({ close }) => (
              <FormDetailsAndPayShipment
                shipment={shipment}
                onClose={close}
                onSettled={() => shipmentsQuery.refetch()}
              />
            )}
          />
        )),
    },
  ];

  return (
    <>
      {slot.output}

      <div style={{ margin: "18px 0", display: "block" }}>
        <Button
          style={{ marginLeft: "auto" }}
          onClick={() =>
            slot.show((dismiss) => (
              <Drawer
                title="Combine Shipments"
                position="right"
                width={570}
                onClosed={dismiss}
                render={({ close }) => (
                  <CombineShipmentForm
                    shippingsSelected={selection.data}
                    closeDrawer={close}
                  />
                )}
              />
            ))
          }
          disabled={
            shipmentsQuery.isLoading ||
            shipmentsQuery.isFetching ||
            selection.rowIds.length < 2
          }
          label="Combine Shipments"
          icon={<HiTruck />}
        />
      </div>

      <div>
        <TableCustomerSide
          urlPrefix=""
          enableSelectionForRow={({ status_shipping }) => +status_shipping < 2}
          enableExpansionForRow={(shipment) => !!shipment.lots?.length}
          data={shipmentsQuery.data}
          isLoading={shipmentsQuery.isLoading}
          isFetching={shipmentsQuery.isFetching}
          selection={selection.rowIds}
          onChangeSelection={selection.set}
          getRowId={getRowId}
          minCellHeightDesktop="74px"
          noDataTitle="No Shipments Data"
          noDataContent={
            <div>
              <p>View live auctions to make a new bid.</p>
              <Button as={A} label="Go to Auctions" to={path.weekly.value()} />
            </div>
          }
          renderSubRow={ShipmentsTableSubrow}
          columns={[
            {
              header: "Shipment",
              id: "description",
              alignHeaderDesktop: "left",
              getSortingValue: (shipment) => +shipment.id_order,
              cellDesktop: (shipment) => (
                <CellContentShipmentDetails
                  title={`Shipment order: ${shipment.id_order}`}
                  note={`Requested on: ${setDataTimeZone(
                    shipment.request_date_frontend,
                    "YYYY/MM/DD HH:mm:ss",
                  )}`}
                />
              ),
            },
            {
              header: "Total",
              id: "total",
              alignHeaderDesktop: "right",
              width: "12%",
              getSortingValue: (shipment) => shipment.total_price,
              cellDesktop: ({
                total_price,
                shipping_price,
                extra_fee,
                vat_shipping_price,
                vat_wines_price,
                vat_extra_fee,
                other_taxes,
              }) => (
                <DesktopCellContentTotalPrice
                  total={total_price}
                  details={[
                    { amount: shipping_price, label: "Shipping" },
                    { amount: extra_fee, label: "Extra Fee" },
                    { amount: vat_shipping_price, label: "Vat Shipping" },
                    { amount: vat_wines_price, label: "Vat Wines" },
                    { amount: vat_extra_fee, label: "Vat Extra Fee" },
                    { amount: other_taxes, label: "Other Taxes" },
                  ]}
                />
              ),
              cellMobile: ({
                total_price,
                shipping_price,
                extra_fee,
                vat_shipping_price,
                vat_wines_price,
                vat_extra_fee,
                other_taxes,
              }) => (
                <MobileCellContentTotalPrice
                  total={total_price}
                  details={[
                    { amount: shipping_price, label: "Shipping" },
                    { amount: extra_fee, label: "Extra Fee" },
                    { amount: vat_shipping_price, label: "Vat Shipping" },
                    { amount: vat_wines_price, label: "Vat Wines" },
                    { amount: vat_extra_fee, label: "Vat Extra Fee" },
                    { amount: other_taxes, label: "Other Taxes" },
                  ]}
                />
              ),
            },
            {
              header: "Status",
              id: "status",
              alignHeaderDesktop: "center",
              getSortingValue: (shipment) => shipment.status_shipping,
              cellDesktop: (shipment) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <MyOfferStatus
                    status={shipment.status_shipping}
                    type={"SHIPMENTS"}
                    notes={false}
                  />
                </div>
              ),
              cellMobile: (shipment) => (
                <MyOfferStatus
                  status={shipment.status_shipping}
                  type={"SHIPMENTS"}
                  notes={false}
                />
              ),
            },
            {
              header: "Actions",
              id: "actions",
              alignHeaderDesktop: "center",
              cellDesktop: (shipment) => (
                <DesktopCellContentActions
                  disabled={shipmentsQuery.isFetching}
                  actions={getActions(shipment)}
                />
              ),
              cellMobile: (shipment) => (
                <MobileCellContentActions
                  disabled={shipmentsQuery.isFetching}
                  actions={getActions(shipment)}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};
