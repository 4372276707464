import { cn } from "src/app/utils/cn";
import styles from "./styles.module.css";
import { FiSearch } from "react-icons/fi";

type Props = React.ComponentPropsWithoutRef<"div">;

export const IconSearch = ({ className = "", ...props }: Props) => {
  return (
    <div className={cn(styles.searchIcon, className)} {...props}>
      <FiSearch size={18} />
    </div>
  );
};
