import styles from "./index.module.css";
import { Spacer } from "src/app/components/spacer";
import { Button } from "src/app/components/button";
import { FormContextType } from "src/app/components/cru-form-3/types";
import { GiftFormValues } from ".";
import { CruForm } from "src/app/components/cru-form-3";
import { Input } from "src/app/components/form-elements/input";
import { TextArea } from "../../form-elements/textarea";
import { useCountryOptions } from "../../address-form/use-country-options";
import { Select } from "../../form-elements/select";
import { CheckboxWithLabel } from "src/app/components/form-elements/checkbox/checkbox-with-label";
import { z } from "zod";
import { useCountriesQuery } from "src/app/api/queries_to_rename_later";
import moment from "moment";

export function RecipientInfo({
  ctx,
  onGoBack,
}: {
  ctx: FormContextType<GiftFormValues>;
  onGoBack: () => void;
}) {
  const countryOptions = useCountryOptions({
    addressType: "invoice",
    pickOnlyEA: false,
  });
  const { data: dataCountries = [], isLoading } = useCountriesQuery();

  return (
    <section>
      <div className={styles.sectionBody}>
        <h3>Who do you want to send it to?</h3>
        <Spacer size={24} />
        <div className={styles.contactsGrid}>
          <div style={{ display: "flex", gap: 10 }}>
            <CruForm.Field
              ctx={ctx}
              name="recipient_first_name"
              validate={(value) => !value && "Recipient First Name is required"}
            >
              {(props) => (
                <div className={styles.inputGroup}>
                  <CruForm.Label className={styles.formLabel} required>
                    First Name
                  </CruForm.Label>

                  <Input
                    {...props}
                    type="text"
                    placeholder="Enter First Name"
                    value={ctx.getFieldValue(props.name)}
                    onChange={(e) => {
                      ctx.setFieldsValues({ [props.name]: e.target.value });
                    }}
                    onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                  />

                  <CruForm.ErrorMessage />
                </div>
              )}
            </CruForm.Field>
            <CruForm.Field
              ctx={ctx}
              name="recipient_last_name"
              validate={(value) => !value && "Recipient Last Name is required"}
            >
              {(props) => (
                <div className={styles.inputGroup}>
                  <CruForm.Label className={styles.formLabel} required>
                    Last Name
                  </CruForm.Label>

                  <Input
                    {...props}
                    type="text"
                    placeholder="Enter Last Name"
                    value={ctx.getFieldValue(props.name)}
                    onChange={(e) => {
                      ctx.setFieldsValues({ [props.name]: e.target.value });
                    }}
                    onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                  />

                  <CruForm.ErrorMessage />
                </div>
              )}
            </CruForm.Field>
          </div>

          <CruForm.Field
            ctx={ctx}
            name="recipient_email"
            validate={(value) => {
              const validation = z
                .string()
                .min(1, "Recipient Email is required")
                .email("Invalid email address")
                .safeParse(value);

              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          >
            {(props) => (
              <div className={styles.inputGroup}>
                <CruForm.Label className={styles.formLabel} required>
                  Email
                </CruForm.Label>

                <Input
                  {...props}
                  type="text"
                  placeholder="Enter Email"
                  value={ctx.getFieldValue(props.name)}
                  onChange={(e) => {
                    ctx.setFieldsValues({ [props.name]: e.target.value });
                  }}
                  onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                />

                <CruForm.ErrorMessage />
              </div>
            )}
          </CruForm.Field>

          <CruForm.Field ctx={ctx} name="message">
            {(props) => (
              <div className={styles.inputGroup}>
                <CruForm.Label className={styles.formLabel}>
                  Message
                </CruForm.Label>

                <TextArea
                  {...props}
                  rows={4}
                  placeholder="Enter message"
                  value={ctx.getFieldValue(props.name)}
                  onChange={(e) => {
                    ctx.setFieldsValues({ [props.name]: e.target.value });
                  }}
                  onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                />

                <CruForm.ErrorMessage />
              </div>
            )}
          </CruForm.Field>

          <CruForm.Field
            ctx={ctx}
            name="date_to_send_gift"
            validate={(value) => {
              if (!value) return "This field is required";
              if (new Date(value).getTime() < new Date().getTime()) {
                return "This date is in the past";
              }
            }}
          >
            {(props) => (
              <div className={styles.inputGroup}>
                <CruForm.Label className={styles.formLabel} required>
                  Send the gift on
                </CruForm.Label>

                <Input
                  {...props}
                  type="date"
                  value={ctx.getFieldValue(props.name)}
                  onChange={(e) => {
                    ctx.setFieldsValues({ [props.name]: e.target.value });
                  }}
                  onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                />

                <CruForm.ErrorMessage />
              </div>
            )}
          </CruForm.Field>

          <CruForm.Field
            ctx={ctx}
            name="country"
            validate={(option) => !option.value && "Country is required"}
          >
            {(props) => (
              <div className={styles.inputGroup}>
                <CruForm.Label className={styles.formLabel} required>
                  Country of recipient
                </CruForm.Label>

                {isLoading || !countryOptions.length ? (
                  <Input {...props} value={"Loading countries..."} disabled />
                ) : (
                  <Select
                    {...props}
                    selectedOption={ctx.getFieldValue(props.name)}
                    onChange={(option) => {
                      const countrySelected = dataCountries.find(
                        (item) => Number(item.id_country) === Number(option.id),
                      );
                      ctx.setFieldsValues({ [props.name]: option });
                      ctx.setFieldsTouched({ [props.name]: true });
                      if (countrySelected) {
                        const timezone = moment.tz.zonesForCountry(
                          countrySelected.countryISO.toString(),
                          true,
                        );
                        ctx.setFieldsValues({
                          recipient_time_zone: String(timezone[0].offset),
                        });
                      }
                    }}
                    options={countryOptions}
                    className={styles.countrySelect}
                  />
                )}

                <CruForm.ErrorMessage />
              </div>
            )}
          </CruForm.Field>

          <CruForm.Field
            ctx={ctx}
            name="recipient_confirm_adult"
            validate={(value) =>
              !value && "The recipient must be above 18 years old"
            }
          >
            {(props) => (
              <div className={styles.inputGroup} style={{ marginTop: 10 }}>
                <CheckboxWithLabel
                  {...props}
                  label="I confirm that the receiver is above 18 years old"
                  required
                  onChange={(event) => {
                    ctx.setFieldsValues({
                      [props.name]: event.target.checked ? 1 : 0,
                    });
                    ctx.setFieldsTouched({ [props.name]: true });
                  }}
                  checked={!!ctx.getFieldValue(props.name)}
                />
                <CruForm.ErrorMessage />
              </div>
            )}
          </CruForm.Field>
        </div>
      </div>
      <div className={styles.sectionFooter}>
        <Button
          variant="outlined"
          label="Back"
          style={{ minWidth: 150 }}
          type="button"
          onClick={onGoBack}
        />
        <Button variant="contained" label="Next" style={{ minWidth: 150 }} />
      </div>
    </section>
  );
}
