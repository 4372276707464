import styles from "./form-details-and-pay-shipment.module.css";
import { ReactNode, useState } from "react";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { cn } from "src/app/utils/cn";
import { AiFillCaretDown } from "react-icons/ai";
import { setDataTimeZone } from "src/app/config/Utility";
import {
  AlertPopup,
  AlertPopupMeta,
} from "src/app/modules/Includes/LayoutSide/components/common/alert-popup";
import { usePayShipmentMutation } from "src/app/api/shipments";
import { ShipmentV1 } from "src/app/models/ShipmentV1";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { FaTruck } from "react-icons/fa";
import { SHIPPING_STATUS } from "src/app/const";

type Props = {
  shipment?: ShipmentV1 | null;
  onClose: () => void;
  onSettled: () => void;
};

export const FormDetailsAndPayShipment = ({
  shipment,
  onClose,
  onSettled,
}: Props) => {
  const [alertPopupMeta, setAlertPopupMeta] = useState<AlertPopupMeta | null>(
    null,
  );

  const payShipmentMutation = usePayShipmentMutation({
    onSuccess: () => {
      onSettled();
      setAlertPopupMeta({
        title: "Payment and confirmation successful",
        subTitle: "",
        alertType: "success",
        onClose: () => {
          onClose();
        },
      });
    },
    onError: () => {
      onSettled();
      setAlertPopupMeta({
        title: "Error on Pay Shipping",
        subTitle: "Please try again",
        alertType: "error",
        onClose: () => {
          onClose();
        },
      });
    },
  });

  const handlePayClick = () => {
    setAlertPopupMeta({
      title: "Are you sure?",
      subTitle: "You won't be able to revert this!",
      alertType: "question",
      confirmButton: "Yes, confirm payment!",
      closeButton: "No, Cancel",
      onClose: () => {},
      onConfirmButton: () => {
        payShipmentMutation.mutate(shipment);
      },
    });
  };

  if (!shipment) return null;

  return (
    <>
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <ShipmentFormAddressSection
        title="Invoice Address"
        address={shipment.user_info?.addressInvoice[0]}
        email={shipment.user_info?.email}
      />
      <ShipmentFormAddressSection
        title="Shipping Address"
        address={shipment.shipping_address}
        email={shipment.user_info?.email}
      />
      <ShipmentFormPriceSection shipment={shipment} />

      <ShipmentFormCourierSection shipment={shipment} />

      {[SHIPPING_STATUS.PENDING_PAYMENT].includes(shipment.status_shipping) && (
        <button
          className={styles.payButton}
          onClick={handlePayClick}
          disabled={
            payShipmentMutation.isLoading || payShipmentMutation.isSuccess
          }
        >
          {payShipmentMutation.isLoading
            ? "Processing payment..."
            : "Confirm and Pay"}
          {!!payShipmentMutation.isLoading && <SpinnerSm />}
        </button>
      )}
    </>
  );
};

type AddressSectionProps = {
  title: string;
  address: any;
  email: string;
};

const ShipmentFormAddressSection = ({
  title,
  address,
  email,
}: AddressSectionProps) => {
  if (!address) return null;
  const data = getAddressObject(address, email);

  return (
    <Section title={title}>
      {Object.entries(data).map(
        ([key, value]) =>
          value && (
            <div className={styles.sectionRow} key={`${title}-${key}`}>
              <div className={styles.sectionRowHeading}>{key}:</div>
              <div className={styles.sectionRowData}>{value}</div>
            </div>
          ),
      )}
    </Section>
  );
};

const ShipmentFormPriceSection = ({ shipment }: { shipment: ShipmentV1 }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const data = {
    "Net shipping cost": formatCurrency(+shipment.shipping_price),
    "Extra fee": formatCurrency(+shipment.extra_fee),
    "Extra fee Description": shipment.extra_fee_description,
    "Vat Shipping": formatCurrency(+shipment.vat_shipping_price),
    "Vat Wines": formatCurrency(+shipment.vat_wines_price),
    "Vat extra fee": formatCurrency(+shipment.vat_extra_fee),
    "Other Taxes": formatCurrency(+shipment.other_taxes),
    "Other Taxes Description": shipment.other_taxes_description,
  };

  return (
    <Section title="Shipping Costs">
      <div className={styles.sectionRow}>
        <div className={styles.sectionRowHeading}>Total Price:</div>
        <div className={styles.sectionRowData}>
          {formatCurrency(+shipment.total_price)}
        </div>
      </div>
      <button
        onClick={() => setDetailsOpen((prev) => !prev)}
        className={cn(styles.priceButton, detailsOpen && styles.active)}
      >
        <AiFillCaretDown className={styles.icon} />
        {detailsOpen ? "Hide" : "Show"} Details
      </button>
      {!!detailsOpen && (
        <div className={styles.priceDetails}>
          {Object.entries(data).map(
            ([key, value]) =>
              value && (
                <div className={styles.sectionRow} key={`price-${key}`}>
                  <div className={styles.sectionRowHeading}>{key}:</div>
                  <div className={styles.sectionRowData}>{value}</div>
                </div>
              ),
          )}
        </div>
      )}
    </Section>
  );
};

const ShipmentFormCourierSection = ({ shipment }: { shipment: ShipmentV1 }) => {
  if (!shipment.shipped_on_frontend) return null;

  const data = {
    "Courier Service": shipment.courier_name,

    "Shipped on": setDataTimeZone(
      shipment.shipped_on_frontend,
      "YYYY/MM/DD HH:mm:ss",
    ),

    Tracking: shipment.tracking && shipment.link_tracking && (
      <a
        href={shipment.link_tracking.replace(
          "{track_number}",
          `${shipment.tracking}`,
        )}
        target="_blank"
        rel="noreferrer"
      >
        <FaTruck style={{ marginRight: 10, color: "#000", fontSize: 16 }} />
        {shipment.tracking}
      </a>
    ),
  };

  return (
    <Section title="Courier Details">
      {Object.entries(data).map(
        ([key, value]) =>
          value && (
            <div className={styles.sectionRow} key={`courier-${key}`}>
              <div className={styles.sectionRowHeading}>{key}:</div>
              <div className={styles.sectionRowData}>{value}</div>
            </div>
          ),
      )}
    </Section>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>{title}</div>
    {children}
  </div>
);

type getAddressObjectType = (
  address: any,
  email: string,
) => {
  "Full name": string;
  "Company name"?: string;
  Address: string;
  VAT?: string;
  Email: string;
  Phone: string;
};

const getAddressObject: getAddressObjectType = (address, email) => ({
  "Full name": address?.full_name,
  "Company name": address?.company_name,
  Address: `${address?.address?.address_line_1}, ${
    address?.address?.address_line_2
      ? `${address?.address?.address_line_2}, `
      : ""
  }${address?.address?.city}, ${address?.address?.region}, ${
    address?.address.postal_code
  }, ${address?.address.country?.name}`,
  VAT: address?.vat,
  Email: email,
  Phone: address?.phone,
});
