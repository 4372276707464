import useResizeObserver from "use-resize-observer";
import styles from "./root.module.css";
import React from "react";
import { useCardCtx } from "../../card-context";

type RootProps = {
  children: React.ReactNode;
  className?: string;
  style?: object;
  cardId: string;
};

export const Root = ({
  children,
  className = "",
  style = {},
  cardId,
}: RootProps) => {
  const { outerMostRef, inView } = useCardCtx();
  // innerRef needs an element with no padding
  const { ref: innerRef, width, height } = useResizeObserver();

  return (
    <div
      className={`${styles.root} ${className} `}
      style={style}
      role="listitem"
      ref={outerMostRef}
      id={cardId}
    >
      {!inView && !!width && !!height ? (
        <div style={{ width, height }} />
      ) : (
        <div ref={innerRef} style={{ height: "100%" }}>
          <div className={styles.inner}>{children}</div>
        </div>
      )}
    </div>
  );
};
