/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useEffect, useRef, useState } from "react";

export const usePermanentScroll = ({ speed = 30, step = 1 } = {}) => {
  const containerRef = useRef(null);
  const [scrolling, setScrolling] = useState(true);

  useEffect(() => {
    let scrollInterval;

    const scrollFunction = () => {
      if (!containerRef.current) return;
      if (
        containerRef.current.scrollHeight -
          containerRef.current.scrollTop -
          containerRef.current.clientHeight <
        1
      ) {
        containerRef.current.scrollTop = 0;
      } else {
        containerRef.current.scrollBy({ top: step, behavior: "instant" });
      }
    };

    if (scrolling) {
      scrollInterval = setInterval(scrollFunction, speed);
    }

    return () => {
      clearInterval(scrollInterval);
    };
  }, [scrolling, speed, step]);

  function toggleScroll() {
    setScrolling(!scrolling);
  }

  return { containerRef, toggleScroll };
};
