import { z } from "zod";
import { ID_AUCTION_TYPE } from "../const";

export const apiBarrelSchemaV1 = z.object({
  id_barrel_auction_participant: z.number(),
  id_user: z.number(),
  id_barrel_auction: z.number(),
  quantity: z.number(),
  single_liter: z.number(),
  single_bid: z.number(),
  bid_date: z.string().nullable(),
  bid_date_control: z.string().nullable(),
  priority: z.number(),
  is_winner: z.union([z.literal(0), z.literal(1)]),
  date_winner: z.string().nullable(),
  quantity_winner: z.number(),
  single_liter_winner: z.number(),
  single_bid_winner: z.number(),
  insurance: z.number(),
  payment_deadline: z.string().nullable(),
  notice_as_paid: z.union([z.literal(0), z.literal(1)]),
  is_request: z.union([z.literal(0), z.literal(1)]),
  status_availability: z.number(),
  returned: z.union([z.literal(0), z.literal(1)]),
  edited_date: z.string().nullable(),
  deleted: z.union([z.literal(0), z.literal(1)]),
  redeem: z.union([z.literal(0), z.literal(1)]),
  note: z.null(),
  is_enabled_auto_approval: z.union([z.literal(0), z.literal(1)]),
  description_barrel_auction: z.string(),
  short_description_barrel_auction: z.string().nullable(),
  more_information: z.string(),
  description_link: z.string().nullable(),
  catalogue: z.string().nullable(),
  crurated_estimated_market_price: z.number(),
  increment_selected: z.number(),
  initial_price: z.number(),
  is_enabled_tech_fee: z.union([z.literal(0), z.literal(1)]),
  tech_fee: z.number(),
  insert_date: z.string().nullable(),
  created_data: z.string(),
  finish_data: z.string().nullable(),
  id_barrel: z.number(),
  id_barrel_auction_type: z.nativeEnum(ID_AUCTION_TYPE),
  visible_for: z.string(),
  disabled_for: z.string(),
  processed: z.union([z.literal(0), z.literal(1)]),
  stato: z.union([z.literal(0), z.literal(1)]),
  enable_custom_label_bottle: z.union([z.literal(0), z.literal(1)]),
  custom_label_for_bottles_tiers: z.string().nullable(),
  is_sponsored: z.union([z.literal(0), z.literal(1)]),
  is_charity: z.union([z.literal(0), z.literal(1)]),
  id_experience: z.number().nullable(),
  id_barrel_custom: z.string(),
  id_producer: z.number(),
  id_builder: z.number(),
  id_barrel_mp: z.string(),
  photo: z.string(),
  product_photo_label: z.string(),
  product_photo: z.string(),
  sku: z.string(),
  name: z.string(),
  description: z.string(),
  color: z.union([
    z.literal("Red"),
    z.literal("White"),
    z.literal("RED"),
    z.literal("WHITE"),
  ]),
  initial_bottling_date: z.string().nullable(),
  final_bottling_date: z.string().nullable(),
  final_custom_label_date_end: z.string().nullable(),
  price: z.number(),
  cost_price: z.number(),
  is_cost_price_percentage: z.union([z.literal(0), z.literal(1)]),
  nft_liters: z.number(),
  barrel_capacity: z.number(),
  bottles_tiers: z.string(),
  dinner_with_producer: z.union([z.literal(0), z.literal(1)]),
  notes_dinner_with_producer: z.string(),
  visit_cellar_of_producer: z.union([z.literal(0), z.literal(1)]),
  notes_visit_cellar_of_producer: z.string(),
  lots_available_date: z.string().nullable(),
  product_id_category_wine: z.number(),
  product_name_category_wine: z.string(),
  product_excise_code: z.string(),
  product_color: z.union([
    z.literal("Red"),
    z.literal("White"),
    z.literal("RED"),
    z.literal("WHITE"),
  ]),
  product_description: z.string(),
  product_region: z.string(),
  product_appellation: z.string(),
  product_clos: z.string(),
  product_classification: z.string(),
  product_vintage: z.string(),
  product_alcohol_level: z.string(),
  product_varietal: z.string(),
  product_vintage_characteristics: z.string(),
  product_vineyard_location: z.string(),
  product_duration_of_aging: z.string(),
  product_soil_composition: z.string(),
  product_average_age_of_vines: z.string(),
  product_label: z.string(),
  product_cork: z.string(),
  product_level_of_wine: z.string(),
  product_is_special_packing: z.union([z.literal(0), z.literal(1)]),
  product_appellation_characteristic: z.string(),
  product_link_video: z.string(),
  created_date: z.string(),
  total_bid: z.number(),
  bid_winner: z.number(),
  liter_winner: z.number(),
  liters_available: z.number(),
  paid: z.union([
    z.literal("0"),
    z.literal("1"),
    z.literal(0),
    z.literal(1),
    z.literal(false),
    z.literal(true),
  ]),
  available_date: z.string().nullable(),
  cellar_type: z.string(),
  deposit: z.number(),
  auction: z.object({
    barrels: z.any(),
    custom_label_for_bottles_tiers: z.any(),
    enable_custom_label_bottle: z.any(),
    nameBarrelAuction: z.any(),
  }),
  is_gift: z.union([z.literal(0), z.literal(1)]),
  key_unique: z.number(),
});

export type ApiBarrelV1 = z.infer<typeof apiBarrelSchemaV1>;
