import { NavLink } from "react-router-dom";
import { Button } from "src/app/components/button";
import { path } from "src/app/routes/path";
import styles from "./signup-success.module.css";
import { useResendActivationEmailMutation } from "src/app/api/auth";
import { cn } from "src/app/utils/cn";
import { BsCheck } from "react-icons/bs";
import { seconds } from "src/app/utils/time";

export const SignupSuccess = (props: { email: string; idAccount: number }) => {
  const resendEmailMutation = useResendActivationEmailMutation({
    throttle: seconds(30),
  });

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <h1>Thank your for registering</h1>
        <h3>Please, verify your email</h3>
      </div>
      <h4 className={styles.subtitle}>
        To verify your email, we’ve sent you an email to&nbsp;
        <strong>{props.email}</strong>
      </h4>
      <div className={styles.buttonsContainer}>
        <Button
          as={NavLink}
          variant="contained"
          label="Go to the login page"
          to={path.login()}
          className={styles.button}
        />
        <Button
          type="button"
          variant="ghost"
          label={
            resendEmailMutation.remainingSec
              ? `Email resent successfully ${resendEmailMutation.remainingSec ? `(${resendEmailMutation.remainingSec}s)` : ""}`
              : "Resend email"
          }
          onClick={() => resendEmailMutation.mutate({ id: props.idAccount })}
          isLoading={resendEmailMutation.isLoading}
          className={cn(styles.button, styles.buttonResend)}
          icon={
            resendEmailMutation.remainingSec ? (
              <BsCheck size={24} style={{ color: "#2fa502" }} />
            ) : null
          }
          disabled={!!resendEmailMutation.remainingSec}
        />
      </div>
    </div>
  );
};
