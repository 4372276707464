import styles from "./wavy-animation.module.css";
import { BarrelInputProps } from ".";

type Props = {
  color: BarrelInputProps["color"];
  quantity: number;
  max: number;
  spaceTopPercentage: number;
  barrelHeight: number;
};
export function WavyAnimation({
  color,
  quantity,
  max,
  spaceTopPercentage,
  barrelHeight,
}: Props) {
  return (
    <div className={styles.root}>
      <div
        className={styles.container}
        style={{
          height: `${100 - spaceTopPercentage}%`,
        }}
      >
        <div
          className={styles.wine}
          style={{
            height: `${(quantity / max) * 100}%`,
            color: color.toLowerCase() === "red" ? "#a01821" : "#e9d795",
          }}
        >
          <div
            style={{ height: `${barrelHeight * 0.45}px` }}
            className={styles.wave1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 869 294"
              fill="none"
            >
              <path
                d="M0.000124093 0C127.106 0 136.362 10.8252 251.746 10.8252C367.13 10.8252 377.5 1.00659e-07 445.318 0C513.137 -1.00659e-07 568.007 10.8252 666.003 10.8252C764 10.8252 768 0 869 0C869 25.5 869 294 869 294H0.000124093C0.000124093 294 -0.000155116 46.5 0.000124093 0Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div
            style={{ height: `${barrelHeight * 0.45}px` }}
            className={styles.wave2}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 869 294"
              fill="none"
            >
              <path
                d="M0.000124093 0C127.106 0 136.362 10.8252 251.746 10.8252C367.13 10.8252 377.5 1.00659e-07 445.318 0C513.137 -1.00659e-07 568.007 10.8252 666.003 10.8252C764 10.8252 768 0 869 0C869 25.5 869 294 869 294H0.000124093C0.000124093 294 -0.000155116 46.5 0.000124093 0Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* <svg className={styles.svg}>
        <path
          className="waveshape"
          d="M300,300V2.5c0,0-0.6-0.1-1.1-0.1c0,0-25.5-2.3-40.5-2.4c-15,0-40.6,2.4-40.6,2.4c-12.3,1.1-30.3,1.8-31.9,1.9c-2-0.1-19.7-0.8-32-1.9c0,0-25.8-2.3-40.8-2.4c-15,0-40.8,2.4-40.8,2.4c-12.3,1.1-30.4,1.8-32,1.9c-2-0.1-20-0.8-32.2-1.9c0,0-3.1-0.3-8.1-0.7V300H300z"
        />
      </svg> */}
      {/* <div className={styles.white} />
      <div className={styles.wine} /> */}
      {/* <img
        className={styles.img}
        src={color.toLowerCase() === "red" ? redWineSrc : whiteWineSrc}
        alt="barrel-filled"
      /> */}
    </div>
  );
}
