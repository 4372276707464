import { Button } from "src/app/components/button";
import { ErrorBaseUI } from "./error-base-ui";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";

export const PageError404 = () => {
  return (
    <ErrorBaseUI
      title="404"
      text="Page not Found"
      actions={[
        <Button
          key="btn-home"
          as={A}
          to={path.dashboard()}
          variant="outlined"
          label="Go back to the homepage"
        />,
      ]}
    />
  );
};
