import { ErrorBaseUI } from "./error-base-ui";

export const PageErrorMaintenance = () => {
  return (
    <div style={{ padding: "2rem" }}>
      <div style={{ maxWidth: "34rem" }}>
        <ErrorBaseUI
          title="Maintenance"
          text="We are updating our website to implement new features, we will be back online within an hour."
        />
      </div>
    </div>
  );
};
