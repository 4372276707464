import { Skeleton } from "src/app/components/skeleton";
import { useIsMobile } from "src/app/hooks/useIsMobile";

const styleSkeleton = {
  backgroundColor: "rgba(255,255,255,0.2)",
};

export function SkeletonProvenance() {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && (
        <div className="pnm-left">
          <div className="pnm-360-main">
            <div className="pnm-360-bottle-holder">
              <figure>
                <Skeleton.Rect style={styleSkeleton} height={700} />
              </figure>
            </div>
          </div>
        </div>
      )}

      <div className="pnm-right">
        <div className="pnm-header">
          <div className="pnm-header-titles">
            <div className="pnm-year">
              <Skeleton.Rect style={styleSkeleton} height={20} width={50} />
            </div>
            <h1>
              <Skeleton.Rect style={styleSkeleton} height={70} />
            </h1>
            <h2>
              <Skeleton.Rect style={styleSkeleton} height={30} />
            </h2>
          </div>
          <div className="pnm-header-logo">
            <Skeleton.Circle style={styleSkeleton} size={100} />
          </div>
        </div>

        {!!isMobile && (
          <div className="pnm-bottle-mobile-main">
            <div className="pnm-360-main">
              <div className="pnm-360-bottle-holder">
                <figure>
                  <Skeleton.Rect style={styleSkeleton} height={500} />
                </figure>
              </div>
            </div>
          </div>
        )}

        <Skeleton.Rect style={styleSkeleton} height={300} />

        <Skeleton.Rect style={styleSkeleton} height={300} />

        <Skeleton.Rect style={styleSkeleton} height={100} />

        <Skeleton.Rect style={styleSkeleton} height={50} />
      </div>
    </>
  );
}
