import styles from "./index.module.css";
import { A } from "src/app/components/a";
import { Button } from "src/app/components/button";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";
import { path } from "src/app/routes/path";

export function FooterDesktop() {
  const config = useSystemConfig();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root}>
      <div className={styles.left}>
        <div className={styles.year}>{currentYear}</div>
        <div>
          &copy; Crurated {config?.projectVersion} - Powered by Genesis Brain
        </div>
      </div>
      <div className={styles.right}>
        <Button
          as={A}
          className={styles.link}
          variant="ghost"
          label="Website"
          to="https://crurated.com/"
        />
        <Button
          as={A}
          className={styles.link}
          variant="ghost"
          label="Contact"
          to={path.contact()}
        />
        <Button
          as={A}
          className={styles.link}
          variant="ghost"
          label="Terms & Conditions"
          to={path.termsAndConditions()}
        />
      </div>
    </footer>
  );
}
