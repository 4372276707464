/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Badge } from "src/app/components/badge";
import { DesktopCellContentText } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-text";
import { MobileCellContentText } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-text";
import { TableCustomerSide } from "src/app/components/table-customer-side/table-customer-side";
import { setDataTimeZone } from "src/app/config/Utility";
import { MobileCellContentName } from "src/app/pages/outstanding-payments/cell-content-name-desktop";
import { DesktopCellContentName } from "src/app/pages/outstanding-payments/cell-content-name-mobile";
import { MySubscriptionsV1Base } from "src/app/models/MySubscriptionsV1";
import { subscriptionStates } from ".";
import { DesktopCellContentCurrency } from "src/app/components/table-customer-side/cell-content-desktop/cell-content-currency";
import { MobileCellContentCurrency } from "src/app/components/table-customer-side/cell-content-mobile/cell-content-currency";

export default function SubscriptionHistoryTable({
  data,
}: {
  data: MySubscriptionsV1Base[];
}) {
  if (!data) return;
  return (
    <>
      <TableCustomerSide
        urlPrefix=""
        data={data}
        getRowId={(row) => row.id_subscription_stripe}
        isFetching={false}
        isLoading={false}
        noDataTitle="No Subscriptions"
        noDataContent={
          <div>
            <p>No Subscriptions</p>
          </div>
        }
        columns={[
          {
            header: "Date Subscription",
            id: "date-subscription",
            getSortingValue: (row) =>
              setDataTimeZone(row.date_subscription, "YYYY-MM-DD"),
            cellDesktop: (row) => (
              <DesktopCellContentText
                content={setDataTimeZone(row.date_subscription, "YYYY-MM-DD")}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentText
                content={setDataTimeZone(row.date_subscription, "YYYY-MM-DD")}
              />
            ),
          },
          {
            header: "Event",
            id: "event",
            getSortingValue: (row) => row.status,
            cellDesktop: (row) => (
              <Badge
                label={subscriptionStates[row.status]?.label || "Undefined"}
                palette={subscriptionStates[row.status]?.palette || "black"}
              />
            ),
          },
          {
            header: "Price",
            id: "price",
            getSortingValue: (row) => row.price,
            cellDesktop: (row) => (
              <DesktopCellContentCurrency amount={row.price} align="left" />
            ),
            cellMobile: (row) => (
              <MobileCellContentCurrency amount={row.price} />
            ),
          },
          {
            header: "Plan",
            id: "plan",
            getSortingValue: (row) => row.name,
            cellDesktop: (row) => (
              <DesktopCellContentName
                content={row.name}
                serif
                details={`(${row.recurring_type})`}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentName
                content={row.name}
                serif
                details={`(${row.recurring_type})`}
              />
            ),
          },
          {
            header: "Recurring Date",
            id: "recurring-date",
            getSortingValue: (row) =>
              setDataTimeZone(row.date_end_subscription, "YYYY-MM-DD"),
            cellDesktop: (row) => (
              <DesktopCellContentText
                content={setDataTimeZone(
                  row.date_end_subscription,
                  "YYYY-MM-DD",
                )}
              />
            ),
            cellMobile: (row) => (
              <MobileCellContentText
                content={setDataTimeZone(
                  row.date_end_subscription,
                  "YYYY-MM-DD",
                )}
              />
            ),
          },
        ]}
      />
    </>
  );
}
