import styles from "./index.module.css";
import { CSSProperties, ReactNode } from "react";
import { range } from "lodash";
import { Skeleton } from "../../../skeleton";
import { cn } from "src/app/utils/cn";
import { useInView } from "react-intersection-observer";

type Props = {
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  isLoading?: boolean;
  limit?: number;
  emptyFallback: ReactNode;
  isEmpty?: boolean;
} & (
  | {
      canLoadMore?: never;
      onLoadMore?: never;
      rootMargin?: never;
    }
  | {
      canLoadMore: boolean;
      onLoadMore: () => void;
      rootMargin?: number;
    }
);

export const Grid = ({
  children,
  className,
  canLoadMore,
  onLoadMore,
  style,
  isLoading,
  limit = 20,
  emptyFallback,
  isEmpty,
  rootMargin = 500,
}: Props) => {
  const { ref } = useInView({
    rootMargin: `0px 0px ${rootMargin}px 0px`,
    onChange(inView) {
      inView && canLoadMore && onLoadMore();
    },
  });

  return (
    <div role="list" className={cn(styles.root, className)} style={style}>
      {!isLoading && !!isEmpty && (
        <div className={styles.emptyFallback}>{emptyFallback}</div>
      )}
      {children}
      {!!isLoading &&
        range(0, Math.floor(limit / 2)).map((n) => (
          <Skeleton.Rect key={`skel-card-md-${n}`} height={500} />
        ))}
      <div ref={ref} />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
// type Props = {
//   children?: ReactNode[];
//   isLoading: boolean;
//   emptyFallback: ReactNode;
//   className?: string;
//   style?: CSSProperties;
// };

// export const Grid = ({
//   children = [],
//   isLoading,
//   emptyFallback,
//   className = "",
//   style = {},
// }: Props) => {
//   const isMobile = useIsMobile();

//   const infiniteScroll = useInfiniteScroll({
//     totalItems: children.length,
//     scrollableEl: document.getElementById(ID_IO_ROOT),
//     step: isMobile ? 6 : 18,
//     isLoading,
//   });

//   return (
//     <div className={cn(styles.root, className)} style={style} role="list">
//       {!!isLoading &&
//         range(0, infiniteScroll.sliceEnd).map((n) => (
//           <Skeleton.Rect key={`skel-card-md-${n}`} height={500} />
//         ))}

//       {!isLoading && !children.length && (
//         <div className={styles.emptyFallback}>{emptyFallback}</div>
//       )}

//       {!isLoading &&
//         !!children.length &&
//         children.slice(0, infiniteScroll.sliceEnd)}

//       {/* A few empty div to fill up the grid */}
//       <div />
//       <div />
//     </div>
//   );
// };
