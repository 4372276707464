import styles from "./index.module.css";
import { A } from "src/app/components/a";
import { FetchingIndicator } from "../fetching-indicator";
import { useSidebarStatus } from "../sidebar-menu/useSidebarStatus";
import { path } from "src/app/routes/path";
import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as AuctionIcon } from "../icons/auction.svg";
import { ReactComponent as ShopIcon } from "../icons/shop.svg";
import { ReactComponent as BookIcon } from "../icons/mycellar.svg";
import { ReactComponent as MenuIcon } from "../icons/menu.svg";
import { SidebarMenu } from "../sidebar-menu";
import { useLockBodyScroll } from "react-use";

export const BottomNavMobile = () => {
  const sidebar = useSidebarStatus();

  useLockBodyScroll(sidebar.isPinned);

  return (
    <>
      <nav className={styles.root}>
        <FetchingIndicator />

        <ul className={styles.ul}>
          <li className={styles.li}>
            <A
              to={path.dashboard()}
              activeClassName={styles.active}
              className={styles.link}
              onClick={() => sidebar.unpin()}
            >
              <HomeIcon />
              <span>Dashboard</span>
            </A>
          </li>
          <li className={styles.li}>
            <A
              to={path.weekly.value()}
              activeClassName={styles.active}
              className={styles.link}
              onClick={() => sidebar.unpin()}
            >
              <AuctionIcon />
              <span>Weekly Offers</span>
            </A>
          </li>
          <li className={styles.li}>
            <A
              to={path.store.shop()}
              activeClassName={styles.active}
              className={styles.link}
              onClick={() => sidebar.unpin()}
            >
              <ShopIcon />
              <span>Shop</span>
            </A>
          </li>
          <li className={styles.li}>
            <A
              to={path.myAssets.value()}
              activeClassName={styles.active}
              className={styles.link}
              onClick={() => sidebar.unpin()}
            >
              <BookIcon />
              <span>Cellar</span>
            </A>
          </li>
          <li className={styles.li}>
            <button className={styles.link} onClick={() => sidebar.pin()}>
              <MenuIcon />
              <span>Menu</span>
            </button>
          </li>
        </ul>
      </nav>

      <div className={styles.sidemenu}>
        <SidebarMenu
          isPinned={sidebar.isPinned}
          onPin={sidebar.pin}
          onUnpin={sidebar.unpin}
        />
      </div>
    </>
  );
};
