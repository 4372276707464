import { ComponentProps } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styles from "./phone-input.module.css";

export const CruPhoneInput = ({
  ...props
}: ComponentProps<typeof PhoneInput>) => {
  return <PhoneInput international {...props} className={styles.root} />;
};
