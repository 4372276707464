import { LocalStorage } from "src/app/utils/LocalStorage";
import { minutes } from "src/app/utils/time";
import runtimeVersion from "src/version-control/runtime-version-uuid.json";
/**
 * Fetch the version UUID on the client
 * refresh the page if the UUID has changed
 */
export function assertLatestVersion() {
  checkVersion();
  document.addEventListener("visibilitychange", async () => {
    if (document.hidden) return;
    checkVersion();
  });
}

const LAST_REFRESH_TIMESTAMP_STORAGE_KEY = "last-forced-refresh-timestamp";

declare global {
  interface Window {
    runtimeUUID: string | null;
  }
}

async function checkVersion() {
  console.info("Checking app version...");
  const latestUUID = await getLatestVersionUUID();
  const runtimeUUID = getRuntimeVersionUUID();
  // set the runtimeUUID to check it out on the chrome devtools
  window.runtimeUUID = runtimeUUID;
  const isMatching =
    !!latestUUID && !!runtimeUUID && latestUUID === runtimeUUID;
  if (isMatching) return;
  // uuids do not match
  const nowTimestamp = Date.now();
  const lastRefreshTimestamp = getLastRefreshTimestamp();
  // do nothing if the last refresh occurred less than 5 minutes ago
  if (nowTimestamp - lastRefreshTimestamp < minutes(5)) return;
  // update last refresh timestamp and refresh the page to attempt to bust the cache
  // Note that the browser might decide not to bust the cache
  LocalStorage.setItem(LAST_REFRESH_TIMESTAMP_STORAGE_KEY, nowTimestamp);
  // @ts-expect-error older versions of firefox allow passing `true` as argument in order to bust the cache
  // other browsers do not support this feature
  window.location.reload(true);
}

async function getLatestVersionUUID(): Promise<string | null> {
  const versionFileUrl = `${window.location.origin}/latest-version-uuid.json`;
  try {
    const response = await fetch(versionFileUrl, { cache: "no-store" });
    if (!response.ok) return null; // no version file, the user is on an old version
    const json: { uuid: string } = await response.json();
    return json.uuid;
  } catch (e) {
    return null;
  }
}

function getRuntimeVersionUUID(): string | null {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return runtimeVersion?.uuid || null;
}

function getLastRefreshTimestamp(): number {
  try {
    const lastRefreshTimestamp = Number(
      LocalStorage.getItem(LAST_REFRESH_TIMESTAMP_STORAGE_KEY),
    );
    if (isNaN(lastRefreshTimestamp)) return 0;
    return lastRefreshTimestamp;
  } catch (e) {
    return 0;
  }
}
