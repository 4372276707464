import styles from "./index.module.css";
import { ReactNode, useState } from "react";
import { ApiLotV1 } from "src/app/models/LotV1";
import { CruForm } from "src/app/components/cru-form-3";
import { useMyUser } from "src/app/hooks/useMyUser";
import { Timezone } from "src/app/config/Utility";
import { LotSelection } from "./lot-selection";
import { RecipientInfo } from "./recipient-info";
import { emptyCountry } from "../../address-form/customer-info-section";
import { CountryOption } from "../../address-form/use-country-options";
import { Checkout } from "./checkout";
import { StatusMessage } from "./status-message";
import { useMount } from "react-use";
import { useSendGiftMutation } from "src/app/api/mutations_new_to_rename";
import { parseApiError } from "src/app/api/_parse-api-error";
import { useQueryClient } from "@tanstack/react-query";

export type GiftFormValues = {
  lots: ApiLotV1[];
  id_user_sender: number;
  recipient_first_name: string;
  recipient_last_name: string;
  recipient_email: string;
  message: string;
  date_to_send_gift: string;
  country: CountryOption;
  recipient_confirm_adult: 0 | 1;
  recipient_time_zone: string;
  type_action: "CREATE";
};

type Props = {
  selectedLots: ApiLotV1[];
  onClose: () => void;
};

export default function SendGiftForm({ selectedLots, onClose }: Props) {
  const user = useMyUser();
  const queryClient = useQueryClient();
  const [step, setStep] = useState(1);

  const [errorMessage, setErrorMessage] = useState<string | ReactNode | null>(
    null,
  );

  const sendGiftMutation = useSendGiftMutation({
    onSettled: () => {
      queryClient.invalidateQueries();
    },
    onError: (error) => {
      setErrorMessage(parseApiError(error));
    },
    onSuccess: () => setStep(4),
  });

  useMount(() => {
    if (selectedLots.some((v) => !v.canGift)) {
      setErrorMessage("Some selected items cannot be gifted");
    }
    if (selectedLots.length < 1) {
      setErrorMessage("You must select at least one offer to Gift");
    }
  });

  return (
    <div className={styles.root}>
      {errorMessage || sendGiftMutation.isError ? (
        <StatusMessage
          errorMessage={errorMessage || "Unexpected error"}
          onClose={onClose}
        />
      ) : (
        <CruForm.Form
          initialValues={
            {
              lots: selectedLots,
              id_user_sender: user.id_user,
              recipient_first_name: "",
              recipient_last_name: "",
              recipient_email: "",
              message: "",
              date_to_send_gift: "",
              country: emptyCountry,
              recipient_confirm_adult: 0,
              recipient_time_zone: Timezone(),
              type_action: "CREATE",
            } satisfies GiftFormValues as GiftFormValues
          }
          onSubmit={(values) => {
            if (step !== 3) return setStep(step + 1);
            sendGiftMutation.mutate(values);
          }}
        >
          {(ctx) => (
            <>
              {step === 1 && <LotSelection ctx={ctx} onClose={onClose} />}
              {step === 2 && (
                <RecipientInfo ctx={ctx} onGoBack={() => setStep(step - 1)} />
              )}
              {step === 3 && (
                <Checkout
                  ctx={ctx}
                  onGoBack={() => setStep(step - 1)}
                  isLoading={sendGiftMutation.isLoading}
                />
              )}
              {step === 4 && <StatusMessage onClose={onClose} />}
            </>
          )}
        </CruForm.Form>
      )}
    </div>
  );
}
