import styles from "./index.module.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { useMyEarningsQuery } from "src/app/api/queries_to_rename_later";
import { Button } from "src/app/components/button";
import { STORAGE_KEY } from "src/app/const";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { formatPercentage } from "src/app/utils/formatPercentage";
import { cn } from "src/app/utils/cn";
import { CSSProperties } from "react";
import { useLocalStorage } from "src/app/hooks/useLocalStorage";

type Props = {
  className?: string;
  style?: CSSProperties;
};

export const MyEarnings = ({ className = "", style = {} }: Props) => {
  const isMobile = useIsMobile();
  const [showMyEarnings, setShowMyEarnings] = useLocalStorage(
    STORAGE_KEY.SHOW_MY_EARNINGS,
    true,
  );

  const { data, isLoading, isError } = useMyEarningsQuery();

  if (isLoading || !data || isError) return null;

  const totalPurchased = formatCurrency(data.totalPurchased.value, {
    decimals: isMobile ? 0 : 2,
  });

  const emp = formatCurrency(data.emp.value, {
    decimals: isMobile ? 0 : 2,
  });

  const upside = formatPercentage(data.upside.value);

  return (
    <div
      className={cn(styles.root, isMobile && styles.mobile, className)}
      style={style}
    >
      <div>
        <div className={styles.label}>Total Purchased</div>
        <div className={cn(styles.value, !showMyEarnings && styles.hide)}>
          {totalPurchased}
        </div>
      </div>
      <hr className={styles.divider} />
      <div>
        <div className={styles.label}>EMP</div>
        <div className={cn(styles.value, !showMyEarnings && styles.hide)}>
          {emp}
        </div>
      </div>
      <hr className={styles.divider} />
      <div>
        <div className={styles.label}>Upside</div>
        <div
          className={cn(
            styles.value,
            !showMyEarnings && styles.hide,
            data.upside.direction === 1 && styles.success,
          )}
        >
          {upside}
          {data.upside.direction === 1 ? (
            <FaArrowTrendUp size={16} />
          ) : (
            <FaArrowTrendDown size={16} />
          )}
        </div>
      </div>

      <Button
        variant="ghost"
        tooltip={showMyEarnings ? "Hide my earnings" : "Show my earnings"}
        onClick={() => setShowMyEarnings(!showMyEarnings)}
        icon={
          showMyEarnings ? (
            <IoEyeOffOutline size={24} />
          ) : (
            <IoEyeOutline size={24} />
          )
        }
      />
    </div>
  );
};
