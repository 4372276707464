import { BarrelInput as BarrelInputComponent } from "src/app/components/barrel-input";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";

type Props = {
  auction: AuctionOfBarrel;
  onChangeQuantity: (qty: number) => void;
  quantity: number;
  isThereLittleSpace: boolean;
};

export const BarrelInput = ({
  auction,
  isThereLittleSpace,
  onChangeQuantity,
  quantity,
}: Props) => {
  return (
    <div
      style={
        isThereLittleSpace
          ? { padding: "12px 30px 12px 12px" }
          : { padding: "16px 50px 30px 12px" }
      }
    >
      <BarrelInputComponent
        onChange={onChangeQuantity}
        quantity={quantity}
        max={auction.options.quantities.max}
        min={auction.options.quantities.min}
        step={auction.options.quantities.step}
        mul={auction.options.quantities.mul}
        color={auction.barrel.color.toUpperCase() as "RED" | "WHITE"}
        barrelHeight={isThereLittleSpace ? 200 : 240}
        hideTotalLiters
        disabled={!auction.canBid}
      />
    </div>
  );
};
