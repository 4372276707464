export const QKEY = {
  ADDRESS: "addresses",
  AUCTION: "auctions",
  AUCTION_ARCHIVED: "auction-archived",
  AUCTION_PARTICIPANT: "auction-participant",
  AUCTION_PS: "auction-private-sale",
  AUCTION_SHOP: "auction-shop",
  AUCTION_TYPE: "auction-type",
  AUCTION_WEEKLY: "auction-weekly",
  AUCTION_WISHLISTED: "auction-wishlisted",
  BADGE: "badges",
  BARREL: "barrels",
  BOTTLE_TIER: "bottle-tiers",
  BOTTLE_LIST: "bottle-list",
  CARDS: "cards",
  CELLAR_FILE: "file-cellar",
  CHARITY: "charity",
  CHECK_EMAIL: "check-email",
  COLLECTION: "collection",
  CONFIG: "config",
  CONTRACTS: "contracts",
  COUNTRY: "country",
  COUNTRY_SHIPPING: "country_shipping",
  COUPON: "coupon",
  CUSTOMER_ROLE: "customer-roles",
  DOWNLOAD_CENTER: "download-center",
  EN_PRIMEUR: "en-primeur",
  EXPERIENCES: "experiences",
  LOT: "lots",
  ME: "me",
  MY_ASSETS: "my-assets",
  MY_ASSETS_STATS: "my-assets-stats",
  MY_EARNINGS: "my-earnings",
  MY_OFFERS: "my-offers",
  OUTSTANDING_PAYMENTS: "outstanding-payments",
  PAYMENT_METHODS: "payment-methods",
  PHYSICAL: "physical",
  PRIVATE_SALE: "private-sales",
  PRODUCER: "producers",
  PRODUCT: "products",
  PROVENANCE: "provenance",
  SERIAL: "serials",
  SHIPMENT: "shipments",
  SINGLE_LOT: "single-lot",
  SPONSOR: "sponsor",
  STRIPE_SECRET: "stripe-secret",
  SUBSCRIPTIONS: "subscriptions",
  WHATS_NEW: "WHATS_NEW",
  WINERY: "builders",
  NFT_METADATA: "NFT_METADATA",
} as const;
