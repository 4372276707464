/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import * as braze from "@braze/web-sdk";
import { env } from "src/env";
import { useGetBrazeApiKey } from "src/app/api/api-key";
import { useMyUser } from "src/app/hooks/useMyUser";
import { STORAGE_KEY } from "src/app/const";

export default function useBrazeInitializer() {
  const user = useMyUser({ maybeNull: true });
  const { data: brazeApiKey } = useGetBrazeApiKey();

  useEffect(() => {
    if (!brazeApiKey?.data) return;

    braze.initialize(brazeApiKey.data, {
      baseUrl: "https://sdk.fra-02.braze.eu",
      enableLogging: env.isDev,
    });
  }, [brazeApiKey]);

  useEffect(() => {
    if (!user?.id) {
      if (!localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)) {
        localStorage.removeItem(STORAGE_KEY.USER_LOGGED);
      }
      return;
    }
    braze.changeUser(String(user.id));
    braze.openSession();
    if (
      !localStorage.getItem(STORAGE_KEY.USER_LOGGED) &&
      !localStorage.getItem(STORAGE_KEY.ADMIN_IMPERSONATE)
    ) {
      localStorage.setItem(STORAGE_KEY.USER_LOGGED, "true");
      braze.logCustomEvent("user_logged");
    }
  }, [user]);
}
