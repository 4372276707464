import { addTimezoneOffset } from "./addTimezoneOffset";

type Input = Date | string | null | undefined;

type Options = Intl.DateTimeFormatOptions & { ignoreTimezone?: boolean };

export function formatDate(
  input: Input,
  { ignoreTimezone = false, ...options }: Options = {},
) {
  if (!input) return "";
  // Fix older safari version crashing with new Date GMT string
  if (typeof input === "string") {
    input = input.replace(/-/g, "/");
  }
  const date = typeof input === "string" ? new Date(input) : input;
  if (isNaN(date.valueOf())) return "";
  return new Intl.DateTimeFormat([...navigator.languages], {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    ...options,
    // The timezone from the user profile should be used but it has an incorrect format
  }).format(ignoreTimezone ? date : addTimezoneOffset(date));
}

export function formatDateTime(
  input: Input,
  { ignoreTimezone = false, ...options }: Options = {},
) {
  if (!input) return "";
  const date = typeof input === "string" ? new Date(input) : input;

  return new Intl.DateTimeFormat("en-GB", {
    dateStyle: "medium",
    timeStyle: "short",
    ...options,
    // The timezone from the user profile should be used but it has an incorrect format
  }).format(ignoreTimezone ? date : addTimezoneOffset(date));
}
