import wineBottleSrc from "src/media/barrel/wine-bottle.svg";
import srcWineBottleFilledRed from "src/media/barrel/wine-bottle-filled.svg";
import srcWineBottleFilledWhite from "src/media/barrel/white-wine-bottle-filled.svg";
import { noop } from "lodash";

export function InputBottle({
  color,
  size,
  onClickPlus = noop,
  onClickMinus = noop,
  onClickFillRemaining = noop,
  isHiddenToCustomer,
  isCustomer,
  showMinus,
  showPlus,
}) {
  if (isHiddenToCustomer && isCustomer) return null;

  return (
    <div
      className={`bcp-pb-bottle-item ${
        showMinus && "filled"
      } ${getBottleClassForBottleSize(size)}`}
      style={{ userSelect: "none", cursor: "default" }}
    >
      <div style={{ position: "relative" }}>
        <figure>
          <img src={wineBottleSrc} alt="bottle" className="img-responsive" />
          <div className="bcp-pb-bottle-hover">
            <img
              src={
                color.toLowerCase() === "red"
                  ? srcWineBottleFilledRed
                  : srcWineBottleFilledWhite
              }
              alt="bottle"
              className="img-responsive"
            />
          </div>
        </figure>
        <div
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            fontSize: "20px",
            paddingBottom: "4px",
          }}
        >
          {!!showMinus && (
            <button
              onClick={() => onClickMinus()}
              children="-"
              disabled={!showMinus}
              type="button"
              style={{
                width: "100%",
                height: "30px",
                background: "transparent",
                border: "none",
                outline: "none",
                opacity: showMinus ? 1 : 0,
              }}
            />
          )}
          <button
            onClick={() => onClickPlus()}
            children="+"
            disabled={!showPlus}
            type="button"
            style={{
              width: "100%",
              height: "30px",
              background: "transparent",
              border: "none",
              outline: "none",
              opacity: showPlus ? 1 : 0,
            }}
          />
        </div>
      </div>
      <div
        className={`bcp-pb-bottle-unit ${
          isHiddenToCustomer && "no-bottle-enabled-tier"
        }`}
      >
        {size} L
      </div>

      <div style={{ marginTop: "6px" }}>
        <button
          onClick={onClickFillRemaining}
          disabled={!showPlus}
          type="button"
          title="Convert all remaining wine"
          style={{
            opacity: showPlus ? 1 : 0,
            pointerEvents: showPlus ? "auto" : "none",
            padding: "2px 3px 3px 3px",
            border: "1px solid #7b7b7b",
            borderRadius: "9px",
            background: "rgba(0, 0, 0, 0.4)",
            color: "white",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            height={16}
            width={16}
            style={{ pointerEvents: "none" }}
          >
            <path
              fillRule="evenodd"
              d="M5.23 15.79a.75.75 0 01-.02-1.06l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 11-1.08 1.04L10 11.832 6.29 15.77a.75.75 0 01-1.06.02zm0-6a.75.75 0 01-.02-1.06l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 11-1.08 1.04L10 5.832 6.29 9.77a.75.75 0 01-1.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

function getBottleClassForBottleSize(size) {
  switch (+size) {
    case 0.75: {
      return "bcp-pb-bottle-item-075";
    }
    case 1.5: {
      return "bcp-pb-bottle-item-15";
    }
    case 2: {
      return "bcp-pb-bottle-item-2";
    }
    case 2.5: {
      return "bcp-pb-bottle-item-25";
    }
    case 3: {
      return "bcp-pb-bottle-item-3";
    }
    case 3.5: {
      return "bcp-pb-bottle-item-35";
    }
    case 5: {
      return "bcp-pb-bottle-item-5";
    }
    case 6: {
      return "bcp-pb-bottle-item-6";
    }
    case 9: {
      return "bcp-pb-bottle-item-9";
    }
    case 12: {
      return "bcp-pb-bottle-item-12";
    }
    case 15: {
      return "bcp-pb-bottle-item-150";
    }
    case 18: {
      return "bcp-pb-bottle-item-180";
    }
    default:
      return "bcp-pb-bottle-item-075";
  }
}
