import { useRef } from "react";
import styles from "./search.module.css";
import { FiSearch } from "react-icons/fi";
import { useTimeoutFn } from "react-use";
import { toPx } from "src/app/utils/toPx";
import { useIsMobile } from "src/app/hooks/useIsMobile";

export const searchContainerHeight = 40;

type Props = {
  onChange: (search: string) => void;
  search: string;
};

export const SelectMultipleSearch = ({ onChange, search }: Props) => {
  const isMobile = useIsMobile();
  const ref = useRef<HTMLInputElement | null>(null);

  useTimeoutFn(() => {
    !isMobile && ref.current?.focus();
  }, 500);

  return (
    <label
      className={styles.root}
      style={{ height: toPx(searchContainerHeight) }}
    >
      <FiSearch size={16} color="#888" />

      <input
        ref={ref}
        placeholder="Search"
        spellCheck="false"
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => e.key === " " && e.stopPropagation()}
        value={search}
      />
    </label>
  );
};
