export class TypedSearchParams<T extends Record<string, any>> {
  private __value: T;

  constructor(objOrQs: T | string) {
    this.__value = typeof objOrQs === "string" ? parse<T>(objOrQs) : objOrQs;
  }

  update(objOrQs: Partial<T> | string) {
    this.__value = {
      ...this.__value,
      ...new TypedSearchParams(objOrQs).__value,
    };
    return this;
  }

  toString(): string {
    return stringify(this.__value);
  }

  toObject(): T {
    return this.__value;
  }
}

function stringify(obj: Record<string, any>): string {
  return Object.entries(obj).reduce((qs, [key, val]) => {
    if (typeof val === "undefined") return qs; // remove undefined from the qs
    qs += `${qs.length ? "&" : ""}${encode.key(key)}=${encode.value(val)}`;
    return qs;
  }, "") satisfies string;
}

function parse<T extends Record<string, any>>(str: string) {
  str = str.startsWith("?") ? str.slice(1) : str;

  return str
    .split("&")
    .filter(Boolean)
    .map((str) => str.split("="))
    .reduce(
      (obj, [key, val]) => {
        obj[decode.key(key)] = decode.value(val);
        return obj;
      },
      {} as Record<string, any>,
    ) as T;
}

const isEncoded = {
  string: (str: string) =>
    (str.startsWith('"') && str.endsWith('"')) ||
    (str.startsWith("%22") && str.endsWith("%22")),
  boolean: (str: string) => str === "true" || str === "false",
  number: (str: string) => !isNaN(+str) && (+str).toString() === str,
  null: (str: string) => str === "null",
  object: (str: string) =>
    (str.startsWith("%5B") && str.endsWith("%5D")) ||
    (str.startsWith("%7B") && str.endsWith("%7D")),
};

const encode = {
  key: (key: string): string => encodeURIComponent(key),
  value: (value: any): string => {
    if (typeof value === "object" && value !== null) {
      return encodeURIComponent(JSON.stringify(value));
    }
    if (typeof value === "string") {
      return `"${encodeURIComponent(value)}"`;
    }
    return String(value);
  },
};

const decode = {
  key: (key: string) => decodeURIComponent(key),
  value: (str: string = ""): any => {
    if (isEncoded.object(str)) {
      return JSON.parse(decodeURIComponent(str));
    }
    if (isEncoded.string(str)) {
      return decodeURIComponent(str).slice(1, -1);
    }
    if (isEncoded.boolean(str)) {
      return str === "true";
    }
    if (isEncoded.number(str)) {
      return +str;
    }
    if (isEncoded.null(str)) {
      return null;
    }
    return str;
  },
};
