import { ReactNode } from "react";
import { DesktopCellContentText } from "../../components/table-customer-side/cell-content-desktop/cell-content-text";

type Props = {
  content: ReactNode;
  minWidth?: string | number;
  serif?: boolean;
  maxLines?: number;
  details: ReactNode;
};

export const DesktopCellContentName = ({
  details,
  ...contentTextProps
}: Props) => {
  return (
    <div>
      <DesktopCellContentText {...contentTextProps} />
      <div
        style={{
          fontSize: "0.6875rem",
          color: "var(--color-primary)",
          fontStyle: "italic",
        }}
      >
        {details}
      </div>
    </div>
  );
};
