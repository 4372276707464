import styles from "./bottling-dates.module.css";
import { Tooltip } from "src/app/components/tooltip";
import {
  Auction,
  isAuctionOfBarrel,
  isAuctionOfLot,
} from "src/app/models/auctions";
import bottlesImage from "src/media/barrel/wine-bottle.png";
import dateOfArrival from "src/images/common/date-of-arrival.svg";
import time from "src/images/common/hourglass-solid.svg";
import { ReactNode } from "react";
import { cn } from "src/app/utils/cn";

export const BottlingDates = ({ auction }: { auction: Auction }) => {
  return (
    <>
      {isAuctionOfBarrel(auction) && (
        <>
          {!!auction.barrel.final_bottling_date && (
            <DateBadge tooltipContent="Initial Bottling Date.">
              <img
                width={20}
                height={20}
                src={bottlesImage}
                alt="dateOfArrival"
              />
              <span>{formatDate(auction.barrel.final_bottling_date)}</span>
            </DateBadge>
          )}
          {!!auction.barrel.lots_available_date && (
            <DateBadge tooltipContent="Estimated date of arrival in our warehouse.">
              <img src={dateOfArrival} alt="dateOfArrival" />

              <span>
                {new Date(auction.barrel.lots_available_date).getTime() >
                new Date().getTime()
                  ? formatDate(auction.barrel.lots_available_date)
                  : "Ready to ship"}
              </span>
            </DateBadge>
          )}
        </>
      )}

      {!auction.experience &&
        isAuctionOfLot(auction) &&
        !!auction.lot?.available_date && (
          <DateBadge tooltipContent="Estimated date of arrival in our warehouse.">
            <img src={dateOfArrival} alt="dateOfArrival" />

            <span>
              {new Date(auction.lot.available_date).getTime() >
              new Date().getTime()
                ? formatDate(auction.lot.available_date)
                : "Ready to ship"}
            </span>
          </DateBadge>
        )}

      {!!auction.experience?.expiration_date && (
        <DateBadge tooltipContent="Expiration date. Please book before.">
          <img style={{ width: "10px" }} src={time} alt="time" />
          <span className={styles.expirationDate}>
            {formatDate(auction.experience.expiration_date)}
          </span>
        </DateBadge>
      )}

      {!!auction.experience?.start_experience_date && (
        <div className={cn(styles.bottlingDate, styles.experienceDates)}>
          <span>{formatDate(auction.experience.start_experience_date)}</span>
          {!!auction.experience.end_experience_date && (
            <>
              <span>&nbsp;-&nbsp;</span>
              <span>{formatDate(auction.experience.end_experience_date)}</span>
            </>
          )}
        </div>
      )}
    </>
  );
};

function DateBadge({
  tooltipContent,
  children,
}: {
  tooltipContent: string;
  children: ReactNode;
}) {
  return (
    <div className={styles.dateBadge}>
      <Tooltip position="bottom" content={tooltipContent}>
        <div className={styles.bottlingDate}>{children}</div>
      </Tooltip>
    </div>
  );
}

function formatDate(date: string | null | undefined) {
  if (typeof date === "undefined" || date === null || !date) return;

  return new Intl.DateTimeFormat("en-GB", {
    dateStyle: "medium",
  }).format(new Date(date));
}
