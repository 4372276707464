import { useRef } from "react";
import { useUnmount } from "react-use";

export function useDebounceFn<Args extends any[]>(
  cb: (...args: Args) => void,
  delay: number,
) {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useUnmount(() => clearTimeout(timeoutRef.current));

  return (...args: Args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}
