import styles from "./error-message.module.css";
import { cn } from "src/app/utils/cn";
import { CruFormContextType, CruFormValues } from "./types";

type Props<TFormData extends CruFormValues> = {
  cruForm: CruFormContextType<TFormData>;
  name: keyof TFormData;
  className?: string;
};

export const ErrorMessage = <TFormData extends CruFormValues>({
  cruForm,
  name,
  className = "",
}: Props<TFormData>) => {
  const errorMessage = cruForm.errors[name];

  if (!errorMessage || !cruForm.touched[name]) return null;

  return <div className={cn(className, styles.root)}>{errorMessage}</div>;
};
