import styles from "./index.module.css";
import barrelLeftSrc from "src/media/barrel.svg";
import { CSSProperties } from "react";
import { Scale } from "./scale";
import { Slider } from "./slider";
import { WavyAnimation } from "./wavy-animation";
import { ImgBarrelBorder } from "./img-barrel-border";
import { cn } from "src/app/utils/cn";
import { useInView } from "react-intersection-observer";
import { ID_IO_ROOT } from "src/app/const";

const spaceTopPercentage = 3.5;
const noop = () => {};

export type BarrelInputProps = {
  className?: string;
  style?: CSSProperties;
  max: number;
  min: number;
  step: number;
  mul: number;
  onChange: (quantity: number) => void;
  quantity: number;
  color: "RED" | "WHITE" | "Red" | "White" | string;
  barrelHeight?: number;
  hideTotalLiters?: boolean;
  disabled?: boolean;
};

export function BarrelInput({
  className = "",
  style = {},
  max,
  min,
  step,
  mul,
  onChange = noop,
  quantity,
  color,
  barrelHeight = 200,
  hideTotalLiters = false,
  disabled = false,
}: BarrelInputProps) {
  max = Math.max(1, Math.round(max));

  const numberOfSteps = Math.min(5, max / step);

  const { ref, inView } = useInView({
    rootMargin: "600px 0px",
    root: document.getElementById(ID_IO_ROOT),
  });

  return (
    <div className={cn(styles.root, className)} ref={ref} style={style}>
      <div className={styles.barrelLeft}>
        <img
          className={styles.barrelLeftImg}
          height={barrelHeight}
          src={barrelLeftSrc}
          alt="Barrel left"
        />
      </div>

      {!!inView && (
        <div className={styles.barrelRight}>
          <Scale
            mul={mul}
            max={max}
            numberOfSteps={numberOfSteps}
            spaceTopPercentage={spaceTopPercentage}
          />
          <WavyAnimation
            color={color}
            max={max}
            quantity={quantity}
            spaceTopPercentage={spaceTopPercentage}
            barrelHeight={barrelHeight}
          />
          <Slider
            quantity={quantity}
            onChange={onChange}
            max={max}
            min={min}
            step={step}
            disabled={disabled}
            spaceTopPercentage={spaceTopPercentage}
          />
          <ImgBarrelBorder height={barrelHeight} />
        </div>
      )}

      {!hideTotalLiters && (
        <div className={styles.totalLiters} style={{ color: "currentColor" }}>
          {`${quantity * mul} L`}
        </div>
      )}
    </div>
  );
}
