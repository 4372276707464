type Props = {
  number: number;
  align?: "left" | "right" | "center";
};

export const DesktopCellContentNumber = ({
  number,
  align = "center",
}: Props) => {
  return (
    <div
      style={{
        fontSize: "14px",
        whiteSpace: "nowrap",
        textAlign: align,
      }}
    >
      {number}
    </div>
  );
};
