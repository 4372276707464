import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AddressType } from "src/app/models/Address";
import { CruForm } from "src/app/components/cru-form-3";
import { Spacer } from "src/app/components/spacer";
import {
  InputAddress,
  emptyAddress,
} from "src/app/components/form-elements/input-address";
import { Input } from "src/app/components/form-elements/input";
import { FormContextType } from "../cru-form-3/types";

import styles from "./customer-info-section.module.css";
import { AddressFormValues } from "./address-form";
import { CountryOption } from "./use-country-options";
import { validate } from "src/app/utils/validate";
import { pick } from "lodash";

export const emptyCountry: CountryOption = {
  id: -1,
  value: 0,
  label: "Select country..",
  isEnableForShipping: false,
  EA: false,
};

type Props = {
  ctx: FormContextType<AddressFormValues>;
  disableDetailFields: boolean;
  addressType: AddressType;
  countryOptions: CountryOption[];
};

export function CustomerInfoSection({
  ctx,
  disableDetailFields,
  addressType,
  countryOptions,
}: Props) {
  return (
    <>
      <h4>Customer Info</h4>

      <CruForm.Field
        ctx={ctx}
        name="full_name"
        validate={validate.text({ required: true, allowDigits: false })}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel} required>
              Full Name
            </CruForm.Label>
            <Input
              {...props}
              type="text"
              placeholder="Full name"
              value={ctx.getFieldValue(props.name)}
              onChange={(e) => {
                ctx.setFieldsValues({
                  [props.name]: e.target.value,
                });
              }}
              onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
              disabled={disableDetailFields}
            />
            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>

      <Spacer size={18} />

      <CruForm.Field
        ctx={ctx}
        name="company_name"
        validate={validate.text({ required: false, allowDigits: true })}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel}>
              Company Name
            </CruForm.Label>
            <Input
              {...props}
              type="text"
              placeholder="Company Name"
              value={ctx.getFieldValue(props.name)}
              onChange={(e) => {
                ctx.setFieldsValues({
                  [props.name]: e.target.value,
                });
              }}
              onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
              disabled={disableDetailFields}
            />
            <CruForm.ErrorMessage />
            <div className={styles.inputNote}>
              If you want your invoices addresses to a company. Leave blank to
              use your full name
            </div>
          </>
        )}
      </CruForm.Field>

      <Spacer size={18} />

      {addressType === "invoice" && (
        <>
          <CruForm.Field ctx={ctx} name="vat">
            {(props) => (
              <>
                <CruForm.Label className={styles.formLabel}>
                  VAT Number
                </CruForm.Label>
                <Input
                  {...props}
                  type="text"
                  placeholder="Vat"
                  value={ctx.getFieldValue(props.name)}
                  onChange={(e) => {
                    ctx.setFieldsValues({ [props.name]: e.target.value });
                  }}
                  onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                />
                <CruForm.ErrorMessage />
              </>
            )}
          </CruForm.Field>
          <Spacer size={18} />
        </>
      )}

      <CruForm.Field
        ctx={ctx}
        name="phone"
        validate={(value) => {
          if (!value) {
            return "Contact Phone is required";
          }
          if (value.length < 5) {
            return "Contact Phone is required";
          }
        }}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel} required>
              Contact Phone
            </CruForm.Label>
            <div>
              <PhoneInput
                {...props}
                inputComponent={Input}
                type="text"
                placeholder="+1(123)112-11-11"
                defaultCountry="US"
                international
                value={ctx.getFieldValue(props.name)}
                onChange={(phoneNumber) => {
                  ctx.setFieldsValues({
                    [props.name]: phoneNumber,
                  });
                }}
                onBlur={() => ctx.setFieldsTouched({ [props.name]: true })}
                className={styles.phoneInput}
              />
            </div>
            <CruForm.ErrorMessage />
            <div className={styles.inputNote}>
              We&apos;ll never share your phone with anyone else
            </div>
          </>
        )}
      </CruForm.Field>

      <Spacer size={18} />

      <CruForm.Field
        ctx={ctx}
        name="google_confirmed"
        validate={(gc) => !gc && "Invalid address"}
      >
        {(props) => (
          <>
            <CruForm.Label className={styles.formLabel} required>
              Address Autocomplete
            </CruForm.Label>
            <InputAddress
              {...props}
              disabled={disableDetailFields}
              onChange={(address) => {
                ctx.setFieldsValues({
                  ...address,
                  address_line_1: address.address_line_1,
                  city: address.city,
                  country:
                    countryOptions.find((c) => c.value === address.country) ||
                    emptyCountry,
                  region: address.region,
                  postal_code: address.postal_code,
                  full_text: address.full_text,
                  google_confirmed: !!address.country,
                });
              }}
              value={{
                ...emptyAddress,
                country: ctx.values.country.value,
                ...pick(ctx.values, [
                  "full_text",
                  "address_line_1",
                  "city",
                  "postal_code",
                  "region",
                ]),
              }}
              fullAddressRequired
              notAvailableForShipping="ignore"
            />
            <CruForm.ErrorMessage />
          </>
        )}
      </CruForm.Field>
    </>
  );
}
