import { Button } from "../../button";
import { gaEventConfig } from "src/app/models/GaEvent";
import { path } from "src/app/routes/path";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { useHistory } from "react-router-dom";
import { useCardCtx } from "../card-context";

export const LinkViewDetails = () => {
  const cardCtx = useCardCtx();
  const history = useHistory();

  const [, setSearchParams] = useTypedSearchParams({
    schema: z.object({ id: z.number().optional() }),
  });

  return (
    <Button
      label="Discover More"
      variant="ghost"
      style={{
        fontWeight: 700,
        color: "var(--color-primary-light)",
      }}
      onClick={() => {
        setSearchParams({ id: cardCtx.auction.id }); // required for "back" button scroll restoration to auction card
        history.push(path.sales.value(cardCtx.auction));
      }}
      gaEventConfig={gaEventConfig.viewAuctionDetails(cardCtx.auction)}
    />
  );
};
