import { noop } from "lodash";
import styles from "./alert-popup.module.css";
import { Dialog } from "src/app/components/dialog";

type AlertPopupMetaForQuestion = {
  alertType: "question";
  onClose?: () => void;
  onConfirmButton?: () => void;
  title: string;
  subTitle?: string | JSX.Element;
  closeButton: string;
  confirmButton: string;
};

type AlertPopupMetaForQuestion2 = {
  alertType: "question-2";
  onClose?: () => void;
  onConfirmButton?: () => void;
  onConfirmButton2?: () => void;
  title: string;
  subTitle?: string | JSX.Element;
  confirmButton: string;
  confirmButton2: string;
};

type AlertPopupMetaDefault = {
  alertType: "success" | "error" | "warning";
  onClose?: () => void;
  onClickAction?: () => void;
  title: string;
  subTitle?: string | JSX.Element;
};

/**
 * @deprecated prefer `const alertPopup = useAlertPopup()`
 */
export type AlertPopupMeta =
  | AlertPopupMetaForQuestion
  | AlertPopupMetaForQuestion2
  | AlertPopupMetaDefault;

interface AlertPopupProps {
  alertPopupMeta: AlertPopupMeta;
  hidePopup?: () => void;
}

/**
 * @deprecated prefer `const alertPopup = useAlertPopup()`
 */
export function AlertPopup({
  alertPopupMeta,
  hidePopup = noop,
}: AlertPopupProps) {
  function handleClose() {
    alertPopupMeta.onClose?.();
    hidePopup();
  }

  if (alertPopupMeta.alertType === "question") {
    return (
      <Dialog
        onClosed={handleClose}
        width={528}
        render={({ close }) => (
          <div className={styles.confirmPopupContainer}>
            <div className={styles.confirmPopupInner}>
              <div
                className={`${styles.confirmPopupIcon} ${
                  styles[`confirmPopupIcon-warning`]
                }`}
              />
              <span className={styles.confirmPopupTitle}>
                {alertPopupMeta.title}
              </span>
              {!!alertPopupMeta.subTitle && (
                <div className={styles.confirmPopupSubtitle}>
                  {typeof alertPopupMeta.subTitle === "string" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: alertPopupMeta.subTitle,
                      }}
                    />
                  ) : (
                    <div>{alertPopupMeta.subTitle}</div>
                  )}
                </div>
              )}
              <div className={styles.confirmPopupActions}>
                <button
                  className={`${styles.btn} ${styles.btnSecondary}`}
                  onClick={close}
                >
                  {alertPopupMeta.closeButton}
                </button>
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => {
                    alertPopupMeta.onConfirmButton?.();
                    close();
                  }}
                >
                  {alertPopupMeta.confirmButton}
                </button>
              </div>
            </div>
          </div>
        )}
      />
    );
  }

  if (alertPopupMeta.alertType === "question-2") {
    return (
      <Dialog
        width={528}
        onClosed={handleClose}
        render={({ close }) => (
          <div className={styles.confirmPopupContainer}>
            <div className={styles.confirmPopupInner}>
              <div
                className={`${styles.confirmPopupIcon} ${
                  styles[`confirmPopupIcon-warning`]
                }`}
              />
              <span className={styles.confirmPopupTitle}>
                {alertPopupMeta.title}
              </span>
              {!!alertPopupMeta.subTitle && (
                <div className={styles.confirmPopupSubtitle}>
                  {typeof alertPopupMeta.subTitle === "string" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: alertPopupMeta.subTitle,
                      }}
                    />
                  ) : (
                    <div>{alertPopupMeta.subTitle}</div>
                  )}
                </div>
              )}
              <div className={styles.confirmPopupActions}>
                <button
                  className={`${styles.btn} ${styles.btnSecondary}`}
                  onClick={() => {
                    alertPopupMeta.onConfirmButton?.();
                    close();
                  }}
                >
                  {alertPopupMeta.confirmButton}
                </button>
                <button
                  className={`${styles.btn} ${styles.btnPrimary}`}
                  onClick={() => {
                    alertPopupMeta.onConfirmButton2?.();
                    close();
                  }}
                >
                  {alertPopupMeta.confirmButton2}
                </button>
              </div>
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <Dialog
      onClosed={handleClose}
      width={528}
      render={({ close }) => (
        <div className={styles.confirmPopupContainer}>
          <div className={styles.confirmPopupInner}>
            <div
              className={`${styles.confirmPopupIcon} ${
                styles[`confirmPopupIcon-${alertPopupMeta.alertType}`]
              }`}
            />
            <span className={styles.confirmPopupTitle}>
              {alertPopupMeta.title}
            </span>
            {!!alertPopupMeta.subTitle && (
              <div className={styles.confirmPopupSubtitle}>
                {typeof alertPopupMeta.subTitle === "string" ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: alertPopupMeta.subTitle,
                    }}
                  />
                ) : (
                  <div>{alertPopupMeta.subTitle}</div>
                )}
              </div>
            )}
            <div className={styles.confirmPopupActions}>
              <button
                className={`${styles.btn} ${styles.btnPrimary}`}
                onClick={() => {
                  alertPopupMeta.onClickAction?.();
                  close();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    />
  );
}
