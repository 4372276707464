import {
  useAgreeForCharityMutation,
  useAmericanTaxPayerMutation,
} from "src/app/api/mutations_new_to_rename";
import { WarningBanner } from "./warning-banner";
import { useMyUser } from "src/app/hooks/useMyUser";

export const Warnings = () => {
  const user = useMyUser();

  const agreeMutation = useAgreeForCharityMutation({
    onSettled() {
      !isTaxWarningVisible && window.location.reload();
    },
  });

  const isAgreementWarningVisible =
    !agreeMutation.isSuccess && !user.send_data_to_gb;

  const taxMutation = useAmericanTaxPayerMutation({
    onSettled() {
      !isAgreementWarningVisible && window.location.reload();
    },
  });

  const isTaxWarningVisible =
    !taxMutation.isSuccess && user.is_american_tax === null;

  if (!isAgreementWarningVisible && !isTaxWarningVisible) return null;

  return (
    <div>
      {!!isAgreementWarningVisible && (
        <div style={{ margin: "20px 0" }}>
          <WarningBanner
            message={`I consent to sharing my personal information with the Charity Auction Organization Liquid Icons and third parties involved in the auctions; I agree to the terms and conditions of crurated.com and the charity terms and conditions.`}
            primaryText="Agree"
            onClickPrimary={() => agreeMutation.mutate()}
          />
        </div>
      )}

      {!!isTaxWarningVisible && (
        <div style={{ margin: "20px 0" }}>
          <WarningBanner
            message="Are you a United States taxpayer?"
            primaryText="Yes"
            secondaryText="No"
            onClickPrimary={() => taxMutation.mutate({ is_american_tax: true })}
            onClickSecondary={() =>
              taxMutation.mutate({ is_american_tax: false })
            }
          />
        </div>
      )}
    </div>
  );
};
