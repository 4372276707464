import {
  ComponentPropsWithoutRef,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  BaseValues,
  FormContextType,
  FormErrors,
  FormSubmissionState,
  FormTouched,
  FormValidators,
  GetFieldError,
  GetFieldTouched,
  GetFieldValue,
  SetFieldsTouched,
  SetFieldsValues,
  TryToSubmitForm,
  __ResetFieldError,
} from "./types";
import {
  __computeErrors,
  __getErrorsList,
  __getFieldError,
  __getFieldTouched,
  __getFieldValue,
  __scrollToFirstErrorField,
  __toFormValues,
  __toOriginalValues,
} from "./utils";
import { MaybePromise } from "src/app/utils/types";
import { queueMacrotask } from "src/app/utils/queueMacrotask";

const FormContext = createContext<FormContextType<any, any, any> | null>(null);

interface Props<TValues extends BaseValues, TSubError, TSubSuccess>
  extends Omit<ComponentPropsWithoutRef<"form">, "children" | "onSubmit"> {
  initialValues: TValues;
  children: (
    ctx: FormContextType<TValues, TSubError, TSubSuccess>,
  ) => ReactNode;
  onSubmit: (
    values: TValues,
    ctx: FormContextType<TValues, TSubError, TSubSuccess>,
  ) => MaybePromise<TSubSuccess>;
}

export const Form = <TValues extends BaseValues, TSubError, TSubSuccess>({
  initialValues,
  onSubmit,
  className,
  style,
  children,
}: Props<TValues, TSubError, TSubSuccess>) => {
  type __SubmissionState = FormSubmissionState<TValues, TSubError, TSubSuccess>;
  const validatorsRef = useRef<FormValidators<TValues>>({});
  const onSubmitRef = useRef(onSubmit);
  onSubmitRef.current = onSubmit;

  const [state, setState] = useState(() => ({
    values: __toFormValues(initialValues),
    errors: {} as FormErrors<TValues>,
    touched: {} as FormTouched<TValues>,
    submission: {
      isLoading: false,
      error: undefined,
      isError: false,
      success: undefined,
      isSuccess: false,
      values: undefined,
    } satisfies __SubmissionState as __SubmissionState,
  }));

  const setFieldsValues: SetFieldsValues<TValues> = useCallback((partial) => {
    setState((state) => {
      const newValues = { ...state.values, ...partial };
      const newErrors = __computeErrors(validatorsRef.current, newValues);
      return {
        ...state,
        values: newValues,
        errors: newErrors,
      };
    });
  }, []);

  const setFieldsTouched: SetFieldsTouched<TValues> = useCallback((partial) => {
    setState((state) => ({
      ...state,
      touched: { ...state.touched, ...partial },
    }));
  }, []);

  const __resetFieldError: __ResetFieldError<TValues> = (name) => {
    setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: undefined },
    }));
  };

  const getFieldValue: GetFieldValue<TValues> = __getFieldValue(state.values);
  const getFieldError: GetFieldError<TValues> = __getFieldError(state.errors);
  const getFieldTouched: GetFieldTouched<TValues> = __getFieldTouched(
    state.touched,
  );

  const tryToSubmit: TryToSubmitForm = (e) => {
    e?.preventDefault();

    setState((state) => {
      // touch all validatable fields (= rendered fields with a "validate prop")
      const newTouched = {
        ...state.touched,
        ...Object.keys(validatorsRef.current).reduce(
          (acc, fieldName) => ({ ...acc, [fieldName]: true }),
          {} as FormTouched<TValues>,
        ),
      };

      // set errors for validatable fields (= rendered fields with a "validate prop")
      const newErrors = __computeErrors(validatorsRef.current, state.values);
      const hasErrors = !!__getErrorsList(newErrors).length;

      if (hasErrors) {
        const newState: typeof state = {
          ...state,
          touched: newTouched,
          errors: newErrors,
          submission: {
            isLoading: false,
            isError: false,
            isSuccess: false,
            error: undefined,
            success: undefined,
            values: undefined,
          },
        };
        queueMacrotask(() => __scrollToFirstErrorField());
        return newState;
      }

      const newState: typeof state = {
        ...state,
        touched: newTouched,
        errors: newErrors,
        submission: {
          isLoading: true,
          isError: false,
          isSuccess: false,
          error: undefined,
          success: undefined,
          values: __toOriginalValues(state.values),
        },
      };
      // Submit the form if no error is found
      queueMicrotask(() => submit(newState));
      return newState;
    });
  };

  async function submit(newState: typeof state) {
    const values = __toOriginalValues(newState.values);
    try {
      const successResult = await onSubmitRef.current(values, {
        ...ctx,
        submission: newState.submission,
        values,
      });
      setState({
        ...newState,
        submission: {
          success: successResult,
          error: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
          values,
        },
      });
    } catch (e: any) {
      const errorResult: TSubError = e;
      setState({
        ...newState,
        submission: {
          success: undefined,
          error: errorResult,
          isLoading: false,
          isError: true,
          isSuccess: false,
          values,
        },
      });
    }
  }

  const errorCount = __getErrorsList(state.errors).length;

  const ctx: FormContextType<TValues, TSubError, TSubSuccess> = {
    __errors: state.errors,
    __resetFieldError,
    __validators: validatorsRef.current,
    errorCount: errorCount,
    getFieldError,
    getFieldTouched,
    getFieldValue,
    hasErrors: !!errorCount,
    setFieldsTouched,
    setFieldsValues,
    submission: state.submission,
    tryToSubmit,
    values: __toOriginalValues(state.values),
  };

  return (
    <FormContext.Provider value={ctx}>
      <form
        noValidate
        onSubmit={ctx.tryToSubmit}
        className={className}
        style={style}
      >
        {children(ctx)}
      </form>
    </FormContext.Provider>
  );
};

export function useForm<
  TValues extends BaseValues,
  TSubError = any,
  TSubSuccess = any,
>(): FormContextType<TValues, TSubError, TSubSuccess> {
  const ctx = useContext<FormContextType<
    TValues,
    TSubError,
    TSubSuccess
  > | null>(FormContext as any);

  if (!ctx) {
    throw new Error("CruFormContext not found");
  }
  return ctx;
}
