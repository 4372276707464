import styles from "./lot-product-list.module.css";
import { useLayoutEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import { cn } from "src/app/utils/cn";
import { useCardCtx } from "../../card-context";
import { isAuctionOfLot } from "src/app/models/auctions";
import { BottleCount } from "./bottle-count";
import { getIsScrollable } from "src/app/utils/getIsScrollable";

export const LotProductList = () => {
  const cardCtx = useCardCtx();

  const ref = useRef<HTMLDivElement | null>(null);
  const [id] = useState(nanoid());

  const [isScrollable, setIsScrollable] = useState(false);

  useLayoutEffect(() => {
    setIsScrollable(getIsScrollable(ref.current).y);
  }, []);

  if (!cardCtx.showLotProductList) return null;
  if (!isAuctionOfLot(cardCtx.auction) || !cardCtx.auction.lot) return null;

  return (
    <div className={styles.root}>
      <div
        ref={ref}
        className={cn(styles.inner, "show-scrollbars")}
        style={isScrollable ? { paddingRight: "10px" } : {}}
      >
        {cardCtx.auction.lot.components.map((product, index) => (
          <div key={`auction-product-${id}-${index}`}>
            <div className={cn(styles.item, "show-scrollbar")}>
              <div
                className={styles.name}
                key={`auction-product-name-${index}`}
              >
                {product.nameProductWithoutVintage}
                <span key={`span-${index}`}>{product.vintage || "NV"}</span>
              </div>
              <BottleCount productIndex={index} />
            </div>
            <Divider key={`divider-${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

function Divider() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E1DBD8",
        width: "100%",
      }}
    />
  );
}
