import styles from "./form-delete-shipment.module.css";
import { useDeleteShipmentMutation } from "src/app/api/shipments";
import { cn } from "src/app/utils/cn";
import { ShipmentV1 } from "src/app/models/ShipmentV1";
import { SpinnerSm } from "src/app/components/spinner-sm";

type Props = {
  shipment?: ShipmentV1 | null;
  onClose: () => void;
  onSettled: () => void;
};

/**
 * @deprecated use alert-popup
 */
export const FormDeleteShipment = ({ shipment, onClose, onSettled }: Props) => {
  const { isLoading, isSuccess, isError, mutate, reset } =
    useDeleteShipmentMutation({ onSettled });

  const handleClose = () => {
    reset();
    onClose();
  };

  if (!shipment) return null;

  return (
    <div className={styles.root}>
      {!isSuccess && !isError && (
        <ConfirmActionContent
          isLoading={isLoading}
          onConfirm={() => mutate(shipment)}
          onCancel={handleClose}
        />
      )}

      {!!isSuccess && (
        <SuccessContent
          id={shipment.id_order}
          isLoading={isLoading}
          onClose={handleClose}
        />
      )}

      {!!isError && (
        <ErrorContent isLoading={isLoading} onClose={handleClose} />
      )}
    </div>
  );
};

type ConfirmActionContentProps = {
  isLoading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

function ConfirmActionContent({
  isLoading,
  onConfirm,
  onCancel,
}: ConfirmActionContentProps) {
  return (
    <>
      <div className={cn(styles.icon, styles.warning)} />
      <div className={styles.textWrapper}>
        <h2>Are you sure?</h2>
        <p>You wont be able to revert this!</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <button
          className={styles.buttonSecondary}
          disabled={isLoading}
          onClick={onConfirm}
        >
          {isLoading ? "Deleting..." : "Yes, Delete it!"}
          {!!isLoading && <SpinnerSm />}
        </button>
        <button
          className={styles.buttonPrimary}
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

type ErrorContentProps = {
  isLoading: boolean;
  onClose: () => void;
};

function ErrorContent({ onClose, isLoading }: ErrorContentProps) {
  return (
    <>
      <div className={cn(styles.icon, styles.error)} />
      <div className={styles.textWrapper}>
        <h2>Error deleting the shipment</h2>
        <p>Please, try again later</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <button
          onClick={onClose}
          disabled={isLoading}
          className={styles.buttonSecondary}
          style={{ width: "100%" }}
        >
          Ok
        </button>
      </div>
    </>
  );
}

type SuccessContentProps = {
  id?: string | number;
  onClose: () => void;
  isLoading: boolean;
};

function SuccessContent({ id, onClose, isLoading }: SuccessContentProps) {
  return (
    <>
      <div className={cn(styles.icon, styles.success)} />
      <div className={styles.textWrapper}>
        <h2>{`Shipment ${id} successfully deleted`}</h2>
        <p>You can now continue to manage your shippings</p>
      </div>
      <div className={styles.buttonsWrapper}>
        <button
          onClick={onClose}
          disabled={isLoading}
          className={styles.buttonSecondary}
          style={{ width: "100%" }}
        >
          Ok
        </button>
      </div>
    </>
  );
}
