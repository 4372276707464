import { Badge } from "src/app/components/badge";
import { PAYMENT_STATUS } from "src/app/const";
import { OutstandingPaymentV1 } from "src/app/models/OutstandingPaymentV1";

// TODO: double check the label/colors mapping
const PaymentStatusText = {
  [PAYMENT_STATUS.UNPAID]: "Unpaid",
  [PAYMENT_STATUS.PENDING]: "Pending",
  [PAYMENT_STATUS.PAID]: "Paid",
  [PAYMENT_STATUS.MARKED_AS_PAID]: "Marked as paid",
} as const;

const PaymentStatusPalette = {
  [PAYMENT_STATUS.UNPAID]: "danger",
  [PAYMENT_STATUS.PENDING]: "mild-danger",
  [PAYMENT_STATUS.PAID]: "success",
  [PAYMENT_STATUS.MARKED_AS_PAID]: "warning",
} as const;

type Props = {
  outstandingPayment: OutstandingPaymentV1;
};

export const OutstandingPaymentStatus = ({ outstandingPayment }: Props) => {
  return (
    <Badge
      label={PaymentStatusText[outstandingPayment.notice_as_paid]}
      palette={PaymentStatusPalette[outstandingPayment.notice_as_paid]}
    />
  );
};
