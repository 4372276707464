import { useEffect, useRef } from "react";
import { useTypedSearchParams } from "./useTypedSearchParams";
import { z } from "zod";
import { toPx } from "../utils/toPx";
import { useLatest } from "react-use";

export const useScrollToAuction = ({
  enabled,
  getCardId,
}: {
  enabled: boolean;
  getCardId: (id_auction: number) => string;
}) => {
  const getCardIdRef = useLatest(getCardId);
  const stickyControlsRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useTypedSearchParams({
    schema: z.object({ id: z.number().optional() }),
  });

  // scroll to the auction id passed in the location.search
  useEffect(() => {
    if (!enabled || !searchParams.id) return;
    console.log(`Scroll to auction ${searchParams.id}`);
    const stickyControlsHeight = stickyControlsRef.current?.offsetHeight || 0;

    const cardToScrollTo = document.getElementById(
      getCardIdRef.current(searchParams.id),
    );
    if (!cardToScrollTo) return;
    cardToScrollTo.style.scrollMargin = toPx(stickyControlsHeight + 1);
    cardToScrollTo.scrollIntoView({ behavior: "instant" });
  }, [enabled, searchParams.id, getCardIdRef]);

  return stickyControlsRef;
};
