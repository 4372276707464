import styles from "./auction-attributes.module.css";
import { Tooltip } from "src/app/components/tooltip";
import { Auction } from "src/app/models/auctions";

export const AuctionAttributes = ({ auction }: { auction: Auction }) => {
  return (
    <div className={styles.root}>
      {auction.experience?.attributes.map(
        (attr) =>
          attr.icon && (
            <Tooltip
              content={attr.attribute}
              key={`attribute-${auction.id}-${auction.experience?.id}-${attr.id}`}
            >
              <img
                key={attr.icon}
                className={styles.attributeIcon}
                src={attr.icon}
                alt={attr.attribute}
              />
            </Tooltip>
          ),
      )}
    </div>
  );
};
