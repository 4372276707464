import React from "react";
import bottle15 from "src/media/barrel/bottle-15.svg";
import bottle20 from "src/media/barrel/bottle-20.svg";
import bottle25 from "src/media/barrel/bottle-25.svg";
import bottle30 from "src/media/barrel/bottle-30.svg";
import bottle35 from "src/media/barrel/bottle-35.svg";
import bottle037 from "src/media/barrel/bottle-037.svg";
import bottle50 from "src/media/barrel/bottle-50.svg";
import bottle60 from "src/media/barrel/bottle-60.svg";
import bottle075 from "src/media/barrel/bottle-075.svg";
import bottle90 from "src/media/barrel/bottle-90.svg";
import bottle120 from "src/media/barrel/bottle-120.svg";
import bottle150 from "src/media/barrel/bottle-150.svg";
import bottle180 from "src/media/barrel/bottle-180.svg";

export function BarrelSampleBottleItem({ bottleName, bottleMeasure }) {
  return (
    <div className={`bgc-bottle-item bgc-${bottleName}`}>
      <figure>
        <img
          src={getBottleIcon(bottleName)}
          alt={bottleName}
          className="plain-bottle"
        />
      </figure>
      <span className="bgc-bottle-measure">{bottleMeasure}</span>
    </div>
  );
}

function getBottleIcon(bottleName) {
  if (bottleName === "bottle-15") return bottle15;
  if (bottleName === "bottle-20") return bottle20;
  if (bottleName === "bottle-25") return bottle25;
  if (bottleName === "bottle-30") return bottle30;
  if (bottleName === "bottle-35") return bottle35;
  if (bottleName === "bottle-037") return bottle037;
  if (bottleName === "bottle-50") return bottle50;
  if (bottleName === "bottle-60") return bottle60;
  if (bottleName === "bottle-075") return bottle075;
  if (bottleName === "bottle-90") return bottle90;
  if (bottleName === "bottle-120") return bottle120;
  if (bottleName === "bottle-150") return bottle150;
  if (bottleName === "bottle-180") return bottle180;
}
