import styles from "./title.module.css";
import { cn } from "src/app/utils/cn";
import { CSSProperties } from "react";
import { Tooltip } from "../../../tooltip";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { A } from "../../../a";
import { path } from "src/app/routes/path";
import { useCardCtx } from "../../card-context";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";

type Props = {
  className?: string;
  style?: CSSProperties;
};

export const Title = ({ className = "", style = {} }: Props) => {
  const { auction } = useCardCtx();
  const isMobile = useIsMobile();

  const [, setSearchParams] = useTypedSearchParams({
    schema: z.object({ id: z.number().optional() }),
  });
  return (
    <h3
      className={cn(styles.root, className, isMobile && styles.mobile)}
      style={style}
    >
      <Tooltip position="bottom" content={auction.description}>
        <A
          to={path.sales.value(auction)}
          className={styles.content}
          onClick={() => {
            setSearchParams({ id: auction.id }); // required for "back" button scroll restoration to auction card
          }}
        >
          {auction.description}
        </A>
      </Tooltip>
    </h3>
  );
};
