import styles from "./option-group.module.css";
import { forwardRef } from "react";
import { cn } from "src/app/utils/cn";

type Props = React.ComponentPropsWithoutRef<"div">;

export const DropdownOptionGroup = forwardRef<HTMLDivElement, Props>(
  ({ className = "", children, ...props }, ref) => {
    return (
      <div ref={ref} className={cn(styles.root, className)} {...props}>
        {children}
      </div>
    );
  },
);
