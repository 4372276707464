import styles from "./GridMd.module.css";
import React from "react";

export const GridMd = ({ children }) => {
  return (
    <div className={styles.root}>
      {children}
      {/* A few empty div to fill up the grid */}
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
