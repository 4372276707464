import styles from "./single-lot-image.module.css";
import { Img } from "src/app/components/img";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";

type Props = {
  auction: AuctionOfLot;
};

export const SingleLotImage = ({ auction }: Props) => {
  const src = auction.lot?.components[0].media.cover;
  if (!src) return null;

  return (
    <div className={styles.root}>
      <Img className={styles.img} src={src} alt="" />
    </div>
  );
};
