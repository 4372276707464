import React from "react";
import bottleSrc from "src/media/cellar/cellar-table-bottles.png";
import barrelSrc from "src/media/cellar/cellar-barrel-icon.png";
import experienceSrc from "src/media/cellar/experience-icon.png";

/**
 * @deprecated
 * @param {{imgType: 'lots' | 'barrels' | 'experience' }} props
 */
export function TableProductType({ imgType }) {
  switch (imgType) {
    case "lots":
      var urlImg = bottleSrc;
      break;
    case "barrels":
      urlImg = barrelSrc;
      break;
    case "experience":
      urlImg = experienceSrc;
      break;
    default:
  }

  return imgType === "lots" || imgType === "experience" ? (
    <figure className="phy-cellar-type">
      <img src={urlImg} alt="Bottles" className="img-responsive" />
    </figure>
  ) : (
    <figure className="phy-cellar-type phy-cellar-type-barrel">
      <img src={urlImg} alt="Barrels" />
    </figure>
  );
}
