type Props = {
  number: number;
  align?: "left" | "right" | "center";
};

export const MobileCellContentNumber = ({ number, align = "left" }: Props) => {
  return (
    <div
      style={{
        fontSize: "14px",
        whiteSpace: "nowrap",
        textAlign: align,
      }}
    >
      {number}
    </div>
  );
};
